import currency from 'currency.js'

function roundHalfEven(num) {
  const sign = Math.sign(num);
  let absNum = Math.abs(num);
  
  // Multiply by 100 to deal with 2 decimal places, this removes floating-point errors
  let roundedNum = Math.round(absNum * 100) / 100;
  
  // Check the difference between the absolute number and the rounded number
  const diff = absNum * 100 - Math.floor(absNum * 100);

  if (diff === 50) {
    // Apply "half-to-even" rule
    roundedNum = (Math.floor(absNum * 100) / 100) % 2 === 0 ? roundedNum : roundedNum - 0.01;
  }
  
  return sign * roundedNum;
}


export const calcTip = (percentage, value) => {
  // percentage = [0.10, 0.15, 0.20....]
  // value = [23.43, 43.25, 12.43]
  return currency(value).multiply(percentage).value
}

export const getSurcharge = (value, transactionFee) => {
  const FIXED_FEE = parseFloat(transactionFee); // 5 cents (Stripe) + 20 cents (Kio) = 25c
  const FIXED_PERCENT = 0.027; // 2.7% (Stripe)

  // Calculate the final charge based on the payment goal and fixed fees
  let finalCharge = (value + FIXED_FEE) / (1 - FIXED_PERCENT);

  // Round the final charge to two decimal places using roundHalfEven
  finalCharge = roundHalfEven(finalCharge);

  return finalCharge;
};

export const getSurchargeFee = (value, transactionFee) => {
  const FIXED_FEE = parseFloat(transactionFee); // equivalent to `request.user.systemuser.shop.transaction_fee`
  const FIXED_PERCENT = 0.027; // 2.7% (Stripe)
  
  const valueCurrency = currency(value); // value includes the total amount + tip
  let finalCharge = valueCurrency.add(FIXED_FEE).divide(1 - FIXED_PERCENT);
  
  // Perform rounding using ROUND_HALF_EVEN equivalent
  return roundHalfEven(finalCharge.subtract(valueCurrency).value);
};


export const getSalesTotal = (cartItems, taxRate, discount, toGoNonTaxable = false) => {

  let subtotalTaxable = currency(0);
  let subtotalNonTaxable = currency(0);

  for (let i = 0; i < cartItems.length; i++) {
    const itemPrice = currency(cartItems[i].modalItemData.item.unit_price).multiply(cartItems[i].quantity);
    if (cartItems[i].modalItemData.item.taxable && !toGoNonTaxable) {
      subtotalTaxable = subtotalTaxable.add(itemPrice);
    } else {
      subtotalNonTaxable = subtotalNonTaxable.add(itemPrice);
    }

    if (cartItems[i].modalItemData.item.groups) {
      cartItems[i].modalItemData.item.groups.forEach(group => {
        if (group.addedItem) { // addedItem is a user added option for cartItems[i]
          group.addedItem.forEach(item => {
            let additionalPrice;
            if (!item.is_number_option) { // is number option indicates the option was quantity adjustable or not. (i.e. Boba or 2 x Boba)
              additionalPrice = currency(item.unit_price).multiply(cartItems[i].quantity || 1);
            } else {
              additionalPrice = currency(item.number_value).multiply(item.unit_price).multiply(cartItems[i].quantity || 1)
            }

            if (cartItems[i].modalItemData.item.taxable  && !toGoNonTaxable) {
              subtotalTaxable = subtotalTaxable.add(additionalPrice);
            } else {
              subtotalNonTaxable = subtotalNonTaxable.add(additionalPrice);
            }
          });
        }
      });
    }
  }

  const taxAmount = subtotalTaxable.multiply(taxRate);
  const total = subtotalNonTaxable.add(subtotalTaxable).add(taxAmount).subtract(discount);
  return total.value;
};

export const getSalesSubtotal = (cartItems) => {
  let subtotal = currency(0);
  for (let i = 0; i < cartItems.length; i++) {
    const itemPrice = currency(cartItems[i].modalItemData.item.unit_price).multiply(cartItems[i].quantity);
    subtotal = subtotal.add(itemPrice);

    if (cartItems[i].modalItemData.item.groups) {
      cartItems[i].modalItemData.item.groups.forEach(group => {
        if (group.addedItem) {
          group.addedItem.forEach(item => {
            //const additionalPrice = currency(item.unit_price).multiply(item.is_number_option ? item.number_value : (item.quantity || 1));
            const additionalPrice = currency(item.unit_price).multiply(item.is_number_option ? item.number_value : (item.quantity || 1)).multiply(cartItems[i].quantity || 1);
            subtotal = subtotal.add(additionalPrice);
          });
        }
      });
    }
  }
  return subtotal.value;
};

export const getSalesTax = (cartItems, taxRate, toGoNonTaxable = false) => {
  let taxableSubtotal = currency(0);
  for (let i = 0; i < cartItems.length; i++) {
    if (cartItems[i].modalItemData.item.taxable && !toGoNonTaxable) {
      const itemPrice = currency(cartItems[i].modalItemData.item.unit_price).multiply(cartItems[i].quantity);
      taxableSubtotal = taxableSubtotal.add(itemPrice);

      if (cartItems[i].modalItemData.item.groups) {
        cartItems[i].modalItemData.item.groups.forEach(group => {
          if (group.addedItem) {
            group.addedItem.forEach(item => {
              //const additionalPrice = currency(item.unit_price).multiply(item.is_number_option ? item.number_value : (item.quantity || 1));
              const additionalPrice = currency(item.unit_price).multiply(item.is_number_option ? item.number_value : (item.quantity || 1)).multiply(cartItems[i].quantity || 1);
              taxableSubtotal = taxableSubtotal.add(additionalPrice);
            });
          }
        });
      }
    }
  }
  return taxableSubtotal.multiply(taxRate).value;
};

export const getSalesDiscount = () => {
  return 0;
}


export function serialize(data, number, datetime) {
  let items = [];
  data.map(item => {
    let _item = {
      name: item.name,
      qty: item.quantity,
      addedItem: []
    }

    if (item.modalItemData.item.groups) {
      item.modalItemData.item.groups.map(group => {
        if (group.addedItem) {
          let _addedItem = group.option.name + ': ';

          group.addedItem.map(item => {
            _addedItem += item.name + ', '
          })

          _item.addedItem.push(_addedItem.slice(0, -1));
        }
      })
    }

    items.push(_item);
  })

  return {items: items, number: number, datetime: datetime};
}
