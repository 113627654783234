import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import moment from 'moment';
import {
  deleteCoupon,
  addApplicableItems,
  deleteApplicableItem
} from '../coupons/couponsSlice';
import { fetchItemsAlt } from '../item/itemSlice'
import Popover from '@material-ui/core/Popover';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AddIcon from '@material-ui/icons/Add';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';


const useStyles = makeStyles({
    root: {
      minWidth: 200,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    code: {
      marginBottom: 12,
      fontWeight: 900
    },
  });

const CouponCard = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    // Determine if the coupon is active based on current date
    const now = moment(); // Current date and time
    const validFrom = moment(props.valid_from);
    const validUntil = moment(props.valid_until);
    const isActive = now.isAfter(validFrom) && now.isBefore(validUntil);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [itemsToAdd, setItemsToAdd] = useState([]);
    const allItems = useSelector(state => state.item.items);
    const [isItemAdding, setIsItemAdding] = React.useState(false);

    const unAddedItems = []
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
      setAnchorEl(null);

    };

    useEffect(() => {
      dispatch(fetchItemsAlt())
    }, [])

    useEffect(() => {

    }, [props.applicable_items])

    const open = Boolean(anchorEl);

    return (
        <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" component="h2">
          </Typography>
          <Typography className={classes.code} color="textSecondary">
            {props.code}
          </Typography>
          <Typography style={{borderBottom: '1px solid rgba(0,0,0,0.13)'}} variant="body2" component="p">
            <small>{isActive ? <b style={{color: 'green'}}>ACTIVE</b> : <b style={{color: 'red'}}>INACTIVE</b>  }</small>
          </Typography>
          <Typography style={{borderBottom: '1px solid rgba(0,0,0,0.13)'}} variant="body2" component="p">
            <small>{parseInt(props.discount_value)}% OFF</small>
          </Typography>
          <Typography style={{borderBottom: '1px solid rgba(0,0,0,0.13)'}} variant="body2" component="p">
            <small><b>VALID FROM</b> {moment(props.valid_from).format('MMMM Do YYYY, h:mm A')}</small>
          </Typography>
          <Typography style={{borderBottom: '1px solid rgba(0,0,0,0.13)'}} variant="body2" component="p">
            <small><b>VALID UNTIL</b> {moment(props.valid_until).format('MMMM Do YYYY, h:mm A')}</small>
          </Typography>
          <Typography style={{borderBottom: '1px solid rgba(0,0,0,0.13)', color: 'green'}} variant="body2" component="p">
            <small style={{color: 'black'}}><b style={{color: 'green'}}>REDEEMED</b> {props.redeemed_count}</small>
          </Typography>
        </CardContent>
          <List style={{borderTop: '1px solid rgba(0,0,0,0.15)', borderBottom: '1px solid rgba(0,0,0,0.15)', paddingBottom: 10, height: 150, overflow: 'auto'}} disablePadding disableGutters dense subheader={<ListSubheader style={{backgroundColor: 'white'}}><div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between', }}><small>APPLIES TO</small>
          {isActive &&           <small><IconButton onClick={handleClick} size="small" edge="end" aria-label="delete"><AddCircleOutlineIcon /></IconButton></small>
}
          <Popover
              open={open}
              onClose={handleClose}
              anchorEl={anchorEl}

              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
            <List dense disablePadding style={{width: 230}}>
              {itemsToAdd.length > 0 && (
                <ListItem
                  divider
                  button
                  onClick={() => {
                    dispatch(addApplicableItems({id: props.id, item_ids: itemsToAdd}));
                    setItemsToAdd([]);
                  }}
                >
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText primary="Add Selected Items" />
                </ListItem>
              )}
               {allItems
              .filter(item => !props.applicable_items.includes(item.id)).length === 0 && (
                <ListItem style={{height: 40}}>All items added</ListItem>
              )}
            {allItems
              .filter(item => !props.applicable_items.includes(item.id))
              .map((item) => {
                const labelId = `checkbox-list-secondary-label-${item.id}`;
                return (
                  <ListItem
                    key={item.id}
                    role="listitem"
                    button
                    onClick={() => {
                      if (itemsToAdd.includes(item.id)) {
                        setItemsToAdd(itemsToAdd.filter((id) => id !== item.id));
                      } else {
                        setItemsToAdd([...itemsToAdd, item.id]);
                      }
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={itemsToAdd.includes(item.id)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={item.name} />
                  </ListItem>
                );
            })}

            </List>

            </Popover>
          </div></ListSubheader>} >
            {!props.applicable_items || props.applicable_items.length === 0 && (
                          <ListItem>
                          <small>Coupon applies to all items</small>
                        
                        </ListItem>
            )}
          {props.applicable_items?.map(itemId => {
            const item = allItems.find(item => item.id === itemId);
            return (
              <ListItem key={itemId}>
               {item?.name}
               {isActive && (
                               <ListItemSecondaryAction>
                               <IconButton size="small" style={{float: 'right'}} onClick={() => dispatch(deleteApplicableItem({id: props.id, item_id: itemId}))} aria-label="delete"><DeleteIcon style={{fontSize: 20}} /></IconButton>
                               </ListItemSecondaryAction>
               )}
              </ListItem>
            );
          })}
          </List>
        <CardActions>
        <Button onClick={() => dispatch(deleteCoupon({id: props.id}))} color="secondary" size="small">Delete</Button>
      </CardActions>

      </Card>
    )
}

export default CouponCard;