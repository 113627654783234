import React, { useState, useEffect } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

const ImageLoader = (props) => {
  const [loaded, setLoaded] = useState(false);

  const handleLoad = () => {
    setLoaded(true);
  }

  const style = !loaded ? {...props.style, display: 'none' } : { ...props.style, display: 'block' };

  if (props.skeleton) {
    return (
      <React.Fragment>
        {!loaded ? (
          props.skeleton
        ) : (
          null
        )}
        <img style={{...style}} src={props.src} onLoad={handleLoad} />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {!loaded ? <div style={{...props.style}}></div> : null}
      <img style={{...style}} src={props.src} onLoad={handleLoad} />
    </React.Fragment>
  )
}

export default ImageLoader;
