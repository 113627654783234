import React from 'react';
import AddOn from '../../features/addon/AddOn';


const AddOnComponent = () => {
  return (
      <AddOn />
  );
};

export default AddOnComponent;
