import React, { useEffect, useState } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import Slider from '@material-ui/core/Slider';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useStopwatch } from 'react-timer-hook';
import { fetchTables, deleteTable, updateTable, updatePosition } from '../tableSlice'
import { useSelector, useDispatch } from 'react-redux';
import Draggable from 'react-draggable'; // Both at the same time
import GroupIcon from '@material-ui/icons/Group';

const OptionDialog = (props) => {
  const dispatch = useDispatch();
  if (props.text) {
    return (
      <Dialog id="option-dialog" onClose={props.handleClose} open={props.open}>
      <div style={{width: 500, paddingRight: 6, paddingTop: 5}}>
      <List>
        <ListItem>
        <TextField onChange={(e) => {
          props.onTextChange(e.target.value);
        }} label="TEXT" variant="outlined" fullWidth />
        </ListItem>
      </List>
        <List subheader={<ListSubheader>Size</ListSubheader>}>
          <ListItem>
          <Slider
            onChange={props.onSizeChange}
            defaultValue={props.size}
            valueLabelDisplay="auto"
            step={2}
            marks
            min={5}
            max={100}
          />
          </ListItem>
        </List>
        <List subheader={<ListSubheader>Degree</ListSubheader>}>
          <ListItem>
          <Slider
            onChange={props.onDegreeChange}
            defaultValue={props.degree}
            valueLabelDisplay="auto"
            step={5}
            marks
            min={0}
            max={365}
          />
          </ListItem>
        </List>
        <List subheader={<ListSubheader>Z Value</ListSubheader>}>
          <ListItem>
          <Slider
            onChange={props.onZChange}
            defaultValue={props.z}
            valueLabelDisplay="auto"
            step={1}
            marks
            min={0}
            max={5}
          />
          </ListItem>
        </List>
        <List subheader={<ListSubheader>Assgin Table</ListSubheader>}>
          <ListItem>
          <FormControl fullWidth variant="filled">
              <InputLabel>Tables</InputLabel>
              <Select
                value={props.table?.id}
                onChange={(e) => {dispatch(updateTable({id: props.id, table_id: e.target.value}))}}
              >
                <MenuItem value="-1">
                  <em>None</em>
                </MenuItem>
                {props.tables?.map(table => <MenuItem  key={table.id} value={table.id}>{table.table_number}</MenuItem>)}
              </Select>
            </FormControl>
          </ListItem>
        </List>
        <List>
          <ListItem>
          <Button onClick={() => props.onDelete()} variant="contained" color="primary" fullWidth>
          DELETE
          </Button>
          </ListItem>
        </List>
      </div>
      </Dialog>
    )
  } else {
    return (
      <Dialog onClose={props.handleClose} open={props.open}>
      <div style={{width: 500, paddingRight: 6, paddingTop: 5}}>
        <List subheader={<ListSubheader>Width</ListSubheader>}>
          <ListItem>
          <Slider
            onChange={props.onWidthChange}
            defaultValue={props.width}
            valueLabelDisplay="auto"
            step={25}
            marks
            min={25}
            max={500}
          />
          </ListItem>
        </List>
        <List subheader={<ListSubheader>Height</ListSubheader>}>
          <ListItem>
          <Slider
            onChange={props.onHeightChange}
            defaultValue={props.height}
            valueLabelDisplay="auto"
            step={25}
            marks
            min={25}
            max={500}
          />
          </ListItem>
        </List>
        <List subheader={<ListSubheader>Degree</ListSubheader>}>
          <ListItem>
          <Slider
            onChange={props.onDegreeChange}
            defaultValue={props.degree}
            valueLabelDisplay="auto"
            step={5}
            marks
            min={0}
            max={365}
          />
          </ListItem>
        </List>
        <List subheader={<ListSubheader>Z Value</ListSubheader>}>
          <ListItem>
          <Slider
            onChange={props.onZChange}
            defaultValue={props.z}
            valueLabelDisplay="auto"
            step={1}
            marks
            min={0}
            max={5}
          />
          </ListItem>
        </List>
        <List subheader={<ListSubheader>Assgin Table</ListSubheader>}>
          <ListItem>
          <FormControl fullWidth variant="filled">
              <InputLabel>Tables</InputLabel>
              <Select
              value={props.table?.id}
                onChange={(e) => {dispatch(updateTable({id: props.id, table_id: e.target.value}))}}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {props.tables?.map(table => <MenuItem key={table.id} value={table.id}>{table.table_number}</MenuItem>)}
              </Select>
            </FormControl>
          </ListItem>
        </List>
        <List>
          <ListItem>
          <Button onClick={() => props.onDelete()}  variant="contained" color="primary" fullWidth>
          DELETE
          </Button>
          </ListItem>
        </List>
      </div>
      </Dialog>
    )
  }
}

const Shape = (props) => {
  const [date, setDate] = useState(
    props.manager ? new Date(props.table?.table_instances[0]?.start_time) : new Date()
  );

  const [stopwatchOffset, setStopwatchOffset] = useState(() => {
    const secondsOffset = Math.floor((new Date() - date) / 1000);
    let offset = new Date();
    offset.setSeconds(offset.getSeconds() + secondsOffset);
    return offset;
  });

  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: false, offsetTimestamp: stopwatchOffset });

  useEffect(() => {
    if (props.manager) {
      setDate(new Date(props.table?.table_instances[0]?.start_time));
    }
  }, [props.table?.table_instances, props.manager, props.available, props.hold]);

  useEffect(() => {
    if (props.manager) {
      const secondsOffset = Math.floor((new Date() - date) / 1000);
      let offset = new Date();
      offset.setSeconds(offset.getSeconds() + secondsOffset);
      reset(offset)
      setStopwatchOffset(offset);
      start();
    }
  }, [date, props.manager]);

  const [tableCanvasState, setTableCanvasState] = useState({
    activeDrags: 0,
    deltaPosition: {
      x: 0, y: 0
    },
    controlledPosition: {
      x: -400, y: 200
    }
  })

  useEffect(() => {
    console.log(props.z)
  }, [props.z])

  const onStart = () => {
  setTableCanvasState({activeDrags: ++tableCanvasState.activeDrags});
  };

  const onStop = (e, v, shape_id) => {
    setTableCanvasState({activeDrags: --tableCanvasState.activeDrags});
    const x = v.x;
    const y = v.y;

    dispatch(updatePosition({id: props.id, x: x, y: y}))
  };


const dispatch = useDispatch()
const tables = useSelector(state => state.table.tables);
const [open, setOpen] = React.useState(false);

 const handleClickOpen = () => {
   setOpen(true);
 };

 const handleClose = () => {
   setOpen(false);
 };


  useEffect(() => {
    if (!props.manager) {

      //dispatch(fetchTables());
    }
  }, [])

  // reference to the click button's image
  const imageRef = React.createRef();
  const dragHandlers = {onStart: onStart};

  if (props.type === 'circle') {
    // default style
    let style = {
      margin: 0,
      zIndex: props.z,
      background: '#e8e8e8',
      borderRadius: 100,
      position: 'absolute',
      transform: props.degree ? 'rotate(' + props.degree + 'deg)' : 'rotate(0deg)',
      width: props.width ? props.width : 100,
      height: props.height ? props.height : 100,
    }

    if (props.table && props.table.hold) {
      style = {
        margin: 0,
        zIndex: props.z,
        boxShadow: 'rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px',
        background: 'rgb(116 116 116)',
        borderRadius: 500,
        position: 'absolute',
        transform: props.degree ? 'rotate(' + props.degree + 'deg)' : 'rotate(0deg)',
        width: props.width ? props.width : 100,
        height: props.height ? props.height : 100,
      }
    // if not avail
    } else if (props.table && props.table.available) {
      style = {
        margin: 0,
        zIndex: props.z,
        boxShadow: 'rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px',
        background: 'rgb(0 192 255)',
        borderRadius: 500,
        position: 'absolute',

        transform: props.degree ? 'rotate(' + props.degree + 'deg)' : 'rotate(0deg)',
        width: props.width ? props.width : 100,
        height: props.height ? props.height : 100,
      }
    // if not avail
    } else if (props.table && !props.table.available) {
      style = {
        margin: 0,
        zIndex: props.z,
        boxShadow: 'rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px',
        background: '#f50057',
        borderRadius: 500,
        position: 'absolute',

        transform: props.degree ? 'rotate(' + props.degree + 'deg)' : 'rotate(0deg)',
        width: props.width ? props.width : 100,
        height: props.height ? props.height : 100,
      }
    }

    return (
      <Draggable disabled={props.manager ? true : false} position={null} defaultPosition={{x: props.x, y: props.y}} handle=".handle" {...dragHandlers} onStop={(e,v) => onStop(e, v)}>
      <div className="handle">
      {props.table ? (
        <div
          onClick={props.onMouseClick}
          style={style}>
        <div style={{
          position: 'absolute',
           top:20, left:20, color: 'white'
        }}>

        <b>{props.table.table_number}</b> <br />
          <GroupIcon /> <span style={{position: 'relative',  bottom: 6, fontSize: 18}}>{props.table.max_capacity}</span>
        </div>
        {!props.manager ? <IconButton onClick={handleClickOpen} style={{background: 'rgba(0, 0, 0, 0.05)', position: 'absolute',
           top: -20, left: -30}} aria-label="menu">
            <MenuIcon  />
        </IconButton> : null}
        </div>
      ) : (
        <div
          onClick={props.onMouseClick}
          style={style}>
        {!props.manager ? <IconButton  onClick={handleClickOpen} style={{background: 'rgba(0, 0, 0, 0.05)', position: 'absolute',
           top: -10, left: -10}} aria-label="menu">
            <MenuIcon />
        </IconButton> : <IconButton onClick={handleClickOpen} style={{color: 'rgba(0,0,0,0)', position: 'absolute',
           top: -10, left: -10}} aria-label="menu">
            <MenuIcon  />
        </IconButton>}

        </div>
      )}
      <OptionDialog id={props.id} table={props.table}  tables={tables} onDelete={() => props.onDelete(props.id)} onDegreeChange={(e, v) => props.onDegreeUpdate(v)} onZChange={(e, v) => props.onZUpdate(v)}  onWidthChange={(e, v) => props.onWidthUpdate(v)} onHeightChange={(e, v) => props.onHeightUpdate(v)} degree={props.degree ? props.degree : 0}  z={props.z ? props.z : 0} width={props.width ? props.width : 100} height={props.height ? props.height : 100} open={open} handleClose={handleClose}/>
      </div>
      </Draggable>
    )
  } else if (props.type === 'rect') {
    // default style
    let style = {
      margin: 0,
      zIndex: props.z,
      background: '#e8e8e8',
      position: 'absolute',
      borderRadius: 5,
      transform: props.degree ? 'rotate(' + props.degree + 'deg)' : 'rotate(0deg)',
      width: props.width ? props.width : 200,
      height: props.height ? props.height : 100,
    }

    if (props.table && props.table.hold) {
      style = {
        margin: 0,
        zIndex: props.z,
        boxShadow: 'rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px',
        background: 'rgb(116 116 116)',
        position: 'absolute',
        borderRadius: 5,

        transform: props.degree ? 'rotate(' + props.degree + 'deg)' : 'rotate(0deg)',
        width: props.width ? props.width : 200,
        height: props.height ? props.height : 100,
      }
    // if available
    } else if (props.table && props.table.available) {
      style = {
        margin: 0,
        zIndex: props.z,
        display: 'inline-block',
        boxShadow: 'rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px',
        background: 'rgb(0 192 255)',
        position: 'absolute',
        borderRadius: 5,

        transform: props.degree ? 'rotate(' + props.degree + 'deg)' : 'rotate(0deg)',
        width: props.width ? props.width : 200,
        height: props.height ? props.height : 100,
      }
    // if not avail
    } else if (props.table && !props.table.available) {
      style = {
        margin: 0,
        zIndex: props.z,
        boxShadow: 'rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px',
        background: '#f50057',
        position: 'absolute',
        borderRadius: 5,

        transform: props.degree ? 'rotate(' + props.degree + 'deg)' : 'rotate(0deg)',
        width: props.width ? props.width : 200,
        height: props.height ? props.height : 100,
      }
    }
    return (
      <Draggable disabled={props.manager ? true : false} position={null} defaultPosition={{x: props.x, y: props.y}} handle=".handle" {...dragHandlers} onStop={(e,v) => onStop(e, v)}>
      <div className="handle">
      {props.table ? (
        <div
          onClick={props.onMouseClick}
          style={style}>
        <div style={{
          position: 'absolute',
           top:1, left:20, color: 'white'
        }}>
        {props.manager && !props.table.hold && !props.table.available && props.table?.table_instances[0]?.order?.length > 0  ? (
          <small style={{position: 'relative', background: '#ffffff3d', display: 'inline', fontSize: 16}}>
          {hours  > 0 ? String(hours).padStart(2, '0') : '00'}:{minutes > 0 ? String(minutes).padStart(2, '0') : '00'}:{String(seconds).padStart(2, '0')}
          </small>
        ) : (
          null
        )}<br/>
        <b>{props.table.table_number}</b> <br />
          <GroupIcon /> <span style={{position: 'relative',  bottom: 6, fontSize: 18}}>{props.table.max_capacity}</span>
        </div>
        {!props.manager ? <IconButton  onClick={handleClickOpen} style={{background: 'rgba(0, 0, 0, 0.05)', position: 'absolute',
           top: -25, left: -25}} aria-label="menu">
            <MenuIcon />
        </IconButton> : null}
        </div>
      ) : (
        <div
          onClick={props.onMouseClick}
          style={style}>
        {!props.manager ? <IconButton  onClick={handleClickOpen}  style={{background: 'rgba(0, 0, 0, 0.05)', position: 'absolute',
           top: -25, left: -25}} aria-label="menu">
            <MenuIcon/>
        </IconButton> : <IconButton  onClick={handleClickOpen}  style={{color: 'rgba(0,0,0,0)', position: 'absolute',
           top: -25, left: -25}} aria-label="menu">
            <MenuIcon />
        </IconButton>}

        </div>
      )}
      <OptionDialog id={props.id} table={props.table} tables={tables} onDelete={() => props.onDelete(props.id)}  onDegreeChange={(e, v) => props.onDegreeUpdate(v)} onZChange={(e, v) => props.onZUpdate(v)}  onWidthChange={(e, v) => props.onWidthUpdate(v)} onHeightChange={(e, v) => props.onHeightUpdate(v)} degree={props.degree ? props.degree : 0}  z={props.z ? props.z : 0} width={props.width ? props.width : 100} height={props.height ? props.height : 100} open={open} handleClose={handleClose}/>
      </div>
      </Draggable>
    )
  } else if (props.type === 'square') {
    // default style
    let style = {
      margin: 0,
      zIndex: props.z,
      background: '#e8e8e8',
      position: 'absolute',
      transform: props.degree ? 'rotate(' + props.degree + 'deg)' : 'rotate(0deg)',
      width: props.width ? props.width : 100,
      height: props.height ? props.height : 100,
      borderRadius: 5,

    }

    if (props.table && props.table.hold) {
      style = {

      margin: 0,
      zIndex: props.z,
      boxShadow: 'rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px',
      background: 'rgb(116 116 116)',
      position: 'absolute',
      transform: props.degree ? 'rotate(' + props.degree + 'deg)' : 'rotate(0deg)',
      width: props.width ? props.width : 100,
      height: props.height ? props.height : 100,
      borderRadius: 5,
    }
    // if available
    } else if (props.table && props.table.available) {
      style = {
        margin: 0,
        zIndex: props.z,
        display: 'inline-block',
        boxShadow: 'rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px',
        background: 'rgb(0 192 255)',
        position: 'absolute',
        transform: props.degree ? 'rotate(' + props.degree + 'deg)' : 'rotate(0deg)',
        width: props.width ? props.width : 100,
        height: props.height ? props.height : 100,
        borderRadius: 5,

      }
    // if not avail
    } else if (props.table && !props.table.available) {
      style = {
        margin: 0,
        zIndex: props.z,
        boxShadow: 'rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px',
        background: '#f50057',
        position: 'absolute',
        transform: props.degree ? 'rotate(' + props.degree + 'deg)' : 'rotate(0deg)',
        width: props.width ? props.width : 100,
        height: props.height ? props.height : 100,
        borderRadius: 5,

      }
    }
    return (
      <Draggable disabled={props.manager ? true : false} position={null} defaultPosition={{x: props.x, y: props.y}} handle=".handle" {...dragHandlers} onStop={(e,v) => onStop(e, v)}>
      <div className="handle">
      {props.table ? (
        <div
          onClick={props.onMouseClick}
          style={style}>
        <div style={{
          position: 'absolute',
           top:1, left:20, color: 'white'
        }}>
        {props.manager && !props.table.hold && !props.table.available && props.table?.table_instances[0]?.order?.length > 0  ? (
          <small style={{position: 'relative', background: '#ffffff3d', display: 'inline', fontSize: 16}}>
          {hours  > 0 ? String(hours).padStart(2, '0') : '00'}:{minutes > 0 ? String(minutes).padStart(2, '0') : '00'}:{String(seconds).padStart(2, '0')}
          </small>
        ) : (
          null
        )}<br/>
        <b>{props.table.table_number}</b>
        <br />
          <GroupIcon /> <span style={{position: 'relative',  bottom: 6, fontSize: 18}}>{props.table.max_capacity}</span>
        </div>
        {!props.manager ? <IconButton onClick={handleClickOpen}  style={{background: 'rgba(0, 0, 0, 0.05)', position: 'absolute',
           top: -25, left: -20}} aria-label="menu">
            <MenuIcon />
        </IconButton> : null}
        </div>
      ) : (
        <div
          onClick={props.onMouseClick}
          style={style}>
        {!props.manager ? <IconButton onClick={handleClickOpen} style={{background: 'rgba(0, 0, 0, 0.05)', position: 'absolute',
           top: -25, left: -20}} aria-label="menu">
            <MenuIcon />
        </IconButton> : <IconButton onClick={handleClickOpen}  style={{color: 'rgba(0,0,0,0)', position: 'absolute',
           top: -25, left: -20}} aria-label="menu">
            <MenuIcon />
        </IconButton>}

        </div>
      )}
      <OptionDialog  id={props.id} table={props.table} tables={tables} onDelete={() => props.onDelete(props.id)}  onDegreeChange={(e, v) => props.onDegreeUpdate(v)} onWidthChange={(e, v) => props.onWidthUpdate(v)} onZChange={(e, v) => props.onZUpdate(v)} onHeightChange={(e, v) => props.onHeightUpdate(v)}  z={props.z ? props.z : 0} degree={props.degree ? props.degree : 0} width={props.width ? props.width : 100} height={props.height ? props.height : 100} open={open} handleClose={handleClose}/>
      </div>
      </Draggable>
    )
  } else if (props.type === 'line') {
    // default style
    let style = {
      margin: 0,
      borderTop: '7px solid #bababa',
      position: 'absolute',
      transform: props.degree ? 'rotate(' + props.degree + 'deg)' : 'rotate(0deg)',
      width: props.width ? props.width : 50,
      height: props.height ? props.height : 1,
      zIndex: props.z
    }

    // if available
    if (props.table && props.table.available) {
      style = {
        margin: 0,
        borderTop: '7px solid #bababa',
        position: 'absolute',
        transform: props.degree ? 'rotate(' + props.degree + 'deg)' : 'rotate(0deg)',
        width: props.width ? props.width : 50,
        height: props.height ? props.height : 1,
        zIndex: props.z

      }
    // if not avail
    } else if (props.table && !props.table.available) {
      style = {
        margin: 0,
        borderTop: '7px solid #bababa',
        position: 'absolute',
        transform: props.degree ? 'rotate(' + props.degree + 'deg)' : 'rotate(0deg)',
        width: props.width ? props.width : 50,
        height: props.height ? props.height : 1,
        zIndex: props.z

      }
    }
    return (
      <Draggable disabled={props.manager ? true : false} position={null} defaultPosition={{x: props.x, y: props.y}} handle=".handle" {...dragHandlers} onStop={(e,v) => onStop(e, v)}>
      <div className="handle">
      {props.table ? (
        <div
          onClick={props.onMouseClick}
          style={style}>
        <div style={{
          position: 'absolute',
           top:20, left:20, color: 'white'
        }}><b>{props.table.table_number}</b> <br />
          <GroupIcon /> <span style={{position: 'relative',  bottom: 6, fontSize: 18}}>{props.table.max_capacity}</span>
        </div>
        {!props.manager ? <IconButton style={{background: 'rgba(0, 0, 0, 0.05)', position: 'absolute',
           top: -42, left: -25}} aria-label="menu">
            <MenuIcon onClick={handleClickOpen} />
        </IconButton> : null}
        </div>
      ) : (
        <div
          onClick={props.onMouseClick}
          style={style}>
        {!props.manager ? <IconButton style={{background: 'rgba(0, 0, 0, 0.05)', position: 'absolute',
           top: -42, left: -25}} aria-label="menu">
            <MenuIcon onClick={handleClickOpen} />
        </IconButton> : <IconButton style={{color: 'rgba(0,0,0,0)', position: 'absolute',
           top: -42, left: -25}} aria-label="menu">
            <MenuIcon onClick={handleClickOpen} />
        </IconButton>}

        </div>
      )}
      <OptionDialog id={props.id} table={props.table} tables={tables} onDelete={() => props.onDelete(props.id)}  onDegreeChange={(e, v) => props.onDegreeUpdate(v)} onWidthChange={(e, v) => props.onWidthUpdate(v)} onZChange={(e, v) => props.onZUpdate(v)}  onHeightChange={(e, v) => props.onHeightUpdate(v)} z={props.z ? props.z : 0} degree={props.degree ? props.degree : 0} width={props.width ? props.width : 100} height={props.height ? props.height : 100} open={open} handleClose={handleClose}/>
      </div>
      </Draggable>
    )
  } else {
    // default style
    let style = {
      display:'inline-block',
      height: 50,
      position: 'absolute',
      color: 'gray',
      fontSize: props.size ? props.size + 'px' : '20px',
      zIndex: props.z,

    }

    // if available
    if (props.table && props.table.available) {
      style = {
        display:'inline-block',
        height: 50,
        position: 'absolute',
        color: 'gray',
        zIndex: props.z,

        fontSize: props.size ? props.size + 'px' : '20px',
      }
    // if not avail
    } else if (props.table && !props.table.available) {
      style = {
        display:'inline-block',
        height: 50,
        position: 'absolute',
        color: 'gray',
        zIndex: props.z,

        fontSize: props.size ? props.size + 'px' : '20px',
      }
    }
    return (
      <Draggable disabled={props.manager ? true : false} position={null} defaultPosition={{x: props.x, y: props.y}} handle=".handle" {...dragHandlers} onStop={(e,v) => onStop(e, v)}>
      <div className={'handle'}>
      {props.table ? (
        <div
          onClick={props.onMouseClick}
          style={style}>
        <div style={{
          position: 'absolute',
           top:20, left:20, color: 'white'
        }}><b>{props.table.table_number}</b> <br />
          <GroupIcon /> <span style={{position: 'relative',  bottom: 6, fontSize: 18}}>{props.table.max_capacity}</span>
        </div>
        {!props.manager ? <IconButton style={{background: 'rgba(0, 0, 0, 0.05)', position: 'absolute',
           top: -20, left: -34}} aria-label="menu">
            <MenuIcon onClick={handleClickOpen} />
        </IconButton> : null}
          <span style={{display: 'inline-block', transform: props.degree ? 'rotate(' + props.degree + 'deg)' : 'rotate(0deg)'}}>{props.text ? props.text : 'TEXT'}</span>
        </div>
      ) : (
        <div
          onClick={props.onMouseClick}
          style={style}>
        {!props.manager ? <IconButton style={{background: 'rgba(0, 0, 0, 0.05)', position: 'absolute',
           top: -20, left:-34}} aria-label="menu">
            <MenuIcon onClick={handleClickOpen} />
        </IconButton> : <IconButton style={{color: 'rgba(0,0,0,0)', position: 'absolute',
           top: -20, left: -34}} aria-label="menu">
            <MenuIcon onClick={handleClickOpen} />
        </IconButton>}
        <span style={{display: 'inline-block', width:100, transform: props.degree ? 'rotate(' + props.degree + 'deg)' : 'rotate(0deg)'}}>{props.text ? props.text : 'TEXT'}</span>

        </div>
      )}
      <OptionDialog text size={props.size} onSizeChange={(e, s) => props.onSizeUpdate(s)} onTextChange={(s) => props.onTextUpdate(s)} id={props.id} table={props.table} tables={tables} onDelete={() => props.onDelete(props.id)}  onDegreeChange={(e, v) => props.onDegreeUpdate(v)} onZChange={(e, v) => props.onZUpdate(v)} onZChange={(e, v) => props.onZUpdate(v)}   onWidthChange={(e, v) => props.onWidthUpdate(v)} onHeightChange={(e, v) => props.onHeightUpdate(v)} z={props.z ? props.z : 0} degree={props.degree ? props.degree : 0} width={props.width ? props.width : 100} height={props.height ? props.height : 100} open={open} handleClose={handleClose}/>
      </div>
      </Draggable>

    )
  }
}

export default Shape;
