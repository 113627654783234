import React, { useEffect, useState } from 'react';
import { Dialog, Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { setOpenCheckOutForm, postManualPaymentIntent, clearSecret, clearPaymentProcessingInfo } from './paymentProcessingSlice';
import { getOrderDetail, clearOrderDetail } from '../order/orderSlice';

import currency from 'currency.js';
import {
    Elements,
  } from '@stripe/react-stripe-js';
  import {loadStripe} from '@stripe/stripe-js';


export const CheckOutForm = (props) => {
    const dispatch = useDispatch();
    const open = props.open;

    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState(null);
    const [submitting, setSubmitting] = useState(false)

    const handleSubmit = async (event) => {
        setSubmitting(true)
        event.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            return;
        }

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: window.location.href + '#', // Append '#' to current URL
            },
            redirect: "if_required",
        });

        if (error) {
            setErrorMessage(error.message);
        } else {
            dispatch(setOpenCheckOutForm(false))
            props.onSuccess()
        }

        setSubmitting(false)
    };

    return (
        <Dialog
            onClose={() => dispatch(setOpenCheckOutForm(false))}
            fullWidth
            maxWidth="sm"
            open={open}
            aria-labelledby="checkout-dialog-title"
            aria-describedby="checkout-dialog-description"
        >
            <form  style={{padding: 20}}
                onSubmit={handleSubmit}>
                    <>
                        <PaymentElement />
                        {stripe && elements && <Button  disableElevation type="submit" style={{marginTop: 15}} fullWidth variant="contained" color="primary" disabled={submitting || !stripe || !elements}>
                            {!submitting ? 'CHECKOUT' : 'LOADING ...'} 
                        </Button>}
                    </>

                {errorMessage && <div style={{marginTop: 10}}>{errorMessage}</div>}
            </form>
        </Dialog>
    );
};


export const CheckOutFormWrapper = (props) => {
    const dispatch = useDispatch();
    const open = useSelector(state => state.paymentProcessing.openCheckOutForm);
    const connectedAcctId = useSelector(state => state.auth?.user?.shop?.stripe_connect_acct_id)
    const stripe_connect_acct_id = useSelector(state => state.auth.user?.shop?.stripe_connect_acct_id ?? null);
    const isTesting = useSelector(state => state.auth.user?.shop?.is_testing ?? false);

    //const stripePromise = loadStripe('pk_live_51KU187GdutISh1LNZezgggZv4r3qt9G5t3hyCZ0zxwwgYFrr82LHZeTC84XW4SYyonSPsf0em2oiaP9fvN7IUGq100AzguEjGH');
    let stripePromise;

    if (isTesting) {
        stripePromise = connectedAcctId ? loadStripe('pk_test_51KU187GdutISh1LNbBFnDLJ2dOvxSiOAYCuFBphEqQZ7bdqkzRLwnQCWmnNn5GXdyd5c0XJjFmMksBdzx9TDV8ZQ00ChTasKTm', {
            stripeAccount: connectedAcctId
        }) : null;
    } else {
        stripePromise = connectedAcctId ? loadStripe('pk_live_51KU187GdutISh1LNZezgggZv4r3qt9G5t3hyCZ0zxwwgYFrr82LHZeTC84XW4SYyonSPsf0em2oiaP9fvN7IUGq100AzguEjGH', {
            stripeAccount: connectedAcctId
        }) : null;
    }

    const clientSecret = useSelector(state => state.paymentProcessing.secret);

    useEffect(() => {
        dispatch(postManualPaymentIntent({
            order_id: props.orderId,
            stripe_connect_acct_id: stripe_connect_acct_id,
            order_payment_id: null,
            total: currency(props.total).value,
        }));
        return () => {
            dispatch(clearSecret())
            dispatch(clearOrderDetail()) 
            dispatch(clearPaymentProcessingInfo());
        }
    }, [])

    // Only render CheckOutForm with Elements once clientSecret is available
    return (
      <div>
        {clientSecret && stripePromise && (
            <Elements stripe={stripePromise} options={{ clientSecret, }}>
                <CheckOutForm onSuccess={props.onSuccess} orderId={props.orderId} open={open} clientSecret={clientSecret} />
            </Elements>
        )}
      </div>
    );
  };

