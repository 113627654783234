import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useSelector, useDispatch } from 'react-redux';
import {
  setAddOptionModalOpen,
  selectOptionOpen
} from './modalSlice';
import { addItem, postOption } from '../addon/addOnSlice';

const AddOptionModal = (props) => {
  const open = useSelector(selectOptionOpen);
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    const categoryName = e.target.name.value;
    dispatch(postOption(categoryName));
    dispatch(setAddOptionModalOpen(false));
  };

  return (
    <Dialog fullWidth={50} maxWidth="sm" open={open} onClose={() => dispatch(setAddOptionModalOpen(false))} aria-labelledby="form-dialog-title">
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <DialogTitle id="form-dialog-title">Add Add-on</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="name"
          id="name"
          label="Add-on Name"
          type="text"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(setAddOptionModalOpen(false))} color="primary">
          Cancel
        </Button>
        <Button type="submit" color="primary">
          Add
        </Button>
      </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddOptionModal;
