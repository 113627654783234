import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Badge from '@material-ui/core/Badge';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import AddIcon from '@material-ui/icons/Add';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import InputAdornment from '@material-ui/core/InputAdornment';
import Avatar from '@material-ui/core/Avatar';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Popover from '@material-ui/core/Popover';
import { useDispatch, useSelector } from 'react-redux';
import { selectItemById } from '../../features/item/itemSlice';
import { selectOptionById } from '../../features/addon/addOnSlice';
import { selectCategoryById } from '../../features/category/categorySlice';

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers';

import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {useDraggable} from '@dnd-kit/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {

    height: 350,
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

const SimpleList = (props) => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isItemAdding, setIsItemAdding] = React.useState(false);
  const [itemsToAdd, setItemsToAdd] = useState([]);

  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const user = useSelector(state => state.auth.user);

  useEffect(() => {
    if (props.type == 'filters' && typeof props.items == 'undefined') {
      setList([])
    } else if (props.type === 'addons') {
      setList([...props.items.map(item => item.option.id)])
    } else if (props.type === 'items') {
      setList(props.items.map(item => item.item.id))
    } else if (props.type === 'items2') {
      setList([...props.items])
    } else if (typeof props.items !== 'undefined' && typeof props.items[0] === 'object') {
      setList(props.items.map(item => item.id))
    } else {
      setList([...props.items])
    }
  }, [props.items])

  useEffect(() => {
    if (list && list.length > 0) {
      if (list.length === props.items.length && activeId) {
        props.onOrderUpdate(typeof list[0] === 'object' ? list.map(item => item.id) : list)
      }
    }
  }, [list])

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        tolerance: 105,
      }}),

  );

  function handleDragStart(event) {
    const {active} = event;

    setActiveId(active.id);
  }

  function handleDragEnd(event) {
    const {active, over} = event;
    if (over && active) {
      if (active.id !== over.id) {
        setList((list) => {
          const oldIndex = list.indexOf(active.id);
          const newIndex = list.indexOf(over.id);
          return arrayMove(list, oldIndex, newIndex);
        });
      }
    }
    setActiveId(null);
  }



   const handleClick = (event) => {
     setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
     setAnchorEl(null);
   };

  const handleCheck = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  }

  const handleClickDelete = () => {
    typeof props.items[0] === 'object'
    ?
    props.onItemRemove(checked, props.items.map(item => item.id))
    :
    props.onItemRemove(checked, props.items)

    setChecked([]);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const unAddedItems = props.unselectedItems.filter(unselectedItem => list.indexOf(parseInt(unselectedItem.id, 10)) === -1)
  const customList = (items, name, handleClick, hasOptions, type) => (
    <React.Fragment>
      <Paper className={classes.paper}>
        <List className="prevent-select" component="div" role="list">
        <Grid container
              direction="row"
              justify="space-between"
              alignItems="center">
          <Grid item xs>
            <h3 style={{paddingLeft: 15, fontWeight: 400}}>{props.title}</h3>
          </Grid>
          <Grid item xs>
          {checked.length > 0 && user?.shop?.type != 'franchisee' ?<IconButton style={{float: 'right'}} onClick={handleClickDelete} aria-label="delete"><DeleteIcon /></IconButton> : null}

          {user?.shop?.type != 'franchisee' ? (
          <IconButton style={{float: 'right'}} onClick={handleClick}><AddCircleOutlineIcon /></IconButton>) : (
            null
          )}
          <Popover
              open={open}
              onClose={handleClose}
              anchorEl={anchorEl}

              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
            <List dense disablePadding style={{minWidth: 150}}>
              {itemsToAdd.length > 0 && (
                <ListItem
                  divider
                  button
                  onClick={() => {
                    setIsItemAdding(true);
                    props.onItemBulkAdd(list, itemsToAdd);
                    setItemsToAdd([]);
                    setTimeout(() => {
                      setIsItemAdding(false);
                    }, 1200);
                  }}
                >
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText primary="Add Selected Items" />
                </ListItem>
              )}
              {/* Select All Button */}
             <ListItem
               divider
               button
               onClick={() => {
                 const allItemIds = unAddedItems.map(item => item.id);
                 setItemsToAdd(allItemIds);
               }}
             >
               <ListItemIcon>
                 <SelectAllIcon />
               </ListItemIcon>
               <ListItemText primary="Select All" />
             </ListItem>
              {unAddedItems.map((item) => {
                const labelId = `checkbox-list-secondary-label-${item.id}`;
                return (
                  <ListItem
                    key={item.id}
                    role="listitem"
                    button
                    onClick={() => {
                      if (itemsToAdd.includes(item.id)) {
                        setItemsToAdd(itemsToAdd.filter((id) => id !== item.id));
                      } else {
                        setItemsToAdd([...itemsToAdd, item.id]);
                      }
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={itemsToAdd.includes(item.id)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={item.name} />
                  </ListItem>
                );
              })}

            </List>

            </Popover>
          </Grid>
          <Grid item xs={12} style={{marginBottom: 10}}>
          <Divider />
          </Grid>
        </Grid>
          <div style={{overflowY: 'scroll', height: 265}}>
          <DndContext
            modifiers={[restrictToVerticalAxis]}
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >

          <SortableContext
            items={typeof list[0] === 'object' ? list.map(item => item.id) : list}
            strategy={verticalListSortingStrategy}>
            {list.map(item => {
              return typeof item === 'object'
              ?
              <SimpleListItem hasOptions={hasOptions} key={item.id} handleCheck={handleCheck} checked={checked} id={item.id} type={type} />
              :
              <SimpleListItem hasOptions={hasOptions} key={item} handleCheck={handleCheck} checked={checked} id={item} type={type} />
            })}
          </SortableContext>
          </DndContext>
          </div>
        </List>
      </Paper>
    </React.Fragment>
  );

  return (
    <Grid container className={classes.root}>
      <Grid item xs>{customList(list, props.name, handleClick, props.hasOptions, props.type)}</Grid>
    </Grid>
  );
}


const SimpleListItem = (props) => {
  // !!TODO!!
  // provide SimpleListItem explicitly to client so that the below logic
  // can be explicitly defined outside of SimpleList.js
  let item = useSelector(state => {
    if (props.type == 'items' || props.type == 'items2') {
      return selectItemById(state, props.id)
    } else if (props.type == 'categories') {
      return selectCategoryById(state, props.id)
    } else if (props.type == 'addons') {
      return selectOptionById(state, props.id)
    } else if (props.type == 'filters') {
      return selectItemById(state, props.id)
    }
  })

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: props.id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  if (item !== null) {
    return (
      <ListItem  ref={setNodeRef} style={style} {...attributes} {...listeners} key={props.key} role="listitem" button onClick={() => props.handleCheck(props.id)}>
        <ListItemText primary={item.name} />
        <ListItemSecondaryAction onClick={() => props.handleCheck(props.id)}>
          <Checkbox
            checked={props.checked.indexOf(props.id) !== -1}
            edge="end"
          />
        </ListItemSecondaryAction>
      </ListItem>
    )
  }
  return null;
  };

export default SimpleList;
