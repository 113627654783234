import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { refundOrderItemSingleTender } from '../features/order/orderSlice';

const useProcessRefunds = () => {
  const dispatch = useDispatch();

  const [refundStatus, setRefundStatus] = useState(null);
  const maxRetries = 3; // Set the maximum number of retries

  const refundEntireOrder = async (orderId) => {
    // Implement the refund logic for the entire order using your API or back-end function
    console.log('Refunding entire order:', orderId);
  };

  const refundOrderItem = async (orderId, orderItemId) => {
    // Implement the refund logic for a specific item across all tenders using your API or back-end function
    console.log('Refunding order item across all tenders:', orderId, orderItemId);
  };


  const refundCustomAmount = async (orderId, paymentId, refundAmount) => {
    // Implement the refund logic for a custom amount to a specific tender using your API or back-end function
    console.log('Refunding custom amount to specific tender:', orderId, paymentId, refundAmount);
  };

  const processRefund = async (refundType, orderId, options = {}) => {
    let retryCount = 0;

    const retryRefund = async () => {
      try {
        switch (refundType) {
          case 'refundAll':
            // Call your API or back-end function to refund the entire order
            await refundEntireOrder(orderId);
            break;

          case 'refundByItemsAllTenders':
            const { orderItemId } = options;
            // Call your API or back-end function to refund a specific item across all tenders
            await refundOrderItem(orderId, orderItemId);
            break;

          case 'refundByItemsSpecificTender':
            const { orderItemIds, intent } = options;
            // Call your API or back-end function to refund a specific item to a specific tender
            await dispatch(refundOrderItemSingleTender({intent: intent, orderId: orderId, orderItemIds: orderItemIds}));
            break;

          case 'customValue':
            const { refundAmount, paymentId: customPaymentId } = options;
            // Call your API or back-end function to refund a custom amount to a specific tender
            await refundCustomAmount(orderId, customPaymentId, refundAmount);
            break;

          default:
            throw new Error('Invalid refund type provided');
        }

        setRefundStatus('success');
      } catch (error) {
        console.error(error);
        retryCount++;

        if (retryCount <= maxRetries) {
          console.log(`Retry attempt ${retryCount}...`);
          return retryRefund();
        } else {
          setRefundStatus('error');
        }
      }
    };

    return retryRefund();
  };

  return { refundStatus, processRefund };
};

export default useProcessRefunds; // Export the hook as default
