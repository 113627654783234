import React, { useEffect, useState, useRef } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import CURRENT_SCREEN from './CurrentScreenEnums';

const TableHistory = (props) => {

  return (
      <div>
        <h2 style={{padding: 0, margin: 0}}>
          <IconButton onClick={() => {props.onTypeUpdate(CURRENT_SCREEN.HOME)}}><KeyboardBackspaceIcon /></IconButton>
          Table History
        </h2>

        {props.tableInstances.map((instance, index) =>
          <>
          <List key={index}>

            <ListItem>
              <ListItemText>
                <small>{new Date(instance.start_time).toLocaleString()}</small>
              </ListItemText>
              <ListItemText>
              ~
              </ListItemText>

              <ListItemText>
              {instance.end_time ? (
                <small>{new Date(instance.end_time).toLocaleString()}</small>
              ) : (
                <small>N/A</small>
              )}
              </ListItemText>
            </ListItem>
          </List>
          <Divider />
          </>
        )}
    </div>
  )
}

export default TableHistory;
