import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  ThemeProvider, createMuiTheme, makeStyles
} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import {
  postAuthLogin, getSystemUser
} from './authSlice'
import {useDispatch, useSelector} from "react-redux";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import OrangeLogo from '../../assets/kio-orange.png';
import KioLogoText from '../../assets/kio-logo-text.png';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 40,
    width: 475,
    [theme.breakpoints.down('sm')]: {
      width: "95%",
      padding: 20
    },
    borderRadius: 15
  },
  alert: {
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  gridRoot: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  }
}));

// Define the spinning animation using CSS
const styles = {
  "@keyframes spin": {
    "from": { transform: "rotate(0deg)" },
    "to": { transform: "rotate(360deg)" }
  },
  container: {
    height: "100vh", // Full viewport height
    display: "flex",
    alignItems: "center", // Centers the content vertically
    justifyContent: "center", // Centers the content horizontally
  },
  image: {
    transformOrigin: 'center', /* Center the rotation axis */
    animation: "spin 1.5s linear infinite", // Apply the spinning effect
  }
};


const Login = (props) => {
  const classes = useStyles();
  const theme = createMuiTheme({
    typography: {
      fontFamily: 'Heebo, sans-serif',
    },
   palette: {
    background: {
      default: 'rgb(247, 249, 252)'
    },
    primary: {
      main: '#438abd'
    }
  }
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const status = useSelector(state => state.auth.status);
  const user = useSelector(state => state.auth.user);

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(postAuthLogin({username: username, password: password}));
  }

  const handleChangeUsername = e => {
    setUsername(e.target.value);
  }

  const handleChangePassword = e => {
    setPassword(e.target.value);
  }

  useEffect(() => {
    if (localStorage.getItem('refresh_token')) {
      dispatch(getSystemUser());
    }
  }, [])

  useEffect(() => {
    if (localStorage.getItem('refresh_token')) {
      dispatch(getSystemUser());
    }
  }, [status])

  useEffect(() => {
    if (user) {
      localStorage.setItem('shop_id', user.shop.id)
      history.push('/category')
    }
  }, [user])

  return (
    localStorage.getItem('refresh_token') ?
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth={false} disableGutters style={styles.container}>
          <img src={OrangeLogo} style={styles.image} alt="Logo" />
        </Container>
      </ThemeProvider>
    </React.Fragment>
    :
    <React.Fragment>
    <ThemeProvider theme={theme}>
    <CssBaseline />
    <Container maxWidth={false} disableGutters>      
      <Grid className={classes.gridRoot}
      spacing={0}
      alignItems="center"
      justify="center">
            <Card className={classes.root}>
              <Grid
                container
                item
                direction="column"
                justifyContent="center"
                alignItems="center">
                {status === 'unauthorized' ?
                  <Alert className={classes.alert} style={{marginBottom: 20}} elevation={0} variant="filled" severity="warning">
                    Unauthorized. Please check your username or password.
                  </Alert> : null}
                  <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems:'center', flexDirection: 'column', padding: 0}}>
          <img src={OrangeLogo} style={{position: 'relative', top: 0,width: 45.4, marginBottom: 10}} />

            <img src={KioLogoText} style={{width: 140, marginRight: 2.5, zIndex: 1}}/> 
            
                      </div>
                                        <h2 className="roboto-font">Sign in</h2>
                  <form onSubmit={(e) => handleSubmit(e)}>
                  <TextField
                    name="username"
                    label="Username"
                    placeholder="Enter your username"
                    required
                    fullWidth
                    onChange={handleChangeUsername}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    name="password"
                    label="Password"
                    type="password"
                    required
                    fullWidth
                    onChange={handleChangePassword}
                    style={{ marginTop: 20 }}
                    placeholder="Enter your password"
                    variant="outlined"
                    onEnter={handleSubmit}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Button type="submit" onClick={handleSubmit} style={{float: "right", marginTop: 15, backgroundColor: '#4c5b5e'}} variant="contained" color="primary">
                    Login
                  </Button>
                  </form>
                  </Grid>
            </Card>
            </Grid>
            </Container>
            </ThemeProvider>
    </React.Fragment>
  )
}

export default Login;
