import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import { useStopwatch } from 'react-timer-hook';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { updateTicketStatus, hideTicket, updateItemDone, updateOrderHidden } from './kitchenSlice';
import { sendSMSPickUp } from '../order/orderSlice';
import { useDispatch, useSelector } from 'react-redux';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DoneIcon from '@material-ui/icons/Done';
import SmsIcon from '@material-ui/icons/Sms';
import SendIcon from '@material-ui/icons/Send';
import Popover from '@material-ui/core/Popover';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { IconButton } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 7
  },
  smallBtn: {
    fontSize: 11,
    position: 'relative',
    bottom: 1.4,
    paddingTop:0,
    paddingBottom:0,
    backgroundColor: '#fb8d78'
  }
}));

const FilteredItems = (props) => {
  const dispatch = useDispatch()
  const simplified = useSelector(state => state.auth.user?.shop?.kitchen_ticket_simplified ?? false);

  let renderItems = []
  const ticket = props.ticket;
  const station = props.station;
  const valueStation = props.valueStation;
  ticket.order_items.map(item => {
    station.items.map(stationItem => {
      if (item.item !== null && stationItem !== null) {
        if (stationItem.id === item.item.id) {
          renderItems.push(item);
        }
      }
    })
  })

  return valueStation === 'all' ? (
    ticket.order_items.map(item =>
      <ListItem onClick={() => {
        props.onItemUpdate({order_id: ticket.id, order_item_id: item.id, status: !item.done});
        dispatch(updateItemDone({done: !item.done, itemId: item.id, ticketId: props.ticket.id}))
      }} style={{display: 'block'}} key={item.id}>{item.item ? <b><Chip size="small" color="primary" style={{position: 'relative', borderRadius:2, bottom: 0, marginRight: 5, minWidth: 20}} label={<h3>{item.quantity}</h3>} />{item.done ? <strike>{item.item.name}</strike> : item.item.name}</b> : <b  style={{margin:0, padding:0}}>-</b>}
        <List style={{marginTop: 0}} dense  component="span" disablePadding>
          {item.done ? <DoneIcon color="primary" /> : null}
          {item.order_item_options.map(option => <ListItem style={{display: 'block'}} button key={option.id}>{simplified ? option.name : option.option_str}</ListItem>)}
        </List>
      </ListItem>
    )
  ) : (
   renderItems.map(item =>
      <ListItem
        onClick={() => {
          props.onItemUpdate({order_id: ticket.id, order_item_id: item.id, status: !item.done});
          dispatch(updateItemDone({done: !item.done, itemId: item.id, ticketId: props.ticket.id}))
        }}
        style={{display: 'block'}} key={item.id}>{item.item ? <b><Chip size="small" color="primary" style={{position: 'relative', borderRadius:2, bottom: 0, marginRight: 5, minWidth: 20}} label={<h3>{item.quantity}</h3>} />{item.done ? <strike>{item.item.name}</strike> : item.item.name}</b> : <b  style={{margin:0, padding:0}}>-</b>}
        <List style={{marginTop: 0}} dense  component="span" disablePadding>
        {item.done ? <DoneIcon color="primary" /> : null}
          {item.order_item_options.map(option => <ListItem style={{display: 'block'}} button key={option.id}>{simplified ? option.name : option.option_str}</ListItem>)}
        </List>
      </ListItem>
    )
  )
}


const Ticket = (props) => {
  const dispatch = useDispatch();
  const status = useSelector(state => state.kitchen.status);
  const orderStatus = useSelector(state => state.order.status);

  const date = new Date(props.datetime);
  const secondsOffset = Math.floor((new Date() - date) / 1000);
  let stopwatchOffset = new Date();
  stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + secondsOffset)
  const classes = useStyles();
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: true, offsetTimestamp: stopwatchOffset});
  const [sendSms, setSendSms] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [sendSmsLoading, setSendSmsLoading] = useState(false);
  const [sendSmsLabel, setSendSmsLabel] = useState('Send Pickup Text')



  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handlePrint = () => {
    props.onPrint();
  }

  function handleSendPickUpSMS() {
    setSendSmsLoading(true);
    if (props.ticket.phone && !props.ticket.table_instance) {
      dispatch(sendSMSPickUp({number: props.number, phone: props.ticket.phone})).then(() => {
        setTimeout(() => {
          setSendSmsLoading(false);
        }, 1500)
      })
    }
  }

  const handleStatusUpdate = (payload) => {
    if (props.valueStation === 'all' && status !== 'loading') {
      let renderItems = []
      const ticket = props.ticket;
      const station = props.station;
      const valueStation = props.valueStation;
      ticket.order_items.map(item => {
          renderItems.push(item);
      })
      renderItems.forEach(item => {
        if (!item.done) {
          props.onOrderItemUpdate({order_id: props.ticket.id, order_item_id: item.id, status: true})
          dispatch(updateItemDone({done: true, itemId: item.id, ticketId: props.ticket.id}))
        }
      });
      props.onTicketUpdate({order_id: props.ticket.id, status: payload});
      dispatch(updateTicketStatus({id: props.ticket.id, status: payload}))
    } else if (props.valueStation !== 'all' && status !== 'loading') {
      let renderItems = []
      const ticket = props.ticket;
      const station = props.station;
      const valueStation = props.valueStation;
      ticket.order_items.map(item => {
        station.items.map(stationItem => {
          if (item.item !== null && stationItem !== null) {
            if (stationItem.id === item.item.id) {
              renderItems.push(item);
            }
          }
        })
      })

      if (props.value === 3) {
        renderItems.forEach(item => {
           props.onOrderItemUpdate({order_id: props.ticket.id, order_item_id: item.id, status: false})
           dispatch(updateItemDone({done: false, itemId: item.id, ticketId: props.ticket.id}))
        });
        props.onTicketHiddenUpdate({order_id: props.ticket.id, status: false})
        dispatch(updateOrderHidden({orderId: props.ticket.id, hidden: false}))
      } else {
        renderItems.forEach(item => {
           props.onOrderItemUpdate({order_id: props.ticket.id, order_item_id: item.id, status: true})
           dispatch(updateItemDone({done: true, itemId: item.id, ticketId: props.ticket.id}))
        });
        props.onTicketHiddenUpdate({order_id: props.ticket.id, status: true})
        dispatch(updateOrderHidden({orderId: props.ticket.id, hidden: true}))
      }
    }
  }

  //const [offsetSeconds, setOffsetSeconds] = useState(0)
  const cancelBtn = props.ticket.status !== 'cancelled' ? <Button variant="contained" size="small"
                    className={classes.smallBtn}
                    onClick={() => handleStatusUpdate('cancelled')}
                    color="secondary">CANCEL
            </Button> : null;

  const undoBtn = props.ticket.status !== 'cancelled' ?  <Button
    disableRipple
    onClick={() => {
      props.onTicketUpdate({order_id: props.ticket.id, status: 'processing'});
      dispatch(updateTicketStatus({id: props.ticket.id, status: 'processing'}))}
    }
    fullWidth color="primary" variant="contained" size="large"
   >
     Undo
   </Button> : null;

  if (props.valueStation !== 'all' && props.ticket.hidden && props.ticket.hidden === true && props.value !== 3) {
    return null;
  }

  return (
    <Card
      style={{marginRight: 10, marginBottom: 10, width: 250}}
      className={classes.root}>
    <CardContent style={{paddingBottom: 0}}>
    <Grid
      style={{padding:0, borderBottom: '1px solid #c9c9c9'}}
      container
      direction="row"
      justifyContent="space-between"
    >
      {props.ticket.status === 'processing' && props.ticket.phone ? (
        <>
        <Grid item xs={5}>
        <Typography className={classes.title} color="textPrimary" gutterBottom>
        # {props.number}
        </Typography>
        </Grid>
        <Grid item xs={6}>
          {props.ticket.status === 'processing' ?
          <span style={{float: 'right'}}>
            {minutes > 0 ? String(minutes).padStart(2, '0') : '00'}:{String(seconds).padStart(2, '0')}
          </span> :
          <span style={{float: 'right'}}>
            {cancelBtn}
          </span>}
        </Grid>
        <Grid item xs={1}>
          <IconButton style={{position: 'relative', bottom: 5}} edge="end" size="small"  onClick={handlePopoverClick}>
            <MoreVertIcon />
          </IconButton>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
          <div style={{padding: 10}}>
          <FormControlLabel
            control={
              <Checkbox
                style={{marginRight: 0, paddingRight: 0, marginLeft: 0}}
                checked={sendSms}
                onChange={(event) => setSendSms(event.target.checked)}
                name="sendSms"
                color="primary"
              />
            }
            label={<small style={{lineHeight: 0.5, paddingLeft: 3}}>Send SMS on <i>DONE</i></small>}
            style={{ padding: 0, margin: 0, display: 'block', marginLeft: 0, paddingRight: 7, marginRight: -3, height: 37, borderRadius: 4, border: '1px solid #438abd'}}
          />
          </div>
          </Popover>
        </Grid>
        </>
      ) : (
        <>
        <Grid item xs={6}>
        <Typography className={classes.title} color="textPrimary" gutterBottom>
        # {props.number}
        </Typography>
        </Grid>
        <Grid item xs={6}>
          {props.ticket.status === 'processing' ?
          <span style={{float: 'right'}}>
            {minutes > 0 ? String(minutes).padStart(2, '0') : '00'}:{String(seconds).padStart(2, '0')}
          </span> :
          <span style={{float: 'right'}}>
            {cancelBtn}
          </span>}
        </Grid>
        </>
      )}
    </Grid>
    </CardContent>
     <CardContent style={{maxHeight: '60vh', overflowY: 'scroll', paddingTop: 10, paddingLeft: 0, paddingRight:0, marginBottom: 0, paddingBottom: 0}}>
      <List component="span"  style={{paddingTop: 0}}>
        <ListItem style={{justifyContent: 'space-between'}}>
          {props.ticket.to_go ? (
            <b style={{color: 'red'}}>TO GO</b>
          ) : (
            <b style={{color: 'blue'}}>
            DINE IN
            <span style={{marginLeft: 5, color: 'black'}}>{props.ticket.table_instance?.table?.table_number ? '| TABLE #' + props.ticket.table_instance.table.table_number : null}</span>
            </b>
          )}

          {props.ticket.from_kiosk_id && <Chip style={{borderRadius: 3, padding: 1}} size="small" label={props.ticket.from_kiosk_id} />}
        </ListItem>
        {props.ticket.note && props.ticket.note.length > 0 ? (
          <ListItem><b>{props.ticket.note}</b></ListItem>
        ) : (
          null
        )}
        <FilteredItems onItemUpdate={(payload) => props.onOrderItemUpdate(payload)} ticket={props.ticket} station={props.station} valueStation={props.valueStation} />
      </List>
     </CardContent>
     <CardActions style={{marginTop:0, paddingTop: 0, paddingLeft:14, paddingRight: 14}}>
     {props.ticket.status === 'processing' && props.ticket.phone  && !props.ticket.table_instance && props.valueStation === 'all' && (

       <Button disabled={sendSmsLoading} onClick={handleSendPickUpSMS} fullWidth variant="outlined" color="primary">
        {sendSmsLoading ? (
          'Sending...'
        ) : (
          <>{sendSmsLabel} {sendSmsLabel === 'Send Pickup Text' ? <SendIcon style={{marginLeft: 6, fontSize: 16}} /> : null}</>
        )}

       </Button>
     )}
     </CardActions>
     <CardActions style={{marginTop:0, paddingTop: 0, paddingLeft:14, paddingRight: 14}}>
      {props.ticket.status === 'processing' ?
      <>
      <Button variant="outlined" onClick={handlePrint}>PRINT</Button>
       <Button
        disableElevation
        disableRipple
        onClick={() => {
           if (sendSms && props.ticket.phone && !props.ticket.table_instance && props.valueStation === 'all' && props.value !== 3 ) {
             dispatch(sendSMSPickUp({number: props.number, phone: props.ticket.phone}));
           }
           handleStatusUpdate('done');
         }}
        fullWidth color="primary" variant="contained" elevation={0} size="medium"
       >
         {props.valueStation === 'all' ? 'Done' : props.value === 3 ? 'OPEN' : 'CLOSE'}
       </Button>
       </>
       :
        <React.Fragment>{undoBtn}</React.Fragment>}
     </CardActions>
   </Card>
  )
}

export default Ticket;
