import React from 'react';
import OrderTable from '../../features/order/OrderTable';
import { ErrorBoundary } from 'react-error-boundary';

const ErrorFallback = ({error, resetErrorBoundary}) => {
  return (
    <h1>Error!</h1>
  )
}

const OrderComponent = () => {
  // error handler for any errors
  const errorHandler = (error: Error, info: {componentStack: string}) => {
      console.log(error);
  }

  return (
    <div>
        <OrderTable />
    </div>
  );
};

export default OrderComponent;
