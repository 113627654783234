import React, {useState} from 'react';
import { IconButton, makeStyles, Grid, Chip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import { updateAddedItemQty } from '../../features/itemModal/itemModalSlice';
import { useDispatch } from 'react-redux';

const IncrementOptionButton = (props) => {
  const dispatch = useDispatch();
  const [qty, setQty] = useState(parseInt(props.number_value2));

  const increment = () => {

    setQty(props.number_value2 + 1);
    props.onUpdate(props.number_value2 + 1);
  };

  const decrement = () => {

    setQty(qty - 1);
    props.onUpdate(qty - 1);
    if (qty - 1 === 0 || qty - 1 < 0) {
      props.onEmpty();
      setQty(0);
    }
  };

  const modifiedQty = props.selected > 0 ? qty : null

  return (
      <Grid
        style={{marginBottom: 5}}
        container
        spacing={1}
        direction="row"
        alignItems="center">
        <Grid item>
          <IconButton style={{width: 40, background: 'transparent'}} size="small" onClick={() => decrement()}><RemoveCircleRoundedIcon htmlColor="#717885"  fontSize="large" /></IconButton>
        </Grid>
        <Grid item>
          <IconButton style={{width: 40, background: 'transparent'}} size="small" onClick={() => increment()}><AddCircleIcon htmlColor="#717885"   fontSize="large" /></IconButton>
        </Grid>
        <Grid item style={{position: 'relative', bottom: 0, marginRight: 10, fontSize: 13}}>
          <span>{props.number_value2 > 0 ? props.number_value2 : null} {qty ? props.incremental_name : null}</span> <span>{props.name}</span><br />
          <small style={{float: 'left',fontSize: 11.5}}>+${props.unit_price}</small>
        </Grid>
      </Grid>
    );
}

export default IncrementOptionButton;
