import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import {
  postAuthLogin, postAuthRefresh, selectRefreshToken, selectAccessToken,
  selectRefreshExpired, selectAccessTokenExpired, clearAll
} from './authSlice'

const Logout = (props) => {
  const history = useHistory();
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearAll());
    window.location.href = '/login'

  }, [])

  return null; // logout merely handles logout process
}

export default Logout;
