import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import FeedbackIcon from '@material-ui/icons/Feedback';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import CURRENT_SCREEN from './CurrentScreenEnums';

import {
  postPaymentIntent, cancelPaymentIntent, clearSecret, postConnectToken,
  postRemoveReader, selectSecret, postPairReader, setMyConnectedTerminal
} from '../paymentProcessing/paymentProcessingSlice'
import {loadStripeTerminal} from '@stripe/terminal-js';
import useCheckPaymentReceived from '../../hooks/useCheckPaymentReceived'
import { getSystemUser } from '../auth/authSlice';
import { getSplitsByOrderId, updatePmtIntent, clearAllSplits, getPmtStatus, postSplitPmtByItems, postSplitPmt, fetchSplitPmt, clearSplitPmt, getOrderById, setStatus } from '../order/orderSlice';
import { fetchTableForCancel, fetchTableForPayment, setPaymentComplete, setDialogOrder, fetchTable, clearTableInstance, fetchTables, deleteTable, getTableInstances, deactivateInstance, updateTableNumber, updateTableAvail, postTableInstance } from '../table/tableSlice';
import { useSelector, useDispatch } from 'react-redux';
import currency from 'currency.js'
import CircularProgress from '@material-ui/core/CircularProgress';

const SplitByItemsPayment = (props) => {
  const [checked, setChecked] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [total, setTotal] = useState(0);
  const [showPaidExistsAlert, setShowPaidExistsAlert] = useState(false)
  const dispatch = useDispatch();

  //const splitPayments = useSelector(state => state.order.splits);
  const status = useSelector(state => state.order.status);
  const paymentProcessingStatus = useSelector(state => state.paymentProcessing.status);
  const tableStatus = useSelector(state => state.table.status);
  const [errorMessage, setErrorMessage] = useState(false)
  const splitPayments = useSelector(state => getSplitsByOrderId(state, props.order?.id));
  const { received, partiallyReceived, reset, allUnpaid } = useCheckPaymentReceived(props.order);

  useEffect(() => {
    return () => {
      // on close..
      dispatch(fetchTable({pk: props.order?.table_instance?.table?.id}))
      setShowPaidExistsAlert(false);
    }
  }, [])


  useEffect(() => {
    if (paymentProcessingStatus === 'failed payment intent') {
      setIsLoading(false)
    }
  }, [paymentProcessingStatus])

  useEffect(() => {
    if (status === 'split removed') {
      props.onCancel();
    }
  }, [status])

  useEffect(() => {
    // 1. create a payment intent
    // 2. obtain a secret
    // 3. collect and capture intent

    // payment intent created (not yet collected)
    if (splitPayments?.length > 0 && status === 'split by items pmt added') {
      //setIsLoading(false);
      props.onCollectSplitByItems(
        splitPayments[splitPayments.length - 1].id,
        checked,
        currency(total))
    }
  }, [splitPayments, status])


  useEffect(() => {
    if (props.paymentComplete) {
      setTotal(0)
      setChecked([]);
      setIsLoading(false);
      dispatch(setPaymentComplete(false));
    }
  }, [props.paymentComplete])


  useEffect(() => {
    if (tableStatus === 'fetch table for cancel fulfilled') {
      props.onCancel();
    }
  }, [tableStatus])


  useEffect(() => {
    if (props.order) {
      if (props.order.order_payments.length > 0) {
        dispatch(fetchSplitPmt({id: props.order.id}))
      }
    }
  }, [props.order])

  //                    value == item.id
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      const index = props.order.order_items.findIndex(item => item.id === value);
      if (index != -1) {
        const value = props.order.order_items[index].get_total;
        const sum = currency(total).add(value);
        setTotal(sum.value);
      }
      newChecked.push(value);
    } else {
      const index = props.order.order_items.findIndex(item => item.id === value);
      if (index != -1) {
        const value = props.order.order_items[index].get_total;
        const sum = currency(total).subtract(value);
        setTotal(sum.value);
      }
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };


  const handleCancel = () => {

    // partially received
    if (partiallyReceived && !allUnpaid) {
      setShowPaidExistsAlert(true);
    } else {
      setShowPaidExistsAlert(false);

      if (props.order) {
        dispatch(clearSplitPmt({id: props.order.id}))
      }
    }
    setIsLoading(false);
  }

  const handleSplit = () => {
    setIsLoading(true);
    dispatch(postSplitPmtByItems({id: props.order.id, splits: [total], noReset: true}))
  }

  useEffect(() => {
    if (props.message != '') {
      setErrorMessage(false)
      setErrorMessage(true)
      setIsLoading(false)
    } else {
      setErrorMessage(false)
    }
  }, [props.message])

  /*
  The flow of the payment processing
  ------------------------------------------------------------------------------
  1. Create Payment intent
  2. Set order_item.paid = true
  3. Capture -- Signals props.paymentSucceeded
  4. Get updated split payment ~ [updated] splitPayments  (state.order.splits)
  5. Set payment intent ~ order_item.payment_intent = intent.id
  6. Get table ~ order gets updated too by setting checkoutDialogOrder
  */
  return (
    <div>
    <h2 style={{padding: 0, margin: 0}}>
      <IconButton onClick={
        () => {
          if (!isLoading) {
            received ? props.onTypeUpdate(CURRENT_SCREEN.HOME) : props.onTypeUpdate(CURRENT_SCREEN.HOME)
          }
        }
      }><KeyboardBackspaceIcon /></IconButton>
      Split By Items
    </h2>
    <div style={{padding: 20, paddingTop: 5}}>
    <Divider style={{marginBottom: 15}} />
    {errorMessage ? <MuiAlert style={{ marginBottom: 15, marginTop: 15, wordWrap: 'break-word', overflow: 'auto' }} severity="warning" elevation={0} variant="filled">{props.message}</MuiAlert> : null}
        {showPaidExistsAlert ? (
          <MuiAlert style={{marginBottom: 10}} variant="filled"  severity="warning">Please refund paid items before you cancel split</MuiAlert>
        ) : (
          null
        )}
        {received ? <h3 style={{textAlign: 'center', fontSize: 20, marginTop: 0, marginBottom:10, color: 'green'}}>PAYMENT RECEIVED</h3> : null}
        <div style={{padding:3, paddingLeft:0, maxHeight: 300, marginBottom: 12, overflowY: 'scroll'}}>
        <List>
              {props.order?.order_items?.map((item) => {
                const labelId = `checkbox-list-label-${item.id}`;
                return (
                  <ListItem
                    divider={true}
                    key={item.id}
                    role={undefined}
                    dense
                    button
                    onClick={!item.paid && tableStatus != 'fetch tables loading' ? handleToggle(item.id) : null}
                  >
                    <ListItemIcon>
                        {checked?.indexOf(item.id) !== -1 && isLoading ? (
                          <CircularProgress size={15} />
                        ) : (
                          !item.paid ? <Checkbox
                            edge="start"
                            checked={checked.indexOf(item.id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          /> : 'PAID'
                        )}
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={'(' + item?.quantity + ') ' + item?.item?.name }/>
                    <ListItemSecondaryAction>
                    {currency(item?.get_total).format()}
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
        </div>
        {!received ? (
          <Button
            disabled={isLoading || checked?.length == 0}
            style={{marginBottom: 10}}
            onClick={() => handleSplit()}
            fullWidth
            color="primary"
            variant="outlined">
            {isLoading && !props.paymentSucceeded ? <CircularProgress /> : currency(total).format()}
          </Button>
        ) : null}
        {isLoading && !received ? (
          <Button onClick={() => { setIsLoading(false); props.onCollectCancel() }} fullWidth color="secondary" variant="outlined">Cancel Payment</Button>
        ) : (
          received ? (
           null
          ) : (
            <Button  disabled={status === 'split removing' || tableStatus === 'fetch table for cancel loading'}  onClick={() => handleCancel()} fullWidth color="secondary" variant="outlined">
            {status !== 'split removing' || tableStatus !== 'fetch table for cancel loading' ? (
              'Cancel Split'
            ) : (
              'Cancelling...'
            )}
          </Button>

          )
        )}
      </div>
    </div>
  )
}

const SplitPayment = (props) => {
  const [isLoading, setIsLoading] = useState(-1)
  const [errorMessage, setErrorMessage] = useState(false)
  const [showPaidExistsAlert, setShowPaidExistsAlert] = useState(false)

  const dispatch = useDispatch();

  //const splitPayments = useSelector(state => state.order.splits);
  const splitPayments = useSelector(state => getSplitsByOrderId(state, props.order?.id));

  const orderStatus = useSelector(state => state.order.status);
  const tableStatus = useSelector(state => state.table.status);
  const paymentProcessingStatus = useSelector(state => state.paymentProcessing.status);
  const { received, partiallyReceived, reset, allUnpaid } = useCheckPaymentReceived(props.order);


  const handleCancel = () => {
    if (partiallyReceived && !allUnpaid) {
      setShowPaidExistsAlert(true);
    } else {
      if (props.order && isLoading === -1) {
        dispatch(clearSplitPmt({id: props.order.id}))
      }
    }
  }

  useEffect(() => {
    return () => {
      setShowPaidExistsAlert(false);
    }
  }, [])

  useEffect(() => {
    if (orderStatus === 'split removed') {
      dispatch(fetchTableForCancel({pk: props.order?.table_instance?.table?.id}))
      dispatch(setStatus('idle'))
    } else if (orderStatus === 'split pmt added') {
      dispatch(fetchTable({pk: props.order?.table_instance?.table?.id}))
      dispatch(setStatus('idle'))
    }
  }, [orderStatus])

  useEffect(() => {
    if (tableStatus === 'fetch table for cancel fulfilled') {
      props.onCancel();
    }

  }, [tableStatus])

  useEffect(() => {
    if (paymentProcessingStatus === 'failed payment intent') {
      setIsLoading(-1)
    }
  }, [paymentProcessingStatus])

  useEffect(() => {
    if (props.order) {
      if (props.order.order_payments.length > 0) {
        dispatch(fetchSplitPmt({id: props.order.id}))
      }
    }
  }, [props.order])


  useEffect(() => {
    if (props.paymentComplete) {
      setIsLoading(-1);
      dispatch(setPaymentComplete(false));
    }
  }, [props.paymentComplete])

  useEffect(() => {
    if (props.message != '') {
      setErrorMessage(false)
      setErrorMessage(true)
      setIsLoading(-1)
    } else {
      setErrorMessage(false)
    }
  }, [props.message])

  return (
    <div>
    <h2 style={{padding: 0, margin: 0}}>
      <IconButton onClick={() => {received ? props.onTypeUpdate(CURRENT_SCREEN.HOME) : handleCancel()}}><KeyboardBackspaceIcon /></IconButton>
      Split Payment
    </h2>
    <div style={{padding: 20, paddingTop: 5}}>
    <Divider style={{marginBottom: 15}} />
    {errorMessage ? <MuiAlert style={{ marginBottom: 15, marginTop: 15, wordWrap: 'break-word', overflow: 'auto' }} severity="warning" elevation={0} variant="filled">
       <span>{props.message}</span>
      </MuiAlert> : null}
        {showPaidExistsAlert ? (
          <MuiAlert style={{marginBottom: 20}} variant="filled"  severity="warning">
            Please refund paid items before you cancel split
          </MuiAlert>
        ) : (
          null
        )}
        {props.order ? <h3 style={{textAlign: 'center', fontSize: 40, marginTop: -10, marginBottom: 10}}>{currency(currency(props.order.surcharge)).format()}</h3> : null}
        {received ? <h3 style={{textAlign: 'center', fontSize: 20, marginTop: 0, color: 'green'}}>PAYMENT RECEIVED</h3> : null}
        <div style={{padding:3, paddingLeft:0, maxHeight: 300, overflowY: 'scroll', marginBottom: 12}}>
        {splitPayments.map(payment => (
          <Button
            key={payment.id}
            onClick={() => { setIsLoading(payment.id); props.onCollectSplit(payment.id, currency(payment.total))}}
            fullWidth
            style={{marginBottom: 7}}
            variant="outlined"
            color="primary"
            disabled={isLoading === payment.id || payment.paid ? true : false }
          >
          {payment.refunded_amount > 0 ? '[REFUNDED] ' : null}
          {payment.paid && payment.refunded_amount == 0 ? '[PAID] ' : null}
            {isLoading === payment.id && !payment.paid? <CircularProgress /> : '$' + payment.total}
        </Button>
        ))}
        </div>
        {isLoading !== -1 && !received ? (
          <Button onClick={() => {setIsLoading(-1); props.onCollectCancel()}} fullWidth color="secondary" variant="outlined">Cancel Payment</Button>
        ) : (
          received ? (
            null
          ) : (
            <Button disabled={orderStatus === 'split removing' || tableStatus === 'fetch table for cancel loading'} onClick={() => handleCancel()} fullWidth color="secondary" variant="outlined">
              {orderStatus !== 'split removed' || tableStatus !== 'fetch table for cancel loading' ? (
                'Cancel Split'
              ) : (
                'Cancelling...'
              )}
            </Button>
          )
        )}
      </div>
    </div>
  )
}

const CheckOut = (props) => {
  const dispatch = useDispatch()
  const [terminalId, setTerminalId] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [isSplitPayment, setIsSplitPayment] = React.useState(false);
  const [isSplitByItemsPayment, setIsSplitByItemsPayment] = React.useState(false);
  const [message, setMessage] = React.useState(props.message)
  const splitPayments = useSelector(state => state.order.splits);
  const status = useSelector(state => state.order.status);
  const [numSplit, setNumSplit] = React.useState(2);
  const [errorMessage, setErrorMessage] = useState(false)
  const secret = useSelector(state => state.paymentProcessing.secret)
  const paymentProcessingStatus = useSelector(state => state.paymentProcessing.status)
  const { received } = useCheckPaymentReceived(props.order);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isConnected, setIsConnected] = React.useState(false);

  useEffect(() => {
    if (props.message != '') {
      setErrorMessage(false)
      setErrorMessage(true)
      setIsLoading(false)
    } else {
      setErrorMessage(false)
    }
  }, [props.message])

  useEffect(() => {
    return () => {
      dispatch(setDialogOrder(null));
      setIsSplitPayment(false);
      setIsSplitByItemsPayment(false);
      setIsLoading(false)
      setIsConnected(false)
      setMessage('')
      handleClose();
    }

  }, [])

  useEffect(() => {
    if (props.terminals) setTerminalId(props.terminals[0]?.id);
  }, [props.terminals])

  useEffect(() => {
    if (terminalId) {
      handleReaderConnect();
    }
  }, [terminalId])

  useEffect(() => {
    if (props.order) {
      const type = props.order?.type;
      if (type === 'by_items') {
        setIsSplitPayment(false)
        setIsSplitByItemsPayment(true)
      } else if (type === 'n_ways') {
        setIsSplitPayment(true)
        setIsSplitByItemsPayment(false)
      } else {
        setIsSplitPayment(false)
        setIsSplitByItemsPayment(false)
      }
    }
  }, [props.order, props.order?.type])

  useEffect(() => {
    // called when captured intent -- updates a table
    if (paymentProcessingStatus === 'captured intent') {
      //dispatch(fetchTableForPayment({pk: props.order?.table_instance?.table?.id}))
    } else if (paymentProcessingStatus === 'failed payment intent') {
      setIsLoading(false)
      setMessage('Something went wrong. Check the checkout amount.')
    }
  }, [paymentProcessingStatus])

  const handleReaderConnect = async () => {
    const index = props.terminals?.findIndex(terminal => terminal.id === terminalId);
    if (index != -1) {
      props.terminal?.disconnectReader()
      //_terminal.disconnectReader();
        props.terminal?.connectReader(props.terminals[index]).then(function(connectResult) {
        if (connectResult.error) {
          setIsConnected(false)
          console.log('Failed to connect: ', connectResult.error);
        } else {
          setIsConnected(true)
          console.log('Connected to reader: ', connectResult.reader.label);
        }
      });
    }
  }

  const handleChange = (event) => {
    setTerminalId(event.target.value);
  };

  const handleChange2 = (event) => {
    setNumSplit(event.target.value);
  };

  const handleClose = () => {
    dispatch(clearAllSplits())
    dispatch(setDialogOrder(null))
    setMessage('')
    props.handleClose();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCheckout = () => {
    setIsLoading(true)
    props.onCollect(props.order.id)
  }

  const handleSplit = (noReset) => {
    const splits = currency(props.order.surcharge).distribute(numSplit);
    dispatch(postSplitPmt({id: props.order.id, splits: splits, noReset: noReset}))
  }


    if (!props.order) {
      return (
        <div>
          <h2 style={{padding: 0, margin: 0}}>
            <IconButton onClick={() => {handleClose(); props.onTypeUpdate(CURRENT_SCREEN.HOME)}}><KeyboardBackspaceIcon /></IconButton>
            Checkout
          </h2>
          <div style={{padding: 20, paddingTop: 5, height: 162}}>
          <Divider style={{marginBottom: 15}} />

            <p style={{marginTop: 55, textAlign: 'center'}}>Order not found <br /> <small>Please assign an order from <i>View Order</i></small></p>
          </div>
        </div>
      )
    } else if (received && !isSplitPayment && !isSplitByItemsPayment) {
      return (
        <div>
        <h2 style={{padding: 0, margin: 0}}>
          <IconButton onClick={() => props.onTypeUpdate(CURRENT_SCREEN.HOME)}><KeyboardBackspaceIcon /></IconButton>
          Checkout
        </h2>
        <div style={{padding: 20, paddingTop: 5}}>
        <Divider style={{marginBottom: 15}} />

          {props.order ? <h3 style={{textAlign: 'center', fontSize: 40, marginTop: 0, marginBottom: 20}}>{currency(currency(props.order.surcharge)).format()}</h3> : null}
            <h3 style={{textAlign: 'center', fontSize: 20, marginTop: 0, color: 'green'}}>
              PAYMENT RECEIVED
            </h3>
          </div>
        </div>
      )
    } else if (!isSplitPayment && !isSplitByItemsPayment) {
      return (
        <div>
        <h2 style={{padding: 0, margin: 0}}>
          <IconButton onClick={() => props.onTypeUpdate(CURRENT_SCREEN.HOME)}><KeyboardBackspaceIcon /></IconButton>
          Checkout
        </h2>
        <div style={{padding: 20, paddingTop: 5}}>
        <Divider style={{marginBottom: 15}} />
        {errorMessage ? <MuiAlert style={{ marginBottom: 15, marginTop: 15, wordWrap: 'break-word', overflow: 'auto' }} severity="warning" elevation={0} variant="filled">
           <span>{props.message}</span>
          </MuiAlert> : null}
          {message ? <MuiAlert style={{marginBottom: 15, marginTop: 15}} severity="warning" elevation={0} variant="filled">{message}</MuiAlert> : null}

          {props.order ? <h3 style={{textAlign: 'center', fontSize: 40, marginTop: 0, marginBottom: 20}}>{currency(currency(props.order.surcharge)).format()}</h3> : null}
          {received && status !== 'split removing' ? (
            <h3 style={{textAlign: 'center', fontSize: 20, marginTop: 0, color: 'green'}}>
              PAYMENT RECEIVED
            </h3>
          ) : (
            null
          )}
          {props.order && !props.order?.stripe_payment_intent || status !== 'split removing' ? (
            <>
            <FormControl size="small" fullWidth>
              <Select
                variant="outlined"
                labelId="terminal-label"
                id="terminal-open-select"
                open={open}
                onClose={() => {setOpen(false)}}
                onOpen={handleOpen}
                value={props.terminals ? terminalId : null}
                onChange={handleChange}
              >
                {props.terminals?.map(terminal => <MenuItem key={terminal.id} value={terminal.id}>{terminal.label}</MenuItem>)}
              </Select>
            </FormControl>
            <hr style={{marginTop: 10, marginBottom: 10}} />
            </>
          ) : (
            null
          )}
          {props.order && !props.order?.stripe_payment_intent || status !== 'split removing' ? <><FormControl size="small" fullWidth>
         <Select
           disabled={isLoading || !isConnected ? true : false}
           variant="outlined"
           labelId="demo-simple-select-placeholder-label-label"
           id="demo-simple-select-placeholder-label"
           value={numSplit}
           onChange={handleChange2}
           displayEmpty
         >
           <MenuItem value={2}>Split into 2</MenuItem>
           <MenuItem value={3}>Split into 3</MenuItem>
           <MenuItem value={4}>Split into 4</MenuItem>
           <MenuItem value={5}>Split into 5</MenuItem>
           <MenuItem value={6}>Split into 6</MenuItem>
           <MenuItem value={7}>Split into 7</MenuItem>
           <MenuItem value={8}>Split into 8</MenuItem>
           <MenuItem value={9}>Split into 9</MenuItem>
           <MenuItem value={10}>Split into 10</MenuItem>
           <MenuItem value={11}>Split into 11</MenuItem>
           <MenuItem value={12}>Split into 12</MenuItem>
           <MenuItem value={13}>Split into 13</MenuItem>
           <MenuItem value={14}>Split into 14</MenuItem>
           <MenuItem value={15}>Split into 15</MenuItem>
           <MenuItem value={16}>Split into 16</MenuItem>
           <MenuItem value={17}>Split into 17</MenuItem>
           <MenuItem value={18}>Split into 18</MenuItem>
           <MenuItem value={19}>Split into 19</MenuItem>
           <MenuItem value={20}>Split into 20</MenuItem>
         </Select>
       </FormControl>
       <Button  disabled={isLoading || !isConnected ? true : false} onClick={() => {setIsSplitPayment(true);setIsSplitByItemsPayment(false); handleSplit(false);}} fullWidth variant="outlined" color="primary">SPLIT EVENLY</Button>
       <hr style={{marginTop: 10, marginBottom: 10}} /></>
       :
       null}
       {props.order && !props.order?.stripe_payment_intent || status !== 'split removing' ? (
        <>
          <Button  disabled={isLoading || !isConnected ? true : false} onClick={() => {setIsSplitByItemsPayment(true);setIsSplitPayment(false)}} fullWidth variant="outlined" color="primary">SPLIT BY ITEMS</Button>
          <hr style={{marginTop: 10, marginBottom: 10}} />
        </>
        ) : (null)}
       {props.order && !props.order?.stripe_payment_intent || status !== 'split removing' ? (
         <Button disabled={isLoading || !isConnected ? true : false} onClick={handleCheckout} fullWidth variant="contained" color="primary">CHECKOUT</Button>
       ) : (
         null
       )}
          </div>
        </div>
      )
    } else if (isSplitPayment && props.order) {
      return (<SplitPayment paymentComplete={props.paymentComplete} onTypeUpdate={(type) => { props.onTypeChange(type);setIsSplitPayment(false);setIsSplitByItemsPayment(false)}} message={props.message} onCollectCancel={() => props.onCollectCancel()} onCollectSplit={(id, total) => props.onCollectSplit(id, total)} numSplits={numSplit} onCancel={() => setIsSplitPayment(false)} handleClose={props.handleClose} order={props.order}  />)
    } else if  (isSplitByItemsPayment && props.order)  { // isSplitPayment
      return (<SplitByItemsPayment  paymentComplete={props.paymentComplete} onTypeUpdate={(type) => { props.onTypeChange(type);setIsSplitPayment(false);setIsSplitByItemsPayment(false)}} message={props.message} onCollectCancel={() => props.onCollectCancel()} onCollectSplitByItems={(id, items, total) => props.onCollectSplitByItems(id, items, total)} numSplits={numSplit} onCancel={() => setIsSplitByItemsPayment(false)} handleClose={props.handleClose} order={props.order} />)
    } else {
      return (
        <div>
        </div>
      )
    }

}

export default CheckOut
