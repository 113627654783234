import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid,
    Tooltip, Legend, ResponsiveContainer, LabelList,
    PieChart, Pie, Sector, Cell,
    ComposedChart, Line, Area,
} from 'recharts';
import { getFranchisedShops, getSystemUser } from '../auth/authSlice';
import { fetchItemsTopSellingReportCustom, fetchIdentifiers, fetchItemsTopSellingReport, fetchSalesCardsReport, fetchSalesCardsReportCustom, fetchSalesReport, fetchSalesReportByShop, selectReportDataArr } from './orderSlice';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CategoryIcon from '@material-ui/icons/Category';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import {
  DateTimePicker ,
} from '@material-ui/pickers';
import CircularProgress from '@material-ui/core/CircularProgress';
import ViewAgendaOutlinedIcon from '@material-ui/icons/ViewAgendaOutlined';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ItemsReport from './ItemsReport';
import CategoriesReport from './CategoriesReport';
import AddonReport from './AddonReport';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 500,
    width: 800,
    [theme.breakpoints.down('md')]: {
      width: 660
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100%)`,
    },
  },
  root2: {
    width: 800,
    [theme.breakpoints.down('md')]: {
      width: 660
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100%)`,
    },
  },
  appbar: {
    width: 800,
    [theme.breakpoints.down('md')]: {
      width: 660
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100%)`,
    },
  },
}));

/*
const data = [
    {
      name: '9/7/2021',
      Revenue: 200,
      Sold: 20,
      Tip: 3
    },
    {
      name: '9/8/2021',
      Revenue: 200,
      Sold: 30,
      Tip: 3
    },
    {
      name: '9/9/2021',
      Revenue: 200,
      Sold: 40,
      Tip: 3
    },
    {
      name: '9/10/2021',
      Revenue: 200,
      Sold: 60,
      Tip: 3
    },
    {
      name: '9/11/2021',
      Revenue: 200,
      Sold: 10,
      Tip: 3
    },
];
*/


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Paper square>
          {children}
        </Paper>
      )}
    </div>
  );
}

const ReportsAdvancedHeader = (props) => {

  return (
    <div >
    <Paper style={{width: '100%', height: 45,  overflow:'auto', borderRadius: 10, paddingLeft: 7.5, padding: 6, display: 'flex'}}>
    <Button
      size="large"
      variant="text"
      disableElevation
      style={{ marginRight: 5, fontSize: 14, flex: '0 0 auto', display:'flex', justifyContent: 'center', backgroundColor: props.type == 1 ? 'rgba(0,0,0,0.05)' : 'inherit', color: props.type == 1 ? 'black' : 'grey'}}
      onClick={() => props.onSettingsTypeUpdate(1)}>
      <ViewAgendaOutlinedIcon style={{fontSize: 16}} /> &nbsp; General Report
    </Button>
    <Button
      size="large"
      variant="text"
      disableElevation
      style={{ marginRight: 5, fontSize: 14, flex: '0 0 auto', display:'flex', justifyContent: 'center', backgroundColor: props.type == 2 ? 'rgba(0,0,0,0.05)' : 'inherit', color: props.type == 2 ? 'black' : 'grey'}}
      onClick={() => props.onSettingsTypeUpdate(2)}>
      <DescriptionOutlinedIcon style={{fontSize: 16}} /> &nbsp; Items Report
    </Button>
    <Button
      size="large"
      variant="text"
      disableElevation
      style={{ marginRight: 5, fontSize: 14, flex: '0 0 auto', display:'flex', justifyContent: 'center', backgroundColor: props.type == 3 ? 'rgba(0,0,0,0.05)' : 'inherit', color: props.type == 3 ? 'black' : 'grey'}}
      onClick={() => props.onSettingsTypeUpdate(3)}>
      <FormatListBulletedOutlinedIcon style={{fontSize: 16}} /> &nbsp; Categories Report
    </Button>
    <Button
      size="large"
      variant="text"
      disableElevation
      style={{ marginRight: 5, fontSize: 14, flex: '0 0 auto', display:'flex', justifyContent: 'center', backgroundColor: props.type == 4 ? 'rgba(0,0,0,0.05)' : 'inherit', color: props.type == 4 ? 'black' : 'grey'}}
      onClick={() => props.onSettingsTypeUpdate(4)}>
      <CategoryIcon style={{fontSize: 16}} /> &nbsp; Add-on Report
    </Button>
    </Paper>
    </div>
  )
}


const ReportHeader = (props) => {
  return (
    <div >
    <Paper style={{width: '100%', borderTopLeftRadius: 0, borderTopRightRadius: 0,   overflow:'auto',  display: 'flex'}}>
    <Button
      variant="text"
      disableElevation
      style={{ flex: '0 0 auto', color: props.active == 'Today' ? 'black' : 'grey'}}
      onClick={() => props.onDateUpdate('Today')}>
      Today
    </Button>
    <Button
    style={{ flex: '0 0 auto', color: props.active == 'Yesterday' ? 'black' : 'grey'}}
    variant="text"
      onClick={() => props.onDateUpdate('Yesterday')}
      disableElevation>
      Yesterday
    </Button>

    <Button
    style={{ flex: '0 0 auto', color: props.active == 'This Month' ? 'black' : 'grey'}}
    variant="text"
    onClick={() => props.onDateUpdate('This Month')}
      disableElevation>
      This Month
    </Button>

    <Button
    variant="text"
    style={{ flex: '0 0 auto', color: props.active == 'Last Month' ? 'black' : 'grey'}}
    onClick={() => props.onDateUpdate('Last Month')}
      disableElevation>
      Last Month
    </Button>

      <Button
        variant="text"
        onClick={() => props.onDateUpdate('Custom')}
        disableElevation
        style={{  flex: '0 0 auto', color: props.active == 'Custom' ? 'black' : 'grey'}}>
        Custom
      </Button>

      </Paper>
      {props.active === 'Custom' ? (
        <Grid style={{marginTop: 7}} spacing={1} container>
          <Grid item xs>
        <DateTimePicker
          style={{background: 'white'}}
          fullWidth
          inputVariant="outlined"
          name="start_date"
          label="Start Date"
          placeholder="2018/10/30"
          value={props.startDate}
          size="small"
          onChange={date => props.onStartDateUpdate(date)}
          format="yyyy/MM/DD HH:mm"
          showTodayButton
        />
        </Grid>
          <Grid item xs>
            <DateTimePicker
              fullWidth
              style={{background: 'white'}}
              inputVariant="outlined"
              name="end_date"
              label="End Date"
              placeholder="2025/10/30 03:02"
              value={props.endDate}
              size="small"
              onChange={date => props.onEndDateUpdate(date)}
              format="yyyy/MM/DD HH:mm"
              showTodayButton
            />
          </Grid>
        </Grid>
      ) : (
        null
      )}
    </div>

  )
}


const IdentifierHeader = (props) => {
  return (
    <div >
    <Paper style={{width: '100%', height: 40, borderBottomLeftRadius: 0, borderBottomRightRadius: 0,  borderBottom: '1px solid rgba(0,0,0,0.1)',   overflow:'auto',  display: 'flex'}}>
      <Button
         variant="text"
         disableElevation
         style={{ flex: '0 0 auto', fontSize: 15, color: props.activeIdentifier == 'All' ? 'black' : 'grey'}}
         onClick={() => props.onActiveIdentifierUpdate('All')}>
         all
       </Button>
      {props.identifiers?.map((identifier, index) => (
         <Button
         key={index}
         variant="text"
         disableElevation
         style={{ flex: '0 0 auto', fontSize: 15,  color: props.activeIdentifier == identifier ? 'black' : 'grey'}}
         onClick={() => props.onActiveIdentifierUpdate(identifier)}>
         {identifier?.replace(/\b\w/g, char => char.toUpperCase())}
       </Button>
      ))}
      </Paper>
    </div>

  )
}

const labelFormatter = (value) => {
  return (
    value
  )
}

const tickFormatter = (value, index) => {

  const limit = 40; // put your maximum character
  if (value.length < limit) return value;
  return `${value.substring(0, limit)}...`;
};

const SalesReport = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const pin = useSelector(state => state.auth.user?.shop?.admin_pin)
  const data = useSelector(selectReportDataArr);
  const user = useSelector(state => state.auth.user);
  const total = useSelector(state => state.order.total);
  const tip = useSelector(state => state.order.tip);
  const tax = useSelector(state => state.order.tax);
  const gross = useSelector(state => state.order.gross);
  const refund = useSelector(state => state.order.refund);
  const topSellingData = useSelector(state => state.order.rankedSoldItems);
  const status = useSelector(state => state.order.status);
  const avgTicketSize = useSelector(state => state.order.avgTicketSize);
  const ticketCount = useSelector(state => state.order.ticketCount);
  const franchisedShops = useSelector(state => state.auth.franchisedShops);
  const chargedCustomers = useSelector(state => state.auth.chargeCustomers);
  const identifiers = useSelector(state => state.order.identifiers);

  const [value, setValue] = React.useState(0);
  const [currentShop, setCurrentShop] = useState(null)
  const [num, setNum] = useState(1);
  const [num2, setNum2] = useState(1);
  const [active, setActive] = useState('Today')
  const [activeIdentifier, setActiveIdentifier] = useState('All')

  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [topSellingLoading, setTopSellingLoading] = useState(true)
  const [settingsType, setSettingsType] = useState(1);
  const [pinValidated, setPinValidated] = useState(false);
  const [enteredPin, setEnteredPin] = useState('');
  const [error, setError] = useState('');

  const correctPin = String(pin); // Replace with your actual PIN logic

  const handlePinSubmit = () => {
    if (enteredPin === correctPin) {
      setPinValidated(true);
      setError('');
    } else {
      setError('Invalid PIN. Please try again.');
    }
  };



  useEffect(() => {
    startDate?.setHours(
      0
    )

    startDate?.setMinutes(
      0
    )

    endDate?.setHours(
      23
    )

    endDate?.setMinutes(
      59
    )

    if (user?.shop?.type == 'franchiser') {
      dispatch(fetchSalesCardsReport({type: 'Today', shopId: user?.shop?.id}))
      setTimeout(() => {
        dispatch(fetchItemsTopSellingReport({type: 'Today', shopId: user?.shop?.id}))
      }, 500)
    }

    dispatch(fetchIdentifiers())
  }, []);

  useEffect(() => {
    if (status === 'top selling succeeded') {
      setTopSellingLoading(false)
    } else if (status === 'top selling loading') {
      setTopSellingLoading(true)
    }
  }, [status])

  useEffect(() => {
    if (user != null) {
      if (user?.shop?.type == 'franchiser') {
        dispatch(getFranchisedShops({franchise_id: user?.shop?.franchise?.id}))
      }
      if (currentShop != user?.shop?.id) {
        setCurrentShop(user?.shop?.id)
      }

    }

  }, [user])

  useEffect(() => {
    if (user?.shop?.type == 'franchiser' && currentShop != null) {
      dispatch(fetchSalesReportByShop({num: num2, shopId: currentShop}))
      if (active != 'Custom') {
        dispatch(fetchSalesCardsReport({type: active, shopId: currentShop}))
        setTimeout(() => {
          dispatch(fetchItemsTopSellingReport({type: active, shopId: currentShop}))
        }, 500)
      } else if (active == 'Custom') {
        dispatch(fetchSalesCardsReportCustom({shopId: currentShop, type: active, start_date: startDate.toISOString(), end_date: endDate.toISOString()}))
        setTimeout(() => {
          dispatch(fetchItemsTopSellingReportCustom({shopId: currentShop, type: active, start_date: startDate.toISOString(), end_date: endDate.toISOString()}))
        }, 500)
      }
    }
  }, [currentShop])


  useEffect(() => {
    if (num > 0) {
      dispatch(fetchSalesReport({num: num, activeIdentifier: activeIdentifier}));
    }
  }, [num, activeIdentifier])

  useEffect(() => {
    if (active != null) {
      if (active != 'Custom') {
        if (currentShop) {
          dispatch(fetchSalesCardsReport({type: active, shopId: currentShop, activeIdentifier: activeIdentifier}))
          setTimeout(() => {
            dispatch(fetchItemsTopSellingReport({type: active, shopId: currentShop, activeIdentifier: activeIdentifier}))
          }, 500)
        } else {
          dispatch(fetchSalesCardsReport({type: active, activeIdentifier: activeIdentifier}))
          setTimeout(() => {
            dispatch(fetchItemsTopSellingReport({type: active, activeIdentifier: activeIdentifier}))
          }, 500)
        }
      } else {
        if (currentShop) {
          dispatch(fetchSalesCardsReportCustom({ shopId: currentShop, type: active, start_date: startDate.toISOString(), end_date: endDate.toISOString(), activeIdentifier: activeIdentifier}))
          setTimeout(() => {
            dispatch(fetchItemsTopSellingReportCustom({ shopId: currentShop, type: active, start_date: startDate.toISOString(), end_date: endDate.toISOString(), activeIdentifier: activeIdentifier}))
          }, 500)

        } else {
          dispatch(fetchSalesCardsReportCustom({type: active, start_date: startDate.toISOString(), end_date: endDate.toISOString(), activeIdentifier: activeIdentifier}))
          setTimeout(() => {
            dispatch(fetchItemsTopSellingReportCustom({type: active, start_date: startDate.toISOString(), end_date: endDate.toISOString(), activeIdentifier: activeIdentifier}))
          }, 500)

        }
      }
    }

  }, [active, activeIdentifier, startDate, endDate])


  useEffect(() => {
    if (num2 > 0 && user?.shop?.type == 'franchiser') {
        dispatch(fetchSalesReportByShop({num: num2, shopId: currentShop}))
    }
  }, [num2])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePrevious = () => {
    setNum(num + 1);
  }

  const handleNext = () => {
    if (num > 0) {
      setNum(num - 1);
    }
  }

  const handlePrevious2 = () => {
    setNum2(num2 + 1);
  }

  const handleNext2 = () => {
    if (num2 > 0) {
      setNum2(num2 - 1);
    }
  }

  if (!pinValidated && pin) {
    return (
      <div>
        <Paper elevation={1} style={{ padding: '20px', paddingTop: 5 }}>
          <h3 style={{fontWeight: 500}}>Enter PIN
          </h3>
          <TextField
          focused
          fullWidth
            type="password"
            label="PIN"
            value={enteredPin}
            onChange={(e) => setEnteredPin(e.target.value)}
            autoFocus
            helperText="HINT: Date of Birth (yyyymmdd;19941225)"
          />
          <br />
          <Button 
            fullWidth
            variant="contained" 
            color="primary" 
            onClick={handlePinSubmit}
            style={{ marginTop: 10 }}
          >
            Submit
          </Button>
          {error && <p style={{ color: 'red', marginTop: '10px' }}>{error}</p>}
        </Paper>
      </div>
    );
  }

  let content = null;
  if (settingsType === 1) {
    content = <>
          {identifiers?.length > 0 && <IdentifierHeader onActiveIdentifierUpdate={setActiveIdentifier} activeIdentifier={activeIdentifier} identifiers={identifiers} />}
    <ReportHeader
        active={active}
        onDateUpdate={(date) =>
            setActive(date)
        }
        startDate={startDate}
        endDate={endDate}
        onStartDateUpdate={(date) => setStartDate(date)}
        onEndDateUpdate={(date) => setEndDate(date)}
      />
        <Grid
        container
        direction="column"
        justifyContent="center"
        >
        <Grid spacing={1} container alignItems="center" justifyContent="flex-start" item xs style={{marginTop: 4}}>

          <Grid item xs={6} md={3}>
            <Card>
              <CardContent>
              <h3>Sales <small style={{fontSize: 10, fontWeight: 400}}>Subtotal + Tax</small></h3>
              <p style={{fontSize: 20}}>${total}</p>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={3}>
            <Card>
              <CardContent>
              <h3>Gross Sales </h3>
              <p style={{fontSize: 20}}>${gross}</p>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={3}>
            <Card>
              <CardContent>
              <h3>Tax</h3>
              <p style={{fontSize: 20}}>${tax}</p>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={3}>
            <Card>
              <CardContent>
              <h3>Tip</h3>
              <p style={{fontSize: 20}}>${tip}</p>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}  md={3}>
            <Card>
              <CardContent>
              <h3>Refunds</h3>
              <p style={{fontSize: 20}}>${refund}</p>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}  md={3}>
            <Card>
              <CardContent>
              <h3>Avg. Ticket Size</h3>
              <p style={{fontSize: 20}}>${avgTicketSize}</p>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}  md={3}>
            <Card>
              <CardContent>
              <h3>Number of Sales</h3>
              <p style={{fontSize: 20}}>{ticketCount}</p>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
          <Grid item xs={12} style={{marginTop: 15}}>
            <Paper style={{height: topSellingData?.length > 15 ? 1200 : 600, padding: 30,borderRadius: 10 }}>
              {topSellingLoading ? (
                  <div style={{height: 600, margin: '0 auto', paddingTop: 195, textAlign: 'center'}}>
                    <CircularProgress style={{margin: '0 auto'}} />
                    <h4 style={{fontWeight: 300}}>LOADING BEST SELLING ITEMS</h4>
                  </div>
              ) : (
                  topSellingData?.length > 0 ? (
                      <ResponsiveContainer width="99%" >
                        <ComposedChart
                            layout="vertical"
                            data={topSellingData}
                            margin={{
                              top: 20,
                              right: 20,
                              bottom: 20,
                              left: 20,
                            }}
                        >
                          <XAxis allowDecimals={false} orientation="bottom" type="number" />
                          <YAxis interval={0} width={topSellingData?.length > 0 ? 150 : 30}
                                 tickFormatter={tickFormatter} dataKey="name" type="category" scale="auto" />

                          <Tooltip />
                          <CartesianGrid stroke="#f5f5f5" />
                          <Bar dataKey="quantity_sum" name="Quantity Sold" barSize={39} fill="#413ea0">
                            <LabelList style={{fill: 'white'}} formatter={labelFormatter} dataKey="quantity_sum"  position="inside" />
                          </Bar>
                          {topSellingData?.length > 0 ? <Legend /> : null}

                        </ComposedChart>
                      </ResponsiveContainer>
                      ) : (
                          <h3 style={{paddingTop: 220, textAlign: 'center'}}>NO DATA FOUND FOR TOP SELLING ITEMS</h3>
                      )
              )}
            </Paper>
          </Grid>
        <Grid item xs={12}>
        <Paper
          style={{height: 550, marginTop:30,  padding: 30, paddingLeft: 5, paddingTop: 50, paddingBottom: 70}}>
        <ResponsiveContainer width="99%">
          <BarChart
            data={data}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="datetime" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Revenue" fill="#538abd">
              <LabelList formatter={value => value > 0 ? '$' + value : null} style={{fill: 'black'}} dataKey="Revenue"  position="top" />
            </Bar>
            <Bar dataKey="Sold" fill="grey">
              <LabelList offset={5} formatter={value => value > 0 ? value : null} style={{fill: 'grey'}} dataKey="Sold"  position="top" />
            </Bar>

            <Bar dataKey="Tickets" fill="blue">
            <LabelList offset={5}  formatter={value => value > 0 ? value : null} style={{fill: 'blue'}} dataKey="Tickets" position="top" />

            </Bar>

            <Bar dataKey="Tip" fill="black">
            <LabelList offset={5} formatter={value => value > 0 ? '$' + value : null} style={{fill: 'black'}} dataKey="Tickets"  position="top" />

            </Bar>

            <Bar dataKey="Refunded" fill="red">
            <LabelList offset={5} formatter={value => value > 0 ? '$' + value : null} style={{fill: 'red'}} dataKey="Refunded"  position="top" />

            </Bar>


          </BarChart>
          </ResponsiveContainer>
          <Grid
            style={{paddingLeft: 20, marginTop: 10}}
            container
            direction="row"
            justifyContent="space-around"
            alignItems="flex-end"
          >
            <Grid item xs>
              <div>
              <Button onClick={handlePrevious} aria-label="previous">
                <NavigateBeforeIcon /> Previous
              </Button>
              </div>
            </Grid>
            <Grid item xs>
              <div style={{float: 'right'}}>
                  {num === 1 ?
                    <Button disabled aria-label="next">
                      Next
                      <NavigateNextIcon />
                    </Button> :
                    <Button onClick={handleNext} aria-label="next">
                      Next
                      <NavigateNextIcon />
                    </Button>
                  }
              </div>
            </Grid>
          </Grid>
        </Paper>
        </Grid>
        </Grid>
    </>
  } else if (settingsType === 2) {
    content = <ItemsReport />;
  } else if (settingsType == 3) {
    content = <CategoriesReport />;
  } else {
    content = <AddonReport />;
  }

  return (
    <React.Fragment>
    {user?.shop?.type == 'franchiser' ? (
      <>
      <div  className={classes.appbar}>
        
      <AppBar position="static" color="white" elevation={1}>
         <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
             indicatorColor="primary"
             textColor="primary"
             aria-label="scrollable tabs for franchise access control"
         >
           <Tab onClick={() => setCurrentShop(user?.shop?.id)} key={user?.shop?.id} label={'All'} />
           {franchisedShops?.map(
            shop => {
              return user.shop.id != shop.id ? (
                <Tab onClick={() => setCurrentShop(shop.id)} key={shop.id} label={shop.business_name + ' ' + shop.city}/>
              ) : (
                null
              )
            }
          )}
         </Tabs>
      </AppBar>
      </div>
      <div className={classes.root2}>
      <br />
      <ReportHeader
        active={active}
        onDateUpdate={(date) =>
            setActive(date)
        }
        startDate={startDate}
        endDate={endDate}
        onStartDateUpdate={(date) => setStartDate(date)}
        onEndDateUpdate={(date) => setEndDate(date)}
      />
        <Grid
        container
        direction="column"
        justifyContent="center"
        >
        <Grid container alignItems="center" justifyContent="space-between" item xs style={{marginTop: 4}}>
          <Grid item xs={6} md={3}>
            <Card square>
              <CardContent>
              <h3>Sales <small style={{fontSize: 10, fontWeight: 400}}>Subtotal + Tax</small></h3>
              <p style={{fontSize: 20}}>${total}</p>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={3}>
            <Card square>
              <CardContent>
              <h3>Tax</h3>
              <p style={{fontSize: 20}}>${tax}</p>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={3}>
            <Card square>
              <CardContent>
              <h3>Tip</h3>
              <p style={{fontSize: 20}}>${tip}</p>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}  md={3}>
            <Card square>
              <CardContent>
              <h3>Refunds</h3>
              <p style={{fontSize: 20}}>${refund}</p>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={6}  md={3}>
            <Card square>
              <CardContent>
              <h3>Avg. Ticket Size</h3>
              <p style={{fontSize: 20}}>${avgTicketSize}</p>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}  md={3}>
            <Card square>
              <CardContent>
              <h3>Number of Sales</h3>
              <p style={{fontSize: 20}}>{ticketCount}</p>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
          <Grid style={{marginTop: 5}} item xs={12}>
          {franchisedShops?.map(
            (shop, index) => {
            return (
            <TabPanel key={index} value={value} index={index}>
            <Paper style={{height: 600, padding: 30}}>
              {topSellingLoading ? (
                  <div style={{height: 600, margin: '0 auto', paddingTop: 195, textAlign: 'center'}}>
                    <CircularProgress style={{margin: '0 auto'}} />
                    <h4 style={{fontWeight: 300}}>LOADING BEST SELLING ITEMS</h4>

                  </div>
              ) : (
                  topSellingData?.length > 0 ? (
                      <ResponsiveContainer width="99%" >
                    <ComposedChart
                        layout="vertical"
                        data={topSellingData}
                        margin={{
                          top: 20,
                          right: 20,
                          bottom: 20,
                          left: 20,
                        }}
                    >
                      <CartesianGrid stroke="#f5f5f5" />
                      <XAxis type="number" />
                      <YAxis width={topSellingData?.length > 0 ? 150 : 30} tickFormatter={tickFormatter} dataKey="name" type="category" scale="auto" />
                      <Tooltip />
                      <Bar dataKey="quantity_sum" name="Quantity Sold" barSize={39} fill="#413ea0">
                        <LabelList style={{fill: 'white'}} formatter={labelFormatter} dataKey="quantity_sum"  position="inside" />
                      </Bar>
                      {topSellingData?.length > 0 ? <Legend /> : null}
                    </ComposedChart>
                  </ResponsiveContainer>
                  ) : (
                      <h3 style={{paddingTop: 220, textAlign: 'center'}}>NO DATA FOUND FOR TOP SELLING ITEMS</h3>
                  )
              )}

            </Paper>
            </TabPanel>
            )
          }
            )}
          </Grid>
          <Grid style={{marginTop: 50}} item xs={12}>
            {franchisedShops?.map(
                (shop, index) => {
                  return (
                      <TabPanel key={index} value={value} index={index}>

                        <Paper
                            style={{height: 500, padding: 30, paddingLeft: 5, paddingTop: 50, paddingBottom: 70}}>
                          <ResponsiveContainer width="99%">
                            <BarChart

                                data={data}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="datetime" />
                              <YAxis />
                              <Tooltip />
                              <Legend />
                              <Bar dataKey="Revenue" fill="#538abd">
                                <LabelList formatter={value => value > 0 ? '$' + value : null} style={{fill: 'black'}} dataKey="Revenue"  position="top" />
                              </Bar>
                              <Bar dataKey="Sold" fill="grey">
                                <LabelList offset={5} formatter={value => value > 0 ? value : null} style={{fill: 'grey'}} dataKey="Sold"  position="top" />
                              </Bar>

                              <Bar dataKey="Tickets" fill="blue">
                              <LabelList offset={5}  formatter={value => value > 0 ? value : null} style={{fill: 'blue'}} dataKey="Tickets" position="top" />

                              </Bar>

                              <Bar dataKey="Tip" fill="black">
                              <LabelList offset={5} formatter={value => value > 0 ? '$' + value : null} style={{fill: 'black'}} dataKey="Tickets"  position="top" />

                              </Bar>

                              <Bar dataKey="Refunded" fill="red">
                              <LabelList offset={5} formatter={value => value > 0 ? '$' + value : null} style={{fill: 'red'}} dataKey="Refunded"  position="top" />

                              </Bar>

                            </BarChart>
                          </ResponsiveContainer>
                          <Grid
                              style={{paddingLeft: 20, marginTop: 10}}
                              container
                              direction="row"
                              justifyContent="space-around"
                              alignItems="flex-end"
                          >
                            <Grid item xs>
                              <div>
                                <Button onClick={handlePrevious2} aria-label="previous">
                                  <NavigateBeforeIcon /> Previous
                                </Button>
                              </div>
                            </Grid>
                            <Grid item xs>
                              <div style={{float: 'right'}}>
                                {num2 === 1 ?
                                    <Button disabled aria-label="next">
                                      Next
                                      <NavigateNextIcon />
                                    </Button> :
                                    <Button onClick={handleNext2} aria-label="next">
                                      Next
                                      <NavigateNextIcon />
                                    </Button>
                                }
                              </div>
                            </Grid>
                          </Grid>
                        </Paper>
                      </TabPanel>
                  )
                }
            )}
          </Grid>
        </Grid>
        <br /><br />
       </div>
        </>

    ) : (
      <div className={classes.root2}>
        <ReportsAdvancedHeader type={settingsType} onSettingsTypeUpdate={type => setSettingsType(type)} active="General" />
        <br />
        {content}
      </div>
    )}

    </React.Fragment>
  );
};

export default SalesReport;
