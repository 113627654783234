import { createSlice } from '@reduxjs/toolkit';

export const headerSlice = createSlice({
  name: 'header',
  initialState: {
    title: 'Category',
    buttonTitle: 'Add'
  },
  reducers: {
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setButtonTitle: (state, action) => {
      state.title = action.payload;
    }
  },
});

export const { setTitle } = headerSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectTitle = state => state.header.title;
export const selectButtonTitle = state => state.header.buttonTitle;

export default headerSlice.reducer;
