import React, { useEffect, useState, useRef } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import InputAdornment from '@material-ui/core/InputAdornment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Chip from '@material-ui/core/Chip';
import {
  getRewardsMembers, updateEarningRatio, getSystemUser, sendMarketingSms, getRewardsMembersCountAvailToSend, getSmsRecords
} from '../auth/authSlice';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import currency from 'currency.js';
import SMSForm from './SmsMarketing';


const useStyles = makeStyles((theme) => ({
  table: {
    width: 800,
    [theme.breakpoints.down('md')]: {
      width: 660
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
}));

const RewardsHome = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const rewardsMembers = useSelector(state => state.auth.rewardsMembers)
  const smsRecords = useSelector(state => state.auth.smsRecords)

  const [openForm, setOpenForm] = useState(false)
  const [active, setActive] = useState('members')

  useEffect(() => {
    dispatch(getRewardsMembers());
    dispatch(getRewardsMembersCountAvailToSend());
  }, [])


    useEffect(() => {
      if (active === 'members') {
        dispatch(getRewardsMembers());
        dispatch(getRewardsMembersCountAvailToSend());
      } else if (active === 'records') {

        dispatch(getSmsRecords());
      }

    }, [active])

  return (
    <React.Fragment>
    <MarketingBanner onSend={() => setOpenForm(prev => !prev)} />
    <Settings />
    <Header active={active} onUpdate={active => setActive(active)} numRewardsMembers={rewardsMembers.length} />
    {active === 'members' ? (
      <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell>Tier</TableCell>
              <TableCell>Number of Visits</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Points <br/></TableCell>
              <TableCell>Member Since</TableCell>
              <TableCell>SMS Marketing Approved</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rewardsMembers.length == 0 && <b>LOADING ...</b>}
            {rewardsMembers.map(rewardMemberShop => (
              <TableRow key={rewardMemberShop.id}>
                <TableCell>{rewardMemberShop.rewards_member.tier}</TableCell>
                <TableCell>{rewardMemberShop.loyalty_visits}</TableCell>
                <TableCell>-</TableCell>
                <TableCell>{rewardMemberShop.rewards_member.formatted_phone}</TableCell>
                <TableCell>{rewardMemberShop.total_points}</TableCell>
                <TableCell>
                {moment(rewardMemberShop.datetime).format('MMMM Do YYYY, h:mm:ss a')}

                </TableCell>
                <TableCell>{rewardMemberShop.sms_marketing_approved ? 'Yes' : 'No'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ) : null}
    {active === 'records' ? (
      <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell>Date Sent</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Content</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {smsRecords?.map(record => (
              <TableRow key={record.id}>
                <TableCell>{moment(record.sent_date).format('MMMM Do YYYY, h:mm:ss a')}</TableCell>
                <TableCell>{record.status === 'sent' || record.status === 'queued' ? 'Sent' : 'Failed'}</TableCell>
                <TableCell>{record.message_content}</TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ) : null}
    <SMSForm onClose={() => setOpenForm(false)} handleClose={() => setOpenForm(false)} open={openForm} />
    </React.Fragment>
  );
}


function roundDown(number, decimals) {
    decimals = decimals || 0;
    return (Math.floor(number * Math.pow(10, decimals)) / Math.pow(10, decimals));
}

function displayAsDollarOrCents(value) {
    return value / 10 < 1 ? Math.round(value * 10) + ' cents' : '$' + value / 10;
}

const Settings = (props) => {
  const dispatch = useDispatch();
  const _earningRatio = useSelector(state => state.auth.user?.shop?.earning_ratio)
  const _pointsPerDollar = useSelector(state => state.auth.user?.shop?.points_per_dollar)
  const status = useSelector(state => state.auth.status)

  const [earningRatio, setEarningRatio] = useState(null);
  const [pointsPerDollar, setPointsPerDollar] = useState(null);

  const handleEarningRatioChange = (e) => {
    const newValue = parseFloat(e.target.value);
    setEarningRatio(newValue);
    setPointsPerDollar(roundDown(100 / newValue, 2));
  }

  const handlePointsPerDollarChange = (e) => {
    const newValue = parseFloat(e.target.value);
    setPointsPerDollar(newValue);
    setEarningRatio(roundDown(100 / (newValue * 10), 2));
  }

  const handleUpdate = async () => {
    await dispatch(updateEarningRatio({earning_ratio: earningRatio}))
    await dispatch(getSystemUser())
    console.log('Updated values:', earningRatio);
  }

  useEffect(() => {
    if (_earningRatio !== null && _earningRatio !== undefined) {
      setEarningRatio(_earningRatio);
    }

    if (_pointsPerDollar !== null && _pointsPerDollar !== undefined) {
      setPointsPerDollar(_pointsPerDollar);
    }
  }, [_earningRatio, _pointsPerDollar]);

  return (
    <div style={{ padding: 16, borderRadius: 5, marginBottom: 10,
      background: 'white',
      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    }}>
      <Grid container spacing={1}>
        <Grid item md={6} xs={12}>
          <TextField

            required
            size="small"
            fullWidth
            label={earningRatio !== null && earningRatio !== undefined ?
                  ("Earning Ratio " + earningRatio + ':1') :
                  "Earning Ratio"}
                  variant="outlined"
            value={earningRatio}
            onChange={handleEarningRatioChange}
            type="number"
            InputProps={{
              inputProps: {
                min: 0, step: 0.01
              }
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            disabled
            required
            size="small"
            fullWidth
            label={"Spend $10, Earn " + displayAsDollarOrCents(pointsPerDollar)}
            variant="outlined"
            value={currency(pointsPerDollar / 10)}
            onChange={handlePointsPerDollarChange}
            type="number"
            InputProps={{
              inputProps: {
                min: 0, step: 0.01
              }
            }}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Button disabled={status === 'get earning ratio loading'} fullWidth size="small" variant="contained" color="primary" onClick={handleUpdate}>
            {status === 'get earning ratio loading' ? 'Loading' : 'Update'}
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}


const Header = (props) => {
  return (
    <div style={{marginBottom: 3}} >
    <Paper style={{width: '100%',   overflow:'auto',  display: 'flex'}}>
    <Button onClick={() => props.onUpdate('members')}
      variant="text"
      disableElevation
      style={{ flex: '0 0 auto', color: props.active == 'members' ? 'black' : 'grey'}}>
      Rewars Members &nbsp; <Chip style={{borderRadius: 5, fontSize: 10, padding: 0}} size="small" color="primary" label={props.numRewardsMembers} />
    </Button>
    <Button
    style={{ flex: '0 0 auto', color: props.active == 'transactions' ? 'black' : 'grey'}}
    variant="text"
      disableElevation>
      Rewards Transactions
    </Button>
    <Button onClick={() => props.onUpdate('records')}
    style={{ flex: '0 0 auto', color: props.active == 'records' ? 'black' : 'grey'}}
    variant="text"
      disableElevation>
      SMS History
    </Button>



      </Paper>

    </div>

  )
}

const MarketingBanner = (props) => {
  const dispatch = useDispatch();
  const rewardsMembers = useSelector(state => state.auth.rewardsMembers)
  const [approvedMembersLength, setApprovedMembersLength] = useState(0)

  useEffect(() => {
    setApprovedMembersLength(rewardsMembers.filter(member => member.sms_marketing_approved).length)
  }, [rewardsMembers])

  return (
    <div style={{  boxShadow: '10px 10px 54px -3px rgba(0,0,0,0.23)', marginBottom: 20,width: '100%', padding: 15, paddingLeft: 20, paddingRight: 20, borderRadius: 12, paddingTop: 15, color: 'white',backgroundImage: 'linear-gradient(to right top, #3132dc, #5338d7, #693ed1, #7a46cd, #874ec8, #8c4ecb, #914dce, #964dd1, #9642dc, #9535e8, #9324f3, #9000ff)'}}>
      <h3 style={{paddingBottom:10, marginBotom: 0, borderBottom: '1px solid rgba(255,255,255, 0.4)'}}>Text Message Marketing</h3>
      <h2 style={{margin: 0, padding: 0}}>
      Drive more sales with instant text campaigns!
      </h2>
      <p style={{fontSize: 18}}>Text messagess, when being sent, are opened by 99% customers, and 90% of SMS messages are read within 3 minutes. You'll see Roughly 20-30% repeat visits on average.</p>
      <div style={{textAlign: 'center', borderRadius: 20, fontSize: 16, background: 'rgba(255,255,255,0.2)', padding: 10, }}>

      <Grid   container

  direction="row"
  justifyContent="space-between"
  alignItems="center">
        <Grid item xs={12} md={10} style={{padding: 5, fontSize: 20}}>
        <b>{approvedMembersLength} {approvedMembersLength > 1 ? 'customers' : 'customer'}</b> agreed to receive text messages from you!

        </Grid>
        <Grid item xs={12} md={2} style={{padding: 10}}>
        <Button onClick={() => props.onSend()} style={{ width: '100%', background: '#428abd', color: 'white'}} variant="contained" fullWidth >Send</Button>

        </Grid>
      </Grid>
      </div>
      <div style={{float: 'right', paddingRight: 10, paddingTop: 5}}>As low as 10c. No subscription required.</div>
      <br />
    </div>
  )
}


export default RewardsHome;
