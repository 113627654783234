import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AddOnAddModal from './AddOnAddModal';
import AddOnItem from './AddOnItem';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAddOnItems, selectAllAddOns } from './addOnSlice';
import { useLocation } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import {io} from "socket.io-client";


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  skeleton: {
    borderRadius: 4,
    height: 60,
    width: 800,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    marginBottom:1
  }
}));

const AddOn = (props) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const items = useSelector(selectAllAddOns);
  const status = useSelector(state => state.addon.status)

  const location = useLocation();

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchAddOnItems()); // populates state.category.categories
    }
  }, [status, dispatch]) // if either postStatus or dispatch changes, fire!

  useEffect(() => {
    dispatch(fetchAddOnItems());
  }, [location])

  useEffect(() => {
    dispatch(fetchAddOnItems()); // populates state.category.categories
  }, []) // on mount, once.

  // handling empty case; refactor by creating a reusable empty component
  let itemsDisp = '';

  if (items.length > 0) {
    itemsDisp = items.map((item, idx) =>
      <AddOnItem {...item} key={item.id} id={item.id} />
    );
  } else if (status === 'loading') {
    itemsDisp =
      <React.Fragment>
        <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
        <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
        <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
        <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
        <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
        <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      </React.Fragment>;
  } else {
    itemsDisp = <h1>Empty Add-ons</h1>;
  }

  return (
    <React.Fragment>
      {itemsDisp}
      <AddOnAddModal />
    </React.Fragment>
  );
};

export default AddOn;
