import React, { useState, useEffect } from 'react';
import { Chip, Button, TextField, MenuItem, Dialog} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Alert from '@material-ui/lab/Alert';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendTestMarketingSms, sendMarketingSms, getSystemUser, chargeDefaultCard, setSendSmsMessage, getRewardsMembersCountAvailToSend
} from '../auth/authSlice';
import currency from 'currency.js'

const pricingOptions = [
  {'label': 'Up to 25 messages', 'value': 25, 'fixedCost': 2},
  {'label': 'Up to 50 messages', 'value': 50, 'fixedCost': 5},
  {'label': 'Up to 100 messages', 'value': 100, 'fixedCost': 10},
  {'label': 'Up to 150 messages', 'value': 150, 'fixedCost': 15},
  {'label': 'Up to 250 messages', 'value': 250, 'fixedCost': 25},
  {'label': 'Up to 500 messages', 'value': 500, 'fixedCost': 30},
  {'label': 'Up to 1000 messages', 'value': 1000, 'fixedCost': 35},
  {'label': 'Up to 2000 messages', 'value': 2000, 'fixedCost': 60},
  {'label': 'Up to 3000 messages', 'value': 3000, 'fixedCost': 80},
  {'label': 'Up to 4000 messages', 'value': 4000, 'fixedCost': 100},
  {'label': 'Up to 5000 messages', 'value': 5000, 'fixedCost': 120},
]

function SMSForm(props) {

    const dispatch = useDispatch();
    const [selectedOption, setSelectedOption] = useState(pricingOptions[0]);
    const [message, setMessage] = useState('');
    const [message2, setMessage2] = useState('');
    const [sent, setSent] = useState(false);
    const rewardsMembers = useSelector(state => state.auth.rewardsMembers)
    const isStaff = useSelector(state => state.auth.user?.user?.is_staff)

    const business_name = useSelector(state => state.auth.user?.shop?.business_name ?? '')
    const availCredit = useSelector(state => state.auth.user?.shop?.available_credit ?? 0)
    const lastSent = useSelector(state => state.auth.user?.shop?.last_sms_marketing_sent ?? null)

    const chargeCardMessage = useSelector(state => state.auth.chargeCardMessage)
    const sendSmsMessage = useSelector(state => state.auth.sendSmsMessage)
    const status = useSelector(state => state.auth.status)
    const numRewardsMembersCountAvailToSend = useSelector(state => state.auth.numRewardsMembersCountAvailToSend)
    const [approvedMembersLength, setApprovedMembersLength] = useState(0)
    const [loading, setLoading] = useState(0)

    let indexToShow = pricingOptions.findIndex(option => approvedMembersLength <= option.value);
    const availablePricingOptions = pricingOptions.slice(0, indexToShow + 1);
    const maxTextLength = 160 - (business_name.length + 2); // Adjust based on selected option
    // Function to check if the lastSent date is less than 12 hours ago
    const isLessThanTwelveHoursAgo = (date) => {
      if (!date) return false;

      const lastSentDate = new Date(date);
      const now = new Date();
      const twelveHours = 12 * 60 * 60 * 1000; // 12 hours in milliseconds

      return (now - lastSentDate) < twelveHours;
    };


    useEffect(() => {
      if (props.open) {
        dispatch(getSystemUser());
        dispatch(getRewardsMembersCountAvailToSend());

      }
    }, [props.open])

    useEffect(() => {
      setApprovedMembersLength(numRewardsMembersCountAvailToSend)
    }, [rewardsMembers, numRewardsMembersCountAvailToSend])

    useEffect(() => {
      if (status === 'charge default failed') {
        setMessage2('Reloading credits failed. Please try again.')

      } else if (status === 'marketing sms send failed') {
        setMessage2('Sending text messages failed. Please try again.')
        dispatch(setSendSmsMessage('Sending text messages failed. Please try again.'))

      } else if (status === 'charge default loading') {
        setMessage2('Insufficient credit. Charging card...')
      } else if (status === 'marketing sms send loading') {
        setMessage2('Sending text messages...')
      } else if (status === 'marketing sms send succeeded') {
        dispatch(setSendSmsMessage(''))
        setMessage2('Sending succeeded!')
        setSent(true);

      } else if (status === 'charge default succeeded') {
        dispatch(setSendSmsMessage(''))
        setMessage2('Charging card succeeded!')
      }
    }, [status])

    const handleOptionChange = (event) => {
        setSelectedOption(pricingOptions.find(opt => opt.value === event.target.value));
    };

    const handleTextChange = (event) => {
        if (event.target.value.length <= maxTextLength) {
            setMessage(event.target.value);
        }
    };

    const handleSend = async () => {
      dispatch(setSendSmsMessage(''))

      setLoading(true)

      const creditNeededToSend = selectedOption.fixedCost;

      if (creditNeededToSend > availCredit) {
        let difference = creditNeededToSend - availCredit;
        let chargeAmount = Math.ceil(difference / 10) * 10;
        await dispatch(chargeDefaultCard({amount: chargeAmount}))
      }

      await dispatch(sendMarketingSms({number: Math.min(numRewardsMembersCountAvailToSend, selectedOption.value), message: message}))
      await dispatch(getSystemUser());
      console.log('Sending to:', selectedOption.label, 'Message:', message);
      setLoading(false)
    };

    const handleTestSend = async () => {
      dispatch(setSendSmsMessage(''))

      setLoading(true)

      const creditNeededToSend = selectedOption.fixedCost;
      
      if (creditNeededToSend > availCredit) {
          let difference = creditNeededToSend - availCredit;
          let chargeAmount = Math.ceil(difference / 10) * 10;
          await dispatch(chargeDefaultCard({amount: chargeAmount}))
      }

      await dispatch(sendTestMarketingSms({number: Math.min(numRewardsMembersCountAvailToSend, selectedOption.value), message: message}))
      await dispatch(getSystemUser());
      console.log('Test Sending to:', selectedOption.label, 'Message:', message);
      setLoading(false)
    };


    // Use the function in your component logic
    if (!sent && lastSent !== null && isLessThanTwelveHoursAgo(lastSent)) {
      // Do something if lastSent is not null and less than 12 hours ago
      // Your code here...

          return (
              <Dialog onClose={() => {props.handleClose();setSent(false);  dispatch(setSendSmsMessage('')); setMessage(''); setMessage2('')}} open={props.open}>
                <div style={{ padding: '20px' }}>
                  <h2 className="roboto-font2"><CheckCircleOutlineIcon htmlColor="green" style={{marginRight: 8, position: 'relative', top: 5}} />You have reached a quota</h2>
                  <p className="roboto-font2">
Remember, you can send a maximum of 4 text messages per phone number monthly. We suggest spacing them out to once a week. Avoid sending messages too early in the morning or too late at night. Always prioritize offers that genuinely add value for your customers.
                  </p>
                </div>

              </Dialog>
          );
    } else {

          return (
              <Dialog onClose={() => {props.handleClose();setSent(false);  dispatch(setSendSmsMessage('')); setMessage(''); setMessage2('')}} open={props.open}>
              {sent ? (
                <div style={{ padding: '20px' }}>
                  <h2 className="roboto-font2"><CheckCircleOutlineIcon htmlColor="green" style={{marginRight: 8, position: 'relative', top: 5}} />Now sending text messages...</h2>
                  <p className="roboto-font2">Depending on the number of messages requested, it may take from 1 minute to an hour to send all messages. You can close this.</p>
                </div>
              ) : (
                <div style={{ padding: '20px' }}>
                {
                  chargeCardMessage ? (
                    <Alert style={{marginBottom: 10}} variant="filled" severity="warning">
                     {chargeCardMessage}
                   </Alert>
                  ) : (
                    null
                  )
                }
                {
                  sendSmsMessage ? (
                    <Alert style={{marginBottom: 10}} variant="filled" severity="warning">
                     {sendSmsMessage}
                   </Alert>
                  ) : (
                    null
                  )
                }

                <div style={{overflowY: 'auto', marginBottom: 20, borderRadius: 10, padding: 10, border: '1px solid rgba(0,0,0,0.2)'}}>
                    <Chip label={'AVAILABLE CREDIT - $' + availCredit} color="primary" size="small"  style={{marginBottom: 5}}/> <br />
                    Sending messages to <b>{numRewardsMembersCountAvailToSend} customers</b> that are eligible for messaging this month
                    <hr />
                    <small>Remember, you can send a maximum of 4 text messages per phone number monthly. We suggest spacing them out to once a week. Avoid sending messages too early in the morning or too late at night. Always prioritize offers that genuinely add value for your customers.</small>
                </div>
                    <TextField
                        select
                        label="Select number of customers"
                        value={selectedOption.value}
                        onChange={handleOptionChange}
                        fullWidth
                    >
                    {availablePricingOptions.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                          {option.label}
                      </MenuItem>
                  ))}
                    </TextField>
                    <br /><br />
                    <TextField
                        label="Enter your message"
                        multiline
                        rows={4}
                        variant="outlined"
                        placeholder="We have an exciting offer for you. Visit our store today and avail a special discount. Valid till 10/20/0000!"
                        value={message}
                        onChange={handleTextChange}
                        fullWidth
                        helperText={`${message.length}/${maxTextLength} characters`}
                    />
                    <br /><br />
                    <div style={{overflowY: 'auto', marginBottom: 20,borderRadius: 10, padding: 10,border: '1px solid rgba(0,0,0,0.2)'}}><small>PREVIEW</small><hr />
                      {business_name +': ' + message}
                    </div>
                    {loading ? (
                      <Button disabled style={{color: 'black'}} fullWidth variant="contained" color="primary" >
                        {message2}
                      </Button>
                    ) : (
                      <Button disabled={(message.length < 10 || numRewardsMembersCountAvailToSend < 1)} fullWidth variant="contained" color="primary" onClick={isStaff ? handleTestSend : handleSend}>
                          {numRewardsMembersCountAvailToSend < 1 ? (
                            'You need at least 1 eligible customer to send'
                          ) : (
                            <>Send <Chip size="small" style={{marginLeft: 8,background: message.length < 10 ?  'rgba(0,0,0,0.1)' : 'white', height: 16, color: message.length < 10 ? 'gray' : 'black'}} label={currency(selectedOption.fixedCost).format()} /></>
                          )}
                      </Button>
                    )}

                    <div style={{textAlign: 'center', marginTop: 3, fontWeight: 400, fontSize: 12}}>Credit will be automatically reloaded in multiple of $10 if there's an insufficient amount</div>

                </div>
              )}

              </Dialog>
          );
    }

}

export default SMSForm;
