import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET, DELETE, POST, PUT, PATCH, POST_FILE } from '../../api/DashboardAPI';
import currency from 'currency.js'

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: [],
    toGo: false,
    modalItemData: null
  },
  reducers: {
    addCartItem: (state, action) => {
      let cartItem = action.payload.cartItem;
      cartItem.modalItemData = action.payload.modalItemData;
      state.items.push(cartItem);
    },
    updateCartItem: (state, action) => {
      state.items[action.payload.cartIdx] = action.payload.cartItem;
      state.items[action.payload.cartIdx].modalItemData = action.payload.modalItemData;
    },
    deleteCartItem: (state, action) => {
      const id = action.payload;
      state.items.splice(id, 1);
    },
    deleteAllCart: state => {
      state.items = []
    },
    updateQty: (state, action) => {
      const qty = action.payload.qty;
      const index = state.items.findIndex(item => item.modalItemData.item.id === action.payload.item.id);

      if (index !== -1) {
        state.items[index].modalItemData.priceSummary.qty = qty;
        let addedItemSum = 0;
        state.items[index].modalItemData.item.groups.map(option => {
          if (option.addedItem) {
            option.addedItem.map(item => {
              if (!item.is_number_option) {
                addedItemSum = (currency(item.unit_price).add(addedItemSum)).value
              } else {
                // calc item.number_value * item.unit_price
                addedItemSum = ((currency(item.number_value).multiply(item.unit_price)).add(addedItemSum)).value
              }
            })
          }
        });

        let unitPrice = currency(state.items[index].unit_price);
        //state.items[index].modalItemData.priceSummary.amount = ((parseFloat(unitPrice) + parseFloat(addedItemSum)) * qty).toFixed(2);
        state.items[index].modalItemData.priceSummary.amount = (currency(currency(unitPrice).add(addedItemSum)).multiply(qty)).value;
      }
    },
    updateQtyWithIndex: (state, action) => {
      const qty = action.payload.qty;
      const index = action.payload.index;
      if (index !== -1) {
        state.items[index].modalItemData.priceSummary.qty = qty;
        state.items[index].quantity = qty;

        let addedItemSum = 0;
        state.items[index].modalItemData.item.groups.map(option => {
          if (option.addedItem) {
            option.addedItem.map(item => {
              if (!item.is_number_option) {
                addedItemSum = (currency(item.unit_price).add(addedItemSum)).value
              } else {
                // calc item.number_value * item.unit_price
                addedItemSum = ((currency(item.number_value).multiply(item.unit_price)).add(addedItemSum)).value
              }
            })
          }
        });

        let unitPrice = currency(state.items[index].unit_price);
        //state.items[index].modalItemData.priceSummary.amount = ((parseFloat(unitPrice) + parseFloat(addedItemSum)) * qty).toFixed(2);
        state.items[index].modalItemData.priceSummary.amount = (currency(currency(unitPrice).add(addedItemSum)).multiply(qty)).value;
      }
    },
  }
});

export const {updateQtyWithIndex, updateCartItem, addCartItem, deleteCartItem, deleteAllCart, updateQty} = cartSlice.actions;

export const selectCartItems = state => state.cart.items;
export const selectCartItemsCount = state => state.cart.items.length;

export default cartSlice.reducer;
