import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import { useHistory } from "react-router-dom";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Shape from './Shape'
import { clearAll, postShape, postTableCanvas, updatePosition, updateWidth, updateHeight, updateDegree, updateText, updateSize, addShape, deleteShape, updateZ } from '../tableSlice'
import { useSelector, useDispatch } from 'react-redux';

const RectRender = () => {
  return (
    <span style={{
      background: '#e8e8e8',
      width: 50,
      height: 25
    }}></span>
  )
}

const SquareRender = () => {
  return (
    <span style={{
      background: '#e8e8e8',
      width: 25,
      height: 25
    }}></span>
  )
}

const CircleRender = () => {
  return (
    <span style={{
      background: '#e8e8e8',
      borderRadius: 50,
      width: 25,
      height: 25
    }}></span>
  )
}

const LineRender = () => {
  return (
    <span style={{
      borderTop: '1px solid black',
      width: 25,
      transform: "rotate(-45deg)",
      height: 1
    }}></span>
  )
}

const TableCanvas = (props) => {
  const history = useHistory();
  const shapes = useSelector(state => state.table.shapes)
  const dispatch = useDispatch();

  function _addShape(type) {
    let width;
    let height;
    let deg;
    let size;

    if (type === 'rect') {
      width = 200;
      height = 100
    } else if (type === 'square') {
      width = 100
      height = 100
    } else if (type === 'line') {
      width = 50
      height = 1
    } else if (type === 'circle') {
      width = 100
      height = 100
    } else {
      height = 50
      size = 20
    }
    dispatch(postShape({
      type: type,
      width: width,
      height: height,
      degree: deg,
      text: 'TEXT',
      size: size,
      x: 0,
      y: 0
    }))
  }


  return (
    <Grid
      style={{
        marginBottom: 40
      }}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item style={{boxShadow: '0 0.09rem 0.13rem rgba(0,0,0,.075)', borderRadius: 20, margin: 10, padding: 5, paddingLeft: 20,   background: 'white', width: '100%'}}>
        <Grid container
        spacing={0}
        direction="row"
        justifyContent="center"
        alignItems="center"
        >
          <Grid item>
            <Button style={{padding: 0}} onClick={() => _addShape('rect')} size="small">
             <RectRender />
           </Button>
          </Grid>
          <Grid item>
            <Button style={{padding: 0, minWidth: 50}} onClick={() => _addShape('square')} size="small">
             <SquareRender />
           </Button>
          </Grid>
          <Grid item>
            <Button style={{padding: 0, minWidth: 40}}  onClick={() => _addShape('circle')} size="small">
              <CircleRender />
           </Button>
          </Grid>
          <Grid item>
            <Button style={{padding: 0, height: 30, minWidth: 40}} onClick={() => _addShape('line')} size="small">
              <LineRender />
           </Button>
          </Grid>
          <Grid item>
            <Button style={{padding: 0, minWidth: 50, marginRight: 20}}onClick={() => _addShape('text')} size="small">
             TEXT
           </Button>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item>
            <Button style={{padding: 0}} style={{marginLeft: 25, marginRight: 25}} color="secondary" onClick={() => dispatch(clearAll())} size="small">
             CLEAR ALL
           </Button>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item>
            <IconButton onClick={() => {history.goBack()}} style={{marginLeft: 20}}><ExitToAppIcon /></IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container>
        <div style={{
          overflow: 'hidden',
          position: 'relative',
          width: '900px',
          height: '1000px',
          background: "white",
          borderRadius: 2,
          boxShadow: '0 0.09rem 0.13rem rgba(0,0,0,.075)',
          backgroundImage: "radial-gradient(grey 1px, transparent 0)",
          backgroundSize: "45px 45px",
          backgroundPosition: "-19px -47px"
        }}>
          {shapes?.map((shape, index) => {
            return <Shape
                    key={shape.id}
                    onWidthUpdate={
                      (w) => {
                        dispatch(updateWidth({id: shape.id, width: w}));
                      }
                    }
                    onZUpdate={
                      (z) => {
                        dispatch(updateZ({id: shape.id, z: z}));
                      }
                    }
                    onHeightUpdate={
                      (h) => {
                        dispatch(updateHeight({id: shape.id, height: h}));
                      }
                    }
                    onDegreeUpdate={
                      (d) => {
                        dispatch(updateDegree({id: shape.id, degree: d}));
                      }
                    }
                    onSizeUpdate={
                      (s) => {
                        dispatch(updateSize({id: shape.id, size: s}));
                      }
                    }
                    onTextUpdate={
                      (s) => {
                        dispatch(updateText({id: shape.id, text: s}));
                      }
                    }

                    onDelete={
                      (id) => {
                        dispatch(deleteShape({id: shape.id}))
                    }}
                    width={shape.width}
                    height={shape.height}
                    x={shape.x}
                    y={shape.y}
                    z={shape.z}
                    degree={shape.degree}
                    text={shape.text}
                    size={shape.size}
                    type={shape.type}
                    id={shape.id}
                    table={shape.table}
                  />})}
        </div>
      </Grid>
    </Grid>
  )
}

export default TableCanvas;
