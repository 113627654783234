import React from 'react';
import Category from '../../features/category/Category';


const CategoryComponent = () => {
  return (
      <Category />
  );
};

export default CategoryComponent;
