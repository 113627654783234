import React, { useState, useEffect, useRef } from "react";

const useAudio = url => {
  const audio = useRef(null);
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
      playing ? audio.current?.play() : audio.current?.pause();
    },
    [playing]
  );

  useEffect(() => {
    if (audio.current === null) {
      audio.current = new Audio(url)
    }
    audio.current.crossorigin = 'anonymous';
    audio.current.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.current.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return [playing, toggle];
};

export default useAudio;
