import React, { useEffect, useState } from 'react';
import TableCanvas from './TableCanvas/TableCanvas.js'
import { postTableCanvas, fetchShapes, fetchTableCanvas } from './tableSlice'
import { useDispatch } from 'react-redux';

const TableBuilder = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(postTableCanvas())
      dispatch(fetchTableCanvas())
  }, [])

  return (
    <div>
      <TableCanvas />
    </div>
  )
}

export default TableBuilder;
