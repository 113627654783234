import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET, DELETE, POST, POST_FILE, PUT, PATCH } from '../../api/DashboardAPI';

export const patchUpdateDefaultOptionItem = createAsyncThunk('addon/patchUpdateDefaultOptionItem', async (obj) => {
  const response = await POST('/dashboard/option/' + obj.optionId + '/set_default_option_item/', {default_option_item_id: obj.default_option_item_id, clear: obj.clear});
  return {default_option_item: response.data.default_option_item, clear: obj.clear, optionId: obj.optionId}
});

export const patchItemOptionActive = createAsyncThunk('itemOption/patchItemOptionActive', async (payload) => {
  const response = await POST('/dashboard/item-option/' + payload.id + '/set_item_option_active/', {active: payload.active});
  return {item: response.data, optionId: payload.optionId}
});


export const patchRemoveItemsToOption = createAsyncThunk('addon/patchRemoveItemsToOption', async (obj) => {
  const optionId = obj.optionId;
  let items = obj.existingItems.slice();
  obj.removedItems.map(id => {
    const index = items.findIndex(item => item === id);
    items.splice(index, 1);
  });
  const response = await POST('/dashboard/option/' + obj.optionId + '/remove_item_from_option/', {removedItems: obj.removedItems});

  return {id: optionId, items: items};
});

export const removeOptionItem = createAsyncThunk('addon/removeOptionItem', async (obj) => {
  const response = await DELETE('/dashboard/item-option/' + obj.item.id + '/');
  return obj; // payload
});

export const postItemsInAddonOrder = createAsyncThunk('category/postItemsInAddonOrder', async (obj) => {
  const response = await POST('/dashboard/option/' + obj.optionId + '/update_items_in_addon_order/', {items: obj.items});
  return {optionId: obj.optionId, groups: response.data.groups};
});

export const removeOptionItemFromOption = createAsyncThunk('addon/removeOptionItemFromOption', async (obj) => {
  const response = await POST('/dashboard/option/' + obj.optionId + '/remove_itemoption_from_option/', {itemOptionId: obj.itemOptionId});
  return obj; // payload
});

export const removeOption = createAsyncThunk('addon/removeOption', async (id) => {
  const response = await DELETE('/dashboard/option/' + id + '/');
  return id; // payload
});

export const postItem = createAsyncThunk('addon/postItem', async (obj) => {
  const response = await POST('/dashboard/item-option/', {name: obj.name, options: [obj.optionId]});
  return response.data; // payload
});

export const postItemForm = createAsyncThunk('addon/postItemForm', async (obj) => {
  const response = await POST_FILE('/dashboard/item-option/', obj.formData);
  return {data: response.data, optionId: obj.optionId }; // payload
});


export const postOption = createAsyncThunk('addon/postOption', async (name) => {
  const response = await POST('/dashboard/option/', {name: name});
  return response.data; // payload
});

export const fetchAddOnItemsSimplified = createAsyncThunk('addon/fetchAddOnItemsSimplified', async () => {
  const response = await GET('/dashboard/option/fetch_options_for_item_in_dashboard/');
  return response.data; // payload
});

export const fetchAddOnItems = createAsyncThunk('addon/fetchAddOnItems', async () => {
  const response = await GET('/dashboard/option/');
  return response.data.results; // payload
});

export const fetchAddOnOptionItems = createAsyncThunk('addon/fetchAddOnOptionItems', async () => {
  const response = await GET('/dashboard/item-option/');
  return response.data.results; // payload
});

export const fetchAddOnImages = createAsyncThunk('addon/fetchAddOnImages', async () => {
  const response = await GET('/dashboard/item-image/');
  return response.data.results; // payload
});

export const patchAddExistingOptionItem = createAsyncThunk('addon/patchAddExistingOptionItem', async (obj) => {
  const response = await POST('/dashboard/item-option/' +  obj.itemOptionid + '/add_existing_item_option/', {optionId: obj.optionId});
  return { addedItem: response.data, optionId: obj.optionId }; // payload
});

export const patchItem = createAsyncThunk('addon/patchItem', async (obj) => {
  const response = await PATCH('/dashboard/option/' + obj.itemId + '/', {active: obj.active, name: obj.name, multiple_max_number: obj.upTo, required: obj.required, is_multiple_selection: obj.multiSelection, up_to: obj.upToSelection});
  console.log(response)
  const returnObj = {
    itemId: obj.itemId,
    name: obj.name,
    active: obj.active,
    required: obj.required,
    multiple_max_number: obj.upTo,
    is_multiple_selection: obj.multiSelection,
    up_to: obj.upToSelection
  };

  return returnObj; // payload
});

export const patchBulkAddItemsToOption = createAsyncThunk('addon/patchBulkAddItemsToOption', async (obj) => {
    const response = await POST('/dashboard/option/' + obj.optionId + '/bulk_add_items_to_option/', {items: obj.items});  // Send all item IDs
    return { addedItemIds: response.data['added_item_ids'], id: obj.optionId };
});

export const patchAddItemsToOption = createAsyncThunk('addon/patchAddItemsToOption', async (obj) => {
  let existingItems = [];
  obj.existingItems.map(item => existingItems.push(item));
  existingItems.push(obj.addedItem);
  await POST('/dashboard/option/' + obj.optionId + '/add_item_to_option/', {itemId: obj.itemId});
  return { addedItemId: obj.itemId, id: obj.optionId };
});

export const updateItemOptionDescription = createAsyncThunk('addon/updateItemOptionDescription', async (payload) => {
  const response = await POST('/dashboard/item-option/' + payload.id +  '/update_description/', {description: payload.description});
  return {optionId: payload.optionId, itemOption: response.data}; // payload
});

export const updateItemOptionPrice = createAsyncThunk('addon/updateItemOptionPrice', async (payload) => {
  const response = await POST('/dashboard/item-option/' + payload.id +  '/update_price/', {price: payload.price});
  return {optionId: payload.optionId, itemOption: response.data}; // payload
});

export const updateItemOptionName = createAsyncThunk('addon/updateItemOptionName', async (payload) => {
  const response = await POST('/dashboard/item-option/' + payload.id +  '/update_name/', {name: payload.name});
  return {optionId: payload.optionId, itemOption: response.data}; // payload
});


export const patchUpdateApprovalOptionItem = createAsyncThunk('addon/patchUpdateApprovalOptionItem', async (payload) => {
  const response = await POST('/dashboard/item-option/' + payload.optionItemId +  '/update_approval/', {approval_needed: payload.approval_needed});
  return {optionId: payload.optionId, itemOptionId: payload.optionItemId, approval_needed: payload.approval_needed}; // payload
});

export const fetchItemOptionsSimplified = createAsyncThunk('item/fetchItemOptionsSimplified', async () => {
  const response = await GET('/dashboard/item-option/fetch_item_options_simplified/');
  return response.data; // payload
});


export const fetchItemOptionReport = createAsyncThunk('item/fetchItemOptionReport', async (payload) => {
  const response = await POST('/dashboard/item-option/get_item_option_report/', payload);
  return {data: response.data, id: payload.id} // payload
});


export const fetchItemOptionReportCustom = createAsyncThunk('item/fetchItemOptionReportCustom', async (payload) => {
  const response = await POST('/dashboard/item-option/get_item_option_report_custom/?start_date=' + payload.start_date + '&end_date=' + payload.end_date, payload);
  return {data: response.data, id: payload.id} // payload
});


export const addOnSlice = createSlice({
  name: 'addon',
  initialState: {
    addOnItems: [],
    status: 'idle',
    error: null,
    itemImagesStatus: 'idle',
    addOnImages: [],
    putItemStatus: 'idle',
    addOnOptionItems: [],
    itemOptionsSimplified: []
  },
  reducers: {
    updateSelectImage: (state, action) => {
      const obj = action.payload;
      // get the category id and target val
      const itemId = obj.itemId;
      const targetValue = obj.targetValue;
      // find category elem from categories array
      const index = state.addOnItems.findIndex(item => item.id === itemId);
      // update category_image field in a category elem with a target value
      state.items[index].item_image = targetValue;
    }
  },
  extraReducers: {
    [removeOption.pending]: (state, action) => {
      state.status = 'loading'
    },
    [removeOption.fulfilled]: (state, action) => {
      state.addOnItems = state.addOnItems.filter((option) => option.id !== action.payload);
      state.status = 'deleted';
    },
    [removeOption.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [removeOptionItem.pending]: (state, action) => {
      state.status = 'loading'
    },
    [removeOptionItem.fulfilled]: (state, action) => {
      const i = state.addOnOptionItems.findIndex(item => item.id === action.payload.item.id);
      state.addOnOptionItems.splice(i, 1);
      state.status = 'removeOptionItem deleted';
      //state.categories = state.categories.filter((category, idx) => idx !== action.payload);
    },
    [removeOptionItem.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [removeOptionItemFromOption.pending]: (state, action) => {
      state.status = 'loading'
    },
    [removeOptionItemFromOption.fulfilled]: (state, action) => {
      const i = state.addOnItems.findIndex(item => item.id === action.payload.optionId);
      const j = state.addOnItems[i].item_options.findIndex(itemOption => itemOption.id === action.payload.itemOptionId);
      state.addOnItems[i].item_options.splice(j, 1);
      state.status = 'deleted';
      //state.categories = state.categories.filter((category, idx) => idx !== action.payload);
    },
    [removeOptionItemFromOption.rejected]: (state, action) => {
      state.status = 'failed'
    },

    [fetchAddOnItemsSimplified.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchAddOnItemsSimplified.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // Add any fetched posts to the array

      state.addOnItems = action.payload;
    },
    [fetchAddOnItemsSimplified.rejected]: (state, action) => {
      state.status = 'failed'
    },

    [fetchAddOnItems.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchAddOnItems.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // Add any fetched posts to the array

      state.addOnItems = action.payload;
    },
    [fetchAddOnItems.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
    [postOption.pending]: (state, action) => {
      state.status = 'loading'
    },
    [postOption.fulfilled]: (state, action) => {
      state.status = 'added'
      state.addOnItems = state.addOnItems.concat(action.payload);
      // Add any fetched posts to the array
    },
    [postOption.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
    [postItem.pending]: (state, action) => {
      state.status = 'loading'
    },
    [postItem.fulfilled]: (state, action) => {
      state.status = 'added'

    },
    [postItem.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
    [postItemForm.pending]: (state, action) => {
      state.status = 'loading'
    },
    [postItemForm.fulfilled]: (state, action) => {
      const addedData = action.payload.data;
      const optionId = action.payload.optionId;
      const index = state.addOnItems.findIndex(option => option.id === optionId);

      state.addOnItems[index].item_options = state.addOnItems[index].item_options.concat(action.payload.data);
      state.status = 'added'
    },
    [postItemForm.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
    [fetchAddOnImages.pending]: (state, action) => {
      state.itemImagesStatus = 'loading'
    },
    [fetchAddOnImages.fulfilled]: (state, action) => {
      state.itemImagesStatus = 'succeeded'
      // Add any fetched posts to the array
      state.itemImages = action.payload;
    },
    [fetchAddOnImages.rejected]: (state, action) => {
      state.itemImagesStatus = 'failed'
      state.error = action.error.message;
    },
    [patchItem.pending]: (state, action) => {
      state.putItemStatus = 'loading'
    },
    [patchItem.fulfilled]: (state, action) => {
      const obj = action.payload;

      /* update locally after server update */

      // get the category id and target val
      const itemId = obj.itemId;
      // find category elem from categories array
      const index = state.addOnItems.findIndex(item => item.id === itemId);
      // update category_image field in a category elem with a target value
      state.addOnItems[index].unit_price = obj.unit_price;
      state.addOnItems[index].calories = obj.calories;
      state.addOnItems[index].active = obj.active;
      state.putItemStatus = 'updated'
    },
    [patchItem.rejected]: (state, action) => {
      state.putItemStatus = 'failed'
      state.error = action.error.message;
    },
    [patchAddItemsToOption.pending]: (state, action) => {
      state.status = 'loading'
    },
    [patchAddItemsToOption.fulfilled]: (state, action) => {
      state.status = 'added'
      const index = state.addOnItems.findIndex(item => item.id === action.payload.id);
      state.addOnItems[index].items.push(action.payload.addedItemId);
    },
    [patchAddItemsToOption.rejected]: (state, action) => {

      state.status = 'failed'
    },
    [patchRemoveItemsToOption.pending]: (state, action) => {
      state.status = 'loading'
    },
    [patchRemoveItemsToOption.fulfilled]: (state, action) => {
      state.status = 'deleted'
      const index = state.addOnItems.findIndex(item => item.id === action.payload.id);
      state.addOnItems[index].items = action.payload.items;
    },
    [patchRemoveItemsToOption.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [fetchAddOnOptionItems.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchAddOnOptionItems.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // Add any fetched posts to the array
      state.addOnOptionItems = action.payload;
    },
    [fetchAddOnOptionItems.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
    [patchAddExistingOptionItem.pending]: (state, action) => {
      state.status = 'loading'
    },
    [patchAddExistingOptionItem.fulfilled]: (state, action) => {
      const optionId = action.payload.optionId;
      const addedItem = action.payload.addedItem;

      const index = state.addOnItems.findIndex(item => item.id === optionId);
      if (index !== - 1) {
        state.addOnItems[index].item_options = state.addOnItems[index].item_options.concat(addedItem);
      }
      state.status = 'succeeded'
    },
    [patchAddExistingOptionItem.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },

    [postItemsInAddonOrder.fulfilled]: (state, action) => {
      state.status = 'ordered'
      const index = state.addOnItems.findIndex(option => option.id === action.payload.optionId);

      state.addOnItems[index].groups = action.payload.groups;
    },
    [postItemsInAddonOrder.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [postItemsInAddonOrder.pending]: (state, action) => {
      state.status = 'loading'
    },

    [patchUpdateDefaultOptionItem.fulfilled]: (state, action) => {
      state.status = 'updated'
      const index = state.addOnItems.findIndex(option => option.id === action.payload.optionId);
      const clear = action.payload.clear;

      if (clear) {
        state.addOnItems[index].default_item_option = null;
      } else {
        state.addOnItems[index].default_item_option = action.payload.default_option_item;
      }
    },
    [patchUpdateDefaultOptionItem.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [patchUpdateDefaultOptionItem.pending]: (state, action) => {
      state.status = 'loading'
    },

    [patchItemOptionActive.fulfilled]: (state, action) => {
      const index = state.addOnItems.findIndex(option => option.id === action.payload.optionId);
      console.log(index)
      if (index != -1) {
        const index2 = state.addOnItems[index].item_options.findIndex(item => item.id == action.payload.item.id)
        if (index2 != -1) {
          state.addOnItems[index].item_options[index2] = action.payload.item
          state.status = 'item option updated'
        }
      }
    },
    [patchItemOptionActive.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [patchItemOptionActive.pending]: (state, action) => {
      state.status = 'loading'
    },

    [updateItemOptionDescription.fulfilled]: (state, action) => {
      const index = state.addOnItems.findIndex(option => option.id === action.payload.optionId);
      if (index != -1) {
        const index2 = state.addOnItems[index].item_options.findIndex(item => item.id == action.payload.itemOption.id)
        if (index2 != -1) {
          state.addOnItems[index].item_options[index2] = action.payload.itemOption
          state.status = 'item option description updated'
        }
      }
    },
    [updateItemOptionDescription.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [updateItemOptionDescription.pending]: (state, action) => {
      state.status = 'item option price loading'
    },

    [updateItemOptionPrice.fulfilled]: (state, action) => {
      const index = state.addOnItems.findIndex(option => option.id === action.payload.optionId);
      if (index != -1) {
        const index2 = state.addOnItems[index].item_options.findIndex(item => item.id == action.payload.itemOption.id)
        if (index2 != -1) {
          state.addOnItems[index].item_options[index2] = action.payload.itemOption
          state.status = 'item option price updated'
        }
      }
    },
    [updateItemOptionPrice.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [updateItemOptionPrice.pending]: (state, action) => {
      state.status = 'item option price loading'
    },

    [updateItemOptionName.fulfilled]: (state, action) => {
      const index = state.addOnItems.findIndex(option => option.id === action.payload.optionId);
      if (index != -1) {
        const index2 = state.addOnItems[index].item_options.findIndex(item => item.id == action.payload.itemOption.id)
        if (index2 != -1) {
          state.addOnItems[index].item_options[index2] = action.payload.itemOption
          state.status = 'item option name updated'
        }
      }
    },
    [updateItemOptionName.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [updateItemOptionName.pending]: (state, action) => {
      state.status = 'item option name loading'
    },

    [patchBulkAddItemsToOption.pending]: (state, action) => {
        state.status = 'loading'
    },
    [patchBulkAddItemsToOption.fulfilled]: (state, action) => {
        state.status = 'added'
        const index = state.addOnItems.findIndex(item => item.id === action.payload.id);
        action.payload.addedItemIds.forEach(itemId => {
            state.addOnItems[index].items.push(itemId);
        });
    },
    [patchBulkAddItemsToOption.rejected]: (state, action) => {
        state.status = 'failed'
    },
    [patchUpdateApprovalOptionItem.pending]: (state, action) => {
      state.status = 'loading'
    },
    [patchUpdateApprovalOptionItem.fulfilled]: (state, action) => {
        const i = state.addOnItems.findIndex(item => item.id === action.payload.optionId);
        if (i != -1) {
          const j = state.addOnItems[i].item_options.findIndex(itemOption => itemOption.id === action.payload.itemOptionId);

          state.addOnItems[i].item_options[j].approval_needed = action.payload.approval_needed;
          state.status = 'updated approval'
        }
    },
    [patchUpdateApprovalOptionItem.rejected]: (state, action) => {
        state.status = 'failed'
    },


    [fetchItemOptionsSimplified.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchItemOptionsSimplified.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // Add any fetched posts to the array
      state.itemOptionsSimplified = action.payload;
    },
    [fetchItemOptionsSimplified.rejected]: (state, action) => {
      state.status = 'failed'
    },


    [fetchItemOptionReport.pending]: (state, action) => {
      state.status = 'fetch item loading'
    },
    [fetchItemOptionReport.fulfilled]: (state, action) => {
      const data = action.payload.data;
      const id = action.payload.id;

      const index = state.itemOptionsSimplified?.findIndex(item => item.id === id);
      if (index != -1) {
        state.itemOptionsSimplified[index].total = data.total
        state.itemOptionsSimplified[index].item_count = data.item_count
  
        state.status = 'fetch item succeeded'
        // Add any fetched posts to the array
      }
    },
    [fetchItemOptionReport.rejected]: (state, action) => {
      state.status = 'fetch item failed'
      state.error = action.error.message
    },

    [fetchItemOptionReportCustom.pending]: (state, action) => {
      state.status = 'fetch item loading'
    },
    [fetchItemOptionReportCustom.fulfilled]: (state, action) => {
      const data = action.payload.data;
      const id = action.payload.id;

      const index = state.itemOptionsSimplified.findIndex(item => item.id === id);

      state.itemOptionsSimplified[index].total = data.total
      state.itemOptionsSimplified[index].item_count = data.item_count

      state.status = 'fetch item succeeded'
      // Add any fetched posts to the array
    },
    [fetchItemOptionReportCustom.rejected]: (state, action) => {
      state.status = 'fetch item failed'
      state.error = action.error.message
    },
  }
});

export const {  } = addOnSlice.actions;

export default addOnSlice.reducer;

export const selectAllAddOns = state => state.addon.addOnItems;
export const selectAllItemOptions = state => state.addon.addOnOptionItems;
export const selectAllAddOnImages = state => state.addon.addOnImages;
export const selectPutItemStatus = state => state.addon.putItemStatus;
export const selectOptionById = (state, optionId) => {
  const index = state.addon.addOnItems.findIndex(option => option.id === optionId)
  const items = state.addon.addOnItems;
  if (index !== -1) {
    return items[index];
  } else {
    return null;
  }

}
