import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import ItemImage from './ItemImage';
import {
  syncOptionsOrder, patchBulkAddOptionsToItem, postOptionsInItemsOrder, patchAddOptionsToItem, fetchItemImages, fetchItemOptionGroup, fetchItemImage,
  selectAllItemImages, patchItem, removeItem, postOptionsInItemOrder,
  patchRemoveOptionFromItem, overrideItem, fetchItemDetail
} from './itemSlice';
import { getFranchisedShops } from '../auth/authSlice';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import { green, pink } from '@material-ui/core/colors';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FolderIcon from '@material-ui/icons/Folder';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PageviewIcon from '@material-ui/icons/Pageview';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ClickImage from '../../components/previews/ClickImage';
import FlavoredTea from '../../assets/FlavoredTea.png';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import SimpleList from '../../components/commons/SimpleList';
import FormGroup from '@material-ui/core/FormGroup';
import OverrideItemTable from './OverrideItemTable';
import Checkbox from '@material-ui/core/Checkbox';
import SyncIcon from '@material-ui/icons/Sync';
import {
  patchRemoveItemsToOption,
  patchAddExistingOptionItem, fetchAddOnOptionItems,
  selectAllItemOptions, patchAddItemsToOption, fetchAddOnImages, fetchAddOnItemsSimplified,
  selectAllAddOnImages, selectAllAddOns, postItem,
  postItemForm, removeOptionItem, removeOption, selectPutItemStatus
} from '../addon/addOnSlice';
import { setAddItemImageModalOpen, selectItemImageModalOpen } from '../modal/modalSlice';
import { io } from "socket.io-client";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import ConfirmDeleteModal from '../modal/ConfirmDeleteModal';
import HistoryIcon from '@material-ui/icons/History';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';

const DEFAULT_SOCKETS_URL = 'https://sockets.bigkiosksolution.com';

const ITEM_IMG_WIDTH = '60px';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  accordionRoot: {
    width: 800,
    [theme.breakpoints.down('md')]: {
      width: 660
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
  body: {
    paddingTop: 40,
    touchAction: "pan-x"
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
  },
  label: {
    marginTop: -25,
    paddingTop: -1000,
  },
  footer: {
    paddingTop: 5,
    paddingBottom: 3
  },
  paper: {
    backgroundColor: '#fafafa',
    width: '100%',
    padding: 20,
    marginTop: -10,
    paddingTop: 5,
    paddingBottom: 0,
    height: 335,
    textAlign: 'center'
  },
  paperLog: {
    backgroundColor: '#fafafa',
    width: '100%',
    padding: 20,
    marginTop: -10,
    paddingTop: 10,
    paddingBottom: 10,
    maxHeight: 190,
    overflowY: 'auto',
  },
  paper2: {
    backgroundColor: '#fafafa',
    width: '100%',
    padding: 20,
    marginTop: -10,
    paddingTop: 5,
    height: 335,
    textAlign: 'center',
    overflowX: 'hidden',
    overflowY: 'scroll'
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Paper square>
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        </Paper>
      )}
    </div>
  );
}


/* HEADER (PART) */
const ItemHeader = (props) => {
  const classes = props.classes;

  const handleChange = () => {
    props.setExpanded(true);
  }

  const handleCollapse = () => {
    props.setExpanded(false);
  }

  const handleNameChange = (e) => {
    props.onItemNameUpdate(e.target.value);
  }

  return (
    <React.Fragment>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon onClick={handleCollapse} />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <div style={{flexDirection: 'column', height: 60, padding: 0}}>
          <div>
          <Chip color={props.unit_price > 0 ? 'default' : 'secondary'} style={{ minWidth: 70, position: 'relative', top: 4.2, marginRight: 5 }} variant="outlined" size="small" label={<><span style={{ position: 'relative', right: 0, top: 1,  }}>${props.unit_price}</span></>} />
          <Chip style={{ minWidth: 60, position: 'relative', top: 4.2, marginRight: 5 }} variant="outlined" size="small" label={<><FavoriteIcon style={{ fontSize: 14, position: 'relative', top: 2.8 }} htmlColor="#f83208" /> <span style={{ position: 'relative', right: 0, top: 1, color: 'black' }}>{props.likeCount}</span></>} />

          {
            !localStorage.getItem('identifier') && (
              <>
                {props._active ? (
                  props.user?.shop?.type != 'franchiser' ? (
                    <Chip style={{ position: 'relative', top: 4.2, marginRight: 5 }} variant="outlined" color="primary" size="small" label={<small>ACTIVE</small>} />
                  ) : (null)
                ) : (
                  props.user?.shop?.type != 'franchiser' ? (
                    <Chip style={{ position: 'relative', top: 4.2, marginRight: 5 }} variant="outlined" color="secondary" size="small" label={<small>INACTIVE</small>} />
                  ) : (null)
                )}
                {props.hidden ? (
                  props.user?.shop?.type != 'franchiser' ? (
                    <Chip style={{ position: 'relative', top: 4.2, marginRight: 5 }} variant="outlined" color="secondary" size="small" label={<small>HIDDEN</small>} />
                  ) : (null)
                ) : (
                  props.user?.shop?.type != 'franchiser' ? (
                    <Chip style={{ position: 'relative', top: 4.2, marginRight: 5 }} variant="outlined" color="primary" size="small" label={<small>VISIBLE</small>} />
                  ) : (null)
                )} 
              </>
            )
          }
             

          </div>
          <div style={{marginTop: 10}}>
            <InputBase
              fullWidth
              onClick={handleChange}
              onChange={handleNameChange}
              className={classes.heading}
              color="primary"
              defaultValue={props.itemName}
            />
          </div>
        </div>


      </AccordionSummary>
    </React.Fragment>
  );
}

/* BODY (PART) */
const ItemBody = (props) => {
  const socketRef = useRef();

  const classes = props.classes;
  const dispatch = useDispatch();
  const status = useSelector(state => state.item.itemImagesStatus);
  const status2 = useSelector(state => state.item.status);
  const franchisedShops = useSelector(state => state.auth.franchisedShops);

  const open = useSelector(selectItemImageModalOpen);
  const allOptions = useSelector(selectAllAddOns);
  const itemImages = useSelector(selectAllItemImages);
  const user = useSelector(state => state.auth.user);
  const [isLoaded, setIsLoaded] = useState(false); // Loading state

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };


  const handleSwitch = () => {

    // standard
    // franchiser
    // franchisee


    const shopId = localStorage.getItem('shop_id')
    if (socketRef.current == null) {
      socketRef.current = io(DEFAULT_SOCKETS_URL, {
        transports: ["websocket"] // use WebSocket first, if available
      });
    }

    const { current: socket } = socketRef;

    let arr = props.overrides?.filter(override => override.shop == user?.shop?.id);
    if (arr?.length > 0) {
      dispatch(overrideItem({ unit_price: null, shop_id: shopId, item_id: props.item.itemId, hidden: null, active: props.active ? false : true }));
      socket.emit('ticket-status-update-from-dashboard', { status: props.active ? false : true, ticket_id: props.item.itemId, identifier: props.storedIdentifier, categories: props.categories, id: shopId });
    } else {

      socket.emit('ticket-status-update-from-dashboard', { status: props.active ? false : true, ticket_id: props.item.itemId, identifier: props.storedIdentifier, categories: props.categories, id: shopId });
    }

    props.active ? props.onActive(false) : props.onActive(true);
    let item = props.item;
    item.active = props.active ? false : true;
    if (!props.storedIdentifier) {
      dispatch(patchItem(item))
    }
  }


  const handleSwitch2 = (active, shopId) => {
    if (socketRef.current == null) {
      socketRef.current = io(DEFAULT_SOCKETS_URL, {
        transports: ["websocket"] // use WebSocket first, if available
      });
    }

    const { current: socket } = socketRef;

    socket.emit('ticket-status-update-from-dashboard', { status: active, ticket_id: props.item.itemId, identifier: props.storedIdentifier, categories: props.categories, id: shopId.toString() });

  }

  const handleHidden = () => {
    const shopId = localStorage.getItem('shop_id')

    if (socketRef.current == null) {
      socketRef.current = io(DEFAULT_SOCKETS_URL, {
        transports: ["websocket"] // use WebSocket first, if available
      });
    }

    const { current: socket } = socketRef;

    if (franchisedShops && franchisedShops.length > 0) {
      // update and override all attached override_items
      // > write the code here <

      for (let i = 0; i < franchisedShops.length; i++) {
        socket.emit('ticket-hidden-update-from-dashboard', { hidden: props.hidden ? false : true, ticket_id: props.item.itemId, categories: props.categories, id: franchisedShops[i].id.toString() });
      }
    } else {
      socket.emit('ticket-hidden-update-from-dashboard', { hidden: props.hidden ? false : true, ticket_id: props.item.itemId, categories: props.categories, id: shopId });
    }

    props.itemHidden ? props.onHidden(false) : props.onHidden(true);
    let item = props.item;
    item.hidden = props.itemHidden ? false : true;

    dispatch(patchItem(item))
  }

  const handleHidden2 = (hidden, shopId) => {

    if (socketRef.current == null) {
      socketRef.current = io(DEFAULT_SOCKETS_URL, {
        transports: ["websocket"] // use WebSocket first, if available
      });
    }

    const { current: socket } = socketRef;

    socket.emit('ticket-hidden-update-from-dashboard', { hidden: hidden, ticket_id: props.item.itemId, categories: props.categories, id: shopId.toString() });
  }

  const handleTaxableCheck = () => {
    props.taxable ? props.onTaxable(false) : props.onTaxable(true);
  }

  const handleLimitOne = () => {
    props.limitOne ? props.onLimitOne(null) : props.onLimitOne(1);
  }

  const handleQtyControl = () => {
    props.qtyControl ? props.onQtyControl(false) : props.onQtyControl(true);
  }


  const [dndList, setDndList] = useState(
    props.groups ? [...props.groups.map(item => item.option.id)] : []
  );

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchItemImages()); // populates state.category.categories
    }

    if (status2 === 'idle') {
      dispatch(fetchAddOnItemsSimplified());
    }

    if (status2 === 'item detail fetched') {
      setTimeout(() => {
        setIsLoaded(true)
      }, 1000)

    }

  }, [status, status2, dispatch]) // if either postStatus or dispatch changes, fire!

  useEffect(() => {
    dispatch(fetchAddOnItemsSimplified()); // populates state.category.categories
    dispatch(getFranchisedShops({ franchise_id: user?.shop?.franchise?.id }))
    pingKioskActivity();
    
    return () => {
      const { current: socket } = socketRef;
      if (socket?.connected) {
        socket.close();
      }
    }
  }, [])


  const pingKioskActivity = () => {
    const shopId = localStorage.getItem('shop_id')

    if (socketRef.current == null) {
      socketRef.current = io(DEFAULT_SOCKETS_URL, {
        transports: ["websocket"] // use WebSocket first, if available
        });
    }

    const {current: socket} = socketRef;
    console.log('pinging kiosk')
  // Emit with a callback for acknowledgment
    socket.emit(
      'ping-subject-activity',  
      { identifier: props.storedIdentifier, shop_id: shopId.toString(), subject_id: props.itemId, subject: 'Item' },
      (response) => {
        if (response === undefined) {
          // Handle case when response is undefined
          console.error('No acknowledgment received from server');
        } else if (response.status === 'success') {
          // Handle success case
          console.log('Success:', response.message);
        } else {
          
        }    
      }
    );  
}



  if (!isLoaded) {
    return <div style={{ display: 'flex', height: 100, flexDirection: 'column', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}><CircularProgress /></div>; // Display loading indicator until everything is loaded
  }


  // gets called when added, deleted, initialized, or updated
  const handleSimpleListItemOrder = (list) => {
    dispatch(postOptionsInItemsOrder({ items: list, itemId: props.id }));
  }

  let imagesDisp = '';
  if (itemImages.length > 0) {
    imagesDisp = <div>
      <Grid container spacing={2}>
        <Grid item>
          {user?.shop?.type != 'franchisee' ? (
            <IconButton onClick={() => dispatch(setAddItemImageModalOpen(open => !open))}>
              <AddCircleOutlineIcon />
            </IconButton>
          ) : (null)}
        </Grid>
        {itemImages.map((image) => {
          const id = props.item_image ? props.item_image.id : -1
          if (image.id === id) {
            return <Grid item><ItemImage key={image.id} itemHidden={props.itemHidden} itemActive={props.itemActive} itemName={props.itemName} selected itemId={props.id} {...image} width={ITEM_IMG_WIDTH} /></Grid>
          } else {
            return <Grid item><ItemImage key={image.id} itemHidden={props.itemHidden} itemActive={props.itemActive} itemName={props.itemName} itemId={props.id} {...image} width={ITEM_IMG_WIDTH} /></Grid>
          }
        }, props.id)}
      </Grid>

    </div>
  } else if (status === 'loading') {
    imagesDisp = <h1>Loading</h1>;

  } else {
    imagesDisp = <Grid container spacing={2}>  <Grid item>
      {user?.shop?.type != 'franchisee' ? (
        <IconButton onClick={() => dispatch(setAddItemImageModalOpen(open => !open))}>
          <AddCircleOutlineIcon />
        </IconButton>) : (null)}
    </Grid>
    </Grid>;
  }

  const imgUrl = props.item_image ? props.item_image.item_image : ''
  const imgId = props.item_image ? props.item_image.id : 1
  let selectedImgUrl = '';
  return (
    <React.Fragment>
      <Divider />

      <AccordionDetails className={classes.body}>
        <FormGroup row>
          {user?.shop?.type != 'franchisee' && !localStorage.getItem('identifier') ? (<FormControlLabel className={classes.label}
            control={
              <Switch
                checked={props.itemHidden}
                onClick={handleHidden}
                name="hidden"
                color="primary" />
            }
            label="Hidden" />) : (null)}
          <FormControlLabel className={classes.label}
            control={
              <Switch
                checked={props.active}
                onClick={handleSwitch}
                name="checkedB"
                color="primary" />
            }
            label={<>Active <small>{props.storedIdentifier && `(${props.storedIdentifier})`}</small></>}/>
          <FormControlLabel className={classes.label}
            control={<Checkbox checked={props.taxable} onClick={handleTaxableCheck} />}
            label="Taxable"
          />
                    <FormControlLabel className={classes.label}
            control={<Checkbox checked={props.limitOne} onClick={handleLimitOne} />}
            label="Limit 1"
          />
                    <FormControlLabel className={classes.label}
            control={<Checkbox checked={props.qtyControl} onClick={handleQtyControl} />}
            label="Qty Control"
          />
        </FormGroup>

      </AccordionDetails>
      {props.logs && props.logs?.length > 0 && (
        <AccordionDetails>
          <Paper className={classes.paperLog} elevation={0} >
            {props.logs?.map(log => (
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 5, paddingBottom: 5, borderBottom: '1px solid rgba(0,0,0,0.09)' }}>
                <div style={{ display: 'flex', fontFamily: 'Courier New, Courier', fontWeight: 500, flexDirection: 'row', alignItems: 'center', paddingRight: 8 }}>
                  <HistoryIcon style={{ marginRight: 10 }} />
                  {log.description}
                </div>
                <div style={{ fontSize: '0.8em', color: 'gray', background: 'rgba(0,0,0,0.07)', borderRadius: 2, padding: 2, paddingLeft: 5, paddingRight: 5, flexShrink: 0 }}>
                  <span>{moment(log.datetime).format('YYYY-MM-DD h:mm A')}</span>

                </div>
              </div>
            ))}
          </Paper>
        </AccordionDetails>
      )}

      <AccordionDetails style={{ marginBottom: 0 }}>
        <Grid container
          spacing={1}
          direction="row">
          <Grid item xs={12} md={4} style={{ marginBottom: 12 }}>
            <Paper className={classes.paper} elevation={0} >
              <h4>Preview</h4>
              <ClickImage
                disableRipple
                onMouseLeave={() => props.onPreviewImageUngrabbed()}
                onMouseEnter={() => props.onPreviewImageGrabbed()}
                id={imgId}
                itemId={props.itemId}
                x={props.relative_position_x} y={props.relative_position_y}
                headerImg={imgUrl} menuTitle={props.itemName} menuPrice={props.itemAmount} menuCalories={props.itemCalories} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper className={classes.paper2} elevation={0}>
              <h4>Choose Image</h4>
              {imagesDisp}
            </Paper>
          </Grid>
        </Grid>
      </AccordionDetails>
      <AccordionDetails style={{ marginTop: -15 }}>
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
          <OutlinedInput onChange={(e) => props.onItemPriceUpdate(e.target.value)}
            id="outlined-adornment-amount"
            value={props.itemAmount}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            labelWidth={60}
          />
        </FormControl>
      </AccordionDetails>
      <AccordionDetails>
        <FormControl fullWidth variant="outlined">
          <TextField
            id="description-textfield"
            label="Item Description"
            onChange={(e) => props.onDescriptionUpdate(e.target.value)}
            multiline
            inputProps={{ maxLength: 187 }}
            rows={5}
            variant="outlined"
            value={props.itemDescription}
          />
        </FormControl>
      </AccordionDetails>

      <AccordionDetails style={{ flexDirection: 'column' }}>
        <SimpleList
          title={'Options(s) for ' + props.name?.slice(0, 14)}
          onItemRemove={(selectedItems, existingItems) => dispatch(patchRemoveOptionFromItem({ existingItems: existingItems, removedOptions: selectedItems, itemId: props.itemId }))}
          onItemAdd={(list, id) => { dispatch(patchAddOptionsToItem({ existingItems: list, itemId: props.id, optionId: id })) }}
          onItemBulkAdd={(list, ids) => {
            dispatch(patchBulkAddOptionsToItem({ existingItems: list, itemId: props.id, optionIds: ids }))
          }}
          unselectedItems={allOptions}
          name={props.name}
          onOrderUpdate={(list) => handleSimpleListItemOrder(list)}
          hasOptions
          items={props.groups || []}
          type="addons"
        />
        {status2 === 'syncing' ? (
          <Button disabled size="small" style={{ marginTop: 15, fontSize: 12 }} variant="outlined"><SyncIcon style={{ fontSize: 18, marginRight: 5 }} /> Sync this option order to all matching items
          </Button>
        ) : (
          <Button onClick={() => {
            dispatch(syncOptionsOrder({ reference_item_id: props.id }));
          }} size="small" style={{ marginTop: 15, fontSize: 12 }} variant="outlined"><SyncIcon style={{ fontSize: 18, marginRight: 5 }} /> Sync this option order to all matching items
          </Button>
        )}

      </AccordionDetails>

      {user?.shop?.type === 'franchiser' ? (
        <AccordionDetails>
          <OverrideItemTable onActiveOverride={(active, shopId) => handleSwitch2(active, shopId)} onHiddenOverride={(hidden, shopId) => handleHidden2(hidden, shopId)} overrides={props.overrides} item={props.item} shops={franchisedShops.filter(shop => shop.id != user?.shop?.id)} />
        </AccordionDetails>
      ) : (
        null
      )}
      <AccordionDetails>

      </AccordionDetails>
    </React.Fragment>
  );
}

/* FOOTER (PART) */
const ItemFooter = (props) => {
  const classes = props.classes;
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const putItemStatus = useSelector(state => state.item.putItemStatus);

  return (
    <React.Fragment>
      <Divider />
      <AccordionDetails className={classes.footer}>
        <Grid container
          direction="row"
          justify="space-between"
          alignItems="center">

          {user?.shop?.type != 'franchisee' || user?.shop?.delete_item_allowed ? (
            <Button onClick={() => props.onDelete()} color="secondary">DELETE</Button>
          ) : (
            null
          )}
          {user?.shop?.type != 'franchisee' || user?.shop?.edit_item_allowed ? (
            <Button disabled={putItemStatus === 'loading'} onClick={() => { dispatch(patchItem(props.item)) }}>SAVE</Button>
          ) : (
            null
          )}
        </Grid>
      </AccordionDetails>
    </React.Fragment>
  );
}

const AddedItem = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [active, setActive] = useState(props.active);
  const [hidden, setHidden] = useState(props.hidden || false);
  const [description, setDescription] = useState(props.description || '');
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [taxable, setTaxable] = useState(props.taxable || true);
  const [limitOne, setLimitOne] = useState(props.limit_per_order);
  const [qtyControl, setQtyControl] = useState(props.qty_control || true);

  const [itemName, setItemName] = useState(props.name || '');
  const [amount, setAmount] = useState(props.unit_price || 0);
  const [calories, setCalories] = useState(props.calories || 0);
  const [sortableDisabled, setSortableDisabled] = useState(false);
  const user = useSelector(state => state.auth.user);
  const status = useSelector(state => state.item.status);

  const identifier = localStorage.getItem('identifier');

  useEffect(() => {
    // populate "details" of  Item
    /*
      - fetch item detail

      const index = state.items.findIndex(item => item.id == input: id)
      state.items[index] = item

      props.active
      props.hidden
      props.description
      props.taxable
      props.name
      props.unit_price
      props.calories
      - fetch overrides (ItemOverride)
      - fetch ItemOptionGroup
      - fetch ItemImage
    */
    if (expanded) {
      dispatch(fetchItemDetail({ id: props.id }))
      dispatch(fetchItemOptionGroup({ item_id: props.id })); // id:

    }
  }, [expanded])

  useEffect(() => {
    if (status === 'item detail fetched') {
      if (typeof props.item_image === 'number') {
        dispatch(fetchItemImage({ item_id: props.id, id: props.item_image }))
      }

    }
  }, [status])

  useEffect(() => {
    let overrideArr = []
    if (props.overrides?.length > 0) {
      overrideArr = props.overrides?.filter(override => override.shop === user?.shop?.id);
    }

    if (overrideArr.length > 0) {
      setActive(overrideArr[0].active)
      setAmount(overrideArr[0].unit_price)
      setHidden(overrideArr[0].hidden)
    }

  }, [props.overrides])

  useEffect(() => {
    if (expanded) {
      setAmount(props.unit_price)
      setActive(props.active)
      setHidden(props.hidden)
      setTaxable(props.taxable)
      setItemName(props.name)
      setCalories(props.calories || 0)
      setDescription(props.description)
      setLimitOne(props.limit_per_order)
      setQtyControl(props.qty_control)

      item = {
        targetValue: props.item_image || null,
        itemId: props.id,
        name: itemName,
        calories: calories,
        unit_price: amount,
        active: active,
        hidden: hidden,
        taxable: taxable,
        description: description,
        limit_per_order: limitOne,
        qty_control: qtyControl
      };
    }

  }, [props.unit_price, props.active, props.hidden, props.taxable, props.name, props.limit_per_order, props.qty_control, props.calories, props.description])

      
  useEffect(() => {
    const shopId = localStorage.getItem('shop_id');
  
    const handlePongSubjectActivity = (payload) => {
      console.log('mounting')
      if (
        payload.data?.subject_id === props.id && 
        payload.data?.subject === 'Item' && 
        payload.data?.identifier === identifier && 
        shopId === payload.data?.shop_id
      ) {
        setActive(payload?.data.activity)
      } 
    };
  
    if (identifier && props.id && expanded) {
      // Check if socket is initialized, if not, initialize it
      if (!props.socket) {
        props.socket = io(DEFAULT_SOCKETS_URL, {
          transports: ["websocket"], // use WebSocket first, if available
        });
      }
  
      // Attach the event listener
      props.socket.on('pong-subject-activity', handlePongSubjectActivity);
    }
  
    // Cleanup listener on unmount or dependency change
    return () => {
      if (props.socket) {
        console.log('exit')
        props.socket.off('pong-subject-activity', handlePongSubjectActivity);
      }
    };
  }, [identifier, props.id, expanded]); // include identifier and props.id as dependencies
  

  const handleExpansion = (e) => {
    if (!expanded) {
      setExpanded(true);
    }
  };

  const handleGrab = (grabbed) => {
    setSortableDisabled(grabbed);
  }

  let item = {
    targetValue: props.item_image || null,
    itemId: props.id,
    name: itemName,
    calories: calories,
    unit_price: amount,
    active: active,
    hidden: hidden,
    taxable: taxable,
    description: description,
    limit_per_order: limitOne,
    qty_control: qtyControl
  };

  

  return (
    <>
      <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded} onClick={handleExpansion} className={classes.accordionRoot}>
        <ItemHeader hidden={props.hidden} unitPrice={props.unit_price} likeCount={props.like_count} user={user} onItemNameUpdate={setItemName} itemName={itemName} setExpanded={setExpanded} classes={classes} {...props} _active={active} />
        {expanded ? <ItemBody storedIdentifier={identifier} logs={props.recent_logs} overrides={props.overrides || []} itemDescription={description} item={item} onPreviewImageUngrabbed={() => handleGrab(false)} onPreviewImageGrabbed={() => handleGrab(true)} itemId={props.itemId} {...props} itemAmount={amount} itemCalories={calories} onItemCaloriesUpdate={setCalories} onDescriptionUpdate={setDescription} onItemPriceUpdate={setAmount} itemHidden={hidden} itemActive={active} itemName={itemName} onHidden={setHidden} onActive={setActive} active={active} onQtyControl={setQtyControl} qtyControl={qtyControl} onLimitOne={setLimitOne} limitOne={limitOne}  onTaxable={setTaxable} taxable={taxable} classes={classes} /> : null}
        <ItemFooter onDelete={() => setConfirmDelete(true)} item={item} itemHidden={hidden} itemActive={active} classes={classes} itemName={itemName} {...props} />
      </Accordion>
      <ConfirmDeleteModal onDelete={() => { dispatch(removeItem(props.id)); setConfirmDelete(false); }} onClose={() => setConfirmDelete(false)} open={confirmDelete} />

    </>
  );
}

export default AddedItem;
