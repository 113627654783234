import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPayouts, clearError, getPayoutTransactions, clearTransactions, getPayoutBalance, payout } from './orderSlice';
import { getFranchisedShops } from '../auth/authSlice';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Dialog, Select, MenuItem } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import currency from 'currency.js'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles((theme) => ({
  table: {
    width: 800,
    [theme.breakpoints.down('md')]: {
      width: 660
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100%)`,
    }
  },
  root2: {
    width: 800,
    [theme.breakpoints.down('md')]: {
      width: 660
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100%)`,
    },
  },
  appbar: {
    marginBottom: 20,
    width: 800,
    [theme.breakpoints.down('md')]: {
      width: 660
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100%)`,
    }
  },
  payout: {
    width: 800,
    [theme.breakpoints.down('md')]: {
      width: 660
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100%)`,
    },
    marginBottom: 10,
  },
}));

const convertUnixToLocal = timestamp => {
  const date = new Date(timestamp * 1000);

  return date.toLocaleString();
}


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Paper square>
          {children}
        </Paper>
      )}
    </div>
  );
}

const InstantPayout = (props) => {
  const dispatch = useDispatch();
  const availablePayoutMethods = useSelector(state => state.order?.availablePayoutMethods)
  const availableBalance = useSelector(state => state.order?.availableBalance)
  const payoutError = useSelector(state => state.order?.payoutError)
  
  const user = useSelector(state => state.auth.user);
  const status = useSelector(state => state.order?.status)

  const [disabled, setDisabled] = useState(false)
  const [showPayoutSuccess, setShowPayoutSuccess] = useState(false) 
  const [selectedMethod, setSelectedMethod] = useState('');
  const classes = useStyles();

  useEffect(() => {
    dispatch(getPayoutBalance());

    return () => {
      dispatch(clearError());
      setShowPayoutSuccess(false);
    }
  }, [])

  useEffect(() => {
    dispatch(clearError());
  }, [dispatch])

  useEffect(() => {
    if (status === 'instant payout success') {
      dispatch(getPayoutBalance());
      handleInstantPayoutComplete(); 
    }
  }, [status])

  const handleInstantPayoutComplete = () => {
    dispatch(getPayouts({ id: user?.shop?.id }))
    setShowPayoutSuccess(true)
}

  useEffect(() => {
    if (availablePayoutMethods?.length > 0) {
      setSelectedMethod(availablePayoutMethods[0]?.id)
      console.log(availablePayoutMethods[0]?.id)
    }
  }, [availablePayoutMethods])

  const handleMethodChange = (event) => {
    setSelectedMethod(event.target.value);
  };

  // Find the selected payout method object based on selectedMethod (id)
  const selectedPayoutMethod = availablePayoutMethods?.find(
    method => method.id === selectedMethod
  );

  // Check if the selected method has "instant" payout available
  const hasInstantPayout = selectedPayoutMethod?.available_payout_methods?.includes("instant");


  return (
    <Paper className={classes.payout}>
        {showPayoutSuccess && (
  <div style={{ fontWeight: 500, margin: 8, marginTop: 15, padding: 6, border: '1px solid rgba(0,120,0,1)', borderRadius: 3, display: 'flex', alignItems: 'center' }}>
    <CheckCircleOutlineIcon style={{ color: 'rgba(0,120,0,1)', marginRight: 8 }} />
    Instant payout was successful and has been processed.
  </div>
)}
             {payoutError && <div style={{ fontWeight: 500, margin: 8, marginTop: 15, padding: 6, border: '1px solid rgba(120,0,0,1)', borderRadius: 3, display: 'flex', alignItems: 'center' }}>
    <ErrorOutlineIcon style={{ color: 'rgba(120,0,0,1)', marginRight: 8 }} />
    {payoutError}
  </div>}
      <div style={{ padding: 10, display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', borderBottom: '1px solid rgba(0,0,0,0.05)' }}>
        {hasInstantPayout ? (
          <div>
            <span style={{ fontWeight: 500, fontSize: 15 }}>Instant Payout</span>
            <span style={{ padding: 2, paddingLeft: 5, paddingRight: 5, marginLeft: 6, fontWeight: 500, fontSize: 10, color: 'white', borderRadius: 4, backgroundColor: 'rgb(59, 201, 78)' }}>AVAILABLE</span>

          </div>
        ) : (
          <div>
            <span style={{ fontWeight: 500, fontSize: 15 }}>Instant Payout</span>
            <span style={{ padding: 2, paddingLeft: 5, paddingRight: 5, marginLeft: 6, fontWeight: 500, fontSize: 10, color: 'black', borderRadius: 4, backgroundColor: 'rgb(255, 201, 120)' }}>UNAVAILABLE</span>
          </div>
        )}
        {availablePayoutMethods && (
          <Select

            size="sm"
            value={selectedMethod}
            onChange={handleMethodChange}
            displayEmpty
            style={{ minWidth: 200, }}

          >
            {availablePayoutMethods?.map((method, index) => (
              <MenuItem key={index} value={method.id}>
                {method?.brand} {method.bank_name} {method.last4}
              </MenuItem>
            ))}
          </Select>
        )}
      </div>

      <div style={{ padding: 10, paddingBottom: 10, display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', alignItems: 'center' }}>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div style={{marginRight: 10}}>
            <span style={{ fontSize: 13, marginRight: 10, }}> INSTANT PAYOUT</span>
            <span style={{ backgroundColor: 'rgba(0,0,0,0.09)', borderRadius: 4, fontSize: 13, paddingLeft: 5, paddingRight: 5 }}>{currency((availableBalance?.instant_available?.[0]?.net_available?.[0]?.amount ?? 0) / 100).format()}      </span>
          </div>
          <div>
            <span style={{ fontSize: 13, marginRight: 10, }}> PENDING BALANCE</span>
            <span style={{ backgroundColor: 'rgba(0,0,0,0.09)', borderRadius: 4, fontSize: 13, paddingLeft: 5, paddingRight: 5 }}> {currency((availableBalance?.pending?.[0]?.amount ?? 0) / 100).format()}</span>
          </div>
        </div>
      </div>
      <div style={{ fontSize: 11, paddingLeft: 10, color: 'rgba(0,0,0,0.55)', marginTop: -10, paddingBottom: 8 }}>
        Instant payouts are subject to a 1.5% fee.
      </div>
      <Button fullWidth variant="contained" onClick={
          () => {
            setDisabled(true);
            dispatch(payout({ external_id: selectedMethod }));
          }} disabled={currency((availableBalance?.instant_available?.[0]?.net_available?.[0]?.amount ?? 0)).value === 0 || disabled} disableElevation color="primary" size="small">Instant Payout

          
        </Button>
        <p style={{paddingLeft: 10, fontWeight: 500, color: 'rgba(200,100,0)'}}>If your payout submission fails or encounters an error, please go to the <a href="/account">My Account</a> page. Scroll down to <b>Registered Cards</b> and add a new credit/debit card to enable payout processing and try again.</p>
        </Paper>
  )
};

const PayoutStatus = (props) => {
  const status = props.status;

  let statusDisplay = null;

  if (status == 'paid') {
    statusDisplay = <Chip style={{ textTransform: 'uppercase', background: '#3bc94e' }} color="primary" label={status} />
  } else if (status == 'pending' || status == 'in_transit') {
    statusDisplay = <Chip style={{ textTransform: 'uppercase' }} label={status} />
  } else {
    statusDisplay = <Chip style={{ textTransform: 'uppercase' }} color="secondary" label={status} />
  }

  return statusDisplay
}

const Payout = (props) => {
  const [open, setOpen] = useState(false);
  const [currentPayoutId, setCurrentPayoutId] = useState();
  const [value, setValue] = useState(0);
  const [currentShop, setCurrentShop] = useState(null)

  const payouts = useSelector(state => state.order.payouts);
  const user = useSelector(state => state.auth.user);
  const franchisedShops = useSelector(state => state.auth.franchisedShops);

  const classes = useStyles();
  const dispatch = useDispatch();


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = (id) => {
    setCurrentPayoutId(id)
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };




  useEffect(() => {
    if (user) {
      if (user?.shop?.type == 'franchiser') {
        dispatch(getFranchisedShops({ franchise_id: user?.shop?.franchise?.id }))
      } else {
        dispatch(getPayouts({ id: user?.shop?.id }))
      }
    }
  }, [user])

  useEffect(() => {
    if (franchisedShops && franchisedShops.length > 0) {
      setCurrentShop(franchisedShops[0].id)
    }
  }, [franchisedShops])

  useEffect(() => {
    if (currentShop) {
      dispatch(getPayouts({ id: currentShop }))
    }
  }, [currentShop])



  return (
    <>
      {user?.shop?.type == 'franchiser' ? (
        <>
          <div className={classes.appbar}>
            <AppBar position="static" color="white" elevation={1}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
                aria-label="scrollable tabs for franchise access control"
              >
                {franchisedShops?.map(
                  shop => {
                    return user.shop.id != shop.id ? (
                      <Tab onClick={() => setCurrentShop(shop.id)} key={shop.id} label={shop.business_name + ' ' + shop.city} />
                    ) : (
                      null
                    )
                  }
                )}
              </Tabs>
            </AppBar>
          </div>
          <div className={classes.root2}>
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Amount</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell align="right">Initiated</TableCell>
                    <TableCell align="right">Est. Arrival</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payouts.map(payout => (
                    <TableRow onClick={() => handleClickOpen(payout.id)} key={payout.id}>
                      <TableCell component="th" scope="row">
                        {currency(payout.amount / 100).format()}
                      </TableCell>
                      <TableCell >
                        <PayoutStatus status={payout.status.replace('_', ' ')} />
                      </TableCell>
                      <TableCell align="right">{convertUnixToLocal(payout.created)}</TableCell>
                      <TableCell align="right">{convertUnixToLocal(payout.arrival_date)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      ) : (
        <>

          <InstantPayout />
          <TableContainer className={classes.table} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Amount</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell align="right">Initiated</TableCell>
                  <TableCell align="right">Est. Arrival</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payouts.map(payout => (
                  <TableRow onClick={() => handleClickOpen(payout.id)} key={payout.id}>
                    <TableCell component="th" scope="row">
                      {currency(payout.amount / 100).format()}
                    </TableCell>
                    <TableCell >
                      <PayoutStatus status={payout.status.replace('_', ' ')} />
                    </TableCell>
                    <TableCell align="right">{convertUnixToLocal(payout.created)}</TableCell>
                    <TableCell align="right">{convertUnixToLocal(payout.arrival_date)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      <PayoutSummary shopId={user?.shop?.type === 'franchiser' ? currentShop : user?.shop?.id} payoutId={currentPayoutId} open={open} onClose={handleClose} />
    </>

  )
};

const PayoutSummary = (props) => {
  const [charges, setCharges] = useState(0);
  const [refundTotal, setRefundTotal] = useState(0);
  const [adjustment, setAdjustments] = useState(0);
  const [total, setTotal] = useState(0);
  const [payout, setPayout] = useState(0);

  const classes = useStyles();
  const dispatch = useDispatch();
  const transactions = useSelector(state => state.order.transactions);
  const hasMore = useSelector(state => state.order.hasMore);

  const handleClose = () => {
    props.onClose();
  };

  useEffect(() => {
    if (props.payoutId) {
      dispatch(clearTransactions())
      dispatch(getPayoutTransactions({ id: props.payoutId, shop_id: props.shopId }))
    }
  }, [props.payoutId])

  useEffect(() => {
    getCharges();
    getRefundTotal();
    getAdjustments();
    getTotal();
    getPayout();
  }, [transactions])

  function getCharges() {
    const filteredTrans = transactions.filter(transaction => transaction.type === 'charge')
    setCharges(filteredTrans.length)
  }

  function getRefundTotal() {
    const filteredTrans = transactions.filter(transaction => transaction.type === 'refund')
    let sum = 0;
    for (let i = 0; i < filteredTrans.length; i++) {
      sum += filteredTrans[i].amount;
    }

    setRefundTotal(currency(sum / 100).format())
  }

  function getAdjustments() {
    const filteredTrans = transactions.filter(transaction => transaction.type === 'adjustment')
    let sum = 0;
    for (let i = 0; i < filteredTrans.length; i++) {
      sum += filteredTrans[i].amount;
    }

    setAdjustments(currency(sum / 100).format())
  }

  function getTotal() {
    const filteredTrans = transactions.filter(transaction => transaction.type === 'charge')
    let sum = 0;
    for (let i = 0; i < filteredTrans.length; i++) {
      sum += filteredTrans[i].net;
    }

    setTotal(currency(sum / 100).format())
  }

  function getPayout() {
    const filteredTrans = transactions.filter(transaction => transaction.type === 'payout')
    let sum = 0;
    for (let i = 0; i < filteredTrans.length; i++) {
      sum += filteredTrans[i].net;
    }

    setPayout(currency(sum * -1 / 100).format())
  }

  return (
    <Dialog maxWidth={'md'} onClose={handleClose} open={props.open}>
      <h3 style={{ paddingLeft: 15 }}>Transactions (Charges and Refunds)<br />
      </h3>
      <TableContainer className={classes.table} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Charged on</TableCell>
              <TableCell>Available on</TableCell>
              <TableCell align="right">Net</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.filter(transaction => transaction.type == 'refund' || transaction.type == 'charge').map(transaction => (
              <TableRow>
                <TableCell style={{ textTransform: 'uppercase' }}>
                  {transaction.type}
                </TableCell>
                <TableCell>{convertUnixToLocal(transaction.created)}</TableCell>
                <TableCell>{convertUnixToLocal(transaction.available_on)}</TableCell>
                <TableCell align="right">{currency(transaction.net / 100).format()}</TableCell>
              </TableRow>
            ))}

          </TableBody>

        </Table>
        <div style={{ textAlign: 'center' }}>
          {hasMore ? (
            <Button onClick={() => dispatch(getPayoutTransactions({ id: props.payoutId, shop_id: props.shopId, startingAfter: transactions[transactions.length - 1].id }))} fullWidth>LOAD MORE</Button>
          ) : (
            null
          )}
        </div>

      </TableContainer>
    </Dialog>
  )
}

export default Payout;
