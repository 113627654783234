import React, { useEffect, useState, useRef } from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TableItem from '../TableItem'
import ConfirmDeleteModal from '../../modal/ConfirmDeleteModal';
import { CheckoutDialog } from '../CheckoutDialog'
import { fetchTables, deleteTable, setEmitted, updateTableAvail, postTableInstance, updateTableHold, deactivateInstance } from '../tableSlice'
import useActionStatusTracker from '../../../hooks/useActionStatusTracker';
import { setStatus, postSplitPmt } from '../../order/orderSlice'
import { useSelector, useDispatch } from 'react-redux';
import { io } from "socket.io-client";
import { makeStyles } from '@material-ui/core/styles';

const DEFAULT_SOCKETS_URL = 'https://sockets.bigkiosksolution.com';
let socket = null;


const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      width: `71.1vw`,
     },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
     },
  },
}));

const CardView = (props) => {
  const socketRef = useRef();
  const dispatch = useDispatch();
  // TODO: move em to table js
  const tables = useSelector(state => state.table.tables)
  const status = useSelector(state => state.table.status)
  const status2 = useSelector(state => state.order.status)
  const classes = useStyles();

  const [tableDelete, setTableDelete] = useState(-1)

  const handleTableDelete = () => {
    setTableDelete(-1)
    dispatch(deleteTable({id: tableDelete}))
  }


  return (
    <>
    <Grid container  justifyContent="space-around" spacing={1}>
        {tables.map((table, index) => <Grid key={table.id}  item md={4} lg={3} xs={12}><TableItem {...props} onDeleteTable={() => setTableDelete(table.id)} {...table} /></Grid>)}
    </Grid>
    <ConfirmDeleteModal open={tableDelete != -1} onClose={() => setTableDelete(-1)} onDelete={handleTableDelete} />
    </>
  )
}

export default CardView;
