import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const ConfirmDeleteModal = (props) => {

  return (
    <Dialog maxWidth="sm"  onClose={() => props.onClose()} fullWidth={50} maxWidth="sm" open={props.open}>
      <DialogTitle id="form-dialog-title">Delete</DialogTitle>
      <DialogContent>
        <h3 style={{margin: 0, fontWeight: 400}}>Are you sure you want to delete?</h3>
      </DialogContent>
      <DialogActions>
      <Button onClick={() => props.onClose()} color="default">
        Cancel
      </Button>
      <Button onClick={() => {props.onDelete(); props.onClose()}}  color="secondary">
        DELETE
      </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDeleteModal;
