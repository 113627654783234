import React from 'react';
import Kiosk from '../../features/kiosk/Kiosk';


const KioskComponent = () => {
  return (
      <Kiosk />
  );
};

export default KioskComponent;
