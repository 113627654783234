import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET, DELETE, POST_SOCKET_IO, POST, PUT, PATCH, POST_FILE } from '../../api/DashboardAPI';

export const fetchTickets = createAsyncThunk('order/fetchTickets', async (payload) => {
  const identifier = payload === null ? '' : payload; // Set identifier to '' if payload is null
  const response = await GET('/dashboard/order/get_kds_tickets/?identifier=' + identifier);
  return response.data; // payload
});

export const fetchOneTicket = createAsyncThunk('order/fetchOneTicket', async (payload) => {
  const response = await POST('/dashboard/order/fetch_one_ticket/', payload);
  return response.data; // payload
});

export const fetchTicketById = createAsyncThunk('order/fetchTicketById', async (payload) => {
  const response = await POST('/dashboard/order/get_ticket/', payload);
  return response.data; // payload
});

export const getTicketByIdAndAdd = createAsyncThunk('order/getTicketByIdAndAdd', async (payload) => {
  const response = await POST('/dashboard/order/get_ticket/', payload);
  return response.data; // payload
});

export const fetchStations = createAsyncThunk('station/fetchStations', async () => {
  const response = await GET('/dashboard/station/');
  return response.data.results; // payload
});

export const updateTicketStatus = createAsyncThunk('order/updateTicketStatus', async (obj) => {
  const response = await PATCH('/dashboard/order/update_ticket_status/', {status: obj.status, id: obj.id});
  return response.data; // payload
});

export const updateItemDone = createAsyncThunk('order-item/updateItemDone', async (obj) => {
  const response = await POST('/dashboard/order-item/' + obj.itemId + '/set_item_done/', {done: obj.done});
  return obj; // payload
});


export const updateOrderHidden = createAsyncThunk('order/updateOrderHidden', async (obj) => {
  const response = await POST('/dashboard/order/' + obj.orderId + '/set_hidden/', {hidden: obj.hidden});
  return obj; // payload
});


export const postStation = createAsyncThunk('station/postStation', async (obj) => {
  const response = await POST('/dashboard/station/', obj);
  return response.data; // payload
});

export const removeStation = createAsyncThunk('station/removeStation', async (id) => {
  const response = await DELETE('/dashboard/station/' + id + '/');
  return id; // payload
});

export const patchAddItemsToStation = createAsyncThunk('station/patchAddItemsToStation', async (obj) => {
  let existingItems = [];
  obj.existingItems.map(item => existingItems.push(item));
  existingItems.push(obj.addedItem);
  const response = await POST('/dashboard/station/' + obj.stationId + '/add_item_to_station/', {itemId: obj.itemId});
  return { addedItem: response.data, id: obj.stationId };
});


export const patchBulkAddItemsToStation = createAsyncThunk('addon/patchBulkAddItemsToStation', async (obj) => {
    const response = await POST('/dashboard/station/' + obj.stationId + '/bulk_add_item_to_station/', {items: obj.items});  // Send all items
    return {
      addedItemIds: response.data['added_item_ids'],
      groups: response.data['station']['items'],
      id: obj.stationId
    };
  }
);

export const patchRemoveItemsFromStation = createAsyncThunk('station/patchRemoveItemsFromStation', async (obj) => {
  let items = obj.existingItems.slice();
  obj.removedItems.map(id => {
    const index = items.findIndex(item => item === id);
    items.splice(index, 1);
  });
  const response = await POST('/dashboard/station/' + obj.stationId + '/remove_item_from_station/', {removedItems: obj.removedItems});
  return {id: obj.stationId, items: response.data.items};
});


export const fetchApprovalTicket = createAsyncThunk('kitchen/fetchApprovalTicket', async (payload) => {
  const response = await GET('/dashboard/item-option/' + payload.item_option_id + '/fetch_approval_ticket/');
  return response.data; // payload
});


export const kitchenSlice = createSlice({
  name: 'kitchen',
  initialState: {
    tickets: [],
    stations: [],
    status: 'idle',
    approvalTicket: null /* { name: 'Bring Your Own Discount' } */
  },
  reducers: {
    setTickets: (state, action) => {
      state.tickets = action.payload;
    },
    setStation: (state, action) => {
      state.tickets = action.payload;
    },
    hideTicket: (state, action) => {
      const orderId = action.payload.id;
      const ticketIndex = state.tickets.findIndex(ticket => ticket.id === orderId)
      if (ticketIndex !== -1) {
        const currentTicket = state.tickets[ticketIndex];
           // recreate the ticket object with the same values
           // and update the 'hidden' attribute
           state.tickets[ticketIndex] = { ...currentTicket, hidden: action.payload.status };
      }
    },
    clearApprovalTicket: (state) => {
      state.approvalTicket = null;
      state.status = 'idle'

    },
    addApprovalTicket: (state, action) => {
      state.approvalTicket = {name: action.payload};
      state.status = 'fetching item option success'
    },
    updateOrderItemStatus: (state, action) => {
      const orderId = action.payload.order_id;
      const orderItemId = action.payload.order_item_id;
      const status = action.payload.status;
      const ticketIndex = state.tickets.findIndex(ticket => ticket.id === orderId)
      let itemIndex;
      if (ticketIndex !== -1) {
        // add an 'hidden' bool attr
        itemIndex = state.tickets[ticketIndex].order_items.findIndex(item => item.id === orderItemId) // boolean
      }

      if (itemIndex !== -1) {
        state.tickets[ticketIndex].order_items[itemIndex].done = status
      }
    },
    updateOrderStatus: (state, action) => {
      const orderId = action.payload.id;
      const status = action.payload.status;
      const ticketIndex = state.tickets.findIndex(ticket => ticket.id === orderId)
      if (ticketIndex !== -1) {
        // add an 'hidden' bool attr
        state.tickets[ticketIndex].status = status // boolean
      }
    }
  },
  extraReducers: {
    [fetchStations.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchStations.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // Add any fetched posts to the array
      state.stations = [...action.payload]
    },
    [fetchStations.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
    [fetchTickets.pending]: (state, action) => {
      state.status = 'ticket fetching'
    },
    [fetchTickets.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // Add any fetched posts to the array
      state.tickets = [...action.payload]
    },
    [fetchTickets.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
    [fetchOneTicket.pending]: (state, action) => {
      state.status = 'ticket fetching'
    },
    [fetchOneTicket.fulfilled]: (state, action) => {
      const index = state.tickets.findIndex(ticket => ticket.id === action.payload.id)
      if (index === -1) { // ticket not avail
        state.tickets.unshift(action.payload) // add a new one
      } else { // ticket avail
        state.tickets[index] = action.payload // just update it
      }
      state.status = 'fetch one ticket success'
    },
    [fetchOneTicket.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },

    [fetchTicketById.pending]: (state, action) => {
      state.status = 'ticket fetching'
    },
    [fetchTicketById.fulfilled]: (state, action) => {
      const index = state.tickets.findIndex(ticket => ticket.id === action.payload.id)
      if (index !== -1) {
        state.tickets[index] = action.payload;
        state.status = 'fetch ticket succeeded'
      }
    },
    [fetchTicketById.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },

    [getTicketByIdAndAdd.pending]: (state, action) => {
      state.status = 'ticket fetching'
    },
    [getTicketByIdAndAdd.fulfilled]: (state, action) => {

      state.tickets.unshift(action.payload)
      state.status = 'fetching and adding ticket succeeded'
    },
    [getTicketByIdAndAdd.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },

    [updateTicketStatus.pending]: (state, action) => {
      state.status = 'updating ticket'
    },
    [updateTicketStatus.fulfilled]: (state, action) => {
      if (action.payload.id) {
        const id = action.payload.id;

        const index = state.tickets.findIndex(ticket => ticket.id === id);
        state.tickets[index].status = action.payload.status;
        state.status = 'succeeded'
      }
      // Add any fetched posts to the array
    },
    [updateTicketStatus.rejected]: (state, action) => {
      state.status = 'failed updating ticket'
      state.error = action.error.message
    },
    [postStation.pending]: (state, action) => {
      state.status = 'loading'
    },
    [postStation.fulfilled]: (state, action) => {
      state.status = 'added'
      state.stations = state.stations.concat(action.payload);
      // Add any fetched posts to the array
    },
    [postStation.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
    [removeStation.pending]: (state, action) => {
      state.status = 'deleting'
    },
    [removeStation.fulfilled]: (state, action) => {
      state.status = 'deleted'
      //state.items = state.items.filter((category, idx) => idx !== action.payload);
      const index = state.stations.findIndex(item => item.id === action.payload);
      state.stations.splice(index, 1);
    },
    [removeStation.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
    [patchAddItemsToStation.pending]: (state, action) => {
      state.status = 'loading'
    },
    [patchAddItemsToStation.fulfilled]: (state, action) => {
      state.status = 'filter items added'
      const index = state.stations.findIndex(item => item.id === action.payload.id);
      state.stations[index].items = [...action.payload.addedItem]

    },
    [patchAddItemsToStation.rejected]: (state, action) => {
      state.status = 'failed'
    },

    [patchBulkAddItemsToStation.pending]: (state, action) => {

      state.status = 'loading'
    },
    [patchBulkAddItemsToStation.fulfilled]: (state, action) => {
      const { addedItemIds, groups, id } = action.payload;
      const index = state.stations.findIndex(item => item.id === action.payload.id);

      if (index !== -1) {
        state.stations[index].items = groups;
      }

      state.status = 'filter items added'
    },
    [patchBulkAddItemsToStation.rejected]: (state, action) => {

      state.status = 'failed'
    },

    [patchRemoveItemsFromStation.pending]: (state, action) => {
      state.status = 'loading'
    },
    [patchRemoveItemsFromStation.fulfilled]: (state, action) => {
      const index = state.stations.findIndex(item => item.id === action.payload.id);
      let items = []
      action.payload.items.map(item => items.push(item.item));
      state.stations[index].items = items;
      state.status = 'filter items deleted'
    },
    [patchRemoveItemsFromStation.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [updateItemDone.pending]: (state, action) => {
      state.status = 'loading'
    },
    [updateItemDone.fulfilled]: (state, action) => {
      state.status = 'order item update success'
      const orderId = action.payload.ticketId
      const itemId = action.payload.itemId
      const ticketIdx = state.tickets.findIndex(ticket => ticket.id === orderId);
      let itemIdx;
      if (ticketIdx !== -1) {
        itemIdx = state.tickets[ticketIdx].order_items.findIndex(item => item.id === itemId);
      }
      if (itemIdx !== -1) {
        state.tickets[ticketIdx].order_items[itemIdx].done = action.payload.done
      }
    },
    [updateItemDone.rejected]: (state, action) => {
      state.status = 'failed'
    },

    [updateOrderHidden.pending]: (state, action) => {
      state.status = 'loading'
    },
    [updateOrderHidden.fulfilled]: (state, action) => {
      state.status = 'order item update success'
      const orderId = action.payload.orderId
      const ticketIdx = state.tickets.findIndex(ticket => ticket.id === orderId);
      if (ticketIdx !== -1) {
        state.tickets[ticketIdx].hidden = action.payload.hidden;
      }
    },
    [updateOrderHidden.rejected]: (state, action) => {
      state.status = 'failed'
    },

    [fetchApprovalTicket.pending]: (state, action) => {
      state.status = 'fetching item option name'
    },
    [fetchApprovalTicket.fulfilled]: (state, action) => {
      state.approvalTicket = { 
        name: action.payload.name 
      }
      state.status = 'fetching item option success'
    },
    [fetchApprovalTicket.rejected]: (state, action) => {
      state.status = 'fetching item option '
    },
    
  }
});


export const { addApprovalTicket, updateOrderStatus, setTickets, hideTicket, updateOrderItemStatus, clearApprovalTicket } = kitchenSlice.actions;

export default kitchenSlice.reducer;

export const selectAllTickets = state => state.kitchen.tickets;
export const selectAllStations = state => state.kitchen.stations;
