import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { POST, GET } from '../../api/DashboardAPI';

export const postProcessPayment = createAsyncThunk('dashboard/postProcessPayment', async (obj) => {
  const response = await POST('/dashboard/process_payment_intent_dashboard/', obj);
  return response.data; // payload
});


export const cancelReaderAction = createAsyncThunk('dashboard/cancelReaderAction', async (payload) => {
  const response = await POST('/dashboard/action_reader_cancel_dashboard/', payload);
  return response.data; // payload
});


export const postConnectToken = createAsyncThunk('dashboard/ConnectionToken', async (obj) => {
  const response = await POST('/dashboard/connection_token/');
  return response.data; // payload
});

export const postPairReader = createAsyncThunk('dashboard/RegisterReader', async (obj) => {
  const response = await POST('/dashboard/register_reader/', obj);
  return response.data; // payload
});

export const updatePaymentIntent = createAsyncThunk('dashboard/updatePaymentIntent', async (obj) => {
  const response = await POST('/dashboard/dashboard_update_payment_intent/', obj);
  return response.data; // payload
});


export const postRemoveReader = createAsyncThunk('dashboard/postRemoveReader', async (payload) => {
  const response = await POST('/dashboard/remove_reader_from_dashboard/', payload);
  return response.data; // payload
});

export const postPaymentIntent = createAsyncThunk('dashboard/postPaymentIntent', async (obj) => {
  const response = await POST('/dashboard/dashboard_payment_intent/', obj);
  return response.data; // payload
});


export const postManualPaymentIntent = createAsyncThunk('dashboard/postManualPaymentIntent', async (obj) => {
  const response = await POST('/dashboard/manual_payment_intent/', obj);
  return response.data; // payload
});

export const splitPaymentIntent = createAsyncThunk('dashboard/splitPaymentIntent', async (obj) => {
  const response = await POST('/dashboard/split_payment_intent/', obj);
  return response.data; // payload
});


export const cancelPaymentIntent = createAsyncThunk('dashboard/cancelPaymentIntent', async (obj) => {
  const response = await POST('/dashboard/cancel_payment_intent/', obj);
  return response.data; // payload
});

export const capturePaymentIntent = createAsyncThunk('dashboard/capturePaymentIntent', async (obj) => {
  const response = await POST('/dashboard/dashboard_capture_payment_intent/', obj);
  return response.data; // payload
});

export const confirmPaymentIntent = createAsyncThunk('dashboard/confirmPaymentIntent', async (obj) => {
  const response = await POST('/dashboard/confirm_payment_intent/', obj);
  return response.data; // payload
});


export const paymentProcessingSlice = createSlice({
  name: 'paymentProcessing',
  initialState: {
    status: 'idle',
    secret: null,
    splitSecret: null,
    intentId: null,
    terminal: null,
    intent: null,
    openCheckOutForm: false,
  },
  reducers: {
    setMyConnectedTerminal: (state, action) => {
      state.terminal = action.payload
    },
    setStatus: (state, action) => {
      state.status = action.payload
    },
    clearSecret: (state) => {
      state.secret = null;
    },
    setOpenCheckOutForm: (state, action) => {
      state.openCheckOutForm = action.payload;
    },
    clearPaymentProcessingInfo: (state) => {
      state.intent = null;
      state.intentId = null;
      state.secret = null;
    }
  },
  extraReducers: {
    [postConnectToken.pending]: (state, action) => {
      state.status = 'loading'
    },
    [postConnectToken.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.secret = action.payload.secret;
    },
    [postConnectToken.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
    [postPairReader.pending]: (state, action) => {
      state.status = 'loading'
    },
    [postPairReader.fulfilled]: (state, action) => {
      state.status = 'succeeded'
    },
    [postPairReader.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [postRemoveReader.pending]: (state, action) => {
      state.status = 'loading'
    },
    [postRemoveReader.fulfilled]: (state, action) => {
      state.status = 'removed'
    },
    [postRemoveReader.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [postPaymentIntent.pending]: (state, action) => {
      state.status = 'loading'
    },
    [postPaymentIntent.fulfilled]: (state, action) => {
      state.status = 'secret obtained'
      state.secret = action.payload.client_secret;
      state.intentId = action.payload.id;
      state.intent = action.payload
    },
    [postPaymentIntent.rejected]: (state, action) => {
      state.status = 'failed payment intent'
    },

    [postManualPaymentIntent.pending]: (state, action) => {
      state.status = 'loading'
    },
    [postManualPaymentIntent.fulfilled]: (state, action) => {
      state.status = 'secret obtained'
      state.secret = action.payload.client_secret;
      state.intentId = action.payload.id;
      state.intent = action.payload
    },
    [postManualPaymentIntent.rejected]: (state, action) => {
      state.status = 'failed payment intent'
    },
    [splitPaymentIntent.pending]: (state, action) => {
      state.status = 'loading'
    },
    [splitPaymentIntent.fulfilled]: (state, action) => {
      state.status = 'secret obtained'
      state.splitSecret = action.payload.client_secret;
      state.intentId = action.payload.id;
      state.intent = action.payload
    },
    [splitPaymentIntent.rejected]: (state, action) => {
      state.status = 'failed payment intent'
    },

    [cancelPaymentIntent.pending]: (state, action) => {
      state.status = 'loading'
    },
    [cancelPaymentIntent.fulfilled]: (state, action) => {
      state.status = 'cancelled intent'
    },
    [cancelPaymentIntent.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [capturePaymentIntent.pending]: (state, action) => {
      state.status = 'capturing'
    },
    [capturePaymentIntent.fulfilled]: (state, action) => {
      state.status = 'captured intent'
    },
    [capturePaymentIntent.rejected]: (state, action) => {
      state.status = 'capture failed'
    },
    [confirmPaymentIntent.pending]: (state, action) => {
      state.status = 'confirmed'
    },
    [confirmPaymentIntent.fulfilled]: (state, action) => {
      state.status = 'confirmed intent'
    },
    [confirmPaymentIntent.rejected]: (state, action) => {
      state.status = 'confirmed failed'
    },

    [postProcessPayment.pending]: (state, action) => {
      state.status = 'processing'
    },
    [postProcessPayment.fulfilled]: (state, action) => {
      state.status = 'payment initiated'
      state.reader = action.payload
    },
    [postProcessPayment.rejected]: (state, action) => {
      state.status = 'processing failed'
    },
    [cancelReaderAction.pending]: (state, action) => {
      state.status = 'cancel reader processing'
    },
    [cancelReaderAction.fulfilled]: (state, action) => {
      state.reader = action.payload
      state.status = 'cancel reader success'
    },
    [cancelReaderAction.rejected]: (state, action) => {
      state.status = 'cancel reader failed'
    },
    [updatePaymentIntent.pending]: (state, action) => {
      state.status = 'loading'
    },
    [updatePaymentIntent.fulfilled]: (state, action) => {
      state.secret = action.payload.client_secret;
      state.intentId = action.payload.id;
      state.intent = action.payload
      state.status = 'upated secret obtained'
    },
    [updatePaymentIntent.rejected]: (state, action) => {
      state.status = 'failed payment intent'
    },
  }
});

export const { setMyConnectedTerminal, setStatus, clearSecret,setOpenCheckOutForm, clearPaymentProcessingInfo } = paymentProcessingSlice.actions;

export default paymentProcessingSlice.reducer;

export const selectSecret = state => state.paymentProcessing.secret;
export const selectTerminal = state => state.paymentProcessing.terminal;
