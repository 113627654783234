import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { DropzoneArea } from 'material-ui-dropzone'
import {
  setAddCategoryImageModalOpen,
  selectCategoryImageModalOpen
} from './modalSlice';
import { postCategoryImage } from '../category/categorySlice';

const AddCategoryImageModal = (props) => {
  const open = useSelector(selectCategoryImageModalOpen);
  const dispatch = useDispatch();
  const [files, setFiles] = React.useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    //dispatch(postItem(categoryName));
    let formData = new FormData();

    formData.append('name', 'default');

    if (files.length > 0) {
      formData.append('category_image', files[0]);
    }

    dispatch(postCategoryImage(formData));
    dispatch(setAddCategoryImageModalOpen(false));
  };

  /* RENDER */
  return (
    <Dialog fullWidth maxWidth={'sm'} open={open} onClose={() => dispatch(setAddCategoryImageModalOpen(false))} aria-labelledby="form-dialog-title">
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <DialogContent style={{marginTop: 5, marginBottom: -5}}>
        <DropzoneArea
          dropzoneText={<small style={{fontSize: 20}}>Drag and drop an image here or click</small>}
          filesLimit={1}
          onChange={(files) => setFiles(files)}
        />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => dispatch(setAddCategoryImageModalOpen(false))} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Add
          </Button>
        </DialogActions>
      </form>

    </Dialog>
  );
}

export default AddCategoryImageModal;
