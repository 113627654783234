import React from 'react';
import TableBuilder from '../../features/table/TableBuilder';


const TableComponent = (props) => {
  return (
    <div>
      <TableBuilder />
    </div>
  );
};

export default TableComponent;
