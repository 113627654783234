import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useSelector, useDispatch } from 'react-redux';
import {
  setAddTableModalOpen,
  selectTableOpen
} from './modalSlice';
import { postTable } from '../table/tableSlice';

const AddTableModal = (props) => {
  const open = useSelector(selectTableOpen);
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    const number = e.target.number.value;
    dispatch(postTable({table_number: number}));
    dispatch(setAddTableModalOpen(false));
  };

  return (
    <Dialog fullWidth={50} maxWidth="sm" open={open} onClose={() => dispatch(setAddTableModalOpen(false))} aria-labelledby="form-dialog-title">
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <DialogTitle id="form-dialog-title">Add Table</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="number"
          id="number"
          label="Table Number"
          type="text"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(setAddTableModalOpen(false))} color="primary">
          Cancel
        </Button>
        <Button type="submit" color="primary">
          Add
        </Button>
      </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddTableModal;
