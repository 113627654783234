import React, {useEffect, useState} from 'react';
import { fetchItemImage, updateSelectImage, patchItemImage, removeItemImage } from './itemSlice';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import CheckIcon from '@material-ui/icons/Check';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
}));

const ItemImage = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const putCategoryStatus = useSelector(state => state.item.putItemStatus);
  const user = useSelector(state => state.auth.user);

  // encapsulated the image selection logic
  const handleClick = () => {
    if (user?.shop?.type != 'franchisee') {
      dispatch(patchItemImage({ targetValue: props.id, itemId: props.itemId }));
   }
  }
  const handleDeleteOnRightClick = (e) => {
    e.preventDefault();
    if (user?.shop?.type != 'franchisee') {
      dispatch(removeItemImage(props.id));
    }
  }

  return (props.selected ? <Badge onContextMenu={e => handleDeleteOnRightClick(e)}  badgeContent={<CheckIcon />} color="primary">
                              <Avatar style={{boxShadow: '0px 2px 1px 0px #878787'}}  src={props.item_image} className={classes.large} />
                           </Badge>
                           :
                           <Avatar style={{boxShadow: '0px 2px 1px 0px #878787'}} src={props.item_image} className={classes.large} onContextMenu={e => handleDeleteOnRightClick(e)}  onClick={handleClick} />)}

export default ItemImage;
