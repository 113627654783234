import { createSlice } from '@reduxjs/toolkit';

const DEFAULT_DRAWER_WIDTH = 250;

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState: {
    width: DEFAULT_DRAWER_WIDTH,
  },
  reducers: {
    setWidth: (state, action) => {
      state.width = action.payload;
    },
  },
});

export const { setWidth } = drawerSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectWidth = state => state.drawer.width;

export default drawerSlice.reducer;
