import { configureStore, getDefaultMiddleware  } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history'
import counterReducer from '../features/counter/counterSlice';
import rootReducer from '../reducers/index'
import {persistData, handleWebSockets} from '../middleware/middleware'

export const history = createBrowserHistory();

export default configureStore({
  reducer: rootReducer(history),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(persistData).concat(handleWebSockets),
});
