import React from 'react';
import Account from '../../features/auth/Account';

const AccountComponent = () => {
  return (
      <Account />
  );
};

export default AccountComponent;
