import { createSlice } from '@reduxjs/toolkit';

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    addItemImageModal: {
      open: false
    },
    addCouponModal: {
      open: false
    },
    addCategoryImageModal: {
      open: false
    },
    addCategoryModal: {
      open: false
    },
    addItemModal: {
      open: false
    },
    addTableModal: {
      open: false
    },
    addOptionModal: {
      open: false
    },
    addImageModal: {
      open: false
    },
    mainImageModal: {
      open: false
    },
    orderModal: {
      open: false
    }
  },
  reducers: {
    setAddItemImageModalOpen: (state, action) => {
      state.addItemImageModal.open = action.payload;
    },
    setAddCategoryImageModalOpen: (state, action) => {
      state.addCategoryImageModal.open = action.payload;
    },
    setAddCouponModalOpen: (state, action) => {
      state.addCouponModal.open = action.payload;
    },
    setAddCategoryModalOpen: (state, action) => {
      state.addCategoryModal.open = action.payload;
    },
    setAddItemModalOpen: (state, action) => {
      state.addItemModal.open = action.payload;
    },
    setAddOptionModalOpen: (state, action) => {
      state.addOptionModal.open = action.payload;
    },
    setAddTableModalOpen: (state, action) => {
      state.addTableModal.open = action.payload;
    },
    setAddImageModalOpen: (state, action) => {
      state.addImageModal.open = action.payload;
    },
    setMainImageModalOpen: (state, action) => {
      state.mainImageModal.open = action.payload;
    },
    setOrderModalOpen: (state, action) => {
      state.orderModal.open = action.payload;
    },
  },
});

export const { setAddCouponModalOpen, setOrderModalOpen, setAddTableModalOpen, setMainImageModalOpen, setAddImageModalOpen, setAddItemImageModalOpen, setAddCategoryModalOpen, setAddItemModalOpen, setAddOptionModalOpen, setAddCategoryImageModalOpen } = modalSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectOpen = state => state.modal.addCategoryModal.open;
export const selectItemOpen = state => state.modal.addItemModal.open;
export const selectCouponOpen = state => state.modal.addCouponModal.open;

export const selectTableOpen = state => state.modal.addTableModal.open;

export const selectOptionOpen = state => state.modal.addOptionModal.open;
export const selectCategoryImageModalOpen = state => state.modal.addCategoryImageModal.open;
export const selectItemImageModalOpen = state => state.modal.addItemImageModal.open;
export const selectImageModalOpen = state => state.modal.addImageModal.open;
export const selectMainImageModalOpen = state => state.modal.mainImageModal.open;
export const selectOrderModalOpen = state => state.modal.orderModal.open;

export default modalSlice.reducer;
