import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import {
  postAuthLogin, postAuthRefresh, selectRefreshToken, selectAccessToken,
  selectRefreshExpired, selectAccessTokenExpired, postRegisterUser, postCustomBusinessData
} from './authSlice'
import { FormControl, FormHelperText } from '@material-ui/core';
import {useDispatch, useSelector} from "react-redux";
import MainLogoSvg from '../../assets/61d8c0fe1e774f433535395a_kiostart_logo.svg';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { useForm, Controller } from "react-hook-form";
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import HttpsIcon from '@material-ui/icons/Https';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { DropzoneArea } from 'material-ui-dropzone'
import OrangeLogo from '../../assets/kio-orange.png';
import KioLogoText from '../../assets/kio-logo-text.png';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 40,
    width: 475,
    [theme.breakpoints.down('sm')]: {
      width: "95%",
      padding: 20
    },
    marginTop: 30,
    marginBottom: 30
  },
  alert: {
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  gridRoot: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  }
}));

const SignupCustom = (props) => {
  const { control, register, handleSubmit } = useForm();
  const classes = useStyles();
  const theme = createMuiTheme({
    typography: {
      fontFamily: 'Heebo, sans-serif',
    },
   palette: {
    background: {
      default: 'rgb(247, 249, 252)'
    },
    primary: {
      main: '#438abd'
    },
    formControl: {
      minWidth: 120,
    },
  }
  });

  const history = useHistory();
  const dispatch = useDispatch();
  const status = useSelector(state => state.auth.status);
  const msg = useSelector(state => state.auth.serverMessage);
  const [selectedDate, handleDateChange] = useState(new Date());

  const onSubmit = data => {
    let formData = new FormData();
    formData.append('address_line_one', data['address_line_one']);
    formData.append('address_line_two', data['address_line_two']);
    formData.append('city', data['city']);
    formData.append('state', data['state']);
    formData.append('zip', data['zip']);
    formData.append('phone', data['phone']);
    formData.append('website_url', data['website_url']);
    formData.append('type_of_business', data['type_of_business']);
    formData.append('legal_business_name', data['legal_business_name']);
    formData.append('operating_name', data['operating_name']);
    formData.append('first_name', data['first_name']);
    formData.append('last_name', data['last_name']);
    formData.append('email', data['email']);
    formData.append('job_title', data['job_title']);
    formData.append('dob', selectedDate.toISOString().substring(0, 10));
    formData.append('home_address', data['home_address']);
    formData.append('personal_phone', data['personal_phone']);
    formData.append('ssn', data['ssn']);
    formData.append('ein', data['ein']);
    formData.append('bank_routing_number', data['bank_routing_number']);
    formData.append('bank_account_number', data['bank_account_number']);
    formData.append('cc_number', data['cc_number']);
    formData.append('exp_mo', data['exp_mo']);
    formData.append('exp_yr', data['exp_yr']);
    formData.append('cvc', data['cvc']);


    dispatch(postCustomBusinessData(formData))


  }

  useEffect(() => {
    if (localStorage.getItem('refresh_token')) {
      history.push('/')
    }
  }, [])

  useEffect(() => {
     if (status == 'registered' || (status == 'authorized' && localStorage.getItem('refresh_token'))) {
      history.push('/')
    }
  }, [status])

  return (
    localStorage.getItem('refresh_token') ?
    <React.Fragment>
      <h1>Redirecting...</h1>
    </React.Fragment>
    :
    status === 'custom form submitted' ? (
      <React.Fragment>
      <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth={false} disableGutters>

      <Grid style={{paddingTop: 50, paddingBottom: 50}}
            container
            direction="column"
            justify="center"
            alignItems="center">
            <Card className={classes.root}>
              <Grid item>
                <img src={MainLogoSvg} style={{width: 100}}/>
              </Grid>
              <Grid item>
              <h4 style={{fontWeight: 400}}>Congratulations! It'll take about 24-hours or less to process. Our service representative will contact you once we finish setting up your account.</h4>
              </Grid>
            </Card>
        </Grid>
        </Container>
        </ThemeProvider>
      </React.Fragment>
    ) : (
    <React.Fragment>
    <ThemeProvider theme={theme}>
    <CssBaseline />
    <Container  maxWidth={false} disableGutters>
    <Grid className={classes.gridRoot}
        spacing={0}
          justify="center"
          alignItems="center">
          <Card className={classes.root}>
            <Grid                 container
                            item
                            direction="column"
                            justifyContent="center"
                            alignItems="center">
                {status === 'register failed' ?
                  <Alert
                    className={classes.alert}
                    style={{marginBottom: 20}}
                    elevation={0}
                    variant="filled"
                    severity="warning">
                    {msg}
                  </Alert> : null}

                  <img src={OrangeLogo} style={{position: 'relative', top: 0,width: 54.4}} />

<img src={KioLogoText} style={{width: 140, marginTop: 10, marginBottom: 10, zIndex: 1}}/> 
            
                <h2 className="roboto-font">Sign up</h2>
                <form onSubmit={handleSubmit(onSubmit)}>

                <FormControl required fullWidth>
                <Controller
                  control={control}
                  name="address_line_one"
                  render={
                    ({field}) =>
                        <TextField

                          label="Address Line 1"
                          size="normal"
                          placeholder="Enter Your Business Address Line 1"
                          fullWidth
                          required
                          margin="normal"
                          variant="outlined"
                          helperText="Use Registered Business Address"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...field}
                        />
                  } />
                  </FormControl>
                  <FormControl required fullWidth>
                  <Controller
                    control={control}
                    name="address_line_two"
                    render={
                      ({field}) =>
                          <TextField
                            label="Address Line 2"
                            size="normal"
                            placeholder="Enter Your Business Address Line 2"
                            fullWidth
                            required
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            {...field}
                          />
                    } />
                    </FormControl>
                    <FormControl required fullWidth>
                    <Controller
                      control={control}
                      name="city"
                      render={
                        ({field}) =>
                            <TextField

                              label="City"
                              size="normal"
                              placeholder="Enter City"
                              fullWidth
                              required
                              margin="normal"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              {...field}
                            />
                      } />
                      </FormControl>
                  <FormControl required fullWidth>
                  <Controller
                    control={control}
                    name="state"
                    render={
                      ({field}) =>
                          <TextField

                            label="State"
                            size="normal"
                            placeholder="Enter State"
                            fullWidth
                            required
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            {...field}
                          />
                    } />
                    </FormControl>
                    <FormControl required fullWidth>
                    <Controller
                      control={control}
                      name="zip"
                      render={
                        ({field}) =>
                            <TextField

                              label="Zip"
                              size="normal"
                              placeholder="Enter Zip Code"
                              fullWidth
                              required
                              margin="normal"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              {...field}
                            />
                      } />
                      </FormControl>
                      <FormControl required fullWidth>
                      <Controller
                        control={control}
                        name="phone"
                        render={
                          ({field}) =>
                              <TextField
                                label="Business Phone Number"
                                size="normal"
                                placeholder="Enter Phone Number"
                                fullWidth
                                required
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                {...field}
                              />
                        } />
                      </FormControl>
                      <FormControl required fullWidth>
                      <Controller
                        control={control}
                        name="website_url"
                        render={
                          ({field}) =>
                              <TextField

                                label="Business Website"
                                size="normal"
                                placeholder="Enter Website URL (optional)"
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                {...field}
                              />
                        } />
                      </FormControl>

                      <FormControl style={{marginTop: 15}} required variant="outlined" className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="business-type">Business Type</InputLabel>
                        <Controller
                        required
                          control={control}
                          name="type_of_business"
                          render={
                          ({field}) =>
                          <Select
                          required
                            native
                            {...field}
                            label="Business Type"
                            inputProps={{
                              id: 'business-type',
                            }}
                          >
                          <option aria-label="None" value="" />
                            <option value="Individual">Individual</option>
                            <option value="Sole proprietorship">Sole proprietorship</option>
                            <option value="Single-Member LLC">Single-Member LLC</option>
                            <option value="Multi-Member LLC">Multi-Member LLC</option>
                            <option value="Private Partnership">Private Partnership</option>
                            <option value="Private Coporation">Private Coporation</option>
                            <option value="Other/I'm not sure">Other/I'm not sure</option>
                          </Select>} />
                      </FormControl>
                      <FormControl style={{marginTop: 10}}  required fullWidth>
                        <Controller
                          control={control}
                          name="legal_business_name"
                          render={
                            ({field}) =>
                                <TextField

                                  label="Legal Business Name"
                                  size="normal"
                                  placeholder="Legal Business Name"
                                  fullWidth
                                  required
                                  helperText="The combination of your name and Employer Identification Number (EIN) must exactly match the one listed on your IRS documents (e.g., Letter 147C or SS-4 Confirmation letter), including capitalization and punctuation."
                                  margin="normal"
                                  variant="outlined"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  {...field}
                                />
                          } />
                      </FormControl>
                      <FormControl  required fullWidth>
                      <p style={{backgroundColor: 'green', borderRadius: 3, color: 'white', padding: 5, marginBottom: -3}}>
                        <HttpsIcon style={{marginLeft:10, fontSize: 28, position: 'relative', top: 5, marginRight: 7}} /> <span style={{fontSize:20, marginRight: 7}}>SSL Secured</span>
                      </p>
                        <Controller
                          control={control}
                          name="ein"
                          render={
                            ({field}) =>
                                <TextField

                                  label="Employer Identification Number (EIN)"
                                  size="normal"
                                  placeholder="Enter EIN"
                                  fullWidth
                                  required
                                  helperText="If you use your Social Security number for business tax purposes, you can enter that instead."
                                  margin="normal"
                                  variant="outlined"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  {...field}
                                />
                          } />
                      </FormControl>
                      <FormControl  fullWidth>
                        <Controller
                          control={control}
                          name="operating_name"
                          render={
                            ({field}) =>
                                <TextField

                                  label="Doing Business As"
                                  size="normal"
                                  placeholder="Operating Name"
                                  fullWidth
                                  helperText="The operating name of your company, if it’s different than the legal name."
                                  margin="normal"
                                  variant="outlined"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  {...field}
                                />
                          } />
                      </FormControl>
                      <FormControl required fullWidth>
                      <Controller
                        control={control}
                        name="first_name"
                        render={
                          ({field}) =>
                              <TextField

                                label="Your First Name"
                                size="normal"
                                placeholder="Enter First Name"
                                fullWidth
                                required
                                helperText="Legal Name of Business Representative"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                {...field}
                              />
                        } />
                      </FormControl>
                      <FormControl required fullWidth>
                      <Controller
                        control={control}
                        name="last_name"
                        render={
                          ({field}) =>
                              <TextField

                                label="Your Last Name"
                                size="normal"
                                placeholder="Enter Last Name"
                                fullWidth
                                required
                                helperText="Legal Name of Business Representative"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                {...field}
                              />
                        } />
                      </FormControl>

                      <FormControl style={{marginTop: 13, marginBottom: 13}} required fullWidth>
                      <KeyboardDatePicker
                        inputVariant="outlined"
                        name="dob"
                        label="Date of Birth"
                        placeholder="2018/10/30"
                        value={selectedDate}
                        onChange={date => handleDateChange(date)}
                        format="yyyy/MM/DD"
                      />
                        </FormControl>


                      <FormControl required fullWidth>
                      <Controller
                        control={control}
                        name="personal_phone"
                        render={
                          ({field}) =>
                              <TextField

                                label="Your Phone #"
                                size="normal"
                                placeholder="Enter Phone #"
                                fullWidth
                                margin="normal"
                                required
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                {...field}
                              />
                        } />
                      </FormControl>
                      <FormControl required fullWidth>
                      <Controller
                        control={control}
                        name="home_address"
                        render={
                          ({field}) =>
                              <TextField

                                label="Home Address"
                                size="normal"
                                placeholder="Enter Home Address"
                                fullWidth
                                required
                                margin="normal"
                                helperText="Include street address, city, state, and zip code"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                {...field}
                              />
                        } />
                      </FormControl>
                      <FormControl required fullWidth>
                      <p style={{backgroundColor: 'green', borderRadius: 3, color: 'white', padding: 5, marginBottom: -3}}>
                        <HttpsIcon style={{marginLeft:10, fontSize: 28, position: 'relative', top: 5, marginRight: 7}} /> <span style={{fontSize:20, marginRight: 7}}>SSL Secured</span>
                      </p>
                      <Controller
                        control={control}
                        name="ssn"
                        render={
                          ({field}) =>
                              <TextField
                                label="Social Security Number"
                                size="normal"
                                placeholder="Enter 9-Digit Social Security Number"
                                fullWidth
                                required
                                margin="normal"
                                helperText="Partnered card processing company must collect, verify, and maintain information on the individuals associated with every account, as required by their regulators and financial partners. This is intended to promote transparency and prevent individuals from using complex company structures to hide terrorist financing, money laundering, tax evasion, and other financial crimes. Kio Start WILL NOT store your number. Your SSN will simply be forwarded to partnered card processing company"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                {...field}
                              />
                        } />
                      </FormControl>
                      <FormControl required fullWidth>
                      <Controller
                        control={control}
                        name="bank_account_number"
                        render={
                          ({field}) =>
                              <TextField

                                label="Payout Bank Account Number"
                                size="normal"
                                placeholder="Enter Your Bank's Checking Account Number"
                                fullWidth
                                required
                                margin="normal"
                                helperText="Your Your bank account must be a checking account"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                {...field}
                              />
                        } />
                      </FormControl>
                      <FormControl required fullWidth>
                      <Controller
                        control={control}
                        name="bank_routing_number"
                        render={
                          ({field}) =>
                              <TextField

                                label="Payout Bank Routing Number"
                                size="normal"
                                placeholder="Enter Routing Number"
                                fullWidth
                                required
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                {...field}
                              />
                        } />
                      </FormControl>
                      <FormControl required fullWidth>
                      <Controller
                        control={control}
                        name="cc_number"
                        render={
                          ({field}) =>
                              <TextField
                                label="Credit Card Number"
                                size="normal"
                                placeholder="Enter Credit Card Number"
                                fullWidth
                                required
                                margin="normal"
                                max="10"
                                variant="outlined"
                                inputProps={{ maxLength: 16 }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                {...field}
                              />
                        } />
                      </FormControl>
                      <Grid container>
                        <Grid item xs>
                        <FormControl required fullWidth>
                        <Controller
                          control={control}
                          name="exp_mo"
                          render={
                            ({field}) =>
                                <TextField
                                  label="Expiration Month"
                                  size="normal"
                                  placeholder="03"
                                  fullWidth
                                  required
                                  margin="normal"
                                  variant="outlined"
                                  inputProps={{ maxLength: 16 }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  {...field}
                                />
                          } />
                        </FormControl>
                        </Grid>
                        <Grid item xs>
                        <FormControl required fullWidth>
                        <Controller
                          control={control}
                          name="exp_yr"
                          render={
                            ({field}) =>
                                <TextField
                                  type="number"
                                label="Expiration Year"
                                  size="normal"
                                  placeholder="22"
                                  fullWidth
                                  required
                                  margin="normal"
                                  variant="outlined"
                                  InputProps={{ inputProps: { min: 0, max: 99 } }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  {...field}
                                />
                          } />
                        </FormControl>
                        </Grid>
                        <Grid item xs>
                        <FormControl required fullWidth>
                        <Controller
                          control={control}
                          name="cvc"
                          render={
                            ({field}) =>
                                <TextField
                                  type="number"
                                  label="CVC"
                                  size="normal"
                                  placeholder="812"
                                  fullWidth
                                  required
                                  margin="normal"
                                  variant="outlined"
                                  InputProps={{ inputProps: { min: 0 } }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  {...field}
                                />
                          } />
                        </FormControl>
                        </Grid>
                      </Grid>

                <Button type="submit" style={{float: "right", marginRight: 0, marginTop: 10}} variant="contained" color="primary">
                  Submit
                </Button>
                </form>
            </Grid>
          </Card>

      </Grid>
      </Container>
    </ThemeProvider>
    </React.Fragment>)
  )
}

export default SignupCustom;
