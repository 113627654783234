import React, { useRef, useEffect } from 'react';

const useOrderIdEncryption = (key) => {
  const encryptOrderId = (orderId) => {
    const orderIdString = orderId.toString();
    let encrypted = '';
    for (let i = 0; i < orderIdString.length; i++) {
      const charCode = orderIdString.charCodeAt(i) ^ key.charCodeAt(i % key.length);
      encrypted += String.fromCharCode(charCode);
    }
    const base64Encoded = btoa(encrypted);
    const base64URLSafe = base64Encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    return base64URLSafe;
  };

  return encryptOrderId;
};

export default useOrderIdEncryption;
