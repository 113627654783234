import React, { useState, useEffect, useRef } from 'react';
import { overrideItem } from './itemSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchItemsAlt } from './itemSlice';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';


const OverrideItemTable = (props) => {
  const dispatch = useDispatch();
  const [unitPrices, setUnitPrices] = useState({})
  const [hiddens, setHiddens] = useState({})
  const [actives, setActives] = useState({})

  useEffect(() => {
    props.overrides?.map(override => setUnitPrices(prevState => (
      {
        ...prevState,
        [override.shop]: override.unit_price
      }
    )))

    props.overrides?.map(override => setHiddens(prevState => (
      {
        ...prevState,
        [override.shop]: override.hidden
      }
    )))

    props.overrides?.map(override => setActives(prevState => (
      {
        ...prevState,
        [override.shop]: override.active
      }
    )))
  }, [props.overrides])

  function overrideExists(shopId) {
    let arr = props.overrides?.filter(override => override.shop == shopId);
    return arr?.length > 0;
  }


  const handleUnitPriceChange = (e, shopId) => {
    setUnitPrices(prevState => ({ ...prevState, [shopId]: parseFloat(e.target.value) }))
  }

  const handleActiveChange = (active, shopId) => {
    setActives(prevState => ({ ...prevState, [shopId]: active }))
  }

  const handleHiddenChange = (hidden, shopId) => {
    setHiddens(prevState => ({ ...prevState, [shopId]: hidden }))
  }

  function overridePrice(shopId, unitPrice) {
    dispatch(overrideItem({unit_price: unitPrice, shop_id: shopId, item_id: props.item.itemId, hidden: null, active: null}));
  }

  function overrideActive(active, shopId) {
    if (overrideExists(shopId)) {
      dispatch(overrideItem({unit_price: null, shop_id: shopId, item_id: props.item.itemId, hidden: null, active: active}));
    } else {
      dispatch(overrideItem({unit_price: props.item?.unit_price, shop_id: shopId, item_id: props.item.itemId, hidden: props.item?.hidden, active: active}));

    }
    props.onActiveOverride(active, shopId)
    handleActiveChange(active, shopId);

    // create a handleSwitch2 func on parent
  }

  function overrideHidden(hidden, shopId) {
    if (overrideExists(shopId)) {
      dispatch(overrideItem({unit_price: null, shop_id: shopId, item_id: props.item.itemId, hidden: hidden, active: null}));
    } else {
      dispatch(overrideItem({unit_price: props.item?.unit_price, shop_id: shopId, item_id: props.item?.itemId, hidden: hidden, active: props.item?.active}));
    }
    props.onHiddenOverride(hidden, shopId)
    handleHiddenChange(hidden, shopId);
    // create a handleHidden2 func on parent
  }

  return (
    <TableContainer style={{maxHeight: 500}} component={Paper}>
     <Table  aria-label="a dense table">
       <TableBody>
       <TableCell component="th" scope="row">
       Override All
       </TableCell>
       <TableCell align="right">
       <Button disabled style={{fontSize: 10}} variant="contained" disableElevation color="primary" size="small">${props.item.unit_price}</Button>
       </TableCell>
       <TableCell align="right">
       <Button disabled style={{fontSize: 10}} variant="contained" disableElevation color="primary" size="small">ACTIVE</Button>
       <Button disabled style={{fontSize: 10, marginLeft:2}} variant="outlined" disableElevation color="primary" size="small">INACTIVE</Button>
       </TableCell>
       <TableCell align="right">
       <Button disabled style={{fontSize: 10}} variant="contained" disableElevation color="primary" size="small">HIDDEN</Button>
       <Button disabled style={{fontSize: 10, marginLeft:2}} variant="outlined" disableElevation color="primary" size="small">SHOWN</Button>
       </TableCell>
         <TableRow>
           <TableCell component="th" scope="row"><b>Shop ({props.shops.length})</b></TableCell>
           <TableCell component="th" scope="row" align="right"><b>Unit Price</b></TableCell>
           <TableCell component="th" scope="row" align="right"><b>Active</b></TableCell>
           <TableCell component="th" scope="row" align="right"><b>Hidden</b></TableCell>
         </TableRow>
       {props.shops?.map((shop, index) => {
            let overrideArr = []
            if (props.overrides?.length > 0) {
              overrideArr = props.overrides.filter(override => override.shop === shop.id);
            }
            return <TableRow key={shop.id}>
              <TableCell component="th" scope="row">
                {shop.business_name} {shop.city}
              </TableCell>
              <TableCell align="right">
                <input
                  onBlur={(e) => overridePrice(shop.id, unitPrices[shop.id])}
                  onChange={(e) => handleUnitPriceChange(e, shop.id)}
                  style={{textAlign: 'right', width: 70}}
                  type="number"
                  value={unitPrices[shop.id] || props.item.unit_price}
                />
              </TableCell>
              <TableCell align="right">
                {!overrideExists(shop.id) ? (
                  // Override Item isn't attached. Let's create one.
                  props.item.active ? (
                    <Button onClick={() => overrideActive(false, shop.id)} style={{fontSize: 10}} variant="contained" disableElevation color="primary" size="small">ACTIVE</Button>
                  ) : (
                    <Button onClick={() => overrideActive(true, shop.id)} style={{fontSize: 10}} variant="outlined" size="small">ACTIVE</Button>
                  )
                ) : (
                  // Override Item exists.
                  actives[shop.id] ? (
                    <Button onClick={() => overrideActive(false, shop.id)} style={{fontSize: 10}} variant="contained" disableElevation color="primary" size="small">ACTIVE</Button>
                  ) : (
                    <Button onClick={() => overrideActive(true, shop.id)} style={{fontSize: 10}} variant="outlined" size="small">ACTIVE</Button>
                  )
                )}
              </TableCell>
              <TableCell align="right">
              {!overrideExists(shop.id) ? (
                props.item.hidden ? (
                  <Button onClick={() => overrideHidden(false, shop.id)} style={{fontSize: 10}} variant="contained" disableElevation color="primary" size="small">HIDDEN</Button>
                ) : (
                  <Button onClick={() => overrideHidden(true, shop.id)} style={{fontSize: 10}} variant="outlined" size="small">HIDDEN</Button>
                )
              ) : (
                hiddens[shop.id] ? (
                  <Button onClick={() => overrideHidden(false, shop.id)} style={{fontSize: 10}} variant="contained" disableElevation color="primary" size="small">HIDDEN</Button>
                ) : (
                  <Button onClick={() => overrideHidden(true, shop.id)} style={{fontSize: 10}} variant="outlined" size="small">HIDDEN</Button>
                )
              )}
              </TableCell>
            </TableRow>
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default OverrideItemTable;
