import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import {
  postAuthLogin, postAuthRefresh, selectRefreshToken, selectAccessToken,
  selectRefreshExpired, selectAccessTokenExpired, postRegisterUser
} from './authSlice'
import { FormControl, FormHelperText } from '@material-ui/core';
import {useDispatch, useSelector} from "react-redux";
import MainLogoSvg from '../../assets/61d8c0fe1e774f433535395a_kiostart_logo.svg';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import OrangeLogo from '../../assets/kio-orange.png';
import KioLogoText from '../../assets/kio-logo-text.png';

const useStyles = makeStyles({
  root: {
    minWidth: 475,
    padding: 43,
    width: 280
  },
  alert: {
    minWidth: 475,
    width: 280
  }
});

const Signup = (props) => {

  const [form, setForm] = useState({
    username: {
      validated: true,
      error: ""
    },
    email: {
      validated: true,
      error: ""
    },
    password: {
      validated: true,
      error: "",
    },
    password2: {
      validated: true,
      error: ""
    }
  });

  const classes = useStyles();

  const theme = createMuiTheme({
    typography: {
      fontFamily: 'Heebo, sans-serif',
    },
   palette: {
    background: {
      default: 'rgb(247, 249, 252)'
    },
    primary: {
      main: '#438abd'
    }
  }
  });

  const history = useHistory();

  const dispatch = useDispatch();
  const status = useSelector(state => state.auth.status);
  const msg = useSelector(state => state.auth.serverMessage);

  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')


  function validateEmail() {
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (email && email.match(mailformat)){
      return true;
    } else {
      return false;
    }
  }

  const handleSubmit = e => {
    let validated = true;
    e.preventDefault();
    /*
    form validation
    */
    if (password == '') {
      setForm((prevState) => ({
        ...prevState,
         password: {validated: false, error: "Password is a required field."}}))
      validated = false;
    } else {
      setForm((prevState) => ({
        ...prevState,
         password: {validated: true, error: "Password is a required field."}}))
    }

    if (username == '') {
      setForm((prevState) => ({
        ...prevState,
         username: {validated: false, error: "Username is a required field."}}))
      validated = false;
    } else {
      setForm((prevState) => ({
        ...prevState,
         username: {validated: true, error: "Username is a required field."}}))
    }

    if (!validateEmail()) {
      setForm((prevState) => ({
        ...prevState,
         email: {validated: false, error: "Check your email address"}}))
      validated = false;
    }

    if (password != '' && password2 != '' && password != password2) {
      setForm((prevState) => ({
        ...prevState,
         password2: {validated: false, error: "Password does not match."}}))
      validated = false;
    } else if (password2 == '') {
      setForm((prevState) => ({
        ...prevState,
         password2: {validated: false, error: "This is a required field."}}))
      validated = false;
    } else {
      setForm((prevState) => ({
        ...prevState,
         password2: {validated: true, error: "This is a required field."}}))
    }

    if (password != '' && password.length < 8) {
      setForm((prevState) => ({
        ...prevState,
         password: {validated: false, error: "Password should be at least 8 characters long."}}))
      validated = false;
    }

    if (username != '' && username.length < 5) {
      setForm((prevState) => ({
        ...prevState,
         username: {validated: false, error: "Username should be at least 5 characters long."}}))
      validated = false;
    }

    if (validated) {
      dispatch(postRegisterUser({username: username, email: email, password: password, password2: password2}))
    }
  }

  const handleChangeUsername = e => {
    setUsername(e.target.value);
  }

  const handleChangeEmail = e => {
    console.log(e.target.value)
    setEmail(e.target.value);
  }

  const handleChangePassword = e => {
    setPassword(e.target.value);
  }

  const handleChangePassword2 = e => {
    setPassword2(e.target.value);
  }

  useEffect(() => {
    if (localStorage.getItem('refresh_token')) {
      history.push('/')
    }
  }, [])

  useEffect(() => {
     if (status == 'registered' || (status == 'authorized' && localStorage.getItem('refresh_token'))) {
      history.push('/')
    }
  }, [status])


  return (
    localStorage.getItem('refresh_token') ?
    <React.Fragment>
      <h1>Redirecting...</h1>
    </React.Fragment>
    :
    <React.Fragment>
    <ThemeProvider theme={theme}>
    <CssBaseline />
    <Container  maxWidth={false} disableGutters>
    <Grid style={{height: '100vh'}}
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={0}>
          <Grid item>
            {status === 'register failed' ?
            <Grid item>
              <Alert className={classes.alert} style={{marginBottom: 20}} elevation={0} variant="filled" severity="warning">
                {msg}
              </Alert>
            </Grid> : null}
          </Grid>
            <Grid item>
            <Card className={classes.root}>
              <Grid container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}>
                <Grid item>

                <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems:'center', flexDirection: 'column', padding: 0}}>
          <img src={OrangeLogo} style={{position: 'relative', top: 0,width: 45.4, marginBottom: 10}} />

            <img src={KioLogoText} style={{width: 140, marginRight: 2.5, zIndex: 1}}/> 
            
                      </div>
            
                <h2 className="roboto-font">Sign up</h2>             
                </Grid>
                <Grid item>
                  <form onSubmit={(e) => handleSubmit(e)}>
                  <FormControl required fullWidth>
                  <TextField name="username"
                            id="standard-full-width"
                            label="Username"
                            size="normal"
                            placeholder="Enter your Username"
                            fullWidth
                            required
                            error={!form.username.validated}
                            onChange={handleChangeUsername}
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          {form.username.validated ? null : <FormHelperText style={{margin: 0}} error>{form.username.error}</FormHelperText>}
                  </FormControl>
                  <FormControl required fullWidth>
                  <TextField name="email"
                            id="standard-full-width"
                            label="Email"
                            size="normal"
                            placeholder="Enter your Email Address"
                            fullWidth
                            required
                            error={!form.email.validated}
                            onChange={handleChangeEmail}
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          {form.email.validated ? null : <FormHelperText style={{margin: 0}} error>{form.email.error}</FormHelperText>}
                  </FormControl>
                  <FormControl fullWidth>
                  <TextField name="password"
                            id="standard-full-width"
                            label="Password"
                            type="password"
                            size="normal"
                            onChange={handleChangePassword}
                            placeholder="Enter your password"
                            fullWidth
                            error={!form.password.validated}
                            style={{ marginTop: 20 }}
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onEnter={handleSubmit}
                          />
                          {form.password.validated ? null : <FormHelperText style={{margin: 0}} error>{form.password.error}</FormHelperText>}
                  </FormControl>
                  <FormControl fullWidth>
                          <TextField name="password2"
                                    id="standard-full-width"
                                    label="Confirm Password"
                                    type="password"
                                    size="normal"
                                    onChange={handleChangePassword2}
                                    style={{ marginTop: 20 }}
                                    placeholder="Enter your password again"
                                    fullWidth
                                    error={!form.password2.validated}
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                  {form.password2.validated ? null : <FormHelperText style={{margin: 0}} error>{form.password2.error}</FormHelperText>}
                      </FormControl>
                  <Button   type="submit" onClick={handleSubmit} style={{float: "right", marginRight: 0, marginTop: 10}} variant="contained" color="primary">
                    {status == 'loading' ? 'Loading' : 'Sign up' }
                  </Button>
                  </form>
                  <Button size="small" color="primary" style={{marginLeft: -5}}>
                    Terms & Conditions
                  </Button>
                </Grid>
              </Grid>
            </Card>
            </Grid>
      </Grid>
      </Container>
    </ThemeProvider>

    </React.Fragment>
  )
}

export default Signup;
