import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { fetchItemsAlt, selectAllItems, updateSearchedItemsList } from './itemSlice';
import { useLocation } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import AddedItem from './AddedItem';
import AddItemImageModal from '../modal/AddItemImageModal';
import ItemAddModal from './ItemAddModal';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { io } from "socket.io-client";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  skeleton: {
    borderRadius: 4,
    height: 60,
    width: 800,
    [theme.breakpoints.down('md')]: {
      width: 660
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    marginBottom:1
  },
  search: {
    width: 800,
    [theme.breakpoints.down('md')]: {
      width: 660
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginBottom: 10
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'black',
    zIndex: 100
  },
  inputRoot: {
    backgroundColor: "white",
    color: 'black',
    borderRadius: 4,
    paddingLeft: 6,
    paddingTop: 5,
    paddingBottom: 5,
    boxShadow: '0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 2px 0px rgb(0 0 0 / 12%)'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

const DEFAULT_SOCKETS_URL = 'https://sockets.bigkiosksolution.com';
//const DEFAULT_SOCKETS_URL = 'http://127.0.0.1:5000';

/**
 * Determines if the item is hidden from franchiser.
 * If hidden from franchiser, franchisee doesn't
 * have a view and an access.
 */
const isHidden = (item, user) => {
  // if shop type is standard return false and halt immediately
  if (user?.shop?.type == 'standard' || user?.shop?.type == 'franchiser') {
    return false;
  }

  let overrideArr = []

  if (item.overrides?.length > 0) {
    overrideArr = item.overrides?.filter(override => override.shop === user?.shop?.id);
  }

  if (overrideArr.length > 0) {
    return overrideArr[0].hidden;
  } else { // if no overrides have been made (default)
    // default is visible (NOT hidden)
    return false
  }


  return false;
}

const Item = (props) => {
  const socketRef = useRef();
  const [searchTerm, setSearchTerm] = useState('')
  const [oldList, setOldList] = useState([]);
  const classes = useStyles();

  const allItems = useSelector(selectAllItems);
  const searchedItems = useSelector(state => state.item.searchedItems);

  const status = useSelector(state => state.item.status)
  const identifier = localStorage.getItem('identifier');

  const dispatch = useDispatch();
  const location = useLocation()

  useEffect(() => {
    
    // Only proceed if both storedIdentifier and categoryId are not null
    if (identifier) {
      const shopId = localStorage.getItem('shop_id');
  
      // Initialize the socket connection if it hasn't been initialized yet
      if (socketRef.current == null) {
        socketRef.current = io(DEFAULT_SOCKETS_URL, {
          transports: ["websocket"], // use WebSocket first, if available
        });
      }
      const { current: socket } = socketRef;
      
      // Ensure socket is open
      socket.open();
  
      // Socket connection established
      socket.on("connect", () => {
        socket.sendBuffer = []; // Clear any buffered events
        socket.emit('join', { id: shopId });
        // kiosk has been refreshed/restarted
      });
  
      // Handle disconnection
      socket.on("disconnect", (reason) => {
        if (reason === "io server disconnect") {
          // If server initiates disconnect, manually reconnect
          socket.connect();
        }
        // Automatic reconnection for other reasons
      });
  
      // Handle connection errors and fallback to reconnect
      const handleReconnect = () => {
        socket.connect();
      };
  
      socket.on("connect_error", handleReconnect);
      socket.on("connect_failed", handleReconnect);
      socket.on('error', handleReconnect);
  
      // Handle successful reconnection
      socket.on('reconnect', () => {
        socket.sendBuffer = [];
        socket.emit('join', { id: shopId });
      });
      
    
      // Cleanup function to remove listeners
      return () => {
        socket.off('connect');
        socket.off('disconnect');
        socket.off('connect_error', handleReconnect);
        socket.off('connect_failed', handleReconnect);
        socket.off('error', handleReconnect);
        socket.close()
      };
    }
  }, []); // dependencies that cause effect to re-run
  
  useEffect(() => {
    // handles user navigation changes
    dispatch(fetchItemsAlt());
  }, [location])

  useEffect(() => {
    if (searchTerm.length > 0) {
      const newList = allItems.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
      const deepCopiedList = JSON.parse(JSON.stringify(newList)); // create a deep copy of the array
      dispatch(updateSearchedItemsList(deepCopiedList))
    } else {
      dispatch(updateSearchedItemsList([]));
    }
  }, [searchTerm])

  const searchItem = (term) => {
    setSearchTerm(term);
  }

  return (
    <React.Fragment>
      <div className={classes.search}>
         <div className={classes.searchIcon}>
           <SearchIcon />
         </div>
         <InputBase
           size="medium"
           fullWidth
           placeholder="Search items by name"
           classes={{
             root: classes.inputRoot,
             input: classes.inputInput,
           }}
           onChange={e => searchItem(e.target.value)}
           inputProps={{ 'aria-label': 'search' }}
         />
       </div>
      <ItemDisplay socket={socketRef?.current} items={searchedItems.length > 0 || searchTerm.length > 0 ? searchedItems : allItems} status={status} classes={classes} />
      <ItemAddModal />
      <AddItemImageModal />
    </React.Fragment>
  );
};

const ItemDisplay = (props) => {
  const user = useSelector(state => state.auth.user)
  const items = props.items;
  const status = props.status;
  const classes = props.classes;

  let itemsDisp = null;

  if (items.length > 0) {
    itemsDisp = items.map((item, idx) => {
      return !isHidden(item, user) ? (
        <AddedItem socket={props.socket} {...item} key={item.id} itemId={item.id} />
      ) : (
        null
      )
    });
  } else if (status === 'loading') {
    itemsDisp =
      <React.Fragment>
        <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
        <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
        <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
        <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
        <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
        <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      </React.Fragment>;
  } else {
    itemsDisp = <h1 style={{textAlign: 'center'}}>

                </h1>;
  }

  return itemsDisp;
}

export default Item;
