import React from 'react';
import RewardsHome from '../../features/rewards/RewardsHome';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  root: {
    width: 800,
    [theme.breakpoints.down('md')]: {
      width: 660
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
}));


const RewardsComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <RewardsHome />
      </div>
  );
};

export default RewardsComponent;
