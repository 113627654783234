import React from 'react';
import SalesReport from '../../features/order/SalesReport';
import { ErrorBoundary } from 'react-error-boundary';

const ErrorFallback = ({error, resetErrorBoundary}) => {
  return (
    <h1>Error!</h1>
  )
}

const SalesReportComponent = () => {
  // error handler for any errors
  const errorHandler = (error: Error, info: {componentStack: string}) => {
      console.log(error);
  }

  return (
        <SalesReport />
  );
};

export default SalesReportComponent;
