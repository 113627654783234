import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET, DELETE, POST, PUT, PATCH, POST_FILE } from '../../api/DashboardAPI';

export const updateItemsPaid = createAsyncThunk('order-item/updateItemsPaid', async (obj) => {
  const response = await POST('/dashboard/order-item/set_items_paid/', {paid: obj.paid, items: obj.items});
  return response.data; // payload
});

export const postOrder = createAsyncThunk('order/postOrder', async (obj) => {
  const response = await POST('/dashboard/order/add_order/', {identifier: obj.identifier, note: '', waitlist: false, tableInstanceId: obj.tableInstanceId, phone: obj.phone, tip: obj.tip, cart: obj.cart, to_go: obj.toGo, stripe_payment_intent_id: obj.stripePaymentIntentId});
  return response.data;
});

export const postSplitPmt = createAsyncThunk('order/postSplitPmt', async (obj) => {
  const response = await POST('/dashboard/order/split_payment/', {id: obj.id, splits: obj.splits});
  return {id: obj.id, splits: response.data};
});

export const postSplitPmtByItems = createAsyncThunk('order/postSplitPmtByItems', async (obj) => {
  const response = await POST('/dashboard/order/split_payment_by_items/', {id: obj.id, splits: obj.splits});
  return {id: obj.id, splits: response.data};
});

export const fetchSplitPmt = createAsyncThunk('order/fetchSplitPmt', async (obj) => {
  const response = await POST('/dashboard/order/get_split_payment/', {id: obj.id});
  return {id: obj.id, splits: response.data};
});

  export const clearSplitPmt = createAsyncThunk('order/clearSplitPmt', async (obj) => {
  const response = await POST('/dashboard/order/clear_split_payment/', {id: obj.id});
  return response.data;
});

export const postOrderFromOrderModal = createAsyncThunk('order/postOrderFromOrderModal', async (obj) => {
  const response = await POST('/dashboard/order/add_order/', {identifier: obj.identifier, status: obj.status, waitlist: false, tableInstanceId: obj.tableInstanceId, phone: obj.phone, tip: obj.tip, cart: obj.cart, phone: obj.phone, note: obj.note, to_go: obj.toGo, stripe_payment_intent_id: obj.stripePaymentIntentId});
  return response.data;
});

export const updateOrder = createAsyncThunk('order/updateOrder', async (payload) => {
  const response = await POST('/dashboard/order/update_order/', payload);
  return response.data;
});

export const updateOrderStatus = createAsyncThunk('order/updateOrderStatus', async (obj) => {
  const response = await POST('/dashboard/order/update_order_status/', {status: obj.status, id: obj.id});
  if (obj.noAdd) {
    return {response: response.data, noAdd: obj.noAdd}
  } else {
    return {response: response.data}
  }
});

export const fetchOrdersByStatus = createAsyncThunk('item/fetchOrdersByStatus', async (status) => {
    const response = await POST('/dashboard/order/get_orders_by_status/', {'status': status});
    return response.data; // payload
});

export const refundOrder = createAsyncThunk('order/refundOrder', async (payload) => {
  const response = await POST('/dashboard/order/refund_order/', payload);
  return response.data; // payload
});

export const refundOrderPayment = createAsyncThunk('order/refundOrderPayment', async (payload) => {
  const response = await POST('/dashboard/order/refund_order_payment/', payload);
  return response.data // payload
});

export const getOrderDetail = createAsyncThunk('order/orderDetail', async (payload) => {
  const response = await POST('/dashboard/order/order_detail/', payload);
  return response.data; // payload
});

export const getOrderDetails = createAsyncThunk('order/orderDetails', async (payload) => {
  const response = await POST('/dashboard/order/order_details/', payload);
  return response.data; // payload
});


export const fetchOrders = createAsyncThunk('order/fetchOrders', async (payload) => {
  const response = await GET('/dashboard/order/get_tickets/?page=' + payload);
  return response.data; // payload
});

export const fetchOpenOrders = createAsyncThunk('order/fetchOpenOrders', async () => {
  const response = await GET('/dashboard/order/get_open_tickets/');
  return response.data; // payload
});

export const fetchSalesReport = createAsyncThunk('order/fetchSalesReport', async (payload) => {
  const response = await GET('/dashboard/order/get_sales_report/?num=' + payload.num + '&identifier=' + payload.activeIdentifier);
  return response.data; // payload
});

export const fetchSalesReportByShop = createAsyncThunk('order/fetchSalesReportByShop', async (payload) => {
  const response = await GET('/dashboard/order/get_sales_report2/?num=' + payload.num + '&shop_id=' + payload.shopId + '&identifier=' + payload.activeIdentifier);
  return response.data; // payload
});

export const fetchSalesCardsReport = createAsyncThunk('order/fetchSalesCardsReport', async (payload) => {
  if (payload.shopId) {
    const response = await GET('/dashboard/order/get_sales_report_cards/?type=' + payload.type + '&shop_id=' + payload.shopId + '&identifier=' + payload.activeIdentifier);
    return response.data; // payload
  } else {
    const response = await GET('/dashboard/order/get_sales_report_cards/?type=' + payload.type + '&identifier=' + payload.activeIdentifier);
    return response.data; // payload
  }
});

export const fetchIdentifiers = createAsyncThunk('order/fetchIdentifiers', async () => {
    const response = await GET('/dashboard/order/get_identifiers/')
    return response.data.identifiers; // payload
});

export const fetchItemsTopSellingReport = createAsyncThunk('order/fetchItemsTopSellingReport', async (payload) => {
  if (payload.shopId) {
    const response = await GET('/dashboard/order/get_items_top_selling/?type=' + payload.type + '&shop_id=' + payload.shopId + '&identifier=' + payload.activeIdentifier);
    return response.data; // payload
  } else {
    const response = await GET('/dashboard/order/get_items_top_selling/?type=' + payload.type + '&identifier=' + payload.activeIdentifier);
    return response.data; // payload
  }
});

export const fetchSalesCardsReportCustom = createAsyncThunk('order/fetchSalesCardsReportCustom', async (payload) => {
  if (payload.shopId) {
    const response = await GET('/dashboard/order/get_sales_report_cards_custom/?type=' + payload.type + '&start_date=' + payload.start_date + '&end_date=' + payload.end_date + '&shop_id=' + payload.shopId + '&identifier=' + payload.activeIdentifier);
    return response.data; // payload
  } else {
    const response = await GET('/dashboard/order/get_sales_report_cards_custom/?type=' + payload.type + '&start_date=' + payload.start_date + '&end_date=' + payload.end_date + '&identifier=' + payload.activeIdentifier);
    return response.data; // payload
  }
});

export const fetchItemsTopSellingReportCustom = createAsyncThunk('order/fetchItemsTopSellingReportCustom', async (payload) => {
  if (payload.shopId) {
    const response = await GET('/dashboard/order/get_items_top_selling_custom/?type=' + payload.type + '&start_date=' + payload.start_date + '&end_date=' + payload.end_date + '&shop_id=' + payload.shopId + '&identifier=' + payload.activeIdentifier);
    return response.data; // payload
  } else {
    const response = await GET('/dashboard/order/get_items_top_selling_custom/?type=' + payload.type + '&start_date=' + payload.start_date + '&end_date=' + payload.end_date + '&identifier=' + payload.activeIdentifier);
    return response.data; // payload
  }
});

export const postAddPhone = createAsyncThunk('order/postAddPhone', async (obj) => {
  const response = await POST('/dashboard/order/add_phone_to_order/', obj);
  return response.data;
});

export const refundOrderItemSingleTender = createAsyncThunk('order/refundOrderItemSingleTender', async (payload) => {
  const response = await POST('/dashboard/order/refund_order_items_single_tender/', payload);
  return response.data;
});



export const sendSMS = createAsyncThunk('order/sendSMS', async (obj) => {
  /*
  obj = {
    number: _int (order number)
    phone: _str (phone number)
  }
  */
  const response = await POST('/dashboard/send_sms/', obj);
  return response.data;
});


export const sendRefundSMS = createAsyncThunk('order/sendRefundSMS', async (obj) => {
  /*
  obj = {
    number: _int (order number)
    phone: _str (phone number)
  }
  */
  const response = await POST('/dashboard/send_refund_sms/', obj);
  return response.data;
});


export const sendSMSPickUp = createAsyncThunk('order/sendSMSPickUp', async (obj) => {
  /*
  obj = {
    number: _int (order number)
    phone: _str (phone number)
  }
  */
  const response = await POST('/dashboard/pickup_sms/', obj);
  return response.data;
});

export const updatePmtIntent = createAsyncThunk('order/updatePmtIntent', async (obj) => {
  const response = await POST('/dashboard/order/' + obj.id + '/set_payment_intent/', {id: obj.id, stripe_payment_intent_id: obj.stripe_payment_intent});
  return response.data;
});

export const getPmtStatus = createAsyncThunk('order/getPmtStatus', async (obj) => {
  const response = await POST('/dashboard/order/' + obj.id + '/payment_status/', {id: obj.id});
  return response.data;
});

export const getPayouts = createAsyncThunk('order/getPayouts', async (payload) => {
  const response = await GET('/dashboard/order/get_payouts/?shop_id=' + payload.id);
  return response.data;
});

export const getPayoutTransactions = createAsyncThunk('order/getPayoutTransactions', async (payload) => {
  if (payload.startingAfter) {
    const response = await GET('/dashboard/order/get_payout_transactions/?po_id=' + payload.id + '&starting_after=' + payload.startingAfter + '&shop_id=' + payload.shop_id );
    return response.data;
  } else if (payload.endingBefore) {
    const response = await GET('/dashboard/order/get_payout_transactions/?po_id=' + payload.id + '&ending_before=' + payload.endingBefore + '&shop_id=' + payload.shop_id );
    return response.data;
  } else {
    const response = await GET('/dashboard/order/get_payout_transactions/?po_id=' + payload.id + '&shop_id=' + payload.shop_id);
    return response.data;
  }
});

export const fetchCategoryImages = createAsyncThunk('category/fetchCategoryImages', async () => {
  const response = await GET('/dashboard/category-image/');
  return response.data.results; // payload
});

export const getTaxRate = createAsyncThunk('order/getTaxRate', async () => {
  const response = await GET('/dashboard/order/get_tax_rate/');
  return response.data.tax_rate;
});


export const getPayoutBalance = createAsyncThunk('order/getPayoutBalance', async () => {
  const response = await GET('/dashboard/order/get_balance/');
  return response.data;
});

export const payout = createAsyncThunk('order/payout', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/order/payout/', payload);
    return response.data;
  } catch (error) {
    // Check if the error has a response object with data
    if (error.data && error.data.error) {
      return rejectWithValue(error.data.error); // Preserve the error message
    }
    // Default to a generic error if no response data is available
    return rejectWithValue('An unexpected error occurred.');
  }
});


export const orderSlice = createSlice({
  name: 'order',
  initialState: {
    splits: [
      /*
      { order_id: number, splits: [] },
      { order_id: number, splits: [] },
      { order_id: number, splits: [] },
      { order_id: number, splits: [] },
      */
    ],
    orders: {
      results: []
    },
    availablePayoutMethods: null,
    availableBalance: null,
    identifiers: [],
    rankedSoldItems: [],
    payouts: [],
    transactions: [],
    openOrders: [],
    status: 'idle',
    reportDataArr: [],
    orderDetail: null,
    orderDetailArr: [], // used for split payments
    pmtStatusArr: [],
    total: 0,
    tip: 0,
    gross: 0,
    tax: 0,
    refund: 0,
    ticketCount: 0,
    taxRate: null,
    avgTicketSize: 0,
    hasMore: false,
    categoryImages: [],
    payoutError: '',
  },
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    clearTransactions: (state) => {
      state.transactions = [];
    },
    clearAllSplits: (state) => {
      state.splits = []
    },
    clearOrderDetail: (state) => {
      state.orderDetail = null;
    },
    clearError: (state) => {
      state.payoutError = ""
    }
  },
  extraReducers: {
    [postAddPhone.pending]: (state, action) => {
      state.status = 'loading'
    },
    [postAddPhone.fulfilled]: (state, action) => {
      state.status = 'succeeded'
    },
    [postAddPhone.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [fetchOrders.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchOrders.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // Add any fetched posts to the array
      state.orders.results = action.payload;
    },
    [fetchOrders.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
    [fetchOpenOrders.pending]: (state, action) => {
      state.status = 'fetching open orders'
    },
    [fetchOpenOrders.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // Add any fetched posts to the array
      state.openOrders = action.payload;
    },
    [fetchOpenOrders.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },

    [fetchOrdersByStatus.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchOrdersByStatus.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // Add any fetched posts to the array
      state.orders.results = action.payload;
    },
    [fetchOrdersByStatus.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },

    [fetchSalesReport.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchSalesReport.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // Add any fetched posts to the array
      state.reportDataArr = action.payload;
    },
    [fetchSalesReport.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },

    [fetchSalesReportByShop.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchSalesReportByShop.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // Add any fetched posts to the array
      state.reportDataArr = action.payload;
    },
    [fetchSalesReportByShop.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },

    [fetchSalesCardsReport.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchSalesCardsReport.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // Add any fetched posts to the array
      state.total = action.payload.total;
      state.tip = action.payload.tip;
      state.gross = action.payload.gross;
      state.tax = action.payload.tax;
      state.ticketCount = action.payload.ticket_count;
      state.avgTicketSize = action.payload.avg_tiket_size;
      state.refund = action.payload.refund;

    },
    [fetchSalesCardsReport.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },

    [fetchItemsTopSellingReport.pending]: (state, action) => {
      state.status = 'top selling loading'
    },
    [fetchItemsTopSellingReport.fulfilled]: (state, action) => {
      state.status = 'top selling succeeded'
      // Add any fetched posts to the array
      state.rankedSoldItems = action.payload;
    },
    [fetchItemsTopSellingReport.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },

    [fetchSalesCardsReportCustom.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchSalesCardsReportCustom.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // Add any fetched posts to the array
      state.total = action.payload.total;
      state.tip = action.payload.tip;
      state.tax = action.payload.tax;
      state.gross = action.payload.gross;
      state.avgTicketSize = action.payload.avg_tiket_size;
      state.ticketCount = action.payload.ticket_count;
      state.refund = action.payload.refund;

    },
    [fetchSalesCardsReportCustom.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },

    [fetchItemsTopSellingReportCustom.pending]: (state, action) => {
      state.status = 'top selling loading'
    },
    [fetchItemsTopSellingReportCustom.fulfilled]: (state, action) => {
      state.status = 'top selling succeeded'
      // Add any fetched posts to the array
      state.rankedSoldItems = action.payload;
    },
    [fetchItemsTopSellingReportCustom.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
    [sendSMSPickUp.pending]: (state, action) => {
      state.status = 'sms sending'
    },
    [sendSMSPickUp.fulfilled]: (state, action) => {
      state.status = 'sms sent'
    },
    [sendSMSPickUp.rejected]: (state, action) => {
      state.status = 'sms sending failed'
    },
    [refundOrder.pending]: (state, action) => {
      state.status = 'refunding'
    },
    [refundOrder.fulfilled]: (state, action) => {
      const _order = action.payload
      const index = state.orders.results.results.findIndex(order => order.id === _order.id)
      if (index !== -1) {
        state.orders.results.results[index] = _order;
      }
      state.status = 'refunded'
    },
    [refundOrder.rejected]: (state, action) => {
      state.status = 'refund failed'
    },

    [refundOrderPayment.pending]: (state, action) => {
      state.status = 'refunding'
    },
    [refundOrderPayment.fulfilled]: (state, action) => {
      const orderId = action.payload.order_id
      const orderPayment = action.payload

      const index = state.orders.results.results.findIndex(order => order.id === orderId)

      if (index !== -1) {
        const index2 = state.orders.results.results[index].order_payments.findIndex(pmt => pmt.id === orderPayment.id)
        //orderPayment
        state.orders.results.results[index].order_payments[index2] = orderPayment;
      }
      state.status = 'refunded split'

    },
    [refundOrderPayment.rejected]: (state, action) => {
      state.status = 'refund failed'
    },
    [getOrderDetail.pending]: (state, action) => {
      state.status = 'detail loading'
    },
    [getOrderDetail.fulfilled]: (state, action) => {
      state.orderDetail = action.payload.intent
      state.orderDetail.charges = action.payload.charges

      const _order = action.payload.order
      const index = state.orders.results.results.findIndex(order => order.id === _order.id)
      if (index !== -1) {
        state.orders.results.results[index] = _order;
        state.orderDetail.note = _order.note 
        state.orderDetail.phone = _order.phone
        state.orderDetail.to_go = _order.to_go

      }
      state.status = 'detail fetched'
    },
    [getOrderDetail.rejected]: (state, action) => {
      state.status = 'detail rejected'
    },
    [getOrderDetails.pending]: (state, action) => {
      state.status = 'detail loading'
    },
    [getOrderDetails.fulfilled]: (state, action) => {
      state.orderDetailArr = action.payload.intents
      const _order = action.payload.order
      const index = state.orders.results.results.findIndex(order => order.id === _order.id)
      if (index !== -1) {
        state.orders.results.results[index] = _order;
      }
      state.status = 'detail fetched'
    },
    [getOrderDetails.rejected]: (state, action) => {
      state.status = 'detail rejected'
    },
    [getPmtStatus.pending]: (state, action) => {
      state.status = 'status loading'
    },
    [getPmtStatus.fulfilled]: (state, action) => {
      if(state.pmtStatusArr.indexOf(action.payload.intent) === -1) {
          if (typeof action.payload.intent !== 'undefined')
            state.pmtStatusArr.push(action.payload.intent);
      }

        state.status = 'status fetched'
    },
    [getPmtStatus.rejected]: (state, action) => {
      state.status = 'status failed'
    },

    [updatePmtIntent.pending]: (state, action) => {
      state.status = 'status loading'
    },
    [updatePmtIntent.fulfilled]: (state, action) => {

      const index = state.orders.results.results.findIndex(item => item.id === action.payload.id)
      if (index === -1) {
          state.orders.results.results[index] = action.payload;
      }
        state.status = 'status fetched'
    },
    [updatePmtIntent.rejected]: (state, action) => {
      state.status = 'status failed'
    },
    [postOrder.pending]: (state, action) => {
      state.status = 'loading'
    },
    [postOrder.fulfilled]: (state, action) => {
      state.status = 'order added'
      state.orders.results.results.unshift(action.payload)
    },
    [postOrder.rejected]: (state  , action) => {
      state.status = 'failed'
    },

    [postOrderFromOrderModal.pending]: (state, action) => {
      state.status = 'order adding'
    },
    [postOrderFromOrderModal.fulfilled]: (state, action) => {
      state.status = 'order added'
      state.orders.results.results.unshift(action.payload)
    },
    [postOrderFromOrderModal.rejected]: (state  , action) => {
      state.status = 'failed'
    },

    [updateOrder.pending]: (state, action) => {
      state.status = 'order update loading'
    },
    [updateOrder.fulfilled]: (state, action) => {
      if (state.orders && state.orders.results && state.orders.results.results) {

        const index = state.orders?.results?.results?.findIndex(order => order.id === action.payload.id)
        if (index !== -1) {
          state.orders.results.results[index] = action.payload
        }
      }
      state.status = 'order updated'

    },
    [updateOrder.rejected]: (state  , action) => {
      state.status = 'failed'
    },
    [updateOrderStatus.pending]: (state, action) => {
      state.status = 'update order status loading'
    },
    [updateOrderStatus.fulfilled]: (state, action) => {
      if (state.orders && state.orders.results && state.orders.results.results) {
        const index = state.orders?.results?.results?.findIndex(order => order.id === action.payload.response.id)
        if (index !== -1) {
          state.orders.results.results[index] = action.payload.response;
          if (action.payload.noAdd) {
            state.status = 'order status updated no add'
          } else {
            state.status = 'order status updated'
          }
        }
      }

    },
    [updateOrderStatus.rejected]: (state  , action) => {
      state.status = 'failed'
    },

    [postSplitPmt.pending]: (state, action) => {
      state.status = 'loading'
    },
    [postSplitPmt.fulfilled]: (state, action) => {
      const index = state.splits.findIndex(split => split.order_id === action.payload.id);

      if (index != -1) {
        state.splits[index].splits = action.payload.splits;
      } else {
        state.splits.push({ order_id: action.payload.id, splits: action.payload.splits });
      }
      state.status = 'split pmt added'

    },
    [postSplitPmt.rejected]: (state  , action) => {
      state.status = 'failed'
    },

    [postSplitPmtByItems.pending]: (state, action) => {
      state.status = 'loading'
    },
    [postSplitPmtByItems.fulfilled]: (state, action) => {
      state.status = 'split by items pmt added'
      const index = state.splits.findIndex(split => split.order_id === action.payload.id);

      if (index != -1) {
        state.splits[index].splits = action.payload.splits;
      } else {
        state.splits.push({ order_id: action.payload.id, splits: action.payload.splits });

      }
    },
    [postSplitPmtByItems.rejected]: (state  , action) => {
      state.status = 'failed'
    },

    [fetchSplitPmt.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchSplitPmt.fulfilled]: (state, action) => {
      const index = state.splits.findIndex(split => split.order_id === action.payload.id);
      if (index != -1) {
        state.splits[index].splits = action.payload.splits;
      } else {
        state.splits.push({ order_id: action.payload.id, splits: action.payload.splits });
      }
      state.status = 'fetch splits success'

    },
    [fetchSplitPmt.rejected]: (state  , action) => {
      state.status = 'failed'
    },

    [clearSplitPmt.pending]: (state, action) => {
      state.status = 'split removing'
    },
    [clearSplitPmt.fulfilled]: (state, action) => {
      const index2 = state.splits.findIndex(split => split.order_id === action.payload.id);

      if (index2 != -1) {
        state.splits[index2].splits = [];
      }
      state.status = 'split removed'

    },
    [clearSplitPmt.rejected]: (state, action) => {
      state.status = 'split removing failed'
    },
    [payout.pending]: (state, action) => {
      state.status = 'loading'
      state.payoutError = "";
    },
    [payout.fulfilled]: (state, action) => {
      state.status = 'instant payout success'
      state.payoutError = "";

    },
    [payout.rejected]: (state, action) => {
      state.payoutError = action.payload;
      state.status = 'failed'

    },


    [getPayouts.pending]: (state, action) => {
      state.status = 'loading'
    },
    [getPayouts.fulfilled]: (state, action) => {
      state.status = 'payouts received'
      state.payouts = action.payload.data;
    },
    [getPayouts.rejected]: (state, action) => {
      console.log('reject')
      state.status = 'failed'
    },

    [getPayoutTransactions.pending]: (state, action) => {
      state.status = 'loading'
    },
    [getPayoutTransactions.fulfilled]: (state, action) => {
      state.status = 'payouts transactions received'
      state.transactions = state.transactions.concat(action.payload.data);
      state.hasMore = action.payload.has_more;

    },
    [getPayoutTransactions.rejected]: (state, action) => {
      state.status = 'failed'
    },

    [updateItemsPaid.pending]: (state, action) => {
      state.status = 'loading'
    },
    [updateItemsPaid.fulfilled]: (state, action) => {
      state.status = 'update item status paid success'
    },
    [updateItemsPaid.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [sendSMS.pending]: (state, action) => {
      state.status = 'sms sending'
    },
    [sendSMS.fulfilled]: (state, action) => {
      state.status = 'sms sent'
    },
    [sendSMS.rejected]: (state, action) => {
      state.status = 'sms sending failed'
    },
    [sendRefundSMS.pending]: (state, action) => {
      state.status = 'sms sending'
    },
    [sendRefundSMS.fulfilled]: (state, action) => {
      state.status = 'sms sent'
    },
    [sendRefundSMS.rejected]: (state, action) => {
      state.status = 'sms sending failed'
    },
    [fetchCategoryImages.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchCategoryImages.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // Add any fetched posts to the array
      state.categoryImages = action.payload;
    },
    [fetchCategoryImages.rejected]: (state, action) => {
      state.status = 'failed'
    },

    [refundOrderItemSingleTender.pending]: (state, action) => {
      state.status = 'refunding'
    },
    [refundOrderItemSingleTender.fulfilled]: (state, action) => {
      const index = state.orders.results.results.findIndex(order => order.id === action.payload.id)
      if (index !== -1) {
        state.orders.results.results[index] = action.payload
      }
      state.status = 'refunded split';
    },
    [refundOrderItemSingleTender.rejected]: (state, action) => {
      state.status = 'refund failed'
    },
    [getTaxRate.pending]: (state, action) => {
      state.status = 'loading'
    },
    [getTaxRate.fulfilled]: (state, action) => {
      state.status = 'succeeded obtaining tax rate'
      state.taxRate = action.payload;
    },
    [getTaxRate.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [fetchIdentifiers.pending]: (state, action) => {
      state.status = 'loading identifiers'
    },
    [fetchIdentifiers.fulfilled]: (state, action) => {
      state.status = 'fetched identifiers'
      state.identifiers = action.payload;
    },
    [fetchIdentifiers.rejected]: (state, action) => {
      state.status = 'failed identifiers'
    },

    [getPayoutBalance.pending]: (state, action) => {
      state.status = 'loading payout balance'
    },
    [getPayoutBalance.fulfilled]: (state, action) => {
      console.log(action.payload)
      state.availablePayoutMethods = action.payload.availablePayoutMethods;
      state.availableBalance = action.payload.availableBalance;
      state.status = 'payout balance fetched'

      /*
      availablePayoutMethods =
      [
        "standard",
        "instant"
      ],
      
      availableBalance = {
        "object": "balance",
        "available": [
          {
            "amount": 500,
            "currency": "usd",
            "source_types": {
              "card": 500
            }
          }
        ],
        "instant_available": [
          {
            "amount": 500,
            "currency": "usd",
            "net_available": [
              {
                "amount": 490,
                "destination": "ba_abc123",
                "source_types": {
                  "card": 490
                }
              }
            ],
            "source_types": {
              "card": 500
            }
          }
        ],
         "pending": [
          {
            "amount": 5049,
            "currency": "usd",
            "source_types": {
              "card": 5049
            }
          }
        ]
        ...
      }
      */
    },
    [getPayoutBalance.rejected]: (state, action) => {
      state.status = 'failed loading payout balance'
    },

  }
});


export const { clearAllSplits,clearError, clearTransactions, setStatus, clearOrderDetail } = orderSlice.actions;

export default orderSlice.reducer;

export const selectAllOrders = state => state.order.orders.results;
export const selectReportDataArr = state => state.order.reportDataArr;
export const getOrderById = (state, id) => {
  const index = state.order?.orders?.results?.results.findIndex(order => order.id === id);
  if (index !== -1) {
    return state.order?.orders?.results.results[index]
  }
}

export const getSplitsByOrderId = (state, id) => {
  const index = state.order.splits.findIndex(split => split.order_id === id);
  if (index !== -1) {
    return state.order.splits[index].splits;
  } else {
    return []
  }
}
