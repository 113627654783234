import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET, DELETE, POST, PUT, PATCH, POST_FILE } from '../../api/DashboardAPI';


export const addCoupon = createAsyncThunk('coupon/addCoupon', async (payload, { rejectWithValue }) => {
    try {
      const response = await POST('/dashboard/coupon/', payload);
      return response.data;
    } catch (err) {
      console.log(err)
      return rejectWithValue(err.data.code)
    }
});

export const deleteCoupon = createAsyncThunk('coupon/deleteCoupon', async (payload) => {
  await DELETE('/dashboard/coupon/' + payload.id + '/');
  return payload.id;
});


export const fetchCoupons = createAsyncThunk('coupon/fetchCoupons', async () => {
  const response = await GET('/dashboard/coupon/');

  return response.data;
});


export const addApplicableItems = createAsyncThunk('coupon/addApplicableItems', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/coupon/' + payload.id + '/add_applicable_items/', payload);
    return {coupon_id: payload.id, response_data: response.data};
  } catch (err) {
    console.log(err)
    return rejectWithValue(err.data.code)
  }
});


export const deleteApplicableItem = createAsyncThunk('coupon/deleteApplicableItem', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/coupon/' + payload.id + '/delete_applicable_item/', payload);
    return {coupon_id: payload.id, response_data: response.data};
  } catch (err) {
    console.log(err)
    return rejectWithValue(err.data.code)
  }
});


export const couponsSlice = createSlice({
  name: 'Coupons',
  initialState: {
    coupons: [],
    status: 'idle',
    error: ''
  },
  reducers: {
    clearError: (state) => {
        state.error = '';
    }
  },
  extraReducers: {
    [addCoupon.pending]: (state, action) => {
      state.status = 'loading'
    },
    [addCoupon.fulfilled]: (state, action) => {
      state.coupons.results.push(action.payload)
      state.status = 'add coupon succeeded'
    },
    [addCoupon.rejected]: (state, action) => {
      state.error = action.payload
      state.status = 'failed'
    },

    [deleteCoupon.pending]: (state, action) => {
      state.status = 'loading'
    },
    [deleteCoupon.fulfilled]: (state, action) => {
      const index = state.coupons.results.findIndex(coupon => coupon.id == action.payload);

      if (index != -1) {
        state.coupons?.results.splice(index, 1);
      }
      state.status = 'succeeded'
    },
    [deleteCoupon.rejected]: (state, action) => {
      state.status = 'failed'
    },

    [fetchCoupons.pending]: (state, action) => {
      state.status = 'fetching coupons'
    },
    [fetchCoupons.fulfilled]: (state, action) => {
      state.coupons = action.payload;
      state.status = 'fetching coupons succeeded'
    },
    [fetchCoupons.rejected]: (state, action) => {
      state.status = 'fetching coupons failed'
    },
    [addApplicableItems.pending]: (state, action) => {
      state.status = 'adding applicable items to coupons'
    },
    [addApplicableItems.fulfilled]: (state, action) => {
      const index = state.coupons.results.findIndex(coupon => coupon.id == action.payload.coupon_id);
      if (index != -1) {
        // Combine existing applicable items with the new items added
        const combinedItems = [
          ...state.coupons.results[index].applicable_items,
          ...action.payload.response_data.items_added
        ];
        // Use a Set to remove duplicates and then convert it back to an array
        state.coupons.results[index].applicable_items = Array.from(new Set(combinedItems));
      }
      state.status = 'adding applicable items to coupons succeeded'
    },
    [addApplicableItems.rejected]: (state, action) => {
      state.status = 'adding applicable items to coupons failed'
    },


    [deleteApplicableItem.pending]: (state, action) => {
      state.status = 'removing applicable item'
    },
    [deleteApplicableItem.fulfilled]: (state, action) => {
      const index = state.coupons.results.findIndex(coupon => coupon.id == action.payload.coupon_id);
      if (index != -1) {
        // Use a Set to remove duplicates and then convert it back to an array
        const index2 = state.coupons?.results[index].applicable_items.findIndex(item => item === action.payload.response_data.item_removed)
        if (index2 != -1) {
          state.coupons?.results[index].applicable_items.splice(index2, 1)
        }
      }
      state.status = 'removing applicable item succeeded'
    },
    [deleteApplicableItem.rejected]: (state, action) => {
      state.status = 'removing applicable item failed'
    },   
  }
});

export const { clearError } = couponsSlice.actions;

export default couponsSlice.reducer;