import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';



const paid = (order) => {
  if (order?.type === 'by_items') {
    return order?.order_items?.filter(item => !item.paid).length === 0;
  } else if (order?.type === 'n_ways') {
    let allPaid = true;
    for (const payment of order?.order_payments) {
      if (!payment.paid) {
        allPaid = false;
        break;
      }
    }
    return allPaid;
  } else {
    return order?.paid;
  }
}

const allReceived = (order) => {
  if (order?.type === 'by_items') {
    return order?.order_payments?.length === 0 || order?.order_payments?.filter(pmt => pmt.paid === true).length === 0
  } else if (order?.type === 'n_ways') {
    let allUnpaid = true;
    for (const payment of order?.order_payments) {
      if (payment.paid) {
        allUnpaid = false;
        break;
      }
    }
    return allUnpaid;
  } else {
    return order?.paid;
  }
}

const partiallyPaid = (order) => {
  if (order?.type === 'by_items') {
    return order?.order_payments?.length > 0 && order?.order_payments?.length < order?.order_items?.length;
  } else if (order?.type === 'n_ways') {
    console.log(order)
    let allPaid = true;
    let paidCount = 0;
    for (const payment of order?.order_payments) {
      if (!payment.paid) {
        allPaid = false;
      } else {
        paidCount++;
      }
    }

    return !allPaid && paidCount > 0 ? true : false
  } else {
    return false;
  }
}

const orderRefundExists = (order) => {
  return order.status === 'refunded';
}

const useCheckPaymentReceived = (order) => {
  const [received, setReceived] = useState(false);
  const [allUnpaid, setAllUnpaid] = useState(false);
  const [partiallyReceived, setPartiallyReceived] = useState(false);
  const [refundExits, setRefundExists] = useState(false)

  useEffect(() => {
    if (order) {
      setReceived(paid(order));
      setPartiallyReceived(partiallyPaid(order))
      setAllUnpaid(allReceived(order))
      setRefundExists(orderRefundExists(order))
    } else {
      setReceived(false)
      setPartiallyReceived(false)
      setAllUnpaid(false)
      setRefundExists(false)
    }
  }, [order])

  const reset = () => {
    setReceived(false);
    setPartiallyReceived(false);
  };

  return { received, partiallyReceived, reset, allUnpaid, refundExits };
};


export default useCheckPaymentReceived;
