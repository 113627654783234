import React from 'react';
import KitchenDisplay from '../../features/kitchen/KitchenDisplay';


const KitchenComponent = (props) => {
  return (
      <KitchenDisplay />
  );
};

export default KitchenComponent;
