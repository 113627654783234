import React, { useState, useEffect, useRef } from 'react';
import { overrideCategory } from './categorySlice';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';


const OverrideCategoryTable = (props) => {
  const dispatch = useDispatch();
  const [actives, setActives] = useState({})

  useEffect(() => {

    props.overrides.map(override => setActives(prevState => (
      {
        ...prevState,
        [override.shop]: override.active
      }
    )))
  }, [props.overrides])

  function overrideExists(shopId) {
    let arr = props.overrides?.filter(override => override.shop == shopId);
    return arr?.length > 0;
  }


  const handleActiveChange = (active, shopId) => {
    setActives(prevState => ({ ...prevState, [shopId]: active }))
  }



  function overrideActive(active, shopId) {
    if (overrideExists(shopId)) {
      dispatch(overrideCategory({shop_id: shopId, category_id: props.id, active: active}));
    } else {
      dispatch(overrideCategory({shop_id: shopId, category_id: props.id, active: active}));
    }
    props.onActiveOverride(active, shopId)
    handleActiveChange(active, shopId);

    // create a handleSwitch2 func on parent
  }

  return (
    <TableContainer style={{maxHeight: 500}} component={Paper}>
     <Table  aria-label="a dense table">
       <TableBody>
       <TableCell component="th" scope="row">
       Override All
       </TableCell>
       <TableCell align="right">
       <Button disabled style={{fontSize: 10}} variant="contained" disableElevation color="primary" size="small">ACTIVE</Button>
       <Button disabled style={{fontSize: 10, marginLeft:2}} variant="outlined" disableElevation color="primary" size="small">INACTIVE</Button>
       </TableCell>

         <TableRow>
           <TableCell component="th" scope="row"><b>Shop ({props.shops.length})</b></TableCell>
           <TableCell component="th" scope="row" align="right"><b>Active</b></TableCell>
         </TableRow>
       {props.shops.map((shop, index) => {
            let overrideArr = []
            if (props.overrides?.length > 0) {
              overrideArr = props.overrides?.filter(override => override.shop === shop.id);
            }
            return <TableRow key={shop.id}>
              <TableCell component="th" scope="row">
                {shop.business_name} {shop.city}
              </TableCell>
              <TableCell align="right">
                {!overrideExists(shop.id) ? (
                  // Override Item isn't attached. Let's create one.
                  props.active ? (
                    <Button onClick={() => overrideActive(false, shop.id)} style={{fontSize: 10}} variant="contained" disableElevation color="primary" size="small">ACTIVE</Button>
                  ) : (
                    <Button onClick={() => overrideActive(true, shop.id)} style={{fontSize: 10}} variant="outlined" size="small">ACTIVE</Button>
                  )
                ) : (
                  // Override Item exists.
                  actives[shop.id] ? (
                    <Button onClick={() => overrideActive(false, shop.id)} style={{fontSize: 10}} variant="contained" disableElevation color="primary" size="small">ACTIVE</Button>
                  ) : (
                    <Button onClick={() => overrideActive(true, shop.id)} style={{fontSize: 10}} variant="outlined" size="small">ACTIVE</Button>
                  )
                )}
              </TableCell>

            </TableRow>
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default OverrideCategoryTable;
