import React, { useEffect, useState, useRef } from 'react';
import TableCanvas from '../TableCanvas/TableCanvas.js'
import { setSelfTriggered, deleteWaitlist, fetchTable, sendRevertConfirmWaitListNotification, sendCancelWaitListNotification, sendWaitListNotification, getWaitlist, sendTableNotification, fetchTables, assignTableWaitList, deactivateInstance, updateTableHold, cancelConfirmWaitList, confirmWaitList, updateTableAvail } from '../tableSlice'
import { updateOrderStatus } from '../../order/orderSlice'
import { useDispatch, useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import GroupIcon from '@material-ui/icons/Group';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useStopwatch } from 'react-timer-hook';
import { io } from "socket.io-client";

const DEFAULT_SOCKETS_URL = 'https://sockets.bigkiosksolution.com';
//const DEFAULT_SOCKETS_URL = 'http://127.0.0.1:5000';

const WaitListCard = (props) => {
  const socketRef = useRef();
  const mountedRef = useRef(false);
  const status = useSelector(state => state.table.status)
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(!props.order?.table_instance?.table.hold ? new Date(props.timestamp) : new Date(props.summoned_timestamp));
  const [stopwatchOffset, setStopwatchOffset] = useState(() => {
    const secondsOffset = Math.floor((new Date() - date) / 1000);
    let offset = new Date();
    offset.setSeconds(offset.getSeconds() + secondsOffset);
    return offset;
  });


  useEffect(() => {
    if (!mountedRef.current) {
      mountedRef.current = true;
    } else {
      setDate(!props.order?.table_instance?.table.hold ? new Date(props.timestamp) : new Date(props.summoned_timestamp));

      const secondsOffset = Math.floor((new Date() - date) / 1000);
      let offset = new Date();
      offset.setSeconds(offset.getSeconds() + secondsOffset);
      setStopwatchOffset(offset);
      reset();
    }
  }, [props.order?.table_instance?.table.hold]);

  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: true, offsetTimestamp: stopwatchOffset });

  const dispatch = useDispatch();
  const waitlist = useSelector(state => state.table.waitlist);
  const [optionHidden, setOptionHidden] = useState(true)

  const handleComplete = (orderId) => {
      if (socketRef.current == null) {
          socketRef.current = io(DEFAULT_SOCKETS_URL, {
              transports: ["websocket", "polling"] // use WebSocket first, if available
          });
      }

      const {current: socket} = socketRef;

      try {
          socket.emit('order-added-from-kiosk', {order_id: orderId, id: localStorage.getItem('shop_id')});
          socket.emit('table-update-from-kiosk', {id: localStorage.getItem('shop_id')});

      } catch (error) {
          console.log(error);
      }
  }

  const handleConfirmWaitlist = async  () => {
    dispatch(setSelfTriggered(true));
    await dispatch(confirmWaitList({waitlist_id: props.id}))
    await dispatch(updateOrderStatus({id: props.order?.id, status: 'processing'}))
    await dispatch(updateTableAvail({id: props.order?.table_instance?.table?.id, available: false}))
    // combine all three functions into one?
    await dispatch(fetchTable({pk: props.order?.table_instance?.table?.id}))
    handleComplete(props.order?.id);
    dispatch(setSelfTriggered(false));
  }

  const handleCancelConfirm = async () => {
    await dispatch(updateTableHold({id: props.order?.table_instance?.table?.id, hold: true}))
    await dispatch(cancelConfirmWaitList({table_id: props.order?.table_instance?.table?.id, waitlist_id: props.id}))
    await dispatch(sendRevertConfirmWaitListNotification({phone: props.phone}))
  }

  const handleWaitListNotification = () => {
    setIsLoading(true)

    dispatch(sendWaitListNotification({phone: props.phone}))
  }

  const handleCancelWaitListNotification = () => {
    dispatch(sendCancelWaitListNotification({phone: props.phone}))
  }

  const handleConfirmWaitListNotification = () => {
    setIsLoading(true)
    dispatch(sendTableNotification({phone: props.phone, table_number: props.order?.table_instance?.table.table_number}))
  }

  const handleDeleteWaitlist = () => {
    dispatch(deleteWaitlist({id: props.id}))
    handleCancelWaitListNotification();
  }

  useEffect(() => {
    if (status === 'sms sent' && isLoading) {
      setIsLoading(false)
    }
  }, [status])


  let display = <Card>
      <CardContent>
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start">
          <Grid item md>
          <span style={{fontSize: 17}}>#{props.index + 1}</span>
          </Grid>
          <Grid item md >
          <div style={{float: 'right'}}>
            <GroupIcon style={{fontSize: 20}} />
            <span style={{position: 'relative', bottom: 4.5, left: 5, fontWeight: 800, fontSize: 16}}>{props.num_party}</span>
          </div>
          </Grid>
        </Grid>
        <Divider />
      </CardContent>
      <CardContent style={{paddingTop:0, marginTop:0}}>
      <h2 style={{textAlign: 'center', margin:0, padding:0}}>
      {hours  > 0 ? String(hours).padStart(2, '0') : '00'}:{minutes > 0 ? String(minutes).padStart(2, '0') : '00'}:{String(seconds).padStart(2, '0')}
      </h2>
      <AssignmentIndIcon />  <span style={{fontSize: 18, position: 'relative', bottom: 5}}>Customer</span> <br />
      <PhoneAndroidIcon /> <span style={{fontSize: 18, position: 'relative', bottom: 5}}>{props.phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}</span>
      </CardContent>
      <CardActions>
        <Button onClick={() => props.onAssignTableButtonClick()} variant="outlined" elevation={0} color="primary" fullWidth>ASSIGN TABLE</Button>
      </CardActions>
      <CardActions>
      {isLoading ? (
        <Button disabled variant="outlined" elevation={0} color="primary" fullWidth>SENDING SMS...</Button>
      ) : (
        <Button onClick={() => handleWaitListNotification()} variant="outlined" elevation={0} color="primary" fullWidth>SEND SMS <Chip style={{marginLeft: 8}} size="small" color="primary" label="Sent" /></Button>
      )}
      </CardActions>
      {!optionHidden ? (
        <>
        <CardActions>
          <Button onClick={() => handleDeleteWaitlist()} variant="contained" elevation={0} color="secondary" fullWidth>DELETE</Button>
        </CardActions>
        <Divider />
            <div style={{textAlign: 'center'}}>
            <IconButton onClick={() => setOptionHidden(true)} aria-label="expand">
                <ExpandLessIcon />
            </IconButton>
          </div>
          </>
      ) : (
        <div style={{textAlign: 'center'}}>
        <IconButton onClick={() => setOptionHidden(false)} aria-label="expand">
            <ExpandMoreIcon />
        </IconButton>
      </div>

      )}
    </Card>


  if (props.active && props.summoned_timestamp) {
    display = <Card style={{border: '2px solid green'}}>
        <CardContent>
          <Grid
            container
            item
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start">
            <Grid item md>
            <span style={{fontSize: 17}}>#{props.index + 1}</span>
            </Grid>
            <Grid item md >
            <div style={{float: 'right'}}>
              <GroupIcon style={{fontSize: 20}} />
              <span style={{position: 'relative', bottom: 5.5}}>{props.num_party}</span>
            </div>
            </Grid>
          </Grid>
          <Divider />
        </CardContent>
        <CardContent style={{paddingTop:0, marginTop:0}}>
        <h2 style={{textAlign: 'center', margin:0, marginBottom:5, padding:0}}>
        ASSIGNED: {props.order?.table_instance?.table.table_number}
        </h2>
        <h3 style={{textAlign: 'center', margin:0, marginBottom:5, padding:0}}>
          WAITING... <br />
          {hours > 0 ? String(hours).padStart(2, '0') : '00'}:{minutes > 0 ? String(minutes).padStart(2, '0') : '00'}:{String(seconds).padStart(2, '0')}

        </h3>
        <AssignmentIndIcon />  <span style={{fontSize: 18, position: 'relative', bottom: 5}}>Customer</span> <br />
        <PhoneAndroidIcon /> <span style={{fontSize: 18, position: 'relative', bottom: 5}}>{props.phone}</span>
        </CardContent>
        <CardActions>
          <Button onClick={() => handleConfirmWaitlist()} variant="outlined" elevation={0} color="primary" fullWidth>CONFIRM SEATING</Button>
        </CardActions>
        <CardActions>
        {isLoading ? (
          <Button disabled variant="outlined" elevation={0} color="primary" fullWidth>SENDING SMS...</Button>
        ) : (
          <Button onClick={() => handleConfirmWaitListNotification()} variant="outlined" elevation={0} color="primary" fullWidth>SEND SMS</Button>
        )}
        </CardActions>
        {!optionHidden ? (
          <>
          <CardActions>
            <Button onClick={() => handleCancelConfirm()} variant="contained" elevation={0} color="secondary" fullWidth>CANCEL</Button>
          </CardActions>
          <Divider />
              <div style={{textAlign: 'center'}}>
              <IconButton onClick={() => setOptionHidden(true)} aria-label="expand">
                  <ExpandLessIcon />
              </IconButton>
            </div>
            </>
        ) : (
          <div style={{textAlign: 'center'}}>
          <IconButton onClick={() => setOptionHidden(false)} aria-label="expand">
              <ExpandMoreIcon />
          </IconButton>
        </div>

        )}
      </Card>
  } else if (!props.active) {
    display = null;
  }

  return (
    display
  )
}

const WaitList = (props) => {

  const dispatch = useDispatch();
  const waitlist = useSelector(state => state.table.waitlist);
  const status = useSelector(state => state.table.status);
  const [assignTableOpen, setAssignTableOpen] = useState(false)
  const [selectedWaitList, setSelectedWaitList] = useState(-1)

  const handleAssignTableDialogOpen = (waitlistId) => {
    setSelectedWaitList(waitlistId);
    setAssignTableOpen(true);
  }

  useEffect(() => {
    dispatch(getWaitlist())
  }, [])


  useEffect(() => {
    if (status === 'assign finished') {
      const index = waitlist.findIndex(wait => wait.id === selectedWaitList);
      if (index != -1) {
        dispatch(sendTableNotification({phone: waitlist[index].phone, table_number: waitlist[index].order?.table_instance?.table.table_number}))
      }
    }
  }, [status])

  return (
    <>
    <Grid direction="column" container spacing={2}>
    {waitlist?.map((item, index) =>
      {
        return item.active ? (
          <Grid key={item.id} item xs={12} sm={6} md={5} lg={3}>
            <WaitListCard onAssignTableButtonClick={() => handleAssignTableDialogOpen(item.id)} {...item} index={index}  />
          </Grid>
        ) : (
          null
        )
      }
    )}
    </Grid>
    <AssignTableDialog  waitlistId={selectedWaitList} onClose={() => setAssignTableOpen(false)} open={assignTableOpen} />
    </>
  )
}

const AssignTableDialog = (props) => {
  const tables = useSelector(state => state.table.tables)
  const [availTables, setAvailTables] = useState([])
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.open) {
      dispatch(fetchTables());
    }
  }, [props.open])

  useEffect(() => {
    setAvailTables(tables.filter(table => table.hold === true))
  }, [tables])

  const handleAssignTable = (id) => {
    // assign table using id (table_id)
    dispatch(assignTableWaitList({table_id: id, waitlist_id: props.waitlistId}))
    props.onClose()
  }

  return (
    <Dialog  onClose={() => props.onClose()} open={props.open}>
      <div style={{width: 500, padding: 20}}>
        <h3 style={{margin: 0, padding: 0}}>Assign a Table

        </h3>

        <List style={{marginTop: 0}}>
          <p style={{margin: 0, padding: 3}}></p>
          {availTables?.map(table => <ListItem onClick={() => handleAssignTable(table.id)} divider style={{display: 'block', paddingLeft:0, fontSize: 20}} button key={table.id}>{table.table_number}</ListItem>)}
        </List>
      </div>
    </Dialog>
  )
}

const ConfirmDeleteDialog = (props) => {
  return (
    <Dialog  open={props.open}>
    </Dialog>
  )
}

export default WaitList;
