import React from 'react';
import AddOptionModal from '../modal/AddOptionModal'

const AddOnAddModal = () => {
  return (
    <AddOptionModal />
  );
};

export default AddOnAddModal;
