import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET, DELETE, POST_SOCKET_IO, POST, PUT, PATCH, POST_FILE } from '../../api/DashboardAPI';

export const clearAll = createAsyncThunk('table/clearAll', async (payload, { rejectWithValue }) => {
  /* payload: {x: number, y: number, id: number} */
  try {
    const response = await POST('/dashboard/shape/clear_all/');
    return {data: payload, response: response.data} // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const updatePosition = createAsyncThunk('table/updatePosition', async (payload, { rejectWithValue }) => {
  /* payload: {x: number, y: number, id: number} */
  try {
    const response = await POST('/dashboard/shape/'+ payload.id +'/update_pos/', payload);
    return {data: payload, response: response.data} // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const updateWidth = createAsyncThunk('table/updateWidth', async (payload, { rejectWithValue }) => {
  /* payload: {x: number, y: number, id: number} */
  try {
    const response = await POST('/dashboard/shape/'+ payload.id +'/update_width/', payload);
    return {data: payload, response: response.data} // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});


export const updateZ = createAsyncThunk('table/updateZ', async (payload, { rejectWithValue }) => {
  /* payload: {x: number, y: number, id: number} */
  try {
    const response = await POST('/dashboard/shape/'+ payload.id +'/update_z/', payload);
    return {data: payload, response: response.data} // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const updateHeight = createAsyncThunk('table/updateHeight', async (payload, { rejectWithValue }) => {
  /* payload: {x: number, y: number, id: number} */
  try {
    const response = await POST('/dashboard/shape/'+ payload.id +'/update_height/', payload);
    return {data: payload, response: response.data} // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const updateDegree = createAsyncThunk('table/updateDegree', async (payload, { rejectWithValue }) => {
  /* payload: {x: number, y: number, id: number} */
  try {
    const response = await POST('/dashboard/shape/'+ payload.id +'/update_degree/', payload);
    return {data: payload, response: response.data} // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const updateSize = createAsyncThunk('table/updateSize', async (payload, { rejectWithValue }) => {
  /* payload: {x: number, y: number, id: number} */
  try {
    const response = await POST('/dashboard/shape/'+ payload.id +'/update_size/', payload);
    return {data: payload, response: response.data} // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const updateTable = createAsyncThunk('table/updateTable', async (payload, { rejectWithValue }) => {
  /* payload: {x: number, y: number, id: number} */
  try {
    const response = await POST('/dashboard/shape/'+ payload.id +'/update_table/', payload);
    console.log(response.data)
    return {data: payload, data2: response.data} // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const updateTableNumber = createAsyncThunk('table/updateTableNumber', async (payload, { rejectWithValue }) => {
  /* payload: {x: number, y: number, id: number} */
  try {
    const response = await POST('/dashboard/table/'+ payload.id +'/update_table_number/', payload);
    return {data: payload, response: response.data} // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const updateTableAvail = createAsyncThunk('table/updateTableAvail', async (payload, { rejectWithValue }) => {
  /* payload: {x: number, y: number, id: number} */
  try {
    const response = await POST('/dashboard/table/'+ payload.id +'/update_table_availability/', payload);
    return {data: payload, response: response.data} // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const updateTableHold = createAsyncThunk('table/updateTableHold', async (payload, { rejectWithValue }) => {
  /* payload: {x: number, y: number, id: number} */
  try {
    const response = await POST('/dashboard/table/'+ payload.id +'/update_table_hold_status/', payload);
    console.log(response.data)
    return {data: payload, response: response.data} // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const updateText = createAsyncThunk('table/updateText', async (payload, { rejectWithValue }) => {
  /* payload: {x: number, y: number, id: number} */
  try {
    const response = await POST('/dashboard/shape/'+ payload.id +'/update_text/', payload);
    return {data: payload, response: response.data} // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const deactivateInstance = createAsyncThunk('table/deactivateInstance', async (payload, { rejectWithValue }) => {
  /* payload: {x: number, y: number, id: number} */
  try {
    const response = await POST('/dashboard/table/'+ payload.id +'/deactivate_instance/');
    return {data: payload, response: response.data} // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});


export const postShape = createAsyncThunk('table/postShape', async (payload) => {
  const response = await POST('/dashboard/shape/', payload);
  return response.data; // payload
});

export const postTable = createAsyncThunk('table/postTable', async (payload) => {
  const response = await POST('/dashboard/table/', payload);
  return response.data; // payload
});

export const deleteTable = createAsyncThunk('table/deleteTable', async (payload) => {
  const response = await DELETE('/dashboard/table/' + payload.id + '/');
  return { id: payload.id, response: payload.response }; // payload
});

export const deleteWaitlist = createAsyncThunk('table/deleteWaitlist', async (payload) => {
  const response = await DELETE('/dashboard/waitlist/' + payload.id + '/');
  return { id: payload.id, response: payload.response }; // payload
});

export const deleteShape = createAsyncThunk('table/deleteShape', async (payload) => {
  const response = await DELETE('/dashboard/shape/' + payload.id + '/');
  return { id: payload.id, response: payload.response }; // payload
});

export const fetchShapes = createAsyncThunk('table/fetchShapes', async (payload) => {
  const response = await GET('/dashboard/shape/');
  return response.data.results; // payload
});

export const fetchTables = createAsyncThunk('table/fetchTables', async (payload) => {
  const response = await GET('/dashboard/table/fetch_tables/');
  return response.data; // payload
});

export const fetchTable = createAsyncThunk('table/fetchTable', async (payload) => {
  const response = await GET('/dashboard/table/' + payload.pk + '/');
  return response.data; // table obj
});


export const fetchTableForPayment = createAsyncThunk('table/fetchTableForPayment', async (payload) => {
  const response = await GET('/dashboard/table/' + payload.pk + '/');
  return response.data; // table obj
});

export const fetchTableForCancel = createAsyncThunk('table/fetchTableForCancel', async (payload) => {
  const response = await GET('/dashboard/table/' + payload.pk + '/');
  return response.data; // table obj
});

export const fetchTableCanvas = createAsyncThunk('table/fetchTableCanvas', async (payload) => {
  const response = await GET('/dashboard/table-canvas/');
  return response.data.results[0]; // payload
});

export const postTableCanvas = createAsyncThunk('table/postTableCanvas', async (payload) => {
  const response = await POST('/dashboard/table-canvas/');
  return response.data; // payload
});

export const postTableInstance = createAsyncThunk('table/postTableInstance', async (payload) => {
  const response = await POST('/dashboard/table-instance/', payload);
  return {data: payload, response: response.data} // payload
});


export const getTableInstances = createAsyncThunk('table/getTableInstances', async (payload) => {
  const response = await POST('/dashboard/table/' + payload.id + '/get_table_instances/');
  return {data: payload, response: response.data} // payload
});

export const clearTableInstance = createAsyncThunk('order/clearTableInstance', async (payload) => {
  const response = await POST('/dashboard/table/clear_table_instance/', {order_id: payload.orderId, instance_id: payload.instanceId});
  return {data: payload, response: response.data} // payload
});

export const setTableInstance = createAsyncThunk('order/setTableInstance', async (payload) => {
  const response = await POST('/dashboard/order/set_table_instance/', {order_id: payload.orderId, instance_id: payload.instanceId});
  return {data: payload, response: response.data} // payload
});

export const getWaitlist = createAsyncThunk('table/getWaitlist', async (payload) => {
  const response = await GET('/dashboard/table/get_waitlist/');
  return response.data; // payload
});

export const assignTableWaitList = createAsyncThunk('table/assignTableWaitList', async (payload) => {
  const response = await POST('/dashboard/table/assign_table_waitlist/', payload);
  return response.data; // payload
});

export const confirmWaitList = createAsyncThunk('table/confirmWaitList', async (payload) => {
  const response = await POST('/dashboard/table/confirm_waitlist/', payload);
  return response.data; // payload
});

export const cancelConfirmWaitList = createAsyncThunk('table/cancelConfirmWaitList', async (payload) => {
  const response = await POST('/dashboard/table/cancel_confirm_waitlist/', payload);
  return response.data; // payload
});


export const sendTableNotification = createAsyncThunk('order/sendTableNotification', async (payload) => {
  /*
  obj = {
    number: _int (order number)
    phone: _str (phone number)
  }
  */
  const response = await POST('/dashboard/notify_table_sms/', payload);
  return response.data;
});


export const sendWaitListNotification = createAsyncThunk('order/sendWaitListNotification', async (obj) => {
  /*
  obj = {
    number: _int (order number)
    phone: _str (phone number)
  }
  */
  const response = await POST('/dashboard/waitlist_sms/', obj);
  return response.data;
});


export const sendCancelWaitListNotification = createAsyncThunk('order/sendCancelWaitListNotification', async (obj) => {
  /*
  obj = {
    number: _int (order number)
    phone: _str (phone number)
  }
  */
  const response = await POST('/dashboard/cancel_waitlist_sms/', obj);
  return response.data;
});

export const sendRevertConfirmWaitListNotification = createAsyncThunk('order/sendRevertConfirmWaitListNotification', async (obj) => {
  /*
  obj = {
    number: _int (order number)
    phone: _str (phone number)
  }
  */
  const response = await POST('/dashboard/revert_confirm_waitlist_sms/', obj);
  return response.data;
});


export const tableSlice = createSlice({
  name: 'table',
  initialState: {
    shapes: [
      /*
      shape: {
        id: int,
        type: "circle",
        coord: {
          x: 0,
          y: 0
        },
      }
      */
    ],
    waitlist: [],
    tables: [],
    status: 'idle',
    checkoutDialogOpen: false,
    checkoutDialogOrder: null,
    checkoutDialogReceived: false,
    selfTriggered: false,
    paymentComplete: false,
  },
  reducers: {
    setPaymentComplete: (state, action) => {
      state.paymentComplete = action.payload;
    },
    setSelfTriggered: (state, action) => {
      state.selfTriggered = action.payload;
    },
    setDialogOpen: (state, action) => {
      state.checkoutDialogOpen = action.payload
    },
    setDialogOrder: (state, action) => {
      state.checkoutDialogOrder = action.payload
    },
    setDialogReceived: (state, action) => {
      state.checkoutDialogReceived = action.payload
    },
    setTableStatus: (state, action) => {
      state.status = action.payload;

    }
  },
  extraReducers: {
    [postShape.pending]: (state, action) => {
      state.status = 'shape loading'
    },
    [postShape.fulfilled]: (state, action) => {
      state.status = 'shape added'
      state.shapes.push(action.payload)
    },
    [postShape.rejected]: (state, action) => {
      state.status = 'shape failed'
      state.error = action.error.message
    },
    [deleteShape.pending]: (state, action) => {
      state.status = 'shape loading'
    },
    [deleteShape.fulfilled]: (state, action) => {
      state.status = 'shape deleted'
      const id = action.payload.id
      const idx = state.shapes.findIndex(shape => shape.id === id)
      if (idx !== -1) {
        state.shapes.splice(idx, 1);
      }
    },
    [deleteShape.rejected]: (state, action) => {
      state.status = 'shape failed'
      state.error = action.error.message
    },
    [postTableCanvas.pending]: (state, action) => {
      state.status = 'table canvas loading'
    },
    [postTableCanvas.fulfilled]: (state, action) => {
      state.status = 'table canvas added'
    },
    [postTableCanvas.rejected]: (state, action) => {
      state.status = 'table canvas failed'
    },
    [postTableInstance.pending]: (state, action) => {
      state.status = 'table instance loading'
    },
    [postTableInstance.fulfilled]: (state, action) => {
      const tableId = action.payload.data.table;
      const idx = state.tables.findIndex(table => table.id === tableId)
      if (idx !== -1) {
        state.tables[idx].table_instances.unshift(action.payload.response);
        state.status = 'table instance added'
      }
    },
    [postTableInstance.rejected]: (state, action) => {
      state.status = 'table instance failed'
    },
    [getTableInstances.pending]: (state, action) => {
      state.status = 'table instance loading'
    },
    [getTableInstances.fulfilled]: (state, action) => {
      state.status = 'table instance added'
      const tableId = action.payload.data.id;
      const idx = state.tables.findIndex(table => table.id === tableId)
      if (idx !== -1) {
        state.tables[idx].table_instances = action.payload.response;
      }
    },
    [getTableInstances.rejected]: (state, action) => {
      state.status = 'table instance failed'
    },
    [fetchShapes.pending]: (state, action) => {
      state.status = 'fetch shapes loading'
    },
    [fetchShapes.fulfilled]: (state, action) => {
      state.shapes = action.payload;

      state.status = 'fetch shapes fulfilled'
    },
    [fetchShapes.rejected]: (state, action) => {
      state.status = 'fetch shapes failed'
      state.error = action.error.message
    },

    [fetchTableCanvas.pending]: (state, action) => {
      state.status = 'fetch shapes loading'
    },
    [fetchTableCanvas.fulfilled]: (state, action) => {
      if (action.payload && action.payload.shapes) {
        state.shapes = action.payload.shapes;

      }
      state.status = 'fetch shapes fulfilled'
    },
    [fetchTableCanvas.rejected]: (state, action) => {
      state.status = 'fetch shapes failed'
      state.error = action.error.message
    },

    [updatePosition.pending]: (state, action) => {
      state.status = 'update pos loading'
    },
    [updatePosition.fulfilled]: (state, action) => {
      const id = action.payload.data.id;
      const x = action.payload.data.x;
      const y = action.payload.data.y;
      const idx = state.shapes.findIndex(shape => shape.id === id)
      if (idx !== -1) {
        state.shapes[idx].x = x
        state.shapes[idx].y = y
      }
    },
    [updatePosition.rejected]: (state, action) => {
      state.status = 'update pos failed'
    },
    [updateWidth.pending]: (state, action) => {
      state.status = 'update pos loading'
    },
    [updateWidth.fulfilled]: (state, action) => {
      const id = action.payload.data.id;
      const width = action.payload.data.width;
      const idx = state.shapes.findIndex(shape => shape.id === id)
      if (idx !== -1) {
        state.shapes[idx].width = width
      }
    },
    [updateWidth.rejected]: (state, action) => {
      state.status = 'update pos failed'
      state.error = action.payload.error.message
    },

    [updateZ.pending]: (state, action) => {
      state.status = 'update pos loading'
    },
    [updateZ.fulfilled]: (state, action) => {
      const id = action.payload.data.id;
      const z = action.payload.data.z;
      const idx = state.shapes.findIndex(shape => shape.id === id)
      if (idx !== -1) {
        state.shapes[idx].z = z
      }
    },
    [updateZ.rejected]: (state, action) => {
      state.status = 'update pos failed'
      state.error = action.payload.error.message
    },
    [updateHeight.pending]: (state, action) => {
      state.status = 'update pos loading'
    },
    [updateHeight.fulfilled]: (state, action) => {
      const id = action.payload.data.id;
      const height = action.payload.data.height;
      const idx = state.shapes.findIndex(shape => shape.id === id)
      if (idx !== -1) {
        state.shapes[idx].height = height
      }
    },
    [updateHeight.rejected]: (state, action) => {
      state.status = 'update pos failed'
      state.error = action.payload.error.message
    },
    [updateDegree.pending]: (state, action) => {
      state.status = 'update pos loading'
    },
    [updateDegree.fulfilled]: (state, action) => {
      const id = action.payload.data.id;
      const degree = action.payload.data.degree;
      const idx = state.shapes.findIndex(shape => shape.id === id)
      if (idx !== -1) {
        state.shapes[idx].degree = degree
      }
    },
    [updateDegree.rejected]: (state, action) => {
      state.status = 'update pos failed'
      state.error = action.payload.error.message
    },
    [updateSize.pending]: (state, action) => {
      state.status = 'update pos loading'
    },
    [updateSize.fulfilled]: (state, action) => {
      const id = action.payload.data.id;
      const size = action.payload.data.size;
      const idx = state.shapes.findIndex(shape => shape.id === id)
      if (idx !== -1) {
        state.shapes[idx].size = size
      }
    },
    [updateSize.rejected]: (state, action) => {
      state.status = 'update pos failed'
      state.error = action.payload.error.message
    },
    [updateTable.pending]: (state, action) => {
      state.status = 'update pos loading'
    },
    [updateTable.fulfilled]: (state, action) => {
      const id = action.payload.data.id;
      const table = action.payload.data2.table;
      const idx = state.shapes.findIndex(shape => shape.id === id)
      if (idx !== -1) {
        state.shapes[idx].table = table
      }
    },
    [updateTable.rejected]: (state, action) => {
      state.status = 'update pos failed'
    },

    [updateText.pending]: (state, action) => {
      state.status = 'update text loading'
    },
    [updateText.fulfilled]: (state, action) => {
      const id = action.payload.data.id;
      const text = action.payload.data.text;
      const idx = state.shapes.findIndex(shape => shape.id === id)
      if (idx !== -1) {
        state.shapes[idx].text = text
      }
    },
    [updateText.rejected]: (state, action) => {
      state.status = 'update pos failed'
      state.error = action.payload.error.message
    },
    [fetchTables.pending]: (state, action) => {
      state.status = 'fetch tables loading'
    },
    [fetchTables.fulfilled]: (state, action) => {
      state.tables = action.payload;

      state.status = 'fetch tables fulfilled'
    },
    [fetchTables.rejected]: (state, action) => {
      state.status = 'fetch tables failed'
      state.error = action.error.message
    },

    [fetchTable.pending]: (state, action) => {
      state.status = 'fetch table loading'
    },
    [fetchTable.fulfilled]: (state, action) => {
      const index = state.tables.findIndex(table => table.id === action.payload.id)
      if (index != -1) {
        state.tables[index] = action.payload
      }


      state.status = 'fetch table fulfilled'
    },
    [fetchTable.rejected]: (state, action) => {
      state.status = 'fetch table failed'
      state.error = action.error.message
    },

    [fetchTableForCancel.pending]: (state, action) => {
      state.status = 'fetch table for cancel loading'
    },
    [fetchTableForCancel.fulfilled]: (state, action) => {
      const index = state.tables.findIndex(table => table.id === action.payload.id)
      if (index != -1) {
        state.tables[index] = action.payload
      }


      state.status = 'fetch table for cancel fulfilled'
    },
    [fetchTableForCancel.rejected]: (state, action) => {
      state.status = 'fetch table for cancel failed'
      state.error = action.error.message
    },


    [fetchTableForPayment.pending]: (state, action) => {
      state.status = 'fetch table loading'
    },
    [fetchTableForPayment.fulfilled]: (state, action) => {
      const index = state.tables.findIndex(table => table.id === action.payload.id)
      if (index != -1) {
        state.tables[index] = action.payload
      }


      state.status = 'fetch table fulfilled 2'
    },
    [fetchTableForPayment.rejected]: (state, action) => {
      state.status = 'fetch table failed'
      state.error = action.error.message
    },

    [deleteTable.pending]: (state, action) => {
      state.status = 'table loading'
    },
    [deleteTable.fulfilled]: (state, action) => {
      state.status = 'table deleted'
      const id = action.payload.id
      const idx = state.tables.findIndex(shape => shape.id === id)
      if (idx !== -1) {
        state.tables.splice(idx, 1);
      }
    },
    [deleteTable.rejected]: (state, action) => {
      state.status = 'table failed'
    },

    [deleteWaitlist.pending]: (state, action) => {
      state.status = 'deleting'
    },
    [deleteWaitlist.fulfilled]: (state, action) => {
      const id = action.payload.id
      const idx = state.waitlist.findIndex(waitlist => waitlist.id === id)
      if (idx !== -1) {
        state.waitlist.splice(idx, 1);
        state.status = 'waitlist deleted'
      }
    },
    [deleteWaitlist.rejected]: (state, action) => {
      state.status = 'waitlist delete failed'
    },

    [postTable.pending]: (state, action) => {
      state.status = 'table loading'
    },
    [postTable.fulfilled]: (state, action) => {
      state.status = 'table added'
      state.tables.push(action.payload)
    },
    [postTable.rejected]: (state, action) => {
      state.status = 'table failed'
    },

    [updateTableNumber.pending]: (state, action) => {
      state.status = 'update table number loading'
    },
    [updateTableNumber.fulfilled]: (state, action) => {
      const id = action.payload.data.id;
      const table_number = action.payload.response.table_number;
      const max_capacity = action.payload.response.max_capacity;

      const idx = state.tables.findIndex(shape => shape.id === id)
      if (idx !== -1) {
        state.tables[idx].table_number = table_number
        state.tables[idx].max_capacity = max_capacity

      }
      state.status = 'update table number finished'
    },
    [updateTableNumber.rejected]: (state, action) => {
      state.status = 'update table number failed'
    },
    [updateTableAvail.rejected]: (state, action) => {
      state.status = 'update table avail failed'
    },
    [updateTableAvail.pending]: (state, action) => {
      state.status = 'update table avail loading'
    },
    [updateTableAvail.fulfilled]: (state, action) => {
      const id = action.payload.data.id;
      const avail = action.payload.data.available;
      const hold = action.payload.data.hold;
      const idx = state.tables.findIndex(shape => shape.id === id)
      if (idx !== -1) {
        state.tables[idx].available = avail
        state.tables[idx].hold = false
      }
      state.status = 'update table avail finished'
    },
    [updateTableHold.rejected]: (state, action) => {
      state.status = 'update table hold failed'
    },
    [updateTableHold.pending]: (state, action) => {
      state.status = 'update table hold loading'
    },
    [updateTableHold.fulfilled]: (state, action) => {
      const id = action.payload.data.id;
      const hold = action.payload.data.hold;
      const avail = action.payload.data.available;

      const idx = state.tables.findIndex(shape => shape.id === id)
      if (idx !== -1) {
        state.tables[idx].available = avail
        state.tables[idx].hold = hold
        state.status = 'update table hold finished'

      }
    },
    [deactivateInstance.rejected]: (state, action) => {
      state.status = 'deactivate failed'
    },

    [deactivateInstance.pending]: (state, action) => {
      state.status = 'deactivating'
    },
    [deactivateInstance.fulfilled]: (state, action) => {
      const id = action.payload.data.id;
      const instanceId = action.payload.response.id;

      const idx = state.tables.findIndex(table => table.id === id)
      if (idx !== -1) {
        const idx2 = state.tables[idx].table_instances = action.payload.response
      }
      state.status = 'deactivated';
    },

    [clearAll.pending]: (state, action) => {
      state.status = 'clearing'
    },
    [clearAll.fulfilled]: (state, action) => {
      state.shapes = []
    },
    [clearAll.rejected]: (state, action) => {
      state.status = 'clearing failed'
    },
    [setTableInstance.pending]: (state, action) => {
      state.status = 'table instance loading'
    },
    [setTableInstance.fulfilled]: (state, action) => {
      const tableId = action.payload.response.id;
      const idx = state.tables.findIndex(table => table.id === tableId)
      if (idx !== -1) {
        state.tables[idx] = action.payload.response
        state.status = 'table instance added'
      }
    },
    [setTableInstance.rejected]: (state, action) => {
      state.status = 'table instance failed'
    },
    [clearTableInstance.pending]: (state, action) => {
      state.status = 'table instance loading'
    },
    [clearTableInstance.fulfilled]: (state, action) => {
      const tableId = action.payload.response.id;
      const idx = state.tables.findIndex(table => table.id === tableId)
      if (idx !== -1) {
        state.tables[idx] = action.payload.response
      }
      state.status = 'table instance cleared'

    },
    [clearTableInstance.rejected]: (state, action) => {
      state.status = 'table instance failed'
    },

    [getWaitlist.pending]: (state, action) => {
      state.status = 'waitlist loading'
    },
    [getWaitlist.fulfilled]: (state, action) => {
      state.waitlist = action.payload;
      state.status = 'waitlist retrieved'
    },
    [getWaitlist.rejected]: (state, action) => {
      state.status = 'waitlist failed'
    },

    [assignTableWaitList.pending]: (state, action) => {
      state.status = 'assign loading'
    },
    [assignTableWaitList.fulfilled]: (state, action) => {
      const index = state.waitlist.findIndex(waitlist => waitlist.id === action.payload.id)
      if (index != -1) {
        state.waitlist[index] = action.payload
      }
      state.status = 'assign finished'
    },
    [assignTableWaitList.rejected]: (state, action) => {
      state.status = 'assign failed'
    },

    [confirmWaitList.pending]: (state, action) => {
      state.status = 'confirm loading'
    },
    [confirmWaitList.fulfilled]: (state, action) => {
      const index = state.waitlist.findIndex(waitlist => waitlist.id === action.payload.id)
      if (index != -1) {
        state.waitlist[index] = action.payload
      }
      state.status = 'confirm finished'
    },
    [confirmWaitList.rejected]: (state, action) => {
      state.status = 'confirm failed'
    },

    [cancelConfirmWaitList.pending]: (state, action) => {
      state.status = 'cancel loading'
    },
    [cancelConfirmWaitList.fulfilled]: (state, action) => {
      const index = state.waitlist.findIndex(waitlist => waitlist.id === action.payload.id)
      if (index != -1) {
        state.waitlist[index] = action.payload
      }
      state.status = 'cancel finished'
    },
    [cancelConfirmWaitList.rejected]: (state, action) => {
      state.status = 'cancel failed'
    },
    [sendTableNotification.pending]: (state, action) => {
      state.status = 'sms sending'
    },
    [sendTableNotification.fulfilled]: (state, action) => {
      state.status = 'sms sent'
    },
    [sendTableNotification.rejected]: (state, action) => {
      state.status = 'sms sending failed'
    },
    [sendWaitListNotification.pending]: (state, action) => {
      state.status = 'sms sending'
    },
    [sendWaitListNotification.fulfilled]: (state, action) => {
      state.status = 'sms sent'
    },
    [sendWaitListNotification.rejected]: (state, action) => {
      state.status = 'sms sending failed'
    },
    [sendCancelWaitListNotification.pending]: (state, action) => {
      state.status = 'sms sending'
    },
    [sendCancelWaitListNotification.fulfilled]: (state, action) => {
      state.status = 'sms sent'
    },
    [sendCancelWaitListNotification.rejected]: (state, action) => {
      state.status = 'sms sending failed'
    },
    [sendRevertConfirmWaitListNotification.pending]: (state, action) => {
      state.status = 'sms sending'
    },
    [sendRevertConfirmWaitListNotification.fulfilled]: (state, action) => {
      state.status = 'sms sent'
    },
    [sendRevertConfirmWaitListNotification.rejected]: (state, action) => {
      state.status = 'sms sending failed'
    }
  }
});


export const { setTableStatus, setPaymentComplete, setDialogOpen, setDialogOrder, setDialogReceived, setSelfTriggered } = tableSlice.actions;

export default tableSlice.reducer;

export const shapes = state => state.table.shapes;
