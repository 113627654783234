import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import {
  postAuthLogin, postAuthRefresh, selectRefreshToken, selectAccessToken,
  selectRefreshExpired, selectAccessTokenExpired, postRegisterUser, postCustomBusinessData
} from './authSlice'
import { FormControl, FormHelperText } from '@material-ui/core';
import {useDispatch, useSelector} from "react-redux";
import MainLogoSvg from '../../assets/61d8c0fe1e774f433535395a_kiostart_logo.svg';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { useForm, Controller } from "react-hook-form";
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import HttpsIcon from '@material-ui/icons/Https';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { DropzoneArea } from 'material-ui-dropzone'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 40,
    width: 475,
    [theme.breakpoints.down('sm')]: {
      width: "95%",
      padding: 20
    },
    marginTop: 30,
    marginBottom: 30
  },
  alert: {
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  gridRoot: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  }
}));

const SignupCustomAdditional = (props) => {
  const { control, register, handleSubmit } = useForm();
  const classes = useStyles();
  const theme = createMuiTheme({
    typography: {
      fontFamily: 'Heebo, sans-serif',
    },
   palette: {
    background: {
      default: 'rgb(247, 249, 252)'
    },
    primary: {
      main: '#438abd'
    },
    formControl: {
      minWidth: 120,
    },
  }
  });

  const history = useHistory();
  const dispatch = useDispatch();
  const status = useSelector(state => state.auth.status);
  const msg = useSelector(state => state.auth.serverMessage);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [files, setFiles] = React.useState([]);
  const [showPhotoRequire, setShowPhotoRequire] = React.useState(false);

  const handleFileUpload = (files) => {

    setFiles(files)
  }

  const onSubmit = data => {

    let formData = new FormData();

    formData.append('dob', selectedDate.toISOString().substring(0, 10));

    if (files.length > 0) {
      formData.append('id_picture', files[0]);
    }

    if (files.length == 0) {
      setShowPhotoRequire(true)
    } else {

      setShowPhotoRequire(false)
      dispatch(postCustomBusinessData(formData))

    }
  }

  useEffect(() => {
    if (localStorage.getItem('refresh_token')) {
      history.push('/')
    }
  }, [])

  useEffect(() => {
     if (status == 'registered' || (status == 'authorized' && localStorage.getItem('refresh_token'))) {
      history.push('/')
    }
  }, [status])

  return (
    localStorage.getItem('refresh_token') ?
    <React.Fragment>
      <h1>Redirecting...</h1>
    </React.Fragment>
    :
    status === 'custom form submitted' ? (
      <React.Fragment>
      <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth={false} disableGutters>

      <Grid style={{paddingTop: 50, paddingBottom: 50}}
            container
            direction="column"
            justify="center"
            alignItems="center">
            <Card className={classes.root}>
              <Grid item>
                <img src={MainLogoSvg} style={{width: 100}}/>
              </Grid>
              <Grid item>
              <h4 style={{fontWeight: 400}}>Thank you! It'll take about 24-hours or less to process. Our service representative will contact you once we finish setting up your account.</h4>
              </Grid>
            </Card>
        </Grid>
        </Container>
        </ThemeProvider>
      </React.Fragment>
    ) : (
    <React.Fragment>
    <ThemeProvider theme={theme}>
    <CssBaseline />
    <Container  maxWidth={false} disableGutters>
    <Grid className={classes.gridRoot}
        spacing={0}
          justify="center"
          alignItems="center">
          <Card className={classes.root}>
            <Grid                 container
                            item
                            direction="column"
                            justifyContent="center"
                            alignItems="center">
                {status === 'register failed' ?
                  <Alert
                    className={classes.alert}
                    style={{marginBottom: 20}}
                    elevation={0}
                    variant="filled"
                    severity="warning">
                    {msg}
                  </Alert> : null}

                <img src={MainLogoSvg} style={{width: 100}}/>
                <h2 className="roboto-font">Identity Verification</h2>
                <p style={{marginTop: -10}}>Almost there! We just need to confirm your identity</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                      <FormControl style={{marginTop: 13, marginBottom: 13}} required fullWidth>
                      <KeyboardDatePicker
                        inputVariant="outlined"
                        name="dob"
                        label="Date of Birth"
                        placeholder="2018/10/30"
                        value={selectedDate}
                        onChange={date => setSelectedDate(date)}
                        format="yyyy/MM/DD"

                      />
                        </FormControl>




                      <FormControl style={{marginTop: 10}} required fullWidth>
                      <label style={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "0.75rem", marginBottom: 5}}>Identity Verification</label>
                      <DropzoneArea
                        maxFileSize={10000000}
                        dropzoneText={<small style={{fontSize: 17, color: "rgba(0, 0, 0, 0.54)"}}>Upload a copy of a government-issued photo ID</small>}
                        filesLimit={1}
                        onChange={(files) => handleFileUpload(files)}
                      />
                      {showPhotoRequire ? (
                        <p style={{marginTop: 6, color: 'red', float: 'right'}}>Photo is required</p>
                      ) : (
                        null
                      )}
                      </FormControl>
                      <p>Partnered card processing company must collect, verify, and maintain information on the individuals associated with every account, as required by their regulators and financial partners. This is intended to promote transparency and prevent individuals from using complex company structures to hide terrorist financing, money laundering, tax evasion, and other financial crimes. Kio Start, LLC WILL NOT store your data. Your photo copy will simply be forwarded to partnered card processing company</p>
                <Button type="submit" style={{float: "right", marginRight: 0, marginTop: 10}} variant="contained" color="primary">
                  Submit
                </Button>
                </form>
            </Grid>
          </Card>

      </Grid>
      </Container>
    </ThemeProvider>
    </React.Fragment>)
  )
}

export default SignupCustomAdditional;
