import React, {useEffect} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { borders } from '@material-ui/system';
import { fade, makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getSystemUser } from '../../features/auth/authSlice';
import Skeleton from '@material-ui/lab/Skeleton';
import MenuIcon from '@material-ui/icons/Menu';
import OrangeLogo from '../../assets/kio-orange.png';
import KioLogoText from '../../assets/kio-logo-text.png';
import { Icon } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${250}px)`,
    marginLeft: 250,
    marginBottom: 0,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: 0,
      marginBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 0,
      marginBottom: 0,
    },
    
    borderBottom: '1px solid #e3e9ec',
    borderLeft: 0,
    boxShadow: '0 0.09rem .13rem rgba(0,0,0,.075)'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: -10,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(0),
      width: 'auto',
    },
        [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'black',
    zIndex: 100
  },
  inputRoot: {
    backgroundColor: "#fafafa",
    color: 'black',
    borderRadius: 4,
    paddingLeft: 6
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  menuButton: {
    //marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
    marginLeft: 0,
    marginRight: 15
  },
}));

function UserSkeleton() {
 return (
   <React.Fragment>
     <Skeleton style={{marginRight:7}} variant="circle" width={25} height={25} />
     <Skeleton variant="rect" width={100} height={20} />
   </React.Fragment>
 );
}

const Header = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const user = useSelector(state => state.auth.user);

  useEffect(() => {
    if (user === null) {
      dispatch(getSystemUser());
    }
  }, [user, localStorage.getItem('access_token')]);

 const handleMenu = (event) => {
   setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
   setAnchorEl(null);
 };

  const classes = useStyles();
  return (
    <AppBar elevation={0} color="inherit" className={classes.appBar}>
      <Toolbar style={{paddingLeft:12.5, paddingRight: 5}} disableGutters>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={props.handleDrawerToggle}
        className={classes.menuButton}
      >
        <MenuIcon />

      </IconButton>

         <div className={classes.search}>
         <img src={OrangeLogo} style={{position: 'relative', top: 0,width: 22.4}} />

        <img src={KioLogoText} style={{width: 100, marginRight: 2.5, zIndex: 1}}/> 

           
          </div>
          <div style={{marginLeft: 'auto', marginRight: 10}}>
          <Button color="primary"
                  onClick={handleMenu}>
                  {user ? <React.Fragment><AccountCircleIcon style={{color: 'rgb(77 93 95)', marginRight: 5}} /> <span style={{color: 'rgb(77 93 95)'}}>{user.user.username}</span></React.Fragment> : <UserSkeleton /> }
          </Button>
          <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={() => { history.push('/account'); handleClose(); }}>My Account</MenuItem>
              <MenuItem onClick={() => history.push('/logout')}>Logout</MenuItem>
            </Menu>
          </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
