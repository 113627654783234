import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import AddOnImage from './AddOnImage';
import SimpleList  from '../../components/commons/SimpleList';
import { selectAllItems, fetchItems } from '../item/itemSlice';
import {
  patchUpdateApprovalOptionItem, updateItemOptionName, updateItemOptionPrice, updateItemOptionDescription, patchUpdateDefaultOptionItem, removeOptionItemFromOption, patchRemoveItemsToOption, postItemsInAddonOrder,
  patchAddExistingOptionItem, fetchAddOnOptionItems,
  selectAllItemOptions, patchAddItemsToOption, fetchAddOnImages,
  selectAllAddOnImages, selectAllAddOns, patchItem, postItem, patchBulkAddItemsToOption,
  postItemForm, removeOptionItem, removeOption, selectPutItemStatus, patchItemOptionActive
} from './addOnSlice';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import { green, pink } from '@material-ui/core/colors';
import Avatar from '@material-ui/core/Avatar';
import FolderIcon from '@material-ui/icons/Folder';
import PageviewIcon from '@material-ui/icons/Pageview';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ClickImage from '../../components/previews/ClickImage';
import FlavoredTea from '../../assets/FlavoredTea.png';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Badge from '@material-ui/core/Badge';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import Checkbox from '@material-ui/core/Checkbox';
import { DropzoneArea } from 'material-ui-dropzone'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import EditIcon from '@material-ui/icons/Edit';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {io} from "socket.io-client";

const DEFAULT_SOCKETS_URL = 'https://sockets.bigkiosksolution.com';

const ITEM_IMG_WIDTH = '40px';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  accordionRoot: {
    width: 800,
    [theme.breakpoints.down('md')]: {
      width: 660
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
  body: {
    paddingTop: 40,
    touchAction: "pan-x"
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
  },
  label: {
    marginTop: -25,
    paddingTop: -1000,
  },
  footer: {
    paddingTop: 5,
    paddingBottom: 3
  },
  paper: {
    backgroundColor: '#fafafa',
    width: '100%',
    padding: 20,
    marginTop:-10,
    paddingTop: 5,
    paddingBottom:0,
    height: 265,
    textAlign: 'center'
  },
  paper2: {
    backgroundColor: '#fafafa',
    width: '100%',
    padding: 20,
    marginTop:-10,
    paddingTop: 5,
    height: 265,
    textAlign: 'center',
    overflowX: 'hidden',
    overflowY: 'scroll'
  },
  paperItem: {
    backgroundColor: '#fafafa',
    width: '100%',
    padding: 20,
    marginTop:-10,
    paddingTop: 5,
    paddingBottom:0,
    height: 100,
    textAlign: 'center'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
}));

const ItemOption = (props) => {
  const socketRef = useRef();

  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const status = useSelector(state => state.addon.status);

  const [open2, setOpen] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDescriptionClose = () => {
    setOpen(false)
  }

  const handlePriceClose = () => {
    setOpen3(false)
  }

  const handleNameClose = () => {
    setOpen4(false)
  }

  const handleUpdateDescription = (e) => {
    e.preventDefault();
    dispatch(updateItemOptionDescription({id: props.item.id, optionId: props.optionId, description: e.target.description.value}))
    handleDescriptionClose();
  }

  const handleUpdatePrice = (e) => {
    e.preventDefault();
    dispatch(updateItemOptionPrice({id: props.item.id, optionId: props.optionId, price: e.target.price.value}))
    handlePriceClose();
  }

  const handleUpdateName = (e) => {
    e.preventDefault();
    dispatch(updateItemOptionName({id: props.item.id, optionId: props.optionId, name: e.target.name.value}))
    handleNameClose();
  }


  const handleClick = (event) => {
   setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
   setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const defaultId = props.default && typeof props.default === 'object' ? props.default.id : props.default;
  const isDefault = props.default && defaultId === props.item.id ? true : false
  const classes = props.classes;
  const item = props.item;
  let media = null;

  if (item.item_option_image) {
    media = <CardMedia
             className={classes.media}
             image={'https://api.bigkiosksolution.com' + item.item_option_image}
             title={item.name}
            />
  }
  const obj = {
    itemOptionId: item.id,
    optionId: props.optionId
  };

  const handleItemOptionStatus = (status) => {
    const shopId = localStorage.getItem('shop_id')
    if (socketRef.current == null) {
      socketRef.current = io(DEFAULT_SOCKETS_URL, {
        transports: ["websocket"] // use WebSocket first, if available
      });
    }

    const {current: socket} = socketRef;

    socket.emit('item-option-status-update-from-dashboard', {active: status, items: props.items, item_id: props.item.id, id: shopId});
    console.log('emit')
    dispatch(patchItemOptionActive({optionId: props.optionId, id: props.item.id, active: status}))
  }

  return (
    <React.Fragment>
      <Grid item md={4} style={{marginBottom: 12}}>
      <Card>
           <CardHeader
             action={
              user?.shop?.type != 'franchisee' || user?.shop?.delete_addon_allowed ? (
                <IconButton onClick={handleClick} aria-label="settings">
                  <MoreVertIcon />
                </IconButton>
              ) : (null)
             }
             title={
               <div>
                <small style={{fontSize: '0.4em', marginRight: 6, position: 'relative', bottom: 8}}>{isDefault ? 'DEFAULT' : null}</small>
                  <span>{item.name}
                    <IconButton onClick={() => setOpen4(true)} size="small" >
                      <EditIcon style={{fontSize: 20}} />
                    </IconButton>
                  </span>
               </div>}
           />
           {media}
           <CardContent style={{paddingTop:0, paddingBottom: 0}}>
           {item.description ? item.description : 'No description added'}
           <IconButton onClick={() => setOpen(true)} size="small" >
            <EditIcon style={{fontSize: 20}} />
           </IconButton>
           </CardContent>
           <CardContent style={{marginTop:0}}>
             <Typography variant="body2" color="textSecondary" component="p">
               ${item.unit_price}
               <IconButton onClick={() => setOpen3(true)} size="small" >
                 <EditIcon style={{fontSize: 20}} />
                </IconButton>
                {item.active ? ' ACTIVE' : 'INACTIVE'}
                {item.approval_needed ? ' | APPROVAL' : null}
             </Typography>
             {item.is_number_option
               ?
                 <Typography variant="body2" color="textPrimary" style={{fontSize: 11}} component="small">
                   INCREMENTAL OPTION
                 </Typography>
               :
               null
             }
           </CardContent>


         </Card>
      </Grid>
      <Popover
         id={id}
         open={open}
         anchorEl={anchorEl}
         onClose={handleClose}
         anchorOrigin={{
           vertical: 'bottom',
           horizontal: 'center',
         }}
         transformOrigin={{
           vertical: 'top',
           horizontal: 'center',
         }}
       >
       <List>

         {props.item.active ? (
             <ListItem onClick={() => handleItemOptionStatus(false)}  button>
               <ListItemText primary="INACTIVE" />
             </ListItem>
         ) : (
             <ListItem onClick={() => handleItemOptionStatus(true)}  button>
               <ListItemText primary="ACTIVE" />
             </ListItem>
         )}
          {!props.item.approval_needed ? (
             <ListItem onClick={() => dispatch(patchUpdateApprovalOptionItem({optionId: props.optionId, optionItemId: item.id, approval_needed: true}))}  button>
               <ListItemText primary="APPROVAL REQUIRED" />
             </ListItem>
         ) : (
             <ListItem onClick={() => dispatch(patchUpdateApprovalOptionItem({optionId: props.optionId, optionItemId: item.id, approval_needed: false}))}   button>
               <ListItemText primary="APPROVAL NOT REQUIRED" />
             </ListItem>
         )}
        {!isDefault ?
          <ListItem button onClick={() => dispatch(patchUpdateDefaultOptionItem({clear: false, optionId: props.optionId, default_option_item_id: item.id}))}>
            <ListItemText primary={"SET AS DEFAULT"} />
          </ListItem>
          :
          <ListItem button onClick={() => dispatch(patchUpdateDefaultOptionItem({clear: true, optionId: props.optionId, default_option_item_id: item.id}))}>
            <ListItemText primary={"CLEAR DEFAULT"} />
          </ListItem>}
       <ListItem onClick={() => dispatch(removeOptionItemFromOption(obj))}  button>
         <ListItemText primary="DELETE" />
       </ListItem>
     </List>
     </Popover>
     <Dialog width="lg" open={open2} onClose={handleDescriptionClose} aria-labelledby="form-dialog-title">
     <form onSubmit={handleUpdateDescription}  autoComplete="off">
      <DialogTitle id="form-dialog-title">Update Description</DialogTitle>
      <DialogContent>
         <FormControl fullWidth variant="outlined">
           <InputLabel htmlFor="outlined-adornment-amount">Description</InputLabel>
           <OutlinedInput
             name="description"
             type="text"
             startAdornment={<InputAdornment position="start"></InputAdornment>}
             labelWidth={80}
           />
       </FormControl>

      </DialogContent>
      <DialogActions>
       <Button onClick={handleDescriptionClose} color="primary">
         Cancel
       </Button>
       <Button disabled={status === 'item option description loading' ? true : false} type="submit" color="primary">
         UPDATE
       </Button>
      </DialogActions>
      </form>

      </Dialog>
      <Dialog width="lg" open={open3} onClose={handlePriceClose} aria-labelledby="form-dialog-title">
        <form onSubmit={handleUpdatePrice}  autoComplete="off">
         <DialogTitle id="form-dialog-title">Update Price</DialogTitle>
         <DialogContent>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-amount">Price</InputLabel>
              <OutlinedInput
                name="price"
                type="text"
                startAdornment={<InputAdornment position="start"></InputAdornment>}
                labelWidth={37}
              />
          </FormControl>

         </DialogContent>
         <DialogActions>
          <Button onClick={handlePriceClose} color="primary">
            Cancel
          </Button>
          <Button disabled={status === 'item option price loading' ? true : false} type="submit" color="primary">
            UPDATE
          </Button>
         </DialogActions>
         </form>
       </Dialog>

       <Dialog width="lg" open={open4} onClose={handleNameClose} aria-labelledby="form-dialog-title">
         <form onSubmit={handleUpdateName}  autoComplete="off">
          <DialogTitle id="form-dialog-title">Update Name</DialogTitle>
          <DialogContent>
             <FormControl fullWidth variant="outlined">
               <InputLabel htmlFor="outlined-adornment-amount">Name</InputLabel>
               <OutlinedInput
                 name="name"
                 type="text"
                 startAdornment={<InputAdornment position="start"></InputAdornment>}
                 labelWidth={44}
               />
           </FormControl>

          </DialogContent>
          <DialogActions>
           <Button onClick={handleNameClose} color="primary">
             Cancel
           </Button>
           <Button disabled={status === 'item option name loading' ? true : false} type="submit" color="primary">
             UPDATE
           </Button>
          </DialogActions>
          </form>
        </Dialog>
    </React.Fragment>
  );
}

/* HEADER (PART) */
const Header = (props) => {
  const classes = props.classes;

  const handleChange = () => {
    props.setExpanded(true);
  }

  const handleCollapse = () => {
    props.setExpanded(false);
  }

  const handleNameChange = (e) => {
    props.onItemNameUpdate(e.target.value);
  }

  return (
    <React.Fragment>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon onClick={handleCollapse}/>}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <InputBase
        fullWidth
        onClick={handleChange}
        onChange={handleNameChange}
        className={classes.heading}
        color="primary"
        defaultValue={props.itemName}
      />
      </AccordionSummary>
    </React.Fragment>
  );
}

/* BODY (PART) */
const Body = (props) => {
  const allItems = useSelector(selectAllItems);
  const addOnOptionItems = useSelector(selectAllItemOptions);
  const [files, setFiles] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [existingOpen, setExistingOpen] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [checkedIsNumberOption, setCheckedIsNumberOption] = React.useState(false);
  const [dndList, setDndList] = useState([...props.items.map(item => item.id)]);
  const classes = props.classes;
  const dispatch = useDispatch();
  const status = useSelector(state => state.addon.status);
  const itemStatus = useSelector(state => state.item.status);
  const user = useSelector(state => state.auth.user);
  const itemOptions = props.item_options;
  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };

  const toggleCheckedIsNumberOption = () => {
    setCheckedIsNumberOption((prev) => !prev);
  };

  const handleClickOpen = () => {
   setOpen(true);
  };
  const handleClose = () => {
   setOpen(false);
  };

  const handleClickExistingOpen = () => {
   setExistingOpen(true);
   dispatch(fetchAddOnOptionItems());
  };

  const handleClickEixstingClose = () => {
   setExistingOpen(false);
  };

  const handleSimpleListItemOrder = (list) => {
    dispatch(postItemsInAddonOrder({items: list, optionId: props.id}));
  }

  const handleSwitch = () => {
    props.active ? props.onActive(false) : props.onActive(true);
  }
  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchAddOnImages()); // populates state.category.categories
      dispatch(fetchItems()); // populates state.category.categories
    }
  }, [status, dispatch]) // if either postStatus or dispatch changes, fire!

  useEffect(() => {
    if (props.groups) {
      setDndList([...props.groups.map(group => group.item)])

    } else {
      setDndList([...props.items.map(item => item.id)])

    }

      dispatch(fetchItems());

  }, [props.items])

  useEffect(() => {
    dispatch(fetchItems()); // populates state.category.categories
  }, [])

  const handleAddItemOption = (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const calories = e.target.calories.value;
    const unit_price = e.target.unit_price.value;
    const description = e.target.description.value;
    const inc_name = checkedIsNumberOption ? e.target.incremental_name.value : null;
    const incremental = e.target.incremental.value;

    let formData = new FormData();

    formData.append('name', name);
    formData.append('description', description);

    if (files.length > 0) {
      formData.append('item_option_image', files[0]);
    }

    formData.append('options', [props.id]);
    formData.append('unit_price', unit_price);
    formData.append('calories', calories);
    formData.append('is_number_option', checkedIsNumberOption);
    if (inc_name) {
      formData.append('incremental_name', inc_name);
    }
    const obj = {
      formData: formData,
      optionId: props.id
    }
    dispatch(postItemForm(obj));
    setOpen(false);
  };

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchAddOnImages()); // populates state.category.categories
    } else if (itemStatus === 'idle') {
      dispatch(fetchItems()); // populates state.category.categories
    }
  }, [status, dispatch]) // if either postStatus or dispatch changes, fire!

  let itemOptionsDisp = null;

  if (itemOptions.length > 0) {
    itemOptionsDisp = itemOptions.map(item => <ItemOption items={props.items} default={props.default_item_option} optionId={props.id} key={item.id} item={item} classes={classes} />);
  }

  let selectedImgUrl = '';
  itemOptions.map((image) => {
    if (image.id === props.item_image) {
      selectedImgUrl = image.item_image;
    }
  }, props.item_image, selectedImgUrl);

  const multiNumInput = props.multiSelection === true && props.upToSelection === true ?
              <FormControl style={{marginTop: 50, width: 73}}>
                <TextField
                  onChange={(e) => props.onUpToUpdate(e.target.value)}
                  id="outlined-helperText"
                  label="Up to"
                  defaultValue="1"
                  value={props.upTo}
                  variant="outlined"
                  type="number"
                  margin="dense"
                  min="0" step="1"
                  pattern="\d*"
                  />
               </FormControl>
               :
               null

  const multiMustNumInput = props.multiSelection === true && props.upToSelection === false ?
              <FormControl style={{marginTop: 50, width: 86}}>
                <TextField
                  onChange={(e) => props.onUpToUpdate(e.target.value)}
                  id="outlined-helperText"
                  label="Must select"
                  defaultValue="1"
                  value={props.upTo}
                  variant="outlined"
                  type="number"
                  margin="dense"
                  min="0" step="1"
                  pattern="\d*"
                  />
              </FormControl>
              :
              null

  return (
    <React.Fragment>
    <Divider />
      <AccordionDetails className={classes.body}>
      <Grid container
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <FormControlLabel className={classes.label}
            control={
                <Switch
                checked={props.active}
                onClick={handleSwitch}
                name="checkedB"
                color="primary"/>
              }
              label="Active"/>
              <Grid item>
              {user?.shop?.type != 'franchisee' || user?.shop?.add_addon_allowed ? (
                <React.Fragment>
                <Button onClick={handleClickExistingOpen} style={{position: 'realtive', bottom: 12, marginRight: 7}} size="small" variant="outlined" color="primary">
                  ADD EXISTING
                </Button>
                <Button onClick={handleClickOpen} style={{position: 'realtive', bottom: 12}} size="small" variant="outlined" color="primary">
                  ADD NEW
                </Button>
                </React.Fragment>

              ) : (null)}
              </Grid>
              <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
              <form onSubmit={handleAddItemOption}  autoComplete="off">
        <DialogTitle id="form-dialog-title">Add Add-on Item</DialogTitle>
        <DialogContent>
        <FormControl fullWidth>
        <FormControlLabel
            style={{marginTop: -10, marginBottom:0}}
            control={<Switch name="incremental" checked={checkedIsNumberOption} onChange={toggleCheckedIsNumberOption} />}
            label={checkedIsNumberOption ? 'Incremental' : 'Regular'}
          />
          </FormControl>
          {checkedIsNumberOption ? <FormControl>
            <TextField margin="dense" size="small" id="filled-basic" label="Incremental Name" name="incremental_name" variant="outlined" required />
          </FormControl> : null}
          <FormControl style={{marginBottom: 20, marginTop: 20}} required fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">Name</InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              name="name"
              type="text"
              startAdornment={<InputAdornment position="start"></InputAdornment>}
              labelWidth={60}
            />
          </FormControl>
          <FormControl style={{marginBottom: 20}} required fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
            <OutlinedInput
              type="number"
              inputProps={{ min: "0", step: "0.01" }}
              id="outlined-adornment-amount"
              name="unit_price"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              labelWidth={60}
            />
            </FormControl>

            <FormControl style={{marginBottom: 20}} fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-amount">Description</InputLabel>
              <OutlinedInput
                name="description"
                type="text"
                startAdornment={<InputAdornment position="start"></InputAdornment>}
                labelWidth={80}
              />
          </FormControl>
          <FormControl style={{marginBottom: 20}} required fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">Calories</InputLabel>
            <OutlinedInput
              name="calories"
              type="number"
              startAdornment={<InputAdornment position="start"></InputAdornment>}
              labelWidth={80}
            />
        </FormControl>
          {checkedIsNumberOption ? null : <DropzoneArea
            dropzoneText={"Drag and drop an image here or click"}
            filesLimit={1}
            onChange={(files) => setFiles(files)}
          />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Add
          </Button>
        </DialogActions>
        </form>

      </Dialog>

      <Dialog open={existingOpen} onClose={handleClickEixstingClose} aria-labelledby="form-dialog-title">
      <form onSubmit={handleAddItemOption} noValidate autoComplete="off">
<DialogTitle id="form-dialog-title">Add Existing Add-on Item</DialogTitle>
<DialogContent style={{marginTop: -20}}>
  <List dense={true} fullWidth>
    {addOnOptionItems.map(item => <ListItem key={item.id} divider disableGutters>
      <ListItemText
        primary={<span>{item.name} <IconButton onClick={() => dispatch(removeOptionItem({item: item, optionId: props.id}))} size="small" edge="start"><DeleteForeverIcon /></IconButton></span>}
        secondary={'$' + item.unit_price + ' | ' + item.calories + ' Cal.'}
      />
      <ListItemSecondaryAction onClick={() => dispatch(patchAddExistingOptionItem({itemOptionid: item.id, optionId: props.id}))}>
        <IconButton style={{position: 'relative', left: 17}} color="primary" edge="end" aria-label="delete">
          <AddIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>)}
  </List>
</DialogContent>
</form>

</Dialog>
      </Grid>
      </AccordionDetails>

      <AccordionDetails style={{marginBottom: -15}}>
          <Grid container
                spacing={2}
                direction="row">
                {itemOptionsDisp}
          </Grid>
      </AccordionDetails>
      <AccordionDetails style={{marginTop: 10}}>
      <FormControl component="fieldset" style={{marginRight: 20}}>
            <FormLabel component="label">Requires Selection</FormLabel>
            <RadioGroup defaultValue={props.required?.toString().toLowerCase()} name="required-selection">
              <FormControlLabel onClick={() => props.onRequiredUpdate(true)} value="true" control={<StyledRadio />} label="Required" />
              <FormControlLabel onClick={() => props.onRequiredUpdate(false)} value="false" control={<StyledRadio />} label="Non-Required" />
            </RadioGroup>
      </FormControl>
      <FormControl component="fieldset">
            <FormLabel component="label">Selection Rule</FormLabel>
            <RadioGroup defaultValue={props.multiSelection?.toString().toLowerCase() + props.upToSelection?.toString().toLowerCase()} name="multi-selection">
              <FormControlLabel onClick={() => {props.onMultipleSelectionUpdate(false); props.onMultipleSelectionMustUpdate(true);}} value={props.upToSelection ? 'falsetrue' : 'falsefalse'} control={<StyledRadio />} label="Single Selection" />
              <FormControlLabel onClick={() => {props.onMultipleSelectionUpdate(true); props.onMultipleSelectionMustUpdate(true);}} value={"truetrue"} control={<StyledRadio />} label="Multi Selection - Up To " />
              <FormControlLabel onClick={() => {props.onMultipleSelectionUpdate(true); props.onMultipleSelectionMustUpdate(false);}} value={"truefalse"} control={<StyledRadio />} label="Multi Selection - Must Select" />
            </RadioGroup>
          </FormControl>
          {multiNumInput}
          {multiMustNumInput}
      </AccordionDetails>
      <AccordionDetails>
        <SimpleList
          onOrderUpdate={(list) => handleSimpleListItemOrder(list)}
          title={'Item(s) containing ' + props.name}
          onItemRemove={(selectedItems, existingItems) => dispatch(patchRemoveItemsToOption({existingItems: existingItems, removedItems: selectedItems, optionId: props.id}))}
          onItemAdd={(items, id) => dispatch(patchAddItemsToOption({existingItems: items, itemId: id, optionId: props.id}))}
          onItemBulkAdd={(items, ids) => dispatch(patchBulkAddItemsToOption({existingItems: items, items: ids, optionId: props.id}))}
          unselectedItems={allItems}
          name={props.name}
          items={props.items}
          type="items2" />
      </AccordionDetails>
    </React.Fragment>
  );
}

function StyledRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      {...props}
    />
  );
}

/* FOOTER (PART) */
const Footer = (props) => {
  const classes = props.classes;
  const dispatch = useDispatch();
  const loading = useSelector(selectPutItemStatus);
  const user = useSelector(state => state.auth.user);

  return (
    <React.Fragment>
      <Divider />
      <AccordionDetails className={classes.footer}>
        <Grid   container
                direction="row"
                justifyContent="space-between"
                alignItems="center">
                {user?.shop?.type != 'franchisee' ||  user?.shop?.delete_addon_allowed ? (
                  <Button onClick={() => dispatch(removeOption(props.id))} color="secondary">DELETE</Button>
                ) : (
                    null
                )}
                {user?.shop?.type != 'franchisee' || user?.shop?.edit_addon_allowed ? (
                  <Button onClick={() => dispatch(patchItem(props.item))}>SAVE</Button>
                ) : (
                    null
                )}
        </Grid>
      </AccordionDetails>
    </React.Fragment>
  );
}

const AddOnItem = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [active, setActive] = useState(props.active);
  const [itemName, setItemName] = useState(props.name);
  const [required, setRequired] = useState(props.required);
  const [multiSelection, setMultiSelection] = useState(props.is_multiple_selection);
  const [upToSelection, setUpToSelection] = useState(props.up_to);
  const [upTo, setUpTo] = useState(props.multiple_max_number);

  const handleExpansion = (e) => {
    if (!expanded) {
      setExpanded(true);
    }
  };

  useEffect(() => {
    setActive(props.active)
  }, [props.active])

  useEffect(() => {
    setItemName(props.name)
  }, [props.name])

  useEffect(() => {
    setRequired(props.required)
  }, [props.required])

  useEffect(() => {
    setUpTo(props.multiple_max_number)
  }, [props.multiple_max_number])

  useEffect(() => {
    setMultiSelection(props.is_multiple_selection)
  }, [props.is_multiple_selection])

  useEffect(() => {
    setUpToSelection(props.up_to)
  }, [props.up_to])

  const item = {
    itemId: props.id,
    name: itemName,
    active: active,
    required: required,
    upTo: upTo,
    multiSelection: multiSelection,
    upToSelection: upToSelection,
  };

  return (
    <Accordion expanded={expanded}
               onClick={handleExpansion}
               className={classes.accordionRoot}>
      <Header onItemNameUpdate={setItemName}
              itemName={itemName}
              setExpanded={setExpanded}
              classes={classes}
              {...props} />
      {expanded ?
        <Body {...props}
            upToSelection={upToSelection}
            upTo={upTo}
            required={required}
            multiSelection={multiSelection}
            onUpToUpdate={setUpTo}
            onRequiredUpdate={setRequired}
            onMultipleSelectionUpdate={selection => setMultiSelection(selection)}
            onMultipleSelectionMustUpdate={selection => setUpToSelection(selection)}
            itemActive={active}
            itemName={itemName}
            onActive={setActive}
            active={active}
            classes={classes} /> : null}
      <Footer item={item}
              itemActive={active}
              classes={classes}
              itemName={itemName}
              {...props} />
    </Accordion>
  );
}

export default AddOnItem;
