import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET, DELETE, POST, PUT, PATCH, POST_FILE } from '../../api/DashboardAPI';

export const overrideCategory = createAsyncThunk('category/overrideCategory', async (obj) => {
  const response = await POST('/dashboard/category/override_category/', obj);
  return response.data;

});

export const postCategoryImage = createAsyncThunk('category-image/postCategoryImage', async (obj) => {
  const response = await POST_FILE('/dashboard/category-image/', obj);
  return response.data;

});

export const postUpdateCategoryImage = createAsyncThunk('category/postUpdateCategoryImage', async (obj) => {
  const response = await POST('/dashboard/category/' + obj.categoryId + '/update_category_image/', {categoryImageId: obj.targetValue});
  return obj;
});

export const patchRemoveItemsToCategory = createAsyncThunk('addon/patchRemoveItemsToCategory', async (obj) => {
  const optionId = obj.optionId;
  let items = obj.existingItems.slice();
  obj.removedItems.map(id => {
    const index = items.findIndex(item => item === id);
    items.splice(index, 1);
  });
  const response = await POST('/dashboard/category/' + obj.categoryId + '/remove_item_from_category/', {removedItems: obj.removedItems});
  return {id: obj.categoryId, removedItems: obj.removedItems, items: items};
});

export const patchAddItemsToCategory = createAsyncThunk('addon/patchAddItemsToCategory', async (obj) => {
  let existingItems = [];
  obj.existingItems.map(item => existingItems.push(item));
  existingItems.push(obj.addedItem);
  const response = await POST('/dashboard/category/' + obj.categoryId + '/add_item_to_category/', {itemId: obj.itemId});
  return {addedItemId: response.data['added_item_id'], groupItem: response.data['group_item'], id: obj.categoryId};
});

export const postItemsInCategoryOrder = createAsyncThunk('category/postItemsInCategoryOrder', async (obj) => {
  const response = await POST('/dashboard/category/' + obj.categoryId + '/update_items_in_category_order/', {items: obj.items});
  return {categoryId: obj.categoryId, groups: response.data.groups};
});

export const postCategoryItemsOrder = createAsyncThunk('addon/postCategoryItemsOrder', async (items) => {
  const response = await POST('/dashboard/category/update_category_items_order/', {categories: items});
  return response.data;
});

export const postCategoryImagePosition = createAsyncThunk('category-image/postCategoryImagePosition', async (obj) => {
  const response = await POST('/dashboard/category-image/' + obj.id + '/update_category_image_position/', {categoryId: obj.categoryId, x: obj.x, y: obj.y});
  return response.data;
});

export const fetchCategories = createAsyncThunk('category/fetchCategories', async () => {
  //const response = await GET('/dashboard/category/');
  const response = await GET('/dashboard/category/get_categories_dashboard/');
  return response.data; // payload
});

export const fetchCategoriesSimplified = createAsyncThunk('category/fetchCategoriesSimplified', async () => {
  //const response = await GET('/dashboard/category/');
  const response = await GET('/dashboard/category/get_categories_simplified/');
  return response.data; // payload
});

export const removeCategory = createAsyncThunk('category/removeCategory', async (id) => {
  const response = await DELETE('/dashboard/category/' + id + '/');
  return id; // payload
});

export const removeCategoryImage = createAsyncThunk('category/removeCategoryImage', async (id) => {
  const response = await DELETE('/dashboard/category-image/' + id + '/');
  return id; // payload
});

export const postCategory = createAsyncThunk('category/postCategory', async (name) => {
  const response = await POST('/dashboard/category/', {name: name});
  console.log(response);
  return response.data; // payload
});

export const putCategory = createAsyncThunk('category/putCategory', async (obj) => {
  const response = await PUT('/dashboard/category/' + obj.categoryId + '/', {active: obj.active, name: obj.name, category_image_id: obj.targetValue});
  const returnObj = {
    targetValue: obj.targetValue,
    categoryId: obj.categoryId
  };
  return returnObj; // payload
});

export const patchCategory = createAsyncThunk('category/patchCategory', async (obj) => {
  const response = await PATCH('/dashboard/category/' + obj.categoryId + '/', {rounded: obj.rounded, active: obj.active, name: obj.name, category_image_id: obj.targetValue});
  const returnObj = {
    targetValue: obj.targetValue,
    categoryId: obj.categoryId
  };
  return returnObj; // payload
});

export const fetchCategoryImages = createAsyncThunk('category/fetchCategoryImages', async () => {
  const response = await GET('/dashboard/category-image/');
  return response.data.results; // payload
});

export const patchBulkAddItemsToCategory = createAsyncThunk('addon/patchBulkAddItemsToCategory', async (obj) => {
    const response = await POST('/dashboard/category/' + obj.categoryId + '/bulk_add_items_to_category/', {items: obj.items});  // Send all items
    return {
      addedItemIds: response.data['added_item_ids'],
      groupItems: response.data['group_items'],
      id: obj.categoryId
    };
  }
);


export const fetchCategoryReport = createAsyncThunk('item/fetchCategoryReport', async (payload) => {
  const response = await POST('/dashboard/category/get_category_report/', payload);
  return {data: response.data.items, total: response.data.total, itemCount: response.data.total_quantity_sum, id: payload.id} // payload
});



export const fetchCategoryReportCustom = createAsyncThunk('item/fetchCategoryReportCustom', async (payload) => {
  const response = await POST('/dashboard/category/get_category_report_custom/?start_date=' + payload.start_date + '&end_date=' + payload.end_date, payload);
  return {data: response.data.items, total: response.data.total, itemCount: response.data.total_quantity_sum, id: payload.id} // payload
});




export const categorySlice = createSlice({
  name: 'category',
  initialState: {
    categories: [],
    categoriesSimplified: [],
    status: 'idle',
    error: null,
    categoryImagesStatus: 'idle',
    categoryImages: [],
    putCategoryStatus: 'idle'
  },
  reducers: {
    addCategory: (state, action) => {
      const category = { name: action.payload };
      state.categories.push(category);
    },

    updateSelectImage: (state, action) => {
      const obj = action.payload;
      // get the category id and target val
      const categoryId = obj.categoryId;
      const targetValue = obj.targetValue;
      // find category elem from categories array
      const index = state.categories.findIndex(category => category.id === categoryId);
      // update category_image field in a category elem with a target value
      state.categories[index].category_image = targetValue;
    },

    updateStatus: (state, action) => {
      const status = action.payload;
      state.status = status;
    }
  },
  extraReducers: {

    [fetchCategories.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchCategories.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // Add any fetched posts to the array
      state.categories = action.payload;
    },
    [fetchCategories.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
    [fetchCategoriesSimplified.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchCategoriesSimplified.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      state.categoriesSimplified = action.payload;
      state.status = 'succeeded'

    },
    [fetchCategoriesSimplified.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
  [postCategoryItemsOrder.pending]: (state, action) => {
    state.status = 'loading'
  },
  [postCategoryItemsOrder.fulfilled]: (state, action) => {
    state.status = 'succeeded'
    // Add any fetched posts to the array
    //state.categories = action.payload;
  },
  [postCategoryItemsOrder.rejected]: (state, action) => {
    state.status = 'failed'
    state.error = action.error.message
  },

  [removeCategoryImage.pending]: (state, action) => {
    state.status = 'deleting'
  },
  [removeCategoryImage.fulfilled]: (state, action) => {
    state.status = 'deleted'
    const id = action.payload;
    const index = state.categoryImages.findIndex(image => image.id === id);
    state.categoryImages.splice(index, 1);
  },
  [removeCategoryImage.rejected]: (state, action) => {
    state.status = 'failed'
    state.error = action.error.message
  },


    [removeCategory.pending]: (state, action) => {
      state.status = 'deleting'
    },
    [removeCategory.fulfilled]: (state, action) => {
      state.status = 'deleted'
      //state.categories = state.categories.filter((category, idx) => idx !== action.payload);
      const index = state.categories.findIndex(category => category.id === action.payload);
      state.categories.splice(index, 1);
    },
    [removeCategory.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
    [postCategory.pending]: (state, action) => {
      state.status = 'loading'
    },
    [postCategory.fulfilled]: (state, action) => {
      state.status = 'added'
      state.categories = state.categories.concat(action.payload);
      // Add any fetched posts to the array
    },
    [postCategory.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
    [fetchCategoryImages.pending]: (state, action) => {
      state.categoryImagesStatus = 'loading'
    },
    [fetchCategoryImages.fulfilled]: (state, action) => {
      state.categoryImagesStatus = 'succeeded'
      // Add any fetched posts to the array
      state.categoryImages = action.payload;
    },
    [fetchCategoryImages.rejected]: (state, action) => {
      state.categoryImagesStatus = 'failed'
      state.error = action.error.message;
    },
    [postUpdateCategoryImage.fulfilled]: (state, action) => {
      const imageId = action.payload.targetValue;
      const categoryId = action.payload.categoryId;

      const index = state.categories.findIndex(category => category.id === categoryId);
      state.categories[index].category_image = imageId;
      state.status = 'updated'
    },
    [postUpdateCategoryImage.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [postUpdateCategoryImage.pending]: (state, action) => {
      state.status = 'loading'
    },
    [putCategory.pending]: (state, action) => {
      state.putCategoryStatus = 'loading'
    },
    [putCategory.fulfilled]: (state, action) => {
      state.putCategoryStatus = 'updated'
      const obj = action.payload;

      // get the category id and target val
      const categoryId = obj.categoryId;
      const targetValue = obj.targetValue;
      // find category elem from categories array
      const index = state.categories.findIndex(category => category.id === categoryId);
      // update category_image field in a category elem with a target value
      state.categories[index].category_image = targetValue;
    },
    [putCategory.rejected]: (state, action) => {
      state.putCategoryStatus = 'failed'
      state.error = action.error.message;
    },

    [patchCategory.pending]: (state, action) => {
      state.putCategoryStatus = 'loading'
    },
    [patchCategory.fulfilled]: (state, action) => {
      const obj = action.payload;

      // get the category id and target val
      const categoryId = obj.categoryId;
      const targetValue = obj.targetValue;
      // find category elem from categories array
      const index = state.categories.findIndex(category => category.id === categoryId);
      // update category_image field in a category elem with a target value
      state.categories[index].category_image = targetValue;
      state.putCategoryStatus = 'updated'

    },
    [patchCategory.rejected]: (state, action) => {
      state.putCategoryStatus = 'failed'
      state.error = action.error.message;
    },
    [patchAddItemsToCategory.pending]: (state, action) => {

      state.status = 'loading'
    },
    [patchAddItemsToCategory.fulfilled]: (state, action) => {
      state.status = 'added'
      const index = state.categories.findIndex(item => item.id === action.payload.id);
      state.categories[index].items.push(action.payload.addedItemId);
      state.categories[index].groups.push(action.payload.groupItem);

    },
    [patchAddItemsToCategory.rejected]: (state, action) => {

      state.status = 'failed'
    },

    [patchBulkAddItemsToCategory.pending]: (state, action) => {

      state.status = 'loading'
    },
    [patchBulkAddItemsToCategory.fulfilled]: (state, action) => {
      const { addedItemIds, groupItems, id } = action.payload;
      const categoryIndex = state.categories.findIndex(category => category.id === id);

      if (categoryIndex !== -1) {
        addedItemIds.forEach((addedItemId, index) => {
          state.categories[categoryIndex].items.push(addedItemId);
          state.categories[categoryIndex].groups.push(groupItems[index]);
        });
      }

      state.status = 'succeeded';
    },
    [patchBulkAddItemsToCategory.rejected]: (state, action) => {

      state.status = 'failed'
    },


    [postCategoryItemsOrder.pending]: (state, action) => {

      state.status = 'loading'
    },
    [postCategoryItemsOrder.fulfilled]: (state, action) => {
      state.status = 'ordered'
    },
    [postCategoryItemsOrder.rejected]: (state, action) => {

      state.status = 'failed'
    },

    [patchRemoveItemsToCategory.pending]: (state, action) => {

      state.status = 'loading'
    },
    [patchRemoveItemsToCategory.fulfilled]: (state, action) => {
      state.status = 'deleted'
      const index = state.categories.findIndex(category => category.id === action.payload.id);
      const groups = state.categories[index].groups;
      state.categories[index].items = action.payload.items;

      action.payload.removedItems.map(itemId => {
        const groupIndex = groups.findIndex(group => group.item.id === itemId)
        if (groupIndex != -1) {
          state.categories[index].groups.splice(groupIndex, 1);
        }
      })

    },
    [patchRemoveItemsToCategory.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [postItemsInCategoryOrder.fulfilled]: (state, action) => {
      state.status = 'ordered'
      const index = state.categories.findIndex(category => category.id === action.payload.categoryId);

      state.categories[index].groups = action.payload.groups;
      // state.categories[index].groups = action.payload.groups;
    },
    [postItemsInCategoryOrder.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [postItemsInCategoryOrder.pending]: (state, action) => {
      state.status = 'loading'
    },

    [postCategoryImage.pending]: (state, action) => {
      state.status = 'loading'
    },
    [postCategoryImage.fulfilled]: (state, action) => {
      state.status = 'added'
      state.categoryImages.push(action.payload);
    },
    [postCategoryImage.rejected]: (state, action) => {
      state.status = 'failed'

    },

    [overrideCategory.pending]: (state, action) => {
      state.status = 'loading'
    },
    [overrideCategory.fulfilled]: (state, action) => {
      state.status = 'overidden'
    },
    [overrideCategory.rejected]: (state, action) => {
      state.status = 'failed'
    },



    [fetchCategoryReport.pending]: (state, action) => {
      state.status = 'fetch category loading'
    },
    [fetchCategoryReport.fulfilled]: (state, action) => {
      const data = action.payload.data;
      const itemCount = action.payload.itemCount;
      const total = action.payload.total;

      const id = action.payload.id;

      const index = state.categoriesSimplified.findIndex(item => item.id === id);
      if (index != -1) {
        state.categoriesSimplified[index].soldItems = data
        state.categoriesSimplified[index].total = total
        state.categoriesSimplified[index].item_count = itemCount

      }

      state.status = 'fetch category succeeded'
      // Add any fetched posts to the array
    },
    [fetchCategoryReport.rejected]: (state, action) => {
      state.status = 'fetch category failed'
      state.error = action.error.message
    },


    [fetchCategoryReportCustom.pending]: (state, action) => {
      state.status = 'fetch category loading'
    },
    [fetchCategoryReportCustom.fulfilled]: (state, action) => {
      const data = action.payload.data;
      const itemCount = action.payload.itemCount;
      const total = action.payload.total;

      const id = action.payload.id;

      const index = state.categoriesSimplified.findIndex(item => item.id === id);
      if (index != -1) {
        state.categoriesSimplified[index].soldItems = data
        state.categoriesSimplified[index].total = total
        state.categoriesSimplified[index].item_count = itemCount

      }

      state.status = 'fetch category succeeded'
      // Add any fetched posts to the array
    },
    [fetchCategoryReportCustom.rejected]: (state, action) => {
      state.status = 'fetch category failed'
      state.error = action.error.message
    },
  }
});

export const { addCategory, deleteCategory, updateSelectImage, updateStatus } = categorySlice.actions;

export default categorySlice.reducer;

export const selectAllCategories = state => state.category.categories;
export const selectAllCategoryImages = state => state.category.categoryImages;

export const selectCategoryById = (state, categoryId) => {
  const index = state.category.categories.findIndex(category => category.id === categoryId)
  const categories = state.category.categories;
  if (index !== -1) {
    return categories[index];
  } else {
    return null;
  }

}

export const selectSoldItemsByCategoryId = (state, categoryId) => {
  const index = state.category.categoriesSimplified.findIndex(category => category.id === categoryId)
  const categories = state.category.categoriesSimplified;
  if (index !== -1) {
    return categories[index].soldItems ?? [];
  } else {
    return null;
  }

}
