import React, { useEffect, useState, useRef } from 'react';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CheckOut from './CheckOut';
import { postPaymentIntent, cancelPaymentIntent, clearSecret, postConnectToken, postRemoveReader, selectSecret, postPairReader, setMyConnectedTerminal } from '../paymentProcessing/paymentProcessingSlice'
import { getSystemUser } from '../auth/authSlice';
import { updatePmtIntent, getPmtStatus, fetchOpenOrders } from '../order/orderSlice';
import { setSelfTriggered, setDialogOpen, fetchTable, setTableInstance, setDialogOrder, setDialogReceived, clearTableInstance, fetchTables, deleteTable, getTableInstances, deactivateInstance, updateTableNumber, updateTableAvail, updateTableHold, postTableInstance } from '../table/tableSlice';
import { useSelector, useDispatch } from 'react-redux';
import useCheckPaymentReceived from '../../hooks/useCheckPaymentReceived'
import currency from 'currency.js'
import { io } from "socket.io-client";
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ListItemText from '@material-ui/core/ListItemText';
import Order from './Order';
import { useStopwatch } from 'react-timer-hook';
import TableHistory from './TableHistory';
import CURRENT_SCREEN from './CurrentScreenEnums';

const calculateOrderNumber = (orderNumber, today, switchDate) => {
  if (today >= switchDate) {
    return (orderNumber % 999) + 1;
  } else {
    return (orderNumber >= 999 ? (orderNumber % 999) - 1 : orderNumber % 999);
  }
};

const DEFAULT_SOCKETS_URL = 'https://sockets.bigkiosksolution.com';
//const DEFAULT_SOCKETS_URL = 'http://127.0.0.1:5000';

let socket = null;

const OrderViewDialog = (props) => {

  const today = new Date();
  const switchDate = new Date(Date.UTC(2023, 8, 4, 11, 0));


  const dispatch = useDispatch()
  const openOrders = useSelector(state => state.order.openOrders)
  const status = useSelector(state => state.table.status)

  const [selectedOrder, setSelectedOrder] = useState(null);

  useEffect(() => {
    if (props.open) {

      dispatch(fetchOpenOrders())
    }
  }, [props.open])

  return props.order ? (
      <Dialog onClose={props.handleClose} open={props.open}>
        <div style={{width: 500, padding: 20, paddingTop: 5}}>
        <h2>Order #{calculateOrderNumber(props.order.order_number, today, switchDate)}</h2>
        <Divider style={{marginBottom: 15}} />
        {props.order.order_items.map(item =>
          <ListItem disableGutters style={{display: 'block'}} key={item.id}>
            {item.item ? <b><Chip size="small" color="primary" style={{position: 'relative', borderRadius:2, bottom: 0, marginRight: 5, fontSize: 11, minWidth: 20, maxHeight: 20}} label={item.quantity} />{item.done ? <strike>{item.item.name}</strike> : item.item.name}</b> : <b  style={{marign:0, padding:0}}>-</b>}
            <List style={{marginTop: 0}} dense  component="span">
              <p style={{margin: 0, padding: 3}}></p>
              {item.order_item_options.map(option => <ListItem style={{display: 'block'}} button key={option.id}>{option.option_str}</ListItem>)}
            </List>
          </ListItem>)}
          <Button disabled={status === 'table instance loading' ? true : false} style={{marginTop: 20}} onClick={() => {dispatch(clearTableInstance({orderId: props.order.id, instanceId: props.instanceId}))}} fullWidth variant="contained" color="secondary">Clear Order</Button>
        </div>
      </Dialog>
  ) : (
      <Dialog onClose={props.handleClose} open={props.open}>
        <div style={{width: 500, padding: 20, paddingTop: 0}}>
        <h2 style={{marginBottom: 0, paddingBottom: 0}}>Order</h2>
        <small style={{margin:0, padding: 0}}>Assign an order to the table</small>
        <hr />
        <FormControl style={{marginTop: 10}} size="small" fullWidth variant="outlined">
        <Select
          native
          value={selectedOrder}
          onChange={(e) => setSelectedOrder(e.target.value)}
          inputProps={{
            name: 'order',
            id: 'outlined-age-native-simple',
          }}
        >
          <option aria-label="None" value="None">-- Assign an Order -- </option>
          {openOrders?.map(order => <option key={order.id} value={order.id}>Order # {calculateOrderNumber(order.order_number, today, switchDate)}</option>)}
        </Select>

        <hr />
        {selectedOrder ? (
          <Button disabled={status === 'table instance loading'} onClick={() => dispatch(setTableInstance({orderId: selectedOrder, instanceId: props.instanceId}))} fullWidth variant="contained" color="primary">Assign Order</Button>
        ) : (
          <Button disabled fullWidth variant="contained" color="primary">Assign Order</Button>
        )}
      </FormControl>
        </div>
      </Dialog>
  )

}
const TabelInstanceDialog = (props) => {

  const dispatch = useDispatch()

  return props.open ? (
    <Dialog onClose={props.handleClose} open={props.open}>
      <div style={{width: 500, padding: 20}}>
        {props.tableInstances.map((instance, index) =>
          <>
          <List key={index}>
            <ListItem>
              <ListItemText>
                <small>{new Date(instance.start_time).toLocaleString()}</small>
              </ListItemText>
              <ListItemText>
              ~
              </ListItemText>
              <ListItemText>
              {instance.end_time ? (
                <small>{new Date(instance.end_time).toLocaleString()}</small>
              ) : (
                <small>N/A</small>
              )}
              </ListItemText>
              <ListItemIcon>

                <Button color="primary" variant="text">View Order</Button>

              </ListItemIcon>
            </ListItem>
          </List>
          <Divider />
          </>
        )}
      </div>
    </Dialog>
  ) : (
    null
  )
}

const TabelItemDialog = (props) => {

  const dispatch = useDispatch()

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(updateTableNumber({id: props.id, max_capacity: e.target.max_capacity.value, table_number: e.target.table_number.value}))
    props.handleClose()
  }

  return props.open ? (
      <Dialog onClose={props.handleClose} open={props.open}>
        <div style={{width: 500, padding: 20}}>
          <form onSubmit={handleSubmit}>
          <TextField placeholder={props.table_number} name="table_number" size="small" fullWidth variant="outlined" label="Table #" />
          <TextField style={{marginTop:5}} placeholder={props.max_capacity} type="number" name="max_capacity" size="small" fullWidth variant="outlined" label="Max Capacity" />
          <Button type="submit" style={{marginTop: 7}} fullWidth variant="contained" color="primary">Edit</Button>
          </form>
        </div>
      </Dialog>
  ) : (
    null
  )

}
const options = ['TABLE AVAILABLE', 'TABLE OCCUPIED', 'HOLD FOR WAITLIST'];

const Subtitle = (props) => {
  if (props.option === options[0]) {
    return <small >clears dining session, makes table available</small>;
  } else if (props.option === options[1]) {
    return <small >creates dining session, marks table as occupied</small>;
  } else {
    return <small>clears dining session, holds table for waitlist, marks as unavailable</small>;
  }
}


const DropDownButton = (props) => {

 const [open, setOpen] = React.useState(false);
 const anchorRef = React.useRef(null);
 const [selectedIndex, setSelectedIndex] = React.useState(1);

 useEffect(() => {
   if (props.hold) {
     setSelectedIndex(2);
   } else if (typeof props.available !== 'undefined') {
     if (props.available) {
       setSelectedIndex(0);
     } else {
       setSelectedIndex(1);
     }
   }
 }, [props.hold, props.available]);

 const handleMenuItemClick = (event, index) => {
   props.onUpdate(index);
   setSelectedIndex(index);
   setOpen(false);
 };

 const handleToggle = () => {
   setOpen((prevOpen) => !prevOpen);
 };

 const handleClose = (event) => {
   if (anchorRef.current && anchorRef.current.contains(event.target)) {
     return;
   }

   setOpen(false);
 };

  return (
    <>
        <ButtonGroup color={
            options[selectedIndex] == 'TABLE AVAILABLE'
              ? 'primary'
              : options[selectedIndex] == 'HOLD FOR WAITLIST'
              ? 'default'
              : 'secondary'
          } fullWidth variant="contained" ref={anchorRef} aria-label="split button">
          <Button onClick={handleToggle}>{options[selectedIndex]}</Button>
        </ButtonGroup>
        <Popper style={{zIndex: 99999999}} open={open} anchorEl={anchorRef.current} role={undefined} transition>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem
                        key={index}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                      <ListItemText
                        primary={option}
                        secondary={<Subtitle option={option} />}
                      />
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper></>)
}

const TableItemContent = (props) => {

  const today = new Date();
  const switchDate = new Date(Date.UTC(2023, 8, 4, 11, 0));


  const { received } = useCheckPaymentReceived(props.order);


  const dispatch = useDispatch();
  if (props.type === CURRENT_SCREEN.HOME) {
  return (
    <React.Fragment>
    <CardActions>
    <DropDownButton hold={props.hold} available={props.available} onUpdate={index => props.handleDropDown(index)} />

    </CardActions>
    <CardActions>
        <Button onClick={() => {
          props.onTypeChange(CURRENT_SCREEN.CHECKOUT)
          dispatch(props.setDialogOrder(props.getOrder()));
          /*
          dispatch(props.setDialogOrder(props.getOrder()));
          dispatch(props.setDialogOpen(true));
          */
        }}  disabled={props.available || props.hold ? true : false}  fullWidth color="primary" variant="outlined" size="medium">CHECKOUT {received && !props.available ? <small style={{color: 'green'}}>&nbsp;PAYMENT RECEIVED</small> : null}</Button>
      </CardActions>
      <CardActions>
    <Button onClick={() => {props.onTypeChange(CURRENT_SCREEN.ORDER)}} disabled={props.table_instances?.[0]?.start_time && !props.table_instances?.[0]?.end_time ? false : true} fullWidth color="primary" variant="outlined" size="medium">
      {props.order ? 'Order #' + calculateOrderNumber(props.order?.order_number, today, switchDate) : 'View Order'}
      {!props.available && !props.hold && props.order ? (
        <small style={{marginLeft: 5, position: 'relative', top: 0.7}}>
        {props.hours  > 0 ? String(props.hours).padStart(2, '0') : '00'}:{props.minutes > 0 ? String(props.minutes).padStart(2, '0') : '00'}:{String(props.seconds).padStart(2, '0')}
        </small>
      ) : (
        null
      )}

    </Button>
    </CardActions>
    <CardActions>
    <Button onClick={() => {props.onTypeChange(CURRENT_SCREEN.HISTORY)}}  fullWidth color="primary" variant="outlined" size="medium">Table History</Button>
    </CardActions>
    </React.Fragment>
    )
  } else if (props.type === CURRENT_SCREEN.CHECKOUT) {
    return (
      <CheckOut
        onTypeUpdate={type => props.onTypeChange(type)}
        {...props}
      />
    )
  } else if (props.type === CURRENT_SCREEN.ORDER) {
    return (
      <Order onTypeUpdate={type => props.onTypeChange(type)} instanceId={props.table_instances[0]?.id} order={props.order} handleClose={props.handleClose3} />
    )
  } else if (props.type === CURRENT_SCREEN.HISTORY) {
    return (
      <TableHistory onTypeUpdate={type => props.onTypeChange(type)} id={props.id} table_number={props.table_number} tableInstances={props.table_instances}  />
    )
  }
}


const TableItem = (props) => {
  const socketRef = useRef();

  const isFirstRender = useRef(true);
  let terminal = {};
  let order = null;
  const dispatch = useDispatch();

  const [date, setDate] = useState(new Date(props.table_instances[0]?.start_time));
  const [stopwatchOffset, setStopwatchOffset] = useState(() => {
    const secondsOffset = Math.floor((new Date() - date) / 1000);
    let offset = new Date();
    offset.setSeconds(offset.getSeconds() + secondsOffset);
    return offset;
  });


  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: false, offsetTimestamp: stopwatchOffset });


  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [open3, setOpen3] = useState(false)
  const [optionHidden, setOptionHidden] = useState(true)
  const [open4, setOpen4] = useState(null)
  const [loading, setLoading] = useState(false)
  const [terminals, setTerminals] = useState(null)
  const [_terminal, setTerminal] = useState(null)
  const [currentScreen, setCurrentScreen] = useState(CURRENT_SCREEN.HOME);

  const secret = useSelector(state => state.paymentProcessing.secret)
  const intentId = useSelector(state => state.paymentProcessing.intentId)
  const paymentProcessingStatus = useSelector(state => state.paymentProcessing.status)

  const status = useSelector(state => state.table.status)
  const pmtStatusArr = useSelector(state => state.order.pmtStatusArr)
  const [pairingCode, setPairingCode] = useState(null);
  const [_StripeTerminal, setStripeTerminal] = useState(null);

  const [prevProps, setPrevProps] = useState({ available: props.available, hold: props.hold });

  const user = useSelector(state => state.auth.user)

  const handleClose = () => {
    setOpen(false);
  }

  const handleClose2 = () => {
    setOpen2(false);
  }

  const handleClose3 = () => {
    setOpen3(false);
  }

  const getOrder = () => {
    if (props.table_instances[0]) {
      return props.table_instances[0].start_time &&
             !props.table_instances[0].end_time ? (
               props.table_instances[0].order[0]
             ) : (
               null
             )
    }
    return null;
  }


  const handleDropDown = async (index) => {
    try {
      dispatch(setSelfTriggered(true));
      setLoading(true);

      if (options[index] === 'TABLE AVAILABLE') {
        await dispatch(updateTableAvail({id: props.id, available: true}));
        await dispatch(deactivateInstance({id: props.id}));
        if (getOrder() != null) {
          await dispatch(clearTableInstance({orderId: getOrder().id, instanceId: props.id}))
        }
      } else if (options[index] === 'TABLE OCCUPIED') {
        await dispatch(updateTableAvail({id: props.id, available: false}));
        await dispatch(postTableInstance({table: props.id}));
        if (getOrder() != null) {
          await dispatch(clearTableInstance({orderId: getOrder().id, instanceId: props.id}))
        }

      } else { // options[index] === 'TABLE UNAVAILABLE'
        await dispatch(updateTableHold({id: props.id, hold: true}));
        await dispatch(deactivateInstance({id: props.id}));
        if (getOrder() != null) {
          await dispatch(clearTableInstance({orderId: getOrder().id, instanceId: props.id}))
        }
      }

      dispatch(setSelfTriggered(false));

    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (status === 'deactivate failed' || status === 'update table avail finished' ||
        status === 'deactivated' || status === 'update table avail failed' ||
        status === 'table instance added' || status === 'table instance failed'  ||
        status === 'update table hold finished' || status === 'update table hold failed') {
        setTimeout(() => {
            setLoading(false);
        }, 100)
    } else if (status === 'fetch table fulfilled') {
      order = getOrder();
      if (order != null) {
        dispatch(setDialogOrder(order));
      }
    }

  }, [status])

  useEffect(() => {
   setDate(new Date(props.table_instances[0]?.start_time))
  }, [props.table_instances])

  useEffect(() => {
    const secondsOffset = Math.floor((new Date() - date) / 1000);
    let offset = new Date();
    offset.setSeconds(offset.getSeconds() + secondsOffset);
    reset(offset)
    setStopwatchOffset(offset)
    start();
  }, [date])

  order = getOrder();

  return (
    <React.Fragment>
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs>
            <Typography  color="textPrimary" gutterBottom>
              #{props.table_number}
            </Typography>
          </Grid>
          <Grid item xs>
          <span style={{float: "right"}}>
            {props.hold ? (
              <Chip size="small" label={loading ? <CircularProgress style={{position: 'relative', top: 2}} size={15} color="white" /> : 'HOLD'} color="default"/>
            ) : (
              props.available ? (
                <Chip size="small" label={loading ? <CircularProgress style={{position: 'relative', top: 2}} size={15} color="white" /> : 'AVAILABLE'} color="primary" />
              ) : (
                <Chip size="small" label={loading ? <CircularProgress style={{position: 'relative', top: 2}} size={15} color="white" /> : 'OCCUPIED'} color="secondary" />
              )
            )}

          </span>
          </Grid>
        </Grid>
          </CardContent>
          <TableItemContent seconds={seconds} minutes={minutes} hours={hours} instanceId={props.table_instances[0]?.id} handleClose={handleClose3}  {...props} onTypeChange={type => setCurrentScreen(type)} type={currentScreen}  setOpen2={setOpen2} setDialogOrder={setDialogOrder} setOpen3={setOpen3} handleDropDown={handleDropDown} getOrder={getOrder} order={getOrder()}  available={props.available} hold={props.hold}/>
        {!optionHidden ? (
            <>
                <Divider />
                <CardActions>
                    <Button onClick={() => setOpen(true)} fullWidth color="default" variant="outlined" size="small">EDIT</Button>
                </CardActions>
                <CardActions>
                    <Button onClick={() => {
                      props.onDeleteTable()
                    }} fullWidth color="secondary" variant="contained" size="small">Delete</Button>
                </CardActions>
                <Divider />
                    <div style={{textAlign: 'center'}}>
                    <IconButton onClick={() => setOptionHidden(true)} aria-label="expand">
                        <ExpandLessIcon />
                    </IconButton>
                    </div>
            </>
        ) : (
            <>
                <Divider />
                <div style={{textAlign: 'center'}}>
                    <IconButton onClick={() => setOptionHidden(false)} aria-label="expand">
                        <ExpandMoreIcon />
                    </IconButton>
                </div>
            </>


        )}

    </Card>
    <TabelItemDialog key={props.key} id={props.id} table_number={props.table_number} handleClose={handleClose} open={open} />
    <TabelInstanceDialog key={props.key} id={props.id} table_number={props.table_number} tableInstances={props.table_instances} handleClose={handleClose2} open={open2} />
    <OrderViewDialog key={props.key} instanceId={props.table_instances[0]?.id} order={order} handleClose={handleClose3} open={open3} />
    </React.Fragment>
  )
}

export default TableItem;
