import React from 'react';
import AddCouponModal from '../modal/AddCouponModal'

const CouponModal = () => {
  return (
    <AddCouponModal />
  );
};

export default CouponModal;
