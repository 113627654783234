import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import CategoryImage from './CategoryImage';
import { useDispatch, useSelector } from 'react-redux';
import { updateStatus, postCategoryImagePosition, postItemsInCategoryOrder, patchBulkAddItemsToCategory, patchAddItemsToCategory, patchRemoveItemsToCategory, removeCategory, selectAllCategoryImages, fetchCategories, fetchCategoryImages, putCategory, patchCategory } from './categorySlice';
import { selectAllItems, fetchItems, fetchItemsAlt, fetchItemsSimplified, selectAllItemsSimplified } from '../item/itemSlice';
import { setAddCategoryImageModalOpen, selectCategoryImageModalOpen } from '../modal/modalSlice';
import OverrideCategoryTable from './OverrideCategoryTable';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import { green, pink } from '@material-ui/core/colors';
import Avatar from '@material-ui/core/Avatar';
import FolderIcon from '@material-ui/icons/Folder';
import PageviewIcon from '@material-ui/icons/Pageview';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ClickButton from '../../components/previews/ClickButton';
import FlavoredTea from '../../assets/FlavoredTea.png'
import SimpleList from '../../components/commons/SimpleList';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {useDraggable} from '@dnd-kit/core';
import FormGroup from '@material-ui/core/FormGroup';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { getFranchisedShops } from '../auth/authSlice';
import ConfirmDeleteModal from '../modal/ConfirmDeleteModal';
import { io } from "socket.io-client";
const DEFAULT_SOCKETS_URL = 'https://sockets.bigkiosksolution.com';

const CATEGORY_IMG_WIDTH = '60px';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  accordionRoot: {
    WebkitUserSelect: 'none', // for Safari
    msUserSelect: 'none', // for Internet Explorer 10 and 11
    userSelect: 'none', // Standard syntax for other browsers
    width: 800,
    [theme.breakpoints.down('md')]: {
      width: 660
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
  body: {
    paddingTop: 40,
    touchAction: "pan-x"
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
  },
  label: {
    marginTop: -25,
    paddingTop: -1000,
  },
  footer: {
    paddingTop: 5,
    paddingBottom: 3
  },
  paper: {
    backgroundColor: '#fafafa',
    width: '100%',
    padding: 20,
    marginTop:-10,
    paddingTop: 5,
    paddingBottom:0,
    height: 195,
    textAlign: 'center'
  },
  paper2: {
    backgroundColor: '#fafafa',
    width: '100%',
    padding: 20,
    marginTop:-10,
    paddingTop: 5,
    height: 195,
    textAlign: 'center',
    overflowX: 'hidden',
    overflowY: 'scroll'
  }

}));

/* HEADER (PART) */
const CategoryItemHeader = (props) => {
  const classes = props.classes;
  const user = useSelector(state => state.auth.user)
  const handleChange = () => {
    props.setExpanded(true);
  }

  const handleCollapse = () => {
    props.setExpanded(false);
  }

  const handleNameChange = (e) => {
    props.onCategoryNameUpdate(e.target.value);
  }

  return (
    <React.Fragment>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon onClick={props.expanded ? () => handleCollapse() : () => handleChange()}/>}
        aria-controls="panel1a-content"
        id="panel1a-header">
        {user?.shop?.type !== 'franchisee' ? (
          <IconButton style={{touchAction: "none", marginRight: 10}} {...props.listners} {...props.attrs}>
            <DragIndicatorIcon fontSize="small" />
          </IconButton>
        ) : (
          null
        )}
        <InputBase
        fullWidth
        onChange={handleNameChange}
        className={classes.heading}
        color="primary"
        defaultValue={props.categoryName}
      />
      </AccordionSummary>
    </React.Fragment>
  );
}

const SkeletonImageBody = () => {
  return (
    <React.Fragment>
      <Skeleton variant="circle" width={65} height={65} style={{marginRight: 10, marginBottom: 20}} />
      <Skeleton variant="circle" width={65} height={65} style={{marginRight: 10, marginBottom: 20}} />
      <Skeleton variant="circle" width={65} height={65} style={{marginRight: 10, marginBottom: 20}} />
      <Skeleton variant="circle" width={65} height={65} style={{marginRight: 10, marginBottom: 20}} />
      <Skeleton variant="circle" width={65} height={65} style={{marginRight: 10, marginBottom: 20}} />
      <Skeleton variant="circle" width={65} height={65} style={{marginRight: 10, marginBottom: 20}} />
      <Skeleton variant="circle" width={65} height={65} style={{marginRight: 10, marginBottom: 20}} />
      <Skeleton variant="circle" width={65} height={65} style={{marginRight: 10, marginBottom: 20}} />
      <Skeleton variant="circle" width={65} height={65} style={{marginRight: 10, marginBottom: 20}} />
      <Skeleton variant="circle" width={65} height={65} style={{marginRight: 10, marginBottom: 20}} />
    </React.Fragment>
  );
}

/* BODY (PART) */
const CategoryItemBody = (props) => {
  const socketRef = useRef();

  const classes = props.classes;
  const categoryImages = useSelector(selectAllCategoryImages);
  const allItems = useSelector(selectAllItems);
  const dispatch = useDispatch();
  const categoryStatus = useSelector(state => state.category.status);
  const open = useSelector(selectCategoryImageModalOpen);
  const user = useSelector(state => state.auth.user);
  const franchisedShops = useSelector(state => state.auth.franchisedShops);

  const status = useSelector(state => state.category.categoryImagesStatus);
  const putCategoryStatus = useSelector(state => state.category.putCategoryStatus);
  const [dndList, setDndList] = useState([...props.groups.map(group => group.item.id)]);

  const obj = {
    targetValue: props.category_image,
    categoryId: props.categoryId,
    name: props.categoryName,
    active: props.categoryActive,
    taxable: props.taxable
  };


  const pingKioskActivity = () => {
    const shopId = localStorage.getItem('shop_id')

    if (socketRef.current == null) {
      socketRef.current = io(DEFAULT_SOCKETS_URL, {
        transports: ["websocket"] // use WebSocket first, if available
        });
    }

    const {current: socket} = socketRef;
    console.log('pinging kiosk')
  // Emit with a callback for acknowledgment
    socket.emit(
      'ping-subject-activity',  
      { identifier: props.storedIdentifier, shop_id: shopId.toString(), subject_id: props.categoryId, subject: 'Category' },
      (response) => {
        if (response === undefined) {
          // Handle case when response is undefined
          console.error('No acknowledgment received from server');
        } else if (response.status === 'success') {
          // Handle success case
          console.log('Success:', response.message);
        } else {
          
        }    
      }
    );  
}



  const handleSwitch2 = (active, shopId) => {
    if (socketRef.current == null) {
       socketRef.current = io(DEFAULT_SOCKETS_URL, {
         transports: ["websocket"] // use WebSocket first, if available
       });
    }

    const {current: socket} = socketRef;

    socket.emit('category-status-update-from-dashboard',  {status: active, identifier: props.storedIdentifier, category_id: props.categoryId, id: shopId.toString()});

  }


  const handleSwitch = () => {
    const shopId = localStorage.getItem('shop_id')

    if (socketRef.current == null) {
       socketRef.current = io(DEFAULT_SOCKETS_URL, {
         transports: ["websocket"] // use WebSocket first, if available
       });
    }

    const {current: socket} = socketRef;

    if (franchisedShops && franchisedShops.length > 0) {
      for (let i = 0; i < franchisedShops.length; i++) {
        socket.emit('category-status-update-from-dashboard',  {status: props.active ? false : true, category_id: props.categoryId, identifier: props.storedIdentifier, id: franchisedShops[i].id.toString()},
        (response) => {
          if (response === undefined) {
            // Handle case when response is undefined
            console.error('No acknowledgment received from server');
          } else if (response.status === 'success') {
            props.active ? props.onActive(false) : props.onActive(true);
            let category = obj;
            category.active = props.active ? false : true;
            if (!props.storedIdentifier) {
              dispatch(patchCategory(category))
            }
          } else {
            // Handle other possible responses
            console.error('Failed:', response.message || 'Unknown error');
          }
        }
      );
      }
    } else {

      socket.emit('category-status-update-from-dashboard',  {status: props.active ? false : true, category_id: props.categoryId, identifier: props.storedIdentifier, id: shopId}, 
        (response) => {
          console.log(response)
          if (response === undefined) {
            // Handle case when response is undefined
            console.error('No acknowledgment received from server');
          } else if (response.status === 'success') {
            props.active ? props.onActive(false) : props.onActive(true);
            let category = obj;
            category.active = props.active ? false : true;
            if (!props.storedIdentifier) {
              dispatch(patchCategory(category))
            }
          } else {
            // Handle other possible responses
            console.error('Failed:', response.message || 'Unknown error');
          }
        }
      );
    }    
  }


  const handleRoundedSwitch = () => {
    props._rounded ? props.onRoundedSwitch(false) : props.onRoundedSwitch(true);
    let category = obj;
    category.rounded = props._rounded ? false : true;

    dispatch(patchCategory(category))
  }

  useEffect(() => {
    if (categoryStatus === 'idle' && allItems?.length === 0) {
      dispatch(fetchItemsAlt());
    }

    if (status === 'idle' && categoryImages?.length === 0) {
      dispatch(fetchCategoryImages()); // populates state.category.categories
    }
  }, [status, dispatch]) // if either postStatus or dispatch changes, fire!


  useEffect(() => {
    if (props.groups) {
      setDndList([...props.groups.map(group => group.item)])
    } else {
      setDndList([...props.items.map(item => item.id)])
    }

  }, [props.items])

  useEffect(() => {
    pingKioskActivity()

      if (allItems.length === 0) {
          dispatch(fetchItemsAlt());
      }

    dispatch(getFranchisedShops({franchise_id: user?.shop?.franchise?.id}))

    return () => {
      const {current: socket} = socketRef;
      if (socket?.connected) {
        socket.close();
      }
    }
  }, [])


  const handleSimpleListItemOrder = (list) => {
    dispatch(postItemsInCategoryOrder({items: list, categoryId: props.id}));
  }

  let imagesDisp = '';
  if (categoryImages.length > 0) {
    const selectedId = props.category_image;
    imagesDisp = <Grid container spacing={2}>
                    <Grid item>
                      {user?.shop?.type != 'franchisee' ? (
                        <IconButton onClick={() => dispatch(setAddCategoryImageModalOpen(open => !open))}>
                          <AddCircleOutlineIcon />
                        </IconButton> ) : (
                          null
                        )}
                    </Grid>
                    {categoryImages.map((image) => {
                      const selected = selectedId === image.id ? true : false;
                      return <Grid item>
                              <CategoryImage
                                key={image.id}
                                categoryActive={props.categoryActive}
                                categoryName={props.categoryName}
                                selected={selected}
                                categoryId={props.id}
                                selectedId={selectedId}
                                {...image}
                                width={CATEGORY_IMG_WIDTH} />
                              </Grid>
                    }, selectedId, props.id)}
                 </Grid>
  } else if (status === 'loading') {
    imagesDisp = <Grid container spacing={3}><SkeletonImageBody /></Grid>;
  } else {
    imagesDisp = <Grid container  spacing={2}>  <Grid item>
                    <IconButton  onClick={() => dispatch(setAddCategoryImageModalOpen(open => !open))}>
                      <AddCircleOutlineIcon />
                    </IconButton>
                    </Grid>
                  </Grid>;
  }

  let selectedImgUrl = '';
  let id = '';
  let x = 0;
  let y = 0;
  categoryImages.map((image) => {
      if (image.id === props.category_image) {
        selectedImgUrl = image.category_image;
        id = image.id
        x = props.relative_position_x;
        y = props.relative_position_y;
      }
  }, props.category_image, selectedImgUrl);
  return (
    <React.Fragment>
    <Divider />
      <AccordionDetails className={classes.body}>
      <FormGroup row>
        {user?.shop?.type != 'franchisee' ? (
          <FormControlLabel className={classes.label}
          control={
              <Switch
              checked={props.active}
              onClick={handleSwitch}
              name="checkedB"
              color="primary"/>
            }
            label={<>Active  <small>{props.storedIdentifier && `(${props.storedIdentifier})`}</small></>}/>
        ) : (
          null
        )}
     </FormGroup>
      </AccordionDetails>
      <AccordionDetails style={{marginBottom: 0}}>
          <Grid container
                spacing={1}
                direction="row">
            <Grid item xs={12} md={3} style={{marginBottom: 12}}>
              <Paper className={classes.paper} elevation={0} >
              <h4>Preview</h4>
                <ClickButton
                  onMouseLeave={() => props.onPreviewImageUngrabbed()}
                  onMouseEnter={() => props.onPreviewImageGrabbed()}
                  headerImg={ selectedImgUrl }
                  buttonDesc={props.categoryName}
                  id={props.category_image}
                  categoryId={props.categoryId}
                  x={x} y={y}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={9}>
            <Paper className={classes.paper2} elevation={0}>
              <h4>Choose Image</h4>
              {imagesDisp}
            </Paper>
            </Grid>
          </Grid>
      </AccordionDetails>
      <AccordionDetails>
        <SimpleList
          title={props.name?.slice(0, 14) + '\'s Item(s)'}
          onItemRemove={(selectedItems, existingItems) => dispatch(patchRemoveItemsToCategory({existingItems: existingItems,
                                                                                              removedItems: selectedItems,
                                                                                              categoryId: props.id}))}
          onItemAdd={(items, id) => dispatch(patchAddItemsToCategory({existingItems: items,
                                                                      itemId: id,
                                                                      categoryId: props.id}))}
          onItemBulkAdd={(items, ids) => dispatch(patchBulkAddItemsToCategory({ existingItems: items,
                                                                              items: ids,
                                                                              categoryId: props.id
                                                                              }))}
          unselectedItems={allItems}
          onOrderUpdate={(list) => handleSimpleListItemOrder(list)}
          name={props.name}
          items={props.groups}
          type="items" />
      </AccordionDetails>
      {user?.shop?.type === 'franchiser' ? (
        <AccordionDetails>
          <OverrideCategoryTable onActiveOverride={(active, shopId) => handleSwitch2(active, shopId)} overrides={props.overrides} id={props.id} active={props.categoryActive} shops={franchisedShops.filter(shop => shop.id != user?.shop?.id)} />
        </AccordionDetails>
      ) : (
        null
      )}
    </React.Fragment>
  );
}

/* FOOTER (PART) */
const CategoryItemFooter = (props) => {
  const classes = props.classes;
  const dispatch = useDispatch();
  const categories = useSelector(state => state.category.categories);
  const user = useSelector(state => state.auth.user);

  const obj = {
    targetValue: props.category_image,
    categoryId: props.id,
    name: props.categoryName,
    active: props.categoryActive,
    taxable: props.categoryTaxable
  };
  return (
    <React.Fragment>
      <Divider />
      <AccordionDetails className={classes.footer}>
        <Grid   container
                direction="row"
                justify="space-between"
                alignItems="center">

                {user?.shop?.type != 'franchisee' || user?.shop?.delete_category_allowed ? (
                  <Button onClick={() => props.onDelete()} color="secondary">DELETE</Button>
                ) : (
                    null
                )}
                {user?.shop?.type != 'franchisee' || user?.shop?.edit_category_allowed ? (
                  <Button onClick={() => dispatch(patchCategory(obj))}>SAVE</Button>
                ) : (
                    null
                )}
        </Grid>
      </AccordionDetails>
    </React.Fragment>
  );
}

/* CATEGORY ITEM COMPONENT (MAIN) */
const CategoryItem = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [sortableDisabled, setSortableDisabled] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [confirmImageDelete, setConfirmImageDelete] = useState(false);
    const [active, setActive] = useState(props.active);
    const [taxable, setTaxable] = useState(props.taxable);
    const identifier = localStorage.getItem('identifier') || null;

    const [categoryName, setCategoryName] = useState(props.name);
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
    } = useSortable({id: props.id, disabled: sortableDisabled});

    const handleGrab = (grabbed) => {
      setSortableDisabled(grabbed);
    }

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };
    
    useEffect(() => {
      const shopId = localStorage.getItem('shop_id');
    
      const handlePongSubjectActivity = (payload) => {
        console.log('mounting')
        if (
          payload.data?.subject_id === props.id && 
          payload.data?.subject === 'Category' && 
          payload.data?.identifier === identifier && 
          shopId === payload.data?.shop_id
        ) {
          setActive(payload?.data.activity)
        } 
      };
    
      if (identifier && props.id && expanded) {
        // Check if socket is initialized, if not, initialize it
        if (!props.socket) {
          props.socket = io(DEFAULT_SOCKETS_URL, {
            transports: ["websocket"], // use WebSocket first, if available
          });
        }
    
        // Attach the event listener
        props.socket.on('pong-subject-activity', handlePongSubjectActivity);
      }
    
      // Cleanup listener on unmount or dependency change
      return () => {
        if (props.socket) {
          console.log('exit')
          props.socket.off('pong-subject-activity', handlePongSubjectActivity);
        }
      };
    }, [identifier, props.id, expanded]); // include identifier and props.id as dependencies
    

    return (
      <>
        <Accordion TransitionProps={{ unmountOnExit: true }} ref={setNodeRef} style={style}  expanded={expanded} className={classes.accordionRoot}>
          <CategoryItemHeader attrs={attributes} listners={listeners} onCategoryNameUpdate={setCategoryName}  expanded={expanded} categoryName={categoryName} setExpanded={setExpanded} classes={classes} {...props} />
          {expanded ? <CategoryItemBody  storedIdentifier={identifier}  onTaxable={setTaxable} taxable={taxable} categoryId={props.id} onPreviewImageUngrabbed={() => handleGrab(false)} onPreviewImageGrabbed={() => handleGrab(true)} {...props} categoryActive={active} categoryName={categoryName} onActive={setActive} active={active} classes={classes} /> : null}
          <CategoryItemFooter  onDelete={() => setConfirmDelete(true)} categoryTaxable={taxable} categoryActive={active} classes={classes} categoryName={categoryName} {...props} />
        </Accordion>
        <ConfirmDeleteModal onDelete={() => { dispatch(removeCategory(props.id)); setConfirmDelete(false); }} onClose={() => setConfirmDelete(false)} open={confirmDelete} />

        </>
    );
};

export default CategoryItem;
