import React, { useState, useEffect, useRef } from "react";
import { loadStripeTerminal } from '@stripe/terminal-js';
import { fetchConnectionToken } from '../api/DashboardAPI';

const StripeStatus = {
    INITIAL: "initializing",
    LOADING: "loading",
    SUCCESS: "success",
    FAILED: "failed",
    STOPPED: "stopped",
    INITIAL_NO_NOTIF: "initializing no notif",
    LOADING_NO_NOTIF: "loading no notif",
    SUCCESS_NO_NOTIF: "success no notif",
    FAILED_NO_NOTIF: "failed no notif"
}

const TerminalStatus = {
    INITIAL: "initializing",
    DISCOVERING: "discovering",
    DISCOVERED: "discovererd",
    CONNECTING: "connecting",
    CONNECTED: "connected",
    DISCONNECTED: "disconnected",
    EMPTY: "empty",
    FAILED: "failed",
    STOPPED: "stopped",
    OFFLINE: "offline",
    ONLINE: "online",
    INITIAL_NO_NOTIF: "initializing no notif",
    DISCOVERING_NO_NOTIF: "discovering no notif",
    DISCOVERED_NO_NOTIF: "discovererd no notif",
    CONNECTING_NO_NOTIF: "connecting no notif",
    CONNECTED_NO_NOTIF: "connected no notif",
    DISCONNECTED_NO_NOTIF: "disconnected no notif",
    EMPTY_NO_NOTIF: "empty no notif",
    FAILED_NO_NOTIF: "failed no notif",
    OFFLINE_NO_NOTIF: "offline no notif",
    ONLINE_NO_NOTIF: "online no notif"

}

const useTerminal = (shop, explicitTerminals = null) => {
    const [stopped, setStopped] = useState(false);
    const [stripeStatus, setStripeStatus] = useState(StripeStatus.INITIAL);
    const [terminalStatus, setTerminalStatus] = useState(TerminalStatus.INITIAL);
    const [stripe, setStripe] = useState(null);
    const [terminal, setTerminal] = useState(null);
    const [physicalTerminals, setPhysicalTerminals] = useState([]);
    const [connectedPhysicalTerminal, setConnectedPhysicalTerminal] = useState(null);
    const [isNoNotifMode, setIsNoNotifMode] = useState(false);
    const isMounted = useRef(true);
    const operationInProgressInit = useRef(false);
    const operationInProgressDiscover = useRef(false);
    const operationInProgressConnect = useRef(false);

    useEffect(() => {
        console.log('useTerminal')

        isMounted.current = true;
        if (shop) {
            stripeInit();
        }
    
        return () => {
            isMounted.current = false;
        };
    }, [shop]);

    useEffect(() => {
        if (explicitTerminals) {
          console.log(explicitTerminals)
          console.log('discovering readers...')
          stripeDiscoverReaders()
        }
      }, [explicitTerminals])

    useEffect(() => {
        let retryTimeout;

        if (stripeStatus === StripeStatus.STOPPED && terminalStatus === TerminalStatus.STOPPED) {
            setStopped(true);
        }

        if (!stopped && stripeStatus !== StripeStatus.STOPPED && (stripeStatus === StripeStatus.FAILED || stripeStatus === StripeStatus.FAILED_NO_NOTIF)) {
            retryTimeout = setTimeout(() => {
                reset();
                stripeInit();
            }, 8000);
        }

        if (!stopped && terminalStatus !== TerminalStatus.STOPPED && (terminalStatus === TerminalStatus.FAILED || terminalStatus === TerminalStatus.FAILED_NO_NOTIF)) {
            retryTimeout = setTimeout(() => {
                physicalTerminals.length === 0 ? stripeDiscoverReaders() : !shop.is_terminal_experimental ? stripeConnectReader(physicalTerminals) : stripeConnectReaderExperimental(physicalTerminals)
            }, 8000);
        }

        return () => clearTimeout(retryTimeout);
    }, [stripeStatus, terminalStatus, stopped]);


    useEffect(() => {
        if (stripe) {
            setStripeTerminal();
        }
    }, [stripe]);

    useEffect(() => {
        if (terminal) {
            //stripeDiscoverReaders();
        }
    }, [terminal]);

    const updateStripeStatus = (status) => {
        if (!stopped) {
            setStripeStatus(status);
        }
    }

    const updateTerminalStatus = (status) => {
        if (!stopped) {
            setTerminalStatus(status);
        }
    }

    const reset = () => {
        setStripeStatus(StripeStatus.INITIAL);
        setTerminalStatus(TerminalStatus.INITIAL);
        setStopped(false);
        setStripe(null);
        setTerminal(null);
        setConnectedPhysicalTerminal(null);
    }

    const stripeInit = async () => {
        try {
            console.log('debug: stripe init in useTerminal...')
            if (operationInProgressInit.current) {
                console.log('debug: oo')
                return;
            }
            
            operationInProgressInit.current = true;
            console.log('debug: here 1')

            setIsNoNotifMode(false);
            updateStripeStatus(StripeStatus.LOADING);
            console.log('debug: here 2')
        } catch (error) {
            console.log(error)
        }
        try {
            const stripeTerminal = await loadStripeTerminal();
            if (isMounted.current) {
                console.log('debug: ismounted check passed')
                setStripe(stripeTerminal);
                updateStripeStatus(stripeTerminal ? StripeStatus.SUCCESS : StripeStatus.FAILED);
            }
        } catch (error) {
            if (isMounted.current) {
                console.log('debug: exception error')
                updateStripeStatus(StripeStatus.FAILED);
            }
        } finally {
            operationInProgressInit.current = false;
        }
    }

    const stripeInitNoNotif = async () => {
        if (operationInProgressInit.current) return;
        operationInProgressInit.current = true;

        setIsNoNotifMode(true);
        updateStripeStatus(StripeStatus.LOADING_NO_NOTIF);
        try {
            if (stripe == null) {
                const stripeTerminal = await loadStripeTerminal();
                if (isMounted.current) {
                    setStripe(stripeTerminal);
                    updateStripeStatus(stripeTerminal ? StripeStatus.SUCCESS_NO_NOTIF : StripeStatus.FAILED_NO_NOTIF);
                }
            }
        } catch (error) {
            if (isMounted.current) {
                updateStripeStatus(StripeStatus.FAILED_NO_NOTIF);
            }
        } finally {
            operationInProgressInit.current = false;
        }
    }

    const setStripeTerminal = async () => {
        if (stripe) {
            const newTerminal = stripe.create({
                onFetchConnectionToken: await fetchConnectionToken,
                onUnexpectedReaderDisconnect: unexpectedDisconnect,
            });
            setTerminal(newTerminal);
        }
    }

    const stripeDiscoverReaders = async () => {
        console.log('debug: exec stripeDiscoverReaders useTerminal')
        if (operationInProgressDiscover.current) {
            console.log('operationInProgressDiscover')
            return;
        }
        operationInProgressDiscover.current = true;
        console.log('debug: operationInProgress is false')

        updateTerminalStatus(isNoNotifMode ? TerminalStatus.DISCOVERING_NO_NOTIF : TerminalStatus.DISCOVERING);
        
        if (shop) {
            let config = null;
            if (shop.multiple_device) {
                config = { simulated: false };
            } else {
                config = { simulated: false, location: shop?.stripe_dashboard_term_loc_id };
            }
            //terminal?.setSimulatorConfiguration({testCardNumber: '4242424242424242'});

            try {
                const discoverResult = await terminal.discoverReaders(config);
                console.log(discoverResult)
                console.log(explicitTerminals)
                if (explicitTerminals) {
                    if (isMounted.current) {
                    
                    const filteredReaders = discoverResult.discoveredReaders.filter(reader =>
                        explicitTerminals.some(explicitTerminal => explicitTerminal.term_id === reader.id)
                      );
      
                        console.log('in explicitTerminals')
                      console.log(filteredReaders);
      
                      if (filteredReaders.length === 0) {
                        setPhysicalTerminals([]);
      
                        isNoNotifMode ? (
                          updateTerminalStatus(TerminalStatus.EMPTY_NO_NOTIF)
                        ) : (
                          updateTerminalStatus(TerminalStatus.EMPTY)
                        );
                      } else {
                        setPhysicalTerminals(filteredReaders);
                        isNoNotifMode ? (
                          updateTerminalStatus(TerminalStatus.DISCOVERED_NO_NOTIF)
                        ) : (
                          updateTerminalStatus(TerminalStatus.DISCOVERED)
                        );
      
                        isNoNotifMode ? (
                          updateTerminalStatus(TerminalStatus.CONNECTING_NO_NOTIF)
                        ) : (
                          updateTerminalStatus(TerminalStatus.CONNECTING)
                        );
      
                        if (!stopped) {
                            !shop.is_terminal_experimental ? stripeConnectReader(filteredReaders) : stripeConnectReaderExperimental(filteredReaders);
                        }
                      }
                    }
                } else {
                    console.log(discoverResult)
                    if (isMounted.current) {
                        console.log('debug: isMounted')
    
                        if (discoverResult.error) {
                            console.log('debug: error')
                            console.log(discoverResult.error)
                            setPhysicalTerminals([])
                            updateTerminalStatus(isNoNotifMode ? TerminalStatus.FAILED_NO_NOTIF : TerminalStatus.FAILED);
                        } else if (discoverResult.discoveredReaders.length === 0) {
                            console.log('debug: debug: empty')
                            setPhysicalTerminals([])
                            updateTerminalStatus(isNoNotifMode ? TerminalStatus.EMPTY_NO_NOTIF : TerminalStatus.EMPTY);
                        } else {
                            console.log('debug: discovered')
                            console.log(discoverResult.discoveredReaders)
    
                            setPhysicalTerminals(discoverResult.discoveredReaders);
                            updateTerminalStatus(isNoNotifMode ? TerminalStatus.DISCOVERED_NO_NOTIF : TerminalStatus.DISCOVERED);
                            
                            if (discoverResult.discoveredReaders[0].status === 'offline') {
                                updateTerminalStatus(isNoNotifMode ? TerminalStatus.OFFLINE_NO_NOTIF : TerminalStatus.OFFLINE);
    
                            } else {
                                updateTerminalStatus(isNoNotifMode ? TerminalStatus.ONLINE_NO_NOTIF : TerminalStatus.ONLINE);
    
                                if (!stopped) {
                                    console.log('debug: now connecting')
                                    !shop.is_terminal_experimental ? stripeConnectReader(discoverResult.discoveredReaders) : stripeConnectReaderExperimental(discoverResult.discoveredReaders);
                                }
                            }
                        }
                    }
                }
        
            } catch (error) {
                if (isMounted.current) {
                    console.log('debug: exception error')
                    console.log(error)
                    setPhysicalTerminals([])
                    updateTerminalStatus(isNoNotifMode ? TerminalStatus.FAILED_NO_NOTIF : TerminalStatus.FAILED);
                }
            } finally {
                console.log('debug: reached finally')

                operationInProgressDiscover.current = false;
            }
        }
    };
    const stripeConnectReaderExperimental = (discoveredReaders) => {
        console.log('debug: exec stripeConnectReader experiment')

        if (!shop?.server_driven_terminal) {
            console.log('debug: operationInProgressConnect: ' +  operationInProgressConnect.current)
            if (operationInProgressConnect.current) return;
            operationInProgressConnect.current = true;
            console.log('debug: connecting to reader')
            updateTerminalStatus(isNoNotifMode ? TerminalStatus.CONNECTING_NO_NOTIF : TerminalStatus.CONNECTING);
            let selectedReader = null;

            const filteredReaders = discoveredReaders.filter(reader =>
                explicitTerminals.some(explicitTerminal => explicitTerminal.term_id === reader.id)
              );

            selectedReader = filteredReaders[0]
    
            if (selectedReader.status === 'offline') {
                updateTerminalStatus(isNoNotifMode ? TerminalStatus.OFFLINE_NO_NOTIF : TerminalStatus.OFFLINE);
            } else {
                terminal.connectReader(selectedReader).then(connectResult => {
                    if (isMounted.current) {
                        if (connectResult.error) {
                            console.log('debug: error')
                            updateTerminalStatus(isNoNotifMode ? TerminalStatus.FAILED_NO_NOTIF : TerminalStatus.FAILED);
                        } else {
                            updateTerminalStatus(isNoNotifMode ? TerminalStatus.CONNECTED_NO_NOTIF : TerminalStatus.CONNECTED);
                        }
                    }
                }).catch(error => {
                    console.log(error)
                    if (isMounted.current) {
                        updateTerminalStatus(isNoNotifMode ? TerminalStatus.FAILED_NO_NOTIF : TerminalStatus.FAILED);
                    }
                }).finally(() => {
                    operationInProgressConnect.current = false;
                });
            }
            
        } else {
            if (operationInProgressConnect.current) return;
            operationInProgressConnect.current = true;
            try {
                let selectedReader = null;
                
                const filteredReaders = discoveredReaders.filter(reader =>
                    explicitTerminals.some(explicitTerminal => explicitTerminal.term_id === reader.id)
                  );
    
                selectedReader = filteredReaders[0]

                if (selectedReader.status === 'offline') {
                    updateTerminalStatus(isNoNotifMode ? TerminalStatus.OFFLINE_NO_NOTIF : TerminalStatus.OFFLINE);
                } else {
                    updateTerminalStatus(isNoNotifMode ? TerminalStatus.ONLINE_NO_NOTIF : TerminalStatus.ONLINE);
                    if (isMounted.current) {
                        updateTerminalStatus(isNoNotifMode ? TerminalStatus.CONNECTED_NO_NOTIF : TerminalStatus.CONNECTED);
                    }
                }
            } finally {
                operationInProgressConnect.current = false;
            }
        }
    }


    const stripeConnectReader = (discoveredReaders) => {
        console.log('debug: exec stripeConnectReader')
        console.log('debug: operationInProgressConnect: ' +  operationInProgressConnect.current)
        if (operationInProgressConnect.current) return;
        operationInProgressConnect.current = true;
        console.log('debug: connecting to reader')
        updateTerminalStatus(isNoNotifMode ? TerminalStatus.CONNECTING_NO_NOTIF : TerminalStatus.CONNECTING);
        let selectedReader = null;
        // shop.stripe_last_connected_reader_id gets populated when registering/paring a reader
        // so assume the value is always present
        selectedReader = discoveredReaders[0];


        terminal.connectReader(selectedReader).then(connectResult => {
            if (isMounted.current) {
                if (connectResult.error) {
                    console.log('debug: error')
                    updateTerminalStatus(isNoNotifMode ? TerminalStatus.FAILED_NO_NOTIF : TerminalStatus.FAILED);
                } else {
                    updateTerminalStatus(isNoNotifMode ? TerminalStatus.CONNECTED_NO_NOTIF : TerminalStatus.CONNECTED);
                }
            }
        }).catch(error => {
            console.log(error)
            if (isMounted.current) {
                updateTerminalStatus(isNoNotifMode ? TerminalStatus.FAILED_NO_NOTIF : TerminalStatus.FAILED);
            }
        }).finally(() => {
            operationInProgressConnect.current = false;
        });
    }

    const unexpectedDisconnect = () => {
        updateTerminalStatus(isNoNotifMode ? TerminalStatus.DISCONNECTED_NO_NOTIF : TerminalStatus.DISCONNECTED);
        setResume();
    }

    const setStop = () => {
        setStripeStatus(StripeStatus.STOPPED);
        setTerminalStatus(TerminalStatus.STOPPED);
        setStripe(null);
        setTerminal(null);
        setConnectedPhysicalTerminal(null);
    }

    async function setResume(noNotif = false) {
        reset();
        noNotif ? await stripeInitNoNotif() : await stripeInit();
    }

    return {
        connectedTerminal: terminal || null,
        discoveredTerminals: physicalTerminals,
        terminalStatus,
        stripeStatus,
        setStop,
        setResume,
        stripeDiscoverReaders: stripeDiscoverReaders
    };
};

export default useTerminal;