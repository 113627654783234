import React from 'react';
import Settings from '../../features/kiosk/Settings';


const SettingsComponent = () => {
  return (
    <div>
      <Settings />
    </div>
  );
};

export default SettingsComponent;
