import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CategoryAddModal from './CategoryAddModal';
import AddCategoryImageModal from '../modal/AddCategoryImageModal';
import CategoryItem from './CategoryItem';
import Typography from '@material-ui/core/Typography';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCategories, selectAllCategories, postCategoryItemsOrder, updateStatus } from './categorySlice';
import Skeleton from '@material-ui/lab/Skeleton';
import { io } from "socket.io-client";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  TouchSensor,
  MouseSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import Popover from '@material-ui/core/Popover';

import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers';

import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useLocation } from 'react-router-dom';

const DEFAULT_SOCKETS_URL = 'https://sockets.bigkiosksolution.com';
//const DEFAULT_SOCKETS_URL = 'http://127.0.0.1:5000';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  skeleton: {
    borderRadius: 4,
    height: 60,
    width: 800,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    marginBottom:1
  }
}));

const isActive = (category, user) => {
  // if shop type is standard return false and halt immediately
  if (user?.shop?.type == 'standard' || user?.shop?.type == 'franchiser') {
    return true;
  }

  let overrideArr = []

  if (category.overrides?.length > 0) {
    overrideArr = category.overrides?.filter(override => override.shop === user?.shop?.id);
  }

  if (overrideArr.length > 0) {
    return overrideArr[0].active;
  } else { // if no overrides have been made (default)
    // default is visible (active)
    return true
  }


  return false;
}


const Category = (props) => {
  const socketRef = useRef();

  const classes = useStyles();
  const dispatch = useDispatch();
  const categories = useSelector(selectAllCategories);
  const status = useSelector(state => state.category.status)
  const user = useSelector(state => state.auth.user)
  
  const [items, setItems] = useState([...categories.map(category => category.id)]);
  const [activeId, setActiveId] = useState(null);
  const identifier = localStorage.getItem('identifier')

  const location = useLocation()

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        tolerance: 105,
      }}),
  );

  /***************************************************************************
   * Initial Fetch
   * -------------------------------------------------------------------------
   * Fetches category items from server on mount and when a category is deleted
   ***************************************************************************/
  useEffect(() => {
    if (status === 'idle' || status === 'overidden') {
      setItems([...categories.map(category => category.id)]);
    }
  }, [status]) // if either postStatus or dispatch changes, fire!

  useEffect(() => {
    dispatch(fetchCategories());
  }, [location])

  useEffect(() => {
    if (status === 'succeeded'){
      setItems([...categories.map(category => category.id)]);
    } else if (status === 'added') {
      setItems([...categories.map(category => category.id)]);
    }
    //dispatch(fetchCategories());
  }, [categories]) // setItems on mount

  useEffect(() => {
    if (status === 'ordered' || status === 'added') {
      dispatch(postCategoryItemsOrder(items));
    }
  }, [items])

  function handleDragStart(event) {
    const {active} = event;

    setActiveId(active.id);
  }

  useEffect(() => {
    
    // Only proceed if both storedIdentifier and categoryId are not null
    if (identifier) {
      const shopId = localStorage.getItem('shop_id');
  
      // Initialize the socket connection if it hasn't been initialized yet
      if (socketRef.current == null) {
        socketRef.current = io(DEFAULT_SOCKETS_URL, {
          transports: ["websocket"], // use WebSocket first, if available
        });
      }
      const { current: socket } = socketRef;
      
      // Ensure socket is open
      socket.open();
  
      // Socket connection established
      socket.on("connect", () => {
        socket.sendBuffer = []; // Clear any buffered events
        socket.emit('join', { id: shopId });
        // kiosk has been refreshed/restarted
      });
  
      // Handle disconnection
      socket.on("disconnect", (reason) => {
        if (reason === "io server disconnect") {
          // If server initiates disconnect, manually reconnect
          socket.connect();
        }
        // Automatic reconnection for other reasons
      });
  
      // Handle connection errors and fallback to reconnect
      const handleReconnect = () => {
        socket.connect();
      };
  
      socket.on("connect_error", handleReconnect);
      socket.on("connect_failed", handleReconnect);
      socket.on('error', handleReconnect);
  
      // Handle successful reconnection
      socket.on('reconnect', () => {
        socket.sendBuffer = [];
        socket.emit('join', { id: shopId });
      });
      
    
      // Cleanup function to remove listeners
      return () => {
        socket.off('connect');
        socket.off('disconnect');
        socket.off('connect_error', handleReconnect);
        socket.off('connect_failed', handleReconnect);
        socket.off('error', handleReconnect);
        socket.close()
      };
    }
  }, []); // dependencies that cause effect to re-run
  


  function handleDragEnd(event) {
    const {active, over} = event;

    if (over && active) {
      if (active.id !== over.id) {
        setItems((items) => {
          const oldIndex = items.indexOf(active.id);
          const newIndex = items.indexOf(over.id);
          dispatch(updateStatus('ordered'))
          return arrayMove(items, oldIndex, newIndex);
        });
      }
      setActiveId(null);
    }
  }

  let categoriesDisp = '';
  // handling empty case; refactor by creating a reusable empty component
  if (items.length > 0) {
    categoriesDisp = <SortableContext
                          items={items}
                          strategy={verticalListSortingStrategy}>
                          {items.map((idx) => {
                            const index = categories.findIndex(category => category.id === idx);
                            if (index !== -1) {
                              return isActive(categories[index], user) ? (
                                <CategoryItem socket={socketRef?.current}  {...categories[index]} key={categories[index].id}  />
                              ) : (
                                null
                              )
                            }
                          })}
                      </SortableContext>
  } else if (status === 'loading') {
    categoriesDisp =
      <React.Fragment>
        <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
        <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
        <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
        <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
        <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
        <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      </React.Fragment>;
  } else if (categories.length === 0) {
    categoriesDisp = <h1>Empty Categories</h1>;
  }

  return (
    <DndContext
      modifiers={[restrictToVerticalAxis]}
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
        {categoriesDisp}
        <CategoryAddModal />
        <AddCategoryImageModal />
    </DndContext>
  );
};

export default Category;
