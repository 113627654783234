import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET, DELETE, POST, PUT, PATCH, POST_FILE } from '../../api/DashboardAPI';

export const getKiosk = createAsyncThunk('auth/getKiosk', async () => {
  const response = await GET('/dashboard/kiosk/get_kiosk/');
  return response.data; // payload
});

export const getBannerImageUrl = createAsyncThunk('auth/getBannerImageUrl', async () => {
  const response = await GET('/dashboard/shop/get_banner_img/');
  console.log(response.data)
  return response.data; // payload
});

export const getMainImageUrl = createAsyncThunk('auth/getMainImageUrl', async () => {
  const response = await GET('/dashboard/shop/get_main_img/');
  console.log(response.data)
  return response.data; // payload
});

export const kioskSlice = createSlice({
  name: 'kiosk',
  initialState: {
    kiosks: [],
    status: 'idle',
    bannerImgUrl: '',
    mainImgUrl: ''
  },
  reducers: {
    dummy: (state, action) => {

    }
  },
  extraReducers: {
    [getKiosk.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.kiosks = action.payload;
    },
    [getKiosk.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [getKiosk.pending]: (state, action) => {
      state.status = 'loading'
    },
    [getBannerImageUrl.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.bannerImgUrl = action.payload.url;
    },
    [getBannerImageUrl.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [getBannerImageUrl.pending]: (state, action) => {
      state.status = 'loading'
    },
    [getMainImageUrl.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.mainImgUrl = action.payload.url;
    },
    [getMainImageUrl.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [getMainImageUrl.pending]: (state, action) => {
      state.status = 'loading'
    },
  }
});

export const {  } = kioskSlice.actions;

export default kioskSlice.reducer;

export const selectAllKiosks = state => state.kiosk.kiosks;
