import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, TextField, Button, List, ListItem, ListItemText, IconButton, Divider } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

// Component Function
const IdentifierDialog = ({ open, onIdentifierUpdate, onClose }) => {
    const [identifier, setIdentifier] = useState('');

    useEffect(() => {
        // Load the identifier from localStorage when the dialog opens
        const storedIdentifier = localStorage.getItem('identifier') || null;
        setIdentifier(storedIdentifier);
        onIdentifierUpdate(storedIdentifier)
    }, [open]);

    // Close dialog handler
    const handleCloseIdentifier = () => {
        onClose();
    };

    // Submit handler
    const handleSubmitIdentifier = (e) => {
        e.preventDefault();
        const newIdentifier = e.target.name.value;
        if (newIdentifier) {
            setIdentifier(newIdentifier);
            localStorage.setItem('identifier', newIdentifier);
        }
        e.target.reset(); // Clear the input field
    };

    // Remove identifier handler
    const handleRemoveIdentifier = () => {
        setIdentifier('');
        localStorage.removeItem('identifier');
    };

    return (
        <Dialog
            onClose={handleCloseIdentifier}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <DialogContent style={{ minWidth: 400 }}>
                {/* Show the existing identifier if it exists */}
                {identifier && (
                    <>
                        <List dense style={{ marginBottom: 10 }}>
                            <Divider />
                            <ListItem>
                                <ListItemText primary={identifier} />
                                <IconButton onClick={handleRemoveIdentifier}>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItem>
                            <Divider />
                        </List>
                    </>
                )}

                {/* Form to enter a new identifier */}
                {!identifier && (
                    <form onSubmit={handleSubmitIdentifier}>
                        <TextField
                            style={{ marginBottom: 10 }}
                            id="identifier"
                            label="Enter Identifier"
                            type="text"
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="name"
                            inputProps={{ maxLength: 8 }} // Limit number of characters
                            helperText="Receive orders from the identified kiosk" // Optional helper text
                        />
                        <Button
                            type="submit"
                            style={{ marginBottom: 10 }}
                            fullWidth
                            variant="contained"
                            color="primary"
                            disableElevation
                        >
                            Add
                        </Button>
                    </form>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default IdentifierDialog;
