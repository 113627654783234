import React, { useEffect, useState } from 'react';
import { Dialog, Button, Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { setOpenCheckOutForm, postManualPaymentIntent, postPaymentIntent, postProcessPayment, cancelReaderAction, clearSecret, clearPaymentProcessingInfo } from './paymentProcessingSlice';
import { getOrderDetail, clearOrderDetail } from '../order/orderSlice';
import { getTerminals } from '../auth/authSlice';
import { getSurchargeFee, getSurcharge, calcTip} from '../order/utils/util-fns';
import { updatePaymentIntent } from '../paymentProcessing/paymentProcessingSlice';
import currency from 'currency.js';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import useTerminal from '../../hooks/useTerminal'
import CircularProgress from '@material-ui/core/CircularProgress';
import WarningIcon from '@material-ui/icons/Warning';
import Alert from '@material-ui/lab/Alert';

const CheckOutCardPresent = (props) => {
    // flag to notify the payment is complete regardless of successful and failure
    const user = useSelector(state => state.auth.user)
    const _terminals = useSelector(state => state.auth.terminals)
    const {
        stripeStatus,
        terminalStatus,
        discoveredTerminals,
        setStop,
    } = useTerminal(user?.shop, _terminals)
  
    const [selectedValue, setSelectedValue] = useState('');
    const stripe_connect_acct_id = useSelector(state => state.auth.user?.shop?.stripe_connect_acct_id ?? null);
    const secret = useSelector(state => state.paymentProcessing.secret)
    const [message, setMessage] = useState(null);
    const [processingPmt, setIsProcessingPmt] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [tip, setTip] = useState(0)
    const [surcharge, setSurcharge] = useState(0)

    useEffect(() => {
      dispatch(postPaymentIntent({
        from_pos: 'from pos',
        order_id: props.orderId,
        stripe_connect_acct_id: stripe_connect_acct_id,
        order_payment_id: null,
        total: currency(props.total).value,
      }));

      dispatch(getTerminals());
      return () => {
        dispatch(clearSecret())
        setStop();
        dispatch(clearOrderDetail())
        dispatch(clearPaymentProcessingInfo());

      }
    }, [])

    useEffect(() => {
      if (props.intentId) {
        console.log('intentId')
        console.log(props.itnentId)
      }
    }, [props.intentId])

    useEffect(() => {
      if (tip > 0) {
        props.onTipUpdate(tip)
      }
    }, [tip])

    useEffect(() => {
      if (selectedValue != '') {
        return () => {
          dispatch(cancelReaderAction({ reader_id: selectedValue }));
        };
      }
    }, [selectedValue]); 
        
    useEffect(() => {
      if (discoveredTerminals.length > 0) {
        setSelectedValue(discoveredTerminals[0].id); // Default to the first terminal's id
      }
    }, [discoveredTerminals]); // This effect runs whenever discoveredTerminals changes
  


  useEffect(() => {
    if (stripeStatus === 'loading') {
        setMessage('INITIALIZING PAYMENT TERMINAL')
    } else if (stripeStatus === 'initializing') {
      setMessage('DISCOVERING TERMINAL')
    }

    if (terminalStatus === 'failed' || stripeStatus === 'failed') {
      setMessage('FAILED TO CONNECT TO TERMINAL. PLEASE WAIT WHILE WE RETRY')
    } else if (terminalStatus === 'connecting') {

      setMessage('ESTABLISHING CONNECTION TO TERMINAL')
    } else if (terminalStatus === 'connected') {

      setMessage('CONNECTED TO TERMINAL!')

    } else if (terminalStatus === 'disconnected') {

      setMessage('TERMINAL DISCONNECTED. RECONNECTION IS UNDERWAY')
    } else if (terminalStatus === 'empty') {
      setMessage('TERMINAL NOT FOUND')
    } else if (terminalStatus === 'offline') {
      setMessage('TERMINAL OFFLINE')
    }
}, [terminalStatus, stripeStatus])

// Handle change event for the Select component
const handleChange = (event) => {
  const value = event.target.value;
  setSelectedValue(value);
  
  // Store the selected value in localStorage
  localStorage.setItem('lastConnectedTerminal', value);
};
    
    const handleCheckOut = () => {
      if (props.intentId) {

        dispatch(postProcessPayment({payment_intent: props.intentId, terminal_id: localStorage.getItem('lastConnectedTerminal') ? localStorage.getItem('lastConnectedTerminal') : selectedValue}));
        setIsProcessingPmt(true)
      }
    }

    const handleClose = () => {
      dispatch(cancelReaderAction({reader_id: selectedValue}));

      props.onClose();
    }

    const [loading, setIsLoading] = useState(false)
    const [promptTip, setPromptTip] = useState(true)
    const dispatch = useDispatch()

    useEffect(() => {

        if (props.socket) {
          const shopId = localStorage.getItem('shop_id')

          props.socket.on('notify-dashboard-payment-success', payload => {
            try {
              if (payload.data.shop_id == shopId) {
                props.onSuccess()
                dispatch(clearSecret())
                dispatch(clearPaymentProcessingInfo());
                dispatch(clearOrderDetail())

              }
            } catch (error) {
              console.error('Error:', error);
            }
          });
  
          props.socket.on('notify-dashboard-payment-failure', payload => {
            try {
              if (payload.data.shop_id == shopId) {
                const errorMsg = payload.data.message;
                setErrorMessage(errorMsg)
                setIsProcessingPmt(false)
              }
            } catch (error) {
              console.error('Error:', error);
            }
          });
  
        }
  
        // Clean up function
        return () => {
          if (props.socket) {
            props.socket.off('notify-dashboard-payment-success');
            props.socket.off('notify-dashboard-payment-failure');
  
          }
        };
      }, [props.socket]); // re-run the effect if socket changes

      useEffect(() => {

        // durations are in ms (1000 ms = 1 sec)
        const messageDuration = 0;
        const errorMessageDuration = 5500

        if (message) {
          setTimeout(() => {
            if (terminalStatus === 'connected') setMessage('')
          }, messageDuration)
        }

        if (errorMessage) {
          setTimeout(() => {
            setErrorMessage('')
          }, errorMessageDuration)
        }
      }, [message, errorMessage, terminalStatus])
      

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={props.open}
            onClose={handleClose}
            aria-labelledby="checkout-dialog-title"
            aria-describedby="checkout-dialog-description"
        >
          {!promptTip ? 
          <>
            <div style={{padding: 20}}>
            {message && <Alert icon={<CircularProgress style={{marginTop: 2}} size={14} thickness={4} />} style={{marginBottom: 20, fontSize: 13}} elevation={0} variant="outlined" severity="info">
            {message}
          </Alert> }
          {errorMessage  && <Alert style={{marginBottom: 20, fontSize: 13}} elevation={0} variant="outlined" severity="warning">
            {errorMessage}
          </Alert>}
            <h1 style={{textAlign: 'center', fontSize: 60, margin: 0}}>
              {currency(surcharge).format()}
            </h1>
            <FormControl size="small" fullWidth>
              <Select
                variant="outlined"
                labelId="terminal-label"
                id="terminal-open-select"
                value={localStorage.getItem('lastConnectedTerminal') ? localStorage.getItem('lastConnectedTerminal') : selectedValue}
                onChange={handleChange}
              >
                {discoveredTerminals.map(terminal => <MenuItem key={terminal.id} value={terminal.id}>[{terminal.status}] {terminal.label} {terminal.id}</MenuItem>)}
              </Select>
              {discoveredTerminals?.length > 0 && secret ? <Button disabled={discoveredTerminals?.find(reader => reader.id === selectedValue)?.status != 'online'} onClick={!processingPmt ? handleCheckOut : () => { dispatch(cancelReaderAction({reader_id: selectedValue}));setIsProcessingPmt(false)}} style={{marginTop: 10}} variant="contained" color="primary" fullWidth disableElevation>{!processingPmt ? 'CHECKOUT' : 'CANCEL'}</Button> : 
              <Button disabled style={{marginTop: 10}} variant="contained" color="primary" fullWidth disableElevation>LOADING</Button>}
            </FormControl>
            </div>
            </> : <Tip onTipSelectionComplete={(tip, surcharge) => { setTip(tip); setSurcharge(surcharge); setPromptTip(false)}} onNoTip={(total) => {setSurcharge(total);setPromptTip(false)}} total={currency(props.total).value} />}
        </Dialog>
    )
}

const Tip = (props) => {
  // props.total -- total w/o any surcharge
  const transactionFee = useSelector(state => state.auth.user.shop.transaction_fee)
  const intentId = useSelector(state => state.paymentProcessing.intentId)
  const status = useSelector(state => state.paymentProcessing.status);
  const payAsYouGo = useSelector(state => state.auth.user?.shop?.pay_as_you_go ?? false)
  const chargeCustomersFee = useSelector(state => state.auth.user?.shop?.charge_customers_fee ?? false)
  useEffect(() => {
    if (status === 'upated secret obtained') {
      props.onTipSelectionComplete(tip, getSurcharge(props.total + tip, transactionFee))
    }
  }, [status])
  const dispatch = useDispatch();
  const surcharge = getSurchargeFee(props.total, transactionFee)
  const [tip, setMyTip] = useState(0)

  function handleBtnClick(percentage) {
    const tip = chargeCustomersFee ? (
      // if shop pass on the fees to customer, calculate the tip from total+surcharge
      currency(calcTip(percentage, ((currency(props.total).add(surcharge))).value)).value
    ) : (
      currency(calcTip(percentage, currency(props.total).value)).value
    )
    setMyTip(tip)
    const surchargeTotal = getSurcharge(props.total + tip, transactionFee);
    // let's update the payment intent with a new value = total + tip
    dispatch(updatePaymentIntent({pi_id: intentId, tip: tip, total: surchargeTotal}))
    //props.onTipSelectionComplete(tip)
  }


  return (   
  <Grid container
    style={{padding: 20}}
    spacing={3}
    direction="row"
    justifyContent="flex-start"
    alignItems="center">

    <Grid item xs container>
    <Grid item>
    <div
className="raleway"
style={{
display:'flex',
flex: 1,
justifyContent: 'flex-start',
alignItems: 'center',
fontSize: 26,
fontWeight: 600,
color: '#3D3D3D',
marginBottom: 15,
marginTop: 10,
}}
>

<div style={{ position: 'relative', bottom: 6 }}>
  <div style={{margin: 0, padding: 0}}>Add a Tip?</div>
  </div>
</div>
    </Grid>
    
    <Button disabled={status === 'loading'} onClick={() => handleBtnClick(0.10)} size="large" variant="contained" style={{marginBottom:20, fontSize:30}} color="primary" fullWidth>
      {
        chargeCustomersFee ? (
          '10% (' + currency(calcTip(0.10, ((currency(props.total).add(surcharge))).value)).format() + ')'
        ) : (
          '10% (' + currency(calcTip(0.10, currency(props.total).value)).format() + ')'
        )
      }
    </Button>
    <Button disabled={status === 'loading'}  onClick={() => handleBtnClick(0.15)} size="large" variant="contained" style={{marginBottom:20, fontSize:30}} color="primary" fullWidth>
    {
      chargeCustomersFee ? (
        '15% (' + currency(calcTip(0.15, ((currency(props.total).add(surcharge))).value)).format() + ')'
      ) : (
        '15% (' + currency(calcTip(0.15, currency(props.total).value)).format() + ')'
      )
    }
    </Button>
    <Button disabled={status === 'loading'}  onClick={() => handleBtnClick(0.20)} size="large" variant="contained" style={{marginBottom:20, fontSize: 30}} color="primary" fullWidth>
    {
      chargeCustomersFee ? (
        '20% (' + currency(calcTip(0.20, ((currency(props.total).add(surcharge))).value)).format() + ')'
      ) : (
        '20% (' + currency(calcTip(0.20, currency(props.total).value)).format() + ')'
      )
    }</Button>
        <Button
        disabled={status === 'loading'} 
        fullWidth
                  onClick={
                    () => {
                      props.onNoTip(getSurcharge(props.total, transactionFee));
                    }
                  }    size="medium"
        style={{ borderRadius: 6, color: 'black', fontWeight: 800, fontSize: !props.isSurfaceKiosk ? 18 : 20, border: '1px solid #ededed'}}>
        No
      </Button>
    
    </Grid>

</Grid>)

}

export default CheckOutCardPresent;