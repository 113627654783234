import React from 'react';
import store from '../app/store';
import jwt from 'jsonwebtoken'
import { Redirect } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { AUTH_REFRESH } from '../api/DashboardAPI';
import axios from 'axios';
import { postAuthRefresh, setToken, setStatus } from '../features/auth/authSlice'
import { io } from "socket.io-client";

const DEFAULT_SOCKETS_URL = 'https://sockets.bigkiosksolution.com';
//const AUTH_REFRESH_URL = 'http://127.0.0.1:8000/api/token/refresh/';
const AUTH_REFRESH_URL = 'https://api.bigkiosksolution.com/api/token/refresh/';

let socket = null;

export const handleWebSockets = ({dispatch, getState }) => next => action => {
  let result = next(action)

  if (result.type === 'order/updateTicketStatus/fulfilled') {
      const user = getState().auth.user;
      if (!socket) {
        socket = io.connect(DEFAULT_SOCKETS_URL);

        if (user && user.shop) {
          socket.on('connect', function(data) {
            socket.sendBuffer = [];
            // join a shop's unique room, defined by shop_id, for handling kds
            socket.emit('join', {id: user.shop.id});
          });
        }
      }
      // now, tell websocket server that the item has been added
      if (user && user.shop) {
        const identifier = localStorage.getItem('identifier') || null;

        socket.on('join', function() {
          socket.emit('ticket-update-from-kiosk',  {id: user.shop.id, identifier: identifier});
        })
        socket.emit('ticket-update-from-kiosk', {id: user.shop.id, identifier: identifier});
      }
  }

  return result;
}

export const persistData = ({dispatch, getState }) => next => action => {
  let refreshExpired = false;
  let accessExpired = false;

  // handles logout
  if (action.type == "auth/clearAll") {
    localStorage.clear();
    return next(action);
  }

  // handles login
  if (action.type == "auth/postAuthLogin/fulfilled") {
      localStorage.setItem("access_token",  action.payload.access)
      localStorage.setItem("refresh_token",  action.payload.refresh)
  }

  const access_token =  localStorage.getItem('access_token');
  const refresh_token = localStorage.getItem('refresh_token');

  /*
  First check if refresh_token is null or has already expired.
  If it is, redirect to <Login />.
  */
  if (refresh_token == null || refresh_token == 'null') {
    localStorage.clear();
    return next(action);
  }

  // has refresh expired?
  jwt.verify(
    refresh_token,
    '_xg8%*z=6!t%g_rq0usjcn%$wmm_cr^fjj*#9a=lj--4*rb+5t',
    function(err, decoded) {
      if (err) {
        localStorage.clear();
        refreshExpired = true;
      }
    }
  );

  if (refreshExpired) {
    dispatch(setStatus("unauthorized"));
    localStorage.clear();
    return next(action);
  }

  // has access expired?
  jwt.verify(
    access_token,
    '_xg8%*z=6!t%g_rq0usjcn%$wmm_cr^fjj*#9a=lj--4*rb+5t',
    function(err, decoded) {
      if (err) { // it has expired let's rotate
        accessExpired = true;
      }
    }
  );

  if (accessExpired) {
    // REFACTOR BY USING DASHBOARD_API
    axios.post(AUTH_REFRESH_URL,
    {refresh: refresh_token},
    {headers: {"Content-Type": "application/json"}})
    .then(function (response) {
      localStorage.setItem("access_token",  response.data.access)
      localStorage.setItem("refresh_token",  response.data.refresh)
      dispatch(setToken({access: response.data.access, refresh: response.data.refresh}));
    });
  }

  return next(action);
}
