import React, {useState, useEffect} from 'react';
import { Button, Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { postItemImagePosition } from '../../features/item/itemSlice';
import Skeleton from '@material-ui/lab/Skeleton';

const  ClickImage = (props) => {
  const dispatch = useDispatch();
  const handleDragEnd = (e) => {
    e.preventDefault();

    setMouseDown(false);
    setDragging(false);
  }

  const [mouseDown, setMouseDown] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [offset, setOffset] = useState({
    x: props.x,
    y: props.y
  });


  const [dragStartPosition, setDragStartPosition] = useState({
    x: 0,
    y: 0
  });
  const [imageDragged, setImageDragged] = useState(false);

  const handleDrag = (e) => {
    if (mouseDown) {

      let pivotX = dragStartPosition.x;
      let pivotY = dragStartPosition.y;

      let newPosX = e.screenX - pivotX;
      let newPosY = e.screenY - pivotY;
      if (Math.abs(newPosX) > 50) {
        newPosX = newPosX < 0 ? -50 : 50
      }

      if (Math.abs(newPosY) > 30) {
        newPosY = newPosY < 0 ? -25 : 25
      }

      setOffset({
        x: newPosX,
        y: newPosY
      });

    }
  }
  useEffect(() => {
    if (Math.abs(props.x) > 0 || Math.abs(props.y) > 0) {
      setImageDragged(true);
    }
  }, [])

  useEffect(() => {
    setOffset({
      x: props.x,
      y: props.y
    })
  }, [props.x, props.y])

  useEffect(() => {
    if (mouseDown) {
      window.addEventListener('mousemove', handleDrag);
    }

    if (!mouseDown && dragging) {
      dispatch(postItemImagePosition({id: props.id, itemId: props.itemId, x: offset.x, y: offset.y}));
    }

    return () => {
      window.removeEventListener('mousemove', handleDrag);
    };

   }, [mouseDown])

   useEffect(() => {
     if (dragging) {
       window.addEventListener("mouseup", e => handleDragEnd(e));
     }

     return () => {
       window.removeEventListener("mouseup", e => handleDragEnd(e));
     };
  }, [dragging])


  // reference to the click button's image
  const imageRef = React.createRef();

  const handleDragStart = (e) => {
    e.preventDefault();
    setMouseDown(true);
    setImageDragged(true);
    setDragging(true);
    setDragStartPosition({
      x: e.screenX,
      y: e.screenY
    });
  }

  const offsetX = offset.x;
  const offsetY = offset.y;
  return (
    <div>
        <Button disableRipple={props.disableRipple} onClick={props.onClick} className="clickImage">
          <Grid container
                direction="column">
            <Grid item style={{minHeight: 110}}>
            {props.headerImg ?
              <img
                ref={imageRef}
                onMouseDown={handleDragStart}
                onMouseEnter={() => props.onMouseEnter()}
                onMouseLeave={() => props.onMouseLeave()}
                draggable="false"
                style={
                  {
                    marginBottom:10,

                    position: 'relative',
                    left: offsetX,
                    top: offsetY
                  }}
                src={props.headerImg} alt="Header Image" />
              :
              <Skeleton
                style={{margin: 'auto', borderRadius: 10}}
                variant="rect"
                width={60}
                height={95}
                animation="false" />}
            </Grid>
            <Grid item>
            <Grid container
                  direction="column"
                  alignItems="center"
                  justify="center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <path fill="#ffff" fill-opacity="1"
              d="M0,256L40,229.3C80,203,160,149,240,149.3C320,149,400,203,480,197.3C560,192,640,128,720,122.7C800,117,880,171,960,165.3C1040,160,1120,96,1200,96C1280,96,1360,160,1400,192L1440,224L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z">
              </path>
            </svg>
            <h4 style={{width: 100, position: 'relative', top: 8}}>{props.menuTitle}</h4>
            <p  style={{position: 'relative', top: 10}}>${props.menuPrice} | {props.menuCalories} Cal</p>
            </Grid>
            </Grid>

          </Grid>
        </Button>
        {imageDragged ? <Grid item>
                                      <small
                                        style={{
                                          color: 'grey',
                                          fontWeight: 400,
                                          fontSize: 9}}>
                                        CUSTOM POSITION: (
                                          {offsetX}, {offsetY}
                                        )</small>
                                        <br/>
                                        <small
                                          onClick={() => {
                                            setImageDragged(false);
                                            setOffset({x:0, y:0});
                                            dispatch(postItemImagePosition({id: props.id, itemId: props.itemId, x: 0, y: 0}))
                                          }}
                                          style={{
                                            position: 'relative',
                                            bottom: 4,
                                            cursor:'pointer',
                                            border: '1px solid grey',
                                            borderRadius: 3,
                                            padding: 2,
                                            paddingTop: 1,
                                            paddingBottom: 1,
                                            color: 'black',
                                            fontWeight: 400,
                                            fontSize: 8}}>
                                            RESET
                                          </small>
                                    </Grid>
                                    :
                                    null}
    </div>
    );
}

export default ClickImage;
