import { useSelector } from 'react-redux';
import { useEffect, useState, useRef } from 'react';

const useActionStatusTracker = (selector, asyncThunksToTrack, statusMap) => {
  const status = useSelector(selector);
  const [trackedStatus, setTrackedStatus] = useState({});
  const thunksToTrackRef = useRef(asyncThunksToTrack);


  useEffect(() => {
    thunksToTrackRef.current = asyncThunksToTrack;
  }, [asyncThunksToTrack]);

  useEffect(() => {
    const handleStatusChange = (status) => {
      thunksToTrackRef.current.forEach((asyncThunk) => {
        const expectedStatus = statusMap[asyncThunk.fulfilled.type];
        if (status === expectedStatus) {
          setTrackedStatus((prevStatus) => ({
            ...prevStatus,
            [asyncThunk.fulfilled.type]: 'fulfilled',
          }));
        }
      });
    };

    // Call the handler whenever there's a change in the status
    handleStatusChange(status);

  }, [status]);

  const reset = () => {
    setTrackedStatus({});
  };

  return { trackedStatus, reset };
};


export default useActionStatusTracker;
