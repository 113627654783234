import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { selectAllKiosks, getKiosk } from './kioskSlice';
import Skeleton from '@material-ui/lab/Skeleton';
import { useLocation } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid'
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import PermScanWifiIcon from '@material-ui/icons/PermScanWifi';
import KioskImage from '../../assets/61b29061f007f8125b1949cf_Kiosk_machine_LR.png';
import { io } from "socket.io-client";

const DEFAULT_SOCKETS_URL = 'https://sockets.bigkiosksolution.com';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    marginTop: 15
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
  root: {
    margin: '10px',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  skeleton: {
    borderRadius: 4,
    height: 400,
    width: 200,
    [theme.breakpoints.down('md')]: {
      width: 200
    },
    [theme.breakpoints.down('sm')]: {
      width: 200
    },
    marginBottom:2
  },
  media: {
    height: 390,
  },
  card: {
    padding: 20,
    paddingTop: 29
  }
}));

const Kiosk = (props) => {
  const socketRef = useRef();

  const classes = useStyles();
  const dispatch = useDispatch();
  const allItems = useSelector(selectAllKiosks);
  const status = useSelector(state => state.kiosk.status)
  const kiosks = useSelector(state => state.kiosk.kiosks)
  const location = useLocation()
  const [networks, setNetworks] = useState([]);
  const [currNetworks, setCurrNetworks] = useState([]);

  useEffect(() => {
    const shopId = localStorage.getItem('shop_id')
    
    dispatch(getKiosk());
    if (socketRef.current == null) {
       socketRef.current = io(DEFAULT_SOCKETS_URL, {
         transports: ["websocket"] // use WebSocket first, if available
       });
    }

      const {current: socket} = socketRef;
      
      socket.open();

      socket.on("connect", () => {
          socket.sendBuffer = [];
          socket.emit('join', {id: shopId});
      })

      socket.on("disconnect", (reason) => {
          if (reason === "io server disconnect") {
              // the disconnection was initiated by the server, you need to reconnect manually
              socket.connect();
          }
          // else the socket will automatically try to reconnect
      });

      socket.on("connect_error", () => {
          // revert to classic upgrade
          socket.connect();

      });

      socket.on("connect_failed", () => {
          // revert to classic upgrade
          socket.connect();
      });

      socket.on('error', function () {
          socket.connect();
      });

      socket.on('reconnect', () => {
          socket.sendBuffer = [];
          socket.emit('join', {id: shopId});
      })

    socket.on('transfer-network-info-from-kiosk', async (payload) => {
      try {
        if (payload.data.shop_id == shopId) {
          setNetworks(payload.data.networks);
          setCurrNetworks(payload.data.currNetworks);
        }
      } catch (error) {
        console.log(error)
      }
    });

    return () => {
      socket.off('transfer-network-info-from-kiosk')
      socket.off('connect')
      socket.off('disconnect')
      socket.off('connect_error')
      socket.off('connect_failed')
      socket.off('error')
      socket.off('reconnect')
      socket.close()
    }
  }, [])

  return <KioskList currNetworks={currNetworks} networks={networks} socketRef={socketRef} kiosks={kiosks} status={status} classes={classes} />
}

const KioskList = (props) => {
  const [reloading, setReloading] = useState(false);
  const [multiple, setMultiple] = useState(1);
  const [rebooting, setRebooting] = useState(false);
  const [requestingNetwkInfo, setRequestingNetwInfo] = useState(false);
  const [requestingNetwkDelete, setRequestingNetwkDelete] = useState(false);
  const [requestingNetwkConnect, setRequestingNetwkConnect] = useState(false);

  const [multiple2, setMultiple2] = useState(1);
  const kiosks = props.kiosks;
  const status = props.status;
  const classes = props.classes;

  let itemsDisp = null;


  const handleReload = () => {
    setReloading(true);
    const shopId = localStorage.getItem('shop_id')
    if (!shopId || shopId.trim() === '') {
        setReloading(false);
        return;
    }
    const {current: socket} = props.socketRef;

    socket.emit('reload-kiosk', {id: shopId});

    setTimeout(() => {
      setReloading(false)
    }, 3000 * multiple)

    setMultiple(multiple + 1)
  }



  const handleReboot = () => {
    setRebooting(true);
    const shopId = localStorage.getItem('shop_id')
    if (!shopId || shopId.trim() === '') {
        setRebooting(false);
        return;
    }
    const {current: socket} = props.socketRef;

    socket.emit('reboot-kiosk', {id: shopId});

    setTimeout(() => {
      setRebooting(false)
    }, 3000 * multiple2)

    setMultiple2(multiple2 + 1)
  }


  const requestNetworkInfo = () => {
    setRequestingNetwInfo(true);
    const shopId = localStorage.getItem('shop_id')
    if (!shopId || shopId.trim() === '') {
        setRequestingNetwInfo(false);
        return;
    }
    const {current: socket} = props.socketRef;

    socket.emit('request-network-info', {id: shopId});

    setTimeout(() => {
      setRequestingNetwInfo(false)
    }, 3000 * multiple2)

    setMultiple2(multiple2 + 1)
  }


  const requestNetworkConnect = (ssid, password) => {
    setRequestingNetwkConnect(true);
    const shopId = localStorage.getItem('shop_id')
    if (!shopId || shopId.trim() === '') {
      setRequestingNetwkConnect(false);
        return;
    }
    const {current: socket} = props.socketRef;

    socket.emit('request-network-connect', {ssid: ssid, password: password, id: shopId});

    setTimeout(() => {
      setRequestingNetwkConnect(false)
    }, 3000 * multiple2)

    setMultiple2(multiple2 + 1)
  }

  const requestNetworkDelete = (ssid) => {
    setRequestingNetwkDelete(true);
    const shopId = localStorage.getItem('shop_id')
    if (!shopId || shopId.trim() === '') {
      setRequestingNetwkDelete(false);
        return;
    }
    const {current: socket} = props.socketRef;

    socket.emit('request-network-delete', {ssid: ssid, id: shopId});

    setTimeout(() => {
      setRequestingNetwkDelete(false)
    }, 3000 * multiple2)

    setMultiple2(multiple2 + 1)
  }



  if (kiosks.length > 0) {
    itemsDisp =
      <Grid style={{maxWidth: 800}} spacing={3} justify="center" container item
            direction="row" alignItems="center">
         {kiosks?.map((kiosk, index) => {
           return (
          <Grid key={index} item md={5} xs={8}>
            <Card className={classes.card}>
              <CardActionArea>
                <CardMedia
                   className={classes.media}
                   image={KioskImage}
                   title="Kio Kiosk Image"
                 />
                <CardContent>
                  <Typography  variant="h5" component="h2">
                    {kiosk.model_number}
                  </Typography>
                  <Typography variant="body2" color="textPrimary"
                              component="p">
                    {kiosk.activated ? 'Activated' : 'Inactive'}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button variant="outlined" fullWidth  disabled={reloading} onClick={handleReload} size="small" color="primary">
                  {reloading ? 'REFRESHING ...' : 'REFRESH'}
                </Button>
                <Button variant="outlined" fullWidth  disabled={rebooting} onClick={handleReboot} size="small" color="primary">
                  {rebooting ? 'REBOOTING ...' : 'REBOOT'}
                </Button>
              </CardActions>

              <CardContent style={{padding: 8}}>
              <Button variant="outlined" fullWidth style={{marginBottom: 20}} onClick={requestNetworkInfo} disabled={requestingNetwkInfo}  size="small" color="primary">
                  <PermScanWifiIcon style={{fontSize: 17, position: 'relative', bottom: 1}} /> &nbsp;&nbsp;{requestingNetwkInfo ? 'SCANNING ...' : 'SCAN NETWORK'}
                </Button>
                <NetworkList onDelete={ssid => requestNetworkDelete(ssid)} onConnect={(ssid, password) => requestNetworkConnect(ssid, password)} currNetworks={props.currNetworks} networks={props.networks} />
              </CardContent>
            </Card>
          </Grid>
        )
      })}
    </Grid>
  } else if (status === 'loading') {
    itemsDisp =
      <Grid style={{ maxWidth: 800 }} container item spacing={3} justify="center"
            direction="row" alignItems="center">
        <Grid item>
          <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
        </Grid>
      </Grid>
  } else {
    itemsDisp = <h1>Empty Kiosks</h1>;
  }

  return itemsDisp;

}

const NetworkList = (props) => {
  const classes = useStyles();

  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [ssid, setSsid] = useState('');
  const [password, setPassword] = useState('');

  const handleConnectClick = (network) => {
    setSelectedNetwork(network);
    setSsid(network.ssid);
    setPassword('');
  };

  const handleConnectFormSubmit = (e) => {
    e.preventDefault();
    // Here, you can use the 'ssid' and 'password' state variables for connecting to the selected network.
    console.log('Connecting to', selectedNetwork.ssid);
    console.log('SSID:', ssid);
    console.log('Password:', password);
    props.onConnect(ssid, password)
    // You can add your logic to connect to the network here.
  };

  if (props.networks?.length === 1 && props.networks[0]?.error) {
    return (
      <div style={{margin: 0, padding: 0}}>
        <Card>
          <CardContent>
            <Typography variant="subtitle2">
              <small>{props.networks[0]?.error}</small>
            </Typography>
          </CardContent>
        </Card>
      </div>
    );
  }


  return (
    <div style={{margin: 0, padding: 0}}>
       
       {props.currNetworks?.map((network, index) => (
          <Card variant="outlined" key={index} style={{ marginBottom: '10px', border: '1px solid green' }}>
          <CardContent>
          <Typography variant="subtitle2">
              CONNECTED
            </Typography>
            <Typography variant="subtitle2">
              SSID: {network.ssid}
            </Typography>
            <Typography variant="body2">
              BSSID: {network.bssid}
            </Typography>
            <Typography variant="body2">
              MAC: {network.mac}
            </Typography>
            <Typography variant="body2">
              Channel: {network.channel}
            </Typography>
            <Typography variant="body2">
              Frequency: {network.frequency} MHz
            </Typography>
            <Typography variant="body2">
              Signal Level: {network.signal_level} dB
            </Typography>
            <Typography variant="body2">
              Quality: {network.quality}%
            </Typography>
            <Typography variant="body2">
              Security: {network.security}
            </Typography>
            <Typography variant="body2">
              Security Flags 1 - rsn: {network.security_flags?.rsn}
            </Typography>
            <Typography variant="body2">
              Security Flags 2 - wpa: {network.security_flags?.wpa}
            </Typography>
            <Typography variant="body2">
              Mode: {network.mode}
            </Typography>
          </CardContent>
        </Card>
       ))}
       {props.networks?.map((network, index) => (
        <Card variant="outlined" key={index} style={{ marginBottom: '10px' }}>
          <CardContent>
            <Typography variant="subtitle2">
              SSID: {network.ssid}
            </Typography>
            <Typography variant="body2">
              BSSID: {network.bssid}
            </Typography>
            <Typography variant="body2">
              MAC: {network.mac}
            </Typography>
            <Typography variant="body2">
              Channel: {network.channel}
            </Typography>
            <Typography variant="body2">
              Frequency: {network.frequency} MHz
            </Typography>
            <Typography variant="body2">
              Signal Level: {network.signal_level} dB
            </Typography>
            <Typography variant="body2">
              Quality: {network.quality}%
            </Typography>
            <Typography variant="body2">
              Security: {network.security}
            </Typography>
            <Typography variant="body2">
              Security Flags 1 - rsn: {network.security_flags?.rsn}
            </Typography>
            <Typography variant="body2">
              Security Flags 2 - wpa: {network.security_flags?.wpa}
            </Typography>
            <Typography variant="body2">
              Mode: {network.mode}
            </Typography>
            {selectedNetwork && ssid === network.ssid ? <div>
          <form onSubmit={handleConnectFormSubmit} className={classes.formContainer}>
            <TextField
              fullWidth
              size="small"
              label="SSID"
              variant="outlined"
              value={ssid}
              required
            />
            <TextField
            style={{marginTop: 5}} 
              fullWidth
              size="small"
              label="Password"
              variant="outlined"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <Button style={{marginTop: 10}} fullWidth size="small" type="submit" variant="outlined" color="default">
              Connect
            </Button>
          </form>
        </div> :  <Button
                  style={{marginTop: 15}}
                  fullWidth
                  size="small"
                  variant="outlined"
                  color="default"
                  onClick={() => handleConnectClick(network)}
                >
                  Connect
                </Button>}

                <Button style={{marginTop: 5}}  onClick={() => props.onDelete(network.ssid)} fullWidth size="small" variant="outlined" color="secondary">FORGET IF EXISTS</Button>
           
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default Kiosk;
