import React from 'react';
import Item from '../../features/item/Item';


const ItemComponent = () => {
  return (
      <Item />
  );
};

export default ItemComponent;
