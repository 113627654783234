import React, { useEffect, useState, useRef } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import useProcessRefunds from '../../hooks/useProcessRefunds'; // Import your custom hook
import useOrderIdEncryption from '../../hooks/useOrderIdEncryption';
import useCheckPaymentReceived from '../../hooks/useCheckPaymentReceived';
import useOrderNumber from '../../hooks/useOrderNumber';
import MuiAlert from '@material-ui/lab/Alert';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { sendRefundSMS, postAddPhone, sendSMS, fetchOrders, selectAllOrders, refundOrder, getOrderDetails, getOrderDetail, updateOrderStatus, refundOrderPayment, clearOrderDetail } from './orderSlice';
import { setOrderModalOpen, selectOrderModalOpen } from '../modal/modalSlice';
import {setModalOpen, setItem, setIsUpdateModal, setOrderId} from '../itemModal/itemModalSlice';
import { addCartItem, deleteAllCart } from '../cart/cartSlice';
import { getSurchargeFee, getSalesTotal } from './utils/util-fns.js';
import { io } from "socket.io-client";
import OrderModal2  from '../modal/OrderModal2.js';
import currency from 'currency.js'
import moment from 'moment';
const DEFAULT_SOCKETS_URL = 'https://sockets.bigkiosksolution.com';
//const DEFAULT_SOCKETS_URL = 'http://127.0.0.1:5000';

// serialization for EPSON thermal printer
function serialize(ticket) {
  const today = new Date();
  const switchDate = new Date(Date.UTC(2023, 8, 4, 11, 0));

  let items = [];
  let toGoStr = ticket.to_go ? '--TO GO--' : '--DINE IN--';

  ticket.order_items.map(item => {
    let _item = {
      name: item.item.name + ' ' + toGoStr,
      qty: 1,
      addedItem: [],
    };

    if (ticket.note !== '') {
      _item.qty = ticket.note + '\n\n' + '1'
    }


    let optionsDict = {};

    if (item.order_item_options) {
      item.order_item_options.map(item_option => {
        let [optionCategory, optionValue] = item_option.option_str.split(':').map(s => s.trim());
        if (optionsDict[optionCategory]) {
          optionsDict[optionCategory].push(optionValue);
        } else {
          optionsDict[optionCategory] = [optionValue];
        }
      });

      for (let category in optionsDict) {
        _item.addedItem.push(`${category}: ${optionsDict[category].join(', ')}`);
      }
    }

    for (let i = 0; i < item.quantity; i++) {
      items.push(_item);
    }
  });

  const options = {
    headerSizeDouble: true,
    itemSizeDouble: true,
    optionSizeDouble: false,
    doubleLineSections: true,
    autocutter: true,
    fullCut: false
  };

  

  return {items: items, number: calculateOrderNumber(ticket.order_number, today, switchDate), datetime: ticket.datetime, options: options};
}

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));


const useStyles2 = makeStyles((theme) => ({
  table: {
    width: 800,
    [theme.breakpoints.down('md')]: {
      width: 660
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
}));



const PhoneNumberInput = (props) => {
  const [value, setValue] = useState('');

  function formatPhoneNumber(number) {
    const cleanedNumber = ('' + number).replace(/\D/g, '');
    const match = cleanedNumber.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return null;
  }

  const handleInputChange = (event) => {
    const onlyNums = event.target.value.replace(/[^\d]/g, '');
    if (onlyNums.length < 4) {
      setValue(onlyNums);
    } else if (onlyNums.length < 7) {
      setValue(`(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`);
    } else {
      setValue(`(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`);
    }

    // Pass the unformatted value to the parent component
    props.onChange(onlyNums);
  };

  useEffect(() => {
    if (props.value) {
      setValue(formatPhoneNumber(props.value))
    }
  }, [props.value])

  return (
    <TextField
          InputLabelProps={{
        shrink: false,
      }}
      {...props}
      fullWidth
      size="large"
      type="tel"
      variant="outlined"
      value={value}
      label=""
      placeholder="(123) 456-7890"
      onChange={handleInputChange}
    />
  );
};


const TablePaginationActions = (props) => {
  const classes = useStyles1();
  const theme = useTheme();

  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

const calculateOrderNumber = (orderNumber, today, switchDate) => {
  if (today >= switchDate) {
    return (orderNumber % 999) + 1
  } else {
    return (orderNumber >= 999 ? (orderNumber % 999) - 1 : orderNumber % 999);
  }
};

const Body = (props) => {
  const today = new Date();
  const switchDate = new Date(Date.UTC(2023, 8, 4, 11, 0));


  return (
    <TableBody>
    {props.rows?.map((row) => {
      const orderNumber = calculateOrderNumber(row.order_number, today, switchDate);
      return (
        <TableRow key={row.id} onClick={() => props.onClick(row)}>
          <TableCell component="th" scope="row">
            Sales # {orderNumber}
          </TableCell>
          <TableCell>
            <span style={{ textTransform: 'capitalize' }}>{row.status}</span>
          </TableCell>
          <TableCell>
            {moment(row.datetime).format('MMMM Do YYYY, h:mm:ss a')}
          </TableCell>
          <TableCell style={{ width: 160 }}>
            {row.item_count} items
          </TableCell>
          <TableCell style={{ width: 160 }} align="right">
            {row.total ? '$' + row.total : '-'}
          </TableCell>
        </TableRow>
      );
    })}
      {!props.rows || props.rows?.count === 0 ? (
        <TableRow style={{ height: 300 }}>
          <BodySkeleton />
        </TableRow>
      ) : (null)}
    </TableBody>
  );
}

const BodySkeleton = () => {
    const [timed, setTimed] = useState(false);

    useEffect(() => {
      // timeout after 10 seconds
      setTimeout(() => {
        setTimed(true);
      }, 10000);
    }, [])

    return (
      <React.Fragment>
        {!timed ?
          <React.Fragment>
            <Skeleton variant="rect" height={45} style={{marginBottom: 1.3}} />
            <Skeleton variant="rect" height={45} style={{marginBottom: 1.3}} />
            <Skeleton variant="rect" height={45} style={{marginBottom: 1.3}} />
            <Skeleton variant="rect" height={45} style={{marginBottom: 1.3}} />
            <Skeleton variant="rect" height={45} style={{marginBottom: 1.3}} />
            <Skeleton variant="rect" height={45} style={{marginBottom: 1.3}} />
            <Skeleton variant="rect" height={45} style={{marginBottom: 1.3}} />
            <Skeleton variant="rect" height={45} style={{marginBottom: 1.3}} />
            <Skeleton variant="rect" height={45} style={{marginBottom: 1.3}} />
            <Skeleton variant="rect" height={45} style={{marginBottom: 1.3}} />
            <Skeleton variant="rect" height={45} style={{marginBottom: 1.3}} />
            <Skeleton variant="rect" height={45} style={{marginBottom: 1.3}} />
            <Skeleton variant="rect" height={45} style={{marginBottom: 1.3}} />
            <Skeleton variant="rect" height={45} style={{marginBottom: 1.3}} />
            <Skeleton variant="rect" height={45} style={{marginBottom: 1.3}} />
          </React.Fragment>
          : null}
      </React.Fragment>
  )
}

const OrderTable = (props) => {
  const socketRef = useRef();

  const orderModalOpen = useSelector(selectOrderModalOpen);

  const [open, setOpen] = useState(false)
  const classes = useStyles2();
  const rows = useSelector(selectAllOrders);
  const status = useSelector(state => state.order.status);

  const dispatch = useDispatch();

  const accessToken = localStorage.getItem('access_token');

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [orderNum, setOrderNum] = React.useState(-1);
  const [row, setRow] = React.useState(null);

  const handleClose = () => {
    setOpen(false);
    dispatch(clearOrderDetail())
    dispatch(setOrderId(-1))

  }

  let emptyRows = rowsPerPage || 0;

  const handleChangePage = (event, newPage) => {
   setPage(newPage);
  };


  useEffect(() => {
    dispatch(fetchOrders(page + 1));
  }, [accessToken]);

  useEffect(() => {
    if (status !== 'idle') {
      dispatch(fetchOrders(page + 1));
    }
  }, [page]);

  const handleChangeRowsPerPage = (event) => {
   dispatch(fetchOrders(event.target.value));
   setRowsPerPage(parseInt(event.target.value, 10));
   setPage(0);
  };

  const handleClick = (row) => {
    setOpen(true)
    setOrderNum(row.order_number)
    setRow(row);
  }

  useEffect(() => {
    if (status === 'order updated') {
      handleUpdate()
    } else if (status === 'order status updated') {
      handleAdd(rows?.results[0].id)
    } else if (status === 'order status updated no add') {
      handleUpdate()
    }
  }, [status])

  // Add a useEffect hook to update the 'row' state when 'selectAllOrders' updates
    useEffect(() => {
      // Make sure 'rows' is an array before using the 'find' method
      if (Array.isArray(rows.results)) {
        // Find the row with the matching id from the 'selectAllOrders' array
        const matchedRow = rows?.results?.find(order => order.id === row?.id);
        if (matchedRow) {
          setRow(matchedRow);
        }
      }
    }, [rows?.results]);

  const handleAdd = (orderId = null) => {
    if (socketRef.current == null) {
      socketRef.current = io(DEFAULT_SOCKETS_URL, {
        transports: ["websocket"] // use WebSocket first, if available
      });
    }

    const {current: socket} = socketRef;
    try {
      const identifier = localStorage.getItem('identifier') || null;
      socket.emit('ticket-added',  {id: orderId ? orderId : row?.id, identifier: identifier, shop_id: localStorage.getItem('shop_id')});
      console.log('ticket-added ' + localStorage.getItem('shop_id'));
      socket.emit('table-update-from-kiosk', {id: localStorage.getItem('shop_id')});
    } catch (error) {
      console.log(error);
    }

  }

  const handleUpdate = () => {
    if (socketRef.current == null) {
      socketRef.current = io(DEFAULT_SOCKETS_URL, {
        transports: ["websocket"] // use WebSocket first, if available
      });
    }

    const {current: socket} = socketRef;

    try {
      const identifier = localStorage.getItem('identifier') || null;
      socket.emit('ticket-update-from-dashboard',  {id: row?.id, identifier: identifier, shop_id: localStorage.getItem('shop_id')});
      console.log('ticket-update-from-dashboard ' + localStorage.getItem('shop_id'));
      socket.emit('table-update-from-kiosk', {id: localStorage.getItem('shop_id')});
    } catch (error) {
      console.log(error);
    }

  }

  useEffect(() => {

  if (socketRef.current == null) {
     socketRef.current = io(DEFAULT_SOCKETS_URL, {
       transports: ["websocket"] // use WebSocket first, if available
     });
   }

  const {current: socket} = socketRef;

  try {

    socket.on("connect", () => {
      socket.sendBuffer = [];
      socket.emit('join', {id: localStorage.getItem('shop_id')});
    })
    socket.io.on("connect_error", () => {
      socket.connect();

    });

    socket.on("connect_failed", () => {
      socket.connect();

    });

    socket.on('reconnect', () => {
      socket.sendBuffer = [];
      console.log('reconnected')
      socket.emit('join', {id: localStorage.getItem('shop_id')});
      console.log('rejoined')
    })

  } catch (error) {
    console.log(error);
  }
  // Return a callback to be run before unmount-ing.
  return () => {
    socket.off('connect')
    socket.off('connect_error')
    socket.off('connect_failed')
    socket.off('reconnect')

  };

}, [])

  return (
    <React.Fragment>
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="custom pagination table">
        {status !== 'loading' && rows ?
        // display the body
        <Body onClick={(row) => handleClick(row)} page={page} rows={rows?.results} rowsPerPage={rowsPerPage} emptyRows={emptyRows} /> :
        // display skeleton if not ready
        <BodySkeleton />}
        <TableFooter>
          <TableRow>
            <TablePagination
              colSpan={6}
              count={rows?.count || 0}
              rowsPerPage={15}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={handleChangePage}
              rowsPerPageOptions={[]}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
    <TabelItemDialog page={page} item={row} {...row} orderNum={orderNum}  open={open} handleClose={handleClose} />
    {orderModalOpen && <OrderModal2 socket={socketRef.current} />}
    </React.Fragment>
    );
};


const SplitPaymentRefundInput = (props) => {
  const dispatch = useDispatch()
  const [status, setStatus] = useState('idle'); // initiate status as 'idle'
  const _status = useSelector(state => state.order.status);
  const refundValue = props.charges?.data[0]?.amount_captured - props.charges?.data[0]?.amount_refunded
  const [value, setValue] = useState(refundValue/100);

  function handleSubmit(e) {
    e.preventDefault()
    setStatus('refunding'); // Set the status to 'refunding' when starting the refund operation
    // passing stripe_payment_intent_id
    dispatch(refundOrderPayment({id: props.id, amount: value * 100})).then(() => {
      setTimeout(() => {
        setStatus('idle'); // Reset the status once the operation is complete
      }, 1200)
    })

  }


  useEffect(() => {
    const refundValue = props.charges?.data[0]?.amount_captured - props.charges?.data[0]?.amount_refunded
    setValue(refundValue / 100)
  }, [props.charges?.data[0]?.amount_captured, props.charges?.data[0]?.amount_refunded])


  const onChange = (e) => {
    setValue(e.target.value)
  }

  return (
    <form  onSubmit={handleSubmit}>
    <h4 style={{marginTop:0, marginBottom: 5}}>Refund Amount <small>{props.charges?.data[0]?.payment_method_details?.card_present?.network} {props.charges?.data[0]?.payment_method_details?.card_present?.last4}</small><br/> <small style={{fontWeight: 400}}>Including tax, transaction fees, and tips</small></h4>
    <Grid container  direction="column">
    <Grid item xs>
    {status === 'refunding' ? (
      <TextField  disabled fullWidth value={"Refunding ..."} variant="outlined" />
    ) : (
      <TextField disabled={(props.charges?.data[0]?.amount_captured / 100) - (props.charges?.data[0]?.amount_refunded / 100) === 0 ? true : false} name="amount" onChange={onChange} type="number" min="0.00" max="10000.00" step="0.01"  fullWidth value={value} variant="outlined" />
    )}
    </Grid>
    <Grid item xs>
    {status === 'refunding'  ? (
      <Button style={{float: 'right'}} disabled >Refunding</Button>
    ) : (
      <Button  disabled={(props.charges?.data[0]?.amount_captured / 100) - (props.charges?.data[0]?.amount_refunded / 100) === 0 ? true : false}  type="submit" style={{float: 'right'}}>Refund</Button>
    ) }
    </Grid>
    </Grid>

    </form>
  )
}

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const RefundSplittedEvenlyItems = (props) => {
  const [selected, setSelected] = React.useState([]);
  const status = useSelector(state => state.order.status);
  const { refundStatus, processRefund } = useProcessRefunds();

  const refundSpecificItemToTender = async (intentId) => {
    try {
      const orderId = props.id; // Replace with your actual order ID
      const refundType = 'refundByItemsSpecificTender';
      const options = {
        intent: intentId,
        orderItemIds: selected, // Replace with the specific order item ID
      };

      // Call the processRefund function with the refundType, orderId, and options
      await processRefund(refundType, orderId, options);

      // Refund was successful, you can update the UI or show a success message
    } catch (error) {
      // Handle refund error, you can update the UI or show an error message
      console.error('Refund error:', error);
    } finally {
      setSelected([]);

    }
  };


  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };



  const handleSelectAllClick2 = (event) => {
    if (event.target.checked) {
      const newSelecteds = props.order_items.filter((n) => n.refunded_amount !== n.amount).map(item => item.id)
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <>
    <h4 style={{marginBottom: 5}}>Refund Items

    {props.details.map(detail => (
      <small style={{marginLeft: 5}}>
        {detail?.charges?.data[0]?.payment_method_details?.card_present.network}
        &nbsp;
        {detail?.charges?.data[0]?.payment_method_details?.card_present.last4}
      </small>
    ))}
    <br/>
    <small style={{fontWeight: 400}}>Including transaction fees and tips</small></h4>
      <TableContainer component={Paper}>
      <Table
          size="medium"
          aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
               indeterminate={selected?.length > 0 && selected?.length < props?.order_items?.length}
               checked={props?.order_items?.length > 0 && selected?.length === props?.order_items?.length}
               onChange={handleSelectAllClick2}
             />
          </TableCell>
          <TableCell>Item</TableCell>
          <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.order_items?.map((item) => {
            const isItemSelected = isSelected(item.id);

            return (
              currency(item.get_total) - item.refunded_amount === 0 ? (
                <TableRow
                  hover
                  tabIndex={-1}
                  role="checkbox"
                  selected={isItemSelected}
                  key={item.id}>
                <TableCell  padding="checkbox">
                    <Checkbox disabled/>
                </TableCell>
                  <TableCell component="th" scope="row">
                  <Chip
                     size="small"
                     label="Refunded"
                     color="secondary"
                    />
                    <span style={{marginLeft: 10}}>{item.name}</span>
                  </TableCell>
                  <TableCell align="right">{currency(item.get_total - item.refunded_amount).format()}</TableCell>
                </TableRow>
              ) : (
                <TableRow
                  hover
                  tabIndex={-1}
                  role="checkbox"
                  selected={isItemSelected}
                  key={item.id}
                  onClick={(event) => handleClick(event, item.id)}>
                <TableCell  padding="checkbox">
                    <Checkbox checked={isItemSelected} />
                </TableCell>
                  <TableCell component="th" scope="row">
                    {item.name}
                  </TableCell>
                  <TableCell align="right">{currency(item.get_total - item.refunded_amount).format()}</TableCell>
                </TableRow>
              )
          )})}
        </TableBody>
      </Table>
    </TableContainer>
    {status === 'refunding' || status === 'detail loading' ? (
      <Button disabled style={{marginTop: 10, float: 'right'}}>Refunding...</Button>
    ) : (
      <Button disabled={selected.length === 0} onClick={() => refundSpecificItemToTender(props.details[0].id)} style={{marginTop: 10, float: 'right'}}>Refund</Button>
    )}
    </>
  )
}

const RefundItemsPerPayment = (props) => {
  const [selected, setSelected] = React.useState([]);
  const status = useSelector(state => state.order.status);
  const { refundStatus, processRefund } = useProcessRefunds();

  const refundSpecificItemToTender = async (intentId) => {
    try {
      const orderId = props.id; // Replace with your actual order ID
      const refundType = 'refundByItemsSpecificTender';
      const options = {
        intent: intentId,
        orderItemIds: selected, // Replace with the specific order item ID
      };

      // Call the processRefund function with the refundType, orderId, and options
      await processRefund(refundType, orderId, options);

      // Refund was successful, you can update the UI or show a success message
    } catch (error) {
      // Handle refund error, you can update the UI or show an error message
      console.error('Refund error:', error);
    } finally {
      setSelected([]);

    }
  };


  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };



  const handleSelectAllClick2 = (event) => {
    if (event.target.checked) {
      const newSelecteds = props.payment.order_items2.filter((n) => n.refunded_amount !== n.amount).map(item => item.id)
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return props.orderType === 'n_ways' ? (
    <>
    <h4 style={{marginBottom: 5}}>Refund Items  <small>{props.detail?.charges?.data[0]?.payment_method_details?.card_present.network} {props.detail?.charges?.data[0]?.payment_method_details?.card_present.last4}</small><br/> <small style={{fontWeight: 400}}>Including transaction fees and tips</small></h4>
      <TableContainer component={Paper}>
      <Table
          size="medium"
          aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
               indeterminate={selected?.length > 0 && selected?.length < props.payment?.order_items2?.length}
               checked={props.payment?.order_items2?.length > 0 && selected?.length === props.payment?.order_items2?.length}
               onChange={handleSelectAllClick2}
             />
          </TableCell>
          <TableCell>Item</TableCell>
          <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.order_items?.map((item) => {
            const isItemSelected = isSelected(item.id);

            return (
              currency(item.get_total) - item.refunded_amount === 0 ? (
                <TableRow
                  hover
                  tabIndex={-1}
                  role="checkbox"
                  selected={isItemSelected}
                  key={item.id}>
                <TableCell  padding="checkbox">
                    <Checkbox disabled/>
                </TableCell>
                  <TableCell component="th" scope="row">
                  <Chip
                     size="small"
                     label="Refunded"
                     color="secondary"
                    />
                    <span style={{marginLeft: 10}}>{item.name}</span>
                  </TableCell>
                  <TableCell align="right">{currency(item.get_total - item.refunded_amount).divide(2).format()}</TableCell>
                </TableRow>
              ) : (
                <TableRow
                  hover
                  tabIndex={-1}
                  role="checkbox"
                  selected={isItemSelected}
                  key={item.id}
                  onClick={(event) => handleClick(event, item.id)}>
                <TableCell  padding="checkbox">
                    <Checkbox checked={isItemSelected} />
                </TableCell>
                  <TableCell component="th" scope="row">
                    {item.name}
                  </TableCell>
                  <TableCell align="right">{currency(item.get_total - item.refunded_amount).divide(2).format()}</TableCell>
                </TableRow>
              )
          )})}
        </TableBody>
      </Table>
    </TableContainer>
    {status === 'refunding' || status === 'detail loading' ? (
      <Button disabled style={{marginTop: 10, float: 'right'}}>Refunding...</Button>
    ) : (
      <Button disabled={selected.length === 0} onClick={() => refundSpecificItemToTender(props.detail?.id)} style={{marginTop: 10, float: 'right'}}>Refund</Button>
    )}
    </>
  ) : (
    <>
    <h4 style={{marginBottom: 5}}>Refund Items  <small>{props.detail?.charges?.data[0]?.payment_method_details?.card_present.network} {props.detail?.charges?.data[0]?.payment_method_details?.card_present.last4}</small><br/> <small style={{fontWeight: 400}}>Including transaction fees and tips</small></h4>
      <TableContainer component={Paper}>
      <Table
          size="medium"
          aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
               indeterminate={selected?.length > 0 && selected?.length < props.payment?.order_items2?.length}
               checked={props.payment?.order_items2?.length > 0 && selected?.length === props.payment?.order_items2?.length}
               onChange={handleSelectAllClick2}
             />
          </TableCell>
          <TableCell>Item</TableCell>
          <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.payment.order_items2?.map((item) => {
            const isItemSelected = isSelected(item.id);

            return (
              currency(item.get_total) - item.refunded_amount === 0 ? (
                <TableRow
                  hover
                  tabIndex={-1}
                  role="checkbox"
                  selected={isItemSelected}
                  key={item.id}>
                <TableCell  padding="checkbox">
                    <Checkbox disabled/>
                </TableCell>
                  <TableCell component="th" scope="row">
                  <Chip
                     size="small"
                     label="Refunded"
                     color="secondary"
                    />
                    <span style={{marginLeft: 10}}>{item.name}</span>
                  </TableCell>
                  <TableCell align="right">{currency(item.get_total - item.refunded_amount).format()}</TableCell>
                </TableRow>
              ) : (
                <TableRow
                  hover
                  tabIndex={-1}
                  role="checkbox"
                  selected={isItemSelected}
                  key={item.id}
                  onClick={(event) => handleClick(event, item.id)}>
                <TableCell  padding="checkbox">
                    <Checkbox checked={isItemSelected} />
                </TableCell>
                  <TableCell component="th" scope="row">
                    {item.name}
                  </TableCell>
                  <TableCell align="right">{currency(item.get_total - item.refunded_amount).format()}</TableCell>
                </TableRow>
              )
          )})}
        </TableBody>
      </Table>
    </TableContainer>
    {status === 'refunding' || status === 'detail loading' ? (
      <Button disabled style={{marginTop: 10, float: 'right'}}>Refunding...</Button>
    ) : (
      <Button disabled={selected.length === 0} onClick={() => refundSpecificItemToTender(props.detail?.id)} style={{marginTop: 10, float: 'right'}}>Refund</Button>
    )}
    </>
  )
}

const RefundItemsContent = (props) => {
  const [selected, setSelected] = React.useState([]);
  const orderDetailArr = useSelector(state => state.order.orderDetailArr);
  const status = useSelector(state => state.order.status);
  const { refundStatus, processRefund } = useProcessRefunds();
  const refundSpecificItemToTender = async (intentId) => {
    try {
      const orderId = props.id; // Replace with your actual order ID
      const refundType = 'refundByItemsSpecificTender';
      const options = {
        intent: intentId,
        orderItemIds: selected, // Replace with the specific order item ID
      };

      // Call the processRefund function with the refundType, orderId, and options
      await processRefund(refundType, orderId, options);

      // Refund was successful, you can update the UI or show a success message
    } catch (error) {
      // Handle refund error, you can update the UI or show an error message
      console.error('Refund error:', error);
    } finally {
      setSelected([]);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = props.order_items.filter((n) => n.refunded_amount !== n.amount).map(item => item.id)
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };



  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  useEffect(() => {
    if (status === 'refunded') {
      setSelected([])
    }
  }, [status])


  const isSelected = (id) => selected.indexOf(id) !== -1;

  if (props.type == 'n_ways') {
    return (
      <RefundSplittedEvenlyItems order_items={props.order_items} id={props.id} details={orderDetailArr}  />
    )
  } else {
    return orderDetailArr.length === 0 ? (
      <>
      <h4 style={{marginBottom: 5}}>Refund Items  <small>{props.orderDetail?.charges?.data[0]?.payment_method_details?.card_present?.network} {props.orderDetail?.charges?.data[0]?.payment_method_details?.card_present?.last4}</small><br/> <small style={{fontWeight: 400}}>Including transaction fees and tips</small></h4>
        <TableContainer component={Paper}>
        <Table
            size="medium"
            aria-label="simple table">
          <TableHead>
            <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={selected?.length > 0 && selected?.length < props.order_items?.length}
                 checked={props.order_items?.length > 0 && selected?.length === props.order_items?.length}
                 onChange={handleSelectAllClick}
               />
            </TableCell>
            <TableCell>Item</TableCell>
            <TableCell align="right">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.order_items.map((item) => {
              const isItemSelected = isSelected(item.id);
              return (
                currency(item.get_total) - item.refunded_amount === 0 ? (
                  <TableRow
                    hover
                    tabIndex={-1}
                    role="checkbox"
                    selected={isItemSelected}
                    key={item.id}>
                  <TableCell  padding="checkbox">
                      <Checkbox disabled/>
                  </TableCell>
                    <TableCell component="th" scope="row">
                    <Chip
                       size="small"
                       label="Refunded"
                       color="secondary"
                      />
                      <span style={{marginLeft: 10}}>{item.name}</span>
                    </TableCell>
                    <TableCell align="right">{currency(item.get_total - item.refunded_amount).format()}</TableCell>
                  </TableRow>
                ) : (
                  <TableRow
                    hover
                    tabIndex={-1}
                    role="checkbox"
                    selected={isItemSelected}
                    key={item.id}
                    onClick={(event) => handleClick(event, item.id)}>
                  <TableCell  padding="checkbox">
                      <Checkbox checked={isItemSelected} />
                  </TableCell>
                    <TableCell component="th" scope="row">
                      {item.name}
                    </TableCell>
                    <TableCell align="right">{currency(item.get_total - item.refunded_amount).format()}</TableCell>
                  </TableRow>
                )
            )})}
          </TableBody>
        </Table>
      </TableContainer>
      {status === 'refunding' || status === 'detail loading' ? (
        <Button disabled style={{marginTop: 10, float: 'right'}}>Refunding...</Button>
      ) : (
        <Button disabled={selected.length === 0} onClick={() => refundSpecificItemToTender(props.orderDetail?.id)} style={{marginTop: 10, float: 'right'}}>Refund</Button>
      )}
      </>
    ) : (
      props.payments?.map(payment => {
        const detail = orderDetailArr.find(detail => detail.id === payment.stripe_payment_intent)
        return <><RefundItemsPerPayment order_items={props.order_items} orderType={props.type} key={payment.id} id={props.id} payment={payment} detail={detail} /><br /></>
      }) || null
    )
  }

  // Add this line:
   return null;
}

/* Receipt */
const ReceiptDialogContent = (props) => {
  const dispatch = useDispatch();
  const [receiptType, setReceiptType] = useState('normal');
  const [phoneNumber, setPhoneNumber] = useState(props.phone ?? '');
  const status = useSelector(state => state.order.status);
  const encryptOrderId = useOrderIdEncryption('iloveyoumigak');  // Replace with your secret key
  const order = props.item;

  const { refundExits } = useCheckPaymentReceived(order);

  const today = new Date();
  const switchDate = new Date(Date.UTC(2023, 8, 4, 11, 0));

  function unformatPhoneNumber(formattedNumber) {
    if (formattedNumber.length == 10) {
      const cleanedNumber = ('' + formattedNumber).replace(/\D/g, '');
      return cleanedNumber;
    }
  }

   const handleSendSms = () => {
     let isDigit = /^\d+$/.test(phoneNumber);
     let isTenDigit = phoneNumber.length == 10

     if (isDigit && isTenDigit) {

         dispatch(postAddPhone({order_id: props.id, phone: phoneNumber}))
         dispatch(sendSMS({encrypted_id: encryptOrderId(props.id), number: calculateOrderNumber(props.orderNum, today, switchDate), phone: phoneNumber}))
     }
   }


      const handleSendRefundSms = () => {
        let isDigit = /^\d+$/.test(phoneNumber);
        let isTenDigit = phoneNumber.length == 10

        if (isDigit && isTenDigit) {

            dispatch(postAddPhone({order_id: props.id, phone: phoneNumber}))
            dispatch(sendRefundSMS({encrypted_id: encryptOrderId(props.id), number: calculateOrderNumber(props.orderNum, today, switchDate), phone: phoneNumber}))
        }
      }


  return (
    <div style={{ padding: 30, paddingTop: 10, background: 'white' }}>
      <h3><Button onClick={() => props.onSectionUpdate('Main')} variant="outlined" style={{ marginRight: 5 }}><KeyboardArrowLeft /></Button> Receipt # {calculateOrderNumber(props.orderNum, today, switchDate)}</h3>
      <hr />
      <ButtonGroup style={{ marginTop: 10 }} disableElevation fullWidth variant="outlined">
        <Button onClick={() => setReceiptType('normal')} variant="outlined" color={receiptType === 'normal' ? 'primary' : 'default'}>Sales Receipt</Button>
        <Button disabled={!refundExits} onClick={() => setReceiptType('refund')} color={receiptType === 'refund' ? 'primary' : 'default'}>Refund Receipt</Button>
      </ButtonGroup>
      {receiptType === 'normal' ? (
        <>
          <h4 style={{marginBottom: 5}}>Send Receipt</h4>
          <Grid container  direction="column">
            <Grid item xs>
            <PhoneNumberInput
                      label="Phone Number"
                      value={phoneNumber}
                      onChange={(value) => setPhoneNumber(value)}
                    />
            </Grid>
            <Grid item xs>
              {status === 'sms sending' ? (
                <Button disabled style={{float: 'right'}}>Sending...</Button>
              ) : (
                <Button disabled={phoneNumber.length < 10} style={{float: 'right'}} onClick={handleSendSms}>Send</Button>
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <h4 style={{marginBottom: 5}}>Send Refund Receipt</h4>
          <Grid container  direction="column">
            <Grid item xs>
            <PhoneNumberInput
                      label="Phone Number"
                      value={phoneNumber}
                      onChange={(value) => setPhoneNumber(value)}
                    />
            </Grid>
            <Grid item xs>
              {status === 'sms sending' ? (
                <Button disabled style={{float: 'right'}}>Sending...</Button>
              ) : (
                <Button disabled={phoneNumber.length < 10} style={{float: 'right'}} onClick={handleSendRefundSms}>Send</Button>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </div>
  )
}
/* Refund */
const RefundDialogContent = (props) => {
    const dispatch = useDispatch();
    const orderDetail = useSelector(state => state.order.orderDetail);
    const orderDetailArr = useSelector(state => state.order.orderDetailArr);
    const orderStatus = useSelector(state => state.order.status);
    const refundValue = orderDetail?.charges?.data[0]?.amount_captured - orderDetail?.charges?.data[0]?.amount_refunded
    const [isLoading, setIsLoading] = useState(false);

    const [value, setValue] = useState(refundValue / 100);
    const [errorMsg, setErrorMsg] = useState("");
    const [refundType, setRefundType] = useState(props.item?.discount > 0 ? "by_amount" : "by_items");

    function onChange(e) {
      setValue(e.target.value)
    }

    function handleSubmit(e) {
      e.preventDefault()
      setIsLoading(true)
      dispatch(refundOrder({id: props.id, amount: value * 100})).then(() => {
        setTimeout(() => {
          setIsLoading(false)
        }, 1200)
      })

    }

    useEffect(() => {
      const refundValue = orderDetail?.charges?.data[0]?.amount_captured - orderDetail?.charges?.data[0]?.amount_refunded
      setValue(refundValue / 100)
    }, [orderDetail?.charges?.data[0]?.amount_captured, orderDetail?.charges?.data[0]?.amount_refunded])

    const today = new Date();
    const switchDate = new Date(Date.UTC(2023, 8, 4, 11, 0));


    return (
     <div style={{ padding: 30, paddingTop: 10, background: 'white' }}>
       <h3><Button onClick={() => props.onSectionUpdate('Main')} variant="outlined" style={{ marginRight: 5 }}><KeyboardArrowLeft /></Button> Refund Sales # {calculateOrderNumber(props.orderNum, today, switchDate)}</h3>
       <hr />
       <ButtonGroup style={{ marginTop: 10 }} disableElevation fullWidth variant="outlined">
         <Button disabled={props.item?.discount > 0} onClick={() => setRefundType('by_items')} variant="outlined" color={refundType === 'by_items' ? 'primary' : 'default'}>Items</Button>
         <Button onClick={() => setRefundType('by_amount')} color={refundType === 'by_amount' ? 'primary' : 'default'}>Amount</Button>
       </ButtonGroup>
       {refundType === 'by_amount' ? (
         <div style={{marginTop: 10}}>
         {props.stripe_payment_intent && orderDetailArr?.length === 0 ?  (
           <form onSubmit={handleSubmit}>
           <h4 style={{marginBottom: 5}}>Refund Amount&nbsp;
           {orderDetail?.charges?.data[0]?.payment_method_details?.card ? <><small>{orderDetail?.charges?.data[0]?.payment_method_details?.card?.network} {orderDetail?.charges?.data[0]?.payment_method_details?.card?.last4}</small><br/> <small style={{fontWeight: 400}}>Including transaction fees and tips</small></> : null}
           {orderDetail?.charges?.data[0]?.payment_method_details?.card_present ? <><small>{orderDetail?.charges?.data[0]?.payment_method_details?.card_present?.network} {orderDetail?.charges?.data[0]?.payment_method_details?.card_present?.last4}</small><br/> <small style={{fontWeight: 400}}>Including transaction fees and tips</small></> : null}</h4>
           {orderStatus === 'refund failed' ? (
             <p style={{color: 'orange', marginBottom: 10, marginTop: 0}}>Refund failed. Please try again.</p>
           ) : (
             null
           )}
           <Grid container  direction="column">
           <Grid item xs>
           {isLoading ? (
             <TextField size="large" disabled fullWidth value={"Loading ..."} variant="outlined" />
           ) : (
             <TextField size="large" disabled={(orderDetail?.charges?.data[0]?.amount_captured / 100) - (orderDetail?.charges?.data[0]?.amount_refunded / 100) === 0 ? true : false} name="amount" onChange={onChange} type="number" min="0.00" max="10000.00" step="0.01"  fullWidth value={value} variant="outlined" />
           )}
           </Grid>
           <Grid item xs>
           {isLoading ? (
             <Button disabled style={{float: 'right'}}>Refunding...</Button>
           ) : (
             <Button style={{float: 'right'}} disabled={(orderDetail?.charges?.data[0]?.amount_captured / 100) - (orderDetail?.charges?.data[0]?.amount_refunded / 100) === 0 ? true : false} type="submit" >Refund</Button>
           ) }
           </Grid>
           </Grid>
           </form>
         ) : null}
         {orderDetailArr?.map(detail => <SplitPaymentRefundInput key={detail.id} {...detail} />)}
         </div>
       ) : (
           <RefundItemsContent {...props} orderDetail={orderDetail} />
       )}
     </div>
   );
}

const MainContentDialog = (props) => {
  const socketRef = useRef();
  const [phoneNumber, setPhoneNumber] = useState('');
  const transactionFee = props.fee_rate_at_order
  const chargeCustomersFee = useSelector(state => state.auth.user?.shop?.charge_customers_fee || false)
  const payAsYouGo = useSelector(state => state.auth.user?.shop?.pay_as_you_go || false)
  const total = props.discount > 0 ? currency(currency(props.total).add(props.tip).subtract(props.discount)).value : currency(currency(props.total).add(props.tip)).value
  const transactionFeeOutput = parseFloat(transactionFee ?? 0) > 0 ? total > 0 ? getSurchargeFee(total, transactionFee) : 0 : 0;


  const [fee, setFee] = useState(0)

  const buildAddedItems = (order) => {
    /*

    */

    let addedItem = [];

    order.order_item_options?.map(option => {
      addedItem.push(option)
    })
    return addedItem;
  }
  const orderDetail = useSelector(state => state.order.orderDetail);

  const refundValue = orderDetail?.charges?.data[0]?.amount_captured - orderDetail?.charges?.data[0]?.amount_refunded



  const handlePrint = (payload) => {
    if (socketRef.current == null) {
      socketRef.current = io(DEFAULT_SOCKETS_URL, {
        transports: ["websocket"] // use WebSocket first, if available
      });
    }

    const {current: socket} = socketRef;

    try {
      socket.emit('print', {id: localStorage.getItem('shop_id'), ticket: payload});
    } catch (error) {
      console.log(error);
    }
  }

  function handleUpdateOrder() {
    dispatch(setIsUpdateModal(true))
    dispatch(deleteAllCart())
    let cart;
    let items = []
    let itemObj = {cartItem: {quantity: 1, order_item_id: null, name: null, unit_price: 0}, modalItemData: {item: null, priceSummary: {amount: 0, discount: 0, qty: 1}}}
    props.order_items.map(item => {

      const addedItem = buildAddedItems(item)
      let itemCopy = JSON.parse(JSON.stringify(item.item));
      addedItem.map(_item => {
        if (!itemCopy.hasOwnProperty('groups')) {
          itemCopy.groups = []
        }

        const index = itemCopy.groups?.findIndex(group => group.option.id === _item.option_id)
        if (index !== -1) {
          if (itemCopy.hasOwnProperty('groups') || !itemCopy.groups[index].addedItem) {
            itemCopy.groups[index].addedItem = []
          }
          itemCopy.groups[index].addedItem.push(_item)
        }
      })

      itemObj = {cartItem: {quantity: 1, name: null, unit_price: 0}, modalItemData: {item: null, priceSummary: {amount: 0, discount: 0, qty: 1}}}
      itemObj.cartItem.quantity = item.quantity;
      itemObj.cartItem.order_item_id = item.id;
      itemObj.cartItem.name = item.item.name;
      itemObj.cartItem.unit_price = item.unit_price;
      itemObj.modalItemData.item = itemCopy;
      itemObj.modalItemData.priceSummary = {amount: 0, discount: 0, qty: 1};
      dispatch(addCartItem({
        cartItem: itemObj.cartItem,
        modalItemData: itemObj.modalItemData
      }));
    })
    dispatch(setOrderModalOpen(true))
    // update order status without noAdd: true updates and adds another ticket in KDS
  }

  const calcFee = () => {

  }

  function print() {
    const payload = serialize(props);
    handlePrint(payload)
  }

  useEffect(() => {
    if (props.id) {
      dispatch(setOrderId(props.id))
      dispatch(getOrderDetail({id: props.id}))
      dispatch(getOrderDetails({id: props.id}))
      setPhoneNumber(props.phone ?? '')
      setFee(calcFee());
    }
  }, [props.id])

  const dispatch = useDispatch();
  const today = new Date();
  const switchDate = new Date(Date.UTC(2023, 8, 4, 11, 0));

  return (
      <div style={{padding:30, paddingTop:10, background: 'white'}}>
      <h3>Sales #{calculateOrderNumber(props.orderNum, today, switchDate)}</h3>
      <hr />
      {props.payments?.length > 0 ? (
        <div style={{margin: 18}} />
      ) : null}
      {orderDetail && orderDetail?.charges?.data[0]?.payment_method_details?.card_present?.country != 'US' && orderDetail?.charges?.data[0]?.payment_method_details?.card_present?.country && (
        <MuiAlert style={{marginTop: 10, marginBottom: 3,}} disableElevation variant="filled" severity="warning">
          This is an <b>International Transaction</b> <br />
          <div style={{fontWeight: 400, marginTop: 7}}>
          Because the extra fee for international cards is applied directly by our payment processor, our system may not anticipate this additional cost until the transaction is processed. It's important to note that you will be responsible for this small extra fee. To manage this, we recommend informing customers with international cards and suggesting payment at the cashier if you prefer not to incur any fees.
          </div>
        </MuiAlert>
      )}
      <List style={{ maxHeight: 153, overflowY: 'scroll', paddingRight: 5}}>
      {props.order_items?.map(item =>
        <ListItem  dense disableGutters style={{display: 'block'}} key={item?.id}>
          <Grid container
                direction="row"
                justifyContent="space-between"
                alignItems="center">
            <Grid item xs>
            <b><Chip size="small" color="primary" style={{position: 'relative', borderRadius:2, bottom: 0, marginRight: 5, fontSize: 11, minWidth: 20, maxHeight: 20}} label={item?.quantity} />{item?.name}</b>
            </Grid>
            <Grid item xs>
            <b style={{float: 'right'}}>${item?.amount}</b>

            </Grid>
          </Grid>
          <List style={{marginTop: 0}} dense  component="span">
            <p style={{margin: 0, padding: 3}}></p>
            {item?.order_item_options?.map(option => <ListItem style={{display: 'block', paddingRight: 0}} button key={option?.id}>
            <Grid container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center">
            <Grid item xs>
              {option?.option_str}

            </Grid>
            <Grid item xs>
            <small style={{float: 'right'}}>+{currency(item.quantity * option?.unit_price).format()}</small>
            </Grid>
            </Grid>
            </ListItem>)}
          </List>
        </ListItem>)}
        </List>
        <Grid container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{fontSize: 12}}>
              <Grid item xs={5}>
              </Grid>
              <Grid item xs={7}>
                <hr />
              </Grid>

              <Grid item xs={5}>
              </Grid>
              <Grid item xs={5}>
              SUBTOTAL
              </Grid>
              <Grid item xs={2}>
                <span style={{float: 'right'}}>${props.subtotal}</span>
              </Grid>
              <Grid item xs={5}>
              </Grid>
              <Grid item xs={5}>
              TAX
              </Grid>
              <Grid item xs={2}>
                <span style={{float: 'right'}}>${props.tax}</span>
              </Grid>

              <Grid item xs={5}>
              </Grid>
              <Grid item xs={5}>
              TIP
              </Grid>
              <Grid item xs={2}>
                <span style={{float: 'right'}}>${orderDetail?.amount_details?.tip?.amount > 0 ? currency(orderDetail?.amount_details?.tip?.amount / 100).value : props.tip}</span>
              </Grid>
              {orderDetail?.metadata?.tip_added ? (
                currency(currency(props.surcharge).subtract(props.total)).value <= 0 ? (
                  null
                ) : (
                  <>
                  <Grid item xs={5}>
                  </Grid>
                  <Grid item xs={5}>
                  TRANSACTION FEE*
                  </Grid>
                  <Grid item xs={2}>
                    <span style={{float: 'right'}}>{props.total > 0 ? currency(transactionFeeOutput).format() : '$0.00'}</span>
                  </Grid>
                  </>
                )
              ) : (
                currency(currency(props.surcharge).value).subtract(currency(props.total).value).value === 0 ? (
                      null
                    ) : (
                      <>
                      <Grid item xs={5}>
                      </Grid>
                      <Grid item xs={5}>
                      TRANSACTION FEE*
                      </Grid>
                      <Grid item xs={2}>
                        <span style={{float: 'right'}}>{props.total > 0 ? currency(transactionFeeOutput).format() : '$0.00'}</span>
                      </Grid>
                      </>
                    )
              )}
              {chargeCustomersFee && payAsYouGo && props.pay_as_you_go_fee > 0 ? (
                <>
              <Grid item xs={5}>
              </Grid>
              <Grid item xs={5}>
              FEES 
              <Tooltip style={{fontSize: 16}} title={<span style={{fontSize: 13}}>You are on affordable Pay-As-You-Go Plan. It is straightforward and cost-effective, with no subscription or hardware fees. We deduct just a small fee per SMS messages and/or loyalty visit, ensuring our quality service at minimal cost to your business.</span>}><InfoIcon style={{marginLeft: 2,fontSize: 13.5, position: 'relative', top: 2.6, color: 'rgba(0,0,0,0.3)'}} /></Tooltip>
              </Grid>
              <Grid item xs={2}>
                <span style={{float: 'right'}}>({currency(props.pay_as_you_go_fee).format()})</span>
              </Grid>
              </>
              ) : null}

              {props.discount > 0 && <><Grid item xs={5}>
              </Grid>
              <Grid item xs={5}>
              DISCOUNT
              </Grid>
              <Grid item xs={2}>
                <span style={{float: 'right'}}>-{currency(props.discount).format()}</span>
              </Grid></>}
              {chargeCustomersFee && payAsYouGo && props.pay_as_you_go_fee > 0 ? (
                <>
               <Grid item xs={5}>
              </Grid>
              <Grid item xs={5}>
              <b>TOTAL</b>
              </Grid>
              <Grid item xs={2}>
                {orderDetail?.metadata?.tip_added ?
                  <b style={{float: 'right'}}>{currency(total).add(transactionFeeOutput).format()}</b>
                  :
                  <b style={{float: 'right'}}>{currency(total).add(transactionFeeOutput).add((orderDetail?.amount_details?.tip?.amount ?? 0) / 100).format()}</b>}
              </Grid>
              <Grid item xs={5}>
              </Grid>
              <Grid item xs={7}>
              <hr />
              </Grid>
                </>
              ) : (
                <>
               <Grid item xs={5}>
              </Grid>
              <Grid item xs={5}>
              <b>TOTAL</b>
              </Grid>
              <Grid item xs={2}>
                {orderDetail?.metadata?.tip_added ?
                  <b style={{float: 'right'}}>{currency(total).add(transactionFeeOutput).format()}</b>
                  :
                  <b style={{float: 'right'}}>{currency(total).add(transactionFeeOutput).add((orderDetail?.amount_details?.tip?.amount ?? 0) / 100).format()}</b>}
              </Grid>
              <Grid item xs={5}>
              </Grid>
              <Grid item xs={7}>
              <hr />
              </Grid>
                </>
              )}
              {orderDetail?.metadata?.tip_added ? (
                currency(transactionFeeOutput).value <= 0 ? (
                  null
                ) : (
                <span>*Transaction fee charges will cover the credit card processing fees associated with authorized card transactions</span>
                )
              ) : (
                currency(transactionFeeOutput).value === 0 ? (
                null
              ) : (
                <small>*Transaction fee charges will cover the credit card processing fees associated with authorized card transactions. 
                  Please note that when tips are collected through our terminal, our payment processor, Stripe, applies a minimal fee to the tip amount. This is because the tip is added on top of the transaction fee that we charge to customers.
                  </small>
              )
              )}
          </Grid>

        <hr style={{marginBottom: 10}} />
        { props.stripe_payment_intent || props.payments?.length > 0 ? <Button onClick={() => props.onSectionUpdate('Receipt')} fullWidth variant="outlined">Receipt</Button> : null}

      {(props.stripe_payment_intent || props.payments?.length > 0) && orderDetail?.charges?.data[0]?.amount_captured > 0 ? <Button style={{marginTop: 10}} onClick={() => props.onSectionUpdate('Refund')} fullWidth variant="outlined">Refund ORDER</Button> : null}

      {!props.stripe_payment_intent ? (
        <Button disabled={orderDetail?.charges?.data[0] ? true : false} onClick={handleUpdateOrder} style={{marginTop: 10}} fullWidth variant="outlined">UPDATE ORDER</Button>
      ) : (
        <Button disabled={orderDetail?.amount_received > 0} onClick={handleUpdateOrder}  style={{marginTop: 10}} fullWidth variant="outlined">UPDATE ORDER</Button>
      )}
      {props.status === 'draft' ? <Button onClick={() => {dispatch(updateOrderStatus({id: props.id, status: 'processing'}));print();props.handleClose()}} style={{marginTop: 10}} variant="outlined" color='primary' fullWidth>SEND TO KITCHEN</Button> : null}
      {props.status === 'draft' ? <Button onClick={async () => {await dispatch(updateOrderStatus({id: props.id, status: 'void'}));await props.handleClose();await dispatch(fetchOrders(props.page + 1))}} style={{marginTop: 10}} variant="outlined" color='secondary' fullWidth>VOID</Button> : null}

      </div>
    )
}

const dialogSections = ['Main', 'Refund', 'Receipt']

const TabelItemDialog = (props) => {
  const dispatch = useDispatch();
  const [currSection, setCurrSection] = useState();
  const status = useSelector(state => state.order.status);

  useEffect(() => {
    if (status === 'refunded') {
      dispatch(getOrderDetail({id: props.id}))
      dispatch(getOrderDetails({id: props.id}))

    } else if (status === 'refunded split') {
      dispatch(getOrderDetail({id: props.id}))
      dispatch(getOrderDetails({id: props.id}))
    } 
  }, [status])

  useEffect(() => {
    setTimeout(() => {
      setCurrSection('Main');
    }, 500)
  }, [props.open])

  let display = null;

  if (currSection === 'Main') {
    display = <MainContentDialog {...props} onSectionUpdate={(section) => setCurrSection(section)} />
  } else if (currSection == 'Refund') {
    display = <RefundDialogContent {...props} onSectionUpdate={(section) => setCurrSection(section)} />
  } else {
    display = <ReceiptDialogContent {...props} onSectionUpdate={(section) => setCurrSection(section)} />;
  }

  return (
    <Dialog maxWidth={'xs'} onClose={props.handleClose} open={props.open}>
    {display}
    </Dialog>
  )


}

export default OrderTable;
