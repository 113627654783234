import { createSlice } from '@reduxjs/toolkit';
import currency from 'currency.js'

export const itemModalSlice = createSlice({
  name: 'itemModal',
  initialState: {
    orderId: -1,
    isCartItem: false,
    cartIdx: null,
    modalOpen: false,
    modalId: null,
    isUpdateModal: false,
    modalItemData: {
      // a copy (.slice()) of an item object
      item: null,
      priceSummary: {
        amount: 0,
        discount: 0,
        qty: 1
      }
    }
  },
  reducers: {
    setOrderId: (state, action) => {
      state.orderId = action.payload
    },
    setIsUpdateModal: (state, action) => {
      state.isUpdateModal = action.payload
    },
    resetAll: (state) => {
      state = {
        orderId: -1,
        isCartItem: false,
        cartIdx: null,
        modalOpen: false,
        modalId: null,
        isUpdateModal: false,
        modalItemData: {
          // a copy (.slice()) of an item object
          item: null,
          priceSummary: {
            amount: 0,
            discount: 0,
            qty: 1
          }
        }
      };
      state.isCartItem = false;
      state.orderId = -1;

    },
    updateAddedItemQty: (state, action) => {
      const qty = action.payload.qty;
      if (qty >= 0) {
        const optionIdx = action.payload.optionIdx;
        const itemOptionIdx = action.payload.itemOptionIdx;
        const tempOptionIndex = state.modalItemData.item.groups.findIndex(item => item.option.id === optionIdx);

        if (state.modalItemData.item.groups[tempOptionIndex].addedItem) {
          const index = state.modalItemData.item.groups[tempOptionIndex].addedItem.findIndex(item => item.id === itemOptionIdx);
          const index2 = state.modalItemData.item.groups[tempOptionIndex].option.item_options.findIndex(item => item.id === itemOptionIdx);

          const max = state.modalItemData.item.groups[tempOptionIndex].option.multiple_max_number;

          const isMultiSelection = state.modalItemData.item.groups[tempOptionIndex].option.is_multiple_selection;


          let addedItemMax = 0;
          state.modalItemData.item.groups[tempOptionIndex].addedItem.map(item => {
            addedItemMax += item.number_value
          });


          if (qty > 0 || (qty === 0 && addedItemMax <= max)) {
            if (index !== -1) {
              const curr_val = state.modalItemData.item.groups[tempOptionIndex].addedItem[index].number_value;
              if (qty > curr_val) { // increasing
                if (addedItemMax < max) {
                  state.modalItemData.item.groups[tempOptionIndex].addedItem[index].number_value = qty;
                }
              } else if (qty < curr_val) {
                state.modalItemData.item.groups[tempOptionIndex].addedItem[index].number_value = qty;
              }
            }

            if (index2 !== -1) {
              const curr_val = state.modalItemData.item.groups[tempOptionIndex].option.item_options[index2].number_value;
              const set_val = qty;

              if (qty > curr_val) { // increasing
                if (addedItemMax < max) {
                  state.modalItemData.item.groups[tempOptionIndex].option.item_options[index2].number_value = qty;
                }
              } else if (qty < curr_val) { // decreasing
                state.modalItemData.item.groups[tempOptionIndex].option.item_options[index2].number_value = qty;
              }
            }
          }

          // if single selection
           if (!isMultiSelection) {
             state.modalItemData.item.groups[tempOptionIndex].option.item_options.forEach(item_option => {
               if (state.modalItemData.item.groups[tempOptionIndex].addedItem[index].id !== item_option.id) {
                 item_option.number_value = 0
               }

               if (item_option.number_value > 1) {
                 item_option.number_value = 1
               }
             });

             state.modalItemData.item.groups[tempOptionIndex].addedItem.forEach(item_option => {
               if (item_option.number_value > 1) {
                 item_option.number_value = 1
               }
             });
           }
        }
      }
    },
    updateQty: (state, action) => {
      const qty = action.payload;
      state.modalItemData.priceSummary.qty = qty;
    },
    updatePrice: (state) => {
      let addedItemSum = 0;
      state.modalItemData.item.groups.map(option => {
        if (option.addedItem) {
          option.addedItem.map(item => {
            if (!item.is_number_option) {
              addedItemSum = (currency(item.unit_price).add(addedItemSum)).value
            } else {
              // calc item.number_value * item.unit_price
              addedItemSum = ((currency(item.number_value).multiply(item.unit_price)).add(addedItemSum)).value
            }
          })
        }
      });
      // subtotalTaxable = ((currency(cartItems[i].modalItemData.item.unit_price).multiply(cartItems[i].quantity)).add(subtotalTaxable)).value

      let unitPrice = state.modalItemData.item.unit_price;
      state.modalItemData.priceSummary.amount = (currency(currency(unitPrice).add(addedItemSum)).multiply(state.modalItemData.priceSummary.qty)).value
    },
    setSelectedItem: (state, action) => {
      const obj = action.payload;
      const optionIdx = obj.optionIdx; // groups.option.id
      const itemIdx = obj.itemIdx; // groups.item_option.id

      let selected = false;
      let isNumberOption = false;
      let itemIndex;
      const optionIndex = state.modalItemData.item.groups.findIndex(option => option.option.id === optionIdx);

      if (state.modalItemData.item.groups[optionIndex].addedItem) {
        itemIndex = state.modalItemData.item.groups[optionIndex].addedItem.findIndex(item => item.id === itemIdx);
        if (itemIndex !== -1) {
          selected = state.modalItemData.item.groups[optionIndex].addedItem[itemIndex].selected;
          isNumberOption = state.modalItemData.item.groups[optionIndex].addedItem[itemIndex].is_number_option
        }
      }
      // toggle, except for number option
      if (state.modalItemData.item.groups[optionIndex].addedItem[itemIndex] && (!isNumberOption && !state.modalItemData.item.groups[optionIndex].addedItem[itemIndex].selected)) {
        state.modalItemData.item.groups[optionIndex].addedItem[itemIndex].selected = !state.modalItemData.item.groups[optionIndex].addedItem[itemIndex].selected;
      }
    },
    setModalOpen: (state, action) => {
      const obj = action.payload;
      state.modalOpen = obj.modalOpen;
      state.modalId = obj.modalId;
      state.isCartItem = obj.isCartItem;
      state.cartIdx = obj.cartIdx;
      state.modalItemData.priceSummary = {
        amount: 0,
        discount: 0,
        qty: 1
      };
    },
    setItem: (state, action) => {
      state.modalItemData.item = action.payload;
      state.modalItemData.priceSummary.amount = action.payload.unit_price;
    },
    setPriceSummary: (state, action) => {
      state.modalItemData.priceSummary = action.payload;
    },
    deleteSelectedOptionItem: (state, action) => {

      const obj = action.payload;
      const optionId = obj.optionId;
      const itemId = obj.itemId;
      const optionIdx = state.modalItemData.item.groups.findIndex(option => option.option.id === optionId);
      if (state.modalItemData.item.groups[optionIdx].addedItem) {
        const index = state.modalItemData.item.groups[optionIdx].addedItem.findIndex(item => item.id === itemId);
        if (state.modalItemData.item.groups[optionIdx].addedItem.length === 1) {
          state.modalItemData.item.groups[optionIdx].addedItem = []
        } else {
          state.modalItemData.item.groups[optionIdx].addedItem.splice(index, 1);
        }
      }
    },
    addSelectedOption: (state, action) => {
      const index = state.modalItemData.item.groups.findIndex(option => option.option.name === action.payload.name);
      if (!state.modalItemData.item.groups[index].addedItem) {
        state.modalItemData.item.groups[index].addedItem = [];
      }

      let exists = false;
      let isNumberOption = false;

      state.modalItemData.item.groups[index].addedItem.forEach(item => {
        if (item.id === action.payload.option.id) {
          exists = true;
        }

        if (item.is_number_option) {
          isNumberOption = true;
        }
      })

      if (!state.modalItemData.item.groups[index].option.is_multiple_selection) {
        if (isNumberOption) {
          state.modalItemData.item.groups[index].addedItem.map((item, idx) => {
            if (item.id !== action.payload.option.id) {
              state.modalItemData.item.groups[index].addedItem.splice(idx, 1);
            }
          });
        } else {
          state.modalItemData.item.groups[index].addedItem = [];
        }
      }



      if (!exists) {
          state.modalItemData.item.groups[index].addedItem.push(action.payload.option);
      }
    }
  }
});

export default itemModalSlice.reducer;
export const { setOrderId, setIsUpdateModal, updateAddedItemQty, setPriceSummary, resetAll, updateQty, updatePrice, setModalOpen, setItem, addSelectedOption, setSelectedItem, deleteSelectedOptionItem } = itemModalSlice.actions;

export const selectItemOptionSelectedById = (state, obj) => {
  const optionIdx = obj.optionIdx; // Option.id
  const itemIdx = obj.itemIdx; // ItemOption.id

  const optionIndex = state.itemModal.modalItemData.item.groups.findIndex(option => option.option.id === optionIdx);
  if (optionIndex !== -1) {
    if (state.itemModal.modalItemData.item.groups[optionIndex].addedItem) {
      let itemIndex = state.itemModal.modalItemData.item.groups[optionIndex].addedItem.findIndex(item => item.id === itemIdx);
      if (itemIndex === -1) {
        itemIndex = state.itemModal.modalItemData.item.groups[optionIndex].addedItem.findIndex(item => item.option_item_id === itemIdx);
        if (itemIndex === -1) { return null; }
      }
      return state.itemModal.modalItemData.item.groups[optionIndex].addedItem[itemIndex];
    }
  }

  return null;
}
