import React, {useEffect, useState} from 'react';
import { fetchCategoryImage, updateSelectImage, removeCategoryImage } from './categorySlice';
import ConfirmDeleteModal from '../modal/ConfirmDeleteModal';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Avatar from '@material-ui/core/Avatar';

import { postUpdateCategoryImage } from '../category/categorySlice';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
}));

const CategoryImage = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const putCategoryStatus = useSelector(state => state.category.putCategoryStatus);
  const user = useSelector(state => state.auth.user);
  const [confirmDelete, setConfirmDelete] = useState(false)

  const handleClick = () => {
    if (user?.shop?.type != 'franchisee') {
      setConfirmDelete(false);
      dispatch(postUpdateCategoryImage({
        targetValue: props.id, // CategoryImage.id
        categoryId: props.categoryId,
      }));
    }
  }

  const handleDeleteOnRightClick = (e) => {
    e.preventDefault();
    if (user?.shop?.type != 'franchisee') {
      setConfirmDelete(true)
    }
  }

  const handleDeleteOnLongTouch = (e) => {
    e.preventDefault();
  }

  if (props.selected) {
    return (
      <Badge onContextMenu={e => handleDeleteOnRightClick(e)} badgeContent={!confirmDelete ? <CheckIcon /> : null} color="primary">
        {confirmDelete ? (
          <>
          <IconButton onClick={() => setConfirmDelete(false)} size="small" style={{marginRight: -5}}><ArrowBackIcon /></IconButton>
          <IconButton onClick={() => dispatch(removeCategoryImage(props.id))} size="small" color="red"><DeleteIcon/></IconButton>
          </>
        ) : (
          <Avatar style={{boxShadow: '0px 2px 1px 0px #878787'}}  src={props.category_image} className={classes.large} />
        )}
      </Badge>
    );
  } else {
    return (
      <>
      {confirmDelete ? (
        <>
        <IconButton onClick={() => setConfirmDelete(false)} size="small" style={{marginRight: -5}}><ArrowBackIcon /></IconButton>
        <IconButton onClick={() => dispatch(removeCategoryImage(props.id))} size="small" color="red"><DeleteIcon/></IconButton>
        </>
      ) : (
        <Avatar style={{boxShadow: '0px 2px 1px 0px #878787'}} src={props.category_image} className={classes.large} onContextMenu={e => handleDeleteOnRightClick(e)}  onClick={handleClick} />
      )}
      </>
    );
  }
};

export default CategoryImage;
