import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { getAllShops } from '../features/auth/authSlice'
import WarningIcon from '@material-ui/icons/Warning';
import TextField from '@material-ui/core/TextField';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import RefreshIcon from '@material-ui/icons/Refresh';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { io } from "socket.io-client";
import moment from 'moment';

const PIN = '1115'

const DEFAULT_SOCKETS_URL = 'https://sockets.bigkiosksolution.com';
//const DEFAULT_SOCKETS_URL = 'http://127.0.0.1:5000';
const PING_TIMEOUT = 2000; // 2 seconds timeout


const Admin = () => {
    const socketRef = useRef();
    const dispatch = useDispatch()
    const history = useHistory()
    const isStaff = useSelector(state => state.auth.user?.user?.is_staff)
    const status = useSelector(state => state.auth.status)
    const shops = useSelector(state => state.auth.shops)

    const [kiosks, setKiosks] = useState(shops.filter(shop => shop.hidden_from_status_board === false).map(shop => ({
        id: shop,
        status: 'unknown',
        timeoutRef: null,
        lastUpdated: null // Adding last updated field
      })));
    const [verified, setVerified] = useState(false);

    const refreshKiosks = () => {
        dispatch(getAllShops());
    };

    

    useEffect(() => {
        const shopId = localStorage.getItem('shop_id')

        if (socketRef.current == null) {
            socketRef.current = io(DEFAULT_SOCKETS_URL, {
                transports: ["websocket"] // use WebSocket first, if available
            });
        }

        const { current: socket } = socketRef;

        try {
            socket.open();
            socket.on("connect", () => {
                socket.sendBuffer = [];
                socket.emit('join', { id: shopId });
            })

            socket.on("disconnect", (reason) => {
                if (reason === "io server disconnect") {
                    // the disconnection was initiated by the server, you need to reconnect manually
                    socket.connect();
                }
            });

            socket.on("connect_error", () => {
                // revert to classic upgrade
                socket.connect();

            });

            socket.on("connect_failed", () => {
                // revert to classic upgrade
                socket.connect();
            });

            socket.on('error', function () {
                socket.connect();
            });

            socket.on('reconnect', () => {
                socket.sendBuffer = [];
                socket.emit('join', { id: shopId });
            })

            socket.on('pong', (payload) => {
                if (payload.data.request_shop_id == shopId) {
                    const _shopId = payload.data.shop_id;
            
                    // Update the kiosks state, clear the timeout, and set the status to 'online'
                    setKiosks(kiosks => kiosks.map(kiosk => {
                        if (kiosk.id == _shopId && kiosk.timeoutRef) {
                            clearTimeout(kiosk.timeoutRef);
                            return { ...kiosk, status: 'online', timeoutRef: null, lastUpdated: moment().format() };
                        }
                        return kiosk;
                    }));
                }
            });

        } catch (error) {
            console.error(error)
        }
        // Return a callback to be run before unmount-ing.
        return () => {
            socket.off('connect')
            socket.off('disconnect')
            socket.off('connect_error')
            socket.off('connect_failed')
            socket.off('error')
            socket.off('reconnect')
            socket.off('pong')
            socket.close()
        };
    }, [])

    useEffect(() => {
        // Map existing kiosks to a lookup table for quick access
        const kioskLookup = kiosks.reduce((acc, cur) => {
            acc[cur.id] = cur;
            return acc;
        }, {});

        // Create a new kiosks array, preserving status where possible
        const updatedKiosks = shops
            .filter(shop => shop.hidden_from_status_board === false)
            .map(shop => {
                // If this shop already existed in kiosks, use its previous status
                if (kioskLookup[shop]) {
                    return { id: shop.id, status: kioskLookup[shop].status, lastUpdated: kioskLookup[shop].lastUpdated, timeoutRef: kioskLookup[shop].timeoutRef };
                } else { // This is a new shop, so its status is 'unknown'
                    return { id: shop.id, status: 'unknown', lastUpdated: null, timeoutRef: null };
                }
            });

        // Update the kiosks state
        setKiosks(updatedKiosks);
    }, [shops]); // Runs whenever the shops list changes

    useEffect(() => {
        if (isStaff === false) { // ignore 'undefined'
            history.push('/category')
        }
    }, [isStaff])

    useEffect(() => {
        if (status === 'all shops loading failed') {
            history.push('/category')

        }
    }, [status])

    const ping = (shopId) => {
        if (socketRef.current == null) {
            socketRef.current = io(DEFAULT_SOCKETS_URL, {
                transports: ["websocket"] // use WebSocket first, if available
            });
        }

        const { current: socket } = socketRef;

        socket.emit('ping-to-kiosk', { shop_id: shopId, id: localStorage.getItem('shop_id') });
    
        // Optionally, set a timeout for unreachable status, updating lastUpdated as well
        const timeout = setTimeout(() => {
        setKiosks(kiosks => kiosks.map(kiosk =>
            kiosk.id == shopId ? { ...kiosk, status: 'unreachable', timeoutRef: null, lastUpdated: moment().format() } : kiosk
        ));
        }, PING_TIMEOUT);
    
        // Update the lastUpdated time upon sending a ping
        setKiosks(kiosks => kiosks.map(kiosk =>
        kiosk.id == shopId ? { ...kiosk, timeoutRef: timeout } : kiosk
        ));
      };


    return (
        !verified ? (
            <PinPrompt onSuccess={() => setVerified(true)} />
        ) : (
            <AdminDashboard socketRef={socketRef} kiosks={kiosks} onPing={shopId => ping(shopId)} onRefreshKiosks={() => refreshKiosks()} />
        )
    );
};

const AdminDashboard = (props) => {
    const dispatch = useDispatch();
    const status = useSelector(state => state.auth.status);
    const shops = useSelector(state => state.auth.shops);
    const kiosks = props.kiosks;
    const [reloading, setReloading] = useState(false);
    const [multiple, setMultiple] = useState(1);
    const [multiple2, setMultiple2] = useState(1);
    const [multiple3, setMultiple3] = useState(1);
    const [attnMsg, setAttnMsg] = useState('Please check the card reader NOW and follow the on-screen instructions for any pending updates. DO NOT postpone or cancel the update.');
    const [rebooting, setRebooting] = useState(false);
    const [sendingAtnMsg, setSendingAtnMsg] = useState(false);
    const [openAtnMsgDialog, setOpenAtnMsgDialog] = useState(false);
    const [currentShopId, setCurrentShopId] = useState(null); // State for the current shop ID

    useEffect(() => {
        if (shops.length === 0) {
            dispatch(getAllShops());
        }
    }, [shops.length, dispatch]);

    const handleReload = (shopId) => {
        if (props.socketRef.current == null) {
            props.socketRef.current = io(DEFAULT_SOCKETS_URL, {
                transports: ["websocket"] // use WebSocket first, if available
            });
        }

        setReloading(true);
        if (!shopId) {
            setReloading(false);
            return;
        }
        const { current: socket } = props.socketRef;

        socket.emit('reload-kiosk', { id: shopId.toString() });

        setTimeout(() => {
            setReloading(false);
        }, 3000 * multiple);

        setMultiple(multiple + 1);
    }

    const handleReboot = (shopId) => {
        if (props.socketRef.current == null) {
            props.socketRef.current = io(DEFAULT_SOCKETS_URL, {
                transports: ["websocket"] // use WebSocket first, if available
            });
        }

        setRebooting(true);
        if (!shopId) {
            setRebooting(false);
            return;
        }
        const { current: socket } = props.socketRef;

        socket.emit('reboot-kiosk', { id: shopId.toString() });

        setTimeout(() => {
            setRebooting(false);
        }, 3000 * multiple2);

        setMultiple2(multiple2 + 1);
    }

    const handleAttention = (e, id) => {
        e.preventDefault();
        if (props.socketRef.current == null) {
            props.socketRef.current = io(DEFAULT_SOCKETS_URL, {
                transports: ["websocket"] // use WebSocket first, if available
            });
        }

        setSendingAtnMsg(true);
        if (!id) {
            setSendingAtnMsg(false);
            return;
        }
        const { current: socket } = props.socketRef;

        socket.emit('send-attention-message-to-kds', { id: id.toString(), message: attnMsg });

        setTimeout(() => {
            setSendingAtnMsg(false);
        }, 3000 * multiple3);

        setMultiple3(multiple3 + 1);
    }

    // Add the custom hook to trigger re-renders every minute
    useForceUpdateInterval(60000);

    // Count the number of shops with at least one order today and for the last 3 days
    const shopsWithActiveOrdersToday = shops?.filter(shop => shop.hidden_from_status_board === false)?.filter(shop => shop.today_order_count > 0).length;
    const shopsWithOrdersToday = shops?.filter(shop => shop.hidden_from_status_board === false)?.filter(shop => shop.today_order_count === 0).length;
    const shopsWithOrdersLast3Days = shops?.filter(shop => shop.hidden_from_status_board === false)?.filter(shop => shop.last_three_days_order_count === 0).length;

    return (
        <div className="font-roboto" style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            backgroundColor: '#121212',
            color: '#e0e0e0',
            height: '100vh',
            overflow: 'auto',
            paddingBottom: 100
        }}>
            <h1 style={{
                textAlign: 'center',
                color: '#333', // Darker text color for better readability
                fontSize: 22
            }}>
                <span style={{ color: 'red' }}>CONFIDENTIAL</span><br />
                Kio Admin Dashboard
                <br />
            </h1>
            <div style={{display: 'flex', width: 250, flexDirection: 'column'}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 3}}>
                    <div style={{display: 'flex', alignItems: 'center'}}><span style={{marginRight: 27}}></span> Active Shops</div>
                    <span style={{fontWeight: 800, backgroundColor: 'rgba(255,255,255,0.1)', paddingLeft: 8, paddingRight: 8, borderRadius: 5}}>
                        {shops?.filter(shop => shop.hidden_from_status_board === false)?.length}
                    </span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 3}}>
                    <div style={{display: 'flex', alignItems: 'center'}}><CheckCircleIcon htmlColor="green" style={{marginRight: 3}} /> Orders Today Shops</div>
                    <span style={{fontWeight: 800, backgroundColor: 'green', paddingLeft: 8, paddingRight: 8, borderRadius: 5}}>
                        {shopsWithActiveOrdersToday}
                    </span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 3}}>
                    <div style={{display: 'flex', alignItems: 'center'}}><WarningIcon htmlColor="orange" style={{marginRight: 3}} /> No Orders Today Shops</div>
                    <span style={{fontWeight: 800, backgroundColor: 'orange', paddingLeft: 8, paddingRight: 8, borderRadius: 5}}>
                        {shopsWithOrdersToday}
                    </span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 3}}>
                    <div style={{display: 'flex', alignItems: 'center'}}><ErrorIcon htmlColor="red" style={{marginRight: 3}} /> No Orders Last 3d Shops</div>
                    <span style={{fontWeight: 800, backgroundColor: 'red', paddingLeft: 8, paddingRight: 8, borderRadius: 5}}>
                        {shopsWithOrdersLast3Days}
                    </span>
                </div>
            </div>
            <div style={{
                display: 'flex',
                flexWrap: 'wrap', // Allow items to wrap in smaller screens
                justifyContent: 'center', // Center children horizontally
                gap: '20px', // Use gap to space out the children instead of marginRight
                marginTop: '20px', // Add some top margin for spacing between title and content
            }}>
                {status === 'all shops loading' && (
                    <small>Loading Shops...</small>
                )}
                {shops?.filter(shop => shop.hidden_from_status_board === false).map(shop => (
                    <div key={shop.id} style={{
                        border: '1px solid #333',
                        borderRadius: '10px',
                        width: '220px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        backgroundColor: '#1e1e1e',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.5)',
                        padding: '10px',
                        paddingTop: 0,
                        color: '#e0e0e0',
                        minHeight: 100,
                    }}>
                        <p style={{
                            color: '#e0e0e0',
                            display: 'flex',
                            flexDirection: 'row', justifyContent: 'space-between', width: '100%', textAlign: 'center', fontWeight: 'bold', paddingBottom: 10, borderBottom: '1px solid rgba(0,0,0,0.2)'
                        }}>
                            <span style={{
                                overflow: 'hidden', // Hide overflow
                                textOverflow: 'ellipsis', // Add ellipsis (...) for overflow text
                                whiteSpace: 'nowrap', // Keep text in a single line
                                maxWidth: '75%', // Adjust this percentage based on the space you want to allocate for the text vs. icons
                            }}>{shop.business_name}</span>
                            <span>
                                {shop.today_order_count > 0 && <CheckBoxIcon htmlColor="green" />}
                                {shop.last_three_days_order_count === 0 && <ErrorIcon htmlColor="red" />}
                                {shop.today_order_count === 0 && <WarningIcon htmlColor="orange" />}
                            </span>
                        </p>
                        <div style={{
                            width: 'inherit',
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                            {shop.error_code && (
                                <div style={{borderRadius: 5, marginBottom: 10, padding: 5, backgroundColor: 'orange', color: 'black', border: '3px solid rgba(255,0,0,0.7)'}}><b>ATN! CRITICAL ERROR</b><br />
                                    <span style={{fontFamily: 'monospace'}}>{shop.error_code}</span><br/>
                                    <small style={{float: 'right', fontWeight: 800, fontSize: 12, color: 'rgba(0,0,0,0.5)'}}>
                                        {moment(shop.error_timestamp).format('YYYY-MM-DD h:mm A')}
                                    </small>
                                </div>
                            )}
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between', // Evenly space the content vertically
                            }}>
                                <span>Today</span>
                                <span>{shop.today_order_count}</span>
                            </div>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between', // Evenly space the content vertically
                            }}>
                                <span>Last 3d</span>
                                <span>{shop.last_three_days_order_count}</span>
                            </div>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between', // Evenly space the content vertically
                            }}>
                                <span>This month</span>
                                <span>{shop.this_month_order_count}</span>
                            </div>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between', // Evenly space the content vertically
                            }}>
                                <span>Last month</span>
                                <span>{shop.last_month_order_count}</span>
                            </div>
                            {(shop.city || shop.state) && (
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between', // Evenly space the content vertically
                                }}>
                                    <span>Loc</span>
                                    <span>{shop.city}, {shop.state}</span>
                                </div>
                            )}
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between', // Evenly space the content vertically
                            }}>
                                <span>Kiosk <RefreshButton onClick={() => props.onPing(shop.id)} /></span>
                                <span>
                                    {kiosks.find(kiosk => kiosk.id === shop.id)?.status === 'online' ? <FiberManualRecordIcon htmlColor="green" /> : kiosks.find(kiosk => kiosk.id === shop.id)?.status === 'unknown' ? <FiberManualRecordIcon htmlColor="gray" /> : <FiberManualRecordIcon htmlColor="red" />}
                                </span>
                            </div>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between', // Evenly space the content vertically
                            }}>
                                <small style={{fontSize: 10}}>LAST SEEN</small>
                                <small style={{fontSize: 10}}>
                                    {kiosks.find(kiosk => kiosk.id === shop.id)?.lastUpdated ? moment(kiosks.find(kiosk => kiosk.id === shop.id)?.lastUpdated).fromNow() : 'NEVER'}
                                </small>
                            </div>
                            <div style={{
                                borderTop: '1px solid rgba(255,255,255,0.3)',
                                paddingTop: 10,
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between', // Evenly space the content vertically
                            }}>
                                {rebooting ? (
                                    <Button disabled variant="contained" size="small" style={{color: 'rgba(255,255,255,0.3)', backgroundColor: 'rgba(255,255,255,0.2)', fontSize: 12, padding: 2}}>Rebooting</Button>
                                ) : (
                                    <Button onClick={() => handleReboot(shop.id)} variant="contained" size="small" style={{ fontSize: 12, padding: 2}}>Reboot</Button>
                                )}
                                {reloading ? (
                                    <Button disabled variant="contained" size="small" style={{color: 'rgba(255,255,255,0.3)', backgroundColor: 'rgba(255,255,255,0.2)', fontSize: 12, padding: 2}}>Refreshing</Button>
                                ) : (
                                    <Button onClick={() => handleReload(shop.id)} variant="contained" size="small" style={{ fontSize: 12, padding: 2}}>Refresh</Button>
                                )}
                                {sendingAtnMsg ? (
                                    <Button disabled variant="contained" size="small" style={{color: 'rgba(255,255,255,0.3)', backgroundColor: 'rgba(255,255,255,0.2)', fontSize: 12, padding: 2}}>Sending</Button>
                                ) : (
                                    <Button onClick={() => { setOpenAtnMsgDialog(true); setCurrentShopId(shop.id); }} variant="contained" size="small" style={{ backgroundColor: 'orange', color: 'black', fontSize: 12, padding: 2}}>ATTN</Button>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <Dialog
                onClose={() => {setOpenAtnMsgDialog(false); setAttnMsg('Please check the card reader NOW and follow the on-screen instructions for any pending updates. DO NOT postpone or cancel the update.');}} aria-labelledby="simple-dialog-title" open={openAtnMsgDialog}>
                <DialogContent style={{minWidth: 400}}>
                    <form onSubmit={e => handleAttention(e, currentShopId)}>
                        <TextField
                            multiline
                            style={{marginBottom: 10}}
                            id="filled-password-input"
                            label="Enter Attention Message"
                            type="text"
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="name"
                            value={attnMsg}
                            inputProps={{ maxLength: 167 }} // Limiting to 167 characters
                            onChange={e => setAttnMsg(e.target.value)}
                        />
                        {sendingAtnMsg ? 
                            <Button disabled type="submit" style={{marginBottom: 10}} fullWidth variant="contained" color="primary" disableElevation>
                                SENDING
                            </Button> : 
                            <Button onClick={() => {setOpenAtnMsgDialog(false);}} type="submit" style={{marginBottom: 10}} fullWidth variant="contained" color="primary" disableElevation>
                                SEND
                            </Button>
                        }
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
}

const useForceUpdateInterval = (interval = 60000) => {
    const [tick, setTick] = useState(0);
  
    useEffect(() => {
      const timerId = setInterval(() => setTick(tick => tick + 1), interval);
      return () => clearInterval(timerId);
    }, [interval]);
  
    return tick; // `tick` value is not directly used, but changes trigger re-renders
  };

const PinPrompt = (props) => {
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Check localStorage on mount
        const pinSuccessTimestamp = localStorage.getItem('pinSuccessTimestamp');
        if (pinSuccessTimestamp) {
            const timePassed = Date.now() - parseInt(pinSuccessTimestamp);
            const oneDayInMs = 86400000; // 24 * 60 * 60 * 1000
            if (timePassed < oneDayInMs) {
                props.onSuccess();
                return;
            }
        }
    }, [props]);

    useEffect(() => {
        if (password === PIN) {
            setLoading(true);
            setTimeout(() => {
                // Store the current timestamp in localStorage
                localStorage.setItem('pinSuccessTimestamp', Date.now().toString());
                props.onSuccess();
                setLoading(false);
            }, 1500);
        }
    }, [password, props]);

    function handleInput(e) {
        const input = e.target.value;
        setPassword(input);
    }

    return (
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <h1>Admin Access</h1>
            {loading ? (
                <h3>Entering Admin Page...</h3>
            ) : (<label>
                Enter PIN<br />
                <input onChange={handleInput} type="password" />
            </label>)}
        </div>
    )
};


function RefreshButton(props) {
    const [isSpinning, setIsSpinning] = useState(false);

    const handleClick = () => {
        setIsSpinning(true);
        props.onClick()
        // Optionally, stop spinning after some time or after some operation is completed
        setTimeout(() => setIsSpinning(false), 2000); // Example: stop after 2 seconds
    };

    return (
        <IconButton disabled={isSpinning} onClick={!isSpinning ? handleClick : null} style={{ margin: 0, padding: 0 }} size="small" aria-label="refresh">
            <RefreshIcon fontSize="small" htmlColor={isSpinning ? "gray" : "white"} className={isSpinning ? 'spinAnimation' : ''} />
        </IconButton>
    );
}

function MainRefreshButton(props) {
    const [isSpinning, setIsSpinning] = useState(false);

    const handleClick = () => {
        setIsSpinning(true);
        props.onClick()
        // Optionally, stop spinning after some time or after some operation is completed
        setTimeout(() => setIsSpinning(false), 2000); // Example: stop after 2 seconds
    };

    return (
        <IconButton disabled={isSpinning} onClick={!isSpinning ? handleClick : null} style={{...props.style}} size="large" aria-label="refresh">
            <RefreshIcon fontSize="large" htmlColor={isSpinning ? "gray" : "white"} className={isSpinning ? 'spinAnimation' : ''} />
        </IconButton>
    );
}

export default Admin;
