import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchCoupons } from './couponsSlice';
import CouponCard from './CouponCard'
import Grid from '@material-ui/core/Grid';
import CouponModal from './AddCouponModal';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
   
  }));
  
  const CouponsList = (props) => {
    const location = useLocation();

    const dispatch = useDispatch();
    const coupons = useSelector(state => state.coupons?.coupons?.results ?? []);

    useEffect(() => {
        // handles user navigation changes
        dispatch(fetchCoupons());
    }, [location])

    return (
      <>
         <Grid spacing={2} justifyContent='flex-start' alignItems='center' direction="row" container>
            {coupons?.map(coupon => <Grid key={coupon.id} item xs={6} md={4}><CouponCard  {...coupon} /></Grid>)}
       </Grid>
      <CouponModal />
      </>
    
    )
  }

  export default CouponsList;