import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useSelector, useDispatch } from 'react-redux';
import {
    setAddCouponModalOpen,
  selectCouponOpen
} from './modalSlice';
import {
  addCoupon, clearError
} from '../coupons/couponsSlice';
import {
    DateTimePicker ,
  } from '@material-ui/pickers';
import { DialogContentText } from '@material-ui/core';

const AddCouponModal = (props) => {
  const open = useSelector(selectCouponOpen);
  const error = useSelector(state => state.coupons?.error);
  const status = useSelector(state => state.coupons?.status);

  const dispatch = useDispatch();
  const shopId = useSelector(state => state.auth?.user?.shop?.id)
  const [validFrom, setValidFrom] = useState(new Date())
  const [validUntil, setValidUntil] = useState(new Date())

  const handleSubmit = (e) => {
    e.preventDefault();
    const couponCode = e.target.code.value;
    const percentage = e.target.percentage.value;

    dispatch(addCoupon({
      code: couponCode,
      description: "Shop's coupon",
      valid_from: validFrom.toISOString(),
      valid_until: validUntil.toISOString(),
      max_redemptions: 9999,
      discount_type: 'percentage',
      discount_value: percentage,
      shop: shopId
    }));

  };
  useEffect(() => {
    dispatch(clearError())

  }, [open])

  useEffect(() => {
    if (status === 'add coupon succeeded') {
      dispatch(clearError())
      dispatch(setAddCouponModalOpen(false))
    }
  }, [status])


  return (
    <Dialog fullWidth={50} maxWidth="sm" open={open} onClose={() => dispatch(setAddCouponModalOpen(false))} aria-labelledby="form-dialog-title">
    <form onSubmit={handleSubmit} autoComplete="off">
      <DialogTitle id="form-dialog-title">Coupon Code
      </DialogTitle>

      <DialogContent>
        <TextField
         InputLabelProps={{
            shrink: true,
          }}
        required
          margin="dense"
          name="code"
          id="code"
          label="Coupon Code"
          type="text"
          variant="outlined"
          fullWidth
          placeholder='e.g. SPRING20'
          inputProps={{style: {textTransform: 'uppercase'}}} // This ensures the visual text is uppercase
          onChange={(e) => {
            // Transform input value to uppercase before setting it
            e.target.value = e.target.value.toUpperCase();
          }}
        />
                <TextField
                style={{marginTop: 15}}
                 InputLabelProps={{
                    shrink: true,
                  }}
         type="number"
         required
          margin="dense"
          name="percentage"
          id="percentage"
          variant="outlined"
          label="Discount Percentage"
          placeholder="e.g. 20"
          fullWidth
          inputProps={{
            min: "0", // Minimum value
            max: "100", // Maximum value for a percentage
            step: "1" // Allows only integer increments
          }}
        />
        <DateTimePicker
        required
          style={{background: 'white', marginTop: 15}}
          fullWidth
          inputVariant="outlined"
          name="valid_from"
          label="Valid From"
          placeholder="2029/10/30"
          size="small"
          value={validFrom}
          onChange={date => setValidFrom(date)}
          format="yyyy/MM/DD HH:mm"
          showTodayButton
        />
        <DateTimePicker
        required
          style={{background: 'white', marginTop: 15}}
          fullWidth
          inputVariant="outlined"
          name="valid_until"
          label="Valid Until"
          placeholder="2029/11/5"
          size="small"
          value={validUntil}

          onChange={date => setValidUntil(date)}
          format="yyyy/MM/DD HH:mm"
          showTodayButton
        />
              <br />
      <p style={{padding:0, margin: 0, marginTop: 8, fontWeight: 300, color: 'red', textTransform: 'capitalize', fontSize: 15}}>{error}</p>
      </DialogContent>
      
      <DialogActions>
        <Button onClick={() => dispatch(setAddCouponModalOpen(false))} color="primary">
          Cancel
        </Button>
        <Button type="submit" color="primary">
          Add
        </Button>
      </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddCouponModal;
