import React from 'react';
import AddModal from '../modal/AddModal'

const CategoryAddModal = () => {
  return (
    <AddModal />
  );
};

export default CategoryAddModal;
