import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import ContentHeader from './ContentHeader';
import Backdrop from '@material-ui/core/Backdrop';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { setTitle, setMenu } from '../../features/header/headerSlice';
import { useSelector, useDispatch } from 'react-redux';
import { getSystemUser, agreeTermsAndCondition } from '../../features/auth/authSlice';

const Content = (props) => {
  const dispatch = useDispatch();
  const shop = useSelector(state => state.auth.user?.shop)
  const user = useSelector(state => state.auth.user)
  const status = useSelector(state => state.auth.status)

  useEffect(() => {
    if (status === 'agreed') {
      dispatch(getSystemUser())
    }
  }, [status])

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      flex:1,
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
      flexDirection: 'column',
      position: 'absolute',
    },
    paper: {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(0),
      width: '50vw',
      [theme.breakpoints.down('md')]: {
        width: '90vw'
      },
      //background: 'linear-gradient(to right bottom, #489db8 50%, #3f95b0 50.3%)',
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      color: 'black',
      fontFamily: 'Poppins, sans-serif',
    },
    paper2: {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(1.5),
      width: '50vw',
      [theme.breakpoints.down('md')]: {
        width: '90vw'
      },
      background: 'linear-gradient(to right bottom, #b84848 50%, #b03f3f 50.3%)',
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      color: 'white',
      fontFamily: 'Poppins, sans-serif',
    },
    paperTwo: {
      background: 'white',
      padding: theme.spacing(3),
      paddingTop: theme.spacing(3),
      width: '50vw',
      [theme.breakpoints.down('md')]: {
        width: '90vw'
      },
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
      fontFamily: 'Poppins, sans-serif',
    },
    paperThree: {
      background: 'white',
      padding: theme.spacing(3),
      paddingTop: theme.spacing(1.5),

      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 13,
      borderBottomRightRadius: 13,
      fontFamily: 'Poppins, sans-serif',
    },
    root: {
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${props.drawerWidth}px)`,
        marginLeft: 250,
        paddingTop: 10,
        alignItems: 'center',
      },
      [theme.breakpoints.down('md')]: {
        paddingTop: 0,
        paddingLeft: 10,
        paddingRight: 10,

      },
      paddingBottom: 50,
      marginTop: 70
      //marginTop: 170
    }
  }));


  const classes = useStyles();

  useEffect(() => {
    dispatch(setTitle(props.title.charAt(0).toUpperCase() + props.title.slice(1)));
  }, [props.title]);

  return (
    <React.Fragment>
    {shop && !shop?.terms_and_condition_agreed ? (
      <Grid container>
         <Backdrop className={classes.backdrop} open={true}>
           <Paper className={classes.paper} elevation={6}>
               <Grid container
                 direction="column"
                 justifyContent="center"
                 alignItems="center">
                <Grid item><h2 style={{marginTop: 20}}>Terms and Condition</h2></Grid>
             </Grid>
             <Grid container>
               <Grid item>
                 <p style={{overflowY: 'scroll', height: 400, marginTop: -2}}>
                 Welcome to Kiostart LLC!<br />
    <br />
    This Kiostart LLC Services Agreement includes this introduction, the General Terms, Definitions, Services Terms, and incorporated documents and terms (&ldquo;Agreement&rdquo;) and forms a legal agreement between Kiostart LLC and the entity or sole proprietor on whose behalf a Kiostart LLC account is created (&ldquo;you&rdquo; and &ldquo;your&rdquo;) to receive certain Kiosk, payment processing, data, technology and analytics, or other business services offered by Kiostart LLC and its Affiliates. This Agreement states the terms and conditions that apply to your use of the Services.<br />
    <br />
    This Agreement is effective upon the date you first access or use the Services (&ldquo;Effective Date&rdquo;) and continues until you or Kiostart LLC terminates it (this period, the &ldquo;Term&rdquo;). Capitalized terms used in this Agreement that are not defined inline are defined in the Definitions.<br />
    <br />
    As referenced in Section 13 of the General Terms, any dispute between you and Kiostart LLC is subject to a class action waiver and must be resolved by individual binding arbitration. Please read the arbitration provision in this Agreement as it affects your rights under this Agreement.<br />
    <br />
    General Terms<br />
    <br />
    Last modified: August 22, 2022<br />
    <br />
    You and Kiostart LLC agree as follows<br />
    <br />
    1. Your Kiostart LLC Account.<br />
    <br />
    1.1 Eligibility.<br />
    Only businesses (including sole proprietors) and non-profit organizations located in the United States are eligible to apply for a Kiostart LLC Account and use the Services. Kiostart LLC and its Affiliates may provide Services to you or your Affiliates in other countries or regions under separate agreements. You and your Representative must not attempt to create a Kiostart LLC Account on behalf of or for the benefit of a user whose use of the Kiostart LLC services was suspended or terminated by Kiostart LLC, unless Kiostart LLC approves otherwise.<br />
    <br />
    1.2 Business Representative.<br />
    You and your Representative individually affirm to Kiostart LLC that (a) your Representative is authorized to provide User Information on your behalf and to bind you to this Agreement; and (b) your Representative is an executive officer, senior manager or otherwise has significant responsibility for the control, management or direction of your business. Kiostart LLC may require you or your Representative to provide additional information or documentation demonstrating your Representative&rsquo;s authority.<br />
    <br />
    1.3 Sole Proprietors.<br />
    If you are a sole proprietor, you and your Representative also affirm that your Representative is personally responsible and liable for your use of the Services and your obligations to Customers, including payment of amounts you owe under this Agreement.<br />
    <br />
    1.4 Age Requirements.<br />
    If you are a sole proprietor, and you are not old enough to enter into a contract on your own behalf (which is commonly but not always 18 years old), but you are 13 years old or older, your Representative must be your parent or legal guardian. If you are a legal entity that is owned, directly or indirectly, by an individual who is not old enough to enter into a contract on their own behalf, but the individual is 13 years old or older, your Representative must obtain the consent of either your board or an authorized officer. The approving board, authorized officer, parent or legal guardian is responsible to Kiostart LLC and is legally bound to this Agreement as if it had agreed to this Agreement itself. You must not use the Services if you are under 13 years of age.<br />
    <br />
    2. Services and Support.<br />
    <br />
    2.1 Services.<br />
    Kiostart LLC (and its Affiliates, as applicable) will make available to you the Services, including those described in the applicable Services Terms, and, if applicable, give you access to a Kiostart LLC Dashboard.<br />
    <br />
    2.2 Services Terms; Order of Precedence.<br />
    The Services Terms contain specific terms governing the parties&rsquo; rights and obligations related to the Services described in those Services Terms. If there are no Services Terms for a particular Kiostart LLC service, then only these General Terms govern. By accessing or using a Service, you agree to comply with the applicable Services Terms. If any term in these General Terms conflicts with a term in any Services Terms or set of terms incorporated by reference into this Agreement, then unless terms of lower precedence expressly state to the contrary, the order of precedence is: (a) the Services Terms; (b) these General Terms; and (c) all terms incorporated by reference into this Agreement. Your access to or use of the Services may also be subject to additional terms to which you agree through the Kiostart LLC Dashboard.<br />
    <br />
    2.3 Service Modifications and Updates.<br />
    Kiostart LLC may modify the Services and Kiostart LLC Technology at any time, including adding or removing functionality or imposing conditions on use of the Services. Kiostart LLC will notify you of material adverse changes in, deprecations to, or removal of functionality from, Services or Kiostart LLC Technology that you are using. Kiostart LLC is not obligated to provide any Updates. However, if Kiostart LLC makes an Update available, you must fully install the Update by the date or within the time period stated in Kiostart LLC&rsquo;s notice; or, if there is no date or period stated in the notice, then no later than 30 days after the date of the notice.<br />
    <br />
    2.4 Subcontracting.<br />
    Kiostart LLC may subcontract its obligations under this Agreement to third parties.<br />
    <br />
    2.5 Services Restrictions.<br />
    You may only use the Services for business purposes. You must not, and must not enable or allow any third party to:<br />
    (a) use the Services for personal, family or household purposes;<br />
    (b) act as service bureau or pass-through agent for the Services with no added value to Customers;<br />
    (c) work around any of the technical limitations of the Services or enable functionality that is disabled or prohibited, or access or attempt to access non-public Kiostart LLC systems, programs, data, or services;<br />
    (d) except as Law permits, reverse engineer or attempt to reverse engineer the Services or Kiostart LLC Technology;<br />
    (e) use the Services to engage in any activity that is illegal, fraudulent, deceptive or harmful;<br />
    (f) perform or attempt to perform any action that interferes with the normal operation of the Services or affects other Kiostart LLC users&rsquo; use of Kiostart LLC services; or<br />
    (g) copy, reproduce, republish, upload, post, transmit, resell, or distribute in any way, any part of the Services, Documentation, or the Kiostart LLC Website except as permitted by Law.<br />
    <br />
    2.6 Beta Services.<br />
    (a) Classification. Kiostart LLC may classify certain Kiostart LLC services or Kiostart LLC Technology, including a particular release or feature, as Beta. A Kiostart LLC service may be generally available in some circumstances (e.g., in some countries or regions) while still classified as Beta in other circumstances.<br />
    (b) Nature of Beta Services. By their nature, Beta Services may be feature-incomplete or contain bugs. Kiostart LLC may describe limitations that exist within a Beta Service; however, your reliance on the accuracy or completeness of these descriptions is at your own risk. You should not use Beta Services in a production environment until and unless you understand and accept the limitations and flaws that may be present in the Beta Services.<br />
    (c) Feedback. Unless Kiostart LLC otherwise agrees in writing, your use of Beta Services is confidential, and you must provide timely Feedback on the Beta Services in response to Kiostart LLC requests.<br />
    (d) Availability During Beta Period. Kiostart LLC may suspend or terminate your access to any Beta Services at any time.<br />
    <br />
    2.7 Support.<br />
    Kiostart LLC will provide you with support to resolve general issues relating to your Kiostart LLC Account and your use of the Services through resources and documentation that Kiostart LLC makes available on the Kiostart LLC Website and in the Documentation. Kiostart LLC&rsquo;s support is also available by contacting Kiostart LLC at contact us. Kiostart LLC is not responsible for providing support to Customers.<br />
    <br />
    2.8 Third-Party Services.<br />
    Kiostart LLC may reference, enable you to access, or promote (including on the Kiostart LLC Website) Third-Party Services. These Third-Party Services are provided for your convenience only and Kiostart LLC does not approve, endorse, or recommend any Third-Party Services to you. Your access and use of any Third-Party Service is at your own risk and Kiostart LLC disclaims all responsibility and liability for your use of any Third-Party Service. Third-Party Services are not Services and are not governed by this Agreement or Kiostart LLC&rsquo;s Privacy Policy. Your use of any Third-Party Service, including those linked from the Kiostart LLC Website, is subject to that Third-Party Service&rsquo;s own terms of use and privacy policies (if any).<br />
    <br />
    3. Information; Your Business.<br />
    <br />
    3.1 User Information.<br />
    Upon Kiostart LLC&rsquo;s request, you must provide User Information to Kiostart LLC in a form satisfactory to Kiostart LLC. You must keep the User Information in your Kiostart LLC Account current. You must promptly update your Kiostart LLC Account with any changes affecting you, the nature of your business activities, your Representative, beneficial owners, principals, or any other pertinent information. You must immediately notify Kiostart LLC, and provide to Kiostart LLC updated User Information, if (a) you experience or anticipate experiencing a Change of Control; (b) you experience or anticipate experiencing a material change in your business or financial condition, including if you experience or are likely to experience an Insolvency Proceeding; (c) the regulatory status of the business for which you are using the Services changes, including if it becomes subject, or no longer subject, to regulatory oversight; or (d) a Governmental Authority has notified you that you or your business is the subject of investigative action.<br />
    <br />
    3.2 Information Retrieved by Kiostart LLC.<br />
    You authorize Kiostart LLC to retrieve information about you and your business from Kiostart LLC&rsquo;s service providers and other third parties, including credit reporting agencies, banking partners and information bureaus, and you authorize and direct those third parties to compile and provide that information to Kiostart LLC. This information may include your, or your Representative&rsquo;s, name, addresses, credit history, banking relationships, and financial history.<br />
    <br />
    4. Services Fees; Taxes.<br />
    <br />
    4.1 Services Fees.<br />
    The Fees are stated on the Kiostart LLC Pricing (As of January 2022, 2.7% + 25 Cents per transaction towards customer) or can be changed to the user, unless you and Kiostart LLC otherwise agree in writing. Kiostart LLC may revise the Fees at any time. If Kiostart LLC revises the Fees for a Service that you are currently using, Kiostart LLC will notify you at least 30 days before the revised Fees apply to you.<br />
    <br />
    4.2 Collection of Fees and Other Amounts.<br />
    You must pay, or ensure that Kiostart LLC is able to collect, Fees and other amounts you owe under this Agreement when due. Kiostart LLC may deduct, recoup or setoff Fees and other amounts you owe under this Agreement, or under any other agreements you have with Kiostart LLC or any of its Affiliates, from your Kiostart LLC Account balance, or invoice you for those amounts. If you fail to pay invoiced amounts when due, if your Kiostart LLC Account balance is negative or does not contain funds sufficient to pay amounts that you owe under this Agreement, or under any other agreement with Kiostart LLC or any of its Affiliates, or if Kiostart LLC is unable to collect amounts due from your Kiostart LLC Account balance, then Kiostart LLC may, to the extent Law permits, deduct, recoup or setoff those amounts from: (a) if established and applicable, each Reserve; (b) funds payable by Kiostart LLC or its Affiliate to you or your Affiliate; (c) if established, each User Affiliate Reserve; (d) each User Bank Account; and (e) the Kiostart LLC account balance of each Kiostart LLC account that Kiostart LLC determines, acting reasonably, is associated with you or your Affiliate. If the currency of the amount being deducted is different from the currency of the amount you owe, Kiostart LLC may deduct, recoup or setoff an amount equal to the amount owed (using Kiostart LLC&rsquo;s conversion rate) together with any fees Kiostart LLC incurs in making the conversion.<br />
    <br />
    4.3 Debit Authorization.<br />
    Without limiting Section 4.2, you authorize Kiostart LLC to debit each User Bank Account without separate notice, and according to the applicable User Bank Account Debit Authorization, to collect amounts you owe under this Agreement. If Kiostart LLC is unable to collect those amounts by debiting a User Bank Account, then you immediately grant to Kiostart LLC a new, original authorization to debit each User Bank Account without notice and according to the applicable User Bank Account Debit Authorization. Kiostart LLC may rely on this authorization to make one or more attempts to collect all or a subset of the amounts owed. Your authorization under this Section 4.3 will remain in full force and effect until (a) all of your Kiostart LLC Accounts are closed; or (b) all fees and other amounts you owe under this Agreement are paid, whichever occurs later. If applicable debit scheme authorization rules grant you the right to revoke your debit authorization, then to the extent Law permits, you waive that right.<br />
    <br />
    4.4 Taxes.<br />
    Kiostart LLC&rsquo;s fees exclude all Taxes, except as the Kiostart LLC Pricing Page states to the contrary. You have sole responsibility and liability for:<br />
    (a) determining which, if any, Taxes or fees apply to the sale of your products and services, acceptance of donations, or payments you make or receive in connection with your use of the Services; and<br />
    (b) assessing, collecting, reporting and remitting Taxes for your business. If Kiostart LLC is required to withhold any Taxes, Kiostart LLC may deduct those Taxes from amounts otherwise owed to you and pay those Taxes to the appropriate taxing authority. If you are exempt from paying, or are otherwise eligible to pay a reduced rate on, those Taxes, you may provide to Kiostart LLC an original certificate that satisfies applicable legal requirements attesting to your tax-exempt status or reduced rate eligibility, in which case Kiostart LLC will not deduct the Taxes covered by the certificate. You must provide accurate information regarding your tax affairs as Kiostart LLC reasonably requests, and must promptly notify Kiostart LLC if any information that Kiostart LLC prepopulates is inaccurate or incomplete. Kiostart LLC may send documents to you and taxing authorities for transactions processed using the Services. Specifically, Law may require Kiostart LLC to file periodic informational returns with taxing authorities related to your use of the Services. Kiostart LLC may send tax-related information electronically to you.<br />
    <br />
    5. User Bank Accounts; Funds.<br />
    <br />
    5.1 User Bank Accounts; Prohibition on Grant or Assignment.<br />
    You must designate at least one User Bank Account in connection with the Services. Kiostart LLC may debit and credit a User Bank Account as described in this Agreement. You must not grant or assign to any third party any lien on or interest in funds that may be owed to you under this Agreement until the funds are deposited into a User Bank Account.<br />
    <br />
    5.2 Dormant Accounts.<br />
    If you leave any funds dormant in a Kiostart LLC Account and you do not instruct Kiostart LLC on where to send them, Kiostart LLC may deem the funds abandoned by you and deliver them to the appropriate Governmental Authority. However, if Law requires, Kiostart LLC will attempt to notify you before doing so.<br />
    <br />
    6. Termination; Suspension; Survival.<br />
    <br />
    6.1 Termination.<br />
    (a) Your Termination. You may terminate this Agreement at any time by closing your Kiostart LLC Account. To do so, you must contact us through &ldquo;Contact Us&rdquo; page in Kiostart.com and stop using the Services. If after termination you use the Services again, this Agreement will apply with an Effective Date that is the date on which you first use the Services again.<br />
    (b) Kiostart LLC Termination. Kiostart LLC may terminate this Agreement (or any part) or close your Kiostart LLC Account at any time for any or no reason (including if any event listed in Sections 6.2(a)&ndash;(i) of these General Terms occurs) by notifying you. In addition, Kiostart LLC may terminate this Agreement (or relevant part) for cause if Kiostart LLC exercises its right to suspend Services (including under Section 6.2 of these General Terms) and does not reinstate the suspended Services within 30 days.<br />
    (c) Termination for Material Breach. A party may terminate this Agreement immediately upon notice to the other party if the other party materially breaches this Agreement, and if capable of cure, does not cure the breach within 10 days after receiving notice specifying the breach. If the material breach affects only certain Services, the non-breaching party may choose to terminate only the affected Services.<br />
    (d) Effect on Other Agreements. Unless stated to the contrary, termination of this Agreement will not affect any other agreement between the parties or their Affiliates.<br />
    <br />
    6.2 Suspension.<br />
    Kiostart LLC may immediately suspend providing any or all Services to you, and your access to the Kiostart LLC Technology, if:<br />
    (a) Kiostart LLC believes it will violate any Law, Financial Services Terms or Governmental Authority requirement;<br />
    (b) a Governmental Authority or a Financial Partner requires or directs Kiostart LLC to do so;<br />
    (c) you do not update in a timely manner your implementation of the Services or Kiostart LLC Technology to the latest production version Kiostart LLC recommends or requires;<br />
    (d) you do not respond in a timely manner to Kiostart LLC&rsquo;s request for User Information or do not provide Kiostart LLC adequate time to verify and process updated User Information;<br />
    (e) you breach this Agreement or any other agreement between the parties;<br />
    (f) you breach any Financial Services Terms;<br />
    (g) you enter an Insolvency Proceeding;<br />
    (h) Kiostart LLC believes that you are engaged in a business, trading practice or other activity that presents an unacceptable risk to Kiostart LLC; or<br />
    (i) Kiostart LLC believes that your use of the Services (i) is or may be harmful to Kiostart LLC or any third party; (ii) presents an unacceptable level of credit risk; (iii) increases, or may increase, the rate of fraud that Kiostart LLC observes; (iv) degrades, or may degrade, the security, stability or reliability of the Kiostart LLC services, Kiostart LLC Technology or any third party&rsquo;s system (e.g., your involvement in a distributed denial of service attack); (v) enables or facilitates, or may enable or facilitate, illegal or prohibited transactions; or (vi) is or may be unlawful.<br />
    <br />
    6.3 Survival.<br />
    The following will survive termination of this Agreement:<br />
    (a) provisions that by their nature are intended to survive termination (including Sections 4, 7.2, 9.4, 11, 12 and 13 of these General Terms); and<br />
    (b) provisions that allocate risk, or limit or exclude a party&rsquo;s liability, to the extent necessary to ensure that a party&rsquo;s potential liability for acts and omissions that occur during the Term remains unchanged after this Agreement terminates.<br />
    <br />
    7. Use Rights.<br />
    <br />
    7.1 Use of Services.<br />
    Subject to the terms of this Agreement, Kiostart LLC grants you a worldwide, non-exclusive, non-transferable, non-sublicensable, royalty-free license during the Term to access the Documentation, and access and use the Kiostart LLC Technology, as long as your access and use is (a) solely as necessary to use the Services; (b) solely for your business purposes; and (c) in compliance with this Agreement and the Documentation.<br />
    <br />
    7.2 Feedback.<br />
    During the Term, you and your Affiliates may provide Feedback to Kiostart LLC or its Affiliates. You grant, on behalf of yourself and your Affiliates, to Kiostart LLC and its Affiliates a perpetual, worldwide, non-exclusive, irrevocable, royalty-free license to exploit that Feedback for any purpose, including developing, improving, manufacturing, promoting, selling and maintaining the Kiostart LLC services. All Feedback is Kiostart LLC&rsquo;s confidential information.<br />
    <br />
    7.3 Marks Usage.<br />
    Subject to the terms of this Agreement, each party grants to the other party and its Affiliates a worldwide, non-exclusive, non-transferable, non-sublicensable, royalty-free license during the Term to use the Marks of the grantor party or its Affiliate solely to identify Kiostart LLC as your service provider. Accordingly, Kiostart LLC and its Affiliates may use those Marks:<br />
    (a) on Kiostart LLC webpages and apps that identify Kiostart LLC&rsquo;s customers;<br />
    (b) in Kiostart LLC sales/marketing materials and communications; and<br />
    (c) in connection with promotional activities to which the parties agree in writing.<br />
    When using Marks of Kiostart LLC or its Affiliate, you must contact Kiostart LLC in writing and all additional usage terms and guidelines that Kiostart LLC provides to you in writing (if any). All goodwill generated from the use of Marks will inure to the sole benefit of the Mark owner.<br />
    <br />
    7.4 No Joint Development; Reservation of Rights.<br />
    Any joint development between the parties will require and be subject to a separate agreement between the parties. Nothing in this Agreement assigns or transfers ownership of any IP Rights to the other party. All rights (including IP Rights) not expressly granted in this Agreement are reserved.<br />
    <br />
    8. Privacy and Data Use.<br />
    <br />
    8.1 Privacy Policies.<br />
    Each party will make available a Privacy Policy that complies with Law. Kiostart LLC&rsquo;s Privacy Policy explains how and for what purposes Kiostart LLC collects, uses, retains, discloses and safeguards the Personal Data you provide to Kiostart LLC.<br />
    <br />
    8.2 Personal Data.<br />
    When you provide Personal Data to Kiostart LLC, or authorize Kiostart LLC to collect Personal Data, you must provide all necessary notices to and obtain all necessary rights and consents from the applicable individuals (including your Customers) sufficient to enable Kiostart LLC to lawfully collect, use, retain and disclose the Personal Data in the ways this Agreement and Kiostart LLC&rsquo;s Privacy Policy describe. Kiostart LLC will not sell or lease Personal Data that Kiostart LLC receives from you to any third party.<br />
    <br />
    8.3 Protected Data.<br />
    To the extent Law permits, Kiostart LLC will use Protected Data to (a) secure, provide, provide access to, and update the Kiostart LLC services; (b) fulfill its obligations under Law, and comply with Financial Partner and Governmental Authority requirements and requests; and (c) prevent and mitigate fraud, financial loss, and other harm. Kiostart LLC is not obligated to retain Protected Data after the Term, except as (w) required by Law; (x) required for Kiostart LLC to perform any post-termination obligations; (y) this Agreement otherwise states; or (z) the parties otherwise agree in writing. You are responsible for being aware of and complying with Law governing your use, storage and disclosure of Protected Data.<br />
    <br />
    8.4 Kiostart LLC Data.<br />
    You may use the Kiostart LLC Data only as this Agreement and other agreements between Kiostart LLC and you (or their Affiliates) permit.<br />
    <br />
    8.5 Data Processing Agreement.<br />
    The Data Processing Agreement, including the Approved Data Transfer Mechanisms (as defined in the Data Processing Agreement) that apply to your use of the Services and transfer of Personal Data, is incorporated into this Agreement by this reference. Each party will comply with the terms of the Data Processing Agreement.<br />
    <br />
    8.6 Use of Fraud Signals.<br />
    If Kiostart LLC provides you with information regarding the possibility or likelihood that a transaction may be fraudulent or that an individual cannot be verified, Kiostart LLC may incorporate your subsequent actions and inactions into Kiostart LLC&rsquo;s fraud and verification model, for the purpose of identifying future potential fraud.<br />
    <br />
    9. Data Security.<br />
    <br />
    9.1 Controls.<br />
    Each party will maintain commercially reasonable administrative, technical, and physical controls designed to protect data in its possession or under its control from unauthorized access, accidental loss and unauthorized modification. You are responsible for implementing administrative, technical, and physical controls that are appropriate for your business.<br />
    <br />
    9.2 PCI-DSS.<br />
    Kiostart LLC will make reasonable efforts to provide the Services in a manner consistent with PCI-DSS requirements that apply to Kiostart LLC.<br />
    <br />
    9.3 Kiostart LLC Account Credentials.<br />
    You must prevent any Credential Compromise, and otherwise ensure that your Kiostart LLC Account is not used or modified by anyone other than you and your representatives. If a Credential Compromise occurs, you must promptly notify and cooperate with Kiostart LLC, including by providing information that Kiostart LLC requests. Any act or failure to act by Kiostart LLC will not diminish your responsibility for Credential Compromises.<br />
    <br />
    9.4 Data Breach.<br />
    You must notify Kiostart LLC immediately if you become aware of an unauthorized acquisition, modification, disclosure, access to, or loss of Personal Data on your systems.<br />
    <br />
    9.5 Audit Rights.<br />
    If Kiostart LLC believes that a compromise of data has occurred on your systems, website, or app, Kiostart LLC may require you to permit a Kiostart LLC approved third-party auditor to audit the security of your systems and facilities. You must fully cooperate with all auditor requests for information or assistance. As between the parties, you are responsible for all costs and expenses associated with these audits. Kiostart LLC may share with Financial Services Partners any report the auditor issues.<br />
    <br />
    10. Representations and Warranties.<br />
    <br />
    10.1 Representations and Warranties.<br />
    You represent as of the Effective Date, and warrant at all times during the Term, that:<br />
    (a) you have the right, power, and ability to enter into and perform under this Agreement;<br />
    (b) you are a business (which may be a sole proprietor) or a non-profit organization located in the United States and are eligible to apply for a Kiostart LLC account and use the Services;<br />
    (c) you have, and comply with, all necessary rights, consents, licenses, and approvals for the operation of your business and to allow you to access and use the Services in compliance with this Agreement and Law;<br />
    (d) your employees, contractors and agents are acting consistently with this Agreement;<br />
    (e) your use of the Services does not violate or infringe upon any third-party rights, including IP Rights, and you have obtained, as applicable, all necessary rights and permissions to enable your use of Content in connection with the Services;<br />
    (f) you are authorized to initiate settlements to and debits from the User Bank Accounts;<br />
    (g) you comply with Law with respect to your business, your use of the Services and Kiostart LLC Technology, and the performance of your obligations in this Agreement;<br />
    (h) you comply with the Documentation;<br />
    (i) you comply with the Financial Services Terms, and are not engaging in activity that any Financial Partner identifies as damaging to its brand;<br />
    (j) you do not use the Services to conduct a Restricted Business, transact with any Restricted Business, or enable any individual or entity (including you) to benefit from any Restricted Business;<br />
    (k) you own each User Bank Account, and each User Bank Account is located in a Kiostart LLC-approved country for the location of your Kiostart LLC Account, as described in the Documentation; and<br />
    (l) all information you provide to Kiostart LLC, including the User Information, is accurate and complete.<br />
    10.2 Scope of Application.<br />
    Unless this Agreement states to the contrary elsewhere, the representations and warranties in Sections 10.1 and 15.9 of these General Terms apply generally to your performance under this Agreement. Additional representations and warranties that apply only to a specific Service may be included in the Services Terms.<br />
    <br />
    11. Indemnity.<br />
    <br />
    11.1 Kiostart LLC IP Infringement.<br />
    (a) Defense and Indemnification. Kiostart LLC will defend you against any IP Claim and indemnify you against all IP Claim Losses.<br />
    (b) Limitations. Kiostart LLC&rsquo;s obligations in this Section 11.1 do not apply if the allegations do not specify that the Kiostart LLC Technology, Services, or Mark of Kiostart LLC or its Affiliate is the basis of the IP Claim, or to the extent the IP Claim or IP Claim Losses arise out of:<br />
    (i) the use of the Kiostart LLC Technology or Services in combination with software, hardware, data, or processes not provided by Kiostart LLC;<br />
    (ii) failure to implement, maintain and use the Kiostart LLC Technology or Services in accordance with the Documentation and this Agreement;<br />
    (iii) your breach of this Agreement; or<br />
    (iv) your negligence, fraud or willful misconduct.<br />
    (c) Process. You must promptly notify Kiostart LLC of the IP Claim for which you seek indemnification; however, any delay or failure to notify will not relieve Kiostart LLC of its obligations under this Section 11, except to the extent Kiostart LLC has been prejudiced by the delay or failure. You must give Kiostart LLC sole control and authority to defend and settle the IP Claim, but (i) you may participate in the defense and settlement of the IP Claim with counsel of your own choosing at your own expense; and (ii) Kiostart LLC will not enter into any settlement that imposes any obligation on you (other than payment of money, which Kiostart LLC will pay) without your consent. You must reasonably assist Kiostart LLC in defending the IP Claim.<br />
    (d) Other Kiostart LLC Actions. Kiostart LLC may in its discretion and at no additional expense to you:<br />
    (i) modify the Kiostart LLC Technology or Services so that they are no longer claimed to infringe or misappropriate IP Rights of a third party;<br />
    (ii) replace the affected Kiostart LLC Technology or Services with a non-infringing alternative;<br />
    (iii) obtain a license for you to continue to use the affected Kiostart LLC Technology, Services, or Mark; or<br />
    (iv) terminate your use of the affected Kiostart LLC Technology, Services, or Mark upon 30 days&rsquo; notice.<br />
    (e) Exclusive Remedy. This Section 11.1 states Kiostart LLC&rsquo;s sole liability, and your sole and exclusive right and remedy, for infringement by the Kiostart LLC Technology, Services, or Marks of Kiostart LLC or its Affiliate, including any IP Claim.<br />
    <br />
    11.2 User Indemnification.<br />
    (a) Defense. You will defend the Kiostart LLC Parties against any Claim made against any of the Kiostart LLC Parties to the extent arising out of or relating to:<br />
    (i) your breach of any of your representations, warranties or obligations under this Agreement;<br />
    (ii) your use of the Services, including use of Personal Data;<br />
    (iii) an allegation that any of the Marks you license to Kiostart LLC, or your Content, infringes on or misappropriates the rights, including IP Rights, of the third party making the Claim; or<br />
    (iv) a User Party&rsquo;s negligence, willful misconduct or fraud.<br />
    (b) Indemnification. You will indemnify the Kiostart LLC Parties against all Kiostart LLC Losses arising out of or relating to Claims described in this Section 11.2.<br />
    <br />
    12. Disclaimer and Limitations on Liability.<br />
    The following disclaimer and limitations will apply notwithstanding the failure of the essential purpose of any limited remedy.<br />
    <br />
    12.1 Disclaimer.<br />
    Kiostart LLC provides the Services and Kiostart LLC Technology &ldquo;AS IS&rdquo; and &ldquo;AS AVAILABLE&rdquo;. Except as expressly stated as a &ldquo;warranty&rdquo; in this Agreement, and to the maximum extent permitted by Law, Kiostart LLC does not make any, and expressly disclaims all, express and implied warranties and statutory guarantees with respect to its performance under this Agreement, the Services, Financial Partners, the Kiostart LLC Technology, Kiostart LLC Data and the Documentation, including as related to availability, the implied warranties of fitness for a particular purpose, merchantability and non-infringement, and the implied warranties arising out of any course of dealing, course of performance or usage in trade. The Kiostart LLC Parties are not liable for any losses, damages, or costs that you or others may suffer arising out of or relating to hacking, tampering, or other unauthorized access or use of the Services, your Kiostart LLC Account, or Protected Data, or your failure to use or implement anti-fraud or data security measures. Further, the Kiostart LLC Parties are not liable for any losses, damages, or costs that you or others may suffer arising out of or relating to (a) your access to, or use of, the Services in a way that is inconsistent with this Agreement or the Documentation; (b) unauthorized access to servers or infrastructure, or to Kiostart LLC Data or Protected Data; (c) Service interruptions or stoppages; (d) bugs, viruses, or other harmful code that may be transmitted to or through the Service (e) errors, inaccuracies, omissions or losses in or to any Protected Data or Kiostart LLC Data; (f) Content; or (g) the defamatory, offensive, or illegal conduct of others.<br />
    <br />
    12.2 LIMITATIONS ON LIABILITY.<br />
    (a) Indirect Damages. To the maximum extent permitted by Law, the Kiostart LLC Parties will not be liable to you or your Affiliates in relation to this Agreement or the Services during and after the Term, whether in contract, negligence, strict liability, tort or other legal or equitable theory, for any lost profits, personal injury, property damage, loss of data, business interruption, indirect, incidental, consequential, exemplary, special, reliance, or punitive damages, even if these losses, damages, or costs are foreseeable, and whether or not you or the Kiostart LLC Parties have been advised of their possibility.<br />
    (b) General Damages. To the maximum extent permitted by Law, the Kiostart LLC Parties will not be liable to you or your Affiliates in relation to this Agreement or the Services during and after the Term, whether in contract, negligence, strict liability, tort or other legal or equitable theory, for losses, damages, or costs exceeding in the aggregate the greater of (i) the total amount of Fees you paid to Kiostart LLC (excluding all pass-through fees levied by Financial Partners) during the 3-month period immediately preceding the event giving rise to the liability; and (ii) $500 USD.<br />
    <br />
    13. Dispute Resolution; Agreement to Arbitrate.<br />
    <br />
    13.1 Binding Arbitration.<br />
    (a) All disputes, claims and controversies, whether based on past, present or future events, arising out of or relating to statutory or common law claims, the breach, termination, enforcement, interpretation or validity of any provision of this Agreement, and the determination of the scope or applicability of your agreement to arbitrate any dispute, claim or controversy originating from this Agreement, but specifically excluding any dispute principally related to either party&rsquo;s IP Rights, will be determined by binding arbitration.<br />
    (b) The American Arbitration Association will administrate the arbitration under its Commercial Arbitration Rules. The Expedited Procedures of the American Arbitration Association&rsquo;s Commercial Arbitration Rules will apply for cases in which no disclosed claim or counterclaim exceeds $75,000 USD (excluding interest, attorneys&rsquo; fees and arbitration fees and costs). Where no party&rsquo;s claim exceeds $25,000 USD (excluding interest, attorneys&rsquo; fees and arbitration fees and costs), and in other cases where the parties agree, Section E-6 of the Expedited Procedures of the American Arbitration Association&rsquo;s Commercial Arbitration Rules will apply.<br />
    (c) The arbitrator will apply the substantive law of the State of California and of the United States, excluding their conflict or choice of law rules.<br />
    (d) Nothing in this Agreement will preclude the parties from seeking provisional remedies in aid of arbitration from a court of appropriate jurisdiction.<br />
    (e) The parties acknowledge that this Agreement evidences a transaction involving interstate commerce. Notwithstanding the provisions in this Section 13 referencing applicable substantive law, the Federal Arbitration Act (9 U.S.C. Sections 1-16) will govern any arbitration conducted in accordance with this Agreement.<br />
    <br />
    13.2 Arbitration Procedure.<br />
    (a) A party must notify the other party of its intent to commence arbitration prior to commencing arbitration. The notice must specify the date on which the arbitration demand is intended to be filed, which must be at least 30 days after the date of the notice. During this time period, the parties will meet for the purpose of resolving the dispute prior to commencing arbitration.<br />
    (b) Subject to Section 13.2(a), each party may commence arbitration by providing to the American Arbitration Association and the other party to the dispute a written demand for arbitration, stating the subject of the dispute and the relief requested.<br />
    (c) Subject to the disclaimers and limitations of liability stated in this Agreement, the appointed arbitrators may award monetary damages and any other remedies allowed by the laws of the State of California. In making a determination, the arbitrator will not have the authority to modify any term of this Agreement. The arbitrator will deliver a reasoned, written decision with respect to the dispute to each party, who will promptly act in accordance with the arbitrator&rsquo;s decision. Any award (including interim or final remedies) may be confirmed in or enforced by a state or federal court located in San Francisco, California. The decision of the arbitrator will be final and binding on the parties, and will not be subject to appeal or review.<br />
    (d) In accordance with the AAA Rules, the party initiating the arbitration is responsible for paying the applicable filing fee. Each party will advance one-half of the fees and expenses of the arbitrator, the costs of the attendance of the arbitration reporter at the arbitration hearing, and the costs of the arbitration facility. In any arbitration arising out of or relating to this Agreement, the arbitrator will award to the prevailing party, if any, the costs and attorneys&rsquo; fees reasonably incurred by the prevailing party in connection with those aspects of its claims or defenses on which it prevails, and any opposing awards of costs and legal fees awards will be offset.<br />
    <br />
    13.3 Confidentiality.<br />
    The parties will keep confidential the existence of the arbitration, the arbitration proceeding, the hearing and the arbitrator&rsquo;s decision, except (a) as necessary to prepare for and conduct the arbitration hearing on the merits; (b) in connection with a court application for a preliminary remedy, or confirmation of an arbitrator&rsquo;s decision or its enforcement; (c) Kiostart LLC may disclose the arbitrator&rsquo;s decision in confidential settlement negotiations; (d) each party may disclose as necessary to professional advisors that are subject to a strict duty of confidentiality; and (e) as Law otherwise requires. The parties, witnesses, and arbitrator will treat as confidential and will not disclose to any third person (other than witnesses or experts) any documentary or other evidence produced in any arbitration, except as Law requires or if the evidence was obtained from the public domain or was otherwise obtained independently from the arbitration.<br />
    <br />
    13.4 Conflict of Rules.<br />
    In the case of a conflict between the provisions of this Section 13 and the AAA Rules, the provisions of this Section 13 will prevail.<br />
    <br />
    13.5 Class Waiver.<br />
    To the extent Law permits, any dispute arising out of or relating to this Agreement, whether in arbitration or in court, will be conducted only on an individual basis and not in a class, consolidated or representative action. Notwithstanding any other provision of this Agreement or the AAA Rules, disputes regarding the interpretation, applicability, or enforceability of this class waiver may be resolved only by a court and not by an arbitrator. If this waiver of class or consolidated actions is deemed invalid or unenforceable, neither party is entitled to arbitration.<br />
    <br />
    13.6 No Jury Trial.<br />
    If for any reason a claim or dispute proceeds in court rather than through arbitration, each party knowingly and irrevocably waives any right to trial by jury in any action, proceeding or counterclaim arising out of or relating to this Agreement or any of the transactions contemplated between the parties.<br />
    <br />
    14. Modifications to this Agreement.<br />
    Kiostart LLC may modify all or any part of this Agreement at any time by posting a revised version of the modified General Terms (including the introduction to this Agreement and the Definitions), Services Terms or terms incorporated by reference on the Kiostart LLC Legal Page or by notifying you. The modified Agreement is effective upon posting or, if Kiostart LLC notifies you, as stated in the notice. By continuing to use Services after the effective date of any modification to this Agreement, you agree to be bound by the modified Agreement. It is your responsibility to check the Kiostart LLC Legal Page regularly for modifications to this Agreement. Kiostart LLC last modified these General Terms on the date listed under the &ldquo;General Terms&rdquo; heading, and each set of Services Terms on the date listed under the heading for those terms. Except as this Agreement (including in this Section 14) otherwise allows, this Agreement may not be modified except in a writing signed by the parties.<br />
    <br />
    <br />
    15. General Provisions.<br />
    <br />
    15.1 Notices and Communications.<br />
    (a) Notices to Kiostart LLC. Unless this Agreement states otherwise, for notices to Kiostart LLC, you must contact us. A notice you send to Kiostart LLC is deemed to be received when Kiostart LLC receives it.<br />
    (b) Communications to you. In addition to sending you a Communication electronically as Section 15.1 of these General Terms describes, Kiostart LLC may send you Communications by physical mail or delivery service to the postal address listed in the applicable Kiostart LLC Account. A Communication Kiostart LLC sends to you is deemed received by you on the earliest of (i) when posted to the Kiostart LLC Website or Kiostart LLC Dashboard; (ii) when sent by text message or email; and (iii) three business days after being sent by physical mail or when delivered, if sent by delivery service.<br />
    <br />
    15.2 Legal Process.<br />
    Kiostart LLC may respond to and comply with any Legal Process that Kiostart LLC believes to be valid. Kiostart LLC may deliver or hold any funds or, subject to the terms of Kiostart LLC&rsquo;s Privacy Policy, any data as required under the Legal Process, even if you are receiving funds or data on behalf of other parties. Where Law permits, Kiostart LLC will notify you of the Legal Process by sending a copy to the email address in the applicable Kiostart LLC Account. Kiostart LLC is not responsible for any losses, whether direct or indirect, that you may incur as a result of Kiostart LLC&rsquo;s response or compliance with a Legal Process in accordance with this Section 15.3.<br />
    <br />
    15.3 Collection Costs.<br />
    You are liable for all costs Kiostart LLC incurs during collection of any amounts you owe under this Agreement, in addition to the amounts you owe. Collection costs may include attorneys&rsquo; fees and expenses, costs of any arbitration or court proceeding, collection agency fees, applicable interest, and any other related cost.<br />
    <br />
    15.4 Interpretation.<br />
    (a) No provision of this Agreement will be construed against any party on the basis of that party being the drafter.<br />
    (b) References to &ldquo;includes&rdquo; or &ldquo;including&rdquo; not followed by &ldquo;only&rdquo; or a similar word mean &ldquo;includes, without limitation&rdquo; and &ldquo;including, without limitation,&rdquo; respectively.<br />
    (c) Except where expressly stated otherwise in a writing executed between you and Kiostart LLC, this Agreement will prevail over any conflicting policy or agreement for the provision or use of the Services.<br />
    (d) All references in this Agreement to any terms, documents, Law or Financial Services Terms are to those items as they may be amended, supplemented or replaced from time to time. All references to APIs and URLs are references to those APIs and URLs as they may be updated or replaced.<br />
    (e) The section headings of this Agreement are for convenience only, and have no interpretive value.<br />
    (f) Unless expressly stated otherwise, any consent or approval that may be given by a party (i) is only effective if given in writing and in advance; and (ii) may be given or withheld in the party&rsquo;s sole and absolute discretion.<br />
    (g) References to &ldquo;business days&rdquo; means weekdays on which banks are generally open for business. Unless specified as business days, all references in this Agreement to days, months or years mean calendar days, calendar months or calendar years.<br />
    (h) Unless expressly stated to the contrary, when a party makes a decision or determination under this Agreement, that party has the right to use its sole discretion in making that decision or determination.<br />
    (i) The United Nations Convention on Contracts for the International Sale of Goods will not apply to this Agreement.<br />
    <br />
    15.5 Waivers.<br />
    To be effective, a waiver must be in a writing signed by the waiving party. The failure of either party to enforce any provision of this Agreement will not constitute a waiver of that party&rsquo;s rights to subsequently enforce the provision.<br />
    <br />
    15.6 Force Majeure.<br />
    Kiostart LLC and its Affiliates will not be liable for any losses, damages, or costs you suffer, or delays in Kiostart LLC or its Affiliates&rsquo; performance or non-performance, to the extent caused by a Force Majeure Event.<br />
    <br />
    15.7 Assignment.<br />
    You may not assign or transfer any obligation or benefit under this Agreement without Kiostart LLC&rsquo;s consent. Any attempt to assign or transfer in violation of the previous sentence will be void in each instance. If you wish to assign this Agreement, please contact us. Kiostart LLC may, without your consent, freely assign and transfer this Agreement, including any of its rights or obligations under this Agreement. This Agreement will be binding on, inure to the benefit of, and be enforceable by the parties and their permitted assigns.<br />
    <br />
    15.8 Export Control.<br />
    You must not use or otherwise export, re-export or transfer the Kiostart LLC Technology except as authorized by United States law and the laws of the jurisdiction(s) in which the Kiostart LLC Technology was distributed and obtained, including by providing access to Kiostart LLC Technology (a) to any individual or entity ordinarily resident in a High-Risk Jurisdiction; or (b) to any High-Risk Person. By using the Kiostart LLC Technology, you represent as of the Effective Date and warrant during the Term that you are not (x) located in or organized under the laws of any High-Risk Jurisdiction; (y) a High-Risk Person; or (z) owned 50% or more, or controlled, by individuals and entities (i) located in or, as applicable, organized under the laws of any High-Risk Jurisdiction; or (ii) any of whom or which is a High-Risk Person. You must not use the Kiostart LLC Technology for any purposes prohibited by Law, including the development, design, manufacture or production of missiles, nuclear, chemical or biological weapons.<br />
    <br />
    15.9 No Agency.<br />
    Each party to this Agreement, and each Financial Partner, is an independent contractor. Nothing in this Agreement serves to establish a partnership, joint venture, or general agency relationship between Kiostart LLC and you, or with any Financial Partner. If this Agreement expressly establishes an agency relationship between you as principal and Kiostart LLC or its Affiliate as agent, the agency conferred, including your rights as principal and Kiostart LLC&rsquo;s or its Affiliate&rsquo;s obligations as agent, is limited strictly to the stated appointment and purpose and implies no duty to you, or Kiostart LLC or its Affiliate, and will in no event establish an agency relationship for tax purposes.<br />
    <br />
    15.10 Severability.<br />
    If any court or Governmental Authority determines a provision of this Agreement is unenforceable, the parties intend that this Agreement be enforced as if the unenforceable provision were not present, and that any partially valid and enforceable provision be enforced to the extent that it is enforceable.<br />
    <br />
    15.11 Cumulative Rights; Injunctions.<br />
    The rights and remedies of the parties under this Agreement are cumulative, and each party may exercise any of its rights and enforce any of its remedies under this Agreement, along with all other rights and remedies available to it at law, in equity or under the Financial Services Terms. Any material breach by a party of Section 7 or Section 8 of these General Terms could cause the non-breaching party irreparable harm for which the non-breaching party has no adequate remedies at law. Accordingly, the non-breaching party is entitled to seek specific performance or injunctive relief for the breach.<br />
    <br />
    15.12 Entire Agreement.<br />
    This Agreement constitutes the entire agreement and understanding of the parties with respect to the Services, and supersedes all prior and contemporaneous agreements and understandings.<br />

    {user?.user?.username === 'bigstrawboba5722' ? (
      <>
      <br />
      - PROMOTION AGREEMENT -<br />
      <br />
      Bigstraw Boba<br />
      5722 Telephone Rd Unit 13<br />
      Ventura, CA 93003<br />
      <br />
      *Free Kiosk (Value $3500) provided when signing up within September 30, 2022<br />
      *Promo price for 2nd Kiosk (Value $3500) will be $2000 if purchased within September 30, 2022.<br />
      *No contract, No hidden fee condition.<br />
      *All card transaction fee is charged to the customers<br />
      *No subscription service fee (value $15/day or $5/day) for the first 3 months. After evaluation after 3 month, if transactions are over 5000 per month, the subscription service fee will stay free of charge. If transactions is lower than 5000 per month, subscription service fee will be automatically charge $5/day(charged one time of $150 each month)<br />
      *You can cancel the service anytime. No termination fee. When you cancel the service, you must return the Kiosk in working condition(no damage) or will be charged for the retail price of the unit.<br />
      *We offer a 6 months limited warranty on all the Kiosk and devices purchased/provided by Kiostart LLC.<br />
      *If other Bigstraw Boba locations sign up within September of 2022, we will offer free Kiosk and with the same promotion.<br />
      </>
    ) : null}
                 </p>
               </Grid>
             </Grid>
          </Paper>
         <Paper className={classes.paperTwo}>
          <Button onClick={() => dispatch(agreeTermsAndCondition())} color="primary" fullWidth variant="contained">I Agree</Button>
         </Paper>
        </Backdrop>
        </Grid>
    ) : (
      null
    )}
    <Grid
      className={classes.root}
      container
       direction="column"
    >
      {!props.disableHeader ? <ContentHeader beta={props.beta} disableBtn={props.disableBtn} title={props.title} /> : null}
      { props.children }
    </Grid>
    </React.Fragment>
  );
}

export default Content;
