import React, {useState, useEffect} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Box, Chip, Dialog, DialogActions, DialogContent,
         DialogContentText, DialogTitle, Grid } from '@material-ui/core';
import ClickImage from '../../components/buttons/ClickImage.js';
import ClickButton from '../../components/buttons/ClickButton.js';
import IncrementButton from '../../components/buttons/IncrementButton.js'
import IncrementOptionButton from '../../components/buttons/IncrementOptionButton.js'
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import { addCartItem, CartItem, deleteCartItem, updateCartItem } from '../cart/cartSlice';
import { useSelector, useDispatch } from 'react-redux';
import { updateAddedItemQty, resetAll, updatePrice, updateQty, setModalOpen,
         selectItemOptionSelectedById, setSelectedItem, addSelectedOption,
         deleteSelectedOptionItem } from './itemModalSlice';
import Badge from '@material-ui/core/Badge';
import DoneIcon from '@material-ui/icons/Done';
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Divider from '@material-ui/core/Divider';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ItemModal = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const open = useSelector(state => state.itemModal.modalOpen);
  const id = useSelector(state => state.itemModal.modalId);
  const selectedOptions = useSelector(state => state.itemModal.modalItemData.selectedOption)
  const item = useSelector(state => state.itemModal.modalItemData.item)
  const amount = useSelector(state => state.itemModal.modalItemData.priceSummary.amount)
  const quantity = useSelector(state => state.itemModal.modalItemData.priceSummary.qty)
  const cartIdx = useSelector(state => state.itemModal.cartIdx)
  const modalItemData = useSelector(state => state.itemModal.modalItemData)
  const priceSummary = useSelector(state => state.itemModal.modalItemData.priceSummary)
  const isCartItem = useSelector(state => state.itemModal.isCartItem)

  const itemOptions = item ? item.options : []
  const sortedItemOptions = item ? item.groups : []
  const [qty, setQty] = useState(quantity);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarString, setSnackBarString] = useState();


  const handleSnackBarOpen = () => {
    setSnackBarOpen(true);
  }

  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  }

  useEffect(() => {
    if (sortedItemOptions && sortedItemOptions.length > 0) {

    }
  }, [sortedItemOptions])

  const handleClose = () => {
    if (!isCartItem) {
      setQty(1);
      dispatch(setModalOpen({modalOpen: false, modalId: id, isCartItem: true}));
      dispatch(resetAll());
    } else {
      setQty(1);
      dispatch(setModalOpen({modalOpen: false, modalId: id, isCartItem: false}));
      dispatch(resetAll());

    }
  };

  const validateModalItemData = () => {
    let fulfilled = true;
    modalItemData.item.groups.slice(0).reverse().map(option => {
      if (option.option.required && !option.addedItem) {
        setSnackBarString(option.option.name);
        fulfilled = false;
      } else if (option.option.required && option.addedItem.length === 0) {
        setSnackBarString(option.option.name);
        fulfilled = false;
      }
    })

    return fulfilled;
  }

  const handleAdd = () => {
    if (!validateModalItemData()) {
      handleSnackBarOpen();
    } else {
      let cartItem = {name: '', quantity: 0, unit_price: 0};
      cartItem.name = item.name;
      cartItem.unit_price = modalItemData.item.unit_price;
      cartItem.quantity = priceSummary.qty;

      dispatch(addCartItem({
        cartItem: cartItem,
        modalItemData: modalItemData
      }));
      dispatch(setModalOpen({
        modalOpen: false,
        modalId: id}));
    }
  }

  const handleUpdate = () => {
    if (!validateModalItemData()) {
      handleSnackBarOpen();
    } else {
      let cartItem = {name: '', quantity: 0, unit_price: 0};
      cartItem.name = item.name;
      cartItem.unit_price = modalItemData.item.unit_price;
      cartItem.quantity = priceSummary.qty;

      dispatch(updateCartItem({
        cartItem: cartItem,
        modalItemData: modalItemData,
        cartIdx: cartIdx
      }));
      dispatch(setModalOpen({
        modalOpen: false,
        modalId: id}));
    }
  }

  const handleDelete = (optionIdx, itemIdx) => {
    dispatch(updateAddedItemQty({
      itemOptionIdx: itemIdx,
      optionIdx: optionIdx,
      qty: 0}));
    dispatch(deleteSelectedOptionItem({
      optionId: optionIdx,
      itemId: itemIdx}));
    dispatch(updatePrice());
  };

  if (open) {
    return (
      <React.Fragment>
      <Dialog
          fullWidth
          maxWidth={'sm'}
          transitionDuration={{enter:0, exit: 0, appear: 0}}
          open={open}
          onClose={handleClose}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description">
        {/*Dialog Header*/}
       <DialogTitle id="scroll-dialog-title" style={{padding: 10, margin: 0}}>
        <Grid container
              direction="row"
              justifyContent="flex-start"
              alignItems="center">
              {/*Dialog Header First*/}
            <Grid item xs={6} md={6} container direction="column"  justifyContent="space-between" alignItems="center" style={{
               paddingLeft: 0, paddingRight: 0, margin: 0,
            }} >
              <Grid item>
                <img src={item.item_image?.item_image} style={{    objectFit: 'contain', maxHeight: 100}} />
              </Grid>
              <Grid item >
                <p style={{ marginBottom: 0, fontSize: 15, marginTop:0, textAlign: 'center', lineHeight: 1}}>{item.name}</p>
              </Grid>
              <Grid item>
                <IncrementButton onUpdate={setQty} qty={quantity} />
              </Grid>
            </Grid>
            <Grid item xs={6} md={6} container direction="column"  justifyContent='flex-start' alignItems="flex-start" style={{
               paddingLeft:10, paddingRight: 0, margin: 0, height: 183, borderLeft: '3px solid rgba(0,0,0,0.056)',
            }} >
              <Grid item>
                <div style={{ fontSize: 15, padding: 0, margin: 0, overflow: 'hidden'}}>
                Description
                <p style={{fontSize: 13, padding: 0, margin: 0, marginTop: 5}}>
                {item.description ? item.description : ''}
 
                </p>


                </div>
              </Grid>
              </Grid>
        </Grid>
       </DialogTitle>
    {/*Dialog Content*/}
    <DialogContent
    style={{backgroundColor: '#fafafa', minHeight: '45vh', maxHeight: '45vh', paddingLeft: 15, paddingRight: -15}}>
    {sortedItemOptions?.map((item, idx) =>
      <React.Fragment>
        <ItemOption
          modalItemData={modalItemData}
          validateModalItemData={validateModalItemData}
          item={item.option}
          idx={idx}
        />
      </React.Fragment>)}
   </DialogContent>
   {/*Add to order & Cancel button*/}
   <DialogActions id="dialog-action">
     <Grid container
           direction="row"
           justifyContent="space-between">
      <Grid item xs={6} style={{paddingRight: 5}}>
      {isCartItem ?
        <Button disableElevation onClick={() => {dispatch(deleteCartItem(cartIdx)); handleClose()}} color="secondary"  size="large" fullWidth={true} variant="contained">
          DELETE
        </Button>
        :
        <Button disableElevation size="large" fullWidth={true} variant="contained" onClick={handleClose}>
          CLOSE
        </Button>}
      </Grid>
      <Grid item xs={6} style={{paddingLeft: 5}}>
        {!isCartItem ?
          <Button disableElevation color="primary" size="large" fullWidth={true} variant="contained" onClick={handleAdd}>
            ADD (${amount})
          </Button>
          :
          <Button disableElevation color="primary" size="large" fullWidth={true} variant="contained" onClick={handleUpdate}>
            UPDATE (${amount})
          </Button>
        }
      </Grid>
      </Grid>
     </DialogActions>
    </Dialog>
    <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    message={'Selection on ' + snackBarString + ' is required'}

     open={snackBarOpen} autoHideDuration={3000} onClose={handleSnackBarClose}>
   </Snackbar>
   </React.Fragment>
    );
  } else {
    return null;
  }
};

const ItemOption = (props) => {
  const item = props.item // groups.option
  const dispatch = useDispatch();

  const validateItemOption = () => {
    let fulfilled = true;
    const id = props.item.id;

    const index = props.modalItemData.item.groups.findIndex(option => option.option.id === id);
    if (props.modalItemData.item.groups[index].addedItem && props.modalItemData.item.groups[index].option.is_multiple_selection &&
        props.modalItemData.item.groups[index].addedItem.length + 1 > props.modalItemData.item.groups[index].option.multiple_max_number) {
       fulfilled = false;
    }
    return fulfilled;
  }

  return (
    <DialogContentText id="second-dialog-content">
     <h4 style={{fontSize: 15.5, color: '#3a3f47',  paddingBottom: 0, marginBottom: 7, marginTop: 0}}>
       {item.name}  {item.required ?
          <Typography style={{fontSize: 10, position: 'relative', bottom: 1.1, color: '#d4431f'}} variant="caption"  display="inline" gutterBottom>
            REQUIRED
          </Typography>
          :
          null}
     </h4>
     <Grid
       container

       direction="row">
          {item.item_options.map((option, idx) =>
            <ItemOptionItem
              onValidateModalItemData={validateItemOption}
              key={idx}
              idx={idx}
              optionIdx={props.idx}
              {...props}
              item={item}
              option={option}
            />)}
      </Grid>
    </DialogContentText>
  );
}

const useStyles = makeStyles(theme => ({
  customBadge: {
    backgroundColor: "#4ab7ce",
    background: "linear-gradient(to bottom,  rgba(135,224,253,1) 0%,rgba(83,203,241,1) 40%,rgba(5,171,224,1) 100%)"
  },
  chip: {
    fontSize: 13.5,
    paddingBottom: 0,
    paddingtop: 0,
    height: 34,
  },


}));

const ItemOptionItem = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch();
  // props.item === groups.option
  // props.option === groups.item_option

  // handle adding an item option
  const handleClickButton = option => {
    if (props.onValidateModalItemData()) {
      dispatch(addSelectedOption({
        name: props.item.name,
        option: option,
        is_multiple_selection: props.item.is_multiple_selection}));
      dispatch(setSelectedItem({
        optionIdx: props.item.id,
        itemIdx: option.id
      }));
      dispatch(updatePrice());
    }
  };

  // handle incremental item option
  const handleNumberOption = (data) => {
    dispatch(addSelectedOption({
      name: props.item.name,
      option: props.option,
      is_multiple_selection: true}));
    dispatch(updateAddedItemQty({
      itemOptionIdx: props.option.id,
      optionIdx: props.item.id,
      qty: data}));
    dispatch(setSelectedItem({
      optionIdx: props.item.id,
      itemIdx: props.option.id
    }));
    dispatch(updatePrice());
  };

  // handle deleting an item option
  const handleDelete = (optionIdx, itemIdx) => {

  dispatch(deleteSelectedOptionItem({
      optionId: optionIdx, // state.modalItemData.item.options[optionId]
      itemId: itemIdx})); // state.modalItemData.item.options[optionId].addedItem[itemId]
    dispatch(updatePrice());
  };

  // optionIdx indicates Option.id
  // itemIdx indicates addedItem.id
  // props.item.id === Option.id
  // props.option.id === ItemOption.id
  /*
  Note that addedItem can become eithier a cop of an OrderItemOption or ItemOption
  depending on two situations:

  When creating a new order, addedItem is ItemOption. When updating an order,
  addedItem becomes OrderItemOption.
  */
  const addedItem = useSelector(state => selectItemOptionSelectedById(state, {optionIdx: props.item.id, itemIdx: props.option.id}));
  let selected = false;
  let elem = null; // elem to be displayed


  if (addedItem) {
    selected = addedItem.selected;
  }

  if (props.option.item_option_image) {
    elem = !props.option.is_number_option
    ? <ClickButton
        selected={selected}
        onClick={selected ? () => handleDelete(props.item.id, props.option.id) : () => handleClickButton(props.option)}
        addon
        headerImg={props.option.item_option_image }
        buttonDesc={props.option.name} price={'+$' + props.option.unit_price} />
    :  <IncrementOptionButton headerImg={props.option.item_option_image } selected={selected} onEmpty={() => handleDelete(props.item.id, props.option.id)} onUpdate={(data) => handleNumberOption(data)} {...props.option} number_value2={addedItem?.number_value ? addedItem.number_value : 0}/>
  } else {
    elem = props.option.is_number_option
    ? <IncrementOptionButton selected={selected} onEmpty={() => handleDelete(props.item.id, props.option.id)} onUpdate={(data) => handleNumberOption(data)} {...props.option} number_value2={addedItem?.number_value ? addedItem.number_value : 0}/>
    : <span style={{marginRight: 5, marginBottom: 0}}><Chip style={{fontWeight: 400}}  className={classes.chip} icon={selected ? <DoneIcon style={{color: '#3f9bad'}} /> : null} onClick={selected ? () => handleDelete(props.item.id, props.option.id) : () => handleClickButton(props.option)} label={props.option.name} /><br/><small style={{float: 'right', fontSize: '0.75em', marginRight: 10, marginTop: 0, fontWeight: 400}}>+${props.option.unit_price}</small></span>
  }

  return elem; // render
}

export default ItemModal;
