import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET, DELETE, POST, PUT, PATCH, POST_FILE } from '../../api/DashboardAPI';

export const fetchItemsByCategoryId = createAsyncThunk('item/fetchItemsByCategoryId', async (categoryId) => {
  if (categoryId) {
    const response = await POST('/dashboard/category-item-group/get_items_by_category2/', {'categoryId': categoryId});
    const items = response.data.map(data => data.item);
    return items; // payload
  }
});

export const overrideItem = createAsyncThunk('item/overrideItem', async (obj) => {
  const response = await POST('/dashboard/item/override_item/', obj);
  return response.data;

});

export const postItemImage = createAsyncThunk('item-image/postCategoryImage', async (obj) => {
  const response = await POST_FILE('/dashboard/item-image/', obj);
  return response.data;

});

export const postOptionsInItemsOrder = createAsyncThunk('item/postOptionsInItemsOrder', async (obj) => {
  const response = await POST('/dashboard/item/' + obj.itemId + '/update_options_in_item_order/', {items: obj.items});
  return {itemId: obj.itemId, groups: response.data.groups, options: response.data.options};
});

export const syncOptionsOrder = createAsyncThunk('item/syncOptionsOrder', async (payload) => {
  const response = await POST('/dashboard/item/apply_same_option_order/', payload);
  return {itemId: payload.reference_item_id, groups: response.data.groups, options: response.data.options};
});


export const patchRemoveOptionFromItem = createAsyncThunk('item/patchRemoveOptionFromItem', async (obj) => {
  const itemId = obj.itemId;
  let items = obj.existingItems.slice();
  obj.removedOptions.map(id => {
    const index = items.findIndex(item => item === id);
    items.splice(index, 1);
  });
  const response = await POST('/dashboard/item/' + obj.itemId + '/remove_option_from_item/', {removedOptions: obj.removedOptions});
  return {id: obj.itemId, removedOptions: response.data};
});

export const patchAddOptionsToItem = createAsyncThunk('addon/patchAddOptionsToItem', async (obj) => {
  let existingItems = [];
  obj.existingItems.map(item => existingItems.push(item));
  existingItems.push(obj.addedItem);
  const response = await POST('/dashboard/option/' + obj.optionId + '/add_option_to_item/', {itemId: obj.itemId});

  return { addedOptions: response.data, id: obj.itemId };
});


export const patchBulkAddOptionsToItem = createAsyncThunk('addon/patchBulkAddOptionsToItem', async (obj) => {

  const response = await POST('/dashboard/option/add_bulk_options_to_item/', {itemId: obj.itemId, optionIds: obj.optionIds});

  return { addedOptions: response.data, id: obj.itemId };
});

export const removeItem = createAsyncThunk('item/removeItem', async (id) => {
  const response = await DELETE('/dashboard/item/' + id + '/');
  return id; // payload
});

export const postItem = createAsyncThunk('item/postItem', async (name) => {
  const response = await POST('/dashboard/item/', {name: name});
  return response.data; // payload
});

export const fetchItems = createAsyncThunk('item/fetchItems', async () => {
  const response = await GET('/dashboard/item/');
  return response.data.results; // payload
});

export const fetchItemsSimplified = createAsyncThunk('item/fetchItemsSimplified', async () => {
  const response = await GET('/dashboard/item/fetch_items_simplified/');
  return response.data; // payload
});

export const fetchItemsAlt = createAsyncThunk('item/fetchItemsAlt', async () => {
  const response = await GET('/dashboard/item/fetch_items_for_dashboard/');
  return response.data; // payload
});

export const fetchItemImages = createAsyncThunk('item/fetchItemImages', async () => {
  const response = await GET('/dashboard/item-image/');
  return response.data.results; // payload
});


export const fetchItemImage = createAsyncThunk('item/fetchItemImage', async (payload) => {
  //itemId, data
  const response = await GET('/dashboard/item-image/get_item_image/?id=' + payload.id);
  return {data: response.data, itemId: payload.item_id }// payload
});



export const patchItem = createAsyncThunk('item/patchItem', async (obj) => {
  const response = await PATCH('/dashboard/item/' + obj.itemId + '/', {taxable: obj.taxable, limit_per_order: obj.limit_per_order, qty_control: obj.qty_control, hidden: obj.hidden, active: obj.active, name: obj.name, calories: obj.calories, unit_price: obj.unit_price, description: obj.description});
  return response.data; // payload
});

export const postItemImagePosition = createAsyncThunk('item-image/postItemImagePosition', async (obj) => {
  const response = await POST('/dashboard/item-image/' + obj.id + '/update_item_image_position/', {itemId: obj.itemId, x: obj.x, y: obj.y});
  return response.data;
});

export const patchItemImage = createAsyncThunk('item/patchItemImage', async (obj) => {
  const response = await PATCH('/dashboard/item/' + obj.itemId + '/', {item_image: obj.targetValue});
  const returnObj = {
    targetValue: obj.targetValue,
    itemId: obj.itemId,
    itemImage: response.data.item_image
  };

  return returnObj; // payload
});

export const removeItemImage = createAsyncThunk('item/removeItemImage', async (id) => {
  const response = await DELETE('/dashboard/item-image/' + id + '/');
  return id; // payload
});

export const fetchItemDetail = createAsyncThunk('item/fetchItemDetail', async (payload) => {
  const response = await GET('/dashboard/item/get_item_detail/?id=' + payload.id);
  return response.data; // payload
});


export const fetchItemOptionGroup = createAsyncThunk('item/fetchItemOptionGroup', async (payload) => {
  const response = await GET('/dashboard/item-option-group/get_item_option_group/?item_id=' + payload.item_id);
  return {groups: response.data, item_id: payload.item_id}; // payload
});

export const fetchItemReports = createAsyncThunk('item/fetchItemReports', async (payload) => {
  const response = await GET('/dashboard/item/get_items_reports/');
  return response.data // payload
});


export const fetchItemReport = createAsyncThunk('item/fetchItemReport', async (payload) => {
  const response = await POST('/dashboard/item/get_item_report/', payload);
  return {data: response.data, id: payload.id} // payload
});


export const fetchItemReportCustom = createAsyncThunk('item/fetchItemReportCustom', async (payload) => {
  const response = await POST('/dashboard/item/get_item_report_custom/?start_date=' + payload.start_date + '&end_date=' + payload.end_date, payload);
  return {data: response.data, id: payload.id} // payload
});




export const itemSlice = createSlice({
  name: 'item',
  initialState: {
    items: [],
    itemsAlt: [],
    itemsSimplified: [],
    itemsReports: [],
    searchedItems: [],
    status: 'idle',
    error: null,
    itemImagesStatus: 'idle',
    itemImages: [],
    putItemStatus: 'idle'
  },
  reducers: {
    updateSelectImage: (state, action) => {
      const obj = action.payload;
      // get the category id and target val
      const itemId = obj.itemId;
      const targetValue = obj.targetValue;
      // find category elem from categories array
      const index = state.items.findIndex(item => item.id === itemId);
      if (index !== -1) {
        // update category_image field in a category elem with a target value
        state.items[index].item_image = targetValue;
      }
      const index2 = state.searchedItems.findIndex(item => item.id === itemId);
      if (index2 !== -1) {
        // update category_image field in a category elem with a target value
        state.searchedItems[index2].item_image = targetValue;
      }
    },
    updateSearchedItemsList: (state, action) => {
      state.searchedItems = action.payload
    },

  },
  extraReducers: {
    [fetchItems.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchItems.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // Add any fetched posts to the array
      state.items = action.payload;
    },
    [fetchItems.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },

    [fetchItemDetail.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchItemDetail.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      const id = action.payload.id;
      const index = state.items.findIndex(item => item.id === id)

      if (index !== -1) {
        state.items[index] = {
          ...state.items[index],
          ...action.payload
        };
      }

      const index2 = state.searchedItems.findIndex(item => item.id === id)

      if (index2 !== -1) {
        state.searchedItems[index2] = {
          ...state.items[index],
          ...action.payload
        };
      }

      state.status = 'item detail fetched';

    },
    [fetchItemDetail.rejected]: (state, action) => {
      state.status = 'failed'
    },


    [fetchItemsSimplified.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchItemsSimplified.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // Add any fetched posts to the array
      state.itemsSimplified = action.payload;
    },
    [fetchItemsSimplified.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [fetchItemsAlt.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchItemsAlt.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // Add any fetched posts to the array
      state.items = action.payload;

    },
    [fetchItemsAlt.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
    [postItem.pending]: (state, action) => {
      state.status = 'loading'
    },
    [postItem.fulfilled]: (state, action) => {
      state.status = 'added'
      state.items = state.items.concat(action.payload);
      // Add any fetched posts to the array
    },
    [postItem.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
    [fetchItemImages.pending]: (state, action) => {
      state.itemImagesStatus = 'loading'
    },
    [fetchItemImages.fulfilled]: (state, action) => {
      state.itemImagesStatus = 'succeeded'
      // Add any fetched posts to the array
      state.itemImages = action.payload;
    },
    [fetchItemImages.rejected]: (state, action) => {
      state.itemImagesStatus = 'failed'
      state.error = action.error.message;
    },
    [patchAddOptionsToItem.pending]: (state, action) => {
      state.status = 'loading'
    },
    [patchAddOptionsToItem.fulfilled]: (state, action) => {
      const index = state.items.findIndex(item => item.id === action.payload.id)
      if (index !== -1) {
        state.items[index].groups = action.payload.addedOptions;
      }

      const index2 = state.searchedItems.findIndex(item => item.id === action.payload.id)
      if (index2 !== -1) {
        state.searchedItems[index2].groups = action.payload.addedOptions;
      }
      state.status = 'added'
    },
    [patchAddOptionsToItem.rejected]: (state, action) => {

      state.status = 'failed'
    },
    [patchBulkAddOptionsToItem.pending]: (state, action) => {
      state.status = 'loading'
    },
    [patchBulkAddOptionsToItem.fulfilled]: (state, action) => {
      const index = state.items.findIndex(item => item.id === action.payload.id)
      if (index !== -1) {
        state.items[index].groups = action.payload.addedOptions;
      }

      const index2 = state.searchedItems.findIndex(item => item.id === action.payload.id)
      if (index2 !== -1) {
        state.searchedItems[index2].groups = action.payload.addedOptions;
      }
      state.status = 'added'
    },
    [patchBulkAddOptionsToItem.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [patchItem.pending]: (state, action) => {
      state.putItemStatus = 'loading'
    },
    [patchItem.fulfilled]: (state, action) => {
      const obj = action.payload;
      /* update locally after server update */

      // get the category id and target val
      const itemId = obj.id;
      // find category elem from categories array
      const index = state.items.findIndex(item => item.id === itemId);

      if (index !== -1) {
        // update category_image field in a category elem with a target value
        state.items[index].unit_price = obj.unit_price;
        state.items[index].calories = obj.calories;
        state.items[index].active = obj.active;
        state.items[index].name = obj.name;
        state.items[index].description = obj.description;
        state.items[index].hidden = obj.hidden;
        state.items[index].taxable = obj.taxable;
        state.items[index].item_image = obj.item_image;
      }

      const index2 = state.searchedItems.findIndex(item => item.id === itemId);

      if (index2 !== -1) {
        // update category_image field in a category elem with a target value
        state.searchedItems[index2].unit_price = obj.unit_price;
        state.searchedItems[index2].calories = obj.calories;
        state.searchedItems[index2].active = obj.active;
        state.searchedItems[index2].hidden = obj.hidden;
        state.searchedItems[index2].description = obj.description;
        state.searchedItems[index2].name = obj.name;
        state.searchedItems[index2].taxable = obj.taxable;
        state.searchedItems[index2].item_image = obj.item_image;
      }

      state.putItemStatus = 'updated'
    },
    [patchItem.rejected]: (state, action) => {
      state.putItemStatus = 'failed'
      state.error = action.error.message;
    },

    [patchItemImage.pending]: (state, action) => {
      state.putItemStatus = 'loading'
    },
    [patchItemImage.fulfilled]: (state, action) => {
      state.putItemStatus = 'updated'
      const obj = action.payload;

      /* update locally after server update */

      // get the category id and target val
      const itemId = obj.itemId;
      const targetValue = obj.targetValue;
      // find category elem from categories array
      const index = state.items.findIndex(item => item.id === itemId);
      if (index !== -1) {
        // update category_image field in a category elem with a target value
        state.items[index].item_image = obj.itemImage;
      }
      // find category elem from categories array
      const index2 = state.searchedItems.findIndex(item => item.id === itemId);
      if (index2 !== -1) {
        // update category_image field in a category elem with a target value
        state.searchedItems[index2].item_image = obj.itemImage;
      }
    },

    [fetchItemImage.pending]: (state, action) => {
      state.putItemStatus = 'loading'
    },
    [fetchItemImage.fulfilled]: (state, action) => {
      // find category elem from categories array
      const index = state.items.findIndex(item => item.id === action.payload.itemId);
      // update category_image field in a category elem with a target value
      if (index !== -1) {
        state.items[index].item_image = action.payload.data;

      }
      // find category elem from categories array
      const index2 = state.searchedItems?.findIndex(item => item.id === action.payload.itemId);
      // update category_image field in a category elem with a target value
      if (index2 !== -1) {
        state.searchedItems[index2].item_image = action.payload.data;
      }

      state.putItemStatus = 'updated'
    },
    [fetchItemImage.rejected]: (state, action) => {
      state.putItemStatus = 'failed'
      state.error = action.error.message;
    },
    [patchItemImage.rejected]: (state, action) => {
      state.putItemStatus = 'failed'
      state.error = action.error.message;
    },
    [removeItem.pending]: (state, action) => {
      state.status = 'deleting'
    },
    [removeItem.fulfilled]: (state, action) => {
      //state.items = state.items.filter((category, idx) => idx !== action.payload);
      const index = state.items.findIndex(item => item.id === action.payload);
      if (index !== -1) {
        state.items.splice(index, 1);
      }

      const index2 = state.searchedItems.findIndex(item => item.id === action.payload);
      if (index2 !== -1) {
        state.searchedItems.splice(index2, 1);
      }

      state.status = 'deleted'
    },
    [removeItem.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
    [patchRemoveOptionFromItem.pending]: (state, action) => {

      state.status = 'loading'
    },
    [patchRemoveOptionFromItem.fulfilled]: (state, action) => {
      const index = state.items.findIndex(item => item.id === action.payload.id)
      if (index !== -1) {
        state.items[index].groups = action.payload.removedOptions;
      }

      const index2 = state.searchedItems.findIndex(item => item.id === action.payload.id)
      if (index2 !== -1) {
        state.searchedItems[index2].groups = action.payload.removedOptions;
      }
      state.status = 'deleted'

    },
    [patchRemoveOptionFromItem.rejected]: (state, action) => {
      state.status = 'failed'
    },

    [postOptionsInItemsOrder.fulfilled]: (state, action) => {
      const index = state.items.findIndex(item => item.id === action.payload.itemId);
      if (index !==-1) {
        state.items[index].groups = action.payload.groups;
      }

      const index2 = state.searchedItems.findIndex(item => item.id === action.payload.itemId);
      if (index2 !== -1) {
        state.searchedItems[index2].groups = action.payload.groups;
      }
      state.status = 'ordered'

    },
    [postOptionsInItemsOrder.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [postOptionsInItemsOrder.pending]: (state, action) => {
      state.status = 'loading'
    },
    
    [syncOptionsOrder.fulfilled]: (state, action) => {
      const index = state.items.findIndex(item => item.id === action.payload.itemId);
      if (index !== -1) {
        state.items[index].groups = action.payload.groups;
      }

      const index2 = state.searchedItems.findIndex(item => item.id === action.payload.itemId);
      if (index2 !== -1) {
        state.searchedItems[index2].groups = action.payload.groups;
      }
      state.status = 'synced'

    },
    [syncOptionsOrder.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [syncOptionsOrder.pending]: (state, action) => {
      state.status = 'syncing'
    },

    [postItemImage.pending]: (state, action) => {
      state.status = 'loading'
    },
    [postItemImage.fulfilled]: (state, action) => {
      state.status = 'added'
      state.itemImages.push(action.payload);
    },
    [postItemImage.rejected]: (state, action) => {
      state.status = 'failed'

    },
    [removeItemImage.pending]: (state, action) => {
      state.status = 'deleting'
    },
    [removeItemImage.fulfilled]: (state, action) => {
      state.status = 'deleted'
      const id = action.payload;
      const index = state.itemImages.findIndex(image => image.id === id);
      state.itemImages.splice(index, 1);
    },
    [removeItemImage.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },

    [fetchItemsByCategoryId.pending]: (state, action) => {
      state.status = 'fetch item loading'
    },
    [fetchItemsByCategoryId.fulfilled]: (state, action) => {
      state.status = 'fetch item succeeded'
      // Add any fetched posts to the array
      state.itemsAlt = action.payload;
    },
    [fetchItemsByCategoryId.rejected]: (state, action) => {
      state.status = 'fetch item failed'
      state.error = action.error.message
    },


    [fetchItemReport.pending]: (state, action) => {
      state.status = 'fetch item loading'
    },
    [fetchItemReport.fulfilled]: (state, action) => {
      const data = action.payload.data;
      const id = action.payload.id;

      const index = state.itemsSimplified.findIndex(item => item.id === id);

      state.itemsSimplified[index].total = data.total
      state.itemsSimplified[index].item_count = data.item_count

      state.status = 'fetch item succeeded'
      // Add any fetched posts to the array
    },
    [fetchItemReport.rejected]: (state, action) => {
      state.status = 'fetch item failed'
      state.error = action.error.message
    },

    [fetchItemReportCustom.pending]: (state, action) => {
      state.status = 'fetch item loading'
    },
    [fetchItemReportCustom.fulfilled]: (state, action) => {
      const data = action.payload.data;
      const id = action.payload.id;

      const index = state.itemsSimplified.findIndex(item => item.id === id);

      state.itemsSimplified[index].total = data.total
      state.itemsSimplified[index].item_count = data.item_count

      state.status = 'fetch item succeeded'
      // Add any fetched posts to the array
    },
    [fetchItemReportCustom.rejected]: (state, action) => {
      state.status = 'fetch item failed'
      state.error = action.error.message
    },


    [overrideItem.pending]: (state, action) => {
      state.status = 'loading'
    },
    [overrideItem.fulfilled]: (state, action) => {
      const overrideItem = action.payload;
      const index = state.items.findIndex(item => item.id === overrideItem.item);

      if (index !== -1) {
        const index2 = state.items[index].overrides.findIndex(override => override.id === overrideItem.id)
        if (index2 !== -1) {
          state.items[index].overrides[index2] = overrideItem;
        }
      }

      const index2 = state.searchedItems.findIndex(item => item.id === overrideItem.item);

      if (index2 !== -1) {
        const index3 = state.searchedItems[index2].overrides.findIndex(override => override.id === overrideItem.id)
        if (index3 !== -1) {
          state.searchedItems[index2].overrides[index3] = overrideItem;
        }
      }
      state.status = 'overidden'
    },
    [overrideItem.rejected]: (state, action) => {
      state.status = 'failed'
    },


    [fetchItemOptionGroup.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchItemOptionGroup.fulfilled]: (state, action) => {
      const groups = action.payload.groups;
      const index = state.items.findIndex(item => item.id === action.payload.item_id);

      if (index !== -1) {
        state.items[index].groups = groups;
      }

      const index2 = state.searchedItems.findIndex(item => item.id === action.payload.item_id);

      if (index2 !== -1) {
        state.searchedItems[index2].groups = groups;
      }
      state.status = 'overidden'
    },
    [fetchItemOptionGroup.rejected]: (state, action) => {
      state.status = 'failed'
    },



  }
});

export const { updateSearchedItemsList } = itemSlice.actions;

export default itemSlice.reducer;

export const selectAllItems = state => state.item.items;
export const selectAllItemsSimplified = state => state.item.itemsSimplified;

export const selectAllItemImages = state => state.item.itemImages;
export const selectItemById = (state, itemId) => {
  const index = state.item.items.findIndex(item => item.id === itemId)
  const items = state.item.items;
  if (index !== -1) {
    return items[index];
  } else {
    return null;
  }

}
