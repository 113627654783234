import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CardMedia from '@material-ui/core/CardMedia';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { DropzoneArea } from 'material-ui-dropzone'
import {
  setAddImageModalOpen,
  selectImageModalOpen
} from './modalSlice';
import { postMenuImage } from '../auth/authSlice';

const AddImage = (props) => {
  const open = useSelector(selectImageModalOpen);
  const dispatch = useDispatch();
  const [files, setFiles] = React.useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let formData = new FormData();

    if (files.length > 0) {
      formData.append('kiosk_menu_banner', files[0]);
    }

    dispatch(postMenuImage(formData));
    dispatch(setAddImageModalOpen(false));
  };

  /* RENDER */
  return (
    <Dialog fullWidth maxWidth={'sm'} open={open} onClose={() => dispatch(setAddImageModalOpen(false))} aria-labelledby="form-dialog-title">
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <DialogContent style={{marginTop: 5, marginBottom: -5}}>
        <DropzoneArea
          maxFileSize={7000000}
          dropzoneText={<small style={{fontSize: 20}}>Drag and drop an image here or click</small>}
          filesLimit={1}
          onChange={(files) => setFiles(files)}
        />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => dispatch(setAddImageModalOpen(false))}>
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Add
          </Button>
        </DialogActions>
      </form>

    </Dialog>
  );
}

export default AddImage;
