import React from 'react';
import { IconButton, makeStyles, Grid } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import { updateQty, updatePrice } from '../../features/itemModal/itemModalSlice';
import { useDispatch } from 'react-redux';

const IncrementButton = (props) => {
  const dispatch = useDispatch();

  const increment = () => {
    props.onUpdate(props.qty + 1);
    dispatch(updateQty(props.qty + 1));
    dispatch(updatePrice());
  };

  const decrement = () => {
    if (props.qty > 1) {
      props.onUpdate(props.qty - 1);
      dispatch(updateQty(props.qty - 1));
      dispatch(updatePrice());
    }
  };

  return (
      <Grid container item   direction="row"
          justifyContent="center"
          spacing={1}
          alignItems="center">
            {props.headerImg ? <Grid item><img style={{ width: '100%'}} src={props.headerImg} alt="headerImg"/>          </Grid> : null}
          <Grid item>
          <IconButton onClick={decrement}><RemoveCircleRoundedIcon fontSize="large" /></IconButton>
          </Grid>
          <Grid item>
          <span className="incrementQuantity">{props.qty}</span>
          </Grid>
          <Grid item>
          <IconButton onClick={increment}><AddCircleIcon  fontSize="large" /></IconButton>
          </Grid>
      </Grid>
    );

}

export default IncrementButton;
