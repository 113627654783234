import React from 'react';
import Table from '../../features/table/Table';


const TableManagerComponent = (props) => {
  return (
    <div>
      <Table />
    </div>
  );
};

export default TableManagerComponent;
