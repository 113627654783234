import React, { useEffect, useState, useRef } from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import OrderModal2  from '../modal/OrderModal2.js';
import WaitList from './TableManager/WaitList';
import CheckoutDialog from './CheckoutDialog.js'
import { useSelector, useDispatch } from 'react-redux';
import {
  setAddTableModalOpen,
  selectTableOpen
} from '../modal/modalSlice';
import {
  getSystemUser,
  getTerminals
} from '../auth/authSlice';

import {
  deleteTable, setEmitted, updateTableAvail, postTableInstance, updateTableHold, deactivateInstance,
  getWaitlist, setTableInstance,
  setDialogOpen,
  setDialogOrder,
  setPaymentComplete,
  setTableStatus,
  setDialogReceived, clearTableInstance, fetchTables, fetchTableForPayment, fetchTable, getTableInstances, updateTableNumber
} from './tableSlice';
import { makeStyles } from '@material-ui/core/styles';
import { fetchConnectionToken } from '../../api/DashboardAPI';
import {loadStripeTerminal} from '@stripe/terminal-js';
import { setStatus, updateItemsPaid, updatePmtIntent, getPmtStatus, fetchSplitPmt} from '../order/orderSlice';
import { capturePaymentIntent, postPaymentIntent, splitPaymentIntent, cancelPaymentIntent, clearSecret, postConnectToken, postRemoveReader, selectSecret, postPairReader, setMyConnectedTerminal } from '../paymentProcessing/paymentProcessingSlice'
import AddTableModal from '../modal/AddTableModal';
import { io } from "socket.io-client";
import currency from 'currency.js'
import useTerminal from '../../hooks/useTerminal'
import useActionStatusTracker from '../../hooks/useActionStatusTracker';
import CardView from './TableManager/CardView';
import CanvasView from './TableManager/CanvasView';


const DEFAULT_SOCKETS_URL = 'https://sockets.bigkiosksolution.com';
//const DEFAULT_SOCKETS_URL = 'http://127.0.0.1:5000';
let socket = null;

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 25,
    width: `74.9vw`,
    [theme.breakpoints.down('md')]: {
      width: `72.1vw`,
     },
    [theme.breakpoints.down('sm')]: {
      width: `100%`,
     },
    marginTop: 12,
    borderRadius: 5,
  },
}));

const Table = (props) => {
  const socketRef = useRef();

  const classes = useStyles();
  const dispatch = useDispatch()
  let terminal = {};

  const [terminals, setTerminals] = useState(null)
  const [message, setMessage] = useState('')
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [_terminal, setTerminal] = useState(null)
  // used to capture and mark as paid
  const [orderPaymentId, setOrderPaymentId] = useState(null)
  const [orderItemIds, setOrderItemIds] = useState(null)
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const [value, setValue] = useState(1)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const secret = useSelector(state => state.paymentProcessing.secret)
  const status = useSelector(state => state.paymentProcessing.status)
  const orderStatus = useSelector(state => state.order.status)
  const tableStatus = useSelector(state => state.table.status)

  // secret for split payment
  const splitSecret = useSelector(state => state.paymentProcessing.splitSecret)
  const intentId = useSelector(state => state.paymentProcessing.intentId)
  const pmtStatusArr = useSelector(state => state.order.pmtStatusArr)
  const waitlist = useSelector(state => state.table.waitlist);
  const checkoutDialogOpen = useSelector(state => state.table.checkoutDialogOpen)
  const checkoutDialogOrder = useSelector(state => state.table.checkoutDialogOrder)
  const checkoutDialogReceived = useSelector(state => state.table.checkoutDialogReceived)
  const paymentComplete = useSelector(state => state.table.paymentComplete)
  const selfTriggered = useSelector(state => state.table.selfTriggered)
  const stripe_connect_acct_id = useSelector(state => state.auth.user?.shop?.stripe_connect_acct_id)
  const _terminals = useSelector(state => state.auth.terminals)

  const selfTriggeredRef = useRef(selfTriggered);
  const [_StripeTerminal, setStripeTerminal] = useState(null);

  // flag to notify the payment is complete regardless of successful and failure
  const user = useSelector(state => state.auth.user)
  const {
      connectedTerminal,
      discoveredTerminals,
      terminalStatus,
      stripeStatus,
      setStop,
      setResume,
      stripeDiscoverReaders,
      stripeConnectReader,
      getReader
  } = useTerminal(user?.shop, _terminals)


  const handleClose = () => {
    dispatch(setDialogOpen(false));
    cancel();
    setMessage('')
  };

  const handleCollectSplit = (id, total) => {
    setOrderPaymentId(id)
    setOrderItemIds(null);
    setMessage('')
    dispatch(splitPaymentIntent({order_item_ids: [], order_id: checkoutDialogOrder?.id, stripe_connect_acct_id: stripe_connect_acct_id, order_payment_id: id, total: total}))
  }

  const handleCollectSplitByItems = (id, items, total) => {
    setOrderPaymentId(id);
    setOrderItemIds(items);
    setMessage('')
    dispatch(splitPaymentIntent({order_item_ids: items, order_id: checkoutDialogOrder?.id, stripe_connect_acct_id: stripe_connect_acct_id, order_payment_id: id, total: total}))
  }


  // status change to AVAILABLE from any status
  const { trackedStatus: trackedAvailableStatus, reset: reset1 } = useActionStatusTracker(state => state.table.status, [
    updateTableAvail,
    deactivateInstance,
  ],
  {
    'table/updateTableAvail/fulfilled': 'update table avail finished',
    'table/deactivateInstance/fulfilled': 'deactivated',
    // Add other async thunks and their respective status messages here
  });

  const { trackedStatus: trackedOccupiedStatus, reset: reset2 } = useActionStatusTracker(state => state.table.status, [
    updateTableAvail,
    postTableInstance,
  ],
  {
    'table/updateTableAvail/fulfilled': 'update table avail finished',
    'table/postTableInstance/fulfilled': 'table instance added',
    // Add other async thunks and their respective status messages here
  });

  const { trackedStatus: trackedHoldStatus, reset: reset3 } = useActionStatusTracker(state => state.table.status, [
    updateTableHold,
    deactivateInstance,
  ],
  {
    'table/updateTableHold/fulfilled': 'update table hold finished',
    'table/deactivateInstance/fulfilled': 'deactivated',
    // Add other async thunks and their respective status messages here
  });

  const { trackedStatus: trackedSetTableStatus, reset: reset4 } = useActionStatusTracker(state => state.table.status, [
    setTableInstance,
  ],
  {
    'order/setTableInstance/fulfilled': 'table instance added',
    // Add other async thunks and their respective status messages here
  });


  const { trackedStatus: trackedClearedTableStatus, reset: reset5 } = useActionStatusTracker(state => state.table.status, [
    clearTableInstance,
  ],
  {
    'order/clearTableInstance/fulfilled': 'table instance cleared',
    // Add other async thunks and their respective status messages here
  });


  useEffect(() => {
    if (
       Object.keys(trackedAvailableStatus).length === 2 &&
       Object.values(trackedAvailableStatus).every((status) => status === 'fulfilled')
     ) {
       console.log('trackedAvailableStatus')
      if (status != 'fetch tables loading') {
        handleTableUpdate();
      }
      reset1(); // Reset the trackedStatus if needed
    }
  }, [trackedAvailableStatus, reset1]);

  useEffect(() => {
    if (
       Object.keys(trackedOccupiedStatus).length === 2 &&
       Object.values(trackedOccupiedStatus).every((status) => status === 'fulfilled')
     ) {
       console.log('trackedOccupiedStatus')
      if (status != 'fetch tables loading') {
        handleTableUpdate();
      }
      reset2(); // Reset the trackedStatus if needed
    }
  }, [trackedOccupiedStatus, reset2]);

  useEffect(() => {

    if (
       Object.keys(trackedHoldStatus).length === 2 &&
       Object.values(trackedHoldStatus).every((status) => status === 'fulfilled')
     ) {
       console.log('trackedHoldStatus')
      if (status != 'fetch tables loading') {
        handleTableUpdate();
      }
      reset3(); // Reset the trackedStatus if needed
    }
  }, [trackedHoldStatus, reset3]);

  useEffect(() => {

    if (
       Object.keys(trackedSetTableStatus).length === 1 &&
       Object.values(trackedSetTableStatus).every((status) => status === 'fulfilled')
     ) {
       console.log('trackedSetTableStatus')
      if (status != 'fetch tables loading') {
        handleTableUpdate();
      }
      reset4(); // Reset the trackedStatus if needed
    }
  }, [trackedSetTableStatus, reset4]);

  useEffect(() => {

    if (
       Object.keys(trackedClearedTableStatus).length === 1 &&
       Object.values(trackedClearedTableStatus).every((status) => status === 'fulfilled')
     ) {
       console.log('trackedSetTableStatus')
      if (status != 'fetch tables loading') {
        handleTableUpdate();
      }
      reset5(); // Reset the trackedStatus if needed
    }
  }, [trackedClearedTableStatus, reset5]);
  /*
  useEffect(() => {
    if (
       Object.keys(trackedSplitStatus).length === 1 &&
       Object.values(trackedSplitStatus).every((status) => status === 'fulfilled')
     ) {
       console.log('trackedSplitStatus')
      if (status != 'loading') {
        handleTableUpdate();
      }
      reset4(); // Reset the trackedStatus if needed
    }
  }, [trackedSplitStatus, reset4]);
  */
  // TODO: move it to Table.js
  useEffect(() => {
    if (orderStatus === 'order added') {
      //dispatch(fetchTables())
      dispatch(setStatus('idle'))
    } else if (orderStatus === 'split pmt added' || orderStatus === 'split removed') {
      if (tableStatus != 'fetch tables loading') {
        handleTableUpdate();
      }
    }
  }, [orderStatus])

  /*
  dispatch(updateTableAvail({id: props.id, available: true}))
  dispatch(deactivateInstance({id: props.id}))

  dispatch(updateTableAvail({id: props.id, available: false}))
  dispatch(postTableInstance({table: props.id}))

  dispatch(updateTableHold({id: props.id, hold: true}))
  dispatch(deactivateInstance({id: props.id}))
  */

  // TODO: move it to Table.js
  const handleTableUpdate = () => {

    const shopId = localStorage.getItem('shop_id')

    if (socketRef.current == null) {
       socketRef.current = io(DEFAULT_SOCKETS_URL, {
         transports: ["websocket"] // use WebSocket first, if available
       });
     }

    const {current: socket} = socketRef;

    try {
      console.log('emitting!')
      socket.emit('table-update-from-dashboard', {id: shopId});
    } catch (error) {
      console.log(error);
    }
  }

  const handleWaitlistUpdate = () => {

    const shopId = localStorage.getItem('shop_id')

    if (socketRef.current == null) {
       socketRef.current = io(DEFAULT_SOCKETS_URL, {
         transports: ["websocket"] // use WebSocket first, if available
       });
     }

    const {current: socket} = socketRef;

    try {
      console.log('emitting!')
      socket.emit('waitlist-added-from-kiosk', {id: shopId});
    } catch (error) {
      console.log(error);
    }
  }

  const cancel = async () => {
    if (_terminal) {
      _terminal.cancelCollectPaymentMethod().then(function(result) {
        if (result.error) {
          // error cancelling; do nothing
        } else {
          // cancel successful
          dispatch(clearSecret())
          dispatch(cancelPaymentIntent({id: intentId}))
          setOrderPaymentId(null);
        }
      });
    }
  }

  useEffect(() => {
    dispatch(fetchTables());
    dispatch(getWaitlist());
    dispatch(getTerminals());
    setResume();

    const shopId = localStorage.getItem('shop_id')

    if (socketRef.current == null) {
       socketRef.current = io(DEFAULT_SOCKETS_URL, {
         transports: ["websocket"] // use WebSocket first, if available
       });
     }

    const {current: socket} = socketRef;

    try {
      socket.open();

      socket.on('connect', function(data) {
        socket.sendBuffer = [];

        // join a shop's unique room, defined by shop_id, for handling kds
        console.log('connected')

        socket.emit('join', {id: shopId});
        console.log('joined ' + shopId)
        dispatch(fetchTables());
        dispatch(getWaitlist());
        dispatch(getTerminals());
        setResume();
      });

      socket.on("disconnect", (reason) => {
          if (reason === "io server disconnect") {
              // the disconnection was initiated by the server, you need to reconnect manually
              socket.connect();
          }
          // else the socket will automatically try to reconnect
      });

      socket.on("connect_error", () => {
          // revert to classic upgrade
          socket.connect();

      });

      socket.on("connect_failed", () => {
          // revert to classic upgrade
          socket.connect();
      });

      socket.on('error', function () {
          socket.connect();
      });

      socket.on('reconnect', () => {
        socket.sendBuffer = [];

        console.log('reconnected')

        socket.emit('join', {id: shopId});
        console.log('rejoined')
        dispatch(fetchTables());
        dispatch(getWaitlist());
        dispatch(getTerminals());
        setResume();
      })

      // called when there are updates from kiosk
      socket.on('waitlist-added-dashboard', payload => {
        console.log('waitlist-added-dashboard')
        dispatch(getWaitlist())
      });

      socket.on('notify-success', payload => {
        dispatch(fetchTableForPayment({pk: payload.data.table_id}))
      });

    } catch (error) {
      console.log(error);
    }
    // Return a callback to be run before unmount-ing.
    return () => {
      socket.off('connect')
      socket.off('reconnect')
      socket.off('disconnect')
      socket.off('connect_error')
      socket.off('connect_failed')
      socket.off('error')
      socket.off('waitlist-added-dashboard')
      socket.off('notify-success')
    };
  }, [])


  useEffect(() => {
    const shopId = localStorage.getItem('shop_id')

    if (socketRef.current == null) {
       socketRef.current = io(DEFAULT_SOCKETS_URL, {
         transports: ["websocket"] // use WebSocket first, if available
       });
     }

    const {current: socket} = socketRef;
    // called when there are updates from kiosk
    socket.on('table-updated-dashboard', payload => {
        if (!selfTriggeredRef.current) {
          dispatch(fetchTables());
        }
    });

    // called when there are updates from dashboard
    socket.on('table-updated-kiosk', payload => {
      if (!selfTriggeredRef.current) { // 만약에 다른 대시보드 client에서 update가 온다면??
        dispatch(fetchTables())
      }
    });

    return () => {
      socket.off('table-updated-kiosk')
      socket.off('table-updated-dashboard')
    }
  }, [selfTriggeredRef.current])

  useEffect(() => {
    selfTriggeredRef.current = selfTriggered;
  }, [selfTriggered]);

  useEffect(() => {
    setTerminals(discoveredTerminals);
    //stripeConnectReader(discoveredTerminals);
  }, [discoveredTerminals])

  useEffect(() => {
    setTerminal(connectedTerminal)
  }, [connectedTerminal])

  useEffect(() => {
    if (secret) {
      // if we have a secret, we have a pmt intent on backend
      handleCollection()
    }
  }, [secret])

  useEffect(() => {
    if (splitSecret) {
      // if we have a secret, we have a pmt intent on backend
      handleSplitCollection()
    }
  }, [splitSecret])


  useEffect(() => {
    if (status === 'captured intent') {
      dispatch(fetchSplitPmt({id: checkoutDialogOrder?.id}))
    }
  }, [status])

  useEffect(() => {
    if (tableStatus === 'fetch table fulfilled 2') {
      dispatch(setPaymentComplete(true));
      dispatch(setTableStatus('idle'));
    } else if (
      tableStatus === 'table instance cleared' ||
      tableStatus === 'table deleted'
    ) {
      handleTableUpdate();
    } else if (
      tableStatus === 'assign finished' ||
      tableStatus === 'confirm finished' ||
      tableStatus === 'cancel finished' ||
      tableStatus === 'waitlist deleted'
    ) {
      handleWaitlistUpdate();
    }
  }, [tableStatus]);


  useEffect(() => {
      if (stripeStatus === 'loading') {
          setOpenSnackbar(true)
          setSnackbarMessage('INITIALIZING PAYMENT TERMINAL')

      } else if (stripeStatus === 'initializing') {
        setOpenSnackbar(true)
          setSnackbarMessage('DISCOVERING TERMINAL')
      }

      if (terminalStatus === 'failed' || stripeStatus === 'failed') {
        setOpenSnackbar(true)
          setSnackbarMessage('FAILED TO CONNECT TO TERMINAL. PLEASE WAIT WHILE WE RETRY')
      } else if (terminalStatus === 'connecting') {
        //setOpenSnackbar(true)

          setSnackbarMessage('ESTABLISHING CONNECTION TO TERMINAL')
      } else if (terminalStatus === 'connected') {
        setOpenSnackbar(true)

          setSnackbarMessage('CONNECTED TO TERMINAL!')
          setTimeout(() => {
            setOpenSnackbar(false)

          }, 5000)

      } else if (terminalStatus === 'disconnected') {
        setOpenSnackbar(true)

          setSnackbarMessage('TERMINAL DISCONNECTED. RECONNECTION IS UNDERWAY')
      } else if (terminalStatus === 'empty') {
        setOpenSnackbar(true)
        setSnackbarMessage('TERMINAL NOT FOUND')
        setTimeout(() => {
          setOpenSnackbar(false)

        }, 5000)
      }
  }, [terminalStatus, stripeStatus])

  // call after tip.
  const handleCollection = async () => {
    if (!_terminal || _terminal.getConnectionStatus() !== 'connected') {
      console.log('not connected')
      // DEBUG: COMMENT OUT BELOW TWO LINES
      return;
    }

    _terminal.collectPaymentMethod(secret,
      {
        config_override: {
        skip_tipping: false,
      }}).then(function(result) {
      if (result.error) {
        console.log(result.error.message)
        setMessage(result.error.message)
        /* Payment Collection Error!
         ----------------------------------------------- */
        // 1. Show modal for a few seconds w/ an err msg
        // 2. Try again
        dispatch(clearSecret())           // Clear previous transaction
      } else {
          _terminal.processPayment(result.paymentIntent).then(function(result) {
          if (result.error) {
            console.log(result.error.message)
            setMessage(result.error.message)
            dispatch(clearSecret())           // Clear previous transaction
          } else if (result.paymentIntent) {

            // TODO: What if internet connection gets lost here
            //       before adding an order to the server?
            // ================================================
            // On Checkout Screen, save cart items locally
            // and payment intent id
            // now on app start up, check if any orders have
            // saved intent id, if not create an order and save that
            // id and capture it using id in the background
            // if there's something in the backend, clear all
            // local data.

            /* Payment Intent Created! Proceed.
             ------------------------------------------- */
            //const _order = checkoutDialogOrder // last opened order. checkout processing happens when modal is opened

            //dispatch(updatePmtIntent({id: _order?.id, stripe_payment_intent: result.paymentIntent.id}))
            dispatch(clearSecret())           // 2. Clear previous transaction
            //dispatch(setDialogOpen(false));
            //dispatch(capturePaymentIntent({id: result.paymentIntent.id, order_id: _order.id, order_payment_id: null}))
          }
        });
      }
    });
  }


    // call after tip.
    const handleSplitCollection = async () => {
      if (!_terminal || _terminal.getConnectionStatus() !== 'connected') {
        console.log('not connected')
        // DEBUG: COMMENT OUT BELOW TWO LINES
        return;
      }

      _terminal.collectPaymentMethod(splitSecret,
        {
          config_override: {
          skip_tipping: false,
        }}).then(function(result) {
        if (result.error) {
          setMessage(result.error.message)
          /* Payment Collection Error!
           ----------------------------------------------- */
          // 1. Show modal for a few seconds w/ an err msg
          // 2. Try again
          dispatch(clearSecret())           // Clear previous transaction
        } else {
            _terminal.processPayment(result.paymentIntent).then(function(result) {
            if (result.error) {
              setMessage(result.error.message)

              dispatch(clearSecret())           // Clear previous transaction

            } else if (result.paymentIntent) {

              // TODO: What if internet connection gets lost here
              //       before adding an order to the server?
              // ================================================
              // On Checkout Screen, save cart items locally
              // and payment intent id
              // now on app start up, check if any orders have
              // saved intent id, if not create an order and save that
              // id and capture it using id in the background
              // if there's something in the backend, clear all
              // local data.

              /* Payment Intent Created! Proceed.
               ------------------------------------------- */
              //const _order = checkoutDialogOrder // last opened order. checkout processing happens when modal is opened
              // if this is a split payment, set a four-digit (1111) number
              // to order.stripe_payment_intent to indicate that this is a paid
              // order. Maybe create a column like paid for Order as well in the future
              //dispatch(updateItemsPaid({paid: true, items: orderItemIds}))
              //dispatch(updatePmtIntent({id: _order?.id, stripe_payment_intent: 1111}))
              dispatch(clearSecret())           // 2. Clear previous transaction
              //dispatch(capturePaymentIntent({id: result.paymentIntent.id, order_id: _order.id, order_payment_id: orderPaymentId}))
            }
          });
        }
      });
    }

    const handleSnackbarClose = (event, reason) => {
       if (reason === 'clickaway') {
         return;
       }

       setOpenSnackbar(false);
     };


  let contentView = null;
  if (value === 0) {
    contentView =
    <CardView
      message={message}
      received={checkoutDialogReceived}
      onCollect={(id) => {
        /*
        1. stripe_connect_acct_id
        2. order_payment_id # None if no split payment
        3. order_id
        4. surcharge
        */
        dispatch(postPaymentIntent(
          {
            order_id: id,
            stripe_connect_acct_id: stripe_connect_acct_id,
            order_payment_id: null,
            total: currency(currency(currency(checkoutDialogOrder.total)))
          }))
      }}
      onCollectSplit={(id, total) => handleCollectSplit(id, total)}
      onCollectSplitByItems={(id, items, total) => handleCollectSplitByItems(id, items, total)}
      onCollectCancel={() => {cancel();}}
      terminal={_terminal}
      terminals={terminals}
      getReader={getReader}
      stripeConnectReader={stripeConnectReader}
      paymentComplete={paymentComplete}
      order={checkoutDialogOrder}
      handleClose={handleClose}
      open={checkoutDialogOpen}
    />
  } else if (value === 1) {
    contentView =
    <CanvasView
     getReader={getReader}
      message={message}
      received={checkoutDialogReceived}
      stripeConnectReader={stripeConnectReader}
      onCollect={(id) => {
        /*
        1. stripe_connect_acct_id
        2. order_payment_id # None if no split payment
        3. order_id
        4. surcharge
        */
        dispatch(postPaymentIntent(
          {
            order_id: id,
            stripe_connect_acct_id: stripe_connect_acct_id,
            order_payment_id: null,
            total: currency(currency(currency(checkoutDialogOrder.total)))
          }))
      }}
      onCollectSplit={(id, total) => handleCollectSplit(id, total)}
      onCollectSplitByItems={(id, items, total) => handleCollectSplitByItems(id, items, total)}
      onCollectCancel={() => {cancel();}}
      terminal={_terminal}
      terminals={terminals}
      paymentComplete={paymentComplete}
      order={checkoutDialogOrder}
      handleClose={handleClose}
      open={checkoutDialogOpen}
     />
  } else {
    contentView = <WaitList />
  }

  return (
    <div className={classes.root}>
      <Paper square style={{marginBottom: 20}}>
        {value !== 2 ? <Button onClick={() => dispatch(setAddTableModalOpen(true))} variant="contained" size="small" color="primary" style={{float: 'right', position: 'relative', top: 9, marginRight: 15}}>Add TABLE</Button> : null}
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              aria-label="disabled tabs example"
            >
            <Tab label="Floor Plan" value={1} />
              <Tab label="Card View" value={0} />
              <Tab label={<span>Waitlist {waitlist?.length > 0 ? <Chip style={{marginLeft: 5, borderRadius: 3}} label={waitlist?.length} size="small" color="secondary" /> : null}</span>} value={2} />
            </Tabs>
      </Paper>
      {value !== 2 && waitlist?.length > 0 ? (
        <Alert severity="info" style={{marginBottom: 10}}>Customers are now on the waitlist. Please reserve available tables as 'HOLD FOR WAITLIST' and assign guests to these tables via the WAITLIST tab</Alert>
      ) : null}
      {contentView}
      <AddTableModal />
      <Snackbar
             anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'left',
             }}
             open={openSnackbar}
             autoHideDuration={2000}
             message={snackbarMessage}
             action={
               <React.Fragment>
                 <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
                   <CloseIcon fontSize="small" />
                 </IconButton>
               </React.Fragment>
             }
           />
           <OrderModal2 />

    </div>
  )
}

export default Table;
