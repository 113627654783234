import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET, POST, AUTH_LOGIN, AUTH_REFRESH, POST_PUBLIC, POST_PUBLIC_FILE } from '../../api/DashboardAPI';

export const getSpecificShop = createAsyncThunk('auth/getSpecificShop', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/get_specific_shop/', payload);
    return response.data; // payload
  } catch (err) {
    console.log(err)
    return rejectWithValue(err.data.message)
  }
});

export const getFranchisedShops = createAsyncThunk('auth/getFranchisedShops', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/franchised_shops/', payload);
    return response.data; // payload
  } catch (err) {
    console.log(err)
    return rejectWithValue(err.data.message)
  }
});

export const postMenuImage = createAsyncThunk('auth/postMenuImage', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/upload_shop_menu_image/', payload);
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const postMainImage = createAsyncThunk('auth/postMainImage', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/upload_main_image/', payload);
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});


export const getToggleTestingState = createAsyncThunk('auth/getToggleTestingState', async (payload, { rejectWithValue }) => {
  try {
    const response = await GET('/dashboard/shop/get_toggle_testing_state/');
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const getTerminals = createAsyncThunk('auth/getTerminals', async (payload, { rejectWithValue }) => {
  try {
    const response = await GET('/dashboard/shop/get_terminals/');
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const getToggleFullHeight = createAsyncThunk('auth/getToggleFullHeight', async (payload, { rejectWithValue }) => {
  try {
    const response = await GET('/dashboard/shop/get_toggle_full_height/');
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const postToggleFullHeight = createAsyncThunk('auth/postToggleFullHeight', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/toggle_full_height/');
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const postToggleTicketSimplified = createAsyncThunk('auth/postToggleTicketSimplified', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/toggle_simplify_ticket/');
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const postToggleTestingState = createAsyncThunk('auth/postToggleTestingState', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/toggle_testing_state/');
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const getToggleTableMode = createAsyncThunk('auth/getToggleTableMode', async (payload, { rejectWithValue }) => {
  try {
    const response = await GET('/dashboard/shop/get_toggle_table_mode/');
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const postToggleTableMode = createAsyncThunk('auth/postToggleTableMode', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/toggle_table_mode/');
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const agreeTermsAndCondition = createAsyncThunk('auth/agreeTermsAndCondition', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/agree_terms/');
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const postAuthLogin = createAsyncThunk('auth/postAuthLogin', async (obj, { rejectWithValue }) => {
  try {
    const response = await AUTH_LOGIN({username: obj.username, password: obj.password});
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message);
  }
});

export const postAuthRefresh = createAsyncThunk('auth/postAuthRefresh', async (obj) => {
  const response = await AUTH_REFRESH({refresh: obj.refresh});
  return response.data; // payload
});

export const postRegisterUser = createAsyncThunk('auth/postRegisterUser', async (obj, { rejectWithValue }) => {
  try {
    const response = await POST_PUBLIC('/dashboard/register/', obj);
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data);
  }
});

export const postCustomBusinessData = createAsyncThunk('auth/postCustomBusinessData', async (obj, { rejectWithValue }) => {
  try {
    const response = await POST_PUBLIC_FILE('/dashboard/custom-business-data/', obj);
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data);
  }
});

export const postChangePassword = createAsyncThunk('auth/postChangePassword', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/systemuser/change_password/', payload);
    return response; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const postUpdateTaxRate = createAsyncThunk('auth/postUpdateTaxRate', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/update_tax_rate/', payload);
    return response; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const postUpdateChargeCustomersFee = createAsyncThunk('auth/postUpdateChargeCustomersFee', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/charge_customers_fee/', payload);
    return response; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const postUpdateTipEnabled = createAsyncThunk('auth/postUpdateTipEnabled', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/enable_tipping/', payload);
    return response; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const getSystemUser = createAsyncThunk('auth/getSystemUser', async () => {
  const response = await GET('/dashboard/systemuser/get_systemuser/');
  return response.data; // payload
});

export const getCustomersFeeStatus = createAsyncThunk('auth/getCustomersFeeStatus', async (payload, { rejectWithValue }) => {
  try {
    const response = await GET('/dashboard/shop/get_customers_fee_status/');
    return response; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const getStripeAccount = createAsyncThunk('auth/getStripeAccount', async () => {
  const response = await POST('/dashboard/get_stripe_account/');
  return response.data; // payload
});

export const getAccountLink = createAsyncThunk('auth/getAccountLink', async () => {
  const response = await POST('/dashboard/stripe_account_link/');
  return response.data; // payload
});

export const sendMarketingSms = createAsyncThunk('auth/sendMarketingSms', async (payload) => {
  const response = await POST('/dashboard/shop/send_marketing_sms/', payload);
  return response.data; // payload
});

export const sendTestMarketingSms = createAsyncThunk('auth/sendTestMarketingSms', async (payload) => {
  const response = await POST('/dashboard/shop/test_send_marketing_sms/', payload);
  return response.data; // payload
});


export const fixStripeMCC = createAsyncThunk('auth/fixStripeMCC', async () => {
  const response = await POST('/dashboard/fix_stripe_mcc/');
  return response.data; // payload
});



export const getAddCategoryAllowed = createAsyncThunk('auth/getAddCategoryAllowed', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/get_add_category_allowed/', payload);
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const getAddItemAllowed = createAsyncThunk('auth/getAddItemAllowed', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/get_add_item_allowed/', payload);
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const getAddAddonAllowed = createAsyncThunk('auth/getAddAddonAllowed', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/get_add_addon_allowed/', payload);
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const getEditCategoryAllowed = createAsyncThunk('auth/getEditCategoryAllowed', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/get_edit_category_allowed/', payload);
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const getEditItemAllowed = createAsyncThunk('auth/getEditItemAllowed', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/get_edit_item_allowed/', payload);
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const getEditAddonAllowed = createAsyncThunk('auth/getEditAddonAllowed', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/get_edit_addon_allowed/', payload);
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const getDeleteCategoryAllowed = createAsyncThunk('auth/getDeleteCategoryAllowed', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/get_delete_category_allowed/', payload);
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const getDeleteItemAllowed = createAsyncThunk('auth/getDeleteItemAllowed', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/get_delete_item_allowed/', payload);
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const getDeleteAddonAllowed = createAsyncThunk('auth/getDeleteAddonAllowed', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/get_delete_addon_allowed/', payload);
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});


export const toggleAddCategoryAllowed = createAsyncThunk('auth/toggleAddCategoryAllowed', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/toggle_add_category_allowed/', payload);
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const toggleAddItemAllowed = createAsyncThunk('auth/toggleAddItemAllowed', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/toggle_add_item_allowed/', payload);
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const toggleAddAddonAllowed = createAsyncThunk('auth/toggleAddAddonAllowed', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/toggle_add_addon_allowed/', payload);
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const toggleEditCategoryAllowed = createAsyncThunk('auth/toggleEditCategoryAllowed', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/toggle_edit_category_allowed/', payload);
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const toggleEditItemAllowed = createAsyncThunk('auth/toggleEditItemAllowed', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/toggle_edit_item_allowed/', payload);
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const toggleEditAddonAllowed = createAsyncThunk('auth/toggleEditAddonAllowed', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/toggle_edit_addon_allowed/', payload);
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const toggleDeleteCategoryAllowed = createAsyncThunk('auth/toggleDeleteCategoryAllowed', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/toggle_delete_category_allowed/', payload);
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const toggleDeleteItemAllowed = createAsyncThunk('auth/toggleDeleteItemAllowed', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/toggle_delete_item_allowed/', payload);
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const toggleDeleteAddonAllowed = createAsyncThunk('auth/toggleDeleteAddonAllowed', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/toggle_delete_addon_allowed/', payload);
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const getRewardsMembers = createAsyncThunk('auth/getRewardsMembers', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/get_rewards_members/');
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const getNumRewardsMembers = createAsyncThunk('auth/getNumRewardsMembers', async (payload, { rejectWithValue }) => {
  try {
    const response = await GET('/dashboard/shop/get_num_rewards_members/');
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});


export const updateEarningRatio = createAsyncThunk('auth/updateEarningRatio', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/update_rewards_ratio/', payload);
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const getRewardsMembersCountAvailToSend = createAsyncThunk('auth/getRewardsMembersCountAvailToSend', async (payload, { rejectWithValue }) => {
  try {
    const response = await GET('/dashboard/shop/get_rewards_members_avail_to_send_sms_marketing/');
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});


export const chargeDefaultCard = createAsyncThunk('auth/chargeDefaultCard', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/charge_default_card/', payload);
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});


export const listCards = createAsyncThunk('auth/listCards', async (payload, { rejectWithValue }) => {
  try {
    const response = await GET('/dashboard/shop/list_cards/');
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const setDefaultCard = createAsyncThunk('auth/setDefaultCard', async (payload, { rejectWithValue }) => {
  try {
    const response = await GET('/dashboard/shop/set_default_card/', payload);
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const addCard = createAsyncThunk('auth/addCard', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/add_card/', payload);
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const deleteCard = createAsyncThunk('auth/deleteCard', async (payload, { rejectWithValue }) => {
  try {
    const response = await POST('/dashboard/shop/delete_card/', payload);
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});



export const getSmsRecords = createAsyncThunk('auth/getSmsRecords', async (payload, { rejectWithValue }) => {
  try {
    const response = await GET('/dashboard/shop/get_sms_records/', payload);
    return response.data; // payload
  } catch (err) {
    return rejectWithValue(err.data.message)
  }
});

export const getAllShops = createAsyncThunk('auth/getAllShops', async () => {
  const response = await GET('/dashboard/shop/get_all_shops/');
  return response.data; // payload
});

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    status: 'idle',
    refreshToken: null,
    accessToken: null,
    refreshExpired: true,
    accessTokenExpired: true,
    user: null,
    targetShop: null,
    serverMessage: '',
    accountLink: null,
    stripeAccount: null,
    chargeCustomers: false,
    isTesting: null,
    tipEnabled: null,
    isTableMode: null,
    isImgFullHeight: null,
    franchisedShops: [],
    terminals: [],
    rewardsMembers: [],
    numRewardsMembers: 0,
    numRewardsMembersCountAvailToSend: 0,
    chargeCardMessage: '',
    sendSmsMessage: '',
    cards: [],
    smsRecords: [],
    shops: [],
  },
  reducers: {
    setSendSmsMessage: (state, action) => {
      state.sendSmsMessage = action.payload
    },
    clearAll: (state) => {
      state.status = 'idle';
      state.refreshToken = null;
      state.accessToken = null;
      state.refreshExpired = true;
      state.accessTokenExpired = true;
    },
    setToken: (state, action) => {
      state.refreshExpired = false
      state.accessTokenExpired = false
      state.status = 'authorized'
      state.accessToken = action.payload.access;
      state.refreshToken = action.payload.refresh;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    }
  },
  extraReducers: {
    [postAuthLogin.pending]: (state, action) => {
      state.status = 'loading'
    },
    [postAuthLogin.fulfilled]: (state, action) => {
      state.refreshExpired = false
      state.accessTokenExpired = false
      state.status = 'authorized'
      state.accessToken = action.payload.access;
      state.refreshToken = action.payload.refresh;
    },
    [postAuthLogin.rejected]: (state, action) => {
      state.refreshExpired = true
      state.accessTokenExpired = true
      state.status = 'unauthorized'
      state.accessToken = null
      state.refreshToken = null;
    },
    [postAuthLogin.pending]: (state, action) => {
      state.status = 'loading'
    },
    [postRegisterUser.fulfilled]: (state, action) => {
      state.status = 'registered'
    },
    [postRegisterUser.rejected]: (state, action) => {
      state.status = 'register failed'
      let message = '';
      if (typeof action.payload.username != "undefined") {
        message = action.payload.username[0]
      } else if (typeof action.payload.message != "undefined") {
        message = action.payload.message[0]
      }
      state.serverMessage = message;
    },
    [postRegisterUser.pending]: (state, action) => {
      state.status = 'loading'
    },
    [postAuthRefresh.fulfilled]: (state, action) => {
      state.refreshExpired = false
      state.accessTokenExpired = false
      state.status = 'authorized'
      state.accessToken = action.payload.access;
      state.refreshToken = action.payload.refresh;
    },
    [postAuthRefresh.rejected]: (state, action) => {
      state.refreshExpired = true
      state.accessTokenExpired = true
      state.status = 'unauthorized'
      state.accessToken = null
      state.refreshToken = null;
    },

    [getSystemUser.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.user = action.payload;
    },
    [getSystemUser.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [getSystemUser.pending]: (state, action) => {
      state.status = 'loading'
    },

    [getStripeAccount.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.stripeAccount = action.payload;
    },
    [getStripeAccount.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [getStripeAccount.pending]: (state, action) => {
      state.status = 'loading'
    },
    [getAccountLink.fulfilled]: (state, action) => {
      state.status = 'account link created'
      state.accountLink = action.payload;
    },
    [getAccountLink.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [getAccountLink.pending]: (state, action) => {
      state.status = 'loading'
    },

    [getCustomersFeeStatus.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.chargeCustomers = action.payload.data.charge_customers_fee;
    },
    [getCustomersFeeStatus.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [getCustomersFeeStatus.pending]: (state, action) => {
      state.status = 'loading'
    },
    [postChangePassword.fulfilled]: (state, action) => {
      state.status = 'added'
      state.serverMessage = action.payload.data.message;
    },
    [postChangePassword.rejected]: (state, action) => {
      state.status = 'failed'
      state.serverMessage = action.payload;
    },
    [postChangePassword.pending]: (state, action) => {
      state.status = 'loading'
    },

    [postUpdateTaxRate.fulfilled]: (state, action) => {
      state.status = 'updated'
      state.serverMessage = action.payload.data.message;
    },
    [postUpdateTaxRate.rejected]: (state, action) => {
      state.status = 'failed'
      state.serverMessage = action.payload;
    },
    [postUpdateTaxRate.pending]: (state, action) => {
      state.status = 'loading'
    },
    [postUpdateChargeCustomersFee.fulfilled]: (state, action) => {
      state.status = 'customers fee updated'
      state.chargeCustomers = action.payload.data.charge_customers_fee;
      state.serverMessage = action.payload.data.message;
    },
    [postUpdateChargeCustomersFee.rejected]: (state, action) => {
      state.status = 'failed'
      state.serverMessage = action.payload;
    },
    [postUpdateChargeCustomersFee.pending]: (state, action) => {
      state.status = 'loading'
    },



    [postUpdateTipEnabled.fulfilled]: (state, action) => {
      state.status = 'tipping status updated'
      state.user.shop.tip_enabled = action.payload.data.tip_enabled;
      state.serverMessage = action.payload.data.message;
    },
    [postUpdateTipEnabled.rejected]: (state, action) => {
      state.status = 'failed'
      state.serverMessage = action.payload;
    },
    [postUpdateTipEnabled.pending]: (state, action) => {
      state.status = 'loading'
    },


    [postCustomBusinessData.fulfilled]: (state, action) => {
      state.status = 'custom form submitted'
    },
    [postCustomBusinessData.rejected]: (state, action) => {
      state.status = 'custom form failed submitted'
    },
    [postCustomBusinessData.pending]: (state, action) => {
      state.status = 'custom form loading'
    },

    [getToggleFullHeight.fulfilled]: (state, action) => {
      //state.status = 'succeeded obtaining customers fee boolean'
      state.isImgFullHeight = action.payload.kiosk_main_img_full_height;
    },
    [getToggleFullHeight.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [getToggleFullHeight.pending]: (state, action) => {
      state.status = 'loading'
    },

    [getTerminals.fulfilled]: (state, action) => {
      state.status = 'succeeded obtaining termials'
      state.terminals = action.payload;
    },
    [getTerminals.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [getTerminals.pending]: (state, action) => {
      state.status = 'loading'
    },


    [getToggleTestingState.fulfilled]: (state, action) => {
      //state.status = 'succeeded obtaining customers fee boolean'
      state.isTesting = action.payload.is_testing;
    },
    [getToggleTestingState.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [getToggleTestingState.pending]: (state, action) => {
      state.status = 'loading'
    },
    [getToggleTableMode.fulfilled]: (state, action) => {
      //state.status = 'succeeded obtaining customers fee boolean'
      state.isTableMode = action.payload.is_table_mode;
    },
    [getToggleTableMode.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [getToggleTableMode.pending]: (state, action) => {
      state.status = 'loading'
    },
    [postToggleFullHeight.fulfilled]: (state, action) => {
      //state.status = 'succeeded obtaining customers fee boolean'
      state.isImgFullHeight = action.payload.kiosk_main_img_full_height;
    },
    [postToggleFullHeight.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [postToggleFullHeight.pending]: (state, action) => {
      state.status = 'loading'
    },

    [postToggleTicketSimplified.fulfilled]: (state, action) => {
      //state.status = 'succeeded obtaining customers fee boolean'
      state.status = 'ticket simplified'
    },
    [postToggleTicketSimplified.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [postToggleTicketSimplified.pending]: (state, action) => {
      state.status = 'loading'
    },

    [postToggleTestingState.fulfilled]: (state, action) => {
      //state.status = 'succeeded obtaining customers fee boolean'
      state.isTesting = action.payload.is_testing;
    },
    [postToggleTestingState.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [postToggleTestingState.pending]: (state, action) => {
      state.status = 'loading'
    },
    [postToggleTableMode.fulfilled]: (state, action) => {
      //state.status = 'succeeded obtaining customers fee boolean'
      state.isTableMode = action.payload.is_table_mode;
    },
    [postToggleTableMode.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [postToggleTableMode.pending]: (state, action) => {
      state.status = 'loading'
    },

    [agreeTermsAndCondition.fulfilled]: (state, action) => {
      state.status = 'agreed'
    },
    [agreeTermsAndCondition.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [agreeTermsAndCondition.pending]: (state, action) => {
      state.status = 'loading'
    },

    [postMenuImage.fulfilled]: (state, action) => {
      state.status = 'succeeded image'
    },
    [postMenuImage.rejected]: (state, action) => {
      state.status = 'failed image'
    },
    [postMenuImage.pending]: (state, action) => {
      state.status = 'loading image'
    },
    [postMainImage.fulfilled]: (state, action) => {
      state.status = 'succeeded image'
    },
    [postMainImage.rejected]: (state, action) => {
      state.status = 'failed image'
    },
    [postMainImage.pending]: (state, action) => {
      state.status = 'loading image'
    },


    [fixStripeMCC.fulfilled]: (state, action) => {
      state.status = 'succeeded mcc'
    },
    [fixStripeMCC.rejected]: (state, action) => {
      state.status = 'failed mcc'
    },
    [fixStripeMCC.pending]: (state, action) => {
      state.status = 'loading mcc'
    },

    [getFranchisedShops.fulfilled]: (state, action) => {
      state.status = 'succeeded getting franchised shops'
      state.franchisedShops = action.payload;
    },
    [getFranchisedShops.rejected]: (state, action) => {
      state.status = 'failed getting franchised shops'
    },
    [getFranchisedShops.pending]: (state, action) => {
      state.status = 'loading getting franchised shops'
    },

    [getSpecificShop.fulfilled]: (state, action) => {
      state.status = 'succeeded getting specified shop'
      state.targetShop = action.payload;
    },
    [getSpecificShop.rejected]: (state, action) => {
      state.status = 'failed getting specified shop'
    },
    [getSpecificShop.pending]: (state, action) => {
      state.status = 'loading getting specified shop'
    },












    [toggleAddCategoryAllowed.fulfilled]: (state, action) => {
      state.status = 'succeeded toggling an access control'
    },
    [toggleAddCategoryAllowed.rejected]: (state, action) => {
      state.status = 'failed toggling an access control'
    },
    [toggleAddCategoryAllowed.pending]: (state, action) => {
      state.status = 'loading toggling an access control'
    },

    [toggleAddItemAllowed.fulfilled]: (state, action) => {
      state.status = 'succeeded toggling an access control'
    },
    [toggleAddItemAllowed.rejected]: (state, action) => {
      state.status = 'failed toggling an access control'
    },
    [toggleAddItemAllowed.pending]: (state, action) => {
      state.status = 'loading toggling an access control'
    },

    [toggleAddAddonAllowed.fulfilled]: (state, action) => {
      state.status = 'succeeded toggling an access control'
    },
    [toggleAddAddonAllowed.rejected]: (state, action) => {
      state.status = 'failed toggling an access control'
    },
    [toggleAddAddonAllowed.pending]: (state, action) => {
      state.status = 'loading toggling an access control'
    },


    [toggleEditCategoryAllowed.fulfilled]: (state, action) => {
      state.status = 'succeeded toggling an access control'
    },
    [toggleEditCategoryAllowed.rejected]: (state, action) => {
      state.status = 'failed toggling an access control'
    },
    [toggleEditCategoryAllowed.pending]: (state, action) => {
      state.status = 'loading toggling an access control'
    },

    [toggleEditItemAllowed.fulfilled]: (state, action) => {
      state.status = 'succeeded toggling an access control'
    },
    [toggleEditItemAllowed.rejected]: (state, action) => {
      state.status = 'failed toggling an access control'
    },
    [toggleEditItemAllowed.pending]: (state, action) => {
      state.status = 'loading toggling an access control'
    },

    [toggleEditAddonAllowed.fulfilled]: (state, action) => {
      state.status = 'succeeded toggling an access control'
    },
    [toggleEditAddonAllowed.rejected]: (state, action) => {
      state.status = 'failed toggling an access control'
    },
    [toggleEditAddonAllowed.pending]: (state, action) => {
      state.status = 'loading toggling an access control'
    },


    [toggleDeleteCategoryAllowed.fulfilled]: (state, action) => {
      state.status = 'succeeded toggling an access control'
    },
    [toggleDeleteCategoryAllowed.rejected]: (state, action) => {
      state.status = 'failed toggling an access control'
    },
    [toggleDeleteCategoryAllowed.pending]: (state, action) => {
      state.status = 'loading toggling an access control'
    },

    [toggleDeleteItemAllowed.fulfilled]: (state, action) => {
      state.status = 'succeeded toggling an access control'
    },
    [toggleDeleteItemAllowed.rejected]: (state, action) => {
      state.status = 'failed toggling an access control'
    },
    [toggleDeleteItemAllowed.pending]: (state, action) => {
      state.status = 'loading toggling an access control'
    },

    [toggleDeleteAddonAllowed.fulfilled]: (state, action) => {
      state.status = 'succeeded toggling an access control'
    },
    [toggleDeleteAddonAllowed.rejected]: (state, action) => {
      state.status = 'failed toggling an access control'
    },
    [toggleDeleteAddonAllowed.pending]: (state, action) => {
      state.status = 'loading toggling an access control'
    },

    [getRewardsMembers.fulfilled]: (state, action) => {
      state.rewardsMembers = action.payload;
      state.status = 'get rewards members succeeded'

    },
    [getRewardsMembers.rejected]: (state, action) => {
      state.status = 'get rewards members failed'
    },
    [getRewardsMembers.pending]: (state, action) => {
      state.status = 'get rewards members loading'
    },

    [getNumRewardsMembers.fulfilled]: (state, action) => {
      state.numRewardsMembers = action.payload.count;
      state.status = 'get num rewards members succeeded'

    },
    [getNumRewardsMembers.rejected]: (state, action) => {
      state.status = 'get num rewards members failed'
    },
    [getNumRewardsMembers.pending]: (state, action) => {
      state.status = 'get num rewards members loading'
    },

    [getRewardsMembersCountAvailToSend.fulfilled]: (state, action) => {
      state.numRewardsMembersCountAvailToSend = action.payload.count;
      state.status = 'get num rewards members avail to send succeeded'

    },
    [getRewardsMembersCountAvailToSend.rejected]: (state, action) => {
      state.status = 'get num rewards members avail to send failed'
    },
    [getRewardsMembersCountAvailToSend.pending]: (state, action) => {
      state.status = 'get num rewards members avail to send loading'
    },


    [updateEarningRatio.fulfilled]: (state, action) => {
      state.status = 'get earning ratio succeeded'

    },
    [updateEarningRatio.rejected]: (state, action) => {
      state.status = 'get earning ratio failed'
    },
    [updateEarningRatio.pending]: (state, action) => {
      state.status = 'get earning ratio loading'
    },



    [sendMarketingSms.fulfilled]: (state, action) => {
      state.status = 'marketing sms send succeeded'

    },
    [sendMarketingSms.rejected]: (state, action) => {
      state.status = 'marketing sms send failed'
    },
    [sendMarketingSms.pending]: (state, action) => {
      state.status = 'marketing sms send loading'
    },




    [sendTestMarketingSms.fulfilled]: (state, action) => {
      state.status = 'marketing sms send succeeded'

    },
    [sendTestMarketingSms.rejected]: (state, action) => {
      state.status = 'marketing sms send failed'
    },
    [sendTestMarketingSms.pending]: (state, action) => {
      state.status = 'marketing sms send loading'
    },




    [chargeDefaultCard.fulfilled]: (state, action) => {
      state.status = 'charge default succeeded'

    },
    [chargeDefaultCard.rejected]: (state, action) => {
      state.chargeCardMessage = 'Card is not in the system. Please add card.';
      state.status = 'charge default failed'
    },
    [chargeDefaultCard.pending]: (state, action) => {
      state.status = 'charge default loading'
    },
    [listCards.fulfilled]: (state, action) => {
      state.cards = action.payload;
      state.status = 'list cards succeeded'

    },
    [listCards.rejected]: (state, action) => {
      state.status = 'list cards failed'
    },
    [listCards.pending]: (state, action) => {
      state.cards = action.payload;
      state.status = 'list cards loading'
    },
    [setDefaultCard.fulfilled]: (state, action) => {
      state.status = 'set default card succeeded'

    },
    [setDefaultCard.rejected]: (state, action) => {
      state.status = 'set default card failed'
    },
    [setDefaultCard.pending]: (state, action) => {
      state.status = 'set default card loading'
    },

    [deleteCard.fulfilled]: (state, action) => {
      const index = state.cards.findIndex(card => card.id === action.payload.id);
      if (index != -1) {
        state.cards.splice(index, 1);
        state.status = 'delete card succeeded'
      }
      state.status = 'delete card failed'

    },
    [deleteCard.rejected]: (state, action) => {
      state.status = 'delete card failed'
    },
    [deleteCard.pending]: (state, action) => {
      state.status = 'delete card loading'
    },

    [addCard.fulfilled]: (state, action) => {

      state.status = 'adding card succeeded'

    },
    [addCard.rejected]: (state, action) => {
      state.status = 'adding card failed'
    },
    [addCard.pending]: (state, action) => {
      state.status = 'adding card loading'
    },
    [getSmsRecords.fulfilled]: (state, action) => {
      state.smsRecords = action.payload;
      state.status = 'sms records succeeded'

    },
    [getSmsRecords.rejected]: (state, action) => {
      state.status = 'sms records failed'
    },
    [getSmsRecords.pending]: (state, action) => {
      state.status = 'sms records loading'
    },

    [getAllShops.fulfilled]: (state, action) => {
      state.shops = action.payload;
      state.status = 'all shops loaded'

    },
    [getAllShops.rejected]: (state, action) => {
      state.status = 'all shops loading failed'
    },
    [getAllShops.pending]: (state, action) => {
      state.status = 'all shops loading'
    },

  }
});

export const { clearAll, setToken, setStatus,setSendSmsMessage } = authSlice.actions;

export default authSlice.reducer;

export const selectRefreshToken = state => state.auth.refreshToken;
export const selectStatus = state => state.auth.status;
export const selectChargeCustomers = state => state.auth.chargeCustomers;
export const selectAccessToken = state => state.auth.accessToken;
export const selectRefreshExpired = state => state.auth.refreshTokenExpired;
export const selectAccessTokenExpired = state => state.auth.accessTokenExpired;
export const isAuthenticated = state => {
  if (!state.auth.accessTokenExpired && !state.auth.refreshExpired) {
    return true;
  } else {
    return false;
  }
}
