import React, { useEffect, useState, useRef } from 'react';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CheckOut from './CheckOut';
import { updateOrderStatus, fetchOpenOrders } from '../order/orderSlice';
import { addCartItem, deleteAllCart } from '../cart/cartSlice';
import {setModalOpen, setItem, setIsUpdateModal, setOrderId} from '../itemModal/itemModalSlice';
import { setSelfTriggered, setDialogOpen, fetchTable, setTableInstance, setDialogOrder, setDialogReceived, clearTableInstance, fetchTables, deleteTable, getTableInstances, deactivateInstance, updateTableNumber, updateTableAvail, updateTableHold, postTableInstance } from '../table/tableSlice';
import { useSelector, useDispatch } from 'react-redux';
import currency from 'currency.js'
import { io } from "socket.io-client";
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { setOrderModalOpen } from '../modal/modalSlice';
import ListItemText from '@material-ui/core/ListItemText';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import useCheckPaymentReceived from '../../hooks/useCheckPaymentReceived'
import CURRENT_SCREEN from './CurrentScreenEnums';

const DEFAULT_SOCKETS_URL = 'https://sockets.bigkiosksolution.com';
//const DEFAULT_SOCKETS_URL = 'http://127.0.0.1:5000';

const calculateOrderNumber = (orderNumber, today, switchDate) => {
  if (today >= switchDate) {
    return (orderNumber % 999) + 1;
  } else {
    return (orderNumber >= 999 ? (orderNumber % 999) - 1 : orderNumber % 999);
  }
};

const Order = (props) => {
  const socketRef = useRef();

  const today = new Date();
  const switchDate = new Date(Date.UTC(2023, 8, 4, 11, 0));

  const dispatch = useDispatch()
  const openOrders = useSelector(state => state.order.openOrders)
  const status = useSelector(state => state.table.status)
  const orderStatus = useSelector(state => state.order.status)
  const { received } = useCheckPaymentReceived(props.order);

  const [selectedOrder, setSelectedOrder] = useState(null);

  const handleUpdate = () => {
    if (socketRef.current == null) {
      socketRef.current = io(DEFAULT_SOCKETS_URL, {
        transports: ["websocket", "polling"] // use WebSocket first, if available
      });
    }

    const {current: socket} = socketRef;

    try {
      socket.emit('ticket-update-from-dashboard',  {id: props.order?.id, shop_id: localStorage.getItem('shop_id')});
      console.log('ticket-update-from-dashboard ' + localStorage.getItem('shop_id'));
      socket.emit('table-update-from-kiosk', {id: localStorage.getItem('shop_id')});
      
    } catch (error) {
      console.log(error);
    }
  }

  const buildAddedItems = (order) => {
    /*

    */

    let addedItem = [];

    order.order_item_options?.map(option => {
      addedItem.push(option)
    })
    return addedItem;
  }

    function handleUpdateOrder() {
      dispatch(setIsUpdateModal(true))
      dispatch(deleteAllCart())
      let cart;
      let items = []
      let itemObj = {cartItem: {quantity: 1, order_item_id: null, name: null, unit_price: 0}, modalItemData: {item: null, priceSummary: {amount: 0, discount: 0, qty: 1}}}
      props.order.order_items.map(item => {

        const addedItem = buildAddedItems(item)
        let itemCopy = JSON.parse(JSON.stringify(item.item));
        addedItem.map(_item => {
          if (!itemCopy.hasOwnProperty('groups')) {
            itemCopy.groups = []
          }

          const index = itemCopy.groups?.findIndex(group => group.option.id === _item.option_id)
          if (index !== -1) {
            if (itemCopy.hasOwnProperty('groups') || !itemCopy.groups[index].addedItem) {
              itemCopy.groups[index].addedItem = []
            }
            itemCopy.groups[index].addedItem.push(_item)
          }
        })

        itemObj = {cartItem: {quantity: 1, name: null, unit_price: 0}, modalItemData: {item: null, priceSummary: {amount: 0, discount: 0, qty: 1}}}
        itemObj.cartItem.quantity = item.quantity;
        itemObj.cartItem.order_item_id = item.id;
        itemObj.cartItem.name = item.item.name;
        itemObj.cartItem.unit_price = item.unit_price;
        itemObj.modalItemData.item = itemCopy;
        itemObj.modalItemData.priceSummary = {amount: 0, discount: 0, qty: 1};
        dispatch(addCartItem({
          cartItem: itemObj.cartItem,
          modalItemData: itemObj.modalItemData
        }));

      })
      dispatch(setOrderModalOpen(true))
      // update order status without noAdd: true updates and adds another ticket in KDS
      dispatch(updateOrderStatus({id: props.order.id, status: 'processing', noAdd: true}))
    }


  useEffect(() => {
    dispatch(fetchOpenOrders())
  }, [])

  useEffect(() => {
    if (orderStatus === 'order updated') {
      dispatch(fetchTable({pk: props.order?.table_instance?.table?.id}));
      handleUpdate();
    } else if (status === 'order status updated no add') {
      dispatch(fetchTable({pk: props.order?.table_instance?.table?.id}));
      handleUpdate()
    }
  }, [orderStatus])

  useEffect(() => {
    if (props.order) {
      dispatch(setOrderId(props.order.id))
    }
  }, [props.order])

  return props.order ? (
        <div>
        <h2 style={{padding: 0, margin: 0}}>

              <IconButton onClick={() => {props.onTypeUpdate(CURRENT_SCREEN.HOME)}}><KeyboardBackspaceIcon /></IconButton>
              Order #{calculateOrderNumber(props.order.order_number, today, switchDate)}
              <Button style={{marginLeft: 10,position: 'relative', top: 0, fontSize: 11}} size="small" variant="contained" disableElevation onClick={handleUpdateOrder}>Update</Button>

        </h2>
        <div style={{paddingLeft: 20, paddingRight: 20}}>

          <Divider style={{marginBottom: 15}} />
          {props.order.order_items.map(item =>
            <ListItem disableGutters style={{display: 'block'}} key={item.id}>
              {item.item ? <b><Chip size="small" color="primary" style={{position: 'relative', borderRadius:2, bottom: 0, marginRight: 5, fontSize: 11, minWidth: 20, maxHeight: 20}} label={item.quantity} />{item.done ? <strike>{item.item.name}</strike> : item.item.name}</b> : <b  style={{marign:0, padding:0}}>-</b>}
              <List style={{marginTop: 0}} dense  component="span">
                <p style={{margin: 0, padding: 3}}></p>
                {item.order_item_options.map(option => <ListItem style={{display: 'block'}} button key={option.id}>{option.option_str}</ListItem>)}
              </List>
            </ListItem>)}
            {!received ? <Button disabled={status === 'table instance loading' ? true : false} style={{marginTop: 20, marginBottom: 20}} onClick={() => {dispatch(clearTableInstance({orderId: props.order.id, instanceId: props.instanceId}))}} fullWidth variant="contained" color="secondary">Clear Order</Button>: null}
        </div>
        </div>

  ) : (
        <div>
        <h2 style={{padding: 0, margin: 0}}>
          <IconButton onClick={() => { props.onTypeUpdate(CURRENT_SCREEN.HOME)}}><KeyboardBackspaceIcon /></IconButton>
          Order
        </h2>
        <div style={{paddingLeft: 20, paddingRight: 20}}>
        <small style={{margin:0, padding: 0}}>Assign an order to the table</small>
        <hr />
        <FormControl style={{marginTop: 10}} size="small" fullWidth variant="outlined">
        <Select
          disabled={orderStatus === 'fetching open orders'}
          native
          value={selectedOrder}
          onChange={(e) => setSelectedOrder(e.target.value)}
          inputProps={{
            name: 'order',
            id: 'outlined-age-native-simple',
          }}
        >
          <option aria-label="None" value="None">{orderStatus === 'fetching open orders' ? 'Retrieving Orders...' : '-- Assign an Order --'}</option>
          {openOrders?.map(order => <option key={order.id} value={order.id}>Order # {calculateOrderNumber(order.order_number, today, switchDate)}</option>)}
        </Select>
        <hr />
        {selectedOrder ? (
          <Button disabled={status === 'table instance loading'} onClick={() => dispatch(setTableInstance({orderId: selectedOrder, instanceId: props.instanceId}))} fullWidth variant="contained" color="primary"  style={{marginBottom: 20}}>Assign Order</Button>
        ) : (
          <Button disabled fullWidth variant="contained" color="primary" style={{marginBottom: 20}} onClick>Assign Order</Button>
        )}
      </FormControl>
      </div>

        </div>
  )

}

export default Order;
