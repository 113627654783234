import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { fetchItemReport, fetchItemReportCustom, fetchItemsSimplified } from '../item/itemSlice';
import { fetchCategoriesSimplified } from '../category/categorySlice';
import {
  DateTimePicker,
} from '@material-ui/pickers';
import currency from 'currency.js'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));



const ReportHeader = (props) => {
  return (
    <div style={{width: '100%', margin: 0, padding: 0,}}>
    <Paper variant="outlined" style={{width: '100%', overflow:'auto',  display: 'flex'}}>
    <Button
      variant="text"
      disableElevation
      style={{ flex: '1 0 auto', color: props.active == 'Today' ? 'black' : 'grey'}}
      onClick={() => props.onDateUpdate('Today')}>
      Today
    </Button>
    <Button
    style={{ flex: '1 0 auto', color: props.active == 'Yesterday' ? 'black' : 'grey'}}
    variant="text"
      onClick={() => props.onDateUpdate('Yesterday')}
      disableElevation>
      Yesterday
    </Button>

    <Button
    style={{ flex: '1 0 auto', color: props.active == 'This Month' ? 'black' : 'grey'}}
    variant="text"
    onClick={() => props.onDateUpdate('This Month')}
      disableElevation>
      This Month
    </Button>

    <Button
    variant="text"
    style={{ flex: '1 0 auto', color: props.active == 'Last Month' ? 'black' : 'grey'}}
    onClick={() => props.onDateUpdate('Last Month')}
      disableElevation>
      Last Month
    </Button>

      <Button
        variant="text"
        onClick={() => props.onDateUpdate('Custom')}
        disableElevation
        style={{  flex: '1 0 auto', color: props.active == 'Custom' ? 'black' : 'grey'}}>
        Custom
      </Button>

      </Paper>
      {props.active === 'Custom' ? (
        <Grid style={{marginTop: 7}} spacing={1} container>
          <Grid item xs>
        <DateTimePicker
          style={{background: 'white'}}
          fullWidth
          inputVariant="outlined"
          name="start_date"
          label="Start Date"
          placeholder="2018/10/30"
          value={props.startDate}
          size="small"
          onChange={date => props.onStartDateUpdate(date)}
          format="yyyy/MM/DD HH:mm"
          showTodayButton
        />
        </Grid>
          <Grid item xs>
            <DateTimePicker
              fullWidth
              style={{background: 'white'}}
              inputVariant="outlined"
              name="end_date"
              label="End Date"
              placeholder="2025/10/30 03:02"
              value={props.endDate}
              size="small"
              onChange={date => props.onEndDateUpdate(date)}
              format="yyyy/MM/DD HH:mm"
              showTodayButton
            />
          </Grid>
        </Grid>
      ) : (
        null
      )}
    </div>

  )
}

const ItemsReport = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    
    const items = useSelector(state => state.item.itemsSimplified)

    useEffect(() => {
      dispatch(fetchItemsSimplified())
    }, [])



    return (
        <div className={classes.root}>
            {items?.map(item => <AccordionItem  key={item.id} {...item} classes={classes} />)}
        </div>
    )
}

function AccordionItem(props) {
    const [active, setActive] = useState('Today')
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch();


    useEffect(() => {
      if (open && active !== 'Custom') {
        dispatch(fetchItemReport({ id: props.id, type: active }));
      } else if (open && active === 'Custom') {
        dispatch(fetchItemReportCustom({ id: props.id, type: active, start_date: startDate.toISOString(), end_date: endDate.toISOString() }));
      }
    }, [active, startDate, endDate, open]);
    
    return (
        <Accordion onChange={(e, v) => {
          setOpen(v);
        }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={props.classes.heading}>{props.name}</Typography>
        </AccordionSummary>
        <AccordionDetails>

          <Grid direction="row" container alignItems="center"  spacing={1} item xs style={{marginTop: 4}}>
            <Grid item xs={12}>
            <ReportHeader  
            active={active}
            onDateUpdate={(date) =>
                setActive(date)
            }
            startDate={startDate}
            endDate={endDate}
            onStartDateUpdate={(date) => setStartDate(date)}
            onEndDateUpdate={(date) => setEndDate(date)} 
          />
          </Grid>
          <Grid item xs={6} md={3}>
            <Card variant="outlined">
              <CardContent>
              <h3>Sales* <small style={{fontSize: 10, fontWeight: 400}}>Subtotal + Tax</small></h3>
              <p style={{fontSize: 20}}>{currency(props.total).format()}</p>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={3}>
            <Card variant="outlined">
              <CardContent>
              <h3>Sold</h3>
              <p style={{fontSize: 20}}>{props.item_count}</p>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            *&nbsp;
            <small>
            Items that have been refunded are still counted in the overall sales figures
            </small>
          </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    )
}

export default ItemsReport;