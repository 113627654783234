import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import modalReducer from '../features/modal/modalSlice'
import categoryReducer from '../features/category/categorySlice'
import cartReducer from '../features/cart/cartSlice'
import drawerReducer from '../features/drawer/drawerSlice'
import headerReducer from '../features/header/headerSlice'
import itemReducer from '../features/item/itemSlice'
import kioskReducer from '../features/kiosk/kioskSlice'
import addOnReducer from '../features/addon/addOnSlice'
import authReducer from '../features/auth/authSlice'
import kitchenReducer from '../features/kitchen/kitchenSlice'
import itemModalReducer from '../features/itemModal/itemModalSlice';
import orderReducer from '../features/order/orderSlice'
import tableReducer from '../features/table/tableSlice'
import couponsReducer from '../features/coupons/couponsSlice'

import paymentProcessingReducer from '../features/paymentProcessing/paymentProcessingSlice'

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  modal: modalReducer,
  cart: cartReducer,
  category: categoryReducer,
  itemModal: itemModalReducer,
  drawer: drawerReducer,
  header: headerReducer,
  item: itemReducer,
  addon: addOnReducer,
  auth: authReducer,
  kitchen: kitchenReducer,
  order: orderReducer,
  kiosk: kioskReducer,
  table: tableReducer,
  coupons: couponsReducer,
  paymentProcessing: paymentProcessingReducer,
});



export default rootReducer
