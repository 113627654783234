import React from 'react';
import axios from 'axios';
import {
  selectRefreshToken, selectAccessToken,
  selectRefreshExpired, selectAccessTokenExpired }
from '../features/auth/authSlice';
import { useHistory } from 'react-router-dom';
//const DEFAULT_API_URL = 'https://api.bigkiosksolution.com';
const DEFAULT_API_URL = 'https://api.bigkiosksolution.com';
const AUTH_LOGIN_URL = 'https://api.bigkiosksolution.com/api/token/';
const AUTH_REFRESH_URL = 'https://api.bigkiosksolution.com/api/token/refresh/';
//const DEFAULT_API_URL = 'http://127.0.0.1:8000';
//const AUTH_LOGIN_URL = 'http://127.0.0.1:8000/api/token/';
//const AUTH_REFRESH_URL = 'http://127.0.0.1:8000/api/token/refresh/';
//const DEFAULT_API_URL = 'https://eeb69e6c6b43.ngrok.io';
//const AUTH_LOGIN_URL = 'https://eeb69e6c6b43.ngrok.io/api/token/';
//const AUTH_REFRESH_URL = 'https://eeb69e6c6b43.ngrok.io/api/token/refresh/';

// interceptors steps in to retry request with a new (rotated) access token
const interceptor = axios.interceptors.response.use(response => {
   return response;
}, error => {
  if (error.response && error.response.status === 401) {
    if (localStorage.getItem('refresh_token')) {
      return axios.post(AUTH_REFRESH_URL,
      {refresh: localStorage.getItem('refresh_token')},
      {headers: {"Content-Type": "application/json"}})
      .then(function (response) {
        localStorage.setItem("access_token",  response.data.access)
        localStorage.setItem("refresh_token",  response.data.refresh)
        let request = error.config;
        const auth = response.data.access;
        request['headers']['Authorization'] = "Bearer " + auth;
        axios.defaults.headers.common["Authorization"] = "Bearer " + auth;
        return axios.request(request);
     });
    } else {
     localStorage.clear();
   }
  }
  return error;
});

export const GET = async (query) => {
    const token = localStorage.getItem('access_token');
    return axios.get(DEFAULT_API_URL + query, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      }}).then((response) => {
        if (response.statusText !== "OK" && response.statusText !== 'Created' || response.status > 399)
          return Promise.reject(response.response);
        else
          return response;
       })
  }


export const DELETE = async (query) => {
  const token = localStorage.getItem('access_token');

  return axios.delete(DEFAULT_API_URL + query, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    }}).then((response) => {
      console.log(response)
      if (response.status != 204 || response.status > 399)
        return Promise.reject(response.response);
      else
        return response;
     });
}

export const POST_FILE = async (query, body) => {
  const token = localStorage.getItem('access_token');

  return axios.post(DEFAULT_API_URL + query, body, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    }});
}

export const POST_PUBLIC = async (query, body) => {
  return axios.post(DEFAULT_API_URL + query, body, {
    headers: {
      "Content-Type": "application/json"
    }}).then((response) => {
      console.log(response.status);
      if (response.statusText !== "OK" && response.statusText !== "Created" || response.status > 399)
        return Promise.reject(response.response);
      else
        return response;
     });
}

export const POST_PUBLIC_FILE = async (query, body) => {
  return axios.post(DEFAULT_API_URL + query, body, {
    headers: {
      "Content-Type": "application/json"
    }}).then((response) => {
      console.log(response.status);
      if (response.statusText !== "OK" && response.statusText !== "Created" || response.status > 399)
        return Promise.reject(response.response);
      else
        return response;
     });
}

export const POST = async (query, body) => {
  const token = localStorage.getItem('access_token');

  return axios.post(DEFAULT_API_URL + query, body, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    }}).then((response) => {
      if (response.statusText !== "OK" && response.statusText !== 'Created' || response.status > 399)
        return Promise.reject(response.response);
      else
        return response;
     })
}

export const POST_SOCKET_IO = async (query, body) => {
  const token = localStorage.getItem('access_token');

  return axios.post(DEFAULT_API_URL + query, body, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    }}).catch(function (error) {
      return axios.post(DEFAULT_API_URL + query, body, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem('access_token')
        }});
    });
}

export const PUT = async (query, body) => {
  const token = localStorage.getItem('access_token');

  return axios.put(DEFAULT_API_URL + query, body, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    }});
}

export const PATCH = async (query, body) => {
  const token = localStorage.getItem('access_token');

  return axios.patch(DEFAULT_API_URL + query, body, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    }});
}

// initial login; obtains an access token
export const AUTH_LOGIN = async (body) => {
  // username and password needed
  return axios.post(AUTH_LOGIN_URL, body, {
    headers: {
     "Content-Type": "application/json"
    }}).then((response) => {
      console.log(response.status)
      if (response.statusText !== "OK" && response.status != 200)
        return Promise.reject(response.response);
      else
        return response;
     })
}

// rotates refresh token and obtains a new access token if expires
export const AUTH_REFRESH = async (body) => {
  return axios.post(AUTH_REFRESH, body, {headers: {"Content-Type": "application/json"}});
}


export const fetchConnectionToken = async () => {
  // Your backend should call /v1/terminal/connection_tokens and return the JSON response from Stripe
  return await POST('/dashboard/connection_token/', {dashboard: true})
    .then(function(response) {
      return response.data;
    })
    .then(function(data) {
      return data.secret;
    })
    .catch(e => {
      if (localStorage.getItem('refresh_token')) {
        fetchConnectionToken();
      }
      //fetchConnectionToken();
    })
  }
