import React, {useState, useEffect} from 'react';
import { Button, Grid } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import Chip from '@material-ui/core/Chip';
import { useSelector, useDispatch } from 'react-redux';
import { postCategoryImagePosition } from '../../features/category/categorySlice';
import Skeleton from '@material-ui/lab/Skeleton';

const Subheader = (props) => {
  return (
    <p>{props.text}</p>
  );
}

const ClickButton = (props) => {
  const dispatch = useDispatch();
  const handleDragEnd = (e) => {
    e.preventDefault();

    setMouseDown(false);
    setDragging(false);
  }

  const [mouseDown, setMouseDown] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [offset, setOffset] = useState({
    x: props.x,
    y: props.y
  });


  const [dragStartPosition, setDragStartPosition] = useState({
    x: 0,
    y: 0
  });
  const [imageDragged, setImageDragged] = useState(false);

  const handleDrag = (e) => {
    if (mouseDown) {

      let pivotX = dragStartPosition.x;
      let pivotY = dragStartPosition.y;

      let newPosX = e.screenX - pivotX;
      let newPosY = e.screenY - pivotY;
      if (Math.abs(newPosX) > 50) {
        newPosX = newPosX < 0 ? -50 : 50
      }

      if (Math.abs(newPosY) > 30) {
        newPosY = newPosY < 0 ? -25 : 25
      }

      setOffset({
        x: newPosX,
        y: newPosY
      });

    }
  }

  useEffect(() => {
    if (Math.abs(props.x) > 0 || Math.abs(props.y) > 0) {
      setImageDragged(true);
    }
  }, [])

  useEffect(() => {
    setOffset({
      x: props.x,
      y: props.y
    })
  }, [props.x, props.y])

  useEffect(() => {
    if (mouseDown) {
      window.addEventListener('mousemove', handleDrag);
    }

    if (!mouseDown && dragging) {
      dispatch(postCategoryImagePosition({id: props.id, categoryId: props.categoryId, x: offset.x, y: offset.y}));
    }
    return () => {
      window.removeEventListener('mousemove', handleDrag);
    };

   }, [mouseDown])

   useEffect(() => {
     if (dragging) {
       window.addEventListener("mouseup", e => handleDragEnd(e));
     }

     return () => {
       window.removeEventListener("mouseup", e => handleDragEnd(e));
     };
  }, [dragging])


  // reference to the click button's image
  const imageRef = React.createRef();

  const handleDragStart = (e) => {
    e.preventDefault();
    setMouseDown(true);
    setImageDragged(true);
    setDragging(true);
    setDragStartPosition({
      x: e.screenX,
      y: e.screenY
    });

  }

  if (props.abc) {
    return (
      <div className="clickButton" style={{marginRight:'20px', marginBottom: '15px'}}>
        <Button className="category-btn-active" id="topping">
        <Grid container
              direction="column"
              justify="center"
              alignItems="center">
          <Grid item style={{width: '100%', marginTop: '-55px', paddingBottom:'5px', background: 'linear-gradient(156deg, rgba(191,226,246,1) 0%, rgba(110,148,170,1) 100%'
}}>
          <img style={{height: '150px'}} src={props.headerImg} />
          </Grid>
          <Grid item style={{width: '100%', backgroundColor:'white', overflow: 'hidden', zIndex:5}}>
          <span>{props.buttonDesc}</span> <HelpIcon color='primary' style={{paddingTop:'5px'}}/>
          <Subheader text="$0.22" />
          </Grid>
        </Grid>
        </Button>
      </div>
      );
  } else if (props.active) {
    return (
      <div className="clickButton">
        <Button className="category-btn-active">
        <Grid container
              direction="column-reverse"
              justify="center"
              alignItems="center">
          {props.buttonDesc}
          <img src={props.headerImg} />
        </Grid>
        </Button>
      </div>
      );
    } else {
    const offsetX = offset.x;
    const offsetY = offset.y;
    return (
      <div style={{cursor: 'grab'}}>
        <Grid container
              direction="column-reverse"
              justify="center"
              alignItems="center"
              >
              {imageDragged
                ?
                <React.Fragment>
                  <small onClick={() => {
                            setImageDragged(false);
                            setOffset({x:0, y:0});
                            dispatch(postCategoryImagePosition({id: props.id, categoryId: props.categoryId, x: 0, y: 0}))
                         }}
                        style={{
                          cursor:'pointer',
                          border: '1px solid grey',
                          borderRadius: 3,
                          padding: 2,
                          paddingTop: 1,
                          paddingBottom: 1,
                          color: 'black',
                          fontWeight: 400,
                          fontSize: 8}}>
                  RESET
                </small>
                <small
                  style={{
                    color: 'grey',
                    fontWeight: 400,
                    fontSize: 9}}>
                  CUSTOM POSITION: (
                    {offsetX}, {offsetY}
                  )</small>
              </React.Fragment>
              :
              null}
          <div className="desc">{props.buttonDesc}</div> 
          {props.headerImg ?
            <img
              ref={imageRef}
              onMouseDown={handleDragStart}
              onMouseEnter={() => props.onMouseEnter()}
              onMouseLeave={() => props.onMouseLeave()}
              draggable="false"
              style={{
                  marginBottom:10,
                  width: '60px',
                  position: 'relative',
                  left: offsetX,
                  top: offsetY }}
              src={props.headerImg} />
              :
              <Skeleton
                style={{
                  marginBottom:10,
                }}
                variant="circle"
                width={60}
                height={60}
                animation="false" />}
        </Grid>
      </div>
      );
    }
}

export default ClickButton;
