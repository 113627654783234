import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Ticket from './Ticket'
import ApprovalTicket from './ApprovalTicket'
import { useDispatch, useSelector } from 'react-redux';
import { io } from "socket.io-client";
import { clearApprovalTicket, addApprovalTicket, fetchOneTicket, getTicketByIdAndAdd, updateOrderStatus, updateOrderItemStatus, patchRemoveItemsFromStation, patchAddItemsToStation, postStation, fetchTickets, fetchTicketById, fetchStations, removeStation, selectAllTickets, selectAllStations, patchBulkAddItemsToStation} from './kitchenSlice';
import {
  getSystemUser
} from '../auth/authSlice'
import {
  fetchItemsAlt, selectAllItems
} from '../item/itemSlice'
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tab from '@material-ui/core/Tab';
import { AppBar } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useHistory } from "react-router-dom";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import RemoveIcon from '@material-ui/icons/Remove';
import FilterListIcon from '@material-ui/icons/FilterList';
import SimpleList  from '../../components/commons/SimpleList';
import Hidden from '@material-ui/core/Hidden';
import useAudio from '../../hooks/useAudio';
import NotifySound from '../../assets/kio-llc-order-notif.mp3';
import ApprovalSound from '../../assets/approval_needed_sound_kio.mp3';
import AttentionTerminal from '../../assets/attention_terminal.gif';
import CloseIcon from '@material-ui/icons/Close';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import LabelIcon from '@material-ui/icons/Label';
import IdentifierDialog from './IdentifierDialog';

const DEFAULT_SOCKETS_URL = 'https://sockets.bigkiosksolution.com';
//const DEFAULT_SOCKETS_URL = 'http://127.0.0.1:5000';

let socket = null;

const useStyles = makeStyles((theme) => ({
  root: {
  },
  skeleton: {
    borderRadius: 4,
    height: 160,
    width: 250,
    marginBottom:10,
    marginRight:10
  }
}));

// serialization for EPSON thermal printer
function serialize(ticket) {
  const today = new Date();
  const switchDate = new Date(Date.UTC(2023, 8, 4, 11, 0));

  let items = [];
  let toGoStr = ticket.to_go ? '--TO GO--' : '--DINE IN--';

  ticket.order_items.map(item => {
    let _item = {
      name: item.item.name + ' ' + toGoStr,
      qty: 1,
      addedItem: [],
    };

    if (ticket.note !== '') {
      _item.qty = ticket.note + '\n\n' + '1'
    }

    let optionsDict = {};

    if (item.order_item_options) {
      item.order_item_options.map(item_option => {
        let [optionCategory, optionValue] = item_option.option_str.split(':').map(s => s.trim());
        if (optionsDict[optionCategory]) {
          optionsDict[optionCategory].push(optionValue);
        } else {
          optionsDict[optionCategory] = [optionValue];
        }
      });

      for (let category in optionsDict) {
        _item.addedItem.push(`${category}: ${optionsDict[category].join(', ')}`);
      }
    }

    for (let i = 0; i < item.quantity; i++) {
      items.push(_item);
    }
  });

  const options = {
    headerSizeDouble: true,
    itemSizeDouble: true,
    optionSizeDouble: false,
    doubleLineSections: true,
    autocutter: true,
    fullCut: false
  };

  

  return {items: items, number: calculateOrderNumber(ticket.order_number, today, switchDate), datetime: ticket.datetime, options: options};
}

const deleteTicketFromLocalStorage = (orderId) => {
  let hiddenTickets = JSON.parse(localStorage.getItem('hiddenTickets')) || [];

  // Find the index of the ticket with the specified orderId
  const indexToDelete = hiddenTickets.findIndex(ticket => ticket.orderId === orderId);

  if (indexToDelete !== -1) {
    // Remove the ticket from the array
    hiddenTickets.splice(indexToDelete, 1);
    localStorage.setItem('hiddenTickets', JSON.stringify(hiddenTickets));
  }
}

const hideTicketInLocalStorage = (orderId, stationId) => {
  let hiddenTickets = JSON.parse(localStorage.getItem('hiddenTickets')) || [];

  // Check if this association of orderId and stationId doesn't already exist
  if (!hiddenTickets.some(ticket => ticket.orderId === orderId && ticket.stationId === stationId)) {
      hiddenTickets.push({orderId, stationId});
      localStorage.setItem('hiddenTickets', JSON.stringify(hiddenTickets));
  }
}

const getHiddenTicketsFromLocalStorage = () => {
    return JSON.parse(localStorage.getItem('hiddenTickets')) || [];
}

const unhideTicketInLocalStorage = (orderId, stationId) => {
    let hiddenTickets = JSON.parse(localStorage.getItem('hiddenTickets')) || [];

    // Filter out the provided order ID and station ID
    hiddenTickets = hiddenTickets.filter(ticket =>
        !(ticket.orderId === orderId && ticket.stationId === stationId)
    );

    localStorage.setItem('hiddenTickets', JSON.stringify(hiddenTickets));
}

const filterByStatus = (tickets, status, currStation, valueStation) => {
  const hiddenTickets = getHiddenTicketsFromLocalStorage();

  // Helper to filter out hidden tickets for a given station
  const filterHiddenTickets = (ticketArray) => {
    return ticketArray.filter(ticket =>
      !hiddenTickets.some(hiddenTicket => hiddenTicket.orderId === ticket.id && hiddenTicket.stationId === currStation.id)
    );
  };

  // Helper to filter only hidden tickets for a given station
  const filterOnlyHiddenTickets = (ticketArray) => {
    return ticketArray.filter(ticket =>
      hiddenTickets.some(hiddenTicket => hiddenTicket.orderId === ticket.id && hiddenTicket.stationId === currStation.id)
    );
  };


  if (tickets && tickets.length > 0 && valueStation == 'all' && status !== 'hidden') {
    let _tickets = tickets.filter(ticket => ticket.status === status)
    return status === 'processing' ? _tickets.reverse() : _tickets
  } else if (tickets && tickets.length > 0 && valueStation !== 'all' && status !== 'hidden') {
    const _tickets = filterHiddenTickets(tickets.filter(ticket => ticket.status === status))
    let filteredTickets = filterTicketsToStation(_tickets, currStation)
    return status === 'processing' ? filteredTickets.reverse() : filteredTickets
  } else if (tickets && tickets.length > 0 && valueStation !== 'all' && status == 'hidden') {
    const _tickets = filterOnlyHiddenTickets(tickets.filter(ticket => ticket.status === 'processing'))
    let filteredTickets = filterTicketsToStation(_tickets, currStation)
    return filteredTickets.reverse()
  }
}

const filterTicketsToStation = (tickets, currStation) => {
  // [1, 2, 3] arr of item IDs in a station
  let _tickets = []
  tickets.forEach(ticket => {
    ticket.order_items?.forEach(item => {
      if (item.item !== null) {
        item.item.stations?.forEach(station => {
          if (station == currStation.id && _tickets.indexOf(ticket) === -1) {
            _tickets.push(ticket)
          }
        })
      }
    })
  })
  return _tickets

}

const calculateOrderNumber = (orderNumber, today, switchDate) => {
  if (today >= switchDate) {
    return (orderNumber % 999) + 1;
  } else {
    return (orderNumber >= 999 ? (orderNumber % 999) - 1 : orderNumber % 999);
  }
};

const KitchenDisplay = (props) => {
  const today = new Date();
  const switchDate = new Date(Date.UTC(2023, 8, 4, 11, 0));

  const [playing, toggle] = useAudio(NotifySound);
  const [playingApprovalSound, toggleApprovalSound] = useAudio(ApprovalSound);

  const socketRef = useRef();

  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedTickets = useSelector(selectAllTickets);
  const selectedStations = useSelector(selectAllStations);
  const selectedItems = useSelector(selectAllItems);
  const status = useSelector(state => state.kitchen.status);
  const user = useSelector(state => state.auth.user);
  const approvalTicket = useSelector(state => state.kitchen.approvalTicket);

  const [isLoading, setIsLoading] = useState(true);
  const [attentionShow, setAttentionShow] = useState(false);
  // limit to 167 chars including
  const [attentionCustomMessage, setAttentionCustomMessage] = useState(null)
  const [isLoading2, setIsLoading2] = useState(true); // for changing stations
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState(0);
  const [valueStation, setValueStation] = React.useState('all');
  const [audioGranted, grantAudio] = useState(false)
  const [tickets, setTickets] = useState([...selectedTickets])
  const [stations, setStations] = useState([...selectedStations])
  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [open3, setOpen3] = useState(false)
  const [identifier, setIdentifier] = useState(localStorage.getItem('identifier') || null);

  

  const [currStation, setCurrStation] = useState({
    items: [{id: 0}]
  })

  useEffect(() => {
    if (audioGranted) {
      toggle()
    }
  }, [audioGranted])

  useEffect(() => {
    if (status === 'fetch one ticket success' || status === 'fetching and adding ticket succeeded') {
      audioGranted && toggle()
    } else if (status === 'fetching item option success') {
      toggleApprovalSound();
    }
  }, [status])

  useEffect(() => {
    if (attentionCustomMessage) {
      setAttentionShow(false)
      setTimeout(() => {
        setAttentionShow(true);
      }, 3000)
    }
  }, [attentionCustomMessage])


  useEffect(() => {
    //alert(3)
    if (status == 'filter items added' || status == 'filter items deleted') {
      setStations([...selectedStations])
      const station = selectedStations.filter(station => station.id === valueStation)
      setCurrStation(station[0]);
    } else if (status == 'fetching and adding ticket succeeded') {
      if (value === 0) {
        setTickets([...filterByStatus(selectedTickets, 'processing', currStation, valueStation)])
      } else if (value === 1) {
        setTickets(filterByStatus(selectedTickets, 'done', currStation, valueStation))
      } else if (value === 2) {
        setTickets([...filterByStatus(selectedTickets, 'cancelled', currStation, valueStation)])
      } else if (value === 3) {
        const tickets = filterByStatus(selectedTickets, 'hidden', currStation, valueStation);
        setTickets(tickets)
      }

    }
  }, [selectedStations, status])

  useEffect(() => {
    if (open2) {
      dispatch((fetchItemsAlt()));
    }
  }, [open2])

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);


  const history = useHistory();

   const handleClose = () => {
     setOpen(false);
   };

   const handleOpen = () => {
     setOpen(true);
   };


   const handleOpenIdentifier = () => {
    setOpen3(true);
  };

  const handleCloseIdentifier = () => {
    const storedIdentifier = localStorage.getItem('identifier') || '';
    dispatch(fetchTickets(storedIdentifier))
    setOpen3(false);
  };

   const handleClose2 = () => {
     setOpen2(false);
     setIsLoading(true)

     if (value === 0) {
       setTickets([...filterByStatus(selectedTickets, 'processing', currStation, valueStation)])
     } else if (value === 1) {
       setTickets(filterByStatus(selectedTickets, 'done', currStation, valueStation))
     } else if (value === 2) {
       setTickets([...filterByStatus(selectedTickets, 'cancelled', currStation, valueStation)])
     } else if (value === 3) {
       const tickets = filterByStatus(selectedTickets, 'hidden', currStation, valueStation);
       setTickets(tickets)
     }

     setIsLoading(false)
     const identifier = localStorage.getItem('identifier') || null

     dispatch(fetchTickets(identifier))
   };

   const handleOpen2 = () => {
     setOpen2(true);
   };

  const handleChange = (event, newValue) => {
    if (status !== 'loading') {
      setValue(newValue);
    }
  };

  const handlePrint = (ticket) => {
    const shopId = localStorage.getItem('shop_id')

    if (socketRef.current == null) {
       socketRef.current = io(DEFAULT_SOCKETS_URL, {
         transports: ["websocket"] // use WebSocket first, if available
       });
     }

    const {current: socket} = socketRef;

    try {
      socket.emit('print', {id: shopId, ticket: serialize(ticket)});
    } catch (error) {
      console.log(error);
    }
  }

  const handleApproveTicket = (ticket) => {
    const shopId = localStorage.getItem('shop_id')

    if (socketRef.current == null) {
       socketRef.current = io(DEFAULT_SOCKETS_URL, {
         transports: ["websocket"] // use WebSocket first, if available
       });
     }

    const {current: socket} = socketRef;

    try {
      const identifier = localStorage.getItem('identifier') || null

      socket.emit('approve-request', {id: shopId, identifier: identifier});
    } catch (error) {
      console.log(error);
    }
  }

  const handleDisapproveTicket = (ticket) => {
    const shopId = localStorage.getItem('shop_id')

    if (socketRef.current == null) {
       socketRef.current = io(DEFAULT_SOCKETS_URL, {
         transports: ["websocket"] // use WebSocket first, if available
       });
     }

    const {current: socket} = socketRef;

    try {
      const identifier = localStorage.getItem('identifier') || null
      socket.emit('disapprove-request', {id: shopId, identifier: identifier});
    } catch (error) {
      console.log(error);
    }
  }

  const handleStationUpdate = () => {
    const shopId = localStorage.getItem('shop_id')

    if (socketRef.current == null) {
       socketRef.current = io(DEFAULT_SOCKETS_URL, {
         transports: ["websocket"] // use WebSocket first, if available
       });
     }

    const {current: socket} = socketRef;

    try {
      const identifier = localStorage.getItem('identifier') || null

      socket.emit('station-update-from-kds', {id: shopId, identifier: identifier});
    } catch (error) {
      console.log(error);
    }
  }

  const handleOrderItemUpdate = (payload) => {
    const shopId = localStorage.getItem('shop_id')

    if (socketRef.current == null) {
       socketRef.current = io(DEFAULT_SOCKETS_URL, {
         transports: ["websocket"] // use WebSocket first, if available
       });
     }

    //{'order_item_id': item.id, 'status': !item.done}
    const {current: socket} = socketRef;

    try {
      const identifier = localStorage.getItem('identifier') || null

      socket.emit('ticket-order-items-update-from-kds',
      {id: shopId, order_item_id: payload.order_item_id, identifier: identifier, order_id: payload.order_id, status: payload.status});
    } catch (error) {
      console.log(error);
    }
  }

  const handleTicketHiddenUpdate = (payload) => {
    const shopId = localStorage.getItem('shop_id')

    if (socketRef.current == null) {
       socketRef.current = io(DEFAULT_SOCKETS_URL, {
         transports: ["websocket"] // use WebSocket first, if available
       });
     }

    //{'order_id': item.id, 'status': !item.done}
    const {current: socket} = socketRef;

    try {
      const identifier = localStorage.getItem('identifier') || null

      socket.emit('ticket-order-hidden-update-from-kds',
      {id: shopId, station_id: currStation.id, identifier: identifier, order_id: payload.order_id, status: payload.status});
    } catch (error) {
      console.log(error);
    }
  }

  const handleTicketUpdate = (payload) => {
    const shopId = localStorage.getItem('shop_id')

    if (socketRef.current == null) {
       socketRef.current = io(DEFAULT_SOCKETS_URL, {
         transports: ["websocket"] // use WebSocket first, if available
       });
     }

    //{'order_id': item.id, 'status': !item.done}

    const {current: socket} = socketRef;

    try {
      const identifier = localStorage.getItem('identifier') || null

      socket.emit('ticket-order-status-update-from-kds',
      {id: shopId, order_id: payload.order_id, identifier: identifier, status: payload.status});
    } catch (error) {
      console.log(error);
    }
  }

  const handleItemAdd = (list, id) => {
    dispatch(patchAddItemsToStation({existingItems: list, stationId: currStation.id, itemId: id}))
    handleStationUpdate();
  }

  useEffect(() => {
    setIsLoading(true)

    if (value === 0) {
      setTickets(filterByStatus(selectedTickets, 'processing', currStation, valueStation))
    } else if (value === 1) {
      const tickets = filterByStatus(selectedTickets, 'done', currStation, valueStation)
      setTickets(tickets)
    } else if (value === 2) {
      const tickets = filterByStatus(selectedTickets, 'cancelled', currStation, valueStation);
      setTickets(tickets)
    } else if (value === 3) {
      const tickets = filterByStatus(selectedTickets, 'hidden', currStation, valueStation);
      setTickets(tickets)
    }

    setIsLoading(false)
  }, [selectedTickets, value, localStorage.getItem('hiddenTickets')])

  useEffect(() => {
    setIsLoading2(true)
    setTimeout(() => {
      setIsLoading2(false)
    }, 350)

  }, [valueStation])

  useEffect(() => {
    const station = selectedStations.filter(station => station.id === valueStation)
    if (station.length > 0) {
      setCurrStation(station[0]);
    } else {
      setCurrStation({
        items: []
      });
    }
    setValue(0)

  }, [valueStation])


  useEffect(() => {

      if (!user) {
        dispatch(getSystemUser());
      }

      setIsLoading(true);
      dispatch(fetchStations());
      const shopId = localStorage.getItem('shop_id')

      if (socketRef.current == null) {
         socketRef.current = io(DEFAULT_SOCKETS_URL, {
           transports: ["websocket"] // use WebSocket first, if available
         });
       }

      const {current: socket} = socketRef;

      try {
        socket.on('connect', function(data) {
          socket.sendBuffer = [];
          // join a shop's unique room, defined by shop_id, for handling kds
          console.log('connected')
          socket.emit('join', {id: shopId});
          console.log('joined ' + shopId)
          dispatch(fetchTickets(identifier));
          socket.emit('ping-to-kiosk-check-approval', {id: shopId, identifier: identifier});
        });

        socket.on("disconnect", (reason) => {
          if (reason === "io server disconnect") {
            // the disconnection was initiated by the server, you need to reconnect manually
            socket.connect();
          }
          // else the socket will automatically try to reconnect
        });

        socket.on("connect_error", () => {
          // revert to classic upgrade
          socket.connect();
        });

        socket.on("connect_failed", () => {
          // revert to classic upgrade
          socket.connect();
        });

        socket.on('error', function(){
          socket.connect();
        });

        socket.on('reconnect', () => {
          socket.sendBuffer = [];

          console.log('reconnected')

          socket.emit('join', {id: shopId});
          console.log('rejoined')
          dispatch(fetchTickets(identifier));
          socket.emit('ping-to-kiosk-check-approval', {id: shopId, identifier: identifier});


        })

        socket.on('new-order', payload => {
          console.log('new-order')
          // payload.data = {id: order_id, identifier: identifier}
          try {
            const payloadIdentifier = payload.data?.identifier; // Safely access identifier
            console.log('payloadIdentifier: ' + payloadIdentifier)
            console.log('identifier: ' + identifier)

            if (!identifier) {
              console.log('new order');
              dispatch(fetchOneTicket(payload.data));
              setIsLoading(false);
            }

            if (payloadIdentifier != identifier) {
              console.log('identifier mismatch')
              return
            }

            if (payloadIdentifier == null || payloadIdentifier === identifier) {
              console.log('new order');
              dispatch(fetchOneTicket(payload.data));
              setIsLoading(false);
            }
          } catch (error) {
            console.error('Error:', error);
          }
        });

        // updated from kiosk
        socket.on('updated', payload => {
          // payload.data = {identifier: identifier}
          try {
            const payloadIdentifier = payload.data?.identifier; // Safely access identifier
            if (!identifier) {
              dispatch(fetchTickets(identifier));
              setIsLoading(false);
            }

            if (payloadIdentifier != identifier) {
              console.log('identifier mismatch')
              return
            }
            // Check if identifier is null/undefined or matches the local identifier
            if (payloadIdentifier == null || payloadIdentifier === identifier) {
              dispatch(fetchTickets(identifier));
              setIsLoading(false);
            }
          } catch (error) {
            console.error('Error:', error);
          }
        });

        socket.on('updated-ticket', payload => {
          // payload.data = {order_id: int, identifier: str}
          try {
            const payloadIdentifier = payload.data?.identifier; // Safely access identifier
            if (!identifier) {
              console.log('updated');
              dispatch(fetchTicketById({id: payload.data.order_id}));
            }

            if (payloadIdentifier != identifier) {
              console.log('identifier mismatch')
              return
            }

            if (payloadIdentifier == null || payloadIdentifier === identifier) {
              console.log('updated');
              dispatch(fetchTicketById({id: payload.data.order_id}));
            }
          } catch (error) {
            console.error('Error:', error);
          }
        });

        socket.on('added-ticket', payload => {
          // payload.data = {order_id: int, identifier: str}
          try {
            const payloadIdentifier = payload.data?.identifier; // Safely access identifier
            if (!identifier) {
              dispatch(getTicketByIdAndAdd({id: payload.data.order_id}));
            }

            if (payloadIdentifier != identifier) {
              console.log('identifier mismatch')
              return
            }

            if (payloadIdentifier == null || payloadIdentifier === identifier) {
              dispatch(getTicketByIdAndAdd({id: payload.data.order_id}));
            }
          } catch (error) {
            console.error('Error:', error);
          }
        });

        socket.on('updated-ticket-order-items', payload => {
          // payload.data = {order_item_id: int, order_id: int, status: bool, identifier: str}
          try {
            const payloadIdentifier = payload.data?.identifier; // Safely access identifier
            if (!identifier) {
              dispatch(updateOrderItemStatus(payload.data));
            
            }

            if (payloadIdentifier != identifier) {
              console.log('identifier mismatch')
              return
            }

            if (payloadIdentifier == null || payloadIdentifier === identifier) {
              dispatch(updateOrderItemStatus(payload.data));
            }
          } catch (error) {
            console.error('Error:', error);
          }
        });

        socket.on('updated-ticket-order-status', payload => {
          // payload.data = {order_id: int, status: bool, identifier: str}
          try {
            const payloadIdentifier = payload.data?.identifier; // Safely access identifier
            if (!identifier) {
              const orderId = payload.data.order_id;
              deleteTicketFromLocalStorage(orderId);
              dispatch(updateOrderStatus(payload.data));            
            }

            if (payloadIdentifier != identifier) {
              console.log('identifier mismatch')
              return
            }

            if (payloadIdentifier == null || payloadIdentifier === identifier) {
              const orderId = payload.data.order_id;
              deleteTicketFromLocalStorage(orderId);
              dispatch(updateOrderStatus(payload.data));
            }
          } catch (error) {
            console.error('Error:', error);
          }
        });

        socket.on('updated-ticket-order-hidden', payload => {
          // payload.data = {order_id: int, station_id: int, status: bool, identifier: str}
          try {
            const payloadIdentifier = payload.data?.identifier; // Safely access identifier
            if (!identifier) {
              if (payload.data.status) {
                hideTicketInLocalStorage(payload.data.order_id, payload.data.station_id);
              } else {
                unhideTicketInLocalStorage(payload.data.order_id, payload.data.station_id);
              }
            }

            if (payloadIdentifier != identifier) {
              console.log('identifier mismatch')
              return
            }

            // Check if identifier is null/undefined or matches the local identifier
            if (payloadIdentifier == null || payloadIdentifier === identifier) {
              if (payload.data.status) {
                hideTicketInLocalStorage(payload.data.order_id, payload.data.station_id);
              } else {
                unhideTicketInLocalStorage(payload.data.order_id, payload.data.station_id);
              }
            }
          } catch (error) {
            console.error('Error:', error);
          }
        });

        socket.on('updated-kitchen-station', payload => {
          // payload.data = {status: str, identifier: str}
          dispatch(fetchStations());
        })

        socket.on('cancel-request', payload => {
          // payload.data = {shop_id: shopId}
          try {
            const payloadIdentifier = payload.data?.identifier; // Safely access identifier
            if (payload.data.shop_id == shopId && !identifier) {
              dispatch(clearApprovalTicket());
            }

            if (payloadIdentifier != identifier) {
              console.log('identifier mismatch')
              return
            }

            // Check if shop_id matches and if identifier is null/undefined or matches the local identifier
            if (payload.data.shop_id == shopId && (payloadIdentifier == null || payloadIdentifier === identifier)) {
              dispatch(clearApprovalTicket());
            }
          } catch (error) {
            console.error('Error:', error);
          }
        });

        socket.on('add-approval-ticket', payload => {
          // payload.data = { id: shop_id, option_item_name: str, identifier: identifier }
          try {
            const payloadIdentifier = payload.data?.identifier; // Safely access identifier
            if (payload.data.shop_id == shopId && !identifier) {
              const name = payload.data.option_item_name;
              dispatch(addApprovalTicket(name));
            }

            if (payloadIdentifier != identifier) {
              console.log('identifier mismatch')
              return
            }

            // Check if shop_id matches and if identifier is null/undefined or matches the local identifier
            if (payload.data.shop_id == shopId && (payloadIdentifier == null || payloadIdentifier === identifier)) {
              const name = payload.data.option_item_name;
              dispatch(addApprovalTicket(name));
            }
          } catch (error) {
            console.error('Error:', error);
          }
        });

        socket.on('attention-message-from-kio', payload => {
          // payload.data = { shop_id: shop_id, message: str }
          try {
            if (payload.data.shop_id == shopId) {
              const message = payload.data.message
              if (message) {
                setAttentionCustomMessage(message)
              } 
              setAttentionShow(true)
            }
          } catch (error) {
            console.error('Error:', error);
          }
        });

        socket.on('pong-from-kiosk-check-approval', payload => {
          // payload.data = { id: shop_id, option_item_name: str }
          try {
            if (payload.data.shop_id == shopId) {
              const name = payload.data.option_item_name;
              dispatch(addApprovalTicket(name))
            }
          } catch (error) {
            console.error('Error:', error);
          }
        });

      } catch (error) {
        console.log(error);
      }

      // Return a callback to be run before unmount-ing.
      return () => {
        dispatch(clearApprovalTicket());
        socket.off('connect')
        socket.off('disconnect')
        socket.off('connect_error')
        socket.off('connect_failed')
        socket.off('error')
        socket.off('reconnect')
        socket.off('new-order')
        socket.off('updated')
        socket.off('updated-ticket')
        socket.off('added-ticket')
        socket.off('updated-ticket-order-items')
        socket.off('updated-ticket-order-status')
        socket.off('updated-ticket-order-hidden')
        socket.off('updated-kitchen-station')
        socket.off('cancel-request')
        socket.off('add-approval-ticket')
        socket.off('pong-from-kiosk-check-approval')
        socket.off('attention-message-from-kio')
      };
  }, [identifier]);

  const handleSubmit = e => {
    e.preventDefault();

    dispatch(postStation({name: e.target.name.value}))
    handleStationUpdate();
  }


  const handleSubmitIdentifier = e => {
    e.preventDefault();
    const identifier = e.target.name.value;
    localStorage.setItem('identifier', identifier);
  }


  let display = null;

  if (isLoading || isLoading2 === true) {
    display = <Grid
    id="kds-area"
      style={{
        height: '96vh', width: '100vw', display: 'flex',
        flexDirection: 'column', justifyContent: 'start',
        alignContent: 'flex-start', flexWrap: 'wrap',
        overflowY: 'scroll', overflowX: 'scroll',
        padding: 20, paddingTop: 70,
      }}>
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
    </Grid>
  } else if (tickets && isLoading === false) {
    display = <Grid
    id="kds-area"
      style={{
        height: '96vh', width: '100vw', display: 'flex',
        flexDirection: 'column', justifyContent: 'start',
        alignContent: 'flex-start', flexWrap: 'wrap',
        overflowY: 'scroll', overflowX: 'scroll',
        padding: 20, paddingTop: 70,
      }}>
    {approvalTicket ? <ApprovalTicket onApprove={handleApproveTicket} onDisapprove={handleDisapproveTicket} /> : null}
    {tickets.map((ticket, idx) => {
      const orderNumber = calculateOrderNumber(ticket.order_number, today, switchDate);
      return <Ticket
        onOrderItemUpdate={(payload) => handleOrderItemUpdate(payload)}
        onTicketUpdate={(payload) => handleTicketUpdate(payload)}
        onTicketHiddenUpdate={(payload) => handleTicketHiddenUpdate(payload)}
        key={ticket.id}
        value={value}
        valueStation={valueStation}
        station={currStation}
        onPrint={() => handlePrint(ticket)}
        number={orderNumber}
        ticket={ticket}
        datetime={ticket.datetime}
      />
    })}
    </Grid>
  } else if (tickets && tickets.length === 0 && isLoading) {
    display = <Grid
    id="kds-area"

      style={{
        height: '96vh', width: '100vw', display: 'flex',
        flexDirection: 'column', justifyContent: 'start',
        alignContent: 'flex-start', flexWrap: 'wrap',
        overflowY: 'scroll', overflowX: 'scroll',
        padding: 20, paddingTop: 70,
      }}>
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
    </Grid>
  } else if (tickets && tickets.length > 0 && isLoading) {
    display = <Grid
      id="kds-area"
      style={{
        height: '96vh', width: '100vw', display: 'flex',
        flexDirection: 'column', justifyContent: 'start',
        alignContent: 'flex-start', flexWrap: 'wrap',
        overflowY: 'scroll', overflowX: 'scroll',
        padding: 20, paddingTop: 70,
      }}>
      <Skeleton animation="wave" variant="rect" className={classes.skeleton} />
      {tickets.map((ticket, idx) => {
        const orderNumber = calculateOrderNumber(ticket.order_number, today, switchDate);

        return <Ticket
          onTicketUpdate={(payload) => handleTicketUpdate(payload)}
          onOrderItemUpdate={(payload) => handleOrderItemUpdate(payload)}
          onTicketHiddenUpdate={(payload) => handleTicketHiddenUpdate(payload)}
          key={ticket.id}
          valueStation={valueStation}
          onPrint={() => handlePrint(ticket)}
          number={orderNumber}
          ticket={ticket}
          station={currStation}
          datetime={ticket.datetime}
        />
      })}
    </Grid>
  }

  return (
    <React.Fragment>
    <AppBar>
    <Hidden smDown>
      <Tabs
        centered
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
      >
        <Tab label="PROCESSING" />
        <Tab label="FINISHED" />
        <Tab label="CANCELLED" />
        {valueStation !== 'all' ? (
          <Tab label="CLOSED" />
        ) : (
          null
        )}
        <div style={{paddingTop: 10.5, paddingLeft: 50, paddingRight: 40}} onClick={() => {audioGranted ? grantAudio(false) : grantAudio(true)}}>
          {audioGranted ? <NotificationsActiveIcon /> : <NotificationsOffIcon style={{color: 'red'}}/>}
        </div>
        <Tab onClick={() => {history.goBack()}} label={<ExitToAppIcon />} />
      </Tabs>
    </Hidden>
      <Hidden mdUp>
        <Tabs
          scrollButtons="on"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
        >
          <Tab label="PROCESSING" />
          <Tab label="FINISHED" />
          <Tab label="CANCELLED" />
          {valueStation !== 'all' ? (
            <Tab label="CLOSED" />
          ) : (
            null
          )}
          <div style={{paddingTop: 10.5, paddingLeft: 45, paddingRight: 25}} onClick={() => {audioGranted ? grantAudio(false) : grantAudio(true)}}>
            {audioGranted ? <NotificationsActiveIcon /> : <NotificationsOffIcon style={{color: 'red'}}/>}
          </div>
          <Tab onClick={() => {history.goBack()}} label={<ExitToAppIcon />} />
        </Tabs>
      </Hidden>
    </AppBar>
      {display}
      <BottomNavigation
        value={valueStation}
        showLabels
        onChange={(event, newValue) => {

          if (newValue != 'id' && newValue != 'add' && newValue != 'filter') {
            setValueStation(newValue);
          }
          const identifier = localStorage.getItem('identifier') || null

          dispatch(fetchTickets(identifier))
        }}
        style={{
          overflowX: "none",
          overflowY: "hidden",
          position: "fixed",
          bottom: 0,
          width: "100%",
          borderTop: '1px solid #c3c3c3',
          scrollbarWidth: "none", // For Firefox
           msOverflowStyle: "none", // For Internet Explorer and Edge
           "&::-webkit-scrollbar": {
             display: "none" // For Chrome, Safari and Opera
           }
        }}>
        <BottomNavigationAction
          onClick={handleOpenIdentifier}
          value='id'
          icon={<><b>{localStorage.getItem('identifier')}</b><LabelIcon /></>}
        />
        <BottomNavigationAction
          onClick={handleOpen}
          value='add'
          icon={<AddIcon />}
        />
        {valueStation != 'all' ?
        <BottomNavigationAction
          onClick={handleOpen2}
          value='filter'
          icon={<FilterListIcon />}
          /> : null}
        <BottomNavigationAction
          label="All"
          value="all"
        />
        {selectedStations.map((station, idx) =>
          <BottomNavigationAction
            onClick={() => {
              setTickets([])
            }}
            key={idx}
            label={
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center">
                <Grid item>{station.name.charAt(0).toUpperCase() + station.name.slice(1)}</Grid>
                {station.id === valueStation ?
                  <Grid item style={{marginLeft:5}}>
                    <RemoveIcon onClick={handlePopoverClick} style={{color: 'red', position: 'relative', top: 2, left: 20}} />
                    <Popover
                      open={openPopover}
                      anchorEl={anchorEl}
                      onClose={handleClosePopover}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >

                    <div style={{padding: 10}}>
                      <Button onClick={() => {dispatch(removeStation(station.id));handleStationUpdate(); handleClosePopover()}} >Remove Station</Button>
                    </div>
                    </Popover>
                  </Grid>
                  :
                  null}
              </Grid>
            }
            value={station.id}
          />
        )}

      </BottomNavigation>
      <IdentifierDialog onIdentifierUpdate={identifier => setIdentifier(identifier)} onClose={handleCloseIdentifier} open={open3} />
      <Dialog
            onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogContent style={{minWidth: 400}}>
            <form onSubmit={e => handleSubmit(e)}>
            <TextField
              style={{marginBottom: 10}}
               id="filled-password-input"
               label="Enter Station Name"
               type="text"
               fullWidth
               size="small"
               variant="outlined"
               name="name"
             />
            <Button onClick={handleClose} type="submit" style={{marginBottom: 10}} fullWidth variant="contained" color="primary" disableElevation>
              Add
            </Button>
            </form>
            </DialogContent>
      </Dialog>
      <Dialog onClose={handleClose2} aria-labelledby="simple-dialog-title" open={open2}>
        <div style={{width: 500, overflow: 'hidden'}}>
          <SimpleList
            title={'Items to Show'}
            name="items"
            unselectedItems={selectedItems}
            onItemRemove={
              (selectedItems, existingItems) => dispatch(patchRemoveItemsFromStation({existingItems: existingItems, removedItems: selectedItems, stationId: currStation.id}))}
            onOrderUpdate={
              (list) => dispatch(fetchStations())
            }
            onItemAdd={
              (list, id) => handleItemAdd(list, id)
            }

            onItemBulkAdd={(items, ids) => dispatch(patchBulkAddItemsToStation({ existingItems: items,
                                                                                items: ids,
                                                                                stationId: currStation.id
                                                                                }))}
            type="filters"
            items={currStation.items}
          />
        </div>
      </Dialog>
      <Dialog aria-labelledby="attention-dialog-title" open={attentionShow}>
        <DialogContent style={{ position: 'relative', padding: 0, margin: 0, textAlign: 'left' }}>
          <img src={AttentionTerminal} alt="Attention Required" style={{ width: '100%', objectFit: 'contain', display: 'block' }} />
          <IconButton 
            onClick={() => {setAttentionShow(false); setAttentionCustomMessage(null)}} 
            style={{
              backgroundColor: 'red',
              position: 'absolute',
              top: '10px',
              right: '10px',
              color: 'white',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
            }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
          <Typography 
            variant="h6" 
            style={{
              position: 'absolute',
              top: '80%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: 'white',
              backgroundColor: 'rgba(255, 165, 0, 0.7)', // Orange with 0.7 opacity
              padding: '10px',
              paddingLeft: '15px',
              borderRadius: 10,
              minWidth: '83%',
              alignContent: 'center',
              alignItems: 'center',
              fontSize: 25,
              fontFamily: 'Poppins, sans-serif',

            }}
          >
            {attentionCustomMessage ? (
              attentionCustomMessage
            ) : (
              'Please check the card reader NOW and follow the on-screen instructions for any pending updates. DO NOT postpone or cancel the update.'
            )}
          </Typography>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}

export default KitchenDisplay;
