import React, {useEffect} from 'react';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListSubheader  from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle, selectTitle } from '../../features/header/headerSlice';
import { makeStyles } from '@material-ui/core/styles';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import ViewModuleOutlinedIcon from '@material-ui/icons/ViewModuleOutlined';
import QueueIcon from '@material-ui/icons/Queue';
import OrangeLogo from '../../assets/kio-orange.png';
import KioLogoText from '../../assets/kio-logo-text.png';
import DashboardIcon from '@material-ui/icons/Dashboard';
import RedeemIcon from '@material-ui/icons/Redeem';
import {
  Link
} from "react-router-dom";

const Sidebar = (props) => {
  const user = useSelector(state => state.auth.user);
  const status = useSelector(state => state.auth.status);


  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: props.drawerWidth,
        flexShrink: 0,
      },
    },
    navbarIcon: {
      minWidth: '35px',
      color: '#191e23',
      marginRight: 8
    },
    listItem: {
      fontFamily: "'Roboto Condensed', sans-serif",
      marginBottom: 0,
      height: 35,
      width: 215,
      borderRadius: 5,
      "&:hover": {
        color: '#545454',
      },
      marginTop: 1,
      marginBottom: 1,
      paddingLeft: 9,
    },
    listItemSub: {
      marginBottom: 0,
      height: 35,
      width: 215,
      borderRadius: 2,
      "&:hover": {
        color: '#545454',
      },
      paddingLeft: 9,
    },
    active: {
      backgroundColor: '#4d5d5f',
      color: 'white',
      marginTop: 1,
      marginBottom: 1,
      "&:hover": {
        color: 'white',
        backgroundColor: '#4d5d5e',

      },
    },
    typographyActive: {

    },
    listSubHeaderRoot: {
      height: 38,
      marginBottom: 0,
      paddingLeft: 9,
      fontSize: 12,
      color: '#6c6c6c',
      letterSpacing: '.04em',
      fontWeight: 450,
      /* To change the font, use the fontFamily rule */
    },
    drawerPaper: {
      border: 0,
      borderRight: '1px solid #e3e9ec',
      color: '#545454',
      backgroundColor: 'white',
      width: props.drawerWidth,
      borderBottomRightRadius: 60,
      paddingBottom: 50
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
    pos: {
      marginBottom: 7,
      fontSize: '1em',
      fontWeight: 600
    },
    list: {
        marginLeft: 16,
        marginTop: -15
    },
    link: {
      textDecoration: 'none', /* no underline */
      color: 'inherit'
    },

  }));
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [menu, setMenu] = React.useState({
    kiosk: {
      selected: false
    },
    category: {
      selected: true
    },
    item: {
      selcted: false
    },
    addon: {
      selcted: false
    },
    sales: {
      selected: false
    },
    salesReport: {
      selected: false
    },
    settings: {
      selected: false
    },
    tableBuilder: {
      selected: false
    },
    tableManager: {
      selected: false
    },
    payout: {
      selected: false
    },
    rewards: {
      selected: false
    },
    coupons: {
      selected: false
    },
  });

  const dispatch = useDispatch();

  const title = useSelector(selectTitle);
  const activeMenu = (type) => {
    if (type === 'category') {
      dispatch(setTitle('Category'));

      setMenu(prevState => ({
        kiosk: {
          selected: false
        },
         category: {
           selected: true
         },
         item: {
           selected: false
         },
         addon: {
           selected: false
         },
         sales: {
           selected: false
         },
         salesReport: {
           selected: false
         },
         settings: {
           selected: false
         },
         tableBuilder: {
           selected: false
         },
         tableManager: {
           selected: false
         },
         payout: {
           selected: false
         },
         rewards: {
           selected: false
         },
         coupons: {
           selected: false
         },
       }));
    } else if (type === 'item') {
      dispatch(setTitle('Item'));

      setMenu(prevState => ({
        kiosk: {
          selected: false
        },
         category: {
           selected: false
         },
         item: {
           selected: true
         },
         addon: {
           selected: false
         },
         sales: {
           selected: false
         },
         salesReport: {
           selected: false
         },
         settings: {
           selected: false
         },
         tableBuilder: {
           selected: false
         },
         tableManager: {
           selected: false
         },
         payout: {
           selected: false
         },
         rewards: {
           selected: false
         },
         coupons: {
           selected: false
         },
       }));
    } else if (type === 'addon') {
      dispatch(setTitle('Add-on'));

      setMenu(prevState => ({
        kiosk: {
          selected: false
        },
         category: {
           selected: false
         },
         item: {
           selected: false
         },
         addon: {
           selected: true
         },
         sales: {
           selected: false
         },
         salesReport: {
           selected: false
         },
         settings: {
           selected: false
         },
         tableBuilder: {
           selected: false
         },
         tableManager: {
           selected: false
         },
         payout: {
           selected: false
         },
         rewards: {
           selected: false
         },
         coupons: {
           selected: false
         },
       }));
    } else if (type === 'sales') {
      dispatch(setTitle('Sales'));

      setMenu(prevState => ({
        kiosk: {
          selected: false
        },
         category: {
           selected: false
         },
         item: {
           selected: false
         },
         addon: {
           selected: false
         },
         sales: {
           selected: true
         },
         salesReport: {
           selected: false
         },
         settings: {
           selected: false
         },
         tableBuilder: {
           selected: false
         },
         tableManager: {
           selected: false
         },
         payout: {
           selected: false
         },
         rewards: {
           selected: false
         },
         coupons: {
           selected: false
         },
       }));
    } else if (type === 'sales report') {
      dispatch(setTitle('Sales Report'));

      setMenu(prevState => ({
        kiosk: {
          selected: false
        },
         category: {
           selected: false
         },
         item: {
           selected: false
         },
         addon: {
           selected: false
         },
         sales: {
           selected: false
         },
         salesReport: {
           selected: true
         },
         settings: {
           selected: false
         },
         tableBuilder: {
           selected: false
         },
         tableManager: {
           selected: false
         },
         payout: {
           selected: false
         },
         rewards: {
           selected: false
         },
         coupons: {
           selected: false
         },
       }));
    } else if (type === 'kiosk') {
      dispatch(setTitle('My Kiosks'));

      setMenu(prevState => ({
        kiosk: {
          selected: true
        },
         category: {
           selected: false
         },
         item: {
           selected: false
         },
         addon: {
           selected: false
         },
         sales: {
           selected: false
         },
         salesReport: {
           selected: false
         },
         settings: {
           selected: false
         },
         tableBuilder: {
           selected: false
         },
         tableManager: {
           selected: false
         },
         payout: {
           selected: false
         },
         rewards: {
           selected: false
         },
         coupons: {
           selected: false
         },
       }));
     } else if (type === 'settings') {
       dispatch(setTitle('Settings'));

       setMenu(prevState => ({
         kiosk: {
           selected: false
         },
          category: {
            selected: false
          },
          item: {
            selected: false
          },
          addon: {
            selected: false
          },
          sales: {
            selected: false
          },
          salesReport: {
            selected: false
          },
          settings: {
            selected: true
          },
          tableBuilder: {
            selected: false
          },
          tableManager: {
            selected: false
          },
          payout: {
            selected: false
          },
          rewards: {
            selected: false
          },
          coupons: {
            selected: false
          },
        }));
      } else if (type === 'tablebuilder') {
        dispatch(setTitle('Table Builder'));

        setMenu(prevState => ({
          kiosk: {
            selected: false
          },
           category: {
             selected: false
           },
           item: {
             selected: false
           },
           addon: {
             selected: false
           },
           sales: {
             selected: false
           },
           salesReport: {
             selected: false
           },
           settings: {
             selected: false
           },
           tableBuilder: {
             selected: true
           },
           tableManager: {
             selected: false
           },
           payout: {
             selected: false
           },
           rewards: {
             selected: false
           },
           coupons: {
             selected: false
           },
         }));
      } else if (type === 'table manager') {
         dispatch(setTitle('Table Manager'));

         setMenu(prevState => ({
           kiosk: {
             selected: false
           },
            category: {
              selected: false
            },
            item: {
              selected: false
            },
            addon: {
              selected: false
            },
            sales: {
              selected: false
            },
            salesReport: {
              selected: false
            },
            settings: {
              selected: false
            },
            tableBuilder: {
              selected: false
            },
            tableManager: {
              selected: true
            },
            payout: {
              selected: false
            },
            rewards: {
              selected: false
            },
            coupons: {
              selected: false
            },
          }));
     } else if (type === 'payout') {
        dispatch(setTitle('Payout'));

        setMenu(prevState => ({
          kiosk: {
            selected: false
          },
           category: {
             selected: false
           },
           item: {
             selected: false
           },
           addon: {
             selected: false
           },
           sales: {
             selected: false
           },
           salesReport: {
             selected: false
           },
           settings: {
             selected: false
           },
           tableBuilder: {
             selected: false
           },
           tableManager: {
             selected: false
           },
           payout: {
             selected: true
           },
           rewards: {
             selected: false
           },
           coupons: {
             selected: false
           },
         }));
     } else if (type === 'rewards') {
        dispatch(setTitle('Rewards'));

        setMenu(prevState => ({
          kiosk: {
            selected: false
          },
           category: {
             selected: false
           },
           item: {
             selected: false
           },
           addon: {
             selected: false
           },
           sales: {
             selected: false
           },
           salesReport: {
             selected: false
           },
           settings: {
             selected: false
           },
           tableBuilder: {
             selected: false
           },
           tableManager: {
             selected: false
           },
           payout: {
             selected: false
           },
           rewards: {
             selected: true
           },
           coupons: {
             selected: false
           },
         }));
     } else if (type === 'coupons') {
      dispatch(setTitle('Coupons'));

      setMenu(prevState => ({
        kiosk: {
          selected: false
        },
         category: {
           selected: false
         },
         item: {
           selected: false
         },
         addon: {
           selected: false
         },
         sales: {
           selected: false
         },
         salesReport: {
           selected: false
         },
         settings: {
           selected: false
         },
         tableBuilder: {
           selected: false
         },
         tableManager: {
           selected: false
         },
         payout: {
           selected: false
         },
         rewards: {
           selected: false
         },
         coupons: {
           selected: true
         },
       }));
   } else {
       setMenu(prevState => ({
         kiosk: {
           selected: true
         },
          category: {
            selected: false
          },
          item: {
            selected: false
          },
          addon: {
            selected: false
          },
          sales: {
            selected: false
          },
          salesReport: {
            selected: false
          },
          settings: {
            selected: false
          },
          tableBuilder: {
            selected: false
          },
          tableManager: {
            selected: false
          },
          rewards: {
            selected: false
          },
          payout: {
            selected: false
          },
          coupons: {
            selected: false
          },
        }));
     }
  }

  const handleDrawerToggle = () => {
    props.handleDrawerToggle();
  };

  const handleClick = () => {
     setOpen(!open);
   };
  const handleChange = () => {};
  const onMenuClick = (type) => {
    activeMenu(type);
  }

  useEffect(() => {
    activeMenu(title.toLowerCase().replace('-', ''));
  }, [title])

  const link = classes.link;
  const listItemActive = [classes.listItem, classes.active];
  const listItem = classes.listItem;

  return (
    <React.Fragment>
      <Hidden mdUp >
        
      <Drawer
        onClose={handleDrawerToggle}
        BackdropProps={{ invisible: true }}
        elevation={6}
        variant="temporary"
        open={props.mobileOpen}
        anchor='left'
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        classes={{
            paper: classes.drawerPaper,
          }}>
          <div className={classes.toolbar} style={{textAlign: 'left', paddingLeft:20, paddingTop: 12, marginTop: 1.8, marginBottom:-2}}>
    <img src={OrangeLogo} style={{position: 'relative', top: 0,width: 22.4}} />

            <img src={KioLogoText} style={{width: 100, marginRight: 2.5, zIndex: 1}}/> 
                </div>
        <Divider light style={{width: '84%', margin: '0 auto', marginBottom: 10}}/>
        <p style={{textAlign: 'center', fontWeight: 500, backgroundImage: 'linear-gradient(#fa9f25, #f97713)',position: 'relative', top: 7, fontSize: 15, color: "white", padding: '7px',  borderRadius: 6, width: '85%', margin: '0 auto'}}>{user ? <span>{user.shop.type !== 'franchiser' ? user.shop.business_name : user.shop.franchise.franchise_name}</span> : 'Loading...'}
        </p>
        <List style={{marginTop: 10}} className={classes.list} subheader={
          <ListSubheader className={classes.listSubHeaderRoot} color="inherit" component="div" id="nested-list-subheader">
            MANAGE KIOSK
          </ListSubheader>
        }>
        <Link onClick={() => onMenuClick('kiosk')} className={link} to="/kiosk">
          <ListItem className={menu.kiosk.selected ? listItemActive : listItem} button onClick={handleClick}>
          <ListItemIcon style={{marginRight: -25}}><InfoOutlinedIcon style={menu.kiosk.selected ? {} : {position: 'relative', top: 1.4}} htmlColor={menu.kiosk.selected ? 'white' : 'inherit'} fontSize="small" /></ListItemIcon>
            <ListItemText primary={<Typography style={menu.kiosk.selected ? { fontWeight: 500, fontSize: 15 } : { fontWeight: 400, fontSize: 15, color: '#909090' }}>Kiosk</Typography>} />
          </ListItem>
        </Link>
        <Link onClick={() => onMenuClick('category')} className={link} to="/category">
        <ListItem className={menu.category.selected ? listItemActive : listItem} button onClick={handleClick}>
            <ListItemIcon style={{marginRight: -26}}><LayersOutlinedIcon style={menu.category.selected ? {} : {position: 'relative', top: 1.4}} htmlColor={menu.category.selected ? 'white' : 'inherit'} fontSize="small" /></ListItemIcon>
                  <ListItemText primary={<Typography style={menu.category.selected ? { fontWeight: 500, fontSize: 15 } : { fontWeight: 400, fontSize: 15, color: '#909090' }}>Category</Typography>} />
            </ListItem>
          </Link>
          <Link onClick={() => onMenuClick('item')}  className={link} to="/item">
          <ListItem className={menu.item.selected ? listItemActive : listItem} button onClick={handleClick}>
              <ListItemIcon style={{marginRight: -25}}><ListAltOutlinedIcon style={menu.item.selected ? {} : {position: 'relative', bottom: 0.2}} htmlColor={menu.item.selected ? 'white' : 'inherit'} fontSize="small" /></ListItemIcon>
                <ListItemText primary={<Typography style={menu.item.selected ? { fontWeight: 500, fontSize: 15 } : { fontWeight: 400, fontSize: 15, color: '#909090' }}>Item</Typography>} />

          </ListItem>
          </Link>
          <Link onClick={() => onMenuClick('addon')}  className={link} to="/addon">
          <ListItem className={menu.addon.selected ? listItemActive : listItem} button onClick={handleClick}>
              <ListItemIcon style={{marginRight: -25}}><QueueIcon style={menu.addon.selected ? {} : {position: 'relative', bottom: 0.2}} htmlColor={menu.addon.selected ? 'white' : 'inherit'} fontSize="small" /></ListItemIcon>
                <ListItemText primary={<Typography style={menu.addon.selected ? { fontWeight: 500, fontSize: 15 } : { fontWeight: 400, fontSize: 15, color: '#909090' }}>Add-on</Typography>} />

          </ListItem>
          </Link>
          <Link onClick={() => onMenuClick('settings')}  className={link} to="/settings">
          <ListItem className={menu.settings.selected ? listItemActive : listItem} button onClick={handleClick}>
              <ListItemIcon style={{marginRight: -25}}><SettingsOutlinedIcon style={menu.settings.selected ? {} : {position: 'relative', bottom: 0.2}} htmlColor={menu.settings.selected ? 'white' : 'inherit'} fontSize="small" /></ListItemIcon>
                <ListItemText primary={<Typography style={menu.settings.selected ? { fontWeight: 500, fontSize: 15 } : { fontWeight: 400, fontSize: 15, color: '#909090' }}>Settings</Typography>} />
          </ListItem>
          </Link>
        </List>
        <List style={{marginTop:-5}} className={classes.list} subheader={
          <ListSubheader className={classes.listSubHeaderRoot} color="inherit" component="div" id="nested-list-subheader">
            SALES & ORDERS
          </ListSubheader>
        }>
                  {user?.shop?.type !== 'franchiser' ? (
            <Link onClick={() => onMenuClick('coupons')} className={link} to="/coupons">
                <ListItem className={menu.coupons.selected ? listItemActive : listItem}  button onClick={handleClick}>
                    <ListItemIcon style={{marginRight: -25}}><RedeemIcon  htmlColor={menu.coupons.selected ? 'white' : 'inherit'} fontSize="small" /></ListItemIcon>
                    <ListItemText primary={<Typography  style={menu.coupons.selected ? { fontWeight: 500, fontSize: 15 } : { fontWeight: 400, fontSize: 15, color: '#909090' }}>Coupons </Typography>} />
                </ListItem>
            </Link>
        ) : (
            <ListItem disabled={true}  className={menu.coupons.selected ? listItemActive : listItem}  button>
                <ListItemIcon style={{marginRight: -25}}><RedeemIcon  htmlColor={menu.coupons.selected ? 'white' : 'inherit'} fontSize="small" /></ListItemIcon>
                <ListItemText primary={<Typography style={menu.coupons.selected ? { fontWeight: 500, fontSize: 15 } : { fontWeight: 400, fontSize: 15, color: '#909090' }}>Coupons </Typography>} />
            </ListItem>
        )}
        {user?.shop?.type !== 'franchiser' ? (
            <Link onClick={() => onMenuClick('rewards')} className={link} to="/rewards">
                <ListItem className={menu.rewards.selected ? listItemActive : listItem}  button onClick={handleClick}>
                    <ListItemIcon style={{marginRight: -25}}><LoyaltyIcon  htmlColor={menu.rewards.selected ? 'white' : 'inherit'} fontSize="small" /></ListItemIcon>
                    <ListItemText primary={<Typography  style={menu.rewards.selected ? { fontWeight: 500, fontSize: 15 } : { fontWeight: 400, fontSize: 15, color: '#909090' }}>Rewards Program </Typography>} />
                    <FiberNewIcon  style={menu.rewards.selected ?  {color: 'white', fontSize: 29} : {color: '#3f51b5', fontSize: 29}} />
                </ListItem>
            </Link>
        ) : (
            <ListItem disabled={true}  className={menu.rewards.selected ? listItemActive : listItem}  button>
                <ListItemIcon style={{marginRight: -25}}><LoyaltyIcon  htmlColor={menu.rewards.selected ? 'white' : 'inherit'} fontSize="small" /></ListItemIcon>
                <ListItemText primary={<Typography style={menu.rewards.selected ? { fontWeight: 500, fontSize: 15 } : { fontWeight: 400, fontSize: 15, color: '#909090' }}>Rewards Program </Typography>} />
                <FiberNewIcon  style={menu.rewards.selected ?  {color: 'white', fontSize: 29} : {color: '#3f51b5', fontSize: 29}}  />
            </ListItem>
        )}
        {user?.shop?.type !== 'franchiser' ? (
            <Link onClick={() => onMenuClick('kitchen')} className={link} to="/kitchen">
                <ListItem className={classes.listItem} button onClick={handleClick}>
                    <ListItemIcon style={{marginRight: -25}}><InfoOutlinedIcon fontSize="small" /></ListItemIcon>
                    <ListItemText primary={<Typography style={{ fontWeight: 400, fontSize: 15, color: '#909090' }}>Kitchen Display</Typography>} />
                </ListItem>
            </Link>
        ) : (
            <ListItem disabled={true} className={classes.listItem} button>
                <ListItemIcon style={{marginRight: -25}}><InfoOutlinedIcon fontSize="small" /></ListItemIcon>
                <ListItemText primary={<Typography style={{ fontWeight: 400, fontSize: 15, color: '#909090' }}>Kitchen Display</Typography>} />
            </ListItem>
        )}
        {user?.shop?.type !== 'franchiser' ? (
            <Link onClick={() => onMenuClick('tablemanager')} className={link} to="/table-manager">
                <ListItem className={menu.tableManager.selected ? listItemActive : listItem} button onClick={handleClick}>
                    <ListItemIcon style={{marginRight: -25}}><ViewModuleOutlinedIcon style={menu.tableManager.selected ? {} : {position: 'relative', bottom: 0.2}} htmlColor={menu.tableManager.selected ? 'white' : 'inherit'} fontSize="small" /></ListItemIcon>
                    <ListItemText primary={<Typography style={menu.tableManager.selected ? { fontWeight: 500, fontSize: 15 } : { fontWeight: 400, fontSize: 15, color: '#909090' }}>Table Manager</Typography>} />
                </ListItem>
            </Link>
        ) : (
            <ListItem className={menu.tableManager.selected ? listItemActive : listItem} button disabled>
                <ListItemIcon style={{marginRight: -25}}><ViewModuleOutlinedIcon style={menu.tableManager.selected ? {} : {position: 'relative', bottom: 0.2}} htmlColor={menu.tableManager.selected ? 'white' : 'inherit'} fontSize="small" /></ListItemIcon>
                <ListItemText primary={<Typography style={menu.tableManager.selected ? { fontWeight: 500, fontSize: 15 } : { fontWeight: 400, fontSize: 15, color: '#909090' }}>Table Manager</Typography>} />
            </ListItem>
        )}
        {user?.shop?.type !== 'franchiser' ? (
            <Link onClick={() => onMenuClick('tablebuilder')} className={link} to="/table">
                <ListItem className={classes.listItem} button onClick={handleClick}>
                    <ListItemIcon style={{marginRight: -25}}><DashboardIcon fontSize="small" /></ListItemIcon>

                    <ListItemText primary={<Typography style={{ fontWeight: 400, fontSize: 15, color: '#909090' }}>Table Canvas</Typography>} />
                </ListItem>
            </Link>
        ) : (
            <ListItem className={classes.listItem} button disabled>
                <ListItemIcon style={{marginRight: -25}}><DashboardIcon fontSize="small" /></ListItemIcon>

                <ListItemText primary={<Typography style={{ fontWeight: 400, fontSize: 15, color: '#909090' }}>Table Canvas</Typography>} />
            </ListItem>
        )}
        {user?.shop?.type !== 'franchiser' ? (
            <Link onClick={() => onMenuClick('sales')} className={link} to="/sales">
                <ListItem className={menu.sales.selected ? listItemActive : listItem} button onClick={handleClick}>
                    <ListItemIcon style={{marginRight: -25}}><ListAltOutlinedIcon style={menu.sales.selected ? {} : {position: 'relative', bottom: 0.2}} htmlColor={menu.sales.selected ? 'white' : 'inherit'} fontSize="small" /></ListItemIcon>
                    <ListItemText primary={<Typography style={menu.sales.selected ? { fontWeight: 500, fontSize: 15 } : { fontWeight: 400, fontSize: 15, color: '#909090' }}>Sales</Typography>} />
                </ListItem>
            </Link>
        ) : (
            <ListItem className={menu.sales.selected ? listItemActive : listItem} button disabled>
                <ListItemIcon style={{marginRight: -25}}><ListAltOutlinedIcon style={menu.sales.selected ? {} : {position: 'relative', bottom: 0.2}} htmlColor={menu.sales.selected ? 'white' : 'inherit'} fontSize="small" /></ListItemIcon>
                <ListItemText primary={<Typography style={menu.sales.selected ? { fontWeight: 500, fontSize: 15 } : { fontWeight: 400, fontSize: 15, color: '#909090' }}>Sales</Typography>} />
            </ListItem>
        )}

          <Link onClick={() => onMenuClick('salesreport')} className={link} to="/sales-report">
          <ListItem className={menu.salesReport.selected ? listItemActive : listItem} button onClick={handleClick}>
                <ListItemIcon style={{marginRight: -25}}><AssessmentOutlinedIcon style={menu.salesReport.selected ? {} : {position: 'relative', bottom: 0.2}} htmlColor={menu.salesReport.selected ? 'white' : 'inherit'} fontSize="small"  /></ListItemIcon>
                <ListItemText primary={<Typography style={menu.salesReport.selected ? { fontWeight: 500, fontSize: 15 } : { fontWeight: 400, fontSize: 15, color: '#909090' }}>Sales Report</Typography>} />
          </ListItem>
          </Link>
              <Link onClick={() => onMenuClick('payout')} className={link} to="/payout">
                  <ListItem className={menu.payout.selected ? listItemActive : listItem} button onClick={handleClick}>
                      <ListItemIcon style={{marginRight: -25}}><AssessmentOutlinedIcon style={menu.payout.selected ? {} : {position: 'relative', bottom: 0.2}} htmlColor={menu.payout.selected ? 'white' : 'inherit'} fontSize="small"  /></ListItemIcon>
                      <ListItemText primary={<Typography style={menu.payout.selected ? { fontWeight: 500, fontSize: 15 } : { fontWeight: 400, fontSize: 15, color: '#909090' }}>Payout</Typography>} />
                  </ListItem>
              </Link>

        </List>
      </Drawer>
      </Hidden>
      <Hidden smDown>
      <Drawer
        open
        anchor='left'
        variant="permanent"
        classes={{
            paper: classes.drawerPaper,
          }}>
        <div className={classes.toolbar} style={{textAlign: 'left', paddingLeft:20, marginTop: 1.8, marginBottom:-2}}>
          <h2 style={{ fontFamily: "Roboto Condensed', sans-serif", padding: 0}}>
          <img src={OrangeLogo} style={{position: 'relative', top: 0,width: 22.4}} />

            <img src={KioLogoText} style={{width: 100, marginRight: 2.5, zIndex: 1}}/> 
            
                      </h2>
        </div>
        <Divider light style={{width: '84%', margin: '0 auto', marginBottom: 10}}/>
        <p style={{textAlign: 'center', fontWeight: 500, backgroundImage: 'linear-gradient(#fa9f25, #f97713)',position: 'relative', top: 7, fontSize: 15, color: "white", padding: "7px", borderRadius: 6, width: '85%', margin: '0 auto'}}>{user ? <span>{user.shop.type !== 'franchiser' ? user.shop.business_name : user.shop.franchise.franchise_name}</span> : 'Loading...'}
        </p>

        <List style={{marginTop: 10}} className={classes.list} subheader={
          <ListSubheader className={classes.listSubHeaderRoot} color="inherit" component="div" id="nested-list-subheader">
            MANAGE KIOSK
          </ListSubheader>
        }>
        <Link onClick={() => onMenuClick('kiosk')} className={link} to="/kiosk">
        <ListItem className={menu.kiosk.selected ? listItemActive : listItem} button onClick={handleClick}>
          <ListItemIcon style={{marginRight: -25}}><InfoOutlinedIcon style={menu.kiosk.selected ? {} : {position: 'relative', top: 0.3}} htmlColor={menu.kiosk.selected ? 'white' : 'inherit'} fontSize="small" /></ListItemIcon>
            <ListItemText primary={<Typography style={menu.kiosk.selected ? { fontWeight: 500, fontSize: 15 } : { fontWeight: 400, fontSize: 15, color: '#909090' }}>Kiosk</Typography>} />
          </ListItem>
        </Link>
        <Link onClick={() => onMenuClick('category')} className={link} to="/category">
            <ListItem className={menu.category.selected ? listItemActive : listItem} button onClick={handleClick}>
            <ListItemIcon style={{marginRight: -26}}><LayersOutlinedIcon style={menu.category.selected ? {} : {position: 'relative', top: 1.4}} htmlColor={menu.category.selected ? 'white' : 'inherit'} fontSize="small" /></ListItemIcon>
                  <ListItemText primary={<Typography style={menu.category.selected ? { fontWeight: 500, fontSize: 15 } : { fontWeight: 400, fontSize: 15, color: '#909090' }}>Category</Typography>} />
            </ListItem>
          </Link>
          <Link onClick={() => onMenuClick('item')}  className={link} to="/item">
          <ListItem className={menu.item.selected ? listItemActive : listItem} button onClick={handleClick}>
              <ListItemIcon style={{marginRight: -25}}><ListAltOutlinedIcon style={menu.item.selected ? {} : {position: 'relative', bottom: 0.2}} htmlColor={menu.item.selected ? 'white' : 'inherit'} fontSize="small" /></ListItemIcon>
                <ListItemText primary={<Typography style={menu.item.selected ? { fontWeight: 500, fontSize: 15 } : { fontWeight: 400, fontSize: 15, color: '#909090' }}>Item</Typography>} />

          </ListItem>
          </Link>
          <Link onClick={() => onMenuClick('addon')}  className={link} to="/addon">
          <ListItem className={menu.addon.selected ? listItemActive : listItem} button onClick={handleClick}>
              <ListItemIcon style={{marginRight: -25}}><QueueIcon style={menu.addon.selected ? {} : {position: 'relative', bottom: 0.2}} htmlColor={menu.addon.selected ? 'white' : 'inherit'} fontSize="small" /></ListItemIcon>
                <ListItemText primary={<Typography style={menu.addon.selected ? { fontWeight: 500, fontSize: 15 } : { fontWeight: 400, fontSize: 15, color: '#909090' }}>Add-on</Typography>} />

          </ListItem>
          </Link>
          <Link onClick={() => onMenuClick('settings')}  className={link} to="/settings">
          <ListItem className={menu.settings.selected ? listItemActive : listItem} button onClick={handleClick}>
          <ListItemIcon style={{marginRight: -25}}><SettingsOutlinedIcon style={menu.settings.selected ? {} : {position: 'relative', bottom: 0.2}} htmlColor={menu.settings.selected ? 'white' : 'inherit'} fontSize="small" /></ListItemIcon>
                <ListItemText primary={<Typography style={menu.settings.selected ? { fontWeight: 500, fontSize: 15 } : { fontWeight: 400, fontSize: 15, color: '#909090' }}>Settings</Typography>} />
          </ListItem>
          </Link>
        </List>
        <List style={{marginTop:-5}} className={classes.list} subheader={
          <ListSubheader className={classes.listSubHeaderRoot} color="inherit" component="div" id="nested-list-subheader">
            SALES & ORDERS
          </ListSubheader>
        }>

{user?.shop?.type !== 'franchiser' ? (
            <Link onClick={() => onMenuClick('coupons')} className={link} to="/coupons">
                <ListItem  className={menu.coupons.selected ? listItemActive : listItem} button onClick={handleClick}>
                    <ListItemIcon style={{marginRight: -25}}><RedeemIcon htmlColor={menu.coupons.selected ? 'white' : 'inherit'} fontSize="small" /></ListItemIcon>
                    <ListItemText primary={<Typography style={menu.coupons.selected ? { fontWeight: 500, fontSize: 15 } : { fontWeight: 400, fontSize: 15, color: '#909090' }}>Coupons</Typography>} />

                </ListItem>
            </Link>
        ) : (
            <ListItem disabled  className={menu.coupons.selected ? listItemActive : listItem} button >
                <ListItemIcon style={{marginRight: -25}}><RedeemIcon htmlColor={menu.coupons.selected ? 'white' : 'inherit'} fontSize="small" /></ListItemIcon>
                <ListItemText primary={<Typography style={menu.coupons.selected ? { fontWeight: 500, fontSize: 15 } : { fontWeight: 400, fontSize: 15, color: '#909090' }}>Coupons</Typography>} />

            </ListItem>
        )}




        {user?.shop?.type !== 'franchiser' ? (
            <Link onClick={() => onMenuClick('rewards')} className={link} to="/rewards">
                <ListItem  className={menu.rewards.selected ? listItemActive : listItem} button onClick={handleClick}>
                    <ListItemIcon style={{marginRight: -25}}><LoyaltyIcon htmlColor={menu.rewards.selected ? 'white' : 'inherit'} fontSize="small" /></ListItemIcon>
                    <ListItemText primary={<Typography style={menu.rewards.selected ? { fontWeight: 500, fontSize: 15 } : { fontWeight: 400, fontSize: 15, color: '#909090' }}>Rewards Program</Typography>} />

                </ListItem>
            </Link>
        ) : (
            <ListItem disabled  className={menu.rewards.selected ? listItemActive : listItem} button >
                <ListItemIcon style={{marginRight: -25}}><LoyaltyIcon htmlColor={menu.rewards.selected ? 'white' : 'inherit'} fontSize="small" /></ListItemIcon>
                <ListItemText primary={<Typography style={menu.rewards.selected ? { fontWeight: 500, fontSize: 15 } : { fontWeight: 400, fontSize: 15, color: '#909090' }}>Rewards Program</Typography>} />

            </ListItem>
        )}




        {user?.shop?.type !== 'franchiser' ? (
            <Link onClick={() => onMenuClick('kitchen')} className={link} to="/kitchen">
                <ListItem className={classes.listItem} button onClick={handleClick}>
                    <ListItemIcon style={{marginRight: -25}}><InfoOutlinedIcon fontSize="small" /></ListItemIcon>
                    <ListItemText primary={<Typography style={{ fontWeight: 400, fontSize: 15, color: '#909090' }}>Kitchen Display</Typography>} />
                </ListItem>
            </Link>
        ) : (
            <ListItem disabled className={classes.listItem} button >
                <ListItemIcon style={{marginRight: -25}}><InfoOutlinedIcon fontSize="small" /></ListItemIcon>
                <ListItemText primary={<Typography style={{ fontWeight: 400, fontSize: 15, color: '#909090' }}>Kitchen Display</Typography>} />
            </ListItem>
        )}
        {user?.shop?.type !== 'franchiser' ? (
            <Link onClick={() => onMenuClick('tablemanager')} className={link} to="/table-manager">
                <ListItem className={menu.tableManager.selected ? listItemActive : listItem} button onClick={handleClick}>
                    <ListItemIcon style={{marginRight: -25}}><ViewModuleOutlinedIcon style={menu.tableManager.selected ? {} : {position: 'relative', bottom: 0.2}} htmlColor={menu.tableManager.selected ? 'white' : 'inherit'} fontSize="small" /></ListItemIcon>
                    <ListItemText primary={<Typography style={menu.tableManager.selected ? { fontWeight: 500, fontSize: 15 } : { fontWeight: 400, fontSize: 15, color: '#909090' }}>Table Manager</Typography>} />
                </ListItem>
            </Link>
        ) : (
            <ListItem className={menu.tableManager.selected ? listItemActive : listItem} button disabled>
                <ListItemIcon style={{marginRight: -25}}><ViewModuleOutlinedIcon style={menu.tableManager.selected ? {} : {position: 'relative', bottom: 0.2}} htmlColor={menu.tableManager.selected ? 'white' : 'inherit'} fontSize="small" /></ListItemIcon>
                <ListItemText primary={<Typography style={menu.tableManager.selected ? { fontWeight: 500, fontSize: 15 } : { fontWeight: 400, fontSize: 15, color: '#909090' }}>Table Manager</Typography>} />
            </ListItem>
        )}
        {user?.shop?.type !== 'franchiser' ? (
            <Link onClick={() => onMenuClick('tablebuilder')} className={link} to="/table">
                <ListItem className={classes.listItem} button onClick={handleClick}>
                    <ListItemIcon style={{marginRight: -25}}><DashboardIcon fontSize="small" /></ListItemIcon>
                    <ListItemText primary={<Typography style={{ fontWeight: 400, fontSize: 15, color: '#909090' }}>Table Canvas</Typography>} />
                </ListItem>
            </Link>
        ) : (
            <ListItem className={classes.listItem} button disabled>
                <ListItemIcon style={{marginRight: -25}}><DashboardIcon fontSize="small" /></ListItemIcon>
                <ListItemText primary={<Typography style={{ fontWeight: 400, fontSize: 15, color: '#909090' }}>Table Canvas</Typography>} />
            </ListItem>
        )}
         {user?.shop?.type !== 'franchiser' ? (
             <Link onClick={() => onMenuClick('sales')} className={link} to="/sales">
                 <ListItem className={menu.sales.selected ? listItemActive : listItem} button onClick={handleClick}>
                     <ListItemIcon style={{marginRight: -25}}><ListAltOutlinedIcon style={menu.sales.selected ? {} : {position: 'relative', bottom: 0.2}} htmlColor={menu.sales.selected ? 'white' : 'inherit'} fontSize="small" /></ListItemIcon>
                     <ListItemText primary={<Typography style={menu.sales.selected ? { fontWeight: 500, fontSize: 15 } : { fontWeight: 400, fontSize: 15, color: '#909090' }}>Sales</Typography>} />
                 </ListItem>
             </Link>
         ) : (
             <ListItem className={menu.sales.selected ? listItemActive : listItem} button disabled>
                 <ListItemIcon style={{marginRight: -25}}><ListAltOutlinedIcon style={menu.sales.selected ? {} : {position: 'relative', bottom: 0.2}} htmlColor={menu.sales.selected ? 'white' : 'inherit'} fontSize="small" /></ListItemIcon>
                 <ListItemText primary={<Typography style={menu.sales.selected ? { fontWeight: 500, fontSize: 15 } : { fontWeight: 400, fontSize: 15, color: '#909090' }}>Sales</Typography>} />
             </ListItem>
         )}
          <Link onClick={() => onMenuClick('salesreport')} className={link} to="/sales-report">
          <ListItem className={menu.salesReport.selected ? listItemActive : listItem} button onClick={handleClick}>
                <ListItemIcon style={{marginRight: -25}}><AssessmentOutlinedIcon style={menu.salesReport.selected ? {} : {position: 'relative', bottom: 0.2}} htmlColor={menu.salesReport.selected ? 'white' : 'inherit'} fontSize="small"  /></ListItemIcon>
                <ListItemText primary={<Typography style={menu.salesReport.selected ? { fontWeight: 500, fontSize: 15 } : { fontWeight: 400, fontSize: 15, color: '#909090' }}>Sales Report</Typography>} />
          </ListItem>
          </Link>
              <Link onClick={() => onMenuClick('payout')} className={link} to="/payout">
                  <ListItem className={menu.payout.selected ? listItemActive : listItem} button onClick={handleClick}>
                      <ListItemIcon style={{marginRight: -25}}><AssessmentOutlinedIcon style={menu.payout.selected ? {} : {position: 'relative', bottom: 0.2}} htmlColor={menu.payout.selected ? 'white' : 'inherit'} fontSize="small"  /></ListItemIcon>
                      <ListItemText primary={<Typography style={menu.payout.selected ? { fontWeight: 500, fontSize: 15 } : { fontWeight: 400, fontSize: 15, color: '#909090' }}>Payout</Typography>} />
                  </ListItem>
              </Link>


        </List>
      </Drawer>
      </Hidden>
    </React.Fragment>
  );
};

export default Sidebar;
