import React from 'react';
import AddItemModal from '../modal/AddItemModal'

const CategoryAddModal = () => {
  return (
    <AddItemModal />
  );
};

export default CategoryAddModal;
