import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TextField, Table, RadioGroup, Radio, FormControl, FormControlLabel, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';


function SignatureForm({ formattedDate, setCustomerBusinessName,customerTitle, setCustomerTitle, customerFullName, setCustomerFullName, customerBusinessName, handleChange }) {
  const [consentGiven, setConsentGiven] = useState(false);
  const [signed, setSigned] = useState(false);
  const [signed2, setSigned2] = useState(false);

  const handleConsentChange = (event) => {
    setConsentGiven(event.target.checked);
  };

  const handleSignature = () => {
    if (consentGiven) {
      setSigned(true);
    } else {
      alert('Please provide your consent to sign electronically.');
    }
  };

  const handleSignature2 = () => {
    if (consentGiven) {
      setSigned2(true);
    } else {
      alert('Please provide your consent to sign electronically.');
    }
  };

  return (
    <> <div style={{ marginBottom: '10px', marginTop:0, padding: 0 }}>
    <input
      type="checkbox"
      id="consent"
      checked={consentGiven}
      onChange={handleConsentChange}
    />
    <label htmlFor="consent" style={{ marginLeft: '5px', color: 'red' }}>
      I agree to conduct business electronically and understand that my electronic signature is legally binding.
    </label>
  </div>
    <div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, display: 'flex', justifyContent: 'space-around' }}>

      {/* Service Provider Section */}
      <div>
        <p style={{ margin: 0 }}>Service Provider: <b>Kio Start, Inc</b></p>
        <p style={{ margin: 0, marginTop: 5 }}>
          
          Signature: 
          {!signed ? (
        <>
        
          <button disabled={!consentGiven} onClick={handleSignature} style={{  marginLeft: 10,padding: '10px', fontSize: '16px' }}>
            Tap to Sign
          </button>
        </>
      ) : (
        <span style={{ fontFamily: "'Pacifico', cursive", marginLeft: 10, fontSize: '24px', marginTop: '20px' }}>
          Jared Kim
        </span>
      )}
        </p>
        <p style={{ margin: 0 }}>Title: 
          <TextField 
          
            inputProps={{
              style: { textAlign: 'center', fontFamily: 'Heebo, sans-serif', fontSize: 17, fontWeight: 800 }
            }} 
            style={{ width: 240, position: 'relative', bottom: 3.5 }}
            disabled={!consentGiven}  // Disable field until consent is given
          />
        </p>
        <p style={{ margin: 0 }}>Name: 
          <TextField 
          
            inputProps={{
              style: { textAlign: 'center', fontFamily: 'Heebo, sans-serif', fontSize: 17, fontWeight: 800 }
            }} 
            value="Jared Kim"
            style={{ width: 228, position: 'relative', bottom: 3.5 }}
            disabled={!consentGiven}  // Disable field until consent is given
          />
        </p>
        <p style={{ margin: 0 }}>Date: <b>{formattedDate}</b></p>
      </div>

      {/* Customer Section */}
      <div>
        
      <p style={{ margin: 0 }}>Customer: 
          <TextField 
            value={customerBusinessName}
            onChange={handleChange(setCustomerBusinessName)}
            inputProps={{
              style: { textAlign: 'center', fontFamily: 'Heebo, sans-serif', fontSize: 17, fontWeight: 800 }
            }} 
            style={{ width: 200, position: 'relative', bottom: 3.5 }}
            disabled={!consentGiven}  // Disable field until consent is given
          />
        </p>
        <p style={{ margin: 0, marginTop: 5 }}>
          Signature: 
          {!signed2 ? (
        <>
          <button disabled={!consentGiven} onClick={handleSignature2} style={{ marginLeft: 10, padding: '10px', fontSize: '16px' }}>
            Tap to Sign
          </button>
        </>
      ) : (
        <span style={{ fontFamily: "'Pacifico', cursive", marginLeft: 10, fontSize: '24px', marginTop: '20px' }}>
          {customerFullName}
        </span>
      )}
        </p>
        <p style={{ margin: 0 }}>Title: 
          <TextField 
            value={customerTitle}
            onChange={handleChange(setCustomerTitle)}
            inputProps={{
              style: { textAlign: 'center', fontFamily: 'Heebo, sans-serif', fontSize: 17, fontWeight: 800 }
            }} 
            style={{ width: 240, position: 'relative', bottom: 3.5 }}
            disabled={!consentGiven}  // Disable field until consent is given
          />
        </p>
        <p style={{ margin: 0 }}>Name: 
          <TextField 
            value={customerFullName}
            onChange={handleChange(setCustomerFullName)}
            inputProps={{
              style: { textAlign: 'center', fontFamily: 'Heebo, sans-serif', fontSize: 17, fontWeight: 800 }
            }} 
            style={{ width: 228, position: 'relative', bottom: 3.5 }}
            disabled={!consentGiven}  // Disable field until consent is given
          />
        </p>
        <p style={{ margin: 0 }}>Date: <b>{formattedDate}</b></p>
      </div>
    </div>
    </>
  );
}


function useQuery() {
  return new URLSearchParams(useLocation().search);
}


function ServiceTable() {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 800 }}>Service Type</TableCell>
            <TableCell style={{ fontWeight: 800 }}>Description</TableCell>
            <TableCell style={{ fontWeight: 800 }}>Unit Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {services.map((service) => (
            <TableRow key={service.type}>
              <TableCell style={{ minWidth: 88 }}>{service.type}</TableCell>
              <TableCell>{service.description}</TableCell>
              <TableCell style={{ minWidth: 120 }}>$<TextField
              defaultValue={0}
              inputProps={{
                style: {
                  width: 50,
                  textAlign: 'center',  // This centers the text horizontally
                  fontFamily: 'Heebo, sans-serif', fontSize: 13, fontWeight: 800
                },
              }}
                style={{ position: 'relative', bottom: 3.5, marginLeft: 5, }}
              />  ({service.price})</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const services = [
  { type: 'Design', description: 'Includes main banner, menu header banner, and branding tailored to your business needs.', price: '$79' },
  { type: 'Menu Setup', description: 'Comprehensive menu configuration and integration into the kiosk system.', price: '$200' },
  { type: 'Installation', description: 'Professional installation of kiosk equipment at your location.', price: '$100' },
  { type: 'Delivery', description: 'Secure delivery of all equipment to your specified address.', price: '$59' },
  { type: 'Training', description: 'Comprehensive training for staff on how to use and manage the kiosk system.', price: '$100' },
];
function EquipmentTable() {
  const [selectedValue, setSelectedValue] = useState({});

  // Initialize default values for the radio buttons when the component loads
  React.useEffect(() => {
    const defaultValues = {};
    rows.forEach(row => {
      defaultValues[row.type] = row.type === 'Ticket Printer (EPSON Thermal)' ? 'P' : 'L';
    });
    setSelectedValue(defaultValues);
  }, []);

  const handleChange = (type, value) => {
    setSelectedValue(prevState => ({
      ...prevState,
      [type]: value
    }));
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 800 }} align="center" colSpan={2}>Purchase/Lease</TableCell>
            <TableCell style={{ fontWeight: 800 }}>QTY</TableCell>
            <TableCell style={{ fontWeight: 800 }}>Equipment Type</TableCell>
            <TableCell style={{ fontWeight: 800 }}>Unit Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.type}>
              {/* Radio Buttons for Purchase or Lease */}
              <TableCell align="center" colSpan={2}>
                <RadioGroup
                  row
                  value={selectedValue[row.type] || ''}  // Set dynamic value from state
                  onChange={(e) => handleChange(row.type, e.target.value)}
                >
                  <FormControlLabel value="P" control={<Radio color="primary" />} label="P" />
                  <FormControlLabel value="L" control={<Radio color="primary" />} label="L" />
                </RadioGroup>
              </TableCell>
              <TableCell align="center">
                <TextField
                  value={row.qty}
                  inputProps={{ style: { width: 35, textAlign: 'center' } }}
                  type="number"
                />
              </TableCell>
              <TableCell>{row.type}</TableCell>
              <TableCell>
                $<TextField
                  defaultValue={0}
                  inputProps={{
                    style: {
                      width: 50,
                      textAlign: 'center',
                      fontFamily: 'Heebo, sans-serif', fontSize: 13, fontWeight: 800
                    },
                  }}
                  style={{ position: 'relative', bottom: 3.5, marginLeft: 5 }}
                /> ({row.price})
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const rows = [
  { type: 'Kiosk (Kio KM24 24" Kiosk)', qty: '1', price: '$2,400' },
  { type: 'Kitchen Display (Amazon Fire)', qty: '1', price: '$150' },
  { type: 'Ticket Printer (EPSON Thermal)', qty: '1', price: '$399' },
  { type: 'Card Reader (BBPOS Wise POS E)', qty: '1', price: '$249' },
];


function Contract() {
  const query = useQuery();
  // Initialize state for each query parameter
  const [customerBusinessName, setCustomerBusinessName] = useState('');
  const [contractTerm, setContractTerm] = useState('');
  const [monthlyFee, setMonthlyFee] = useState('');
  const [discountedMonthlyFee, setDiscountedMonthlyFee] = useState('');
  const [minOrder, setMinOrder] = useState('');
  const [firstBillingDate, setFirstBillingDate] = useState('');
  const [transFeeCents, setTransFeeCents] = useState('');
  const [paid, setPaid] = useState('');
  const [paidByShop, setPaidByShop] = useState('');
  const [customerTitle, setCustomerTitle] = useState('');
  const [customerFullName, setCustomerFullName] = useState('');
  const [designFee, setDesignFee] = useState('');
  const [menuSetupFee, setMenuSetupFee] = useState('');
  const [installationFee, setInstallationFee] = useState('');
  const [deliveryFee, setDeliveryFee] = useState('');
  const [trainingFee, setTrainingFee] = useState('');
  const [freeTrial, setFreeTrial] = useState(false)

  // Set initial values from query parameters
  React.useEffect(() => {
    setCustomerBusinessName(query.get("customerBusinessName") || '');
    setContractTerm(query.get("contractTerm") || '');
    setMonthlyFee(query.get("monthlyFee") || '');
    setDiscountedMonthlyFee(query.get("discountedMonthlyFee") || '');
    setMinOrder(query.get("minOrder") || '');
    setFirstBillingDate(query.get("firstBillingDate") || '');
    setTransFeeCents(query.get("transFeeCents") || '');
    setPaid(query.get("paid") || 'paidByCustomer');
    setCustomerTitle(query.get("customerTitle") || '');
    setCustomerFullName(query.get("customerFullName") || '');
    setDesignFee(query.get("designFee") || 0);
    setMenuSetupFee(query.get("menuSetupFee") || 0);
    setInstallationFee(query.get("installationFee") || 0);
    setDeliveryFee(query.get("deliveryFee") || 0);
    setTrainingFee(query.get("trainingFee") || 0);
    setFreeTrial(query.get("freeTrial") || 0);

  }, [query]);

    // Handle change for each field
    const handleInputChange = (setter) => (event) => {
      setter(event.target.value);
    };
  

  const today = new Date();
  const formattedDate = new Intl.DateTimeFormat('en-US', { month: 'long', day: 'numeric', year: 'numeric' }).format(today);
  const [selectedValue, setSelectedValue] = useState('');

  const handleChange2 = (event) => {
    setSelectedValue(event.target.value);
  };
  const [formData, setFormData] = useState({
    customerName: customerFullName,
    purchaseOrLease: 'Purchase',
    kioskQuantity: 1,
    kitchenDisplay: 0,
    ticketPrinter: 0,
    cardReader: 0,
    designService: false,
    menuSetup: false,
    installation: false,
    delivery: false,
    training: false,
    serviceFee: '',
    ordersHandled: '',
    transactionFee: '',
    transactionPaidBy: 'Customer',
    agreementSignDate: '',
    customerSignature: '',
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Data Submitted: ', formData);
  };



  return (
    <form style={{
      fontFamily: 'Heebo, sans-serif',
      padding: 30,
      paddingBottom: 500
    }} onSubmit={handleSubmit}>
      <h2>Kiosk Service Agreement</h2>
      <div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17 }}>
        This Kiosk Service Agreement (this “ Agreement”) is entered into as of <b>{formattedDate}</b> (the “Effective Date”), by and between Kio Start, Inc (“Service Provider”) and
        <TextField  value={customerBusinessName}
        onChange={handleInputChange(setCustomerBusinessName)} inputProps={{
          style: {
            textAlign: 'center',  // This centers the text horizontally
            fontFamily: 'Heebo, sans-serif', fontSize: 17, fontWeight: 800
          },
        }}
          style={{ position: 'relative', bottom: 3.5, marginLeft: 5, }} /> (“Customer”) with regard to Service Provider providing the
        kiosk system and related services as defined herein.
      </div>
      <h3>
        Kiosk Equipment Details:
      </h3>
      <div style={{ fontFamily: 'Heebo, sans-serif', marginBottom: 20, fontSize: 17 }}>
        The following details the equipment provided by the Service Provider. Your purchase
        is subject to the terms and conditions outlined in this Agreement.
      </div>
      <EquipmentTable  />
      <h3 style={{ marginTop: 30 }}>
        Service Details:
      </h3>
      <div style={{ fontFamily: 'Heebo, sans-serif', marginBottom: 20, fontSize: 17 }}>
        The following details of the services provided by the Service Provider. Your purchase
        is subject to the terms and conditions outlined in this Agreement.
      </div>
      <ServiceTable customerBusinessName={customerBusinessName} />
      <h3 style={{ marginTop: 30 }}>THE PARTIES HERETO AGREE AS FOLLOWS:</h3>
      <h4 style={{ fontSize: 18 }}>1. Term and Termination:</h4>
      <div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, marginLeft: 20, marginBottom: 10 }}>
        1.1. The term of this Agreement shall be for a period of         <TextField  value={contractTerm}
        onChange={handleInputChange(setContractTerm)} inputProps={{
          
          style: {
            textAlign: 'center',  // This centers the text horizontally
            fontFamily: 'Heebo, sans-serif', fontSize: 17, fontWeight: 800
          },
        }}
          style={{ width: 100, position: 'relative', bottom: 3.5, }} /> from the date of signing.
      </div>
      <div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, marginLeft: 20, marginBottom: 10 }}>
        1.2. This Agreement shall automatically renew for successive terms of the same
        duration as the initial term specified in Section 1.1, unless either party provides
        written notice of termination at least 30 days prior to the end of the current
        term.
      </div>
      <div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, marginLeft: 20, marginBottom: 10 }}>
        1.3. If the Customer terminates the Agreement during a contracted term, they
        must pay the remaining monthly fees for the term in full upon termination.

      </div>
      <div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, marginLeft: 20, marginBottom: 10 }}>
        1.4. The Service Provider may terminate this Agreement immediately if the
        Customer fails to pay the service fees or breaches any terms of this Agreement.
      </div>
      <h4 style={{ fontSize: 18 }}>2. Payment Processor:</h4>
      <div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, marginLeft: 20, marginBottom: 10 }}>
        2.1. The transaction processing services will be provided by <b>Stripe, Inc.</b>
        ("Processor").
      </div>
      <div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, marginLeft: 20, marginBottom: 10 }}>
        2.2. By using the kiosk system, the Customer agrees to comply with Stripe's
        terms of service and privacy policy.
      </div>
      <div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, marginLeft: 20, marginBottom: 10 }}>
        2.3. Any issues related to transaction processing should be directed to the
        Processor.
      </div>
      <div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, marginLeft: 20, marginBottom: 10 }}>
        2.4. The Service Provider reserves the right to change its Processor at any time.
        In the event of such a change, the Service Provider will provide the Customer
        with reasonable notice and update the terms of this agreement accordingly.
      </div>
      <div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, marginLeft: 20, marginBottom: 10 }}>
        2.5. Payment information will be handled in accordance with the Processor's
        privacy policy. The Service Provider will not store or have access to sensitive
        payment information. All payment data will be securely transmitted and
        processed by the Processor.
      </div>

      <h4 style={{ fontSize: 18 }}>3. Service Provided:</h4>
      <div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, marginLeft: 20, marginBottom: 10 }}>
        3.1. The Service Provider agrees to provide the following services:
        <div style={{ marginLeft: 20, marginTop: 5 }}>3.1.1. Installation and setup of the kiosk system</div>
        <div style={{ marginLeft: 20 }}>3.1.2. Regular software maintenance and updates</div>
        <div style={{ marginLeft: 20 }}>3.1.3. Technical support and troubleshooting</div>
      </div>
      <div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, marginLeft: 20, marginBottom: 10 }}>
        3.2. Additional services will be provided as per separate agreements signed by
        both parties.

      </div>

      <h4 style={{ fontSize: 18 }}>4. Service Fees:</h4>
<div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, marginLeft: 20, marginBottom: 10 }}>
  4.1. The service fee shall be $<TextField 
     value={monthlyFee}
     onChange={handleInputChange(setMonthlyFee)}
     inputProps={{
       style: {
         textAlign: 'center',  // This centers the text horizontally
         fontFamily: 'Heebo, sans-serif', fontSize: 17, fontWeight: 800
       },
     }}
     style={{ width: 80, position: 'relative', bottom: 3.5, }} /> per month.
</div>
<div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, marginLeft: 20, marginBottom: 10 }}>
  4.2. If the Customer handles more than <TextField
    value={minOrder}
    onChange={handleInputChange(setMinOrder)}
    inputProps={{
      style: {
        textAlign: 'center',  // This centers the text horizontally
        fontFamily: 'Heebo, sans-serif', fontSize: 17, fontWeight: 800
      },
    }}
    style={{ width: 50, position: 'relative', bottom: 3.5, }} /> orders in a month, the service fee
  for the following month shall be reduced to $<TextField 
    value={discountedMonthlyFee}
    onChange={handleInputChange(setDiscountedMonthlyFee)}
    inputProps={{
      style: {
        textAlign: 'center',  // This centers the text horizontally
        fontFamily: 'Heebo, sans-serif', fontSize: 17, fontWeight: 800
      },
    }}
    style={{ width: 80, position: 'relative', bottom: 3.5, }} /> for the following month. If fewer than <TextField 
      value={minOrder}
      onChange={handleInputChange(setMinOrder)}
      inputProps={{
        style: {
          textAlign: 'center',  // This centers the text horizontally
          fontFamily: 'Heebo, sans-serif', fontSize: 17, fontWeight: 800
        },
      }}
      style={{ width: 50, position: 'relative', bottom: 3.5, }} /> orders are placed, the fee for the following month shall
  be $<TextField 
    value={monthlyFee}
    onChange={handleInputChange(setMonthlyFee)}
    inputProps={{
      style: {
        textAlign: 'center',  // This centers the text horizontally
        fontFamily: 'Heebo, sans-serif', fontSize: 17, fontWeight: 800
      },
    }}
    style={{ width: 80, position: 'relative', bottom: 3.5, }} /> per month.
</div>
<div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, marginLeft: 20, marginBottom: 10 }}>
  4.3. Fees are calculated starting from the date of installation and commencement
  of service. Billing will occur on the same date each subsequent month unless
  otherwise specified in the billing start date.
</div>
<div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, marginLeft: 20, marginBottom: 10 }}>
  4.4. The first billing date will be: <TextField 
    value={firstBillingDate}
    onChange={handleInputChange(setFirstBillingDate)}
    inputProps={{
      style: {
        textAlign: 'center',  // This centers the text horizontally
        fontFamily: 'Heebo, sans-serif', fontSize: 17, fontWeight: 800
      },
    }}
    style={{ width: 300, position: 'relative', bottom: 3.5, }} />.
</div>

{/* New section for 30-day free trial */}
{freeTrial ? (
  <div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, marginLeft: 20, marginBottom: 10 }}>
    4.5. The Customer is entitled to a 30-day free trial starting from the installation date. If the Customer does not cancel the trial by providing written notice at least 24 hours before the end of the 30-day period, the Agreement will automatically convert into a paid contract. Billing will begin on the first billing date, which is <strong>{firstBillingDate}</strong>.
  </div>
) : null}


      <h4 style={{ fontSize: 18 }}>5. Transaction Fees:</h4>
      <div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, marginLeft: 20, marginBottom: 10 }}>
        5.1. A transaction fee of 2.7% + $<TextField 
        value={transFeeCents}
        onChange={handleInputChange(setTransFeeCents)}
        inputProps={{
          style: {
            textAlign: 'center',  // This centers the text horizontally
            fontFamily: 'Heebo, sans-serif', fontSize: 17, fontWeight: 800
          },
        }}
          style={{ width: 80, position: 'relative', bottom: 3.5, }} /> will be applied for each card transaction.
      </div>
      <div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, marginLeft: 20, marginBottom: 10 }}>
        5.2. The transaction fee can be paid either by the customers or by the shop.
        Please check the applicable option:
        <div style={{ marginLeft: 20, marginTop: 5 }}>
      <FormControl component="fieldset">
        <RadioGroup
          name="paymentOption"
          value={paid}
          onChange={handleChange2}
        >
          <FormControlLabel
            value="paidByCustomer"
            control={<Radio color="primary" />}
            label="Paid by the customers"
            style={{ marginLeft: 5 }}
          />
          <FormControlLabel
            value="paidByShop"
            control={<Radio color="primary" />}
            label="Paid by the shop"
            style={{ marginLeft: 5 }}
          />
        </RadioGroup>
      </FormControl>
    </div>
      </div>


      <h4 style={{ fontSize: 18 }}>6. Maintenance and Support:</h4>
      <div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, marginLeft: 20, marginBottom: 10 }}>
        6.1. The Service Provider shall provide regular software maintenance and the
        Customer shall promptly notify the Service Provider of any system errors or
        issues.
      </div>
      <div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, marginLeft: 20, marginBottom: 10 }}>
        6.2. The Service Provider may make every effort to resolve issues.
      </div>
      <h4 style={{ fontSize: 18 }}>7. Responsibilities and Liabilities:</h4>
      <div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, marginLeft: 20, marginBottom: 10 }}>
        7.1. The Service Provider shall ensure the proper functioning of the kiosk system.
      </div>
      <div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, marginLeft: 20, marginBottom: 10 }}>
        7.2. The Customer shall use the kiosk system appropriately and ensure no
        damage or data loss occurs.      </div>
      <div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, marginLeft: 20, marginBottom: 10 }}>
        7.3. The Customer assumes all legal responsibilities arising from the use of the
        system.  </div>
      <div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, marginLeft: 20, marginBottom: 10 }}>
        7.4 The Customer is responsible for any loss, theft, or damage to the kiosk
        equipment while it is under their control. In the event of any such occurrence,
        the Customer shall promptly notify the Service Provider and may be liable for
        repair or replacement costs as determined by the Service Provider.  </div>
        <h4 style={{ fontSize: 18 }}>8. Return of Leased Equipment:</h4>
<div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, marginLeft: 20, marginBottom: 10 }}>
  8.1. Upon termination, cancellation, or the end of the trial period without conversion to a paid contract, the Service Provider will arrange to pick up all leased equipment (including kiosks, printers, and any related items) from the Customer’s location. The Customer must ensure the equipment is in good working condition, free of broken parts or functional issues, and ready for collection.
</div>
<div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, marginLeft: 20, marginBottom: 10 }}>
  8.2. The Customer will be responsible for inspecting the equipment before it is picked up and must notify the Service Provider of any issues prior to collection.
</div>
<div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, marginLeft: 20, marginBottom: 10 }}>
  8.3. If any leased equipment is found to be damaged or non-functioning upon collection, the Customer will be liable for the cost of repair or replacement, which will be determined by the Service Provider based on the extent of the damage.
</div>
<div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, marginLeft: 20, marginBottom: 10 }}>
  8.4. The Service Provider reserves the right to charge the Customer for any repair or replacement costs related to damaged or non-functioning equipment. These charges will be added to the Customer’s final bill or deducted from any deposits or credits on the Customer's account.
</div>
<div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, marginLeft: 20, marginBottom: 10 }}>
  8.5. At the end of the trial period (or termination/cancellation), if the equipment is not ready for pickup or is returned damaged, the Customer will be charged the full replacement cost of the equipment. The Service Provider may also take legal action to recover the equipment if necessary.
</div>

      <h4 style={{ fontSize: 18 }}>9. Miscellaneous:</h4>
      <div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, marginLeft: 20, marginBottom: 10 }}>
        9.1. This Agreement may not be modified without the written consent of both parties.
      </div>
      <div style={{ fontFamily: 'Heebo, sans-serif', fontSize: 17, marginLeft: 20, marginBottom: 10 }}>
        9.2. Any matters not expressly provided for in this Agreement shall be governed
        by relevant laws and practices.      </div>
      <h3>
        Signatures:
      </h3>
      <div style={{ fontFamily: 'Heebo, sans-serif', marginBottom: 10, fontSize: 17 }}>
        By signing below, both parties acknowledge and agree to the terms and conditions
        outlined in this Agreement.
      </div>
          <SignatureForm setCustomerBusinessName={setCustomerBusinessName} customerTitle={customerTitle} setCustomerTitle={setCustomerTitle} handleChange={handleInputChange} setCustomerFullName={setCustomerFullName} customerFullName={customerFullName} formattedDate={formattedDate} customerBusinessName={customerBusinessName}/>
    </form>
  );
}

export default Contract;
