import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { getSpecificShop, getFranchisedShops, postToggleTableMode, getToggleTableMode, getSystemUser, setStatus, getAccountLink, getStripeAccount,
         postUpdateChargeCustomersFee, selectChargeCustomers, getToggleTestingState,
         postToggleTestingState, getCustomersFeeStatus, postUpdateTipEnabled,
         toggleAddCategoryAllowed, toggleAddItemAllowed, toggleAddAddonAllowed,
         toggleEditCategoryAllowed, toggleEditItemAllowed, toggleEditAddonAllowed,
         toggleDeleteCategoryAllowed, toggleDeleteItemAllowed, toggleDeleteAddonAllowed, postToggleTicketSimplified, addCard, setDefaultCard, deleteCard,
         listCards,
         getTerminals
       } from './authSlice'
import { postConnectToken, postRemoveReader, selectSecret, postPairReader, setMyConnectedTerminal } from '../paymentProcessing/paymentProcessingSlice'
import { fetchConnectionToken } from '../../api/DashboardAPI';
import {loadStripeTerminal} from '@stripe/terminal-js';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { loadStripe } from '@stripe/stripe-js';

import { CardElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import StoreFrontIcon from '@material-ui/icons/Storefront';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Divider from '@material-ui/core/Divider';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Alert from '@material-ui/lab/Alert';
import StripeLogo from '../../assets/512px-Stripe_Logo,_revised_2016.svg.png';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';
import {
  postChangePassword,
  postUpdateTaxRate
} from './authSlice'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import useTerminal from '../../hooks/useTerminal'

const stripePromise = loadStripe('pk_live_51KU187GdutISh1LNZezgggZv4r3qt9G5t3hyCZ0zxwwgYFrr82LHZeTC84XW4SYyonSPsf0em2oiaP9fvN7IUGq100AzguEjGH');
//const stripePromise = loadStripe('pk_test_51KU187GdutISh1LNbBFnDLJ2dOvxSiOAYCuFBphEqQZ7bdqkzRLwnQCWmnNn5GXdyd5c0XJjFmMksBdzx9TDV8ZQ00ChTasKTm');

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    width: 800,
    [theme.breakpoints.down('md')]: {
      width: 600
    },
    [theme.breakpoints.down('sm')]: {
      width: 400
    },
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Paper square>
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
        </Paper>
      )}
    </div>
  );
}

/********************************************************
 A Dialog for Changing password
-----------------------------------------------------
 Opens up when a user clicks 'CHANGE PASSWORD' button
*********************************************************/
const ChangePasswordDialog = (props) => {
  const [form, setForm] = useState({
    oldPwd: {
      validated: true,
      error: ""
    },
    newPwd: {
      validated: true,
      error: "",
    },
    newPwd2: {
      validated: true,
      error: ""
    }
  });
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
      e.preventDefault();
      let validated = true;

      if (e.target.oldPwd.value == '') {
        setForm((prevState) => ({
          ...prevState,
           oldPwd: {validated: false, error: "This is a required field."}}))
        validated = false;
      } else {
        setForm((prevState) => ({
          ...prevState,
           oldPwd: {validated: true, error: "This is a required field."}}))
      }

      if (e.target.newPwd.value == '') {
        setForm((prevState) => ({
          ...prevState,
           newPwd: {validated: false, error: "This is a required field."}}))
        validated = false;
      } else {
        if (e.target.newPwd.value.length < 8) {
          setForm((prevState) => ({
            ...prevState,
             newPwd: {validated: false, error: "Password should be at least 8 characters long"}}))
          validated = false;
        } else {
          setForm((prevState) => ({
            ...prevState,
             newPwd: {validated: true, error: "This is a required field."}}))
        }

      }

      if (e.target.newPwd2.value == '') {
        setForm((prevState) => ({
          ...prevState,
           newPwd2: {validated: false, error: "This is a required field."}}))
        validated = false;
      } else {
        if (e.target.newPwd2.value.length < 8) {
          setForm((prevState) => ({
            ...prevState,
             newPwd2: {validated: false, error: "Password should be at least 8 characters long"}}))
          validated = false;
        } else {
          if (e.target.newPwd.value !== e.target.newPwd2.value) {
            setForm((prevState) => ({
              ...prevState,
               newPwd2: {validated: false, error: "Password doesn't match"}}))
            validated = false;
          } else {
            validated = true
            setForm((prevState) => ({
              ...prevState,
               newPwd2: {validated: true, error: "This is a required field."}}))
          }
        }
      }

      if (validated) {
        dispatch(postChangePassword({'oldPwd': e.target.oldPwd.value, 'newPwd': e.target.newPwd2.value}));
      }
  }
  const message = useSelector(state => state.auth.serverMessage);
  const status = useSelector(state => state.auth.status);

  return (
    <Dialog maxWidth="xs" onClose={props.handleClose} open={props.open}>
    <form onSubmit={(e) => handleSubmit(e)}>
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent dividers>
          {status === 'failed' ?
          <Alert style={{marginBottom: 20}} elevation={0} variant="outlined" severity="warning">
            {message}
          </Alert> : null}
          {status === 'added' ?
          <Alert style={{marginBottom: 20}} elevation={0} variant="outlined" severity="success">
            {message}
          </Alert> : null}
          <FormControl fullWidth size="small" variant="outlined">
            <InputLabel htmlFor="old-pass">Enter Old Password</InputLabel>
            <OutlinedInput size="small" name="oldPwd" id="old-pass" label="Existing Password" type="password" />
            {form.oldPwd.validated ? null : <FormHelperText style={{margin: 0}} error>{form.oldPwd.error}</FormHelperText>}
          </FormControl>
          <FormControl style={{marginTop: 15}} fullWidth size="small" variant="outlined">
            <InputLabel htmlFor="new-pass">Enter New Password</InputLabel>
            <OutlinedInput  size="small" name="newPwd" id="new-pass" label="New Password" type="password" />
            {form.newPwd.validated ? null : <FormHelperText style={{margin: 0}} error>{form.newPwd.error}</FormHelperText>}
          </FormControl>
          <FormControl style={{marginTop: 15}} fullWidth size="small" variant="outlined">
            <InputLabel htmlFor="new-pass2">Confirm New Password</InputLabel>
            <OutlinedInput  size="small" name="newPwd2" id="new-pass2" label="Confirm New Password" type="password" />
            {form.newPwd2.validated ? null : <FormHelperText style={{margin: 0}} error>{form.newPwd2.error}</FormHelperText>}
          </FormControl>
      </DialogContent>
      <DialogActions>
       <Button autoFocus onClick={props.handleClose} color="primary">
         Cancel
       </Button>
       {status === 'loading' ?
         <Button disabled type="submit" color="primary">
           CHANGING...
         </Button> :
         <Button type="submit" color="primary">
           CONFIRM
         </Button>
       }
     </DialogActions>
     </form>
    </Dialog>
  );
}

const AccessControl = (props) => {
  const targetShop = useSelector(state => state.auth.targetShop)
  const status = useSelector(state => state.auth.status)

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSpecificShop({shop_id: props.shopId}))

  }, [props.shopId])

  useEffect(() => {
    if (status === 'succeeded toggling an access control') {
      dispatch(getSpecificShop({shop_id: props.shopId}))
    }
  }, [status])

  const addCategoryAllowed = targetShop?.add_category_allowed;
  const addItemAllowed = targetShop?.add_item_allowed;
  const addAddonAllowed = targetShop?.add_addon_allowed;
  const editCategoryAllowed = targetShop?.edit_category_allowed;
  const editItemAllowed = targetShop?.edit_item_allowed;
  const editAddonAllowed = targetShop?.edit_addon_allowed;
  const deleteCategoryAllowed = targetShop?.delete_category_allowed;
  const deleteItemAllowed = targetShop?.delete_item_allowed;
  const deleteAddonAllowed = targetShop?.delete_addon_allowed;

  const payload = { shop_id: props.shopId };

  return (
    targetShop ? (
      <React.Fragment>
      {props.value === 0 ? <h4 style={{fontWeight: 300}}>* Overrides All Shops</h4> :  <h4 style={{fontWeight: 300}}>{props.shop?.business_name} {props.shop?.city}</h4>}
     <div>
     <FormControlLabel
         control={
           <Switch
             checked={addCategoryAllowed}
             onChange={() => dispatch(toggleAddCategoryAllowed(payload))}
             name="toggle_add_menu_control"
             color="primary"
           />
         }
         label={addCategoryAllowed ? 'Adding Categories Allowed' : 'Adding Categories Disabled'}
       />
     </div>
     <div>
     <FormControlLabel
         style={{marginTop: 20}}
         control={
           <Switch
             checked={addItemAllowed}
             onChange={() => dispatch(toggleAddItemAllowed(payload))}
             name="toggle_table_mode"
             color="primary"
           />
         }
         label={addItemAllowed ? 'Adding Items Allowed' : 'Adding Items Disabled'}
       />
     </div>
     <div>
     <FormControlLabel
         style={{marginTop: 20}}
         control={
           <Switch
             checked={addAddonAllowed}
             onChange={() => dispatch(toggleAddAddonAllowed(payload))}
             name="toggle_table_mode"
             color="primary"
           />
         }
         label={addAddonAllowed ? 'Adding Add-ons Allowed' : 'Adding Add-ons Disabled'}
       />
     </div>

     <Divider style={{marginTop: 20}} />
     <div>
     <FormControlLabel
     style={{marginTop: 20}}

         control={
           <Switch
             checked={editCategoryAllowed}
             onChange={() => dispatch(toggleEditCategoryAllowed(payload))}
             name="toggle_add_menu_control"
             color="primary"
           />
         }
         label={editCategoryAllowed ? 'Editing Categories Allowed' : 'Editing Categories Disabled'}
       />
     </div>
     <div>
     <FormControlLabel
         style={{marginTop: 20}}
         control={
           <Switch
             checked={editItemAllowed}
             onChange={() => dispatch(toggleEditItemAllowed(payload))}
             name="toggle_table_mode"
             color="primary"
           />
         }
         label={editItemAllowed ? 'Editing Items Allowed' : 'Editing Items Disabled'}
       />
     </div>
     <div>
     <FormControlLabel
         style={{marginTop: 20}}
         control={
           <Switch
             checked={editAddonAllowed}
             onChange={() => dispatch(toggleEditAddonAllowed(payload))}
             name="toggle_table_mode"
             color="primary"
           />
         }
         label={editAddonAllowed ? 'Editing Add-ons Allowed' : 'Editing Add-ons Disabled'}
       />
     </div>

     <Divider style={{marginTop: 20}} />
     <div>
       <FormControlLabel
         style={{marginTop: 20}}

             control={
               <Switch
                 checked={deleteCategoryAllowed}
                 onChange={() => dispatch(toggleDeleteCategoryAllowed(payload))}
                 name="toggle_add_menu_control"
                 color="primary"
               />
             }
             label={deleteCategoryAllowed ? 'Deleting Categories Allowed' : 'Deleting Categories Disabled'}
         />
     </div>
     <div>
     <FormControlLabel
         style={{marginTop: 20}}
         control={
           <Switch
             checked={deleteItemAllowed}
             onChange={() => dispatch(toggleDeleteItemAllowed(payload))}
             name="toggle_table_mode"
             color="primary"
           />
         }
         label={deleteItemAllowed ? 'Deleting Items Allowed' : 'Deleting Items Disabled'}
       />
     </div>
     <div>
     <FormControlLabel
         style={{marginTop: 20}}
         control={
           <Switch
             checked={deleteAddonAllowed}
             onChange={() => dispatch(toggleDeleteAddonAllowed(payload))}
             name="toggle_table_mode"
             color="primary"
           />
         }
         label={deleteAddonAllowed ? 'Deleting Add-ons Allowed' : 'Deleting Add-ons Disabled'}
       />
     </div>
   </React.Fragment>
    ) : (
      null
    ))
}

const ListCards = (props) => {
  const [loading, setLoading] = useState(false);

  const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4"
      },
      padding: '10px 14px',
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};

  const classes = useStyles();

  const dispatch = useDispatch();
  const cards = useSelector(state => state.auth.cards);
  const handleDelete = async (id) => {
    await dispatch(deleteCard({card_id: id}))
    //await dispatch(listCards());

  }

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const card = elements.getElement(CardElement);

    const result = await stripe.createToken(card);
    console.log(result)
    if (result.error) {
      console.log(result.error.message);
    } else {
      const token = result.token.id;
      await dispatch(addCard({token: token}));
      await dispatch(listCards());
    }
    card.clear();

    setLoading(false)

  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <h3>Registered Cards</h3>
        <List style={{ backgroundColor: 'white' }}>
          {cards?.map((card) => (
            <ListItem divider key={card.id}>
              <ListItemText
                primary={`${card.brand} ending in ${card.last4}`}
                secondary={`Expires ${card.exp_month}/${card.exp_year}`}
              />
              <IconButton onClick={() => handleDelete(card.id)}>
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>

        <form onSubmit={handleSubmit}>
        <div style={{ width:'100%', border: '1px solid #ced4da', padding: '10px', borderRadius: '4px', marginBottom: '20px' }}>
          <CardElement options={CARD_ELEMENT_OPTIONS} />
          {loading ? (
            <Button fullWidth style={{marginTop: 15}} type="submit" disabled={true} variant="contained" color="primary">
              Loading...
            </Button>
          ) : (
            <Button fullWidth style={{marginTop: 15}} type="submit" disabled={!stripe} variant="contained" color="primary">
              Add Card
            </Button>
          )}

        </div>

      </form>
      </CardContent>
    </Card>
  )
}

const Account = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const status = useSelector(state => state.auth.status);
  const status2 = useSelector(state => state.paymentProcessing.status);

  const accountLink = useSelector(state => state.auth.accountLink);

  const stripeAccount = useSelector(state => state.auth.stripeAccount);
  const chargedCustomers = useSelector(state => state.auth.chargeCustomers);
  const isTesting = useSelector(state => state.auth.isTesting);
  const ticketMinified = useSelector(state => state.auth.user?.shop?.kitchen_ticket_simplified ?? false);
  const multipleDevice = useSelector(state => state.auth.user?.shop?.multiple_device ?? false);

  const franchisedShops = useSelector(state => state.auth.franchisedShops);
  const terminals = useSelector(state => state.auth.terminals);

  const isTableMode = useSelector(state => state.auth.isTableMode);
  const [value, setValue] = React.useState(0);
  const [pairingCode, setPairingCode] = useState(null);

  const [open, setOpen] = useState(false);
  const [chargeCustomers, setChargeCustomers] = useState(chargedCustomers);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  useEffect(() => {
    dispatch(getSystemUser());
    dispatch(getStripeAccount());
    dispatch(getTerminals());
    dispatch(getAccountLink());
    dispatch(getCustomersFeeStatus());
    dispatch(getToggleTestingState());
    dispatch(getToggleTableMode());
    dispatch(listCards())
    setChargeCustomers(chargedCustomers)
  }, [])



  useEffect(() => {
    if (user == null) {
      dispatch(getSystemUser())
    }
  }, [user])

  useEffect(() => {
    if (status === 'ticket simplified') {
      dispatch(getSystemUser())
    }
  }, [status])


  const handlePair = async () => {
    await dispatch(postPairReader({code: pairingCode, dashboard: true}));
    dispatch(getTerminals());
  }

  const handleRemove = async (id, term_id) => {
    localStorage.removeItem('lastConnectedTerminal');
    await dispatch(postRemoveReader({id: id, term_id: term_id}))
    dispatch(getTerminals());
  }

  const handleClickOpen = () => {
     setOpen(true);
   };

   const handleClose = (value) => {
     dispatch(setStatus('idle'));
     setOpen(false);
   };

  const handleTaxUpdateForm = (e) => {
      e.preventDefault();
      dispatch(postUpdateTaxRate({'tax_rate': e.target.taxrate.value}))
  }

  const handleSetupStripeBtn = () => {
    window.open(accountLink?.url);
  }

  return (
      <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={3}
            direction="column">
        <Grid item
              md={12}>
          <Card className={classes.root}>
            <CardContent>
              <AccountCircleIcon color="primary" style={{fontSize: 100}} />
              <h3>{user ? user.user.username : null}</h3>
              <Button onClick={handleClickOpen} size="small" variant="outlined">Change Password</Button>
            </CardContent>
          </Card>
        </Grid>
        {user && user.shop.type !== 'franchiser' ? (
          <Grid item md={12}>
            <Card className={classes.root}>
              <CardContent>
                <StoreFrontIcon color="primary" style={{fontSize: 100}} />
                <h3>{user ? user.shop.business_name : null}</h3>
                <form onSubmit={handleTaxUpdateForm}>
                  <FormControl size="small" variant="outlined">
                   <OutlinedInput
                     variant="filled"
                      placeholder={user ? user.shop.tax_rate * 100 : null}
                      name="taxrate"
                      id="tax-rate"
                      startAdornment={<InputAdornment position="start">Tax</InputAdornment>}
                      endAdornment={<InputAdornment position="end">%</InputAdornment>} />
                    <Button variant="outlined" type="submit" style={{marginTop: 5}} size="small">UPDATE</Button>
                  </FormControl>
                </form>
              </CardContent>
            </Card>
          </Grid>
        ) : (
          null
        )}
        {user && user.shop.type !== 'franchiser' ? (
          <Grid item
                md={12}>
            <Card className={classes.root}>
              <CardContent>
                <img src={StripeLogo} width="140" />
                <h3>Stripe Account {stripeAccount && stripeAccount.charges_enabled ? <span style={{color: 'green'}}>Active</span> : <span style={{color: 'red'}}>Inactive</span> }</h3>
                {stripeAccount && stripeAccount.charges_enabled ? null : <Button onClick={handleSetupStripeBtn} size="small" variant="outlined">Setup Stripe</Button>}
              </CardContent>
            </Card>
          </Grid>
        ) : (
          null
        )}
        {user && user.shop.type !== 'franchisee' ? (
          <Grid item
                md={12}>
            <Card className={classes.root}>
              <CardContent>
                <h3>Charge Customers a Card Fee</h3>
                <div style={{position: 'relative', bottom: 10, color: 'grey'}}><small>Credit card surcharges are optional fees added by you
                    <br />when customers use a credit card to pay at checkout</small></div>
                    <FormControlLabel
                        style={{marginTop: 20}}
                        control={
                          <Switch
                            checked={chargedCustomers}
                            onChange={() => dispatch(postUpdateChargeCustomersFee({charge_customers_fee: !chargedCustomers}))}
                            name="charge_customers_fee"
                            color="primary"
                          />
                        }
                        label={chargedCustomers ? 'Customers Pay' : 'Merchant Pays'}
                      />
              </CardContent>
            </Card>
          </Grid>
        ) : (
          null
        )}

        {user && user.shop ? <Grid item
              md={12}>
          <Card className={classes.root}>
            <CardContent>
              <h3>Enable Tipping on Terminal</h3>
              <div style={{position: 'relative', bottom: 10, color: 'grey'}}>
                <small>Tipping can be accepted on the payment terminal</small>
              </div>
              <FormControlLabel
                  style={{marginTop: 20}}
                  control={
                    <Switch
                      checked={user.shop.tip_enabled}
                      onChange={() => dispatch(postUpdateTipEnabled({tip_enabled: !user.shop.tip_enabled, dashboard: true}))}
                      name="tip_enabled"
                      color="primary"
                    />
                  }
                  label={user.shop.tip_enabled ? 'Tipping Eanabled' : 'Tipping Disabled'}
                />
            </CardContent>
          </Card>
        </Grid> : null}
        {user && user.shop.type !== 'franchiser' ? (
        <Grid item
              md={12}>
          <Card className={classes.root}>
            <CardContent>
              <h3>Testing Mode</h3>
              <div style={{position: 'relative', bottom: 10, color: 'grey'}}>
              <small>Credit card simulation using Stripe's test card.
                  <br />Enable it to test out the checkout process</small></div>
                  <FormControlLabel
                      style={{marginTop: 20}}
                      control={
                        <Switch
                          checked={isTesting}
                          onChange={() => dispatch(postToggleTestingState())}
                          name="toggle_testing_state"
                          color="primary"
                        />
                      }
                      label={isTesting ? 'Test Mode Enabled' : 'Test Mode Disabled'}
                    />
            </CardContent>
          </Card>
        </Grid>) : (null)}
        {user && user.shop.type !== 'franchiser' ? (
        <Grid item
              md={12}>
          <Card className={classes.root}>
            <CardContent>
              <h3>Kitchen Display Ticket Simplified</h3>
              <div style={{position: 'relative', bottom: 10, color: 'grey'}}>
              <small>Simplify kitchen display's ticket content
                  <br />by removing option title</small></div>
                  <FormControlLabel
                      style={{marginTop: 20}}
                      control={
                        <Switch
                          checked={ticketMinified}
                          onChange={() => dispatch(postToggleTicketSimplified())}
                          name="toggle_minify"
                          color="primary"
                        />
                      }
                      label={ticketMinified ? 'Ticket Simplified' : 'Verbose Ticket'}
                    />
            </CardContent>
          </Card>
        </Grid>) : (null)}
    {user && user.shop.type !== 'franchiser' ? (
        <Grid item md={12}>
          <Card className={classes.root}>
            <CardContent>
              <h3>Table Selection Mode</h3>
              <div style={{position: 'relative', bottom: 10, color: 'grey'}}>
              <small>Table selection mode allows customers to select a specific table
                  <br />for a dine in restaurant experience.</small></div>
                  <FormControlLabel
                      style={{marginTop: 20}}
                      control={
                        <Switch
                          checked={isTableMode}
                          onChange={() => dispatch(postToggleTableMode())}
                          name="toggle_table_mode"
                          color="primary"
                        />
                      }
                      label={isTableMode ? 'Table Mode Enabled' : 'Test Mode Disabled'}
                    />
            </CardContent>
          </Card>
        </Grid>) : (
          null
        )}
        {user && user.shop.type === 'franchiser' ? (

        <Grid item md={12}>
          <Card  className={classes.root}>
            <CardContent>
              <h3 style={{textAlign: 'center'}}>Franchise Access Control</h3>
              <div style={{textAlign: 'center', position: 'relative', bottom: 10, color: 'grey'}}>
              <small>Franchise Access Control lets you set previllages on what
                  <br />shop owners can control.</small></div>
                  <AppBar style={{marginTop: 20}} position="static" color="white" elevation={1}>
                         <Tabs
                         value={value}
                          onChange={handleChange}
                           variant="scrollable"
                           scrollButtons="on"
                           indicatorColor="primary"
                           textColor="primary"
                           aria-label="scrollable tabs for franchise access control"
                         >
                          <Tab label={'All'} />
                          {franchisedShops?.map(
                            shop => {
                               return <Tab label={user.shop.id === shop.id ? null : shop.business_name + ' ' + shop.city}/>
                            }
                          )}
                         </Tabs>
                       </AppBar>
                       {franchisedShops?.map(
                         (shop, index) => {
                           return (
                             <TabPanel value={value} index={index}>
                                <AccessControl shop={shop} value={value} shopId={shop.id} />
                             </TabPanel>
                           )
                         }
                       )}

            </CardContent>
          </Card>
        </Grid>) : (null)}
        {user && user.shop.type !== 'franchiser' ? (
          <Grid item md={12}>
          <Elements stripe={stripePromise}>
            <ListCards / >
          </Elements>

    </Grid>
        ) : (
          null
        )}

        {user && user.shop.type !== 'franchiser' ? (
        <Grid item
              md={12}>
          <Card className={classes.root}>
            <CardContent>
              <h3>Stripe Terminal</h3>
              <Grid container item xs={12} spacing={1} style={{backgroundColor:  'white', padding: 30}}>
      <Grid item xs>
      {multipleDevice && terminals.length == 0 && <ButtonGroup fullWidth color="primary" aria-label="outlined primary button group"><TextField onChange={e => setPairingCode(e.target.value)}
                 placeholder="e.g.) apple-orange-banana"
                 className={classes.customBtnStyle}
                 variant="outlined"
                 color="primary"
                 value={props.input}
                 label="Enter Pairing Code" />
      <Button onClick={() => handlePair()}  style={{width: 180}} color="default" variant="outlined">PAIR</Button>      </ButtonGroup>}
      {!multipleDevice && <ButtonGroup fullWidth color="primary" aria-label="outlined primary button group"><TextField onChange={e => setPairingCode(e.target.value)}
                 placeholder="e.g.) apple-orange-banana"
                 className={classes.customBtnStyle}
                 variant="outlined"
                 color="primary"
                 value={props.input}
                 label="Enter Pairing Code" />
      <Button onClick={() => handlePair()}  style={{width: 180}} color="default" variant="outlined">PAIR</Button>      </ButtonGroup>}

        <List style={{background: 'white'}}>
          {terminals?.map(terminal => {
            return (
             <ListItem divider key={terminal.id}>
               <ListItemText
                 primary={
                   <div>{terminal.label} <span style={{float: 'right'}}>
                   <Button onClick={() => handleRemove(terminal.id, terminal.term_id || null)} style={{fontSize: 18, marginRight: 0, backgroundColor: '#77bfbd', color: 'white', borderRadius: 20}}>REMOVE</Button></span></div>
                 }
                 secondary={<div>{terminal.status}</div>}
               />
             </ListItem>)})}
         </List>
      </Grid>
    </Grid>
            </CardContent>
          </Card>
        </Grid>
      ) : (null)}
        <ChangePasswordDialog open={open} handleClose={handleClose} />
      </Grid>

  );
}

export default Account;
