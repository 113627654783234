import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { selectAllKiosks, getKiosk, getBannerImageUrl, getMainImageUrl } from './kioskSlice';
import { useLocation } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid'
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddImage from '../modal/AddImage';
import AddMainImage from '../modal/AddMainImage';
import ImageLoader from '../../components/ImageLoader';
import Skeleton from '@material-ui/lab/Skeleton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { setMainImageModalOpen, setAddImageModalOpen,selectMainImageModalOpen, selectImageModalOpen } from '../modal/modalSlice';
import { getSystemUser, getCustomersFeeStatus, getToggleFullHeight, postToggleFullHeight } from '../auth/authSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    width: 800,
    [theme.breakpoints.down('md')]: {
      width: 600
    },
    [theme.breakpoints.down('sm')]: {
      width: 400
    },
  }
}));

const Settings = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation()
  const bannerImgUrl = useSelector(state => state.kiosk.bannerImgUrl)
  const mainImgUrl = useSelector(state => state.kiosk.mainImgUrl)
  const status = useSelector(state => state.auth.status)
  const isImgFullHeight = useSelector(state => state.auth.isImgFullHeight)
  const user = useSelector(state => state.auth.user);

  useEffect(() => {
    dispatch(getBannerImageUrl())
    dispatch(getMainImageUrl())
    dispatch(getToggleFullHeight())
  }, [])

  useEffect(() => {
    if (status === 'succeeded image') {
      dispatch(getBannerImageUrl())
      dispatch(getMainImageUrl())
    }
  }, [status])

  return (
    <React.Fragment>
    <Grid
      container
      justifyContent="center"
      spacing={3}
      direction="column">
      <Grid item
            md={12}>
        <Card className={classes.root}>
        <ImageLoader skeleton={<Skeleton variant="rect" style={{height: 400}} />} style={{width: '100%'}} src={ 'https://api.bigkiosksolution.com' + mainImgUrl } alt="content image" />
        {user?.shop?.type != 'franchisee' ? (
          <CardContent>
            <h3>Kiosk Main Image</h3>
            {isImgFullHeight ? (
              <p style={{position: 'relative', bottom: 10}}>Image Size Recommendation: 1080px [Width] / 1920px [Height]</p>

            ) : (
              <p style={{position: 'relative', bottom: 10}}>Image Size Recommendation: 1080px [Width] / 1363px [Height]</p>

            )}

            <div>
              <FormControlLabel
                style={{marginTop: -17, marginBottom: 10}}
                control={
                  <Switch
                    checked={isImgFullHeight}
                    onChange={() => dispatch(postToggleFullHeight())}
                    name="toggle_testing_state"
                    color="primary"
                  />
                }
                label={isImgFullHeight ? 'Full Height Enabled' : 'Full Height Disabled'}
              />
              </div>
            <Button  onClick={() => dispatch(setMainImageModalOpen(true))} size="small" variant="outlined">Update Main Image</Button>

          </CardContent>
        ) : (null)}

        </Card>
      </Grid>
      <Grid item
            md={12}>
        <Card className={classes.root}>
        <ImageLoader skeleton={<Skeleton variant="rect" style={{height: 400}} />} style={{width: '100%'}} src={ 'https://api.bigkiosksolution.com' + bannerImgUrl } alt="content image" />
          {user?.shop?.type != 'franchisee' ? (
          <CardContent>
            <h3>Kiosk Menu Banner Image</h3>
            <p style={{position: 'relative', bottom: 10}}>Image Size Recommendation: 1080px [Width] / 486px [Height]</p>
            <Button  onClick={() => dispatch(setAddImageModalOpen(true))} size="small" variant="outlined">Update Banner Image</Button>
          </CardContent>) : (null)}
        </Card>
      </Grid>
    </Grid>
    <AddImage />
    <AddMainImage />
    </React.Fragment>
    )
}

export default Settings;
