import React, {useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { setAddCouponModalOpen, setOrderModalOpen, setAddCategoryModalOpen, setAddItemModalOpen, setAddOptionModalOpen } from '../../features/modal/modalSlice';
import { selectTitle, selectButtonTitle } from '../../features/header/headerSlice';
import { deleteAllCart } from '../../features/cart/cartSlice';
import { setIsUpdateModal } from '../../features/itemModal/itemModalSlice';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginBottom: 50,
    width: `calc(96%)`,
    background: 'white',
    padding: 15,
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 30,
    paddingBottom: 30,
    marginTop: 12,
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 25,
      paddingRight: 25,
      width: `calc(100%)`,


     },
    backgroundColor: 'white',
    boxShadow: '0 0.09rem .13rem rgba(0,0,0,.075)'
  },

  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
    color: '#26496e'
  },
  label: {
    marginTop: -25,
    paddingTop: -1000,
  },
  floatBtn: {
    marginTop: 0,
    float: 'right'
  }
}));

const ContentHeader = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const title = useSelector(selectTitle);
  const user = useSelector(state => state.auth.user);

  const buttonTitle = useSelector(selectButtonTitle);

  let modalButton = null;
  if (title === 'Category') {
    modalButton = <Button className={classes.floatBtn}  onClick={() => dispatch(setAddCategoryModalOpen(true))} variant="contained" color="primary">
                    {buttonTitle}
                  </Button>

    if (user?.shop?.type == 'franchisee' && !user?.shop?.add_category_allowed) {
      modalButton = null;
    }
  } else if (title === 'Item') {
    modalButton = <Button className={classes.floatBtn}  onClick={() => dispatch(setAddItemModalOpen(true))} variant="contained" color="primary">
                    {buttonTitle}
                  </Button>
    if (user?.shop?.type == 'franchisee' && !user?.shop?.add_item_allowed) {
      modalButton = null;
    }
  } else if (title === 'Add-on') {
    modalButton = <Button className={classes.floatBtn}  onClick={() => dispatch(setAddOptionModalOpen(true))} variant="contained" color="primary">
                    {buttonTitle}
                  </Button>
    if (user?.shop?.type == 'franchisee' && !user?.shop?.add_addon_allowed) {
      modalButton = null;
    }
  } else if (title === 'Sales') {
      modalButton = <Button className={classes.floatBtn}  onClick={() => {dispatch(setIsUpdateModal(false));dispatch(deleteAllCart()); dispatch(setOrderModalOpen(true));}} variant="contained" color="primary">
                      {buttonTitle}
                    </Button>
  } else if (title === 'Coupons') {
    modalButton = <Button className={classes.floatBtn}  onClick={() => dispatch(setAddCouponModalOpen(true))} variant="contained" color="primary">
                    {buttonTitle}
                  </Button>
  } else if (props.disableBtn === true) {
    modalButton = null;
  }


  return (
    <Grid
    className={classes.root}
      container
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      <Grid>
        <Typography className={classes.subcategoryHeader} style={{color: 'black'}} variant="h6">
          {props.title} <span style={{color: 'grey'}}>{props.beta ? 'BETA' : ''}</span>
        </Typography>
      </Grid>
      <Grid>
        {modalButton}
      </Grid>
    </Grid>  );
}

export default ContentHeader;
