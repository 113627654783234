import React from 'react';
import Payout from '../../features/order/Payout';


const PayoutComponent = () => {
  return (
      <Payout />
  );
};

export default PayoutComponent;
