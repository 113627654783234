import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import { useStopwatch } from 'react-timer-hook';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { updateTicketStatus, hideTicket, clearApprovalTicket, updateItemDone, updateOrderHidden } from './kitchenSlice';
import { sendSMSPickUp } from '../order/orderSlice';
import { useDispatch, useSelector } from 'react-redux';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DoneIcon from '@material-ui/icons/Done';
import SmsIcon from '@material-ui/icons/Sms';
import SendIcon from '@material-ui/icons/Send';
import Popover from '@material-ui/core/Popover';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { IconButton } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 7
  },
  smallBtn: {
    fontSize: 11,
    position: 'relative',
    bottom: 1.4,
    paddingTop:0,
    paddingBottom:0,
    backgroundColor: '#fb8d78'
  }
}));

const FilteredItems = (props) => {
  const dispatch = useDispatch()
  const simplified = useSelector(state => state.auth.user?.shop?.kitchen_ticket_simplified ?? false);

  let renderItems = []
  const ticket = props.ticket;
  const station = props.station;
  const valueStation = props.valueStation;
  ticket.order_items.map(item => {
    station.items.map(stationItem => {
      if (item.item !== null && stationItem !== null) {
        if (stationItem.id === item.item.id) {
          renderItems.push(item);
        }
      }
    })
  })

  return valueStation === 'all' ? (
    ticket.order_items.map(item =>
      <ListItem onClick={() => {
        props.onItemUpdate({order_id: ticket.id, order_item_id: item.id, status: !item.done});
        dispatch(updateItemDone({done: !item.done, itemId: item.id, ticketId: props.ticket.id}))
      }} style={{display: 'block'}} key={item.id}>{item.item ? <b><Chip size="small" color="primary" style={{position: 'relative', borderRadius:2, bottom: 0, marginRight: 5, minWidth: 20}} label={<h3>{item.quantity}</h3>} />{item.done ? <strike>{item.item.name}</strike> : item.item.name}</b> : <b  style={{margin:0, padding:0}}>-</b>}
        <List style={{marginTop: 0}} dense  component="span" disablePadding>
          {item.done ? <DoneIcon color="primary" /> : null}
          {item.order_item_options.map(option => <ListItem style={{display: 'block'}} button key={option.id}>{simplified ? option.name : option.option_str}</ListItem>)}
        </List>
      </ListItem>
    )
  ) : (
   renderItems.map(item =>
      <ListItem
        onClick={() => {
          props.onItemUpdate({order_id: ticket.id, order_item_id: item.id, status: !item.done});
          dispatch(updateItemDone({done: !item.done, itemId: item.id, ticketId: props.ticket.id}))
        }}
        style={{display: 'block'}} key={item.id}>{item.item ? <b><Chip size="small" color="primary" style={{position: 'relative', borderRadius:2, bottom: 0, marginRight: 5, minWidth: 20}} label={<h3>{item.quantity}</h3>} />{item.done ? <strike>{item.item.name}</strike> : item.item.name}</b> : <b  style={{margin:0, padding:0}}>-</b>}
        <List style={{marginTop: 0}} dense  component="span" disablePadding>
        {item.done ? <DoneIcon color="primary" /> : null}
          {item.order_item_options.map(option => <ListItem style={{display: 'block'}} button key={option.id}>{simplified ? option.name : option.option_str}</ListItem>)}
        </List>
      </ListItem>
    )
  )
}


const Ticket = (props) => {
  const dispatch = useDispatch();
  const status = useSelector(state => state.kitchen.status);
  const orderStatus = useSelector(state => state.order.status);
  const approvalTicket = useSelector(state => state.kitchen.approvalTicket);

  const date = new Date(props.datetime);
  const secondsOffset = Math.floor((new Date() - date) / 1000);
  let stopwatchOffset = new Date();
  stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + secondsOffset)
  const classes = useStyles();
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: true, offsetTimestamp: stopwatchOffset});
  const [sendSms, setSendSms] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [sendSmsLoading, setSendSmsLoading] = useState(false);
  const [sendSmsLabel, setSendSmsLabel] = useState('Send Pickup Text')



  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);



  return (
    <Card
      style={{border: '3px solid orange', marginRight: 10, marginBottom: 10, width: 250}}
      className={classes.root}>
    <CardContent style={{paddingBottom: 0}}>
    <Grid
      style={{padding:0, borderBottom: '1px solid #c9c9c9'}}
      container
      direction="row"
      justifyContent="space-between"
    >
        <>
        <Grid item xs={5}>
        <Typography className={classes.title} color="textPrimary" gutterBottom>
        !
        </Typography>
        </Grid>
        <Grid item xs={6}>
            <span style={{float: 'right'}}>
            {minutes > 0 ? String(minutes).padStart(2, '0') : '00'}:{String(seconds).padStart(2, '0')}
          </span> 
        </Grid>
        
        </>
    </Grid>
    </CardContent>
     <CardContent style={{maxHeight: '60vh', overflowY: 'scroll', paddingTop: 5, paddingLeft: 0, paddingRight:0, marginBottom: 0, paddingBottom: 0}}>
        <h1 style={{textAlign: 'center', fontSize: 16}}>APPROVAL NEEDED</h1>
        <p style={{textAlign: 'center'}}>
            Customer requested approval for: 
            <p style={{display: 'inline-block', marginTop: 5, marginBottom: 0, background: 'rgba(0,0,0,0.07)', borderRadius: 4, paddingLeft: 7, paddingRight: 7}}>{approvalTicket.name}</p>
        </p>
     </CardContent>
     <CardActions style={{marginTop:0, paddingTop: 10, paddingLeft:14, paddingRight: 14}}>
     <Button
        disableElevation
        disableRipple
        onClick={() => { props.onApprove();dispatch(clearApprovalTicket()); }}
        fullWidth color="primary" variant="contained" elevation={0} size="medium"
       >
        APPROVE
        </Button>
     </CardActions>
     <CardActions style={{marginTop:0, paddingTop: 0, paddingLeft:14, paddingRight: 14}}>
       <Button
        disableElevation
        disableRipple
        onClick={() => {props.onDisapprove(); dispatch(clearApprovalTicket());}}
        fullWidth color="secondary" variant="contained" elevation={0} size="medium"
       >
        DISAPPROVE
       </Button>
     </CardActions>
   </Card>
  )
}

export default Ticket;
