import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { useSelector, useDispatch } from 'react-redux';
import { postTableCanvas, fetchShapes, fetchTableCanvas, fetchTables, updatePosition } from '../tableSlice'
import Shape from '../TableCanvas/Shape'
import TableItem from '../TableItem'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'absolute',
    marginTop: 6,
    padding:0,
    marginBottom:30,
    height: '1040px',
    overflowY: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
     },
  },
  canvas: {
    marginTop: -25,
    marginLeft: 10,
    overflow:'hidden',
    height: '1001px',
    width: '902px',
    border: '5px dotted #a7a9ab',
    borderRadius: 5,
  }
}));

const TabelItemDialog = (props) => {
  const { handleClose, ...restProps } = props;

  return props.open ? (
    <Dialog onClose={props.handleClose} open={props.open}>
    <div style={{width: 350}}>
      <TableItem {...restProps} { ...props.table } />
      </div>
    </Dialog>
  ) : (
    null
  )
}

const CanvasView = (props) => {
  const dispatch = useDispatch();
  const shapes = useSelector(state => state.table.shapes)
  const tables = useSelector(state => state.table.tables)
  const [open, setOpen] = useState(null)
  const [zoomInterval, setZoomInterval] = useState(null);
  const [scale, setScale] = useState(() => {
    const savedScale = localStorage.getItem('canvasZoomScale');
    return savedScale ? parseFloat(savedScale) : 1;
  });
  const classes = useStyles();

  const handleZoomOut = () => {
    setScale(prevScale => {
      const newScale = prevScale * 0.98;

      return newScale;
    });
  };

  const handleZoomIn = () => {
    setScale(prevScale => {
      const newScale = prevScale * 1.02;

      return newScale;
    });
  };

  const startZoomingOut = () => {
    setZoomInterval(setInterval(handleZoomOut, 50));  // adjust the interval as needed
  };

  const startZoomingIn = () => {
    setZoomInterval(setInterval(handleZoomIn, 50));  // adjust the interval as needed
  };

  const stopZooming = () => {
      if (zoomInterval) {
          clearInterval(zoomInterval);
          setZoomInterval(null);
          // Save the scale to localStorage once zooming stops
          if (scale === 1) {
              localStorage.removeItem('canvasZoomScale');
          } else {
              localStorage.setItem('canvasZoomScale', scale);
          }
      }
  };


  useEffect(() => {
      //dispatch(postTableCanvas())
      dispatch(fetchTableCanvas())
      //dispatch(fetchTables())
  }, [])

  const handleClose = () => {
    setOpen(false);
  }

  const getTableById = (id) => {
    const index = tables.findIndex(table => table.id === id);
    if (index !== -1) {
      return tables[index]
    }
    return null
  }

  // Inline styles for the zoomable container.
  const zoomStyles = {
      transform: `scale(${scale})`,
      transition: 'transform 0.3s ease',
      transformOrigin: '0% 0%'  // top-left corner
  };

  const noUserSelect = {
    userSelect: 'none',          // standard syntax
    WebkitUserSelect: 'none',    // Safari and Chrome
    MozUserSelect: 'none',       // Firefox
    msUserSelect: 'none'         // Internet Explorer/Edge
  };

  return (
    <Paper className={classes.root}  style={{...noUserSelect}}  square>
    <div style={{position: 'relative', top: 30, left: 30, zIndex:999}}>
    <Button
        style={{marginRight: 10, background: 'white'}}
        variant="outlined"
        disableElevation
        onMouseDown={startZoomingOut}
        onMouseUp={stopZooming}
        onMouseLeave={stopZooming}
        onTouchStart={startZoomingOut} // Added for touch support
        onTouchEnd={stopZooming}       // Added for touch support
        onTouchCancel={stopZooming}   // Added for touch support
    >
        <ZoomOutIcon />
    </Button>

    <Button
      style={{background: 'white'}}
        variant="outlined"
        disableElevation
        onMouseDown={startZoomingIn}
        onMouseUp={stopZooming}
        onMouseLeave={stopZooming}
        onTouchStart={startZoomingIn}  // Added for touch support
        onTouchEnd={stopZooming}       // Added for touch support
        onTouchCancel={stopZooming}    // Added for touch support
    >
        <ZoomInIcon />
    </Button>
    </div>
    <div className={classes.canvas} style={zoomStyles}>
        {shapes?.map(shape => {
          const table = getTableById(shape.table?.id)
          return <React.Fragment>
                  <Shape onMouseClick={() => setOpen(shape.id)} table={table} manager key={shape.id} width={shape.width}
                  height={shape.height}
                  degree={shape.degree}
                  text={shape.text}
                  size={shape.size}
                  x={shape.x}
                  y={shape.y}
                  type={shape.type}
                  id={shape.id} />
                  {shape.table ? <TabelItemDialog {...props} table={table} open={open === shape.id ? true : false} handleClose={handleClose} /> : null}
                </React.Fragment>
      })}
    </div>
    </Paper>
  )
}

export default CanvasView;
