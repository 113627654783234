import React, { useEffect, useState, useRef } from 'react';
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  ListItemAvatar,
  ListItemSecondaryAction
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import ItemModal from '../itemModal/itemModal';
import {CheckOutFormWrapper } from '../paymentProcessing/CheckOutForm';
import CheckOutCardPresent from '../paymentProcessing/CheckOutCardPresent';
import { selectOrderModalOpen, setOrderModalOpen } from './modalSlice';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCategories, fetchCategoryImages } from '../category/categorySlice';
import { setOpenCheckOutForm } from '../paymentProcessing/paymentProcessingSlice';
import { fetchItemsByCategoryId } from '../item/itemSlice';
import { updateOrder, postOrderFromOrderModal, getTaxRate, clearOrderDetail, updateOrderStatus } from '../order/orderSlice';
import { getSalesTotal, getSalesSubtotal, getSurchargeFee, getSurcharge, getSalesTax } from '../order/utils/util-fns';
import { deleteAllCart } from '../cart/cartSlice';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Chip from '@material-ui/core/Chip';
import { resetAll, setOrderId, setModalOpen, setItem, setPriceSummary, setIsUpdateModal } from '../itemModal/itemModalSlice';
import currency from 'currency.js'
import Skeleton from '@material-ui/lab/Skeleton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';

const options = {
  mode: 'payment',
  currency: 'usd',
  // Fully customizable with appearance API.
  appearance: {
    /*...*/
  },
};


const useStyles = makeStyles((theme) => ({
  appBar: {
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30,

    color: 'black',
    position: 'relative',
    backgroundColor: 'rgba(0,0,0,0.01)',
    borderBottom: '1px solid #e0e0e0',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.0)'
  },
  title: {
    flex: 1,
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 64px)',
    overflow: 'hidden'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: 'calc(100vh)',
    overflow: 'hidden', // Add this line
  },
  content: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(4),
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '100%',
    justifyContent: 'space-between'
  },
  contentCard: {
    marginBottom: theme.spacing(2),

    width: '100%',
  },
  navBar: {
    paddingLeft: 20,
    paddingRight: 20,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: 0,

    },
    marginTop: 0,
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    overflowY: 'auto', // Add this line
    height: '100%', // Add this line
    maxHeight: 'calc(100vh)', // Add this line
    '&::-webkit-scrollbar': {
      width: 0,
    },
    scrollbarWidth: 'none', // For Firefox
  },
  bottomSection: {
    padding: theme.spacing(1),
    backgroundColor: '#fdfdfd',
    borderTop: '2px solid #e6e6e6',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap', // make sure items do not wrap
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 10,
    overflowX: 'auto' // add scrollbar when necessary
  },
  cartSection: {
    padding: theme.spacing(1),
    backgroundColor: '#fdfdfd',
    borderTop: '2px solid #e6e6e6',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap', // make sure items do not wrap
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    gap: 10,
    overflowX: 'auto' // add scrollbar when necessary
  },
  bottomBarContainer: {
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000, /* Adjust this value based on your needs */
  },
  card: {
    margin: theme.spacing(0),
    padding: theme.spacing(0),
  },
  actions: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(0,0,0,0.01)',
    borderTop: '1px solid #e0e0e0',
    borderTopLeftRadius: 30,
    
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    height: 70,
  },
  navListItemText: {
    padding: 0,
    margin: 0,
    paddingTop: 6,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    color: '#373737',
    fontSize: 15,
    fontWeight: 450,
    lineHeight: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
  },


  circleThumbnail: {
    [theme.breakpoints.down('sm')]: {
      width: 70,
      height: 70,
    },
    width: 100,
    height: 100,
    borderRadius: '50%',
    marginBottom: theme.spacing(0),
    marginRight: theme.spacing(0),
    padding: 0,
    margin: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fcfdff',
    backgroundImage: 'linear-gradient(164deg, #fcfdff 0%, #d8e1e6 100%)',
    overflow: 'hidden',

  },

  circleThumbnailActive: {
    [theme.breakpoints.down('sm')]: {
      width: 80,
      height: 80,
    },
    width: 100,
    height: 100,
    borderRadius: '50%',
    marginBottom: theme.spacing(0),
    marginRight: theme.spacing(0),
    padding: 0,
    margin: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fcfdff',
    backgroundImage: 'linear-gradient(164deg, #fcfdff 0%, #a8e1e6 100%)',
    overflow: 'hidden',
  },
  thumbnailImage: {
    position: 'relative',
    top: 10,
    maxWidth: '120%',
    maxHeight: '120%',
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    height: 'auto',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 5,
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  skeleton: {
    margin: theme.spacing(0.5),
    marginBottom: 50,
    height: 119,
    borderRadius: 2

  },
  cardItem: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0.5),
    },
    margin: theme.spacing(0.9),
    padding: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.0)', // Add a subtle boxShadow
    justifyContent: 'center',
    '& > :last-child': {
      paddingBottom: 0,
      marginBottom: 0,
    },
  },
  image: {
    borderRadius: 2,
    width: '100%',
    height: '120px',
    objectFit: 'contain',
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: '#fcfdff',
    backgroundImage: 'linear-gradient(164deg, #fcfdff 0%, #e6e6e6 100%)',
  },
  cardContent: {
    minWidth: 'fit-content', // or minWidth: 0 depending on your specific needs
    padding: theme.spacing(0.5),
    paddingTop: 10,
    paddingBottom: 0, // Set paddingBottom to 0
    textAlign: 'center',
  },
  cardTitle: {
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
      marginTop: -3
    },
    fontSize: 15,
    lineHeight: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'wrap',
    maxWidth: '100%',
    margin: theme.spacing(0.9),
    color: '#373737',
    marginTop: 0,
    marginBottom: 0
  },
  cardPrice: {
    fontWeight: 400,
    fontSize: 12,
    marginTop: theme.spacing(0),
    marginBottom: 0,
    paddingBottom: 0,
  },
  categoryTitle: {
    background: 'white',
    marginBottom: 7,
    fontSize: 20,
    marginTop: 0,
    paddingLeft: 5,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
      marginBottom: 5
    },
  },

}));



const OrderModal2 = (props) => {
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

  const containerClass = isIOS ? 'bottom-bar-container iphone-safe-area' : 'bottom-bar-container';
  const openCheckOutForm = useSelector(state => state.paymentProcessing.openCheckOutForm);
  const transactionFee = useSelector(state => state.auth?.user?.shop?.transaction_fee)
  const chargeFee = useSelector(state => state.auth?.user?.shop?.charge_customers_fee)
  const open = useSelector(selectOrderModalOpen);
  const categories = useSelector(state => state.category.categories)
  const cartItems = useSelector(state => state.cart.items)
  const items = useSelector(state => state.item.itemsAlt)
  const status = useSelector(state => state.item.status)
  const orderId = useSelector(state => state.itemModal.orderId)
  const orderDetail = useSelector(state => state.order.orderDetail)
  const clientSecretFromPmt = useSelector(state => state.paymentProcessing.secret ?? null);
  const intentIdFromPmt = useSelector(state => state.paymentProcessing.intentId ?? null);
  const identifier = localStorage.getItem('identifier')

  const taxRate = useSelector(state => state.auth.user?.shop?.tax_rate)
  const isUpdateModal = useSelector(state => state.itemModal.isUpdateModal)
  const categoryImages = useSelector(state => state.category.categoryImages)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openCheckOutCardPresent, setOpenCheckOutCardPresent] = React.useState(false);

  const [total, setTotal] = React.useState(0);
  const [tax, setTax] = React.useState(0);
  const [transFee, setTransFee] = React.useState(0);
  const [subtotal, setSubtotal] = React.useState(0);
  const [tip, setTip] = React.useState(0);

  const [surcharge, setSurcharge] = React.useState(0);
  const [disableAddOrder, setDisableAddOrder] = React.useState(false);

  const [value, setValue] = React.useState(0);
  const [toGo, setToGo] = React.useState(
    isUpdateModal ? orderDetail?.to_go ?? true : false
  );
  const [note, setNote] = React.useState(
    isUpdateModal ? orderDetail?.note ?? orderDetail?.note : ''
  );
  const [phone, setPhone] = React.useState(
    isUpdateModal ? orderDetail?.phone ?? orderDetail?.phone : ''
  );

  const [error, setError] = useState(false); // Track whether there's an error

  const validatePhone = (value) => {
    // Validate that the phone number is exactly 10 digits
    setError(value.length !== 10);
  };

  const [messageDialogOpen, setMessageDialogOpen] = useState(false);

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();

  const classes = useStyles();

  const getCategoryImageUrl = (categoryImageId) => {
    const index = categoryImages.findIndex(image => image.id === categoryImageId);
    if (index !== -1) {
      return categoryImages[index]?.category_image;
    }

    return null;
  }

  const handleItemClick = (item, idx) => {
    dispatch(resetAll());
    dispatch(setModalOpen({ modalOpen: true, modalId: idx, isCartItem: false }));
    dispatch(setItem(item));
  }

  const handleCartItemClick = (item, idx) => {
    dispatch(setModalOpen({ modalOpen: true, modalId: idx, isCartItem: true, cartIdx: idx }));
    dispatch(setItem(item.modalItemData.item));
    dispatch(setPriceSummary(item.modalItemData.priceSummary));
  }


  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  
  useEffect(() => {
    dispatch(fetchCategories())
    dispatch(fetchCategoryImages());

  }, [open])

  useEffect(() => {
    if (categories.length > 0 && open) {
      dispatch(fetchItemsByCategoryId(categories[value].id))
    }
  }, [categories, value, open])


  useEffect(() => {
    if (taxRate === null) {
      dispatch(getTaxRate());
    }
    setTotal(getSalesTotal(cartItems, taxRate))
    setSubtotal(getSalesSubtotal(cartItems))
    cartItems?.length > 0 ? setSurcharge(getSurchargeFee(total, transactionFee)) : setSurcharge(0)
    setTax(getSalesTax(cartItems, taxRate));
  });

  return (
    <>
      <Dialog fullScreen open={open}>
        <AppBar className={classes.appBar}>

          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Order                      {identifier && <Chip style={{marginLeft: 5,borderRadius: 2}} size="small" label={identifier} />} 
            </Typography>
         
            <IconButton variant="outlined" onClick={() => { dispatch(clearOrderDetail());dispatch(setIsUpdateModal(false)); dispatch(setOrderModalOpen(false)); dispatch(deleteAllCart()); dispatch(setOrderId(-1)); dispatch(resetAll()) }} edge="end" color="inherit">
              <CloseIcon />
            </IconButton>
          </Toolbar>

        </AppBar>
        <div className={classes.mainContainer}>
          <div className={classes.contentContainer}>
            <Grid container style={{ paddingLeft: 5, paddingRight: 0 }}>
              <Grid item xl={1} lg={2} md={2} sm={2} xs={3} className={classes.navBar}>
                <List>
                  {categories?.map((item, index) => (
                    <ListItem
                      disableRipple
                      onClick={() => handleChange(index)}
                      className={classes.listItem}
                      key={item.id}
                    >
                      <div className={value === index ? classes.circleThumbnailActive : classes.circleThumbnail}>
                        <img
                          src={getCategoryImageUrl(item.category_image)}
                          alt={item.name}
                          className={classes.thumbnailImage}
                        />
                      </div>
                      <ListItemText
                        primary={item.name}
                        classes={{ primary: classes.navListItemText }}
                        primaryTypographyProps={{ noWrap: false }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Grid className={classes.content} item alignItems="flex-start" justifyContent="flex-start" container md={6} sm={6} xs={9} lg={7}>
                {/* Add your content here */}
                <Grid item container xs={12} style={{ paddingTop: 0, paddingLeft: 0 }}>
                  <Grid item xs={12}>
                    <h1 className={classes.categoryTitle}>{categories[value]?.name}</h1>
                  </Grid>
                  {/* Add your content here */}
                  {status === 'fetch item loading' ? (<React.Fragment>
                    <Grid item xs={6} md={2}>
                      <Skeleton className={classes.skeleton} animation="wave" variant="rect" />
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <Skeleton className={classes.skeleton} animation="wave" variant="rect" />
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <Skeleton className={classes.skeleton} animation="wave" variant="rect" />
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <Skeleton className={classes.skeleton} animation="wave" variant="rect" />
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <Skeleton className={classes.skeleton} animation="wave" variant="rect" />
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <Skeleton className={classes.skeleton} animation="wave" variant="rect" />
                    </Grid>
                  </React.Fragment>) : items.map((item, idx) => {
                    return <Grid style={{ margin: 0, padding: 0 }} key={item.id} onClick={() => handleItemClick(item, idx)} item xs={6} md={3}>
                      <Card className={classes.cardItem}>
                        <img
                          className={classes.image}
                          src={item?.item_image?.item_image}
                          alt="food"
                        />
                        <CardContent className={classes.cardContent}>
                          <Typography
                            variant="subtitle1"
                            className={classes.cardTitle}
                          >
                            {item.name}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            className={classes.cardPrice}
                          >
                            {currency(item.unit_price).format()}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  })}
                  {/* Add more grid items here */}
                </Grid>
              </Grid>
              <Grid direction="column" item container md={4} lg={3} sm={4} style={{ position: 'relative', maxHeight: '100%' }}>
                <div style={{ height: '100%',  borderLeft: '1px solid rgba(0,0,0,0.2' }}>
                  <List divider style={{ height:'80%', padding:0, paddingBottom: 100, margin: 0, overflowY: 'auto',}}>
                  {cartItems.map((cart, idx) => (
                    <ListItem divider  onClick={() => handleCartItemClick(cart, idx)} key={cart.idx}>
                       <ListItemAvatar>
                      <Chip style={{borderRadius: 3}} size="small" label={cart.quantity} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={cart.name}
                      />
                      <ListItemSecondaryAction>
                        {currency(cart.quantity * cart.unit_price).format()}
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
                </div>
                {/* Fixed bottom bar */}
                <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, backgroundColor: '#FFF', borderTop: '1px solid rgba(0,0,0,0.12)', boxSizing: 'border-box', zIndex: 1000 }}>
                  <div style={{display: 'flex', justifyContent: 'center', flexDirection:'row', height: 145, padding: 10, borderLeft: '1px solid rgba(0,0,0,0.12)'}}>

                    <div style={{flex: 1, flexDirection: 'column'}}>
                      <div style={{display: 'flex', justifyContent: 'center', flexDirection:'row', marginBottom: 5,borderBottom: '1px dotted rgba(0,0,0,0.09)'  }}>
                        <div style={{flex: 1}}>
                          SUBTOTAL
                        </div>
                        <div style={{flex: 1, textAlign: 'right'}}>
                          {currency(subtotal).format()}
                        </div>  
                      </div>
                      <div style={{display: 'flex', justifyContent: 'center', flexDirection:'row', marginBottom: 5, borderBottom: '1px dotted rgba(0,0,0,0.09)'}}>
                        <div style={{flex: 1}}>
                          TAX
                        </div>
                        <div style={{flex: 1, textAlign: 'right'}}>
                        {currency(tax).format()}

                        </div>
                        
                      </div>
                      {chargeFee && <div style={{display: 'flex', justifyContent: 'center', flexDirection:'row', marginBottom: 5,borderBottom: '1px dotted rgba(0,0,0,0.09)'}}>
                        <div style={{flex: 1}}>
                          TRANS. FEE
                        </div>
                        <div style={{flex: 1, textAlign: 'right'}}>
                          {total > 0 ? currency(getSurcharge(total+tip, transactionFee) - total - tip).format() : '$0.00'}
                        </div>
                        
                      </div>}


                      {tip > -1 && <div style={{display: 'flex', justifyContent: 'center', flexDirection:'row', marginBottom: 5,borderBottom: '1px dotted rgba(0,0,0,0.09)'}}>
                        <div style={{flex: 1}}>
                          TIP
                        </div>
                        <div style={{flex: 1, textAlign: 'right'}}>
                           {currency(tip).format()}
                        </div>
                        
                      </div>}


                      <div style={{fontSize: 16,fontWeight: 500, display: 'flex', justifyContent: 'center', flexDirection:'row', }}>
                        <div style={{flex: 1}}>
                          TOTAL
                        </div>
                        <div style={{flex: 1, textAlign: 'right'}}>
                          {chargeFee ? total > 0 ? currency(getSurcharge(total+tip, transactionFee)).format() : '$0.00' :  currency(total+tip).format()}
                        </div>
                        
                      </div>         
                    </div>
                  </div>
                  {/* orderId == -1 means order hasn't been added */}
                  {/* Order hasn't been added or payment intent exists, but hasn't paid yet */}
                  {!clientSecretFromPmt && ((orderId != -1 && !orderDetail) || (orderId != -1 && orderDetail && !orderDetail?.charges?.data[0] || orderDetail?.charges?.data[0]?.amount_captured === 0)) ? 
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                      <Button 
                        style={{marginBottom: 3, marginRight: 3}} 
                        disableElevation 
                        variant="contained" 
                        fullWidth 
                        onClick={() => setOpenCheckOutCardPresent(true)}>
                          CARD PRESENT
                        </Button>
                        <Button 
                          style={{marginBottom: 3}} 
                          disableElevation 
                          variant="contained" 
                          fullWidth 
                          onClick={() => dispatch(setOpenCheckOutForm(true))}>
                            CARD MANUAL
                          </Button>
                    </div> : null}
                  {cartItems && cartItems.length > 0 ? (
                    isUpdateModal ? (
                      <Button  style={{height: 50}} onClick={
                        async () => {
                          await dispatch(updateOrder({
                            note: note,
                            phone: phone,
                            cart: cartItems,
                            id: orderId,
                            to_go: toGo
                          }));
                          await dispatch(setOrderModalOpen(false));
                        }} size="large" fullWidth color="primary" variant="contained">SAVE ORDER</Button>
                    ) : (
                      <Button disabled={disableAddOrder} style={{height: 50}} onClick={
                        async () =>{
                          setDisableAddOrder(true)
                          await dispatch(postOrderFromOrderModal({
                            identifier: identifier,
                            status: 'draft',
                            phone: phone,
                            tip: 0,
                            cart: cartItems,
                            toGo: toGo,
                            note: note,
                            tableInstanceId: null,
                            stripePaymentIntentId: null
                          })).then(data => {
                            const _data = data.payload
                            dispatch(setOrderId(_data.id))
                          })
                          setTimeout(async () => {
                            await dispatch(setIsUpdateModal(true));
                            setDisableAddOrder(false)

                          }, 5000)
                        }} size="large" fullWidth color="primary" variant="contained">ADD ORDER</Button>
                    )
                  ) : (
                    <Button style={{height: 50}} disabled size="large" fullWidth color="primary" variant="contained">{isUpdateModal ? 'SAVE ORDER' : 'ADD ORDER'}</Button>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
            <div className={classes.actions}>

            <div style={{ float: 'right', position: 'relative', top: 0, marginLeft: 15, marginRight: 10 }}>
              <TextField   variant="outlined"  size="small" style={{marginRight: 10, backgroundColor: 'white'}} InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CreateIcon style={{color: 'rgba(0,0,0,0.5)', fontSize: 21, position: 'relative', bottom: 1}} />
                    </InputAdornment>
                  ),
                }} value={note} onChange={(e) => setNote(e.target.value)}  />
                <TextField type='tel' style={{backgroundColor: 'white'}}    error={error} // Apply the error state
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow only digit characters and limit to 10 digits
                    const formattedValue = value.replace(/[^\d]/g, '').slice(0, 10);
                    setPhone(formattedValue);
                    validatePhone(formattedValue)
                  }}
                   variant="outlined" InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIphoneIcon style={{color: 'rgba(0,0,0,0.5)', fontSize: 21, position: 'relative', bottom: 1}} />
                    </InputAdornment>
                  ),
                }} 
                size="small" value={phone} />
            </div>
            <ButtonGroup style={{ marginRight: 10 }}>
              {toGo ? (
                <>
                  <Button disableElevation size="small" onClick={() => setToGo(true)} variant="contained" style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: 0, paddingTop: 7, paddingBottom: 7 }} color="primary">To Go</Button>
                  <Button disableElevation size="small" onClick={() => setToGo(false)} variant="contained" style={{ fontWeight: 300, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, color: 'grey' }}>Dine in</Button>
                </>
              ) : (
                <>
                  <Button disableElevation size="small" onClick={() => setToGo(true)} variant="contained" style={{ fontWeight: 300, borderRight: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, paddingTop: 7, paddingBottom: 7, color: 'grey' }}>To Go</Button>
                  <Button disableElevation size="small" onClick={() => setToGo(false)} variant="contained" color="primary" style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>Dine in</Button>
                </>
              )}
            </ButtonGroup>
            </div>
          </div>
      <MessageDialog open={messageDialogOpen} />
      </Dialog>
      <ItemModal />
        {openCheckOutForm ? <CheckOutFormWrapper 
          onSuccess={async () => {
            await dispatch(updateOrder({
              note: note,
              phone: phone,
              cart: cartItems,
              id: orderId,
              to_go: toGo
            }));
            // webhook doesn't handle Manual Card Payment, so manually change status to 'processing'
            await dispatch(updateOrderStatus({id: orderId, status: 'processing'}))
            setMessageDialogOpen(true)
            setTimeout(() => {
              setMessageDialogOpen(false)
              dispatch(setOrderModalOpen(false))
              dispatch(setOrderId(-1))
            }, 1000)
          }} 
          total={total} 
          orderId={orderId} 
        /> : null}
        {openCheckOutCardPresent ? 
        <CheckOutCardPresent
          transFee={surcharge}
          onTipUpdate={tip => setTip(tip)}
          onSuccess={() => {
            dispatch(updateOrder({
              note: note,
              phone: phone,
              cart: cartItems,
              id: orderId,
              to_go: toGo
            }));
            setMessageDialogOpen(true)
            setTimeout(() => {
              setMessageDialogOpen(false)
              setOpenCheckOutCardPresent(false)
              dispatch(setOrderModalOpen(false))
              dispatch(setOrderId(-1))
            }, 1000)
          }} 
         socket={props.socket} 
         open={openCheckOutCardPresent} 
         onClose={() => setOpenCheckOutCardPresent(false)} 
         orderId={orderId}  
         total={total} 
         intentId={intentIdFromPmt ? intentIdFromPmt : orderDetail?.id} /> : null}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackbar}
        autoHideDuration={2000}
        message={snackbarMessage}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  );
};

const MessageDialog = (props) => {

  return (
    <Dialog 
      fullWidth
      maxWidth="xs"
      open={props.open}>
        <div style={{padding: 20, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <CheckCircleIcon style={{color: 'green', fontSize: 80}} />
          <h1>Payment Received</h1>
        </div>
    </Dialog>
  )
}

export default OrderModal2;
