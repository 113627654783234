import React, {useState, useEffect} from 'react';
import { Button, Grid } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import Badge from '@material-ui/core/Badge';
import CheckIcon from '@material-ui/icons/Check';
import DoneIcon from '@material-ui/icons/Done';
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from '@material-ui/lab/Skeleton';
import ImageLoader from '../ImageLoader';

const useStyles = makeStyles(theme => ({
  customBadge: {
    backgroundColor: "#4ab7ce",
    background: "linear-gradient(to bottom,  rgba(135,224,253,1) 0%,rgba(83,203,241,1) 40%,rgba(5,171,224,1) 100%)"
  }
}));

const Subheader = (props) => {
  return (
    <p>{props.text}</p>
  );
}

const ClickButton = (props) => {
  const classes = useStyles();

  if (props.inactive === false) {
    return null;
  }

  if (props.addon) {
    const btn = props.selected ? <Badge classes={{ badge: classes.customBadge}}
                                        color="primary"
                                        badgeContent={
                                          <DoneIcon style={{color: 'white',
                                                            fontSize: '1.2em'}} />
                                        }
                                        overlap="circle">
                                  <Button  id='topping' style={{width: 65,
                                                               height: 65}}>
                                    <img src={'https://api.bigkiosksolution.com' + props.headerImg} alt="headerImg"/>
                                  </Button>
                                 </Badge>
                                 :
                                 <Button  id='topping' style={{width: 65,
                                                              height: 65}}>
                                   <img src={'https://api.bigkiosksolution.com' + props.headerImg} alt="headerImg"/>
                                 </Button>;
    return (
      <Grid onClick={props.onClick}
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            spacing={0}
            id="clickbuttonTopping"
            style={{width: 80}}>
        <Grid item>
          {btn}
        </Grid>
        <span style={{fontSize: 13, color: 'black'}}>{props.buttonDesc}</span>
        <small style={{position: 'relative', bottom: 5, fontSize: 12}}>{props.price}</small>

      </Grid>
      );
  } else if (props.active) {
    return (
      <div className="clickButton">
        <Button  style={{ background: '#edf0f0'}} className="category-btn-active">
        <Grid container
              direction="column-reverse"
              justifyContent="center"
              alignItems="center">
          {props.buttonDesc}
          <ImageLoader skeleton={<Skeleton variant="circle" style={{width: 85, height: 85, position: 'relative', top: 8, marginBottom: 16}}/>} src={props.headerImg}  alt="headerImg"/>
        </Grid>
        </Button>
      </div>
      );
    } else if (props.placeholder) {
      return (
        <div className="clickButton">
              <Grid container
                    direction="column-reverse"
                    justifyContent="center"
                    alignItems="center">
                {props.buttonDesc}
              </Grid>
        </div>
        );
    } else {
    return (
      <div className="clickButton">
        <Button >
        <Grid container
              direction="column-reverse"
              justifyContent="center"
              alignItems="center">
          {props.buttonDesc}
          <ImageLoader skeleton={<Skeleton variant="circle" style={{width: 85, height: 85, position: 'relative', top: 8, marginBottom: 16}}/>} src={props.headerImg}  alt="headerImg"/>
        </Grid>
        </Button>
      </div>
      );
    }
}

export default ClickButton;
