import React, { useState, setState, useEffect } from 'react';
import './App.css';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Header from '../components/layouts/Header.js'
import Admin from '../admin/Admin.js'
import Contract from '../contract/Contract.js'
import Content from '../components/layouts/Content.js'
import Sidebar from '../components/layouts/Sidebar.js'
import CategoryComponent from '../components/category/Category'
import KioskComponent from '../components/kiosk/Kiosk'
import SettingsComponent from '../components/kiosk/Settings'
import PayoutComponent from '../components/payout/Payout'
import AccountComponent from '../components/account/Account'
import KitchenComponent from '../components/kitchen/Kitchen'
import RewardsComponent from '../components/rewards/Rewards'
import CouponsComponent from '../components/coupons/Coupons'
import TableComponent from '../components/table/Table'
import TableManagerComponent from '../components/table/TableManager'
import OrdersComponent from '../components/sales/Orders'
import PageNotFound from '../components/pageNotFound/PageNotFound'
import SalesReportComponent from '../components/sales/SalesReport'
import ItemComponent from '../components/item/Item'
import Login from '../features/auth/Login'
import Logout from '../features/auth/Logout'
import Signup from '../features/auth/Signup'
import SignupCustom from '../features/auth/SignupCustom'
import SignupCustomAdditional from '../features/auth/SignupCustomAdditional'
import AddOnComponent from '../components/addon/AddOn'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { selectWidth } from '../features/drawer/drawerSlice';
import { selectRefreshToken, selectStatus, getSystemUser } from '../features/auth/authSlice';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  PrivateRoute
} from "react-router-dom";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';


function App(props) {

  function PrivateRoute({ children, ...rest }) {
    const status = useSelector(selectStatus);
    return (
      <Route {...rest} render={() => {
        const userLoggedIn = localStorage.getItem('refresh_token');
        return userLoggedIn != null || userLoggedIn && status !== 'unauthorized'
          ? children
          : <Redirect to='/logout' />
      }} />
    )
  }

  let drawerWidth = useSelector(selectWidth);

  const theme = createMuiTheme({
    typography: {
      fontFamily: 'Heebo, sans-serif',
    },
    palette: {
      background: {
        default: 'rgb(247, 249, 252)'
      },
      primary: {
        main: '#438abd'
      }
    }
  });

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Router>
      <Switch>
        <Route path="/signup">
          <Signup />
        </Route>
        <Route path="/signup_business">
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <SignupCustom />
          </MuiPickersUtilsProvider>
        </Route>
        <Route path="/signup_business_additional">
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <SignupCustomAdditional />
          </MuiPickersUtilsProvider>
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/logout">
          <Logout />
        </Route>
        <Route path="/contract">
          <Contract />
        </Route>
        <PrivateRoute path="/admin">
          <Admin />
        </PrivateRoute>
        <PrivateRoute path="/settings">
          <React.Fragment>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Container maxWidth={false} disableGutters>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={0}>
                  <Sidebar handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} drawerWidth={drawerWidth} />
                  <Header handleDrawerToggle={handleDrawerToggle} drawerWidth={drawerWidth} />
                  <Content title="Settings" drawerWidth={drawerWidth} >
                    <SettingsComponent />
                  </Content>
                </Grid>
              </Container>
            </ThemeProvider>
          </React.Fragment>
        </PrivateRoute>
        <PrivateRoute path="/kiosk">
          <React.Fragment>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Container maxWidth={false} disableGutters>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={0}>
                  <Sidebar handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} drawerWidth={drawerWidth} />
                  <Header handleDrawerToggle={handleDrawerToggle} drawerWidth={drawerWidth} />
                  <Content title="Kiosk" drawerWidth={drawerWidth} >
                    <KioskComponent />
                  </Content>
                </Grid>
              </Container>
            </ThemeProvider>
          </React.Fragment>
        </PrivateRoute>
        <PrivateRoute path="/category">
          <React.Fragment>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Container maxWidth={false} disableGutters>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="flex-start"
                  spacing={0}>
                  <Sidebar handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} drawerWidth={drawerWidth} />
                  <Header handleDrawerToggle={handleDrawerToggle} drawerWidth={drawerWidth} />
                  <Content title="Category" drawerWidth={drawerWidth} >
                    <CategoryComponent />
                  </Content>
                </Grid>
              </Container>
            </ThemeProvider>
          </React.Fragment>
        </PrivateRoute>
        <PrivateRoute path="/item">
          <React.Fragment>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Container maxWidth={false} disableGutters>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="flex-start"
                  spacing={0}>
                  <Sidebar handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} drawerWidth={drawerWidth} />
                  <Header handleDrawerToggle={handleDrawerToggle} drawerWidth={drawerWidth} />
                  <Content title="Item" drawerWidth={drawerWidth} >
                    <ItemComponent />
                  </Content>
                </Grid>
              </Container>
            </ThemeProvider>
          </React.Fragment>
        </PrivateRoute>
        <PrivateRoute path="/addon">
          <React.Fragment>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Container maxWidth={false} disableGutters>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="flex-start"
                  spacing={0}>
                  <Sidebar handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} drawerWidth={drawerWidth} />
                  <Header handleDrawerToggle={handleDrawerToggle} drawerWidth={drawerWidth} />
                  <Content title="Add-on" drawerWidth={drawerWidth} >
                    <AddOnComponent />
                  </Content>
                </Grid>
              </Container>
            </ThemeProvider>
          </React.Fragment>
        </PrivateRoute>
        <PrivateRoute path="/coupons">
          <React.Fragment>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Container maxWidth={false} disableGutters>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="flex-start"
                  spacing={0}>
                  <Sidebar handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} drawerWidth={drawerWidth} />
                  <Header handleDrawerToggle={handleDrawerToggle} drawerWidth={drawerWidth} />
                  <Content title="Coupons" drawerWidth={drawerWidth} >
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                      <CouponsComponent />
                    </MuiPickersUtilsProvider>
                  </Content>
                </Grid>
              </Container>
            </ThemeProvider>
          </React.Fragment>
        </PrivateRoute>
        <PrivateRoute path="/rewards">
          <React.Fragment>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Container maxWidth={false} disableGutters>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="flex-start"
                  spacing={0}>
                  <Sidebar handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} drawerWidth={drawerWidth} />
                  <Header handleDrawerToggle={handleDrawerToggle} drawerWidth={drawerWidth} />
                  <Content title="Rewards" drawerWidth={drawerWidth} >

                    <RewardsComponent />
                  </Content>
                </Grid>
              </Container>
            </ThemeProvider>
          </React.Fragment>
        </PrivateRoute>
        <PrivateRoute path="/kitchen">
          <React.Fragment>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Container maxWidth={false} disableGutters>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="flex-start"
                  spacing={0}>
                  <KitchenComponent />
                </Grid>
              </Container>
            </ThemeProvider>
          </React.Fragment>
        </PrivateRoute>
        <PrivateRoute path="/table">
          <React.Fragment>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Container maxWidth={false} disableGutters>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={0}>
                  <TableComponent />
                </Grid>
              </Container>
            </ThemeProvider>
          </React.Fragment>
        </PrivateRoute>
        <PrivateRoute path="/table-manager">
          <React.Fragment>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Container maxWidth={false} disableGutters>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="flex-start"
                  spacing={0}>
                  <Sidebar handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} drawerWidth={drawerWidth} />
                  <Header handleDrawerToggle={handleDrawerToggle} drawerWidth={drawerWidth} />
                  <Content title="Table Manager" disableHeader drawerWidth={drawerWidth} >
                    <TableManagerComponent />
                  </Content>
                </Grid>
              </Container>
            </ThemeProvider>
          </React.Fragment>
        </PrivateRoute>
        <PrivateRoute path="/sales">
          <React.Fragment>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Container maxWidth={false} disableGutters>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="flex-start"
                  spacing={0}>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                    spacing={0}>
                    <Sidebar handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} drawerWidth={drawerWidth} />
                    <Header handleDrawerToggle={handleDrawerToggle} drawerWidth={drawerWidth} />
                    <Content title="Sales" drawerWidth={drawerWidth} >
                      <OrdersComponent />
                    </Content>
                  </Grid>
                </Grid>
              </Container>
            </ThemeProvider>
          </React.Fragment>
        </PrivateRoute>
        <PrivateRoute path="/sales-report">
          <React.Fragment>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Container maxWidth={false} disableGutters>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="flex-start"
                  spacing={0}>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                    spacing={0}>
                    <Sidebar handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} drawerWidth={drawerWidth} />
                    <Header handleDrawerToggle={handleDrawerToggle} drawerWidth={drawerWidth} />
                    <Content title="Sales Report" drawerWidth={drawerWidth} >
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <SalesReportComponent />
                      </MuiPickersUtilsProvider>
                    </Content>
                  </Grid>
                </Grid>
              </Container>
            </ThemeProvider>
          </React.Fragment>
        </PrivateRoute>
        <PrivateRoute path="/account">
          <React.Fragment>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Container maxWidth={false} disableGutters>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="flex-start"
                  spacing={0}>
                  <Sidebar handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} drawerWidth={drawerWidth} />
                  <Header handleDrawerToggle={handleDrawerToggle} drawerWidth={drawerWidth} />
                  <Content disableBtn title="Account" drawerWidth={drawerWidth} >
                    <AccountComponent />
                  </Content>
                </Grid>
              </Container>
            </ThemeProvider>
          </React.Fragment>
        </PrivateRoute>
        <PrivateRoute path="/payout">
          <React.Fragment>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Container maxWidth={false} disableGutters>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="flex-start"
                  spacing={0}>
                  <Sidebar handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} drawerWidth={drawerWidth} />
                  <Header handleDrawerToggle={handleDrawerToggle} drawerWidth={drawerWidth} />
                  <Content beta disableBtn title="Payout" drawerWidth={drawerWidth} >
                    <PayoutComponent />
                  </Content>
                </Grid>
              </Container>
            </ThemeProvider>
          </React.Fragment>
        </PrivateRoute>
        <PrivateRoute exact path="/">
          <Redirect to="/login" />
        </PrivateRoute>
        <Route path="*">
          <PageNotFound />
        </Route>
        <PrivateRoute path="*">
          <PageNotFound />
        </PrivateRoute>
      </Switch>
    </Router>
  );
}

export default App;
